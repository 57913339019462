import { SETTING_ONLINE_DIGITAL_MENUS } from '@base/config/route-menus';
import UserAutoComplete from '@base/containers/user-auto-complete';
import { slideToObject } from '@base/utils/helpers/array-utils';
import { LANDING_PAGE_TYPES } from '../constants';
import { KEY_LANDINGPAGE_TYPE } from '../key-names';

export const groupByOptions = [
  { label: 'All Landing Pages', value: 'all' },
  { label: 'Unpublish Landing Pages', value: 'unpublish' },
  { label: 'Publish Landing Pages', value: 'publish' },
  { label: 'Schedule Landing Pages', value: 'schedule' },
];

export const dateByOptions = [
  { label: 'Created', value: 'createdAt' },
  { label: 'Published', value: 'publishDate' },
];

export const filterByOptions = [
  {
    label: 'Owner',
    value: 'createdBy',
    component: UserAutoComplete,
    componentProps: { single: true },
    getValue: (componentValue: any) => {
      return componentValue?.id ?? '';
    },
  },
];

export const searchFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    defaultValue: '',
  },
];

export const categoryOptions = slideToObject(SETTING_ONLINE_DIGITAL_MENUS, 'value', 'label');

export const sortsBy = [
  {
    value: 'createdAt',
    label: 'Created At',
  },
  {
    value: 'updatedAt',
    label: 'Updated At',
  },
];
