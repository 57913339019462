import { Select } from '@base/components/form';
import TimePicker from '@base/components/time-picker';
import withLoading from '@base/hooks/hocs/withLoading';
import useMutationPost from '@base/hooks/useMutationPost';
import { formatSettingsAtom } from '@base/recoil/atoms';
import { numberSettingSelector } from '@base/recoil/selectors';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { numberFormat } from '@base/utils/helpers';
import { UPDATE_FORMAT_SETTING } from '@settings/general-setting/services/graphql/format';
import { INumberSetting } from '@settings/general-setting/types/interfaces/format';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

interface IProps {
  setLoading: (params: any) => void;
  data: INumberSetting | undefined;
}
const decimalSymbols = [
  {
    value: '.',
    label: '.',
  },
  {
    value: ',',
    label: ',',
  },
  {
    value: "'",
    label: "'",
  },
];

const digitGroupingSymbols = [
  {
    value: '.',
    label: '.',
  },
  {
    value: ',',
    label: ',',
  },
  {
    value: "'",
    label: "'",
  },
];

const digitGroups = [
  {
    value: '123456789',
    label: '123456789',
  },
  {
    value: '123,456,789',
    label: '123,456,789',
  },
  {
    value: '123456,789',
    label: '123456,789',
  },
  {
    value: '12,34,56,789',
    label: '12,34,56,789',
  },
];

const negativeNumberFormats = [
  {
    value: '1.1',
    label: '1.1',
  },
  {
    value: '-1.1',
    label: '-1.1',
  },
  {
    value: '- 1.1',
    label: '- 1.1',
  },
  {
    value: '1.1-',
    label: '1.1-',
  },
  {
    value: '1.1 -',
    label: '1.1 -',
  },
];

const NumberSetting: React.FC<IProps> = (props: IProps) => {
  const { setLoading } = props;
  const data: INumberSetting = useRecoilValue(numberSettingSelector);
  const [formatSettings, setFormatSettings] = useRecoilState(formatSettingsAtom);
  const refNoOfDecimal = useRef<any>({});
  let numberValue = data
    ? data
    : {
        decimalSymbol: '.',
        noOfDecimal: 2,
        digitGroupingSymbol: ',',
        digitGroup: '123,456,789',
        negativeNumberFormat: '-1.1',
      };
  // console.log('input Number', data);
  // const [numberValue, setNumberValue] = useState(initData);
  const { decimalSymbol, noOfDecimal, digitGroupingSymbol, digitGroup, negativeNumberFormat } =
    numberValue;
  const decimalSymbolValue = useMemo(
    () => decimalSymbols.find((item) => item.value == decimalSymbol),
    [decimalSymbol],
  );
  const digitGroupingSymbolValue = useMemo(
    () => digitGroupingSymbols.find((item) => item.value == digitGroupingSymbol),
    [digitGroupingSymbol],
  );
  const digitGroupValue = useMemo(
    () => digitGroups.find((item) => item.value == digitGroup),
    [digitGroup],
  );
  const negativeNumberFormatValue = useMemo(
    () => negativeNumberFormats.find((item) => item.value == negativeNumberFormat),
    [negativeNumberFormat],
  );
  const mUpdateFormat: any = useMutationPost<BaseMutationResponse>(
    UPDATE_FORMAT_SETTING,
    'setting_updateFormatSetting',
    {
      onSuccess: (res: any) => {
        toast.success('Setting saved!');
        setLoading(false);
        // update format settings
        const newSettings = formatSettings.map((item: any) => {
          if (item.key == 'number') {
            return {
              ...item,
              value: JSON.stringify(numberValue),
            };
          }
          return item;
        });
        setFormatSettings(newSettings);
      },
      onError: (error: any) => {
        toast.error('Saving has failed!');
        setLoading(false);
      },
    },
  );
  useEffect(() => {
    if (refNoOfDecimal.current) {
      refNoOfDecimal.current.value = noOfDecimal;
    }
  }, [noOfDecimal]);
  const previewPositive = useMemo(() => {
    return numberFormat('123456789', numberValue, false);
  }, [numberValue]);

  const previewNegative = useMemo(() => {
    return numberFormat('123456789', numberValue, true);
  }, [numberValue]);

  const updateFormatSetting = (value: any) => {
    // save to server
    numberValue = value;
    mUpdateFormat.mutate({ key: 'number', value: JSON.stringify(value) });
  };
  const onChangeData = (day: any, key: any) => {
    setLoading(true);
    const value = {
      ...numberValue,
      [key]: day.value,
    };
    updateFormatSetting(value);
  };
  const onBlur = (e: any) => {
    setLoading(true);
    const value = {
      ...numberValue,
      noOfDecimal: e.target.value,
    };
    updateFormatSetting(value);
  };

  return (
    <div className="card">
      <div className="card-header h6 bg-light">Number</div>
      <div className="card-body">
        <div className="form-row">
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">Decimal Symbol</label>
            <Select
              className="wd-150-f"
              options={decimalSymbols}
              value={decimalSymbolValue}
              onChange={(v: any) => onChangeData(v, 'decimalSymbol')}
            />
          </div>
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">No. of Decimal</label>
            <input
              type="number"
              className="form-control wd-100"
              ref={refNoOfDecimal}
              onBlur={onBlur}
            />
          </div>
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">Digit Grouping Symbol</label>
            <Select
              className="wd-150-f"
              options={digitGroupingSymbols}
              value={digitGroupingSymbolValue}
              onChange={(v: any) => onChangeData(v, 'digitGroupingSymbol')}
            />
          </div>
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">Digit Group</label>
            <Select
              className="wd-150-f"
              options={digitGroups}
              value={digitGroupValue}
              onChange={(v: any) => onChangeData(v, 'digitGroup')}
            />
          </div>
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">Negative Number Format</label>
            <Select
              className="wd-150-f"
              options={negativeNumberFormats}
              value={negativeNumberFormatValue}
              onChange={(v: any) => onChangeData(v, 'negativeNumberFormat')}
            />
          </div>
          <div className="form-col col-12 form-group">
            <label className="form-item-title">Preview</label>
            <div className="pd-15 bg-light bd bd-dashed rounded">
              <div className="row">
                <div className="col-md-6">Positive: {previewPositive}</div>
                <div className="col-md-6">Negative: {previewNegative}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLoading(NumberSetting);
