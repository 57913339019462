import { useRecoilState } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms';
import { ResultDiagram } from '@settings/process/types/diagram';
import displayLayoutConfig from '@settings/process/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import DiagramContainer, {
  DiagramContainerProps,
} from '@settings/process/containers/view/diagram/DiagramContainer';
import HeaderView, { HeaderViewProps } from '@settings/process/containers/view/HeaderView';
import usePost from '@base/hooks/usePost';
import { useParams } from 'react-router-dom';
import { GET_DIAGRAM } from '@settings/process/services/process';

const BusinessViewPage = () => {
  // console.log('proces view page');
  const [{ isDesktop, isMobile, isTablet, device, layout = 'layout1' }] =
    useRecoilState(deviceAtom);
  const { id: sourceId } = useParams();

  const { data, isLoading } = usePost<ResultDiagram>(['process_diagram', sourceId], GET_DIAGRAM, {
    id: sourceId,
  });

  const diagramProps: DiagramContainerProps = {
    data: data,
    isLoading: isLoading,
  };

  const headerProps: HeaderViewProps = {
    title: data?.name,
  };

  const pageProps = {
    header: <HeaderView {...headerProps} />,
    body: <DiagramContainer {...diagramProps} />,
  };

  return (
    <BaseLayoutDisplay
      displayConfig={displayLayoutConfig}
      device={device}
      layout={layout}
      form="view"
      {...pageProps}
    />
  );
};

export default BusinessViewPage;
