import React, { useEffect, useState } from 'react';
import Icon from '@base/assets/icons/svg-icons';
import { Button, RadioGroup, Select, TimeRangePicker } from '@base/components/form';
import { EBUSINESS_HOURS, EWeekDays } from '@settings/preferences/types/enums';
import { useDeskHoursSetting } from '@settings/preferences/services/service';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_DESK_HOURS_SETTING } from '@settings/preferences/services/graphql';
import { IDeskHours, IWorkingHour } from '@settings/preferences/types/interfaces';
import classNames from 'classnames';
import { toast } from 'react-toastify';
interface IDeskHoursProps {}
const DeskHours: React.FC<IDeskHoursProps> = (props: IDeskHoursProps) => {
  const BH_OPTIONS = [
    { value: EBUSINESS_HOURS.BH_247, label: '24/7' },
    { value: EBUSINESS_HOURS.BH_246, label: '24/6' },
    { value: EBUSINESS_HOURS.BH_245, label: '24/5' },
    { value: EBUSINESS_HOURS.BH_CUSTOM, label: 'Custom' },
  ];
  const WD_OPTIONS = [
    { value: EWeekDays.SUN, label: 'Sunday' },
    { value: EWeekDays.MON, label: 'Monday' },
    { value: EWeekDays.TUE, label: 'Tuesday' },
    { value: EWeekDays.WED, label: 'Wednesday' },
    { value: EWeekDays.THU, label: 'Thursday' },
    { value: EWeekDays.FRI, label: 'Friday' },
    { value: EWeekDays.SAT, label: 'Saturday' },
  ];
  const [setting, setSetting] = useState<IDeskHours | null>(null);

  const [businessHours, setBusinessHours] = useState(BH_OPTIONS[3]);
  const [workingHours, setWorkingHours] = useState<IWorkingHour[]>([]);

  const [firstDayOfWeek, setFirstDayOfWeek] = useState<any>(WD_OPTIONS[1]);
  const [wdOptions, setWDOptions] = useState<any>(WD_OPTIONS);
  const { data, isLoading } = useDeskHoursSetting();
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_DESK_HOURS_SETTING,
    'setting_updateMenuSetting',
  );
  const onSave = (nSetting: IDeskHours) => {
    const param = {
      menu: 'desk',
      key: 'desk_hours',
      value: JSON.stringify(nSetting),
    };
    mUpdate.mutate(
      { menuSetting: param },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
        },
      },
    );
  };

  //change
  const handleBHOptionChange = (newOption: any) => {
    if (setting) {
      let nSetting: IDeskHours = setting;

      switch (newOption.value) {
        case EBUSINESS_HOURS.BH_247:
          nSetting.workingDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
          nSetting.workingHours = [];
          break;
        case EBUSINESS_HOURS.BH_246:
          nSetting.workingDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
          nSetting.workingHours = [];
          break;
        case EBUSINESS_HOURS.BH_245:
          nSetting.workingDays = ['mon', 'tue', 'wed', 'thu', 'fri'];
          nSetting.workingHours = [];
          break;
        case EBUSINESS_HOURS.BH_CUSTOM:
          nSetting.workingDays = ['mon', 'tue', 'wed', 'thu', 'fri'];
          // set working hours
          const defaultWH = {
            day: 'mon',
            startTime: '09:00',
            endTime: '18:00',
          };
          nSetting.workingHours = nSetting.workingDays.map((item) => {
            return {
              day: item,
              startTime: defaultWH.startTime,
              endTime: defaultWH.endTime,
            };
          });
          break;
      }

      nSetting.businessHours = newOption.value;
      if (nSetting.workingDays) {
        const nWDOptions = WD_OPTIONS.filter((item) => {
          return nSetting.workingDays.includes(item.value);
        });
        setWDOptions(nWDOptions);
      }
      setBusinessHours(newOption);
      setWorkingHours(nSetting?.workingHours ?? []);
      setSetting(nSetting);
      onSave(nSetting);
    }
  };
  const handleCopyWH = (item: IWorkingHour) => {
    if (setting && setting.workingHours) {
      const nWorkingHours = setting.workingHours.map((w) => {
        return {
          day: w.day,
          startTime: item.startTime,
          endTime: item.endTime,
        };
      });
      const nSetting = {
        ...setting,
        workingHours: nWorkingHours,
      };
      // console.log('handleCopyWH', nWorkingHours);
      setWorkingHours(nWorkingHours);
      setSetting(nSetting);
      onSave(nSetting);
    }
  };
  const handleChangWH = (nVal: any, day: string) => {
    if (setting && setting.workingHours) {
      const nWorkingHours = setting.workingHours.map((item) => {
        if (item.day === day) {
          return {
            day: day,
            startTime: nVal.startTime,
            endTime: nVal.endTime,
          };
        }
        return item;
      });
      setWorkingHours(nWorkingHours);
      const nSetting = {
        ...setting,
        workingHours: nWorkingHours,
      };
      // console.log('workingHours', nWorkingHours);
      setSetting(nSetting);
      onSave(nSetting);
    }
  };
  const handleFirstDayOfWeek = (nVal: any) => {
    if (setting) {
      setFirstDayOfWeek(nVal.value);
      const nSetting: IDeskHours = {
        ...setting,
        firstDayOfWeek: nVal.value,
      };
      setSetting(nSetting);
      onSave(nSetting);
    }
  };
  const handleWorkDay = (day: string) => {
    if (setting) {
      let nSetting = {
        ...setting,
        workingDays: [...setting.workingDays, day],
      };
      let defaultWH = {
        day: day,
        startTime: '09:00',
        endTime: '18:00',
      };
      if (setting.workingHours && setting.workingHours.length > 0) {
        defaultWH.startTime = setting.workingHours[0].startTime;
        defaultWH.endTime = setting.workingHours[0].endTime;
      } else {
        nSetting.workingHours = [];
      }
      nSetting.workingHours?.push(defaultWH);
      if (nSetting.workingDays) {
        const nWDOptions = WD_OPTIONS.filter((item) => {
          return nSetting.workingDays.includes(item.value);
        });
        setWDOptions(nWDOptions);
      }
      setSetting(nSetting);
      onSave(nSetting);
    }
  };
  useEffect(() => {
    // console.log('data', data);
    if (!isLoading && data?.value) {
      try {
        const nSetting: IDeskHours = JSON.parse(data.value);

        if (nSetting?.businessHours) {
          const value = BH_OPTIONS.find((item) => {
            return item.value == nSetting?.businessHours;
          });
          setBusinessHours(value ?? BH_OPTIONS[3]);
        }
        if (nSetting?.workingHours) {
          setWorkingHours(nSetting.workingHours);
        }
        if (nSetting?.firstDayOfWeek != '') {
          const found = WD_OPTIONS.find((item) => {
            return item.value == nSetting?.firstDayOfWeek;
          });
          setFirstDayOfWeek(found);
        }
        if (nSetting.workingDays) {
          const nWDOptions = WD_OPTIONS.filter((item) => {
            return nSetting.workingDays.includes(item.value);
          });
          setWDOptions(nWDOptions);
        }

        setSetting(nSetting);
      } catch (err: any) {}
    }
  }, [data]);
  return (
    <>
      <div className="card mg-b-15">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Desk Hours</div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label className="form-item-title">Business Hours</label>
            <RadioGroup
              value={businessHours}
              options={BH_OPTIONS}
              onChange={handleBHOptionChange}
            />
          </div>
          <div className="form-group">
            <label className="form-item-title d-block">Working Days</label>
            {WD_OPTIONS.map((item) => {
              //active
              const active =
                setting?.workingDays &&
                setting?.workingDays.length > 0 &&
                setting?.workingDays.includes(item.value);
              const disabled =
                (businessHours.value === EBUSINESS_HOURS.BH_246 && item.value === 'sun') ||
                (businessHours.value === EBUSINESS_HOURS.BH_245 && item.value === 'sun') ||
                (businessHours.value === EBUSINESS_HOURS.BH_245 && item.value === 'sat');
              return (
                <Button
                  key={item.value}
                  color="primary"
                  size="sm"
                  outline
                  name={item.label}
                  disabled={disabled}
                  className={classNames('rounded-30 mg-r-5 mg-b-1', {
                    active: active,
                  })}
                  onClick={() => {
                    handleWorkDay(item.value);
                  }}
                />
              );
            })}
          </div>
          <div className="form-group">
            <label className="form-item-title">First day of work week</label>
            <Select
              width={200}
              value={firstDayOfWeek}
              options={wdOptions}
              onChange={(nVal: any) => {
                handleFirstDayOfWeek(nVal);
              }}
            />
          </div>
          {businessHours.value === EBUSINESS_HOURS.BH_CUSTOM && (
            <div className="form-group">
              <label className="form-item-title">Working Hours</label>
              <ul className="list-group">
                {workingHours?.map((item, index) => {
                  const wd = WD_OPTIONS.find((d) => {
                    return d.value === item.day;
                  });
                  const crrValue = {
                    startTime: item.startTime, //'09:00',
                    endTime: item.endTime, //'18:00',
                  };
                  // console.log('crrValue', crrValue);
                  const now = new Date();
                  const strDate =
                    now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
                  const stDate = new Date(strDate + ' ' + item.startTime);
                  const edDate = new Date(strDate + ' ' + item.endTime);

                  const dHours = Math.abs(edDate.getTime() - stDate.getTime()) / 3600000 - 1;
                  return (
                    <li
                      className="list-group-item d-flex flex-wrap align-items-center bg-white"
                      key={index}
                    >
                      <div className="wd-80">{wd?.label || ''}</div>
                      <TimeRangePicker
                        customLabelStart=" "
                        customLabelEnd=" "
                        value={crrValue}
                        onChange={(nVal: any) => {
                          handleChangWH(nVal, item.day);
                        }}
                      />
                      <span className="mg-x-10">{dHours} hrs</span>
                      {index == 0 && (
                        <Button
                          color="link"
                          icon="Copy"
                          iconClass="mg-r-5"
                          name="Copy"
                          className="mg-l-auto"
                          onClick={() => {
                            handleCopyWH(item);
                          }}
                        />
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DeskHours;
