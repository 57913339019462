import { atom } from 'recoil';

export const emailWriteOptionAtom = atom({
  key: 'EmailWriteOptionAtom',
  default: {
    writeType: 'email',
    writeMenu: 'list',
    isOpenWrite: false,
  },
});
