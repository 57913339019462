import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { User } from 'react-feather';
import {
  useAssignmentUsers,
  useAvailableAssignmentUsers,
} from '@settings/preferences/services/service';
import { Select } from '@base/components/form';

interface IAssignedUsersProps {
  className?: string;
  styles?: any;
  placeholder?: string;
  single?: boolean;
  value?: any;
  onChange?: any;
}

/**
 *
 * @param {*} props
 * @returns
 */
const AssignedUsersAutoComplete: React.FC<IAssignedUsersProps> = (props) => {
  const {
    className,
    styles,
    placeholder = 'Select...',
    single = false, //
    value, //[], initial value
    onChange,
  } = props;

  //state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  const [options, setOptions] = useState<any[]>([]); //for default options
  const [selectedValue, setSelectedValue] = useState<any>(value ?? null);

  const { data, isFetching } = useAvailableAssignmentUsers(searchText);

  //init states list
  useEffect(() => {
    if (data?.results) {
      const nReps = data.results.map((rep) => {
        const nData = rep?.user ? rep.user : rep;
        return {
          ...nData,
        };
      });
      setOptions(nReps);
    }
  }, [data]);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };
  //value change
  const handleSelectChange = (newValue: any) => {
    setSelectedValue(newValue);
    //callback
    onChange && onChange(newValue);
  };

  //render
  return (
    <div className={classNames('pos-relative', className)} style={styles}>
      <Select
        isClearable
        isSearchable={false}
        outSide={true}
        isMulti={!single}
        noOptionsMessage={() => 'No user(s) found.'}
        placeholder={<div className="react-select-placeholder">{placeholder}</div>}
        iconIndicator={<User size={16} />}
        isLoading={isFetching}
        inputValue={inputText}
        onInputChange={handleInputChange}
        options={options}
        getOptionLabel={(opt: any) => opt.name ?? ''}
        getOptionValue={(opt: any) => opt.id ?? ''}
        value={selectedValue}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default AssignedUsersAutoComplete;
