import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { Checkbox } from '@base/components/form';

const View: React.FC = (props: any) => {
  const { value } = props;
  //value is object link, openNewWindow
  return (
    <div>
      <span>{value?.link ?? ''}</span>
      <Checkbox label="Open page in new window" checked={value?.openNewWindow} disabled={true} />
    </div>
  );
};

export default View;
