import { useMemo } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import statusAtom from '@settings/process/recoil/status';

interface ChecklistViewProps {}

function ChecklistView(props: ChecklistViewProps) {
  const statusesValue = useRecoilValue(statusAtom);
  const targetIndex = useMemo(
    () => statusesValue.findIndex((status) => status.property.keyName == 'PROPERTY_TODO_CLOSE'),
    [],
  );
  let checklist = _.cloneDeep(statusesValue[targetIndex]?.checklist!!);
  // console.log('checklist', checklist);

  return (
    <div className="form-row">
      <div className="form-group col-12">
        <label className="form-item-title">Checklist</label>
        <ul className="list-group wd-100p">
          {checklist?.map((ck) => {
            return (
              <li key={ck.id} className="list-group-item bg-transparent d-flex align-items-center">
                {ck.name}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default ChecklistView;
