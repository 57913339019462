import * as components from './components';

export default {
  desktop: {
    layout1: {
      setting: components.BasicDesktopLayout1,
      list: components.ListDesktopLayout1,
      view: components.ViewFormDesktopLayout1,
    },
    layout2: {
      list: null,
      write: null,
      view: null,
    },
    layout3: {
      list: null,
      write: null,
      view: null,
    },
  },
  tablet: {
    layout1: {
      list: null,
      write: null,
      view: null,
    },
    layout2: {
      list: null,
      write: null,
      view: null,
    },
    layout3: {
      list: null,
      write: null,
      view: null,
    },
  },
  mobile: {
    layout1: {
      list: null,
      write: null,
      view: null,
    },
    layout2: {
      list: null,
      write: null,
      view: null,
    },
    layout3: {
      list: null,
      write: null,
      view: null,
    },
  },
};
