import React, { useState } from 'react';
import classNames from 'classnames';
import HeaderMultiTheme from '@base/containers/header-multi-theme';
import { RightCollapse, LeftCollapse } from '@base/assets/icons/svg-icons';
import ViewTabsHeader from '@base/components/view/tabs-header';
import ViewTabsBody from '@base/components/view/tabs-body/indext';
import Loading from '@base/components/loading';
import withMiModal from '@base/hooks/hocs/withMiModal';
import ViewLeftPreview from './view-left';
import PreviewDetailView from './view-center';

export interface IPreviewViewAccount {
  className?: string;
  isLoading?: boolean;
  headerProps: any;
  componentTop?: any;
  topProps?: any;
  componentLeft: any;
  leftProps?: any;
  componentCenter?: any;
  centerProps?: any;
  componentRight: any;
  rightProps?: any;
  contentStyle?: any;
  tabViewComponent?: any;
  tabView?: boolean;
  sections: any;
}

const PreviewViewAccount = (props: IPreviewViewAccount) => {
  let {
    className = '',
    isLoading,
    headerProps,
    topProps,
    componentLeft,
    componentCenter,
    componentRight,
    centerProps,
    componentTop,
    contentStyle,
    tabViewComponent,
    tabView,
    sections,
  } = props;
  const [hideAsideLeft, setHideAsideLeft] = useState(false);
  const [hideAsideRight, setHideAsideRight] = useState(false);
  // const [popoverOpen, setPopoverOpen] = useState(false);
  // const toggle = () => setPopoverOpen(!popoverOpen);
  const headerPropss = {
    menu: 'account',
    menus: [{ label: 'account', path: '', value: 'account' }],
    title: '',
    tabPath: '',
  };
  const tabs = [
    {
      default: true,
      label: 'Details',
      order: 1,
      path: 'detail',
      tabComponent: PreviewDetailView,
      tabComponentProps: { category: 'account' },
    },
  ];
  return (
    <div className={classNames('view-wrap', className)}>
      <HeaderMultiTheme {...headerPropss} />
      <div className="row no-gutters flex-nowrap flex-grow-1" style={contentStyle}>
        <div
          className={classNames('col-3 view-aside')}
          style={{ transformOrigin: 'left' }}
        >
          {!hideAsideLeft ? (
            <button
              type="button"
              className="btn btn-show-aside-right"
              onClick={() => setHideAsideLeft(!hideAsideLeft)}
            >
              <LeftCollapse />
            </button>
          ) : (
            ''
          )}
         <ViewLeftPreview section={sections[0]} />
        </div>
        {/** View Content */}
        <div
          className={classNames('view-content', {
            'col-9': hideAsideLeft || hideAsideRight || !componentRight,
            'col-12': (hideAsideLeft && hideAsideRight) || (!componentRight && hideAsideLeft),
            'col-6': Boolean(componentRight),
          })}
        >
          <div className="d-flex align-items-start bg-white bd-b pd-t-8">
            {/* collapse button */}
            {/**TODO TabView */}
            <ViewTabsHeader tabs={tabs} />
            {/* collapse button */}
          </div>

          <div className="view-body"><PreviewDetailView section={sections[1]} /></div>
        </div>
      </div>
    </div>
  );
};

export default withMiModal(PreviewViewAccount);
