import { Checkbox, Input } from '@base/components/form';
import ImageUploader from '@base/components/form/image-uploader';
import {
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_CONDITION,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_EVENT,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_LOGO,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_NAME,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_NUMBER,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_PEPRESENRATATIVE,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_SYNCHRO,
} from '@settings/billing-license/config/key-names';
import React, { useState } from 'react';

interface IProps {
  value: any;
  onChange: (keyName: string, keyValue: any) => any;
}

const BillingInfoRegistration: React.FC<IProps> = (props: IProps) => {
  const { value, onChange } = props;
  const [submitValue, setSubmitValue] = useState(value?.registration);

  const handleChange = (keyName: string, keyValue: any) => {
    const newVal = { ...value.registration };
    newVal[keyName] = keyValue;

    onChange && onChange('registration', newVal);
  };
  return (
    <div className="card">
      <div className="card-header h6 bg-light">사업자 등록증</div>
      <div className="card-body">
        <div className="form-row">
          <div className="form-group col-12">
            <label className="form-item-title">등록번호</label>
            <Input
              type="text"
              value={submitValue[KEY_NAME_BILLING_INFOMATION_REGISTRATION_NUMBER]}
              onChange={(data: any) =>
                handleChange(KEY_NAME_BILLING_INFOMATION_REGISTRATION_NUMBER, data)
              }
            />
          </div>
          <div className="form-group col-md-6">
            <label className="form-item-title">법인명</label>
            <Input
              type="text"
              value={submitValue[KEY_NAME_BILLING_INFOMATION_REGISTRATION_NAME]}
              onChange={(data: any) =>
                handleChange(KEY_NAME_BILLING_INFOMATION_REGISTRATION_NAME, data)
              }
            />
          </div>
          <div className="form-group col-md-6">
            <label className="form-item-title">대표자</label>
            <Input
              type="text"
              value={submitValue[KEY_NAME_BILLING_INFOMATION_REGISTRATION_PEPRESENRATATIVE]}
              onChange={(data: any) =>
                handleChange(KEY_NAME_BILLING_INFOMATION_REGISTRATION_PEPRESENRATATIVE, data)
              }
            />
          </div>
          <div className="form-group col-12">
            <label className="form-item-title">사업장 소재지</label>
            <div className="custom-control custom-checkbox">
              <Checkbox
                label="청구 주소와 동기화"
                className="pd-l-0"
                value={submitValue[KEY_NAME_BILLING_INFOMATION_REGISTRATION_SYNCHRO]}
                onChange={() => {
                  onChange(
                    KEY_NAME_BILLING_INFOMATION_REGISTRATION_SYNCHRO,
                    !submitValue[KEY_NAME_BILLING_INFOMATION_REGISTRATION_SYNCHRO],
                  );
                }}
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="form-item-title">업태</label>
            <Input
              type="text"
              value={submitValue[KEY_NAME_BILLING_INFOMATION_REGISTRATION_CONDITION]}
              onChange={(data: any) =>
                handleChange(KEY_NAME_BILLING_INFOMATION_REGISTRATION_CONDITION, data)
              }
            />
          </div>
          <div className="form-group col-md-6">
            <label className="form-item-title">종목</label>
            <Input
              type="text"
              value={submitValue[KEY_NAME_BILLING_INFOMATION_REGISTRATION_EVENT]}
              onChange={(data: any) =>
                handleChange(KEY_NAME_BILLING_INFOMATION_REGISTRATION_EVENT, data)
              }
            />
          </div>
          <div className="form-group col-12">
            <label className="form-item-title">Your Logo</label>
            <ImageUploader
              isSingle={true}
              type={'img'}
              value={submitValue[KEY_NAME_BILLING_INFOMATION_REGISTRATION_LOGO]}
              onChange={(data: any) =>
                handleChange(KEY_NAME_BILLING_INFOMATION_REGISTRATION_LOGO, data)
              }
            />
            <div className="mg-t-5 tx-info">This logo will apeear on the many documents.</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BillingInfoRegistration;
