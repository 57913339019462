import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { X } from 'react-feather';
import { toast } from 'react-toastify';

import { ListBody, ListContainer } from '@base/components/list';
import { deviceAtom } from '@base/recoil/atoms';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import displayLayoutConfig from '@settings/process/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import ListHeader from '@settings/process/components/list-header/ListHeader';
import { AutomationListContainer } from '@settings/process/containers/list';
import Loading from '@base/components/loading';
import { OptionValue } from '@base/types/interfaces/common';
import WritePage from '@settings/process/pages/write/WritePage'; //for write
import { SpanLang } from '@base/components';
import { AutomationRule } from '@settings/process/types/automation';
import usePosts from '@base/hooks/usePosts';
import {
  DELETE_AUTOMATION_RULE,
  GET_AUTOMATION_RULES,
} from '@settings/process/services/automation';
import { AUTOMATION_TYPE } from '@settings/process/config/constants';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';

interface AutomationListPageProps {}

function AutomationListPage(props: AutomationListPageProps) {
  // console.log('AutomationListPage');
  const [{ isDesktop, isMobile, isTablet, device, layout = 'layout1' }] =
    useRecoilState(deviceAtom);
  const [fullScreen, setFullScreen] = useState(false); //for write page
  const [writeOption, setWriteOption] = useState<{ open: Boolean; id?: string }>({
    open: false,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data,
    isLoading,
    refetch: reloadList,
    isPreviousData,
  } = usePosts<AutomationRule[]>(
    ['process_automationRules', pageNumber],
    GET_AUTOMATION_RULES,
    {
      filter: {
        sort: {
          field: 'createdAt',
          orderBy: 'DESC',
        },
        paging: {
          page: pageNumber,
          size: pageSize,
        },
      },
    },
    {
      keepPreviousData: true,
    },
  );

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_AUTOMATION_RULE,
    'process_deleteAutomationRule',
    {
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Delete Automation Rule successfully!');
        setTimeout(() => {
          reloadList();
        }, 500);
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Delete Automation Rule failed');
      },
    },
  );

  const editFn = useMemo(
    () => (id: string) => {
      setWriteOption({
        open: true,
        id: id,
      });
    },
    [],
  );

  const deleteFn = useMemo(
    () => (id: string) => {
      // console.log('deleted', id);
      if (confirm('Would you like to delete?')) {
        mutationDelete.mutate({ id: id });
      }
    },
    [],
  );

  const pagingProps = {
    totalPage: data?.paging?.totalPage || 1,
    totalItems: data?.paging?.totalItems || 0,
    currentPage: data?.paging?.currentPage || 1,
    itemPerPage: data?.paging?.itemPerPage || 10,
    nextPage: data?.paging?.nextPage || null,
    previousPage: data?.paging?.previousPage || null,
    isPreviousData,
    onChange: (page: number, size: number) => {
      if (pageSize !== size) {
        setPageSize(size);
      }
      setPageNumber(page);
    },
  };

  const fields: OptionValue[] = [
    {
      keyName: 'name',
      languageKey: 'Name',
    },
    {
      keyName: 'type',
      languageKey: 'Type',
    },
    {
      keyName: 'trigger',
      languageKey: 'Trigger',
    },
    {
      keyName: 'criteria',
      languageKey: 'Criteria',
    },
    {
      keyName: 'description',
      languageKey: 'Description',
    },
    {
      keyName: 'createdAt',
      languageKey: 'Created At',
    },
    {
      keyName: 'mode',
      languageKey: 'Mode',
    },
  ];

  const columnRenderRemap = {
    name(col: string, data: any) {
      const field = data[col] ? data[col] : '';
      return (
        <button type="button" className="btn btn-link" onClick={() => editFn(data.id)}>
          {field}
        </button>
      );
    },
    createdAt(col: string, data: any) {
      const createdAt = data[col] ? data[col] : '';
      return convertDateTimeServerToClient({ date: createdAt, isTime: false });
    },
    criteria(col: string, data: any) {
      const criteria = data[col] ? data[col] : null;
      return criteria?.name;
    },
    trigger(col: string, data: any) {
      const trigger = data[col] ? data[col] : null;
      return trigger?.name;
    },
    type(col: string, data: any) {
      const type = data[col] ? data[col] : null;
      return AUTOMATION_TYPE[type];
    },
    description(col: string, data: any) {
      const description = data[col] ? data[col] : null;
      return description;
    },
    mode(col: string, data: any) {
      return (
        <button
          type="button"
          className="btn btn-xs btn-link link-03 btn-icon"
          onClick={() => deleteFn(data.id)}
        >
          <X />
        </button>
      );
    },
  };

  const handleAdd = useCallback(() => {
    setWriteOption({
      open: true,
    });
  }, []);

  const onClose = useCallback(() => {
    setWriteOption({
      open: false,
    });
  }, []);

  const pageProps = {
    toolbar: '',
    header: <ListHeader handleAdd={handleAdd} title="Automation Rule" onRefresh={reloadList} />,
    body: (
      <ListBody>
        <AutomationListContainer
          data={data?.data ?? []}
          fields={fields}
          columnRenderRemap={columnRenderRemap}
          pagingProps={pagingProps}
        />
      </ListBody>
    ),
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <ListContainer isDesktop={isDesktop}>
          <BaseLayoutDisplay
            displayConfig={displayLayoutConfig}
            device={device}
            layout={layout}
            form="list"
            {...pageProps}
          />
        </ListContainer>
      )}

      {writeOption.open && (
        <WritePage
          title={
            <SpanLang
              keyLang={writeOption.id ? 'Edit Automation Rule' : 'Create Automation Rule'}
            />
          }
          size={'lg'}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.open}
          component={'writeAutomation'}
          onReload={reloadList}
          onClose={onClose}
          id={writeOption.id}
        />
      )}
    </>
  );
}

export default AutomationListPage;
