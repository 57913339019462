import { Checkbox } from '@base/components/form';
import React from 'react';

const View: React.FC = (props: any) => {
  const { value } = props;

  return (
    <>
      <Checkbox
        checked={value?.createTicket ?? false}
        onChange={() => {}}
        disabled={true}
        label="Create Ticket"
      />
    </>
  );
};

export default View;
