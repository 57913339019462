import { lazy } from 'react';
import { IFilterByOption } from '@base/types/interfaces/common';

const UserAutoComplete = lazy(() => import('@base/containers/user-auto-complete'));

export const categoryOptions = {
  ['survey']: 'Recent Surveys',
};

export const groupByOptions = [{ label: 'All Surveys', value: 'all' }];

export const dateByOptions = [
  { label: 'Created', value: 'createdAt' },
  { label: 'Updated', value: 'updatedAt' },
];

export const filterByOptions: IFilterByOption[] = [
  {
    label: 'Owner',
    value: 'createdBy',
    component: UserAutoComplete,
    componentProps: { single: true },
    getValue: (componentValue: any) => {
      return componentValue?.id ?? '';
    },
  },
];

export const searchFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    defaultValue: '',
  },
];

export const sortsBy = [
  { label: 'Name', value: 'name' },
  { label: 'Created At', value: 'createdAt' },
  { label: 'Updated At', value: 'updatedAt' },
];
