import React, { useState, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, listPageSettingsAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import SplitView, { EmptySplitViewFC } from '@base/components/split-view';
import { useLanguageByMenu } from '@base/services/i18n';

import ListPage from '../list';
import ViewPage from '../view';
import { ListType } from '@base/types/enums';
import { MENU_SETTING_SURVEY } from '@base/config/menus';
import { isUndefined } from 'lodash';
import Loading from '@base/components/loading';

const MainPage: React.FC = () => {
  const [{ innerHeight, isDesktop }] = useRecoilState(deviceAtom);

  // load menu language
  useLanguageByMenu([MENU_SETTING_SURVEY]);
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_SETTING_SURVEY));
  // const listPageSettings = useRecoilValue(listPageSettingsAtom);
  const [isLoadingSetting, setIsLoadingSetting] = useState(false);
  const { isSplitMode } = pageData;
  const setIsSplitMode = (isSplitMode: boolean) => {
    setPageData({
      ...pageData,
      listType: isSplitMode ? ListType.SPLIT : ListType.LIST,
      isSplitMode,
    });
  };
  const splitViewProps = {
    isSplitMode: isSplitMode,
    styles: {
      height: innerHeight !== 0 ? innerHeight - 60 : 0,
    },
  };

  const listProps = {
    isSplitMode: isSplitMode,
    setIsSplitMode: setIsSplitMode,
  };
  // useEffect(() => {
  //   if (listPageSettings && listPageSettings[MENU_SETTING_SURVEY]) {
  //     const menuListPage: any = listPageSettings[MENU_SETTING_SURVEY];
  //     const nPageData = {
  //       ...pageData,
  //       isSplitMode: menuListPage?.listType === ListType.SPLIT,
  //       listType: menuListPage?.listType,
  //     };
  //     setPageData(nPageData);
  //   }
  //   if (
  //     listPageSettings &&
  //     (isUndefined(listPageSettings[MENU_SETTING_SURVEY]) || listPageSettings[MENU_SETTING_SURVEY])
  //   ) {
  //     setIsLoadingSetting(false);
  //   }
  // }, [listPageSettings]);
  return (
    <React.Suspense fallback={<></>}>
      {isLoadingSetting ? (
        <React.Suspense fallback={<></>}>
          <Loading />
        </React.Suspense>
      ) : (
        <Routes>
          <Route
            path=""
            element={
              <React.Suspense fallback={<></>}>
                {isSplitMode && isDesktop ? (
                  <SplitView
                    {...splitViewProps}
                    leftPane={<ListPage {...listProps} />}
                    rightPane={<EmptySplitViewFC />}
                  />
                ) : (
                  <ListPage {...listProps} />
                )}
              </React.Suspense>
            }
          />
          <Route
            path=":id/*"
            element={
              <React.Suspense fallback={<></>}>
                {isSplitMode && isDesktop ? (
                  <SplitView
                    {...splitViewProps}
                    leftPane={<ListPage {...listProps} />}
                    rightPane={
                      <div className="pane-content" style={{ flex: 1 }}>
                        <Suspense fallback={<div className="han-loading" />}>
                          <ViewPage isSplitMode={isSplitMode} />
                        </Suspense>
                      </div>
                    }
                  />
                ) : (
                  <ViewPage />
                )}
              </React.Suspense>
            }
          />
          {/* <Route path="/" element={<Navigate replace to="settings/online-digital-content/survey" />} /> */}
        </Routes>
      )}
    </React.Suspense>
  );
};

export default MainPage;
