import * as keyNames from '@settings/template/config/key-names';
import * as components from './components';
import * as commonComponents from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';

const kbWriteConfig = {
  [keyNames.KEY_TEMPLATE_NAME]: {
    component: commonComponents.Input,
    componentProps: { type: 'text' },
    validate: { required: validators.required },
    languageKey: 'Template Name',
    showFullRow: true,
    defaultValue: '',
  },
  [keyNames.KEY_TEMPLATE_GROUP]: {
    component: commonComponents.Input,
    componentProps: { type: 'text' },
    validate: {},
    languageKey: 'Template Group',
    showFullRow: true,
    defaultValue: '',
  },
  [keyNames.KEY_TEMPLATE_CONTENT]: {
    component: commonComponents.Editor,
    componentProps: {},
    validate: { required: validators.required },
    languageKey: 'Template Content',
    showFullRow: true,
    defaultValue: '',
  },
};
export default kbWriteConfig;
