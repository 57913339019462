import { lazy } from 'react';

const CtaMain = lazy(() => import('./main'));

export default [
  {
    path: '/settings/email/*',
    component: CtaMain,
  },
];
