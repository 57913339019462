import { SubmissionDisplay, SubmissionBehavior } from './../write-field/components';
import { lazy } from 'react';

export const ProductView = lazy(
  () => import('@product/product/containers/view-field/product-auto-complete'),
);
export const LanguageSelectView = lazy(() => import('@base/containers/view-field/language-select'));
export const SubmissionDisplayView = lazy(
  () =>
    import('@settings/online-digital-content/ticket-form/containers/view-field/submission-display'),
);
export const SubmissionBehaviorView = lazy(
  () =>
    import(
      '@settings/online-digital-content/ticket-form/containers/view-field/submission-behavior'
    ),
);
