import * as keyNames from '../key-names';
import * as components from './components';
import {
  SETTING_CTA_CONTENT_TYPES,
  SETTING_CTA_LANDING_PAGE_TYPES,
  SETTING_CTA_LINK_TYPES,
  SETTING_CTA_TYPES,
} from '../constants';
import validators from '@base/utils/validation/field-validator';

export default {
  [keyNames.KEY_SETTING_CTA_NAME]: {
    component: components.Input,
    componentProps: {
      type: 'text',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SETTING_CTA_TYPE]: {
    component: components.Select,
    componentProps: {
      options: SETTING_CTA_TYPES,
      isSearchable: false,
    },
    showFullRow: true,
    validate: {
      required: true,
    },
    defaultValue: SETTING_CTA_TYPES[0],
    parseParam: (value: any) => value?.value,
  },
  [keyNames.KEY_SETTING_CTA_LANGUAGE]: {
    component: components.LanguageSelect,
    componentProps: {},
    showFullRow: true,
    defaultValue: 'en',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SETTING_CTA_VALUE]: {
    component: null,
  },
  [keyNames.KEY_SETTING_CTA_IMAGE_URL]: {
    component: components.ImageUploadSample,
    validate: {},
    showFullRow: true,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_SETTING_CTA_IMAGE_SIZE]: {
    component: components.ImageSize,
    validate: {},
    defaultValue: { width: 300, height: 300 },
    showFullRow: true,
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SETTING_CTA_IMAGE_ALT]: {
    component: components.Input,
    componentProps: {
      type: 'text',
    },
    validate: {},
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SETTING_CTA_LINK_TYPE]: {
    component: components.Select,
    componentProps: {
      options: SETTING_CTA_LINK_TYPES,
      isSearchable: false,
    },
    showFullRow: true,
    defaultValue: SETTING_CTA_LINK_TYPES[0],
    parseParam: (value: any) => value?.value,
  },
  [keyNames.KEY_SETTING_CTA_EXT_SITE_NAME]: {
    component: components.Input,
    componentProps: {
      type: 'text',
    },
    validate: {},
    showFullRow: true,
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SETTING_CTA_LINK_URL]: {
    component: components.LinkUrl,
    componentProps: {},
    showFullRow: true,
    validate: {
      required: validators.ctaLink,
    },
    defaultValue: { link: '', openNewWindow: false },
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_SETTING_CTA_RESOURCE]: {
    component: components.LandingPageAutoComplete,
    componentProps: {
      single: true,
    },
    showFullRow: true,
    validate: {},
    defaultValue: false,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_SETTING_CTA_RESOURCE_TYPE]: {
    component: components.Select,
    componentProps: {
      isSearchable: false,
      options: SETTING_CTA_LANDING_PAGE_TYPES,
    },
    showFullRow: true,
    validate: {},
    defaultValue: SETTING_CTA_LANDING_PAGE_TYPES[0],
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_SETTING_CTA_CONTENT_TYPE]: {
    component: components.Select,
    componentProps: {
      isSearchable: false,
      options: SETTING_CTA_CONTENT_TYPES,
    },
    showFullRow: true,
    validate: {},
    defaultValue: SETTING_CTA_CONTENT_TYPES[0],
    parseParam: (value: any) => value?.value,
  },
  [keyNames.KEY_SETTING_CTA_PAGE_TITLE]: {
    component: components.Input,
    componentProps: {
      type: 'text',
    },
    showFullRow: true,
    validate: {},
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SETTING_CTA_DESCRIPTION]: {
    component: components.TextArea,
    componentProps: {},
    showFullRow: true,
    validate: {},
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SETTING_CTA_AB_TEST]: {
    component: components.Switch,
    showFullRow: true,
    validate: {},
    defaultValue: false,
    parseParam: (value: boolean) => value,
  },
};
