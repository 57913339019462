import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import DatePicker from '@base/components/form/date-selector';
import {
  LANDING_PAGE_PUBLISH_SCHEDULE_LATER,
  LANDING_PAGE_PUBLISH_SCHEDULE_PUBLISH_NOW,
  LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH,
  PUBLISH_SCHEDULE_OPTIONS,
} from '@settings/online-digital-content/landing-page/config/constants';
import { RadioGroup } from '@base/components/form';

const Edit: React.FC = (props: any) => {
  const { value, errors, onChange } = props;

  const [publish, setPublish] = useState(LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH);
  const [publishDate, setPublishDate] = useState(new Date().toISOString());

  useEffect(() => {
    if (publish != value.publish) {
      setPublish(value.publish);
    }
    if (
      value.publishDate &&
      publishDate != value.publishDate &&
      value.publishDate != '0001-01-01T00:00:00Z'
    ) {
      setPublishDate(value.publishDate);
    }
  }, [value]);

  const handlePublishChange = (value: string) => {
    setPublish(value);

    onChange &&
      onChange({
        publish: value,
        publishDate: publishDate,
      });
  };

  const handlePublishDateChange = (date: string) => {
    setPublishDate(date);

    onChange &&
      onChange({
        publish: publish,
        publishDate: date,
      });
  };

  return (
    <div>
      <div className={classnames({ 'is-required': errors.isRequired })}>
        <div className="d-flex flex-column">
          <RadioGroup
            value={publish}
            options={PUBLISH_SCHEDULE_OPTIONS}
            onChange={(value) => {
              handlePublishChange(value?.value);
            }}
          />
          {publish === LANDING_PAGE_PUBLISH_SCHEDULE_LATER && (
            <div className="pd-t-5">
              <DatePicker value={publishDate} onChange={handlePublishDateChange} />
            </div>
          )}
        </div>
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </div>
  );
};

export default Edit;
