import { isString } from 'lodash';
import { useEffect, useState } from 'react';

const CTAPreview: React.FC<any> = (props) => {
  const { image, imageSize, altText } = props;

  // state
  const [imagePreview, setImagePreView] = useState<string>('');

  useEffect(() => {
    if (image) {
      if (isString(image?.image)) {
        setImagePreView(image?.image);
      } else {
        setImagePreView(URL?.createObjectURL(image?.image));
      }
    }
  }, [image]);

  return (
    <div className="form-group d-flex flex-column ht-100p">
      <label className="form-item-title">Preview</label>
      <div className="d-flex align-items-center flex-grow-1 pd-10 bg-light rounded overflow-auto">
        <div className="wd-100p tx-center">
          {imagePreview && (
            <img
              src={imagePreview}
              alt={altText}
              style={{ width: imageSize?.width, height: imageSize?.height }}
              className="img-fit-contain"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CTAPreview;
