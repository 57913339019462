import { gql } from 'graphql-request';
export const LANGUAGE_VALUE = `
    language {
        en
        vi
        ko
        jp
        zh
        ido
    }
`;
export const GET_ALL_SELECTION_FIELDS = gql`
  query {
    setting_selectionFields {
      results {
        id
        languageKey
        keyName
        ${LANGUAGE_VALUE}
      }
    }
  }
`;

export const GET_SELECTION_FIELD_ITEMS = gql`
  query q($keyName: String) {
    setting_selectionFieldItems(keyName: $keyName) {
      results {
        id
        languageKey
        keyName
        ${LANGUAGE_VALUE}
      }
    }
  }
`;

export const GET_ALL_SELECTION_GROUPS = gql`
  query {
    setting_selectionGroups {
      results {
        id
        languageKey
        keyName
        ${LANGUAGE_VALUE}
      }
    }
  }
`;
export const GET_SELECTION_GROUP_ITEMS = gql`
  query q($parentId: String) {
    setting_selectionGroupData(parentId: $parentId) {
      results {
        id
        languageKey
        keyName
        parentId
        ${LANGUAGE_VALUE}
      }
    }
  }
`;

export const UPDATE_SELECTION_ITEM = gql`
  mutation q($input: Selection) {
    setting_updateSelection(selection: $input) {
      id
    }
  }
`;

export const DELETE_SELECTION_ITEM = gql`
  mutation q($id: String!, $selectType: String!) {
    setting_deleteSelection(id: $id, selectType: $selectType) {
      id
    }
  }
`;

export const MOVE_SELECTION_ITEMS = gql`
  mutation q($ids: [String!]) {
    setting_moveSelections(ids: $ids)
  }
`;
export const ADD_SELECTION_ITEM = gql`
  mutation q($input: Selection) {
    setting_createSelection(selection: $input) {
      id
    }
  }
`;
