import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { Canvas } from '@base/components';
import { generateUUID } from '@base/utils/helpers';

import SurveyHeader from '@settings/online-digital-content/survey/components/survey-header'; //re-use
import QuestionTheme from '@settings/online-digital-content/survey/components/question-theme'; //re-use
import { getSurveyFromStorage } from '@settings/online-digital-content/survey/services/storage'; //re-use

import SurveyView from '@settings/online-digital-content/satisfaction-survey/containers/survey-view';
import QuestionWrite from '@settings/online-digital-content/satisfaction-survey/components/question-write';
import { Q_SAT_MULTI_CHOICES_GRID } from '@settings/online-digital-content/satisfaction-survey/config/constants';
import {
  SURVEY_CREATE,
  SURVEY_UPDATE,
} from '@settings/online-digital-content/satisfaction-survey/services/graphql'; //TODO

interface SurveyFormProps {
  className?: string;
  minScrollHeight?: number;
  id: string;
  survey?: any;
  fullScreen?: boolean;
  storageOn?: boolean;
  storageId?: string;
  value?: any;
  onChange?: (value: any) => void;
}

const TABS = [
  {
    value: 1,
    label: 'Editor',
  },
  {
    value: 2,
    label: 'Preview',
  },
];

const DEFAULT_BG_COLOR = '#e4f3e5';
const DEFAULT_LINE_COLOR = '#4caf50';

const SurveyForm: React.FC<SurveyFormProps> = (props) => {
  const {
    className,
    minScrollHeight = 330,
    id = '', //survey id
    survey = null, //survey data
    storageOn = false,
    fullScreen = false,
    storageId,
    value,
    onChange,
  } = props;
  const tmpStorageId = id ? id : storageId || 'satisfaction_survey_t2m';

  //initial sections
  const INITIAL_SECTIONS = [
    {
      id: generateUUID(),
      title: '',
      description: '',
      questions: [
        {
          id: generateUUID(),
          title: '',
          type: Q_SAT_MULTI_CHOICES_GRID,
          required: false,
          //image: { name: '', url: '', size: '' },
          options: {
            rows: [
              {
                id: generateUUID(),
                value: 'Product/Service Quality',
                isOther: false,
              },
              {
                id: generateUUID(),
                value: 'Easy of Use',
                isOther: false,
              },
              {
                id: generateUUID(),
                value: 'Price',
                isOther: false,
              },
              {
                id: generateUUID(),
                value: 'Delivery Time',
                isOther: false,
              },
              {
                id: generateUUID(),
                value: 'Customer Service',
                isOther: false,
              },
            ],
            cols: [
              {
                id: generateUUID(),
                value: 'Highly Satisfied',
                isOther: false,
              },
              {
                id: generateUUID(),
                value: 'Satisfied',
                isOther: false,
              },
              {
                id: generateUUID(),
                value: 'Neutral',
                isOther: false,
              },
              {
                id: generateUUID(),
                value: 'Dissatisfied',
                isOther: false,
              },
              {
                id: generateUUID(),
                value: 'Highly Dissatisfied',
                isOther: false,
              },
            ],
            // answer: {
            //   0: { 0: false, 1: false, 2: false, 3: false, 4: false },
            //   1: { 0: false, 1: false, 2: false, 3: false, 4: false },
            //   2: { 0: false, 1: false, 2: false, 3: false, 4: false },
            //   3: { 0: false, 1: false, 2: false, 3: false, 4: false },
            //   4: { 0: false, 1: false, 2: false, 3: false, 4: false }
            // }
          },
        },
      ],
    },
  ];

  //state
  const [tab, setTab] = useState<any>(TABS[0]);
  const [surveyHeaderImg, setSurveyHeaderImg] = useState<any>(null); //image on survey header
  const [surveyHeadLineColor, setSurveyHeadLineColor] = useState(DEFAULT_LINE_COLOR); //image on survey header
  const [surveyBgColor, setSurveyBgColor] = useState(DEFAULT_BG_COLOR); //light-gray
  const [surveyImage, setSurveyImage] = useState<any>(null); //thumb image for survey - for display on list
  const [surveySections, setSurveySections] = useState<any>(INITIAL_SECTIONS || []); //all sections of a survey
  const [showSideBar, setShowSideBar] = useState(false);
  const questionRef = useRef<any>(null);

  // create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    SURVEY_CREATE,
    'setting_createSurvey',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Created survey template successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Created survey template failed: ' + JSON.parse(error).message);
      },
    },
  );

  // update mutation
  const mutationUpdate: any = useMutationPost<BaseMutationResponse>(
    SURVEY_UPDATE,
    'setting_updateSurvey',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Updated Survey successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Updated Survey failed: ' + JSON.parse(error).message);
      },
    },
  );

  //get temporary data from indexeddb
  async function getStoreSurvey(id: string) {
    return await getSurveyFromStorage(id);
  }

  //init state
  useEffect(() => {
    if (value) {
      if (value?.sections) {
        if (JSON.stringify(value.sections) !== JSON.stringify(surveySections)) {
          setSurveySections(value.sections);
        }
      } else {
        setSurveySections(INITIAL_SECTIONS);
      }
      if (value?.headerImg) {
        setSurveyHeaderImg(value?.headerImg);
      } else {
        setSurveyHeaderImg(null);
      }
      if (value?.headerLineColor) {
        setSurveyHeadLineColor(value?.headerLineColor);
      } else {
        setSurveyHeadLineColor(DEFAULT_LINE_COLOR);
      }
      if (value?.bgColor) {
        setSurveyBgColor(value?.bgColor);
      } else {
        setSurveyBgColor(DEFAULT_BG_COLOR);
      }
    } else {
      if (storageOn) {
        getStoreSurvey(tmpStorageId).then((storeSurvey: any) => {
          if (storeSurvey) {
            setSurveyHeaderImg(storeSurvey?.headerImg);
            setSurveyHeadLineColor(storeSurvey?.headerLineColor);
            setSurveyBgColor(storeSurvey?.bgColor);
            if (JSON.stringify(storeSurvey?.sections) !== JSON.stringify(surveySections)) {
              setSurveySections(storeSurvey?.sections || []);
            }
          } else {
            setSurveySections(INITIAL_SECTIONS);
          }
        });
      } else {
        resetSurvey();
      }
    }
  }, [value]);

  //set preview when view
  useEffect(() => {
    if (id) {
      setTab(TABS[1]);
    } else {
      setTab(TABS[0]);
    }
  }, [id]);

  //reset survey sections
  const resetSurvey = () => {
    setSurveyHeaderImg(null);
    setSurveyHeadLineColor(DEFAULT_LINE_COLOR);
    setSurveyBgColor(DEFAULT_BG_COLOR);
    setSurveySections(INITIAL_SECTIONS);
  };

  //update
  const handleUpdate = () => {
    if (id) {
      const params = {
        id,
        sections: JSON.stringify(surveySections),
        headerImage: surveyHeaderImg,
        headerLineColor: surveyHeadLineColor,
        bgColor: surveyBgColor,
      };
      mutationUpdate.mutate({ survey: params });
    }
  };

  //theme canvas
  const renderQuestionTheme = () => {
    return (
      <Canvas
        isVisible={showSideBar}
        onCloseSideBar={() => setShowSideBar(false)}
        customStyles={{ width: '400px', zIndex: '1052', top: id ? '60px' : 0 }}
        backdropStyles={{ zIndex: '1051' }}
      >
        <Canvas.Body className="pd-0-f">
          <QuestionTheme
            //surveyId={id}
            closeCanvas={setShowSideBar}
            headerImg={surveyHeaderImg}
            themeColor={surveyHeadLineColor}
            bgColor={surveyBgColor}
            onSetSurveyHeaderImg={(newImage: any) => {
              setSurveyHeaderImg(newImage);
              //callback
              const newSurveyData = {
                //id: id,
                headerImg: newImage,
                headerLineColor: surveyHeadLineColor,
                bgColor: surveyBgColor,
                //image: surveyImage,
                sections: surveySections,
              };
              onChange && onChange(newSurveyData);
            }}
            onSetSurveyThemeColor={(lineColor: string, bgColor: string) => {
              setSurveyHeadLineColor(lineColor);
              setSurveyBgColor(bgColor);
              //callback
              const newSurveyData = {
                //id,
                headerImg: surveyHeaderImg,
                headerLineColor: lineColor,
                bgColor: bgColor,
                //image: surveyImage,
                sections: surveySections,
              };
              onChange && onChange(newSurveyData);
            }}
            onSetSurveyBgColor={(newColor: string) => {
              setSurveyBgColor(newColor);
              //callback
              const newSurveyData = {
                //id,
                headerImg: surveyHeaderImg,
                headerLineColor: surveyHeadLineColor,
                bgColor: newColor,
                //image: surveyImage,
                sections: surveySections,
              };
              onChange && onChange(newSurveyData);
            }}
          />
        </Canvas.Body>
      </Canvas>
    );
  };

  //tab view
  const renderTabs = () => {
    return (
      <div className="survey-write-header-form-tab">
        <div className="survey-write-header-form-tab-abs">
          <nav className="nav nav-line survey-write-nav-tab-no-line">
            {TABS.map((item: any, idx: number) => (
              <button
                type="button"
                key={idx}
                className={classNames('btn nav-link', {
                  active: item.value == tab.value,
                })}
                onClick={(e) => {
                  setTab(item);
                }}
              >
                <span className="tx-20 tx-bold">{item.label}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
    );
  };

  //tab content
  const renderTabContent = () => {
    let formHeight =
      tab.value == 1
        ? `calc(100vh - ${minScrollHeight}px)`
        : `calc(100vh - ${minScrollHeight - 80}px)`;
    if (fullScreen) {
      let minFullScrollHeight = minScrollHeight - 75;
      formHeight =
        tab.value == 1
          ? `calc(100vh - ${minFullScrollHeight}px)`
          : `calc(100vh - ${minFullScrollHeight - 80}px)`;
    }
    return (
      <div id="survey-content" className="survey-write-content-container">
        <div
          className="tab-content mg-t-15 scroll-box"
          id="survey-tab-content"
          style={{ height: formHeight }}
        >
          {tab.value == 1 &&
            <QuestionWrite
              storageOn={storageOn}
              questionRef={questionRef}
              surveyId={id}
              surveyHeaderImg={surveyHeaderImg}
              surveyHeadLineColor={surveyHeadLineColor}
              surveyBgColor={surveyBgColor}
              surveyImage={surveyImage}
              value={surveySections}
              onChange={(newValue: any) => {
                setSurveySections(newValue);
                //callback
                const newSurveyData = {
                  //id,
                  headerImg: surveyHeaderImg || '',
                  headerLineColor: surveyHeadLineColor,
                  bgColor: surveyBgColor,
                  //image: surveyImage,
                  sections: newValue,
                };
                onChange && onChange(newSurveyData);
              }}
            />
          }
          {tab.value == 2 && (
            <SurveyView
              id={id} //TODO: for TEST
              name={survey?.name || ''}
              previewData={{
                headerImg: surveyHeaderImg,
                headerLineColor: surveyHeadLineColor,
                bgColor: surveyBgColor,
                //image: surveyImage,
                sections: surveySections,
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const newTitle = surveySections.length > 0 ? surveySections[0].title : '';
  //main render
  return (
    <div className={classNames('pos-relative z-index-10', className)}>
      <div
        className="survey-home-container-flex-gallery"
        style={{ backgroundColor: surveyBgColor }}
      >
        <SurveyHeader
          isPreview={tab.value === 2}
          surveyId={id} //for view
          surveyTitle={newTitle}
          //questionRef={questionRef} //for generate image preview: d2i
          isLoading={mutationUpdate.isLoading || mutationAdd.isLoading} //loading...
          setShowSideBar={setShowSideBar} //open theme canvas
          onReset={resetSurvey}
          onSave={handleUpdate}
        />
        {renderTabs()}
        {renderTabContent()}
        {showSideBar && renderQuestionTheme()}
      </div>
    </div>
  );
};

export default SurveyForm;
