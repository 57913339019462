import React, { useMemo, useState,useEffect } from 'react';
import * as keyNames from '@customer/customer/config/key-names';
import { PhotoViewField } from '@base/containers/view-field';
import { useRecoilValue } from 'recoil';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { MENU_CUSTOMER } from '@base/config/menus';
//import ViewSummary from '@base/containers/view-summary';
import { CreditCard, Search } from 'react-feather';
import { Canvas, SpanLang } from '@base/components';
import CustomerDebit from '@customer/customer/containers/customer-debit';
//import RegisterPaymentForm from '@customer/customer/containers/register-payment-form';
import { moneyFormat } from '@base/utils/helpers';
import Loading from '@base/components/loading';
import { ViewFields } from '@base/components/hook-form/view';
import { Button } from '@base/components/form';
import UploaderImage from '@base/components/form/image-uploader';
import { goParseFields } from '@base/utils/helpers/page-layout-utils';
import { Field } from '@base/components/hook-form';
import ViewFieldsPreview from '../view-field';

interface IViewLeftProps {
  section:any;
}

const ViewLeftPreview: React.FC<IViewLeftProps> = (props: IViewLeftProps) => {
  const { section } = props;
  console.log(section)
  return (
    <div
      className="pos-relative scroll-box"
      style={{ height: 'calc(100vh - 100px)' }}
    >
     <div className="view-aside-section">
        <div className="view-aside-section-header">
          <span className="view-aside-section-header-title">Summary</span>       
        </div>
        <div id="summary" className="view-aside-section-body collapse fade show">
          <div className="form-row mg-x-0 mg-t-10">
           <ViewFieldsPreview fields={section?.children}/>
          </div>
        </div>
      </div>

     
    </div>
  );
};

export default ViewLeftPreview;