import { atom } from 'recoil';

export const ticketFormWriteOptionAtom = atom({
  key: 'TicketFormWriteOptionAtom',
  default: {
    writeType: '',
    writeMenu: 'list',
    isOpenWrite: false,
  },
});
