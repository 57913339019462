import { FormIcon } from '@base/components/form';
import { getCurrentLang } from '@base/services/i18n';
import { IconType } from '@base/types/types/app';
import { ISelection } from '@settings/general-setting/types/interfaces/selection';
import classNames from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';
import './styles.scss';
interface IGroupProps {
  // node expand or not
  expand?: boolean;
  // group id
  id: string;
  // classname
  className?: string;
  // group loading children or not
  isLoading?: boolean;
  isLoaded?: boolean;
  // Add Group item
  onStartAddItem?: (params: any) => void;
  // Delete Group Item
  onDeleteItem?: (params: any) => void;
  onStartEditItem?: (params: any) => void;
  // group level
  level?: number;
  // children
  subGroups?: ISelection[];
  isActive?: boolean;
  icon?: string;
  iconType?: IconType;
  // group name
  label: string;
  isBadge?: boolean;
  badge?: number;
  isEdit?: boolean;
  onEdit?: () => void;
  isDelete?: boolean;
  onDelete?: () => void;
  onClick?: () => void;
  isAdd?: boolean;
  onAdd?: (params: any) => void;
  onExpand?: (params: any) => void;
  onExpandGroup?: (params: any) => void;
}
// const Group = (props: IGroupProps, ref: any) => {
const Group: React.FC<IGroupProps> = (props: IGroupProps) => {
  const {
    expand = false,
    id = '',
    className = '',
    isLoading = false,
    isLoaded = false,
    onStartAddItem = () => {},
    onDeleteItem = () => {},
    onStartEditItem = () => {},
    level = 0,
    subGroups = [],
    isActive = false,
    icon = 'Folder',
    iconType = 'feather',
    label = '',
    isBadge = '',
    badge = '',
    isEdit = false,
    onEdit = () => {},
    isDelete = false,
    onDelete = () => {},
    onClick = () => {},
    isAdd = false,
    onAdd = () => {},
    onExpand = () => {},
    onExpandGroup = () => {},
    ...attrs
  } = props;
  const [loading, setLoading] = useState(false);
  const userLang = getCurrentLang();
  const loadData = (isExpand: boolean) => {
    if (loading) {
      return;
    }

    onExpandGroup({
      loadData: loadData,
      setLoading: setLoading,
      isExpand: isExpand,
    });
  };

  if (isLoading) {
    return (
      <a
        className={classNames(`nav-link ${className} navata-placeholder`)}
        style={{
          paddingLeft: level == 0 ? 10 : level * 20 + 10,
          height: 33,
        }}
      />
    );
  }
  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [isLoaded]);
  return (
    <>
      <a
        // ref={ref}
        className={classNames(`nav-link navata-manage-group ${className}`, {
          active: isActive,
        })}
        onClick={(e) => onClick()}
        style={{
          paddingLeft: level == 0 ? 10 : level * 20 + 10,
          height: 33,
        }}
        {...attrs}
      >
        <i className="icon-expand" onClick={() => loadData(!expand)}>
          {loading ? (
            <div
              style={{ marginRight: 7 }}
              className="spinner-border spinner-border-sm"
              role="status"
            />
          ) : (
            <FormIcon icon={expand ? 'ChevronDown' : 'ChevronRight'} iconType="feather" />
          )}
        </i>
        <FormIcon icon={icon} iconType={iconType} />
        <span>{label}</span>
        <span className="badge">
          {isBadge && badge}
          {isAdd && (
            <span
              className="btn-add mg-r-5"
              onClick={(e) => {
                e.preventDefault();
                onAdd({
                  loadData: loadData,
                  setLoading: setLoading,
                });
              }}
            >
              <FormIcon icon="FolderPlus" iconType="feather" size={16} />
            </span>
          )}
          {isEdit && (
            <span
              className="btn-edit mg-r-5"
              onClick={(e) => {
                e.preventDefault();
                onEdit();
              }}
            >
              <FormIcon icon="Edit3" iconType="feather" size={16} />
            </span>
          )}
          {isDelete && (
            <span
              className="btn-delete"
              onClick={(e) => {
                e.preventDefault();
                onDelete();
              }}
            >
              <FormIcon icon="Trash2" iconType="feather" size={16} />
            </span>
          )}
        </span>
      </a>
      {expand &&
        subGroups?.map((group: any) => {
          let subRef = React.createRef();
          return (
            <Group
              // ref={subRef}
              onDeleteItem={onDeleteItem}
              onStartEditItem={onStartEditItem}
              onStartAddItem={onStartAddItem}
              onExpand={onExpand}
              expand={group.expand}
              id={group.id}
              key={group.id}
              level={level + 1}
              label={group?.language?.[userLang] ?? ''}
              icon={'Folder'}
              iconType={'feather'}
              onClick={() => {}}
              subGroups={group.children}
              isEdit={group.isBase == '1' ? false : true}
              onEdit={() => onStartEditItem({ item: group })}
              isDelete={group.isBase == '1' ? false : true}
              onDelete={() => onDeleteItem({ item: group })}
              isAdd={group.isBase == '1' ? false : true}
              onAdd={({ loadData, setLoading, setIsExpand }: any) =>
                onStartAddItem({
                  item: group,
                  loadData: loadData,
                  setLoading: setLoading,
                  setIsExpand: setIsExpand,
                })
              }
              onExpandGroup={({ loadData, setLoading, setIsExpand, isExpand }: any) =>
                onExpand({
                  item: group,
                  loadData: loadData,
                  setLoading: setLoading,
                  setIsExpand: setIsExpand,
                  isExpand: isExpand,
                })
              }
              isLoading={group.isLoading}
              isLoaded={group.isLoaded}
            />
          );
        })}
    </>
  );
};

export default Group;
