import usePost from '../../../base/hooks/usePost';
import { BaseResponse } from '../../../base/types/interfaces/response';
import { GET_MERGE_FIELDS } from '../services/graphql/personalize';
import { IMergeField } from '../types/interfaces/personalize';

export const useMergeFields = () => {
  const queryKey = ['setting_mergeFields'];
  const response = usePost<BaseResponse<IMergeField>>(queryKey, GET_MERGE_FIELDS, {});
  return response;
};
