import { t } from 'i18next';

export const TICKET_FORM_STAGE_BUILD = 'STAGE_BUILD';
export const TICKET_FORM_STAGE_DISABLE = 'STAGE_DISABLE';
export const TICKET_FORM_STAGE_ENABLE = 'STAGE_ENABLE';

export const TICKET_FORM_STAGES: any = [
  {
    value: TICKET_FORM_STAGE_BUILD,
    label: t('Build'),
  },
  {
    value: TICKET_FORM_STAGE_DISABLE,
    label: t('Disable'),
  },
  {
    value: TICKET_FORM_STAGE_ENABLE,
    label: t('Enable'),
  },
];

export const TICKET_FORM_LINK_TYPE_LANDING_PAGE = 'LINK_TYPE_LANDING_PAGE';
export const TICKET_FORM_LINK_TYPE_SITE = 'LINK_TYPE_SITE';
export const TICKET_FORM_LINK_TYPE_SURVEY = 'LINK_TYPE_SURVEY';

export const TICKET_FORM_LINK_TYPES: any = [
  {
    value: TICKET_FORM_LINK_TYPE_LANDING_PAGE,
    label: t('Landing Page'),
  },
  {
    value: TICKET_FORM_LINK_TYPE_SITE,
    label: t('Site'),
  },
  {
    value: TICKET_FORM_LINK_TYPE_SURVEY,
    label: t('Survey'),
  },
];

export const TICKET_FORM_SUBMISSION_DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_PAGE = 'LINK_TO_PAGE';
export const TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_RESOURCE = 'LINK_TO_RESOURCE';
