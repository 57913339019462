import React, { FC, useEffect, useState } from 'react';
import { SpanLang, Canvas } from '@base/components';
import { Button } from '@base/components/form';
import NoDataIcon from '@base/components/form/icon/nodata';
import CardManagement from '@settings/general-setting/components/card-management';
import ItemManagement from '@settings/general-setting/components/item-management';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import LangInput from '@base/containers/lang-input';
import Loading from '@base/components/loading';
import './styles.scss';
import {
  useListSelectionFieldItems,
  useListSelectionFields,
} from '@settings/general-setting/services/selection-service';
import { ISelection } from '@settings/general-setting/types/interfaces/selection';
import { confirmAlert } from '@base/components/confirm-alert';
import useMutationPost from '@base/hooks/useMutationPost';
import {
  ADD_SELECTION_ITEM,
  UPDATE_SELECTION_ITEM,
  DELETE_SELECTION_ITEM,
  MOVE_SELECTION_ITEMS,
} from '@settings/general-setting/services/graphql/selection';
import { toast } from 'react-toastify';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { updateLanguageI18n } from '@base/services/i18n';
import { nanoid } from '@base/utils/helpers';

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#0168fa80' : 'transparent',
  borderRadius: '0.25rem',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  width: '100%',
});

const ManageFieldsPage: FC<any> = (props: any) => {
  const NAME_ITEM_INIT = [
    { id: 'en', value: '' },
    { id: 'ko', value: '' },
    { id: 'vi', value: '' },
    { id: 'jp', value: '' },
    { id: 'zh', value: '' },
    { id: 'ido', value: '' },
  ];
  const regexCleanString = new RegExp(/[^\w]/gi);
  const [categoryChoosed, setCategoryChoosed] = useState<ISelection>();
  const [openAddItem, setOpenAddItem] = useState(false);
  const [nameItem, setNameItem] = useState(NAME_ITEM_INIT);
  const [itemChoosed, setItemChoosed] = useState<ISelection>({
    id: '',
    keyName: '',
    languageKey: '',
  });
  const [keyName, setKeyName] = useState('');
  const [loading, setLoading] = useState(false);
  const { data: fieldsResponse, isLoading: isFieldLoading } = useListSelectionFields();
  const {
    data: fieldItemsResponse,
    isLoading: isFieldItemLoading,
    refetch,
  } = useListSelectionFieldItems(keyName);
  const [items, setItems] = useState<ISelection[]>([]);
  const mAddSelection: any = useMutationPost<BaseMutationResponse>(
    ADD_SELECTION_ITEM,
    'setting_createSelection',
    {
      onSuccess: (res: any) => {
        // update language in local
        let languageValue: any = {};
        for (const name of nameItem) {
          languageValue[name.id] = name.value;
        }
        let keyName =
          categoryChoosed?.keyName +
          '_' +
          languageValue.en.toLowerCase().replace(regexCleanString, '_');
        let languageKey = 'options_items_' + keyName + '__' + res.id.replaceAll('-', '_');
        updateLanguageI18n(languageKey, languageValue);
        // turn off loading
        setLoading(false);
        // turn off Add modal
        setOpenAddItem(false);
        toast.success('Data was added!');
        refetch();
      },
      onError: (res: any) => {
        setLoading(false);
      },
    },
  );
  const mUpdateSelection: any = useMutationPost<BaseMutationResponse>(
    UPDATE_SELECTION_ITEM,
    'setting_updateSelection',
    {
      onSuccess: (res: any) => {
        setLoading(false);
        setOpenAddItem(false);
        let languageValue: any = {};
        for (const name of nameItem) {
          languageValue[name.id] = name.value;
        }
        let { languageKey } = itemChoosed;
        updateLanguageI18n(languageKey, languageValue);
        toast.success('Data was updated!');
        refetch();
      },
      onError: (res: any) => {
        setLoading(false);
      },
    },
  );
  const mDeleteSelection: any = useMutationPost<BaseMutationResponse>(
    DELETE_SELECTION_ITEM,
    'setting_deleteSelection',
    {
      onSuccess: (res: any) => {
        toast.success('Data has deleted!');
        refetch();
      },
    },
  );
  const mMoveSelection: any = useMutationPost<BaseMutationResponse>(
    MOVE_SELECTION_ITEMS,
    'setting_moveSelections',
    {
      onSuccess: (res: any) => {
        setLoading(false);
        toast.success('Data was updated!');
      },
      onError: (res: any) => {
        setLoading(false);
        refetch();
      },
    },
  );
  //lang
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setLoading(isFieldLoading);
    if (!isFieldLoading && fieldsResponse) {
      // get frist category
      let category = fieldsResponse.results.length ? fieldsResponse.results[0] : null;
      if (category) {
        setKeyName(category.keyName);
        setCategoryChoosed(category);
      }
    }
  }, [fieldsResponse]);

  useEffect(() => {
    setLoading(isFieldItemLoading);
    if (!isFieldItemLoading && fieldItemsResponse) {
      setItems(fieldItemsResponse.results ?? []);
    }
  }, [fieldItemsResponse]);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsNew = reorder(
      fieldItemsResponse?.results,
      result.source.index,
      result.destination.index,
    );
    const ids = itemsNew.map((item) => item.id);
    // setItems(itemsNew);
    setLoading(true);
    mMoveSelection.mutate({ ids: ids });
    setItems(itemsNew);
  };

  const closeAddItem = () => {
    setOpenAddItem(false);
  };

  const onSaveItem = () => {
    if (!categoryChoosed) {
      return;
    }
    setLoading(true);
    let languageValue: any = {};
    for (const name of nameItem) {
      languageValue[name.id] = name.value;
    }
    if (itemChoosed.id) {
      let input = {
        id: itemChoosed.id,
        parentId: categoryChoosed.id,
        languageKey: itemChoosed.languageKey,
        languageData: languageValue,
        keyGroup: 'options_items',
      };
      // update selection item
      mUpdateSelection.mutate({ input });
    } else {
      let keyName = languageValue.en;
      keyName =
        categoryChoosed.keyName + '_' + keyName.toLowerCase().replace(regexCleanString, '_');
      // create new selection
      let input = {
        parentId: categoryChoosed.id,
        languageData: languageValue,
        keyRoot: categoryChoosed.keyName,
        keyName: keyName,
        keyGroup: 'options_items',
      };
      mAddSelection.mutate({ input });
    }
  };

  const onDeleteItem = ({ item, index }: any) => {
    // delete item
    let nItems = items.filter((sel) => {
      return item.id != sel.id;
    });
    mDeleteSelection.mutate({ id: item.id, selectType: 'options_items' });
    setItems(nItems);
  };

  const onStartAddItem = ({ item = { id: '', label: '' }, index }: any) => {
    setNameItem(NAME_ITEM_INIT);
    setItemChoosed(item);
    setOpenAddItem(true);
  };

  const onStartEditItem = ({ item, index }: any) => {
    if (!item) {
      return;
    }
    setNameItem(
      NAME_ITEM_INIT.map((itemName) => {
        return {
          ...itemName,
          value: item.language && item.language[itemName.id] ? item.language[itemName.id] : '',
        };
      }),
    );
    setItemChoosed(item);
    setOpenAddItem(true);
  };

  const onChangeCategory = (category: ISelection) => {
    // console.log(category);
    setLoading(true);
    // load Field item
    setCategoryChoosed(category);
    setKeyName(category.keyName);
  };

  const handleDelete = (data: any) => {
    confirmAlert({
      title: t('crm_new_common_delete'),
      message: t('crm_new_common_delete_msg'),
      buttons: [
        {
          label: 'No',
          className: 'btn-secondary',
        },
        {
          label: 'Yes',
          className: 'btn-primary',
          onClick: () => {
            onDeleteItem(data);
          },
        },
      ],
    });
  };
  return (
    <div className="general-setting-manage-item row">
      {isFieldLoading || isFieldItemLoading ? <Loading /> : ''}
      <div className="col-4">
        <CardManagement
          title={<SpanLang keyLang="crm_new_generalsetting_categories" />}
          isAdd={false}
        >
          <div className="content-categories" style={{ paddingTop: 0 }}>
            <nav className="nav nav-sidebar tx-13">
              {!isFieldLoading &&
                fieldsResponse?.results?.map((category: ISelection) => (
                  <ItemManagement
                    key={category.id}
                    isLoading={false}
                    isActive={categoryChoosed && category.id == categoryChoosed.id}
                    label={t(category.languageKey)}
                    onClick={() => onChangeCategory(category)}
                    icon="Folder"
                    iconType="feather"
                  />
                ))}
            </nav>
          </div>
        </CardManagement>
      </div>
      <div className="col-8">
        <CardManagement
          title={<SpanLang keyLang="crm_new_generalsetting_items" />}
          labelBtn={<SpanLang keyLang="crm_new_generalsetting_add_item" />}
          isAdd={categoryChoosed && categoryChoosed.isBase ? false : true}
          disabled={loading}
          onAdd={() => onStartAddItem({ item: { id: '', label: '' } })}
        >
          <div className="content-items">
            {!isFieldItemLoading && fieldItemsResponse?.results?.length == 0 ? (
              <NoDataIcon
                style={{
                  height: 'calc(100vh - 240px)',
                }}
              />
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`droppable-${nanoid()}`}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <nav className="nav nav-sidebar tx-13">
                        {items.map((item: ISelection, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                <ItemManagement
                                  className="item-field 1"
                                  isLoading={isFieldItemLoading}
                                  label={t(item.languageKey)}
                                  icon={'Move'}
                                  iconType={'feather'}
                                  isDelete={item.isBase ? false : true}
                                  isEdit={item.isBase ? false : true}
                                  onDelete={() =>
                                    handleDelete({
                                      item: item,
                                      index: index,
                                    })
                                  }
                                  onEdit={() =>
                                    onStartEditItem({
                                      item: item,
                                      index: index,
                                    })
                                  }
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </nav>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
          <Canvas
            isVisible={openAddItem}
            onCloseSideBar={closeAddItem}
            className={'layout-sidebar-container'}
            width="auto"
          >
            <Canvas.Header title={<SpanLang keyLang="crm_new_generalsetting_add_item" />} />
            <Canvas.Body>
              <div className="container-canvas pd-20">
                <LangInput
                  uid={'name_category'} //DOM unique key in one page
                  disabled={false}
                  value={nameItem} //ex: [{id, value}]
                  onChange={(data) => setNameItem(data)}
                />
              </div>
            </Canvas.Body>
            <Canvas.Footer onClose={closeAddItem} onSave={onSaveItem} saveLoading={loading} />
          </Canvas>
        </CardManagement>
      </div>
    </div>
  );
};
export default ManageFieldsPage;
