import { lazy } from 'react';

export const ProductView = lazy(
  () => import('@product/product/containers/view-field/product-auto-complete'),
);
export const LanguageSelectView = lazy(() => import('@base/containers/view-field/language-select'));
export const LandingPageTypeSelectView = lazy(
  () => import('@settings/online-digital-content/landing-page/containers/view-field/type-view'),
);
export const PublishDateView = lazy(
  () =>
    import('@settings/online-digital-content/landing-page/containers/view-field/publish-date-view'),
);
export const PublishView = lazy(
  () => import('@settings/online-digital-content/landing-page/containers/view-field/publish-view'),
);
