import React, { FC } from 'react';

import { isFunction, isUndefined } from 'lodash';

import ListGridItemBase, {
  defaultColumnRender,
  ListGridItemBody,
} from '@base/components/list/list-grid/list-grid-item';
import {
  DEFAULT_CATEGORY,
  MAIN_MENU,
  PRIMARY_KEY,
} from '@settings/template/config/pages/main-page';
import { ListPageConfig } from '@settings/template/config/pages/list-page';
import GridItemFooter from '../list-grid-footer';
import ListGridItemHeader from './header';

export interface IListGridItem {
  primaryKey: string | number;
  data: any;
  isSplitMode: boolean;
  onCheckedRow: (checkedValue: string | number) => void;
  selectedIds: string[];
  columns: {
    name: string;
    title: string;
  }[];
  columnsOnSplitMode: {
    [key: string]: {
      icon?: React.ReactNode;
      isTag: boolean;
    };
  };
  columnRender?: (col: string) => any;
  templateType: string;
  templateGroup: string;
  onDelete: (id: string) => void;
  onOpenEdit: (id: string) => void;
  onEdit: (params: any) => void;
  onAfterSaved: (data: any) => void;
}

const ListGridItem: FC<IListGridItem> = ({
  data,
  isSplitMode,
  onCheckedRow,
  selectedIds,
  columns,
  columnRender,
  templateType,
  templateGroup,
  onDelete,
  onEdit,
  onOpenEdit,
  onAfterSaved,
}: IListGridItem) => {
  const title = ListPageConfig.getTitle(data, isSplitMode);
  const src = data?.templateThumbnail ?? '';
  return (
    <ListGridItemBase
      primaryKey={ListPageConfig.primaryKey}
      data={data}
      isSplitMode={isSplitMode}
      onCheckedRow={onCheckedRow}
      selectedIds={selectedIds}
      photoRender={{
        src: src,
        alt: title ?? 'No title',
      }}
      listItemHeaderComponent={
        // <ListGridItemHeader
        //   id={data.id}
        //   title={title}
        //   titleLink={`/settings/${MAIN_MENU}/${templateType}/${templateGroup}/${data.id}`}
        //   isSplitMode={isSplitMode}
        //   onTitleEdited={(nTitle: string) => {
        //     data.title = nTitle;
        //     onAfterSaved && onAfterSaved(data);
        //   }}
        //   EditComponent={EditTitle}
        // />
        <ListGridItemHeader
          id={data.id}
          title={title}
          titleLink={`/settings/${MAIN_MENU}/${templateType}/${templateGroup}/${data.id}`}
          onAfterSaved={(nTitle: string) => {}}
        />
      }
    >
      <ListGridItemBody isSplitMode={isSplitMode}>
        <>
          {columns &&
            columns.map((col: any, i: number) => {
              const columnComponent =
                isFunction(columnRender) && columnRender(col.name)
                  ? columnRender(col.name)(col.name, data)
                  : defaultColumnRender(col.name, data);

              return (
                <div className="grid-list-item-col" key={i}>
                  <span className="grid-list-item-col-title">{col.title}:</span>
                  {col?.dataType && col.dataType === 'text' ? (
                    <span className="grid-list-item-col-content">{columnComponent}</span>
                  ) : (
                    columnComponent
                  )}
                </div>
              );
            })}
        </>
      </ListGridItemBody>
      <GridItemFooter onDelete={onDelete} onEdit={onOpenEdit} row={data} />
    </ListGridItemBase>
  );
};

ListGridItem.defaultProps = {
  primaryKey: PRIMARY_KEY,
};

export default React.memo(ListGridItem);
