import { useAssignmentRulesList } from '@settings/assignment-rule/services/service';
import * as keyNames from '@settings/assignment-rule/config/key-names';
import { Switch } from '@base/components/form';
import DeleteAssignTo from '@settings/assignment-rule/containers/delete-assign-to';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  IARAssignToItem,
  IAssignMentRule,
  IARRuleEntryItem,
} from '@settings/assignment-rule/types/interfaces';
import { AR_MODULE_OPTIONS } from '../constant';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { User } from '@base/types/interfaces/user';
import { Edit, Edit2 } from 'react-feather';
/** LIST PAGE CONFIG */
export const ListPageConfig = {
  primaryKey: 'id',
  nameKey: 'name',
  fields: [
    {
      keyName: 'name',
      languageKey: 'Rule Name',
      disableSortBy: true,
    },
    {
      keyName: 'module',
      languageKey: 'Module',
      disableSortBy: true,
    },
    {
      keyName: 'description',
      languageKey: 'Description',
      disableSortBy: true,
    },
    {
      keyName: 'createdBy',
      languageKey: 'Created By',
      disableSortBy: true,
    },
    {
      keyName: 'createdAt',
      languageKey: 'Created On',
      disableSortBy: true,
    },
    {
      keyName: 'active',
      languageKey: 'Active',
      disableSortBy: true,
    },
  ],
  useFectchList: useAssignmentRulesList,
  getColumnRenderRemap: (extraParams: any) => {
    const { onActive, goView } = extraParams;
    return {
      /*[keyNames.KEY_NAME_ASSIGNMENT_RULE_NAME](col: string, data: any) {
        return data.name ? (
          <a className="cursor-pointer" onClick={() => goView(data)}>
            {data.name}
          </a>
        ) : (
          ''
        );
      },*/
      [keyNames.KEY_NAME_ASSIGNMENT_RULE_NAME](col: string, data: any) {
        let name = data[col] ? data[col] : '';
        let sourceId = data.id ? data.id : '';
        return <Link to={`/settings/assignment-rule/${sourceId}`}>{name}</Link>;
      },
      [keyNames.KEY_NAME_ASSIGNMENT_RULE_MODULE](col: string, data: any) {
        var valKey = data[col];
        var moduleSelect = '';
        AR_MODULE_OPTIONS.map((m) => {
          if (valKey == m.keyName) {
            moduleSelect = m.languageKey;
          }
        });

        return moduleSelect;
      },
      [keyNames.KEY_NAME_ASSIGNMENT_RULE_DESC](col: string, data: any) {
        return data[col] ?? '';
      },
      [keyNames.KEY_NAME_ASSIGNMENT_RULE_CREATED_BY](col: string, data: any) {
        return data[col] ?? '';
      },
      [keyNames.KEY_NAME_ASSIGNMENT_RULE_CREATED_AT](col: string, data: any) {
        return data[col] ?? '';
      },
      [keyNames.KEY_NAME_ASSIGNMENT_RULE_ACTIVE](col: string, data: any) {
        return (
          <Switch value={data.active} onChange={(nVal: any) => onActive(!data.active, data)} />
        );
      },
    };
  },
  getAssignToColumnRenderRemap: (extraParams: any) => {
    const { onAfterDeleted } = extraParams;
    return {
      ['group'](col: string, data: IARAssignToItem) {
        return data.assignTo?.group?.name ?? '';
      },
      ['user'](col: string, data: IARAssignToItem) {
        return data.assignTo?.user?.name ?? '';
      },
      ['capacity'](col: string, data: IARAssignToItem) {
        return data.capacity ?? 0;
      },
      ['action'](col: string, data: IARAssignToItem) {
        return <DeleteAssignTo data={data} onAfterDeleted={onAfterDeleted} />;
      },
    };
  },
  assignToFields: [
    {
      keyName: 'group',
      languageKey: 'Group Name',
      disableSortBy: true,
    },
    {
      keyName: 'user',
      languageKey: 'User name',
      disableSortBy: true,
    },
    {
      keyName: 'capacity',
      languageKey: 'Capacity',
      disableSortBy: true,
    },
    {
      keyName: 'action',
      languageKey: 'Action',
      disableSortBy: true,
    },
  ],
  getRuleEntryColumnRenderRemap: (extraParams: any) => {
    const { onAfterDeleted, onOpenEdit } = extraParams;
    return {
      ['order'](col: string, data: IARRuleEntryItem) {
        return data.order ?? 0;
      },
      ['criteria'](col: string, data: IARRuleEntryItem) {
        if (data.criteria) {
          return data.criteria.map((m, index) => {
            let value: any = '';
            let label = m.key;
            if (m.condition) {
              const condition = JSON.parse(m.condition);
              if (m.key == 'channel') {
                label = 'Channel';
                value = condition.name;
              } else if (
                m.key == 'ticket_classification' &&
                condition.region &&
                condition.language
              ) {
                label = 'Ticket Classification';
                value = condition.region.name + '/' + condition.language.name;
              } else if (m.key == 'category' && condition.product && condition.category) {
                label = 'Category';
                value = condition.product.name + '/' + condition.category.name;
              } else if (m.key == 'tag' && condition) {
                label = 'Tags';
                let values: string[] = [];
                condition.map((m2: any) => {
                  values.push(m2.name);
                });
                value = values.join(', ');
              }
            }
            if (value) {
              return (
                <div key={index}>
                  <div className="tag-item">{label + ': ' + value}</div>
                  <br></br>
                </div>
              );
            } else {
              return null;
            }
          });
        }
        return '';
      },
      ['assignTo'](col: string, data: IARRuleEntryItem) {
        var items: User[] = [];
        if (data.assignTo?.assignsTo) {
          data.assignTo.assignsTo.map((m) => {
            if (m.user) {
              items.push(m.user);
            }
          });
        }

        return items.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={items}
          />
        ) : (
          '(none)'
        );
      },
      ['action'](col: string, data: IARAssignToItem) {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <button
              onClick={() => onOpenEdit(data)}
              type="button"
              className="btn btn-link btn-icon pd-y-0"
            >
              <Edit2 className="tx-info" />
            </button>
            <DeleteAssignTo data={data} onAfterDeleted={onAfterDeleted} />
          </div>
        );
      },
    };
  },
  ruleEntryFields: [
    {
      keyName: 'order',
      languageKey: 'Order',
      disableSortBy: false,
    },
    {
      keyName: 'criteria',
      languageKey: 'Criteria',
      disableSortBy: true,
    },
    {
      keyName: 'assignTo',
      languageKey: 'Assign to',
      disableSortBy: true,
    },
    {
      keyName: 'action',
      languageKey: 'Action',
      disableSortBy: true,
    },
  ],
  hideColumns: [],
};
