import React from 'react';
import '../styles.scss';

interface IPlaceholderItemProps {}
const PlaceholderItem: React.FC<IPlaceholderItemProps> = (props: IPlaceholderItemProps) => {
  return (
    <div className="page-layout-placeholder">
      <div className="d-flex align-items-center" style={{ flex: 1, height: '100%' }}>
        <div className="view-icon navata-placeholder"></div>
        <span className="pd-l-6 text-description navata-placeholder"></span>
      </div>
    </div>
  );
};

export default PlaceholderItem;
