import { selector } from 'recoil';
import statusAtom from '../status';
import stepTypeAtom, { stepAddOpenAtom } from './atom';

const stepWithClose = selector({
  key: 'stepWithClose',
  get: () => {},
  set: ({ reset }) => {
    reset(stepTypeAtom);
    reset(stepAddOpenAtom);
    reset(statusAtom);
  },
});

export default stepWithClose;
