import * as commonConfig from '@base/config/view-field';
import * as components from './components';
import * as commonComponents from '@base/config/view-field/components';
import validators from '@base/utils/validation/field-validator'; //all validate functions
import { IFieldConfig } from '@base/types/interfaces/common';
import * as keyNames from '@settings/online-digital-content/ticket-form/config/key-names';

const Config: IFieldConfig = {
  ...commonConfig?.default,
  [keyNames.KEY_TICKET_FORM_PRODUCTS]: {
    component: components.ProductView,
    schema: `products {
      id
      name
    }
    isAllProducts
    `,
    componentProps: {
      showAllOption: true,
      single: false,
    },
    viewProps: {
      clickIconToEdit: true,
    },
    getValue: (apiData: any) => {
      return {
        isAllProducts: apiData?.isAllProducts,
        products: apiData?.products,
      };
    },
    getValueView: (value: any) => {
      if (value.isAllProducts) {
        return [{ value: 'all', label: 'All Products', id: 'all', name: 'All Products' }];
      } else {
        return value?.products?.length
          ? value?.products?.map((v: any) => ({ ...v, value: v?.id, label: v?.name }))
          : [];
      }
    },
    getValueEdit: (value: any) => {
      if (value.isAllProducts) {
        return { value: 'all', label: 'All' };
      } else {
        return value?.products?.length > 0
          ? value?.products?.map((v: any) => ({ ...v, value: v?.id, label: v?.name }))
          : [];
      }
    },
    getMutationValue: (value: any) => {
      return {
        products: value?.length ? value?.map((v: any) => ({ id: v?.value, name: v?.label })) : [],
      };
    },
    getChangedValue: (value: any) => {
      return {
        isAllProducts: value?.findIndex((v: any) => v.value === 'all') >= 0 ? true : false,
        products: value,
      };
    },
  },
  [keyNames.KEY_TICKET_FORM_LANGUAGE]: {
    component: components.LanguageSelectView,
    schema: `language`,
    componentProps: {
      options: [],
    },
    getValue: (apiData: any) => {
      return apiData?.[keyNames.KEY_TICKET_FORM_LANGUAGE];
    },
    getValueView: (value: any) => {
      return value;
    },
  },
  [keyNames.KEY_TICKET_FORM_VIEWS]: {
    schema: ``,
  },
  [keyNames.KEY_TICKET_FORM_SUBMISSION]: {
    schema: ``,
  },
  [keyNames.KEY_TICKET_FORM_SUBMISSION_RATE]: {
    schema: ``,
  },
  [keyNames.KEY_TICKET_FORM_SUBMISSION_DISPLAY]: {
    schema: `submissionDisplay
    displayMessage
    linkToPage
    linkToType
    linkToResource {
      id
      name
    }
    `,
    component: components.SubmissionDisplayView,
    getValue: (apiData: any) => {
      return {
        submissionDisplay: apiData?.submissionDisplay,
        displayMessage: apiData?.displayMessage,
        linkToPage: apiData?.linkToPage,
        linkToType: apiData?.linkToType,
        linkToResource:
          apiData?.linkToResource?.id != ''
            ? {
                ...apiData?.linkToResource,
                value: apiData?.linkToResource?.id,
                label: apiData?.linkToResource?.name,
              }
            : null,
      };
    },
    getMutationValue: (value: any) => {
      return {
        submissionDisplay: value?.submissionDisplay,
        displayMessage: value?.displayMessage,
        linkToPage: value?.linkToPage,
        linkToType: value?.linkToType,
        linkToResource: value?.linkToResource?.value
          ? {
              id: value?.linkToResource?.value,
              name: value?.linkToResource?.label,
            }
          : null,
      };
    },
  },
  [keyNames.KEY_TICKET_FORM_SUBMISSION_BEHAVIOR]: {
    schema: `createTicket
    ticketName
    `,
    component: components.SubmissionBehaviorView,
    getValue: (apiData: any) => {
      return {
        createTicket: apiData?.createTicket,
        ticketName: apiData?.ticketName,
      };
    },
    getMutationValue: (value: any) => {
      return {
        createTicket: value?.createTicket,
        ticketName: value?.ticketName,
      };
    },
  },
  [keyNames.KEY_TICKET_FORM_HTML]: {
    schema: `html`,
    component: null,
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_TICKET_FORM_HTML]: JSON.stringify(value),
      };
    },
  },
};

export default Config;
