import TextEditable from '@base/components/form/text-editable';
import ClickOutside from '@base/components/han-click-outside';
import { HoverEditContainer, ViewContainer } from '@base/containers/view-field/common/style';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_ASSIGNMENT_GROUP } from '@settings/preferences/services/graphql';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Edit2 } from 'react-feather';
import { toast } from 'react-toastify';

interface IGroupNameProps {
  name: string;
  canEdit?: boolean;
  id: string;
  onAfterSaved: (nVal: string) => void;
}
const GroupName: React.FC<IGroupNameProps> = (props: IGroupNameProps) => {
  const { name, canEdit = true, id, onAfterSaved } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const mUpdateTemplate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_ASSIGNMENT_GROUP,
    'setting_updateAssignmentGroup',
  );
  const onSave = (nVal: string) => {
    if (nVal != '') {
      setIsSaving(true);
      mUpdateTemplate.mutate(
        { group: { id: id, name: nVal } },
        {
          onSuccess: (res: any) => {
            setIsSaving(false);
            setIsEdit(false);
            toast.success('Data has updated!');
            onAfterSaved && onAfterSaved(nVal);
          },
          onError: (res: any) => {
            setIsSaving(false);
          },
        },
      );
    }
  };
  const handleOnEdit = () => {
    if (canEdit) {
      setIsEdit(true);
    }
  };
  const onClose = () => {
    setIsEdit(false);
  };
  return (
    <>
      <div className="wd-100p">
        <HoverEditContainer>
          {isEdit ? (
            <ClickOutside
              onClickOutsite={() => onClose()}
              onClickInsite={() => {
                // canEdit() && setIsEdit(true);
              }}
            >
              <TextEditable
                value={name}
                onClose={() => {
                  setIsEdit(false);
                }}
                onSave={onSave}
                isSaving={isSaving}
                setIsSaving={setIsSaving}
              />
            </ClickOutside>
          ) : (
            <ViewContainer className={classNames('wd-100p')} onClick={handleOnEdit}>
              {name}
              <Edit2 className="mg-l-5 icon-edit" size={16} color="#8392a5" />
            </ViewContainer>
          )}
        </HoverEditContainer>
      </div>
    </>
  );
};

export default GroupName;
