import { lazy } from 'react';

const MainPage = lazy(() => import('@settings/billing-license/pages/main'));

export default [
  {
    path: '/settings/billing-license/*',
    component: MainPage,
  },
];
