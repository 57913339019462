// import * as keyNames from '@desk/ticket/config/data-types';
import * as keyNames from '@settings/template/config/key-names';
import * as components from './components';
import * as commonComponents from '@base/config/write-field/components';
import { IWriteConfig } from '@base/types/interfaces/common';

const meetingWriteConfig: IWriteConfig = {
  [keyNames.KEY_TEMPLATE_NAME]: {
    component: commonComponents.Input,
    componentProps: { type: 'text' },
    validate: { require: true },
    defaultValue: '',
  },
  [keyNames.KEY_TEMPLATE_CONTENT]: {
    component: commonComponents.Editor,
    componentProps: {},
    validate: { require: true },
    defaultValue: '',
  },
};
export default meetingWriteConfig;
