import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  X,
  AlertTriangle,
} from 'react-feather';
import { SpanLang } from '@base/components';
import QuestionIcon from '../../symbol';

//render question options
const OptionGridWrite: React.FC<any> = (props) => {
  //props
  const {
    focusS,
    focusQ,
    keyS,
    keyQ,
    focusEle,
    type = '',
    optionsQ = [],
    isValid,
    onRowOptionValueChange,
    onRemoveRowOption,
    onColOptionValueChange,
    onRemoveColOption,
    onAddRowOption,
    onAddColOption
  } = props;
  const { t } = useTranslation();
  //state

  /** ================================== HANDLER ========================================== */

  /** ================================== RENDER ========================================== */
  //for grid
  const renderRowOption = (opt: any, idx: number) => {
    let no = idx + 1;
    const qRowOptionInputEleId = 'q-rowopt-' + keyS + '-' + (keyQ + 1) + '-' + (idx + 1);
    const qRowRemoveOptionEleId = 'q-removerowopt-' + keyS + '-' + (keyQ + 1) + '-' + (idx + 1);

    return (
      <div key={idx} className="d-flex flex-row pd-10 align-items-center">
        <div className="survey-write-question-card-list-item-text">
          <span>{no + '.'}</span>
          <input
            autoComplete='off'
            type="text"
            id={qRowOptionInputEleId}
            className="form-control"
            placeholder={t('Row') + " " + (idx + 1)}
            value={opt.value}
            onChange={(e) => onRowOptionValueChange(e, idx)}
            //onBlur={(e) => onRowOptionValueBlur(e, idx)}
            autoFocus={qRowOptionInputEleId === focusEle}
          />
        </div>
        {/* duplicated option name */}
        {focusS === keyS && focusQ === (keyQ + 1)
          && (isValid && isValid.type === 'rows' && isValid.id === idx && !isValid.value)
          && <span className="pd-r-5" data-toggle="tooltip" data-placement="bottom" title="Duplicated option">
            <AlertTriangle className="tx-danger" />
          </span>
        }
        {/* remove action */}
        {focusS === keyS && focusQ === (keyQ + 1) &&
          <div className="survey-write-question-card-list-item-action">
            <a id={qRowRemoveOptionEleId} className="survey-home-header-button mg-r-10 cursor-default"
              onClick={() => onRemoveRowOption(idx)}
            >
              <X />
            </a>
          </div>
        }
      </div>
    )
  }

  //for grid
  const renderColOption = (opt: any, idx: number) => {
    const qColOptionInputEleId = 'q-colopt-' + keyS + '-' + (keyQ + 1) + '-' + (idx + 1);
    const qColRemoveOptionEleId = 'q-removecolopt-' + keyS + '-' + (keyQ + 1) + '-' + (idx + 1);
    return (
      <div key={idx} className="d-flex flex-row pd-10 align-items-center">
        <div className="survey-write-question-card-list-item-text">
          <QuestionIcon type={type} />
          <input
            autoComplete='off'
            type="text"
            id={qColOptionInputEleId}
            className="form-control"
            placeholder={t('Column') + " " + (idx + 1)}
            value={opt.value}
            onChange={(e) => onColOptionValueChange(e, idx)}
            //onBlur={(e) => onColOptionValueBlur(e, idx)}
            autoFocus={qColOptionInputEleId === focusEle}
          />
        </div>
        {/* duplicated option name */}
        {focusS === keyS && focusQ === (keyQ + 1)
          && (isValid && isValid.type === 'cols' && isValid.id === idx && !isValid.value)
          && <span className="pd-r-5" data-toggle="tooltip" data-placement="bottom" title="Duplicated option">
            <AlertTriangle className="tx-danger" />
          </span>
        }
        {/* remove action */}
        {focusS === keyS && focusQ === (keyQ + 1) &&
          <div className="survey-write-question-card-list-item-action">
            <a id={qColRemoveOptionEleId} className="survey-home-header-button mg-r-10 cursor-default"
              onClick={() => onRemoveColOption(idx)}
            >
              <X />
            </a>
          </div>
        }
      </div>
    )
  }

  //for grid
  const renderRowAddOption = () => {
    const no = optionsQ.rows.length + 1;
    const qAddRowOptEleId = 'q-addrowopt-' + keyS + '-' + (keyQ + 1);

    return (
      <div className="survey-write-question-card-list-item">
        <div className="survey-write-question-card-list-item-text">
          <div className="survey-write-question-card-list-item-inline pd-t-5">
            <span>{no + '.'}</span>
            <a id={qAddRowOptEleId} onClick={onAddRowOption} className="survey-write-question-card-list-item-link pd-l-15">
              <SpanLang keyLang={'Add row'} />
            </a>
          </div>
        </div>
      </div>
    );
  }

  //for grid
  const renderColAddOption = () => {
    const qAddColOptEleId = 'q-addcolopt-' + keyS + '-' + (keyQ + 1);
    return (
      <div className="survey-write-question-card-list-item">
        <div className="survey-write-question-card-list-item-text">
          <div className="survey-write-question-card-list-item-inline pd-t-5">
            <QuestionIcon type={type} />
            <a id={qAddColOptEleId} onClick={onAddColOption} className="survey-write-question-card-list-item-link pd-l-15">
              <SpanLang keyLang={'Add Column'} />
            </a>
          </div>
        </div>
      </div>
    );

  }

  //render
  return (
    <div className="survey-write-question-card-list-container pd-t-10">
      {/* rows */}
      <div className="survey-write-question-card-list-col">
        <div className="survey-write-question-card-list">
          <div className="survey-write-question-card-list-item">
            <div className="survey-write-question-card-list-item-text">
              <SpanLang keyLang={'Rows'} />
            </div>
          </div>
          {optionsQ?.rows?.map((opt: any, idx: number) => renderRowOption(opt, idx))}
          {/* add option button */}
          {optionsQ?.rows && renderRowAddOption()}
        </div>
      </div>
      {/* cols */}
      <div className="survey-write-question-card-list-col">
        <div className="survey-write-question-card-list">
          <div className="survey-write-question-card-list-item">
            <div className="survey-write-question-card-list-item-text">
              <SpanLang keyLang={'Columns'} />
            </div>
          </div>
          {optionsQ?.cols?.map((opt: any, idx: number) => renderColOption(opt, idx))}
          {/* add option button */}
          {focusQ === (keyQ + 1) && optionsQ?.cols &&
            renderColAddOption()
          }
        </div>
      </div>
    </div>
  );
}

export default OptionGridWrite;