import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { Plus } from 'react-feather';
import { useNextStepAtomValue } from '@settings/process/recoil/diagram';
import {
  Direction,
  ShapeType,
  Edge,
  Node,
  NodeType,
  MultipleType,
} from '@settings/process/types/diagram';
import React from 'react';
import SelectBox from '@base/containers/view-field/selectbox/edit';
import { OptionValue } from '@base/types/interfaces/common';
import { useQueryClient } from '@tanstack/react-query'; //v4
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_STEP_STATUS } from '@settings/process/services/process';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { stepAddOpenAtom } from '../../../recoil/step';

interface EdgeAddProps {
  style: {
    left: string;
    top?: string;
    bottom?: string;
    marginLeft: string;
    marginTop?: string;
    marginBottom?: string;
  };
  className?: string;
  sourceId: string;
  sourceType: NodeType;
  shape: ShapeType;
  edge: Edge;
  addType?: string;
  stepHelper: any;
}

function EdgeAdd(props: EdgeAddProps) {
  const {
    style,
    className,
    sourceId,
    edge: { sourceDirection: direction, id: edgeId, primary, multiple, position, shape },
    sourceType,
    addType = 'default',
    stepHelper,
  } = props;
  const { id: processId } = useParams();
  const setStepOpening = useSetRecoilState(stepAddOpenAtom);
  const nextSteps = useNextStepAtomValue();
  const [showMore, setShowMore] = useState(false);

  const onStepOpen = useCallback(() => {
    // // console.log('position', props);
    setStepOpening({
      edit: false,
      sourceId: sourceId,
      directionId: edgeId,
      direction: direction,
      sourceType: sourceType,
      multiple: multiple ?? 'MULTIPLE_NONE',
      primary: primary,
      position: position,
      shape: shape,
    });
    setShowMore(false);
  }, [sourceId]);

  const queryClient = useQueryClient();
  const mutationEdit: any = useMutationPost<BaseMutationResponse>(
    UPDATE_STEP_STATUS,
    'process_updateStatus',
    {
      onMutate: () => {
        setShowMore(false);
      },
      onSuccess: (data: any) => {
        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', processId]);
        }, 500);
        // console.log('success data', data);
        toast.success('Update next step successfully!');
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Update next step failed');
      },
    },
  );

  const onChangeNextStep = useCallback((nextStep: OptionValue) => {
    let nextDireciton: Direction = 'DIRECTION_FORWARD_INCOMING_MIDDLE';
    let split: MultipleType = 'MULTIPLE_NONE';
    if (direction == 'DIRECTION_BACKWARD_OUTGOING_FORWARD') {
      nextDireciton = 'DIRECTION_BACKWARD_INCOMING_MIDDLE';
      split = 'MULTIPLE_LINK';
    }
    mutationEdit.mutate({
      id: processId,
      stepId: sourceId,
      status: {
        id: edgeId,
        nextStep: {
          id: nextStep.keyName,
          name: nextStep.languageKey,
        },
        // nextDirection: nextDireciton,
        multiple: split,
      },
    });
  }, []);

  let hasMultiple = false;
  let nextStepOptions = nextSteps;
  if (direction == 'DIRECTION_FORWARD_OUTGOING_FORWARD') {
    if (multiple == 'MULTIPLE_NONE') {
      const backwardNodes = useMemo(() => stepHelper.backwardMultiple(sourceId), [sourceId]);
      // // console.log('check Multiple backward', backwardNodes);
      const lastOne = backwardNodes[backwardNodes.length - 1];
      if (lastOne && lastOne.property !== 'PROPERTY_START') {
        const lastEdge = stepHelper.findPrimary(lastOne);
        if (lastEdge) {
          if (lastEdge.target) {
            const targetId = lastEdge.target;
            // console.log('lastEdge', lastEdge);
            const forwardNodes = useMemo(() => stepHelper.forwardMultiple(targetId), [targetId]);
            // console.log('forwardNodes', forwardNodes);
            // exclude first step
            if (forwardNodes.length > 1) {
              hasMultiple = true;
              nextStepOptions = forwardNodes.slice(1, forwardNodes.length - 1).map((node: Node) => {
                return { keyName: node.id, languageKey: node.data.label };
              });
            }
          }
        }
      }
    } else {
      // get steps with multiple
      // step position >= edge position
      const multipleSteps = useMemo(() => stepHelper.steps(sourceId), [sourceId]);
      // // console.log('multiple steps', multipleSteps);
    }
  }

  return (
    <>
      {addType == 'default' && !hasMultiple && (
        <button
          type="button"
          className={classNames('btn btn-add', className)}
          // data-han-tooltip="Forward"
          style={{ ...style }}
          onClick={onStepOpen}
        >
          <Plus />
          <span className="sr-only">Add step</span>
        </button>
      )}
      {(addType == 'more' || hasMultiple) && (
        <>
          <button
            type="button"
            className={classNames('btn btn-add', className)}
            style={{ ...style }}
            onClick={() => setShowMore((cur) => !cur)}
          >
            <Plus />
            <span className="sr-only">Add step or choose next step</span>
          </button>
          {showMore && (
            <div
              className="pos-absolute wd-200 fade show z-index-200"
              style={
                direction == 'DIRECTION_BACKWARD_OUTGOING_FORWARD'
                  ? { left: '-120px', top: '-15px' }
                  : { left: '30px', top: '-15px' }
              }
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content tx-14">
                  <div className="modal-header pd-10">
                    <h6 className="modal-title">Choose</h6>
                    <button
                      type="button"
                      className="close"
                      onClick={() => setShowMore((cur) => !cur)}
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body pd-5 d-flex flex-column">
                    <button
                      type="button"
                      className="dropdown-item mg-t-5 mg-b-5"
                      onClick={() => onStepOpen()}
                    >
                      New Step
                    </button>
                    <SelectBox
                      value={{ keyName: '', languageKey: 'Select Next Step' }}
                      onChange={(newValue: OptionValue) => onChangeNextStep(newValue)}
                      options={nextStepOptions}
                      isSearchable={false}
                      errors={{}}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default EdgeAdd;
