import { Axis, ShapeType } from '@settings/process/types/diagram';
import {
  Direction,
  MultipleType,
  Stage,
  NodeType,
  NodeEdges,
} from '@settings/process/types/diagram';
import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { OptionValue } from '@base/types/interfaces/common';

const diagramDetailAtom = atom({
  key: 'diagramDetailAtom',
  default: false,
});

export const useDiagramDetailAtomState = () => {
  return useRecoilState(diagramDetailAtom);
};

export const useDiagramDetailAtomValue = () => {
  return useRecoilValue(diagramDetailAtom);
};

const showStepActionAtom = atom({
  key: 'showStepActionAtom',
  default: {
    id: '',
    show: false,
  },
});

export const useShowStepActionAtomState = () => {
  return useRecoilState(showStepActionAtom);
};

export const useShowStepActionAtomValue = () => {
  return useRecoilValue(showStepActionAtom);
};

// export type StepAddValue = {
//   edit: boolean;
//   sourceId: string;
//   directionId?: string;
//   multiple?: MultipleType;
//   primary?: boolean;
//   direction: Direction;
//   sourceType: NodeType;
//   position: Axis;
//   shape: ShapeType;
// };

// const stepAddOpenAtom = atom<StepAddValue>({
//   key: 'stepAddOpenAtom',
//   default: {
//     edit: false,
//     sourceId: '',
//     directionId: '',
//     direction: 'DIRECTION_NONE',
//     sourceType: 'TYPE_NONE',
//     shape: 'SHAPE_NONE',
//     position: {
//       x: 0,
//       y: 0,
//     },
//   },
// });

// export const useStepAddOpenAtomState = () => {
//   return useRecoilState(stepAddOpenAtom);
// };

// export const useStepAddOpenAtomValue = () => {
//   return useRecoilValue(stepAddOpenAtom);
// };

// export const useStepAddOpenAtomReset = () => {
//   return useResetRecoilState(stepAddOpenAtom);
// };

// const stepViewOpenAtom = atom({
//   key: 'stepViewOpenAtom',
//   default: {
//     sourceId: '',
//   },
// });

// export const useStepViewOpenAtomState = () => {
//   return useRecoilState(stepViewOpenAtom);
// };

// export const useStepViewOpenAtomValue = () => {
//   return useRecoilValue(stepViewOpenAtom);
// };

export type StageOpenValue = {
  sourceId: string;
  stage: Stage | null;
};

const stageOpenAtom = atom<StageOpenValue>({
  key: 'stageOpenAtom',
  default: {
    sourceId: '',
    stage: null,
  },
});

export const useStageOpenAtomState = () => {
  return useRecoilState(stageOpenAtom);
};

export const useStageOpenAtomValue = () => {
  return useRecoilValue(stageOpenAtom);
};

const stageAtom = atom<Stage[]>({
  key: 'stageAtom',
  default: [],
});

export const useStageAtomState = () => {
  return useRecoilState(stageAtom);
};

export const useStageAtomValue = () => {
  return useRecoilValue(stageAtom);
};

const nextStepAtom = atom<OptionValue[]>({
  key: 'nextStepAtom',
  default: [],
});

export const useNextStepAtomState = () => {
  return useRecoilState(nextStepAtom);
};

export const useNextStepAtomValue = () => {
  return useRecoilValue(nextStepAtom);
};

const nodeEdgesAtom = atom<NodeEdges>({
  key: 'nodeEdgesAtom',
  default: { others: {}, closed: {} },
});

export const useNodeEdgesAtomState = () => {
  return useRecoilState(nodeEdgesAtom);
};

export const useNodeEdgesAtomValue = () => {
  return useRecoilValue(nodeEdgesAtom);
};

export type ClosedOpenValue = {
  sourceId: string | undefined;
  open: boolean;
};

const closedOpenAtom = atom<ClosedOpenValue>({
  key: 'closedOpenAtom',
  default: {
    open: false,
    sourceId: '',
  },
});

export const useClosedOpenAtomState = () => {
  return useRecoilState(closedOpenAtom);
};

export const useClosedOpenAtomValue = () => {
  return useRecoilValue(closedOpenAtom);
};
