import { Input, TextArea } from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';
import {
  KEY_NAME_SETTING_DESCRIPTION,
  KEY_NAME_SETTING_NAME,
} from '@settings/process/config/keyNames';

export const ActionWriteField = {
  [KEY_NAME_SETTING_NAME]: {
    component: Input,
    showFullRow: false,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    languageKey: 'process_action_name',
    parseParam: (value: string) => value,
  },
  [KEY_NAME_SETTING_DESCRIPTION]: {
    component: TextArea,
    showFullRow: true,
    defaultValue: '',
    languageKey: 'process_action_description',
    parseParam: (value: string) => value,
  },
};

export default ActionWriteField;
