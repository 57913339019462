import React, { useState } from 'react';
import { WriteParseFields } from '@base/utils/helpers/no-layout-utils';
// import { useQueryClient } from 'react-query';
import { useQueryClient } from '@tanstack/react-query'; //v4
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { MENU_SETTING_ASSIGNMENT_RULE } from '@base/config/menus';
import { CREATE_ASSIGNMENT_RULE } from '@settings/assignment-rule/services/graphql';
import AsideWrite from './aside';
import { finalizeParams } from './payload';

interface IARWriteProps {
  onClose: () => void;
  onAfterSaved: () => void;
}
const ARWrite: React.FC<IARWriteProps> = (props: IARWriteProps) => {
  // console.log('ARWrite');
  const { onClose, onAfterSaved } = props;
  const initValues = {};
  const { fields, getParams, defaultValues } = WriteParseFields(
    MENU_SETTING_ASSIGNMENT_RULE,
    initValues,
  );
  // console.log('>>>>> fields', fields);
  const queryClient = useQueryClient();

  //state
  const [isSaving, setIsSaving] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isNew, setIsNew] = useState(false);

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_ASSIGNMENT_RULE,
    'setting_createAssignmentRule',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Create Assignment Rule successfully!');

        setIsSaving(false);
        setIsReset(true);

        if (!isNew) {
          onClose && onClose();
        }
        onAfterSaved && onAfterSaved();
      },
      onError: (error: any) => {
        setIsSaving(false);
        // console.log('failed', error);
        toast.success('Create Assignment Rule failed');
      },
    },
  );

  const onSubmit = (formData: any, isNew: boolean) => {
    setIsNew(isNew);
    const newParams = getParams(formData);
    // console.log('configParams => ', newParams);
    let finalParams = finalizeParams(newParams);
    mutationAdd.mutate(finalParams);
  };
  return (
    <AsideWrite
      onSubmit={onSubmit}
      isSaving={isSaving}
      isReset={isReset}
      defaultValues={defaultValues}
      fields={fields}
    />
  );
};

export default ARWrite;
