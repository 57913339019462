import usePost from '@base/hooks/usePost';
import usePosts from '@base/hooks/usePosts';
import { FilterInput } from '@base/types/interfaces/common';
import { BaseResponse } from '@base/types/interfaces/response';
import { IBillingPlan } from '../types/interfaces/billing-plan';
import { IContact } from '../types/interfaces/contact';
import { IInvoiceIssued } from '../types/interfaces/issued';
import { ILicense } from '../types/interfaces/license';
import { IOrder } from '../types/interfaces/order';
import { IUsageHistory } from '../types/interfaces/usage-history';
import {
  billingPlanFakeData,
  bnpFakeInvoiceIssued,
  bnpFakeOrder,
  contactFakeData,
  fakeLicense,
  fakeLicensingGuide,
  historyUsageFakeData,
} from '../utils/fake-data';

export const useListContact = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['list_contact', keyword];
  let params = {
    filter,
  };
  const response = usePosts<IContact[]>(queryKey, '', params, {
    initialData: contactFakeData(), // init fake data
  });
  return response;
};

export const useListBillingPlan = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['billing_plan', keyword];
  let params = {
    filter,
  };
  const response = usePosts<IBillingPlan[]>(queryKey, '', params, {
    initialData: billingPlanFakeData(), // init fake data
  });
  return response;
};

export const useListLicense = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['license', keyword];
  let params = {
    filter,
  };
  const response = usePosts<ILicense[]>(queryKey, '', params, {
    initialData: fakeLicense(), // init fake data
  });
  return response;
};

export const useListLicensingGuide = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['licensing_guide', keyword];
  let params = {
    filter,
  };
  const response = usePosts<ILicense[]>(queryKey, '', params, {
    initialData: fakeLicensingGuide(), // init fake data
  });
  return response;
};

export const useListOrder = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['order_detail', keyword];
  let params = {
    filter,
  };
  const response = usePosts<BaseResponse<IOrder>[]>(queryKey, '', params, {
    initialData: bnpFakeOrder(), // init fake data
  });
  return response;
};

export const useListInvoiceIssued = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['invoice_issued', keyword];
  let params = {
    filter,
  };
  const response = usePosts<BaseResponse<IInvoiceIssued>[]>(queryKey, '', params, {
    initialData: bnpFakeInvoiceIssued(), // init fake data
  });
  return response;
};

export const useListSMSUsage = () => {
  let filter: FilterInput = {
    // keyword: keyword,
  };
  let queryKey = ['sms_usage'];
  let params = {
    filter,
  };
  const response = usePosts<IUsageHistory[]>(queryKey, '', params, {
    initialData: historyUsageFakeData(), // init fake data
  });
  return response;
};
