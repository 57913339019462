import { FilterInput } from '@base/types/interfaces/common';
import usePosts from '@base/hooks/usePosts';
//import { stringify } from 'query-string';
import { useRecoilValue } from 'recoil';
import { generateFilterQuery, keyStringify } from '@base/utils/helpers/list-page-utils';
import { surveyListFilterState } from '@settings/online-digital-content/survey/recoil/atom/list-atom';
import { ISurvey } from '@settings/online-digital-content/survey/types/interface';
import { GET_SURVEY_LIST, SURVEY_RESPONSES_GET_LIST } from './graphql';

export const useSurveys = () => {
  const graphQLKey = 'setting_surveys';

  const { filter } = useRecoilValue(surveyListFilterState);
  //// console.log('get list filter', filter);

  //build filter
  let filtersQuery: FilterInput = {
    sort: filter.sort,
    paging: filter.paging,
  };
  //build query
  filtersQuery.query = generateFilterQuery(filter.query);
  //get params
  let params = {
    filter: filtersQuery,
  };

  const usePostResult = usePosts<ISurvey[]>(
    [graphQLKey, keyStringify(filtersQuery, '')],
    GET_SURVEY_LIST,
    params,
    // {
    //   keepPreviousData: true,
    //   enabled: fields.length > 0,
    // },
  );

  return usePostResult;
};

export const useSurveyResponses = (surveyId: string) => {
  const graphQLKey = 'setting_answers';

  //const { filter } = useRecoilValue(surveyListFilterState);
  //// console.log('get list filter', filter);

  //build filter
  let filtersQuery: FilterInput = {
    paging: {
      page: 1,
      size: 1000,
    },
  };
  //build query
  filtersQuery.query = `surveyId=${surveyId}`;
  //get params
  let params = {
    filter: filtersQuery,
  };

  const usePostResult = usePosts<ISurvey[]>(
    [graphQLKey, keyStringify(filtersQuery, '')],
    SURVEY_RESPONSES_GET_LIST,
    params,
    {
      enabled: surveyId.length > 0,
    },
  );

  return usePostResult;
};

export const useSurveysAutoComplete = (params: any) => {
  const usePostResult = usePosts<any[]>(
    ['setting_surveys', params.filter.keyword],
    GET_SURVEY_LIST,
    params,
  );

  return usePostResult;
};
