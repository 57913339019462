// import Timeline from '@base/containers/timeline';
// import GrapesTS from '@base/components/grapes-ts';
import Notes from '@base/containers/note';
import ViewCenter from '@settings/online-digital-content/ticket-form/layouts/desktop/layout1/view/view-center';

export const getTabs = ({ id }: any) => {
  return [
    {
      order: 1,
      default: true,
      label: 'Preview',
      path: 'preview',
      tabComponent: ViewCenter,
      tabComponentProps: {
        id,
      },
    },
    {
      order: 2,
      label: 'Note',
      path: 'note',
      tabComponent: Notes,
    },
  ];
};
