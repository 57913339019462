import React from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { ListToolbar } from '@base/components/list';
import ListToolbarContainer from '@base/containers/list/toolbar';
import { ListType } from '@base/types/enums';
import { getMoreActions } from '@base/utils/helpers/list-page-utils';
import { pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { DEFAULT_CATEGORY, MAIN_MENU } from '@settings/assignment-rule/config/pages/main-page';
//import { WRITE_OPTION_ATOM } from '@desk/ticket/config/pages/write-page';
import { WRITE_OPTION_ATOM } from '@settings/assignment-rule/config/pages/write-page';

interface IPageToolbar {
  refetch: () => void;
  onAdd: () => void;
  isSplitMode: any;
  setIsSplitMode: any;
  category: string;
}

const PageToolbar: React.FC<IPageToolbar> = (props: IPageToolbar) => {
  const { refetch, onAdd, isSplitMode, setIsSplitMode, category } = props;

  //recoil
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MAIN_MENU));
  const [writeOption, setWriteOption] = useRecoilState(WRITE_OPTION_ATOM); //for write page
  //state
  let { listType } = pageData;
  const MAIN_MENU_URL = 'assignment-rule';

  const navigate = useNavigate();

  const handleAdd = (category: string) => {
    //// console.log('actionKey', actionKey);
    onAdd();
    setWriteOption((filter) => ({
      ...filter,
      isOpenWrite: true,
      writeType: category === undefined ? DEFAULT_CATEGORY : category,
    }));
  };

  const handleSelectAction = (actionKey: string) => {
    //// console.log('actionKey', actionKey);
  };
  const moreActions = {
    actions: {
      import: {
        label: 'Import',
        icon: 'UploadCloud',
      },
      export: {
        label: 'Export',
        icon: 'Download',
      },
    },
    onSelect: (key: string) => handleSelectAction(key),
  };
  const categoryOptions = {
    list: 'Assignment Rule',
    // report: 'Run Report',
  };
  // console.log(' DEFAULT_CATEGORY >>>', DEFAULT_CATEGORY, category);
  return (
    <>
      <ListToolbarContainer
        listTypeProps={{
          listTypeStr: listType,
          onChangeListType: (listType: ListType) => {
            let newPageData = {
              ...pageData,
              listType: listType,
            };
            setPageData(newPageData);
          },
          listTypes: [ListType.GRID, ListType.LIST, ListType.SPLIT],
        }}
        actionsProps={moreActions}
        isSplitMode={isSplitMode}
        setIsSplitMode={setIsSplitMode}
        onRefresh={refetch}
        onAdd={(selectedCategory: string | undefined) =>
          handleAdd(selectedCategory === undefined ? DEFAULT_CATEGORY : selectedCategory)
        }
        categoryOptions={categoryOptions}
        categorySelected={category}
        onCategoryChange={(category: string) => navigate(`/settings/${MAIN_MENU_URL}/${category}`)}
        menu={MAIN_MENU}
      />
    </>
  );
};

export default PageToolbar;
