import { lazy } from 'react';

const MainPage = lazy(() => import('@settings/online-digital-content/main/pages/main'));

export default [
  {
    path: '/settings/online-digital-content/*',
    component: MainPage,
  },
];
