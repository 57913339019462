import React, { useState } from 'react';
import { ListBody, ListContainer } from '@base/components/list';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { useRecoilState } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms';
import displayLayoutConfig from '@settings/preferences/config/display-layout';
import { ITab } from '@base/types/interfaces/app';
import DeskGeneral from '@settings/preferences/containers/desk-general';
import DeskCategories from '@settings/preferences/containers/desk-categories';
import DeskTag from '@settings/preferences/containers/desk-tag';
import DeskAssignmentGroup from '@settings/preferences/containers/desk-assignment-group';
import DeskChannels from '@settings/preferences/containers/desk-channels';
const TABS: ITab[] = [
  {
    default: true,
    label: 'General',
    path: 'general',
    order: 1,
    tabComponent: DeskGeneral,
    tabComponentProps: {},
    show: true,
  },
  {
    default: false,
    label: 'Categories',
    path: 'categories',
    order: 2,
    tabComponent: DeskCategories,
    tabComponentProps: {},
    show: true,
  },
  {
    default: true,
    label: 'Tag',
    path: 'tag',
    order: 3,
    tabComponent: DeskTag,
    tabComponentProps: {},
    show: true,
  },
  {
    default: true,
    label: 'Assignment User/Group',
    path: 'assignment_group',
    order: 4,
    tabComponent: DeskAssignmentGroup,
    tabComponentProps: {},
    show: true,
  },
  {
    default: true,
    label: 'Channels',
    path: 'channels',
    order: 5,
    tabComponent: DeskChannels,
    tabComponentProps: {},
    show: true,
  },
];
interface IPageProps {}
const DeskPage: React.FC<IPageProps> = (props: IPageProps) => {
  const isLoading = false;
  const [latestPolicyUpdate, setLatestPolicyUpdate] = useState(0);
  const [{ isDesktop, isMobile, isTablet, device, layout = 'layout1' }] =
    useRecoilState(deviceAtom);
  const [writeData, setWriteData] = useState<any | null>(null);
  const [writeType, setWriteType] = useState('');
  const [isOpenWritePage, setIsOpenWritePage] = useState(false);
  const openWritePage = (data: any, type: string) => {
    setWriteType(type);
    setWriteData(data);
    setIsOpenWritePage(true);
  };
  const onCloseWritePage = () => {
    setIsOpenWritePage(false);
    setWriteType('');
  };
  const onAfterSaved = (data: any, type: string) => {
    if (type == 'policy') {
      setLatestPolicyUpdate(new Date().getTime());
    }
  };
  const viewProps = {
    tabs: TABS,
  };
  return (
    <BaseLayoutDisplay
      displayConfig={displayLayoutConfig}
      device={device}
      layout={layout}
      form="view"
      {...viewProps}
    />
  );
};

export default DeskPage;
