import React from 'react';
import { SpanLang } from '@base/components';
import { Copy, Trash } from 'react-feather';
import { Switch } from '@base/components/form';

//render footer
const Footer: React.FC<any> = (props) => {
  const {
    keyS,
    keyQ,
    required,
    onCopyQuestion,
    onRemoveQuestion,
    onRequiredQChange
  } = props;
  //const { t } = useTranslation();

  const qCopyQEleId = 'q-copy-' + keyS + '-' + (keyQ + 1);
  const qRemoveEleId = 'q-remove-' + keyS + '-' + (keyQ + 1);
  const qRequiredEleId = 'q-required-' + keyS + '-' + (keyQ + 1);

  //render
  return (
    <div className="survey-write-question-card-list-footer">
      <div className="survey-write-question-card-list-footer-right">
        <a id={qCopyQEleId} className="survey-home-header-button cursor-default mg-r-10"
          onClick={() => onCopyQuestion(keyS, keyQ)}
        >
          <Copy />
        </a>
        <a id={qRemoveEleId} className="survey-home-header-button cursor-default"
          onClick={() => onRemoveQuestion(keyS, keyQ)}
        >
          <Trash />
        </a>
        <div className="survey-write-question-card-list-footer-height"></div>
        <div id={qRequiredEleId} className="survey-write-question-card-list-footer-required">
          <SpanLang keyLang={'Required'} />
          <Switch
            value={required}
            onChange={(e: any) => onRequiredQChange(e.target.checked)}
          />
          {/* <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id={qRequiredEleId}
              checked={required}
              onChange={e => onRequiredQChange(e.target.checked)}
            />
            <label className="custom-control-label" htmlFor={qRequiredEleId}></label>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Footer;