import { useState } from 'react';
import classnames from 'classnames';
import {
  ChevronUp,
  User,
  X,
  Monitor,
  ChevronDown,
  RotateCcw,
  RotateCw,
  Type,
  Image,
  PlayCircle,
  List,
  ArrowUp,
  Move,
  Copy,
  Trash,
  Plus,
} from 'react-feather';
import { Select } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';


const DESIGNTABS = [
  {
    value: 1,
    label: 'Designer',
  },
  {
    value: 2,
    label: 'HTML',
  },
  {
    value: 3,
    label: 'Preview',
  },
];

const TemplateDesign: React.FC<any> = (props) => {
  //state
  const [designTab, setDesignTab] = useState<any>(DESIGNTABS[0]);
  const [showSetABTest, setShowSetABTest] = useState<boolean>(false);

  return (
    <div className="d-flex flex-column" style={{ height: 'calc(100% - 50px' }}>
      <div className="form-row mg-x-15 mg-t-10">
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">Title</label>
          {showSetABTest ? (
            <div className="row row-xs">
              <div className="col-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text lh-1">A</span>
                  </div>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="col-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text lh-1">B</span>
                  </div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
          ) : (
            <input type="text" className="form-control" />
          )}
        </div>
        <div className="form-col col-lg-6 form-group">
          <label className="form-item-title">A/B Test</label>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="landingpageABSwitch"
              checked={showSetABTest}
              onChange={() => setShowSetABTest(!showSetABTest)}
            />
            <label className="custom-control-label" htmlFor="landingpageABSwitch"></label>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 mg-15 mg-t-0-f bd rounded overflow-auto">
        <div className="row no-gutters ht-100p overflow-auto">
          <div
            className={classnames(
              'd-flex flex-column ht-100p',
              { 'col-lg-7 col-xl-8': designTab.value == 1 },
              { 'col-12': designTab.value != 1 },
            )}
          >
            <div className="d-flex">
              {/* tabs */}
              <ul className="nav nav-line bd-1 flex-grow-1">
                {DESIGNTABS.map((item, index) => (
                  <li className="nav-item" key={index}>
                    <button
                      type="button"
                      className={classnames('btn nav-link pd-x-10-f', {
                        active: item.value == designTab.value,
                      })}
                      onClick={(e) => {
                        setDesignTab(item);
                      }}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
              {showSetABTest && (
                <ul className="nav nav-tabs align-self-end pd-r-10">
                  <li className="nav-item">
                    <button type="button" className="nav-link active" data-toggle="tab">
                      A
                    </button>
                  </li>
                  <li className="nav-item">
                    <button type="button" className="nav-link" data-toggle="tab">
                      B
                    </button>
                  </li>
                </ul>
              )}
              {designTab.value == 1 && (
                <div className="btn-group bd-b">
                  <button
                    type="button"
                    data-han-tooltip="Undo"
                    className="btn btn-icon han-tooltip"
                  >
                    <RotateCcw />
                    <span className="sr-only">실행취소</span>
                  </button>
                  <button
                    type="button"
                    data-han-tooltip="Redo"
                    className="btn btn-icon han-tooltip"
                  >
                    <RotateCw />
                    <span className="sr-only">재실행</span>
                  </button>
                  <button
                    type="button"
                    data-han-tooltip="Clear Canvas"
                    className="btn btn-icon han-tooltip"
                  >
                    <Trash />
                    <span className="sr-only">초기화</span>
                  </button>
                </div>
              )}
            </div>
            <div className="flex-grow-1 overflow-auto">
              {designTab.value == 1 && (
                <>
                  <div
                    className="design-ele"
                    style={{ height: '30px', margin: '40px 15px' }}
                  >
                    <div className="design-ele-label">Section 1</div>
                    <div className="design-ele-toolbar">
                      <button
                        type="button"
                        data-han-tooltip="Parent"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <ArrowUp />
                        <span className="sr-only">상위</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Move"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <Move />
                        <span className="sr-only">이동</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Copy"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <Copy />
                        <span className="sr-only">복사</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Remove"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <Trash />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                  </div>
                  <div className="design-ele" style={{ margin: '15px', padding: '10px' }}>
                    <div className="design-ele-label">Text 1</div>
                    <div className="design-ele-toolbar">
                      <button
                        type="button"
                        data-han-tooltip="Parent"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <ArrowUp />
                        <span className="sr-only">상위</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Move"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <Move />
                        <span className="sr-only">이동</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Copy"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <Copy />
                        <span className="sr-only">복사</span>
                      </button>
                      <button
                        type="button"
                        data-han-tooltip="Remove"
                        className="btn btn-sm btn-icon han-tooltip"
                      >
                        <Trash />
                        <span className="sr-only">삭제</span>
                      </button>
                    </div>
                    Insert your text here
                  </div>
                  <div
                    className="design-ele"
                    style={{ margin: '15px', padding: '15px', textAlign: 'center' }}
                  >
                    <div className="design-ele design-ele-inline-block selected">
                      <div className="design-ele-label">CTA 1</div>
                      <div className="design-ele-toolbar">
                        <button
                          type="button"
                          data-han-tooltip="Parent"
                          className="btn btn-sm btn-icon han-tooltip"
                        >
                          <ArrowUp />
                          <span className="sr-only">상위</span>
                        </button>
                        <button
                          type="button"
                          data-han-tooltip="Move"
                          className="btn btn-sm btn-icon han-tooltip"
                        >
                          <Move />
                          <span className="sr-only">이동</span>
                        </button>
                        <button
                          type="button"
                          data-han-tooltip="Copy"
                          className="btn btn-sm btn-icon han-tooltip"
                        >
                          <Copy />
                          <span className="sr-only">복사</span>
                        </button>
                        <button
                          type="button"
                          data-han-tooltip="Remove"
                          className="btn btn-sm btn-icon han-tooltip"
                        >
                          <Trash />
                          <span className="sr-only">삭제</span>
                        </button>
                      </div>
                      <a
                        href="#"
                        style={{
                          display: 'inline-block',
                          padding: '10px 50px',
                          backgroundColor: '#193e81',
                          color: '#ffffff',
                        }}
                      >
                        REGISTER NOW
                      </a>
                    </div>
                  </div>
                </>
              )}
              {designTab.value == 2 && <div></div>}
              {designTab.value == 3 && (
                <div className="d-flex ht-100p">
                  <div className="wd-80 flex-shrink-0 design-preview-btns overflow-auto">
                    <button type="button" className="btn wd-100p ht-80 bd-b">
                      <div>Desktop</div>
                      <Monitor />
                    </button>
                    <button type="button" className="btn wd-100p ht-80 bd-b active">
                      <div>Tablet</div>
                      {Icon('tablet')}
                    </button>
                    <button type="button" className="btn wd-100p ht-80">
                      <div>Mobile</div>
                      {Icon('smartphone')}
                    </button>
                  </div>
                  <div className="flex-grow-1 overflow-auto pd-20 bd-l">
                    {/* Tablet */}
                    <div
                      className="shadow"
                      style={{
                        width: '828px',
                        height: '1084px',
                        margin: '0 auto',
                        padding: '30px',
                        borderRadius: '15px',
                        border: '1px solid #cccccc',
                      }}
                    >
                      <div className="ht-100p overflow-auto bd"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {designTab.value == 1 && (
            <div className="col-lg-5 col-xl-4 d-flex flex-column ht-100p bd-l">
              <ul className="nav nav-line bd-1">
                <li className="nav-item">
                  <button
                    type="button"
                    data-target="#designToolbox"
                    className="btn nav-link pd-x-10-f active"
                    data-toggle="tab"
                  >
                    Toolbox
                  </button>
                </li>
                <li className="nav-item mg-l-0-f">
                  <button
                    type="button"
                    data-target="#designProperties"
                    className="btn nav-link pd-x-10-f"
                    data-toggle="tab"
                  >
                    Properties
                  </button>
                </li>
                <li className="nav-item mg-l-0-f">
                  <button
                    type="button"
                    data-target="#designStyle"
                    className="btn nav-link pd-x-10-f"
                    data-toggle="tab"
                  >
                    Styles
                  </button>
                </li>
              </ul>
              <div className="tab-content flex-grow-1 overflow-auto">
                <div id="designToolbox" className="tab-pane fade show active">
                  <button
                    type="button"
                    className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                    data-toggle="collapse"
                    data-target="#designLayout"
                  >
                    Layout
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="designLayout" className="collapse fade show bd-b">
                    <div className="design-toolbox pd-5">
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('column1')}
                        <div className="ele-name">1 Column</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('column2')}
                        <div className="ele-name">2 Columns</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('column3')}
                        <div className="ele-name">3 Columns</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('column2_2')}
                        <div className="ele-name">3:7 Columns</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('column2_3')}
                        <div className="ele-name">7:3 Columns</div>
                      </button>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                    data-toggle="collapse"
                    data-target="#designElement"
                  >
                    Elements
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="designElement" className="collapse fade show">
                    <div className="design-toolbox pd-5">
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('heading')}
                        <div className="ele-name">Heading</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        <Type />
                        <div className="ele-name">Text</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        <Image />
                        <div className="ele-name">Image</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        <PlayCircle />
                        <div className="ele-name">Video</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('button')}
                        <div className="ele-name">Button</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('divider')}
                        <div className="ele-name">Divider</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('table')}
                        <div className="ele-name">Table</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        <List />
                        <div className="ele-name">List</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('cta')}
                        <div className="ele-name">CTA</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('personalize_code')}
                        <div className="ele-name">Personalize</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('form')}
                        <div className="ele-name">Form</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('event')}
                        <div className="ele-name">Event</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('survey')}
                        <div className="ele-name">Survey</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('option_set')}
                        <div className="ele-name">Option Set</div>
                      </button>
                      <button type="button" className="btn btn-white shadow-sm">
                        {Icon('social_share')}
                        <div className="ele-name">Social Share</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div id="designProperties" className="tab-pane fade">
                  <button
                    type="button"
                    className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b"
                    data-toggle="collapse"
                    data-target="#designCTA"
                  >
                    Call-to-Action
                    <ChevronDown className="mg-l-auto" />
                  </button>
                  <div id="designCTA" className="collapse fade show pd-15 bd-b">
                    <div className="form-group">
                      <label className="form-item-title">Name</label>
                      <Select options={[{ value: 1, label: 'CTA 1' }]} />
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Type</label>
                      <div>Text</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Landing Page/Site/Form</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Content Type</label>
                      <div>&nbsp;</div>
                    </div>
                    <div className="form-group">
                      <label className="form-item-title">Page Title</label>
                      <div>&nbsp;</div>
                    </div>
                  </div>

                  {/* image type property
                                            <button type="button" className="btn btn-lg bg-gray-100 d-flex align-items-center wd-100p rounded-0 bd-b" data-toggle="collapse" data-target="#designIMG">
                                                Image<ChevronDown className="mg-l-auto" />
                                            </button>
                                            <div id="designIMG" className="collapse fade show pd-15">
                                                <div className="form-group">
                                                    <label className="form-item-title d-block">Type</label>
                                                    <div className="btn-group img-type-list">
                                                        <button type="button" className="btn btn-white han-tooltip" data-han-tooltip="Image only">
                                                            <Image />
                                                        </button>
                                                        <button type="button" className="btn btn-white han-tooltip" data-han-tooltip="Image on top">
                                                            {Icon("img_on_top")}
                                                        </button>
                                                        <button type="button" className="btn btn-white han-tooltip" data-han-tooltip="Image on bottom">
                                                            {Icon("img_on_bottom")}
                                                        </button>
                                                        <button type="button" className="btn btn-white han-tooltip" data-han-tooltip="Image on left">
                                                            {Icon("img_on_left")}
                                                        </button>
                                                        <button type="button" className="btn btn-white han-tooltip" data-han-tooltip="Image on right">
                                                            {Icon("img_on_right")}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-item-title">Source</label>
                                                    <Select/>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-item-title">Alt Text</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                             */}
                </div>
                <div id="designStyle" className="tab-pane fade"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TemplateDesign;