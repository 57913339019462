import { gql } from 'graphql-request';

export const TICKET_FORM_DELETE = gql`
  mutation q($ids: String!) {
    setting_deleteTicketForm(ids: $ids) {
      ids
    }
  }
`;

export const TICKET_FORM_CREATE = gql`
  mutation q($ticketForm: TicketFormData!) {
    setting_createTicketForm(ticketForm: $ticketForm) {
      id
    }
  }
`;

export const TICKET_FORM_UPDATE = gql`
  mutation m($ticketForm: TicketFormData!) {
    setting_updateTicketForm(ticketForm: $ticketForm) {
      id
    }
  }
`;
