import React, { useState } from 'react';
import { ViewFields } from '@base/components/hook-form/view';
import { ViewFieldParse } from '@base/utils/helpers/no-layout-utils';
import { default as viewConfig } from '@settings/assignment-rule/config/view-field';
import { useAssignmentRule } from '@settings/assignment-rule/services/service';
import { Button } from '@base/components/form';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { DELETE_ASSIGNMENT_RULE } from '@settings/assignment-rule/services/graphql';
import { toast } from 'react-toastify';
import { confirmAlert } from '@base/components';
import { useTranslation } from 'react-i18next';

interface IARViewProps {
  menuSource: string;
  menuSourceId: string;
  onClose: () => void;
}
const ARView: React.FC<IARViewProps> = (props: IARViewProps) => {
  // console.log('Ar view');
  const { menuSource, menuSourceId, onClose } = props;
  const { data, isLoading } = useAssignmentRule(menuSourceId);
  const fields = ViewFieldParse(data ?? {}, viewConfig, true, ['setting_updateAssignmentRule']);
  const ignoreFields: string[] = [];
  const { t } = useTranslation();
  const mDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_ASSIGNMENT_RULE,
    'setting_deleteAssignmentRule',
    {
      onSuccess: (res: any) => {
        toast.success('Data has deleted!');
        setIsSaving(false);
        onClose && onClose();
      },
      onError: (res: any) => {
        toast.error('Deleting has failed!');
        setIsSaving(false);
      },
    },
  );
  const onDelete = () => {
    confirmAlert({
      title: t('crm_new_common_delete'),
      message: t('crm_new_common_delete_msg'),
      buttons: [
        {
          label: 'No',
          className: 'btn-secondary',
        },
        {
          label: 'Yes',
          className: 'btn-primary',
          onClick: () => {
            mDelete.mutate({ id: menuSourceId });
          },
        },
      ],
    });
  };
  const [isSaving, setIsSaving] = useState(false);
  return (
    <>
      <div className="form-row mg-0 " style={{ height: 'calc(100vh - 150px)' }}>
        {isLoading ? (
          <div className="placeholder-paragraph wd-100p ht-100">
            <div className="line"></div>
            <div className="line"></div>
          </div>
        ) : (
          ''
        )}
        {!isLoading && data ? (
          <ViewFields
            fields={fields}
            ignoreFields={ignoreFields}
            menuSource={menuSource ?? ''}
            menuSourceId={menuSourceId ?? ''}
          />
        ) : (
          ''
        )}
      </div>
      <div className="pos-fixed bd-t d-flex r-0 b-0 align-items-center justify-content-between pd-10 bg-white wd-500">
        <div className="d-flex mg-l-auto">
          <div className="btn-group dropup">
            <Button
              color="danger"
              onClick={onDelete}
              type="button"
              loading={isSaving}
              name="Delete"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ARView;
