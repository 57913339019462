import { Edit2, X } from 'react-feather';
import React, { useCallback } from 'react';
import { useClosedOpenAtomState } from '@settings/process/recoil/diagram';
import { useQueryClient } from '@tanstack/react-query'; //v4
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { DELETE_PROCESS_STEP } from '@settings/process/services/process';
import { Axis, ShapeType, NodeProperty, NodeType } from '@settings/process/types/diagram';
import { useSetRecoilState } from 'recoil';
import { stepAddOpenAtom } from '../../../recoil/step';

interface NodeEditProps {
  processId: string;
  sourceId: string;
  property: NodeProperty;
  position: Axis;
  sourceType: NodeType;
  shape: ShapeType;
}

function NodeEdit(props: NodeEditProps) {
  const { sourceId, processId, property, position, sourceType, shape } = props;
  const setStepWrite = useSetRecoilState(stepAddOpenAtom);
  const [, setClosedView] = useClosedOpenAtomState();

  const onStepView = useCallback(() => {
    if (property == 'PROPERTY_CLOSE') {
      setClosedView({ open: true, sourceId: sourceId });
    } else {
      setStepWrite({
        edit: true,
        sourceId: sourceId,
        position: position,
        direction: 'DIRECTION_NONE',
        sourceType: sourceType,
        shape: shape,
      });
    }
  }, [sourceId]);

  const queryClient = useQueryClient();
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_PROCESS_STEP,
    'process_deleteStep',
    {
      onSuccess: (data: any) => {
        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', processId]);
        }, 500);
        // console.log('success data', data);
        toast.success('Delete step successfully!');
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Delete step failed');
      },
    },
  );

  const onDelete = useCallback(() => {
    if (confirm('Are you sure to delete step => ' + sourceId)) {
      mutationDelete.mutate({ id: processId, stepId: sourceId });
    }
  }, []);

  return (
    <>
      <div className="diagram-item-actions">
        <button
          type="button"
          className="btn btn-xs btn-link link-03 btn-icon"
          data-han-tooltip="Edit"
          onClick={onStepView}
        >
          <Edit2 />
        </button>
        {property !== 'PROPERTY_START' && (
          <button
            type="button"
            className="btn btn-xs btn-link link-03 btn-icon"
            data-han-tooltip="Delete"
            onClick={onDelete}
          >
            <X />
          </button>
        )}
      </div>
    </>
  );
}

export default React.memo(NodeEdit);
