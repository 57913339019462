import HistoryTransactionTable from '@settings/billing-license/containers/history/transaction';
import HistoryUsageTables from '@settings/billing-license/containers/history/usage';
import React, { useState } from 'react';

const History: React.FC = () => {
  const [submitValue, setSubmitValue] = useState({});
  const onChange = (keyName: string, keyValue: string) => {
    setSubmitValue({ ...submitValue, [keyName]: keyValue });
    // console.log(keyName, keyValue);
  };
  return (
    <div className="card-columns settings-columns scroll-box pd-20">
      {/* Transaction History */}
      <HistoryTransactionTable />

      {/* map react tables */}
      <HistoryUsageTables />
    </div>
  );
};
export default History;
