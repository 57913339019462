import { slideToMapByKey } from '@base/utils/helpers/array-utils';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';

export const parseFields = (layoutView: any, menuApi: string) => {
  let fields: any[] = [];
  if (layoutView?.data && layoutView?.data[0] && layoutView?.data[0]?.children) {
    if (menuApi == 'na') {
      fields = [...layoutView?.data[0]?.children, ...layoutView?.data[1]?.children];
    } else {
      fields = [...layoutView?.data[0]?.children];
    }
  }
  if (fields.length == 0) {
    return layoutView;
  }
  const mapFields = slideToMapByKey(fields, 'keyName');
  if (!mapFields) {
    return layoutView;
  }

  if (menuApi == 'desk') {
    fields = parseFieldsView(mapFields, fields);
    let tmp = { ...layoutView };
    let tmpData = [...tmp.data];
    tmpData[0] = { ...tmpData[0], children: fields };
    tmp = { ...tmp, data: tmpData };
    return tmp;
  }
};
export const parseFieldsList = (layoutList: any, menuApi: string) => {
  let fields: any[] = [];
  if (layoutList?.data && layoutList?.data[0]) {
    fields = [...layoutList?.data];
  }
  if (fields.length == 0) {
    return layoutList;
  }
  const mapFields = slideToMapByKey(fields, 'keyName');
  if (!mapFields) {
    return layoutList;
  }

  if (menuApi == 'desk') {
    fields = parseFieldsListTask(mapFields, fields);
    let tmp = { ...layoutList };
    tmp = { ...tmp, data: fields };
    return tmp;
  } else if (menuApi == 'ticket') {
    fields = parseFieldsListTicket(mapFields, fields);
    let tmp = { ...layoutList };
    tmp = { ...tmp, data: fields };
    return tmp;
  }
};

export const parseFieldsView = (mapFields: any, fields: any) => {
  let newFields: any[] = [];
  let field: any = {};

  field = { ...mapFields['subject'] };
  field.languageKey = 'Name';
  field.keyName = 'name';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['code'] };
  field.languageKey = 'Ticket No';
  newFields.push(field);

  field = { ...mapFields['customer'] };
  field.languageKey = 'Customer';
  newFields.push(field);

  field = { ...mapFields['contact'] };
  field.languageKey = 'Contact';
  newFields.push(field);

  field = { ...mapFields['status'] };
  field.languageKey = 'Status';
  newFields.push(field);

  field = { ...mapFields['category'] };
  field.languageKey = 'Category';
  newFields.push(field);

  field = { ...mapFields['assignedUser'] };
  field.languageKey = 'Assigned Rep';
  newFields.push(field);

  field = { ...mapFields['firstRespondDue'] };
  field.languageKey = '1st Responded on';
  newFields.push(field);

  field = { ...mapFields['resolutionDue'] };
  field.languageKey = 'Resolved on';
  field.keyName = 'resolvedOn';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['closedAt'] };
  field.languageKey = 'Closed At';
  newFields.push(field);

  return newFields;
};

export const parseFieldsListTask = (mapFields: any, fields: any) => {
  let newFields: any[] = [];
  let field: any = {};

  field = { ...mapFields['subject'] };
  field.languageKey = 'Name';
  field.keyName = 'name';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['subject'] };
  field.languageKey = 'Description';
  field.keyName = 'description';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['createdAt'] };
  field.isViewing = true;
  field.defaultViewInList = true;
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['createdBy'] };
  field.isViewing = true;
  field.defaultViewInList = true;
  field.id = field.keyName;
  newFields.push(field);

  return newFields;
};

export const parseFieldsListTicket = (mapFields: any, fields: any) => {
  let newFields: any[] = [];
  let field: any = {};

  field = { ...mapFields['code'] };
  field.languageKey = 'Ticket No';
  newFields.push(field);

  field = { ...mapFields['subject'] };
  newFields.push(field);

  field = { ...mapFields['status'] };
  field.languageKey = 'Status';
  newFields.push(field);

  field = { ...mapFields['assignedUser'] };
  field.languageKey = 'Assigned Rep';
  newFields.push(field);

  field = { ...mapFields['createdAt'] };
  field.id = field.keyName;
  newFields.push(field);

  return newFields;
};
