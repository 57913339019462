import { Select } from '@base/components/form';
import { FIRST_DAYS } from '@settings/general-setting/config/constant';
import React from 'react';
interface IFirstDayOfWeekProps {
  value: any;
  onChange: (params: any) => void;
}
const FirstDayOfWeek: React.FC<IFirstDayOfWeekProps> = (props: IFirstDayOfWeekProps) => {
  const { value, onChange, ...attrs } = props;
  return (
    <>
      <label className="form-item-title">First Day of Week</label>
      <Select
        className="wd-150-f"
        value={value}
        onChange={onChange}
        options={FIRST_DAYS}
        {...attrs}
      />
    </>
  );
};

export default FirstDayOfWeek;
