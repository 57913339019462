import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ChevronDown, Edit2, Folder, Plus, PlusCircle, Trash2 } from 'react-feather';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Loading from '@base/components/loading';
import { Button, FormIcon } from '@base/components/form';
import { confirmAlert, NoData } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { useProductGroups } from '@product/product/services/product-service';
import WriteProductGroupForm from '../write-product-group-form';
import { PRODUCT_GROUP_DELETE, PRODUCT_GROUP_UPDATE } from '@settings/preferences/services/graphql';
import { buildTree, findNode } from '@base/utils/helpers/general.utils';

interface IProductTreeProps {
  className?: string;
  treeId: string;
  editable?: boolean;
  onSelect?: (item: any) => void;
}

const ProductGroupTree: React.FC<IProductTreeProps> = (props) => {
  const { className, treeId, editable = true, onSelect } = props;
  const { t } = useTranslation();
  //state
  const [isOpenWrite, setIsOpenWrite] = useState(false);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [deletedItem, setDeletedItem] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  //mutation
  const mUpdate: any = useMutationPost(PRODUCT_GROUP_UPDATE, 'product_updateGroup');

  //build tree data from array
  // const buildTree = (arrItems: any[], id: any): any =>
  //   arrItems
  //     .filter((_item: any) => _item.parent?.id === id)
  //     .map((_item: any) => ({ ..._item, children: buildTree(arrItems, _item.id) }));

  //hook to get product groups
  const { data: postData, isFetching, refetch: refetch } = useProductGroups({ keyword: '' });

  //set tree: category/folder
  useEffect(() => {
    if (postData?.data) {
      //// console.log('newTreeData', buildTree(postData?.data || [], undefined));
      setTreeData(buildTree(postData?.data || [], undefined));
    }
  }, [postData]);

  //mutation
  const mDelete: any = useMutationPost(PRODUCT_GROUP_DELETE, 'product_deleteGroup');
  //const mSortCategory: any = useMutationPost(KNOWLEDGE_CATEGORIES_SORT, 'desk_sortKBCategory');

  //check create success
  useEffect(() => {
    if (mDelete.isSuccess) {
      const newItems = [...treeData];
      if (deletedItem.parent) {
        //delete node
        const parentNode = findNode(newItems, deletedItem.parent.id, {});
        const fIndex = parentNode.children.findIndex((_ele: any) => _ele.id === deletedItem.id);
        parentNode.children.splice(fIndex, 1);
      } else {
        //delete root
        const fIndex = newItems.findIndex((_ele: any) => _ele.id === deletedItem.id);
        newItems.splice(fIndex, 1);
      }
      setTreeData(newItems);
      //reset
      setDeletedItem(null);
    }
  }, [mDelete.isSuccess]);

  /** ===================================== HANDLER ==================================== */
  //find node in tree
  // const findNode = (treeNodes: any[], nodeId: string, result: any): any => {
  //   for (let i = 0; i < treeNodes.length; i++) {
  //     if (treeNodes[i].id === nodeId) {
  //       //// console.log('nodes[i]', nodes[i]);
  //       result = treeNodes[i];
  //     }
  //     if (treeNodes[i]?.children?.length > 0) {
  //       result = findNode(treeNodes[i].children, nodeId, result);
  //     }
  //   }
  //   return result;
  // };

  //drag end
  const handleDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;
    //// console.log('group result', result);
    if (!destination) {
      return;
    }
    //re-order folder
    const newItems = [...treeData];
    const sourceNode = findNode(newItems, source.droppableId, {});
    const destinationNode = findNode(newItems, destination.droppableId, {});
    //// console.log('sourceNode', sourceNode);
    if (sourceNode?.id) {
      const [removedItem] = sourceNode.children.splice(source.index, 1);
      if (destinationNode.children) {
        destinationNode.children.splice(destination.index, 0, removedItem);
      } else {
        destinationNode.children = [removedItem];
      }
    } else {
      //root node
      const [removedItem] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, removedItem);
    }
    setTreeData(newItems);

    //update to DB
    //1. if the same parent: sourceNode.id === destinationNode.id, sort --> API not support yet
    // if (sourceNode.id === destinationNode.id) {
    //   const params = destinationNode.children.map((_ele: any) => _ele.id);
    //   //mSortCategory.mutate({ ids: params });
    // }
    //2. if different parent sourceNode.id !== destinationNode.id, update
    if (sourceNode.id !== destinationNode.id) {
      //change parent
      const params: any = {
        id: draggableId,
        //order: destination.index,
        parent: {
          id: destinationNode.id,
          name: destinationNode.name,
        },
      };
      mUpdate.mutate({ group: params });

      //change sort
      //const sortParams = destinationNode.children.map((_ele: any) => _ele.id);
      //mSortCategory.mutate({ ids: sortParams });
    }
  };

  //add child to item
  const handleAddItem = (item: any) => {
    setSelectedItem({ parent: item });
    setIsOpenWrite(true);
  };

  //edit item
  const handleEditItem = (parent: any, item: any, depth: number) => {
    setSelectedItem({ ...item, parent });
    setIsOpenWrite(true);
  };

  //delete
  const handleDeleteItem = (parent: any, item: any, depth: number) => {
    mDelete.mutate({ ids: [item.id] });
    setDeletedItem(item);
    // confirmAlert({
    //   title: t('crm_new_common_delete'),
    //   message: t('crm_new_common_delete_msg'),
    //   buttons: [
    //     {
    //       label: 'No',
    //       className: 'btn-secondary',
    //     },
    //     {
    //       label: 'Yes',
    //       className: 'btn-primary',
    //       onClick: () => {
    //         mDelete.mutate({ ids: [item.id] });
    //         setDeletedItem(item);
    //       },
    //     },
    //   ],
    // });
  };

  //new/update folder
  const handleAfterCreateUpdate = (group: any) => {
    //// console.log('group after', group);
    /**
     * parent !== null --> destination is parent
     * source: findNode
     */
    const newItems = _.cloneDeep(treeData);
    //1. delete in source if exist
    const curNode = findNode(newItems, group.id, {});
    if (curNode?.id) {
      //EDIT
      if (curNode.parent) {
        const sourceParentNode = findNode(newItems, curNode.parent.id, {}); //this is current Parent
        const destParentNode = findNode(newItems, group.parent.id, {}); //this is new Parent
        const fChildIdx = sourceParentNode.children.findIndex((_ele: any) => _ele.id === group.id);
        if (fChildIdx > -1) {
          if (sourceParentNode.id === destParentNode.id) {
            destParentNode.children[fChildIdx] = group;
          } else {
            sourceParentNode.children.splice(fChildIdx, 1); //remove from source
            //add to destination
            if (destParentNode.children) {
              destParentNode.children.push(group);
            } else {
              destParentNode.children = [group];
            }
          }
        }
      } else {
        //root node
        const fIndex = newItems.findIndex((_ele: any) => _ele.id === group.id);
        if (fIndex > -1) {
          newItems[fIndex] = group;
        }
      }
    } else {
      //NEW
      if (group.parent) {
        const destParentNode = findNode(newItems, group.parent.id, {}); //this is new Parent
        if (destParentNode?.id) {
          if (destParentNode.children) {
            destParentNode.children.push(group);
          } else {
            destParentNode.children = [group];
          }
        }
      } else {
        newItems.push(group);
      }
    }
    setTreeData(newItems);
  };

  //selected node
  const handleSelectNode = (item: any) => {
    if (item.id !== selectedItem?.id) {
      setSelectedItem(item);
      onSelect && onSelect(item);
    } else {
      setSelectedItem(null);
      onSelect && onSelect(null);
    }
  };

  /** ===================================== RENDER ==================================== */
  //render item
  const renderNode = (parent: any, node: any, depth: number, dragHandleProps: any) => {
    return (
      <div
        className={classNames('tree-btn-wrap mg-b-3 rounded')}
        style={{
          backgroundColor: selectedItem?.id === node.id ? 'lightskyblue' : 'transparent',
        }}
      >
        {editable && (
          <div {...dragHandleProps}>
            <FormIcon icon="move" iconType="icon" className="cursor-move" color="gray" />
          </div>
        )}
        <Folder color="orange" fill="rgba(253,126,20,.3)" size={20} className="mg-x-5" />
        <div className="text-truncate cursor-default" onClick={() => handleSelectNode(node)}>
          {node.name}
        </div>
        <div className="tree-btn-actions">
          {editable && (
            <>
              {/* {node?.children?.length > 0 &&  */}
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon"
                data-toggle="collapse"
                data-target={'#node' + treeId + node.id}
                aria-expanded="false"
              >
                <ChevronDown />
              </button>
              {/* } */}
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                data-han-tooltip="Add"
                onClick={() => handleAddItem(node)}
              >
                <Plus />
              </button>
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                data-han-tooltip="Edit"
                onClick={() => handleEditItem(parent, node, depth)}
              >
                <Edit2 />
              </button>
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
                data-han-tooltip="Delete"
                onClick={() => handleDeleteItem(parent, node, depth)}
              >
                <Trash2 className="tx-danger" />
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  //empty node
  const renderEmptyNode = (parent: any) => {
    return (
      <Draggable key={'-1'} draggableId={'-1'} index={-1} isDragDisabled={true}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div
              className={classNames(
                'd-flex align-items-center justify-content-center ht-30 rounded',
              )}
              style={{ backgroundColor: 'var(--background-hover-color)' }}
            >
              <Folder className="mg-r-5" style={{ width: '15px' }} />
              <span className="text-truncate">No group(s) available.</span>
              <button type="button" className="btn btn-link" onClick={() => handleAddItem(parent)}>
                <PlusCircle className="tx-bold" />
              </button>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  //all tree
  //TODO: using isCombineEnabled prop to add parent-child, but NOW it doesn't support to move child to parent
  const renderTree = (parent: any, children: any[], depth: number) => {
    return (
      <Droppable droppableId={parent ? parent.id : `NODE-${depth}`} type={`NODE-${depth}`}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={classNames(`rounded`, { 'mg-l-20': depth > 0 })}
            style={{
              padding: snapshot.isDraggingOver ? '0 0 0 5px' : 0,
              // margin: 4,
              // borderRadius: 4,
              backgroundColor: snapshot.isDraggingOver
                ? 'var(--background-hover-color)'
                : 'var(--background-container)',
              overflow: 'hidden',
            }}
          >
            {children.map((_item: any, index: number) => (
              <Draggable
                key={_item.id}
                draggableId={_item.id}
                index={index}
                isDragDisabled={true} //disabled always
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    //{...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      padding: snapshot.isDragging ? 0 : 3,
                      borderRadius: snapshot.isDragging ? 4 : 0,
                      backgroundColor: snapshot.isDragging ? 'lightblue' : 'transparent',
                    }}
                  >
                    {renderNode(parent, _item, depth, provided.dragHandleProps)}
                    <div
                      id={'node' + treeId + _item.id}
                      className={classNames('collapse fade mg-r-5', {
                        show: _item.children.length > 0,
                      })}
                    >
                      {renderTree(_item, _item?.children || [], depth + 1)}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {children.length === 0 && renderEmptyNode(parent)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  //tree context
  const TreeMemo = useMemo(() => {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>{renderTree(null, treeData, 0)}</DragDropContext>
    );
  }, [treeData, isFetching, selectedItem]);

  //// console.log('product group treeData', treeData);
  //main render
  return (
    <div className={classNames('card mg-10', className)}>
      <div className="card-body">
        <div className="d-flex flex-grow-1">
          <div
            className="kb-tree list-unstyled w-100 mg-b-0 scroll-box"
            style={{ maxHeight: 'calc(100vh - 100px)' }}
          >
            {isFetching && treeData.length === 0 && <Loading />}
            {treeData.length > 0 && TreeMemo}
            {treeData.length === 0 && <NoData icon="Package" label="No product group available." />}
            <Button
              color="link"
              size="sm"
              icon="Plus"
              iconClass="mg-r-5"
              name="Add"
              className="pd-x-0"
              onClick={() => {
                setSelectedItem(null);
                setIsOpenWrite(true);
              }}
            />
          </div>
          {isOpenWrite && (
            <WriteProductGroupForm
              title="New Product Group"
              size="md"
              isOpen={isOpenWrite}
              onClose={() => setIsOpenWrite(false)}
              data={selectedItem}
              onSuccess={handleAfterCreateUpdate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductGroupTree;
