export const finalizeParams = (params: any, variableKey = 'ticketForm') => {
  let newParams: any = {};

  const { ...remainParams } = params;

  newParams = {
    ...newParams,
    ...remainParams,
  };

  newParams['displayMessage'] = newParams?.submissionDisplay?.displayMessage ?? '';
  newParams['linkToPage'] = newParams?.submissionDisplay?.linkToPage ?? '';
  newParams['linkToType'] = newParams?.submissionDisplay?.linkToType ?? '';
  newParams['linkToResource'] = newParams?.submissionDisplay?.linkToResource
    ? {
        id: newParams?.submissionDisplay?.linkToResource?.value,
        name: newParams?.submissionDisplay?.linkToResource?.label,
      }
    : null;
  newParams['submissionDisplay'] = newParams?.submissionDisplay?.submissionDisplay ?? '';
  // delete newParams.submissionDisplay;

  newParams['createTicket'] = newParams?.submissionBehavior?.createTicket ?? false;
  newParams['ticketName'] = newParams?.submissionBehavior?.ticketName ?? '';
  delete newParams.submissionBehavior;

  return {
    [variableKey]: newParams,
  };
};
