import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { MENU_SETTING_CTA } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { ViewFields } from '@base/components/hook-form/view';
import { Button, Select, SpanLang } from '@base/components/form';
import * as keyNames from '@settings/online-digital-content/cta/config/key-names';
import {
  SETTING_CTA_CONTENT_TYPE_LANDING_PAGE,
  SETTING_CTA_TYPE_IMAGE,
  SETTING_CTA_TYPE_QRCODE,
  SETTING_CTA_TYPE_TEXT,
} from '@settings/online-digital-content/cta/config/constants';
import { concat } from 'lodash';
import ImagePreview from '@settings/online-digital-content/cta/components/image-preview';
import TextPreview from '@settings/online-digital-content/cta/components/text-preview';
import QrCodePreview from '@settings/online-digital-content/cta/components/qr-code-preview';

interface IViewLeftProps {
  id: string;
}

const LINK_PREVIEW_KEYS: string[] = [
  keyNames.KEY_SETTING_CTA_VALUE,
  keyNames.KEY_SETTING_CTA_LINK_TYPE,
  keyNames.KEY_SETTING_CTA_EXT_SITE_NAME,
  keyNames.KEY_SETTING_CTA_LINK_URL,
  keyNames.KEY_SETTING_CTA_PAGE_TITLE,
  keyNames.KEY_SETTING_CTA_IMAGE_URL,
  keyNames.KEY_SETTING_CTA_PREVIEW,
  keyNames.KEY_SETTING_CTA_NAME,
];

const IMAGE_PREVIEW_KEYS: string[] = [
  keyNames.KEY_SETTING_CTA_IMAGE_SIZE,
  keyNames.KEY_SETTING_CTA_IMAGE_ALT,
  keyNames.KEY_SETTING_CTA_PREVIEW,
];

const TEXT_PREVIEW_KEYS: string[] = [
  keyNames.KEY_SETTING_CTA_TEXT_VALUE,
  keyNames.KEY_SETTING_CTA_TEXT_BG_COLOR,
  keyNames.KEY_SETTING_CTA_TEXT_TEXT_COLOR,
  keyNames.KEY_SETTING_CTA_TEXT_FONT_SIZE,
  keyNames.KEY_SETTING_CTA_TEXT_FONT_WEIGHT,
  keyNames.KEY_SETTING_CTA_TEXT_ROUNDED,
];

const ViewLeft: React.FC<IViewLeftProps> = (props) => {
  const { id } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_SETTING_CTA));

  const [ignoreFields, setIgnoreFields] = useState<string[]>([]);

  // get fields by group
  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
  // // console.log('basicFields', basicFields);
  const ctaType = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_TYPE,
  );
  const contentType = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_CONTENT_TYPE,
  );
  const linkUrl = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_LINK_URL,
  );

  useEffect(() => {
    if (ctaType?.data?.value === SETTING_CTA_TYPE_IMAGE) {
      setIgnoreFields(concat(LINK_PREVIEW_KEYS, TEXT_PREVIEW_KEYS));
    } else if (ctaType?.data?.value === SETTING_CTA_TYPE_TEXT) {
      setIgnoreFields(concat(LINK_PREVIEW_KEYS, IMAGE_PREVIEW_KEYS));
    } else {
      setIgnoreFields(concat(LINK_PREVIEW_KEYS, TEXT_PREVIEW_KEYS, IMAGE_PREVIEW_KEYS));
    }
  }, [ctaType]);

  const ImageCtaPreview = () => {
    const imgUrl = basicFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_IMAGE_URL,
    );
    const imgSize = basicFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_IMAGE_SIZE,
    );
    const imgAlt = basicFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_IMAGE_ALT,
    );

    const imgSrc = imgUrl?.data ?? '';
    return (
      <ImagePreview
        className="ht-150 wd-150"
        imgSrc={imgSrc}
        imgSize={imgSize?.data}
        imgAlt={imgAlt?.data}
      />
    );
  };

  const TextCtaPreview = () => {
    const bgColor = basicFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_TEXT_BG_COLOR,
    );
    const textColor = basicFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_TEXT_TEXT_COLOR,
    );
    const bdRounded = basicFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_TEXT_ROUNDED,
    );
    const fontSize = basicFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_TEXT_FONT_SIZE,
    );
    const fontWeight = basicFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_TEXT_FONT_WEIGHT,
    );
    const btnValue = basicFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_TEXT_VALUE,
    );
    return (
      <TextPreview
        txtValue={btnValue?.data}
        bgColor={bgColor?.data}
        textColor={textColor?.data}
        bdRounded={bdRounded?.data}
        fontSize={fontSize?.data}
        fontWeight={fontWeight?.data}
      />
    );
  };

  // render sections
  const SummarySection = useMemo(() => {
    // console.log('...linkUrl...', linkUrl);
    return (
      <div className="view-aside-section">
        <div className="view-aside-section-header">
          <Button icon="move" iconType="custom" className="view-aside-section-move mg-r-5" />
          <span className="view-aside-section-header-title">Summary</span>
          <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#abmSummary"
          />
        </div>
        <div id="abmSummary" className="view-aside-section-body collapse fade show">
          <div className="view-aside-section pd-15">
            <div className="d-flex align-items-center justify-content-center">
              <div className="cta-preview">
                {ctaType?.data?.value == SETTING_CTA_TYPE_IMAGE && <ImageCtaPreview />}
                {ctaType?.data?.value == SETTING_CTA_TYPE_TEXT && <TextCtaPreview />}
                {ctaType?.data?.value == SETTING_CTA_TYPE_QRCODE && (
                  <QrCodePreview id={id} linkUrl={linkUrl?.data?.link ?? ''} />
                )}
              </div>
            </div>
          </div>
          <div className="form-row mg-x-0 mg-t-10">
            <ViewFields
              fields={basicFields}
              ignoreFields={ignoreFields}
              menu={viewData?.menu ?? ''}
              menuSource={viewData?.menuSource ?? ''}
              menuSourceId={viewData?.menuSourceId ?? ''}
            />
          </div>
        </div>
      </div>
    );
  }, [id, basicFields, ignoreFields, ctaType, viewData, contentType, linkUrl]);

  return (
    <div className="scroll-box" style={{ height: 'calc(100vh - 100px)' }}>
      {SummarySection}
    </div>
  );
};

export default ViewLeft;
