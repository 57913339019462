import React, { useEffect, useState } from 'react';
import { useSelectionFields } from '@settings/general-setting/services/format-service';
import Loading from '@base/components/loading';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@settings/preferences/config/display-layout';
import { useRecoilState } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms';
import CustomerOptions from '@settings/preferences/containers/customer-options';

const getTabs = () => {
  return [
    {
      default: true,
      label: 'Rating',
      path: 'rating',
      order: 1,
      tabComponent: CustomerOptions,
      tabComponentProps: {
        keyRoot: 'customer_rating'
      },
      show: true,
    },
    {
      default: false,
      label: 'Employee Role',
      path: 'employee-role',
      order: 2,
      tabComponent: CustomerOptions,
      tabComponentProps: {
        keyRoot: 'employee_role'
      },
      show: true,
    },
    {
      default: false,
      label: 'Job Position',
      path: 'job-position',
      order: 3,
      tabComponent: CustomerOptions,
      tabComponentProps: {
        keyRoot: 'job_position'
      },
      show: true,
    },
  ];
};

const CustomerSettings: React.FC<any> = () => {
  //state
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);

  const viewProps = {
    tabs: getTabs(),
  };

  return (
    <>
      {/* {isRatingLoading && <Loading />} */}
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={device}
        layout={layout}
        form="view"
        {...viewProps}
      />
    </>
  );
};

export default CustomerSettings;
