import React, { lazy } from 'react';
const WriteForm = lazy(() => import('@settings/online-digital-content/survey/containers/write-form'));

const WriteFormDesktopLayout1: React.FC<any> = (props: any) => {
  return (
    <>
      <WriteForm {...props} />
    </>
  );
};

export default WriteFormDesktopLayout1;
