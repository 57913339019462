import Portal from '@base/components/portal';
import Canvas from '@base/components/canvas';
import React, { useMemo, useState } from 'react';
import BasicTable from '@base/components/table/basic';
import { moneyFormat } from '@base/utils/helpers';

interface ILicensePurchased {
  isVisible: boolean;
  onClose: (params: any) => any;
  onSubmit: (params: any) => any;
}

const LicensePurchased: React.FC<ILicensePurchased> = (props: ILicensePurchased) => {
  const { isVisible, onClose, onSubmit } = props;
  const [dataTableDetail, setDataTableDetail] = useState<any[]>([]);
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.name}</>;
        },
      },
      {
        Header: 'Qty',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.quantity}</>;
        },
      },
      {
        Header: 'Unit Price',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.unitPrice == 'Free' ? 'Free' : '$' + moneyFormat(row.unitPrice)}</>;
        },
      },
      {
        Header: 'Unit',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.unit}</>;
        },
      },
      {
        Header: 'Price',
        accessor: (row: any, rowIndex: any) => {
          return (
            <>
              {row.unitPrice == 'Free' ? 'Free' : '$' + moneyFormat(row.quantity * row.unitPrice)}
            </>
          );
        },
      },
    ],
    [dataTableDetail],
  );
  return (
    <Portal>
      <Canvas
        isVisible={isVisible}
        onCloseSideBar={onClose}
        onClose={onClose}
        className={'page-sidebar-container-wrap'}
        customStyles={{ width: '420px' }}
      >
        <Canvas.Header title="Purchasing" />
        <Canvas.Body style={{ height: 'calc(100vh - 130px)', width: '420px' }}>
          <div className="table-responsive rounded bd">
            <BasicTable
              columns={columns}
              data={dataTableDetail}
              className="table table-bordered mg-b-0 bd-0 settings-tb"
            />
          </div>
          <div className="d-flex justify-content-end mg-t-10">
            <ul className="list-unstyled lh-10 wd-lg-40p mg-0 pd-x-20 pd-y-10">
              <li className="d-flex justify-content-between">
                <span>Sub-Total</span>
                <span></span>
              </li>
              <li className="d-flex justify-content-between">
                <span>Tax</span>
                <span></span>
              </li>
              <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-semibold">
                <span>Total</span>
                <span></span>
              </li>
            </ul>
          </div>
        </Canvas.Body>

        <Canvas.Footer onClose={onClose} onSave={onSubmit} onAplly={onClose} />
      </Canvas>
    </Portal>
  );
};
export default LicensePurchased;
