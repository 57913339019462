import { t } from 'i18next';

export const TEMPLATE_GROUP: any[] = [
  {
    value: 'GROUP_NONE',
    label: 'None',
    key: '',
  },
  {
    value: 'GROUP_KNOWLEDGE',
    label: 'Knowledge Base',
    key: 'knowledgebase',
  },
  {
    value: 'GROUP_EMAIL',
    label: 'Email',
    key: 'email',
  },
  {
    value: 'GROUP_SMS',
    label: 'Sms',
    key: 'sms',
  },
  {
    value: 'GROUP_TASK',
    label: 'Task',
    key: 'task',
  },
  {
    value: 'GROUP_CALL',
    label: 'Call',
    key: 'call',
  },
];
export const TEMPLATE_TYPE_OPTIONS: any = [
  /*{
    value: 'TYPE_NONE',
    label: 'None',
  },*/
  {
    label: 'General',
    value: 'TYPE_GENERAL',
  },
  {
    label: 'Survey',
    value: 'TYPE_SURVEY',
  },
  {
    label: 'Thank You',
    value: 'TYPE_THANK_YOU',
  },
  {
    label: 'Follow Up',
    value: 'TYPE_FOLLOW_UP',
  },
];
export const TEMPLATE_TYPE_OPTIONS_ENUM: any = [
  {
    value: 1,
    label: t('TYPE_GENERAL'),
  },
  {
    value: 2,
    label: t('TYPE_SURVEY'),
  },
  {
    value: 3,
    label: t('TYPE_THANK_YOU'),
  },
  {
    value: 4,
    label: t('TYPE_FOLLOW_UP'),
  },
];
export const TEMPLATE_MESSAGE_TYPE_OPTIONS_ENUM: any = [
  /*{
    value: 0,
    label: t('SUB_TYPE_NONE'),
  },*/
  {
    value: 1,
    label: t('SMS'),
  },
  {
    value: 2,
    label: t('MMS'),
  },
];

export const TEMPLATE_STAGE_OPTIONS: any[] = [
  /*{
    value: 'STAGE_NONE',
    label: 'None',
  },*/
  {
    value: 'STAGE_ACTIVE',
    label: 'Active',
  },
  {
    value: 'STAGE_INACTIVE',
    label: 'Inactive',
  },
  {
    value: 'STAGE_PREPARE',
    label: 'Prepare',
  },
];

export const TEMPLATE_MESSAGE_TYPE_OPTIONS: any = [
  {
    value: 'SUB_TYPE_NONE',
    label: 'None',
  },
  {
    label: 'SMS',
    value: 'SUB_TYPE_MESSAGE_SMS',
  },
  {
    label: 'MMS',
    value: 'SUB_TYPE_MESSAGE_MMS',
  },
];

export const TEMPLATE_TASK_TYPE_OPTIONS: any = [
  /*{
    value: 'SUB_TYPE_NONE',
    label: 'None',
  },*/
  {
    label: 'Manual',
    value: 'SUB_TYPE_TASK_MANUAL',
  },
  {
    label: 'Check list',
    value: 'SUB_TYPE_TASK_CHECK_LIST',
  },
  {
    label: 'Sequence',
    value: 'SUB_TYPE_TASK_SEQUENCE',
  },
];

export const TEMPLATE_TASK_TYPE_OPTIONS_ENUM: any = [
  {
    label: t('SUB_TYPE_TASK_MANUAL'),
    value: 3,
  },
  {
    label: t('SUB_TYPE_TASK_CHECK_LIST'),
    value: 4,
  },
  {
    label: t('SUB_TYPE_TASK_SEQUENCE'),
    value: 5,
  },
];
