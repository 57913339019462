import React from 'react';
import AssingTo from '../../assign-to';

interface IAssignToViewProps {
  value: any;
  onChange: (nVal: any) => void;
  menuSourceId: string;
}
const AssignToView: React.FC<IAssignToViewProps> = (props: IAssignToViewProps) => {
  const { value, onChange, menuSourceId } = props;
  return (
    <>
      <div className="assign-to-view wd-100p">
        <AssingTo value={value} onChange={onChange} menuSourceId={menuSourceId} />
      </div>
    </>
  );
};

export default AssignToView;
