import { SpanLang } from '@base/components';
import { confirmAlert } from '@base/components/confirm-alert';
import { Button } from '@base/components/form';
import { MENU_SETTING_PAGELAYOUT } from '@base/config/menus';
import useMutationPost from '@base/hooks/useMutationPost';
import { pageDataByMenuAtom } from '@base/recoil/atoms';
import {
  CREATE_SYSTEM_LANGUAGE,
  UPDATE_SYSTEM_LANGUAGE,
} from '@base/services/graphql/sys_language';
import { ILanguageData } from '@base/types/interfaces/common';
import { generateUUID, nanoid } from '@base/utils/helpers';
import { CONFIG } from '@settings/page-layout/config/common';
import Collapse from '@settings/page-layout/containers/collapse';
import Container from '@settings/page-layout/containers/container';
import Device from '@settings/page-layout/containers/device';
import Items from '@settings/page-layout/containers/items';
import ItemsUnused from '@settings/page-layout/containers/items-unused';
import PreviewWrite from '@settings/page-layout/containers/preview/write';
import PreviewList from '@settings/page-layout/containers/preview/list';
import { ChevronUp, ChevronDown } from 'react-feather';

import ListPagePreview from '@settings/page-layout/containers/preview/customer/account/list';

import {
  CREATE_PAGE_LAYOUT_FIELD,
  DELETE_PAGE_LAYOUT_FIELD,
  GET_PAGE_LAYOUT_FIELD_CREATE,
  REORDER_PAGE_LAYOUT_FIELDS,
  UPDATE_PAGE_LAYOUT_FIELD,
  UPDATE_PAGE_LAYOUT_TPL_FIELD,
} from '@settings/page-layout/services/graphql/pagelayout';
import {
  usePagelayoutBasicFields,
  usePagelayoutData,
  usePagelayoutUnusedFields,
} from '@settings/page-layout/services/pagelayout-service';
import { EPermissionType } from '@settings/page-layout/types/enums';
import {
  IBasicField,
  IField,
  IPgField,
  ITemplateField,
  ITransferData,
} from '@settings/page-layout/types/interfaces/pagelayout';
import {
  getFieldAttrs,
  initFieldData,
  initPgFieldData,
  initPgFieldUpdateData,
  initTplFieldUpdateData,
  parseFieldData,
} from '@settings/page-layout/utils/helpers';
import ko from 'date-fns/esm/locale/ko/index.js';
import React, { useCallback, useState, useMemo, useEffect, memo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
// import { useQueryClient } from 'react-query';
import { useQueryClient } from '@tanstack/react-query'; //v4
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { layoutPreviewAtom } from '@settings/page-layout/recoil/atom/write-atom';
import { useRecoilState } from 'recoil';
import { goParseFields } from '@base/utils/helpers/page-layout-utils';
import PreviewViewAccount from '@settings/page-layout/containers/preview/customer/account/view';
//import ListPagePreview from '@settings/page-layout/containers/preview/customer/account/list';
import WriteCustomer from '@settings/page-layout/containers/preview/customer/account/write';
import {
  filterSelection,
  mergeSection,
} from '@settings/page-layout/containers/preview/customer/helpers';
import { array } from 'prop-types';

interface ILayoutPageProps {
  setIsOpenWrite: (params: any) => void;
  setItemEdit: (params: any) => void;
  dataFromEdit?: ITransferData;
}
const LayoutPage: React.FC<ILayoutPageProps> = (props: ILayoutPageProps) => {
  // console.log('LayoutPage');
  const params = useParams();
  console.log('>>>>>>>>>>>>>> params', params);
  const { setIsOpenWrite, setItemEdit, dataFromEdit } = props;

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const pageData = useRecoilValue(pageDataByMenuAtom(MENU_SETTING_PAGELAYOUT));
  let menu = 'customer';
  if (pageData.activeMenu) {
    menu = pageData.activeMenu;
  }
  console.log('>>>>>>>>>>>>>> pageData', pageData);
  const [layoutId, setLayoutId] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const [isPreviewWrite, setIsPreviewWrite] = useState(false);

  const [isPreviewView, setIsPreviewView] = useState(false);
  const [writeOption, setWriteOption] = useRecoilState(layoutPreviewAtom);

  const [device, setDevice] = useState('desktop');
  const [isAddCustomField, setIsAddCustomField] = useState(true);
  const [itemDragging, setItemDragging] = useState({});
  const [sections, setSections] = useState<IField[]>([]);
  const [unusedFields, setUnusedFields] = useState<IField[]>([]);
  const { data: basicFieldsResp, isLoading: isLoadingBasicField } = usePagelayoutBasicFields();
  const { data: unusedFieldsResp, isLoading: isLoadingUnUsedFields } = usePagelayoutUnusedFields(
    layoutId,
    device,
  );
  const { data: pgDataResp, isLoading: isLoadingPgData } = usePagelayoutData(layoutId, device);
  const COLLECTION: IBasicField[] = useMemo(
    () =>
      !isLoadingBasicField && basicFieldsResp?.results
        ? basicFieldsResp?.results.filter((item, index) => index % 2 == 0)
        : [],
    [basicFieldsResp, isLoadingBasicField],
  );
  const COLLECTION_1: IBasicField[] = useMemo(
    () =>
      !isLoadingBasicField && basicFieldsResp?.results
        ? basicFieldsResp?.results.filter((item, index) => index % 2 !== 0)
        : [],
    [basicFieldsResp, isLoadingBasicField],
  );
  const mAddField: any = useMutationPost<IField>(CREATE_PAGE_LAYOUT_FIELD, 'setting_createField');
  const mUpdateField: any = useMutationPost<IField>(
    UPDATE_PAGE_LAYOUT_FIELD,
    'setting_updateField',
  );
  const mUpdateTplField: any = useMutationPost<IField>(
    UPDATE_PAGE_LAYOUT_TPL_FIELD,
    'setting_updateTemplateField',
  );
  const mDeleteField: any = useMutationPost<IField>(
    DELETE_PAGE_LAYOUT_FIELD,
    'setting_deleteField',
    {},
  );

  const mCreateSysLanguage: any = useMutationPost<ILanguageData>(
    CREATE_SYSTEM_LANGUAGE,
    'setting_createSystemLanguage',
  );
  const mReOrderFields: any = useMutationPost<any>(
    REORDER_PAGE_LAYOUT_FIELDS,
    'setting_reOrderFields',
  );
  const handleReOrderFields = (newSections: IField[]) => {
    let reOrderSections: any = [];
    // reset field order
    newSections = newSections.map((iSection) => {
      let nSection = {
        ...iSection,
        children: iSection.children
          ? iSection.children.map((field, nOrder) => {
              let nOrderField: any = {
                id: field.templateFieldId,
                fieldId: field.id,
                orderInList: nOrder,
                orderInView: nOrder,
                orderInWrite: nOrder,
              };
              reOrderSections.push(nOrderField);
              return {
                ...field,
                orderInList: nOrder,
                orderInView: nOrder,
                orderInWrite: nOrder,
              };
            })
          : [],
      };
      return nSection;
    });
    mReOrderFields.mutate(
      { templateFields: reOrderSections },
      {
        onSuccess: (res: any) => {
          toast.success('Data has updated!');
          setSections(newSections);
        },
      },
    );
    setSections(newSections);
  };
  const handleRestoredUnusedField = ({ destination, restoredField }: any) => {
    let nFields = unusedFields.filter((item, index) => item.id !== restoredField.id);
    // isUsed: true
    // orderIn : List, View, Write
    // console.log('handleRestoredUnusedField', restoredField);
    let nOrder = destination.index;
    mUpdateTplField.mutate(
      {
        id: restoredField.id,
        tplField: {
          id: restoredField.templateFieldId,
          isUsed: true,
          orderInList: nOrder,
          orderInView: nOrder,
          orderInWrite: nOrder,
        },
      },
      {
        onSuccess: (res: any) => {
          toast.success('Field has updated!');
          // setUnusedFields(nFields);
          // handleMoveItem({
          //   destination: destination,
          //   item: restoredField,
          // });
        },
      },
    );

    handleMoveItem({
      destination: destination,
      item: restoredField,
    });
    setUnusedFields(nFields);
  };
  const handleAddItem = ({ destination, bField, section }: any) => {
    // console.log('section', section);
    let keyName = nanoid(10);
    let languageKey = pageData.activeMenu + '_field_' + keyName;
    let order = destination.index;
    let newField: IPgField = initPgFieldData(bField, order, {
      languageKey,
      keyName,
      id: generateUUID(),
      parentId: section ? section.id : '',
      pagelayoutId: layoutId,
    });
    let templateFields = newField.templateFields?.filter((item: ITemplateField) => {
      return item.device === device;
    });
    let newFieldData: IField = initFieldData(bField, order, {
      languageKey,
      keyName,
      id: newField.id,
      parentFieldId: newField.parentId,
      pagelayoutId: newField.pagelayoutId,
      templateFieldId: templateFields && templateFields.length > 0 ? templateFields[0].id : '',
    });
    newFieldData.isLoading = true;
    // do create
    mAddField.mutate(
      { pgField: newField },
      {
        onSuccess: (res: any) => {
          // create language
          let sysLanguage: ILanguageData = {
            langKey: languageKey,
            menu: 'pagelayout',
            en: newField.language ? newField.language.en : '',
            vi: newField.language ? newField.language.en : '',
            ko: newField.language ? newField.language.en : '',
            jp: newField.language ? newField.language.en : '',
            zh: newField.language ? newField.language.en : '',
            ido: newField.language ? newField.language.en : '',
          };
          mCreateSysLanguage.mutate({ systemLanguage: sysLanguage });
          newFieldData.isLoading = false;
          toast.success('Field added!');
        },
      },
    );
    handleMoveItem({
      destination: destination,
      item: newFieldData,
    });
  };
  /**
   * Handle move item between sections
   * @param {*} param
   */
  const handleMoveItem = ({ destination, item }: any) => {
    const sectionId = destination.droppableId;
    let sectionsItems = sections.map((section, index) => {
      if (section.id == sectionId) {
        let itemsNew = section.children ? section.children : [];
        itemsNew.splice(destination.index, 0, item);
        let sectionNew = {
          ...section,
          items: itemsNew,
        };
        return sectionNew;
      }
      return section;
    });
    setSections(sectionsItems);
  };
  const onChangeDevice = (item: any) => {
    setDevice(item.id);
    queryClient.invalidateQueries(['setting_pagelayout', layoutId, device]);
    queryClient.invalidateQueries(['setting_unUsedFields', layoutId, device]);
  };

  const onDragStart = useCallback(
    (event: any) => {
      setItemDragging(event);
    },
    [setItemDragging],
  );

  const onDragEnd = useCallback(
    (result: any) => {
      const { source, destination } = result;
      if (!destination) {
        return;
      }
      // console.log('result =>', result);
      switch (source.droppableId) {
        case CONFIG.unusedItems: {
          // console.log('Action unusedItems', CONFIG.unusedItems);
          const restoredField = unusedFields[source.index];
          handleRestoredUnusedField({ destination, restoredField });
          break;
        }

        case CONFIG.items: {
          // add custom field
          // console.log('Action Add basic field to Summary Section or Detail section', CONFIG.items);
          let bField = COLLECTION[source.index];
          let section = sections.length
            ? sections.find((item: any) => {
                return item.id == destination.droppableId;
              })
            : null;
          // console.log('found section', section);
          handleAddItem({
            destination,
            bField,
            section,
          });
          break;
        }

        case CONFIG.items2: {
          // console.log('Action Add basic field to Summary Section or Detail section', CONFIG.items2);
          let bField = COLLECTION_1[source.index];
          let section = sections.length
            ? sections.find((item: any) => {
                return item.id == destination.droppableId;
              })
            : null;
          // console.log('found section', section);
          handleAddItem({
            destination,
            bField,
            section,
          });
          break;
        }
        case CONFIG.sections: {
          // console.log('Action CONFIG.sections', CONFIG.sections);
          // let item = {
          //   id: uuid(),
          //   label: 'New Section',
          //   items: [],
          //   totalColumn: 2,
          // };
          // let sectionsNew = [...sections];
          // sectionsNew.splice(destination.index, 0, item);
          // setSections(sectionsNew);
          // setTimeChanged(new Date().getTime());
          break;
        }

        case CONFIG.container: {
          // console.log('Action CONFIG.container', CONFIG.container);
          // if (source.droppableId == destination.droppableId) {
          //   const containerSections = [...sections];
          //   const [removed] = containerSections.splice(source.index, 1);
          //   containerSections.splice(destination.index, 0, removed);
          //   setSections(containerSections);
          //   setTimeChanged(new Date().getTime());
          // }
          break;
        }

        default: {
          // console.log('Action move field position');
          const toSectionId = destination.droppableId;
          const fromSectionId = source.droppableId;
          let newSections: IField[] = sections;
          let fieldSource: IField = {
            id: '',
          };
          // move field position in section
          if (destination.droppableId == source.droppableId) {
            newSections = sections.map((iSection) => {
              if (iSection.id == fromSectionId) {
                let children = iSection.children ? iSection.children : [];
                fieldSource = children[source.index];

                children.splice(source.index, 1); // remove field source
                children.splice(destination.index, 0, fieldSource); // add field to position
                return {
                  ...iSection,
                  children: children,
                };
              }
              return iSection;
            });
          } else {
            // move field from section A to section B
            // remove in section A
            newSections = sections.map((iSection) => {
              if (iSection.id == fromSectionId) {
                let children = iSection.children ? iSection.children : [];
                fieldSource = children[source.index];
                // parentFieldId
                fieldSource.parentFieldId = toSectionId;
                children.splice(source.index, 1);
                return {
                  ...iSection,
                  children: children,
                };
              }
              return iSection;
            });
            // insert in section B
            newSections = newSections.map((iSection) => {
              if (iSection.id == toSectionId) {
                let children = iSection.children ? iSection.children : [];
                children.splice(destination.index, 0, fieldSource);

                return {
                  ...iSection,
                  children: children,
                };
              }
              return iSection;
            });
            // update Field: parentId
            mUpdateField.mutate({
              pgField: {
                id: fieldSource.id,
                parentId: fieldSource.parentFieldId,
              },
            });
          }
          handleReOrderFields(newSections);
          break;
        }
      }
    },
    [sections, setSections, COLLECTION],
  );

  const onStartChangeDevice = useCallback(
    (item: any) => {
      if (device == item.id) {
        return;
      }
      onChangeDevice(item);
    },
    [device],
  );
  const handleDataForModal = () => {
    setWriteOption((filter) => ({
      ...filter,
      isOpenWrite: true,
    }));
    //setIsPreview(true);
  };

  const previewWrite = () => {
    setIsPreview(true);
  };
  /**
   * Handle data when delete the section
   * @param {*} param0
   */
  const onDeleteSection = ({ item, index }: any) => {
    setSections(sections.filter((section, indexSection) => indexSection != index));
  };
  const onDeleteItemSection = ({ item, index, section }: any) => {
    // console.log('onDeleteItemSection', item);
    let fieldDelete = item;
    let newSections = sections.map((iSection) => {
      if (section.id === iSection.id) {
        return {
          ...iSection,
          children: iSection.children
            ? iSection.children.filter((field) => {
                return field.id != fieldDelete.id;
              })
            : [],
        };
      }
      return iSection;
    });
    mDeleteField.mutate(
      {
        id: fieldDelete.id,
        pagelayoutId: layoutId,
      },
      {
        onSuccess: (res: any) => {
          toast.success('Field has deleted!');
          setSections(newSections);
        },
        onError: (res: any) => {},
      },
    );
  };

  /**
   * Handle data when delete field in the section
   * @param {*} param0
   */
  const onUnusedItemSection = ({ item, index, section }: any) => {
    // console.log('onUnusedItemSection', item);
    const fieldDelete: any = item;
    let newSections: IField[] = sections.map((iSection) => {
      if (iSection.id == section.id) {
        return {
          ...iSection,
          children: iSection.children
            ? iSection.children.filter((field) => field.id != fieldDelete.id)
            : [],
        };
      }
      return iSection;
    });
    mUpdateTplField.mutate(
      {
        id: fieldDelete.id,
        tplField: {
          id: fieldDelete.templateFieldId,
          isUsed: false,
        },
      },
      {
        onSuccess: (res: any) => {
          // set load
          setSections(newSections);
          setUnusedFields(unusedFields.concat([fieldDelete]));
          toast.success('Data has updated!');
        },
        onError: (res: any) => {},
      },
    );
  };

  /**
   * Handle data when update data field in the section
   * @param {*} param0
   */
  const onUpdateItemSection = ({ item, index, section }: any) => {
    // console.log('onUpdateItemSection', item);
    let updateField: IField = item;
    let newSections: IField[] = sections.map((iSection) => {
      if (iSection.id === section.id) {
        return {
          ...iSection,
          children: iSection.children
            ? iSection.children.map((field) => {
                if (field.id === updateField.id) {
                  return updateField;
                }
                return field;
              })
            : [],
        };
      }
      return iSection;
    });
    setSections(newSections);
  };

  /**
   * Action delete unused field
   * @param {*} param0
   */
  const onDeleteUnusedItem = ({ item, index }: any) => {
    let fieldDelete = item;
    let newUnusedFields = unusedFields.filter((field) => field.id !== fieldDelete.id);
    mDeleteField.mutate(
      {
        id: fieldDelete.id,
        pagelayoutId: layoutId,
      },
      {
        onSuccess: (res: any) => {
          toast.success('Field has deleted!');
          setUnusedFields(newUnusedFields);
        },
        onError: (res: any) => {},
      },
    );
  };

  /**
   * Send data item for Edit modal
   * @param {*} param0
   */
  const onEditItemSection = ({ item, index, section }: any) => {
    // console.log('onEditItemSection', item);

    // Get total List default View
    let totalListDefaultView = 0;
    if (sections.length > 0) {
      sections.forEach((section) => {
        if (section.children) {
          section.children.forEach((item) => {
            if (item.defaultViewInList) {
              totalListDefaultView += 1;
            }
          });
        }
      });
    }
    setItemEdit({
      time: new Date().getTime(),
      item: item,
      totalListDefaultView: totalListDefaultView,
    });
    setIsOpenWrite(true);
  };

  useEffect(() => {
    // console.log('pageData', pageData);
    if (pageData.activeId && pageData.activeId != '') {
      setLayoutId(pageData.activeId);
    }
  }, [pageData]);

  // update isAddCustomeField base on attribulte
  useEffect(() => {
    if (pgDataResp?.attributes && pgDataResp.attributes.length) {
      pgDataResp.attributes.forEach((attr) => {
        if (attr.keyName == 'add_custom_field') {
          setIsAddCustomField(attr.value == '1' ? true : false);
        }
      });
    }
    if (pgDataResp?.sections) {
      let sections = parseFieldData(pgDataResp.sections);
      setSections(sections);
    }
  }, [pgDataResp]);
  // update unusedfield
  useEffect(() => {
    if (unusedFieldsResp?.results) {
      setUnusedFields(unusedFieldsResp.results);
    }
  }, [unusedFieldsResp]);

  useEffect(() => {
    if (dataFromEdit) {
      // do save data
      // console.log('DataEdit', dataFromEdit);
      // update data
      if (sections.length) {
        let nSections = sections.map((section) => {
          let nSection = section;
          if (section.children) {
            let nChildren = section.children.map((field) => {
              if (field.id === dataFromEdit.item.id) {
                return dataFromEdit.item;
              }
              return field;
            });
            nSection.children = nChildren;
          }
          return nSection;
        });
        setSections(nSections);
      }
    }
  }, [dataFromEdit]);

  let type = 'account';
  let keyName = 'customer_all';
  if (pgDataResp != undefined && pgDataResp.keyName) {
    if (pgDataResp.keyName.split('_')[1] != 'all') {
      type = pgDataResp.keyName.split('_')[1];
    }
    keyName = pgDataResp.keyName;
  } else if (pgDataResp != undefined && pgDataResp.keyName == '') {
    keyName = '';
  }

  console.log('sections =>', sections);
  return (
    <div className="page-layout-container d-flex">
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div
          className="col-4 pd-l-0 pd-r-0 bd-r"
          style={{
            height: 'calc(100vh - 115px)',
            overflowY: 'auto', //@TODO: overlay
          }}
        >
          <Collapse
            label={<SpanLang keyLang="crm_new_common_fields" />}
            isShowInit={true}
            isBorderTop={false}
            total={isLoadingBasicField ? 0 : COLLECTION.length + COLLECTION_1.length}
            note={
              isAddCustomField || isLoadingBasicField
                ? ''
                : "You can't add custom field for this menu"
            }
          >
            <div>
              <div className="d-flex pd-10" style={{}}>
                <div className="col pd-l-0 pd-r-0">
                  {COLLECTION.length > 0 ? (
                    <Items
                      items={COLLECTION}
                      id={CONFIG.items}
                      isLoading={isLoadingBasicField}
                      isAddCustomField={isAddCustomField}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div className="col pd-l-0 pd-r-0">
                  {COLLECTION_1.length > 0 ? (
                    <Items
                      items={COLLECTION_1}
                      id={CONFIG.items2}
                      isLoading={isLoadingBasicField}
                      isAddCustomField={isAddCustomField}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </Collapse>
          <Collapse
            label={<SpanLang keyLang="crm_new_common_unused_fields" />}
            isShowInit={true}
            total={unusedFieldsResp?.results ? unusedFieldsResp?.results.length : 0}
          >
            <div className="col pd-l-0 pd-r-0 pd-10">
              {!isLoadingUnUsedFields && unusedFieldsResp?.results ? (
                <ItemsUnused
                  items={unusedFields}
                  id={CONFIG.unusedItems}
                  onDeleteUnusedItem={onDeleteUnusedItem}
                  isLoading={isLoadingUnUsedFields}
                />
              ) : (
                <div className="tx-center pd-10 tx-gray-500">
                  <SpanLang keyLang="crm_new_pagelayout_msg_all_used" />
                </div>
              )}
            </div>
          </Collapse>
        </div>
        <div className="col-8 pd-l-0 pd-r-0">
          <div className="page-layout-detail">
            <div className="page-layout-detail-header fixed-header bd-b align-items-center">
              <Device onChange={onStartChangeDevice} device={device} disabled={isLoadingPgData} />
              <span className="mg-l-auto">
                {/* <Button
                  className="mg-x-5"
                  color="info"
                  icon="Search"
                  onClick={() => {
                    handleDataForModal();
                  }}
                  disabled={isLoadingPgData}
                >
                  <span>
                    <SpanLang keyLang="crm_new_common_preview" />
                  </span>
                </Button> */}
                <div className="btn-group dropdown">
                  <Button
                    className=""
                    color="info"
                    icon="Search"
                    onClick={() => {
                      handleDataForModal();
                      // handleSubmit((data) => onSubmit(data))();
                    }}
                    type="button"
                    disabled={isLoadingPgData}
                    // loading={isSaving}
                    name=" Preview List"
                  />
                  <button
                    type="button"
                    className="btn btn-info dropdown-toggle-split mg-x-5"
                    data-toggle="dropdown"
                  >
                    <ChevronDown />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Button
                      className="dropdown-item"
                      color="primary"
                      type="button"
                      onClick={() => {
                        previewWrite();
                        setIsPreviewWrite(true);
                        // handleSubmit((data) => onSubmit(data))();
                      }}
                      disabled={isLoadingPgData}
                      // loading={isSaving}
                      name="Preview Write"
                    />
                    <Button
                      className="dropdown-item"
                      color="primary"
                      type="button"
                      onClick={() => {
                        setIsPreviewView(true);
                        // handleSubmit((data) => onSubmit(data))();
                      }}
                      disabled={isLoadingPgData}
                      // loading={isSaving}
                      name="Preview View"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
          <Container
            disabled={isLoadingPgData}
            items={sections}
            itemDragging={itemDragging}
            onDeleteSection={onDeleteSection}
            onDeleteItemSection={onDeleteItemSection}
            // onChangeTotalColumn={onChangeTotalColumn}
            onUpdateItemSection={onUpdateItemSection}
            onEditItemSection={onEditItemSection}
            onUnusedItemSection={onUnusedItemSection}
            isLoading={isLoadingPgData}
          />
        </div>
      </DragDropContext>

      {/* <PreviewWrite
        menu={menu}
        pgDataResp={pgDataResp}
        isPreview={isPreview}
        setIsPreview={setIsPreview}
      /> */}
      {/* {writeOption.isOpenWrite && (
        <PreviewList
======= */}
      {isPreviewWrite && (
        <WriteCustomer
          keyName={keyName}
          isSplitMode={false}
          onClose={() => setIsPreviewWrite(false)}
          sections={filterSelection(mergeSection(sections), 'write')}
        />
      )}
      {isPreviewView && (
        <PreviewViewAccount
          title={<SpanLang keyLang={'Preview View Account'} />}
          sections={filterSelection(sections, 'view')}
          isSplitMode={false}
          onClose={() => setIsPreviewView(false)}
        />
      )}
      {/* {writeOption.isOpenWrite && (
        <ListPagePreview
          title={<SpanLang keyLang={'Preview List Account'} />}
          category={'account'}
          section={filterSelection(mergeSection(sections), 'list')}
          isSplitMode={false}
          setIsSplitMode={function () {}}
          size={'lg'}
          fullScreen={true}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
        />
      )} */}
      {writeOption.isOpenWrite && (
        <ListPagePreview
          title={<SpanLang keyLang={'Preview Account'} />}
          category={'account'}
          isSplitMode={false}
          sections={sections}
          setIsSplitMode={function () {}}
          size={'lg'}
          fullScreen={true}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
        />
      )}
    </div>
  );
};

export default LayoutPage; 
