import React, { useState, useEffect, Fragment } from 'react';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import Button from '@base/components/form/button';
import { ArrowLeft, ArrowRight, ChevronUp } from 'react-feather';
import Field from '@base/components/hook-form/field';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import Loading from '@base/components/loading';
import * as keyNames from '../../config/key-names';
import { finalizeParams } from './payload';
import { TICKET_FORM_STAGE_BUILD } from '@settings/online-digital-content/ticket-form/config/constants';
import { TICKET_FORM_CREATE } from '../../services/graphql';
import { nanoid } from '@base/utils/helpers';
import { useStorageUploadMutation } from '@base/hooks/useFileUploadMutation';

const TABS = [
  {
    value: 1,
    label: 'Basic Info',
    keys: [
      keyNames.KEY_TICKET_FORM_NAME,
      keyNames.KEY_TICKET_FORM_LANGUAGE,
      keyNames.KEY_TICKET_FORM_PRODUCTS,
      keyNames.KEY_TICKET_FORM_DESCRIPTION,
    ],
  },
  {
    value: 2,
    label: 'Select a Template',
    keys: [keyNames.KEY_TICKET_FORM_TEMPLATE],
  },
  {
    value: 3,
    label: 'Design',
    keys: [keyNames.KEY_TICKET_FORM_TITLE, keyNames.KEY_TICKET_FORM_HTML],
  },
  {
    value: 4,
    label: 'Submission setting',
    keys: [
      keyNames.KEY_TICKET_FORM_SUBMISSION_DISPLAY,
      keyNames.KEY_TICKET_FORM_SUBMISSION_BEHAVIOR,
      keyNames.KEY_TICKET_FORM_BEHAVIOR_CHECK,
    ],
  },
];

interface WriteFormProps {
  fullScreen: boolean;
  menuApi: string;
  onClose: () => void;
  onReload?: () => void;
  //with write form
  defaultValues: any;
  fields: any[];
  tabs: any[];
  getParams: any;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<WriteFormProps> = (props: WriteFormProps) => {
  const { fullScreen, menuApi, onClose, onReload, defaultValues, fields, tabs, getParams } = props;

  //state
  const [isReset, setIsReset] = useState(false);
  const [formStep, setFormStep] = useState(TABS[0].value);
  const [isFinish, setIsFinish] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  // create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    TICKET_FORM_CREATE,
    'setting_createTicketForm',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Created Ticket Form successfully!');
        // refecth data
        //onReload && onReload();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Created Ticket Form failed: ' + JSON.parse(error).message);
      },
    },
  );

  const mStorageUpload: any = useStorageUploadMutation<BaseMutationResponse>({
    onError: (error: any, variables: any, context: any) => {
      //// console.log('mutation error', error);
      toast.success('There is an error during uploading: ' + JSON.parse(error).message);
    },
  });

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationAdd.isSuccess) {
      if (isReset) {
        reset();
      } else {
        onClose();
      }
      // refecth data
      onReload && onReload();
    }
  }, [mutationAdd.isSuccess]);

  // upload success
  useEffect(() => {
    if (mStorageUpload.isSuccess) {
      if (mStorageUpload.data?.error != '') {
        toast.error(mStorageUpload.data?.error);
      } else {
        //save data
        const formData = getValues();
        const configParams = getParams(formData);
        let defaultParams: any = finalizeParams(configParams);
        defaultParams.ticketForm.stage = TICKET_FORM_STAGE_BUILD;

        //html upload id
        //// console.log('defaultParams', defaultParams);
        defaultParams.ticketForm[keyNames.KEY_TICKET_FORM_HTML] = mStorageUpload.data?.data
          ? mStorageUpload.data?.data[0]
          : '';
        mutationAdd.mutate(defaultParams);
      }
    }
  }, [mStorageUpload.isSuccess]);

  //watching
  const templateData = watch(keyNames.KEY_TICKET_FORM_TEMPLATE);

  useEffect(() => {
    if (templateData) {
      setValue(keyNames.KEY_TICKET_FORM_HTML, templateData);
    }
  }, [templateData]);

  //submit form
  const onSubmit = (formData: any) => {
    // const configParams = getParams(formData);
    // let defaultParams: any = finalizeParams(configParams);
    // defaultParams.ticketForm.stage = TICKET_FORM_STAGE_BUILD;
    // mutationAdd.mutate(defaultParams);

    //upload html+css to Block Storage, then save db
    const fileData = JSON.stringify({
      html: formData[keyNames.KEY_TICKET_FORM_HTML].html,
      css: formData[keyNames.KEY_TICKET_FORM_HTML].css,
    });
    //// console.log('...fileData...', fileData);
    const blob = new Blob([fileData], { type: 'application/json' });
    const fileName =
      formData?.[keyNames.KEY_TICKET_FORM_NAME].replace(/\s+/g, '') + nanoid() + '.json';
    const uploadFile = new File([blob], fileName);

    //Storage
    const fileFormData = new FormData();
    fileFormData.append('files', uploadFile);
    fileFormData.append('module', 'ticketform');
    mStorageUpload.mutate(fileFormData);
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    //// console.log('error', errors, e);
    toast.error('Created Ticket Form failed: ' + JSON.stringify(errors));
  };

  /** ============================== EVENT ==================================== */
  //go to next step - set data
  const goNextStep = () => {
    setFormStep((cur) => cur + 1);
    if (formStep + 1 === 2) {
    }
    if (formStep + 1 === 3) {
    }
    if (formStep + 1 === 4) {
    }
  };

  //go previous step - reset data
  const goPreviousStep = () => {
    setFormStep((cur) => cur - 1);
    if (formStep - 1 === 3) {
    }
    if (formStep - 1 === 2) {
    }
    if (formStep - 1 === 1) {
    }
  };

  //======================== RENDER FORM ========================//
  //step tabs
  const renderFormSteps = () => {
    return (
      <div className="w-100 pd-b-10">
        <ul className="steps steps-justified steps-tab w-100">
          {TABS.map((_item: any, index: number) => (
            <li
              key={index}
              className={classnames('step-item', {
                complete: _item.value < formStep,
                active: _item.value == formStep,
                disabed: _item.value > formStep,
              })}
            >
              <button
                type="button"
                className="btn step-link rounded-0"
                // onClick={(e) => setFormStep(_item.value)}
              >
                <span className="step-number">{_item.value}</span>
                <span className="step-title">{_item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  //form view
  const renderForm = () => {
    return (
      <div className="form-row pd-x-15">
        {TABS.map((_step: any) => {
          if (formStep >= _step.value) {
            return fields.map((_item: any, index: number) => {
              const isBelongsTo = _step.keys.includes(_item.keyName);
              if (isBelongsTo) {
                let newComponentProps = { ..._item?.componentProps };

                if (_item.keyName === keyNames.KEY_TICKET_FORM_HTML) {
                  newComponentProps.isFullScreen = fullScreen;
                }

                // custom field's props
                let newItem = {
                  ..._item,
                  componentProps: newComponentProps,
                };

                if (_item.keyName === keyNames.KEY_TICKET_FORM_TEMPLATE) {
                  newItem.hideTitle = true;
                }

                return (
                  <Fragment key={101 + index}>
                    <Field
                      key={102 + index}
                      item={newItem}
                      control={control}
                      errors={errors}
                      isHidden={formStep != _step.value}
                    />
                  </Fragment>
                );
              }
            });
          }
        })}
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-15 bd-t">
        <div className="flex-grow-1">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
        </div>
        <div className="mg-l-auto">
          {formStep > 1 && (
            <button type="button" className="btn btn-light mg-r-5" onClick={goPreviousStep}>
              <ArrowLeft />
              Previous
            </button>
          )}
          {formStep < 4 && (
            <button
              disabled={!isValid}
              type="button"
              className="btn btn-primary"
              onClick={goNextStep}
            >
              Next
              <ArrowRight />
            </button>
          )}
          {formStep === 4 && (
            <>
              <Button
                color="success"
                onClick={() => {
                  setIsReset(false);
                  setIsFinish(false);
                  setIsArchived(false);
                  handleSubmit((data) => onSubmit(data), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                children="Save"
              />
            </>
          )}
        </div>
      </div>
    );
  };

  // ======================== Debug ========================//
  // // console.log('form values', watch()); //get form values when inputing
  // // console.log('form api values', getParams(getValues())); //get form values when inputing
  // // console.log('form errors', errors);
  // // console.log('form fields', fields); //All fields from pagelayout
  // ======================== End Debug ========================//

  //render
  let formHeight = 'calc(100vh - 280px)';
  if (fullScreen) {
    formHeight = 'calc(100vh - 225px)';
  }

  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-10">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>You cannot except a required field</span>
          </span>
        </div>
      </div>
      {renderFormSteps()}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        <div className="scroll-box" style={{ height: formHeight, overflowX: 'hidden' }}>
          <React.Suspense fallback={<Loading />}>{renderForm()}</React.Suspense>
        </div>
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(withWriteForm(WriteForm));
