import { t } from 'i18next';

export const SETTING_CTA_TYPE_IMAGE = 'SETTING_CTA_TYPE_IMAGE';
export const SETTING_CTA_TYPE_TEXT = 'SETTING_CTA_TYPE_TEXT';
export const SETTING_CTA_TYPE_QRCODE = 'SETTING_CTA_TYPE_QRCODE';
export const SETTING_CTA_TYPE_URL = 'SETTING_CTA_TYPE_URL';

export const SETTING_CTA_TYPES: any = [
  {
    value: SETTING_CTA_TYPE_IMAGE,
    label: t('Image'),
  },
  {
    value: SETTING_CTA_TYPE_TEXT,
    label: t('Text'),
  },
  {
    value: SETTING_CTA_TYPE_QRCODE,
    label: t('QR Code'),
  },
  {
    value: SETTING_CTA_TYPE_URL,
    label: t('URL'),
  },
];

export const SETTING_CTA_LINK_TYPE_INTERNAL = 'SETTING_CTA_LINK_TYPE_INTERNAL';
export const SETTING_CTA_LINK_TYPE_EXTERNAL = 'SETTING_CTA_LINK_TYPE_EXTERNAL';

export const SETTING_CTA_LINK_TYPES: any = [
  {
    value: SETTING_CTA_LINK_TYPE_INTERNAL,
    label: t('Internal'),
  },
  {
    value: SETTING_CTA_LINK_TYPE_EXTERNAL,
    label: t('External'),
  },
];

export const SETTING_CTA_LANDING_PAGE_TYPE_APPLICATION =
  'SETTING_CTA_LANDING_PAGE_TYPE_APPLICATION';
export const SETTING_CTA_LANDING_PAGE_TYPE_AUDIO = 'SETTING_CTA_LANDING_PAGE_TYPE_AUDIO';
export const SETTING_CTA_LANDING_PAGE_TYPE_IMAGE = 'SETTING_CTA_LANDING_PAGE_TYPE_IMAGE';
export const SETTING_CTA_LANDING_PAGE_TYPE_MULTIPART = 'SETTING_CTA_LANDING_PAGE_TYPE_MULTIPART';
export const SETTING_CTA_LANDING_PAGE_TYPE_TEXT = 'SETTING_CTA_LANDING_PAGE_TYPE_TEXT';
export const SETTING_CTA_LANDING_PAGE_TYPE_VIDEO = 'SETTING_CTA_LANDING_PAGE_TYPE_VIDEO';
export const SETTING_CTA_LANDING_PAGE_TYPE_VND = 'SETTING_CTA_LANDING_PAGE_TYPE_VND';

export const SETTING_CTA_CONTENT_TYPE_LANDING_PAGE = 'SETTING_CTA_CONTENT_TYPE_LANDING_PAGE';
export const SETTING_CTA_CONTENT_TYPE_SITE = 'SETTING_CTA_CONTENT_TYPE_SITE';
export const SETTING_CTA_CONTENT_TYPE_SURVEY = 'SETTING_CTA_CONTENT_TYPE_SURVEY';

export const SETTING_CTA_CONTENT_TYPES: any = [
  {
    value: SETTING_CTA_CONTENT_TYPE_LANDING_PAGE,
    label: t('Landing Page'),
  },
  {
    value: SETTING_CTA_CONTENT_TYPE_SITE,
    label: t('Site'),
  },
  {
    value: SETTING_CTA_CONTENT_TYPE_SURVEY,
    label: t('Survey'),
  },
];

export const SETTING_CTA_CONTENT_TYPES_ENUM: any = [
  {
    value: 1,
    label: t('Landing Page'),
  },
  {
    value: 2,
    label: t('Site'),
  },
  {
    value: 3,
    label: t('Survey'),
  },
];

export const SETTING_CTA_LANDING_PAGE_TYPES: any = [
  {
    value: SETTING_CTA_LANDING_PAGE_TYPE_APPLICATION,
    label: t('Application'),
  },
  {
    value: SETTING_CTA_LANDING_PAGE_TYPE_AUDIO,
    label: t('Audio'),
  },
  {
    value: SETTING_CTA_LANDING_PAGE_TYPE_IMAGE,
    label: t('Image'),
  },
  {
    value: SETTING_CTA_LANDING_PAGE_TYPE_MULTIPART,
    label: t('Multipart'),
  },
  {
    value: SETTING_CTA_LANDING_PAGE_TYPE_TEXT,
    label: t('Text'),
  },
  {
    value: SETTING_CTA_LANDING_PAGE_TYPE_VIDEO,
    label: t('Video'),
  },
  {
    value: SETTING_CTA_LANDING_PAGE_TYPE_VND,
    label: t('VND'),
  },
];

export const SETTING_CTA_TEXT_FONT_WEIGHT_OPTIONS: any = [
  { value: 'lighter', label: 'Lighter' },
  { value: 'normal', label: 'Normal' },
  { value: 'bold', label: 'Bold' },
];

export const SETTING_CTA_STAGE_BUILD = 'SETTING_CTA_STAGE_BUILD';
export const SETTING_CTA_STAGE_DISABLE = 'SETTING_CTA_STAGE_DISABLE';
export const SETTING_CTA_STAGE_ENABLE = 'SETTING_CTA_STAGE_ENABLE';

export const SETTING_CTA_STAGE_OPTIONS: any[] = [
  {
    value: SETTING_CTA_STAGE_BUILD,
    label: t('Build'),
  },
  {
    value: SETTING_CTA_STAGE_DISABLE,
    label: t('Disable'),
  },
  {
    value: SETTING_CTA_STAGE_ENABLE,
    label: t('Enable'),
  },
];
