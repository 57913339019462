import { lazy } from 'react';

export const LanguageSelect = lazy(() => import('@base/containers/language-select'));
// export const SurveyTemplateSelect = lazy(
//   () => import('@settings/online-digital-content/survey/containers/survey-template-select'),
// );
export const SurveyForm = lazy(
  () => import('@settings/online-digital-content/survey/containers/survey-form'),
);
export const SiteTemplateSelect = lazy(
  () => import('@settings/template/containers/site-template-select'),
);
