import * as keyNames from '@settings/process/config/keyNames';
import {
  CheckBox,
  Input,
  TextArea,
  SelectBox,
  RadioGroup,
} from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';
import { OptionValue } from '@base/types/interfaces/common';
import { PROCESS_CLOSED_PROPERTY_OPTIONS, PROCESS_CLOSED_VIEW_OPTIONS } from '../constants';

const ClosedWriteField = {
  [keyNames.KEY_NAME_CLOSED_NAME]: {
    component: Input,
    showFullRow: false,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    languageKey: 'process_closed_name',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_CLOSED_DESCRIPTION]: {
    component: TextArea,
    showFullRow: true,
    languageKey: 'process_closed_description',
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_CLOSED_PROPERTY]: {
    component: SelectBox,
    showFullRow: true,
    defaultValue: PROCESS_CLOSED_PROPERTY_OPTIONS[0],
    languageKey: 'process_closed_property',
    componentProps: {
      options: PROCESS_CLOSED_PROPERTY_OPTIONS,
    },
    parseParam: (value: OptionValue) => value.keyName,
  },
  [keyNames.KEY_NAME_CLOSED_VIEW]: {
    component: RadioGroup,
    showFullRow: true,
    defaultValue: PROCESS_CLOSED_VIEW_OPTIONS[0],
    languageKey: 'process_closed_view',
    componentProps: {
      options: PROCESS_CLOSED_VIEW_OPTIONS,
      isHorizontal: true,
    },
    parseParam: (value: OptionValue) => value.keyName,
  },
  [keyNames.KEY_NAME_CLOSED_JUMP]: {
    component: CheckBox,
    showFullRow: true,
    defaultValue: 'no',
    componentProps: {
      label: 'Possible to jump from any step/status',
    },
    hideTitle: true,
    languageKey: 'process_closed_jump',
    parseParam: (value: string) => (value == 'yes' ? true : false),
  },
};

export default ClosedWriteField;
