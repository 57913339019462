import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'react-feather';
import QuestionTypes from '@settings/online-digital-content/survey/components/question-types';
import _ from 'lodash';

//render question types
const QuestionTitleType: React.FC<any> = (props) => {
  //props
  const {
    focusS,
    focusQ,
    keyS,
    keyQ,
    focusEle,
    selectedQType,
    title,
    onTitleChange,
    onQuestionTypeChange,
    onQuestionImageChange,
  } = props;
  //element id
  const qTitleEleId = 'q-title-' + keyS + '-' + (keyQ + 1);
  const qImageEleId = 'q-image-' + keyS + '-' + (keyQ + 1);

  //state
  const uploadImageQRef = useRef<any>(null); //for question image input
  //let initialRef = useRef<any>(null); //for only first Run
  const [text, setText] = useState<string>(title || '');
  const setTextDebounced = useRef(_.debounce((text) => onTitleChange(text), 100)).current;

  //monitor for open file dialog
  // useEffect(() => {
  //   if (focusEle) {
  //     const qImageEleId = 'q-image-' + keyS + '-' + (keyQ + 1); //for question image
  //     //open file dialog
  //     if (focusEle === qImageEleId) {
  //       if (!initialRef.current) {
  //         handleOpenUpload();
  //         initialRef = uploadImageQRef;
  //       }
  //     }
  //   }
  // }, []); //focusEle

  //open dialog file and upload
  function handleOpenUpload() {
    // `current` points to the mounted file input element
    if (uploadImageQRef.current) {
      uploadImageQRef.current.click();
    }
  }

  //get file and upload
  function handleImageQChange(e: any) {
    //get file
    const files = e.target.files;
    //// console.log('question images e', e);

    if (files.length > 0) {
      onQuestionImageChange({ name: files[0].name, url: files[0] });

      //call upload
      //let formData = new FormData();
      //formData.append('file', files[0]);
      // myAxios.post(apis.uploadImage, formData).then((res) => {
      //     setIsLoading(false);
      //     setFocusEle('');
      //     if (res.data.success) {
      //         //update image state
      //         const newImage = { ...imageQ };
      //         newImage.name = res.data.data.name;
      //         newImage.url = apis.getImage + '?path=' + res.data.data.path + res.data.data.name;
      //         //set local state
      //         setImageQ(newImage);

      //         //set in write component
      //         onImageQChange(keyQ, newImage);
      //     }
      // })
      // .catch(function (error) {
      //     //// console.log(error);
      // });
    }
  }

  //render
  return (
    <div className="survey-write-question-card-title-wrapper">
      <div className="survey-write-question-card-title-row">
        <div className="survey-write-question-card-title-input">
          {/* title */}
          <div className="survey-write-question-card-title-name">
            <input
              autoComplete="off"
              type="text"
              id={qTitleEleId}
              className="form-control survey-write-input"
              placeholder="Untitled Question"
              value={text}
              onChange={(e: any) => {
                setText(e.target.value);
                setTextDebounced(e.target.value);
              }}
              autoFocus={qTitleEleId === focusEle}
            />
          </div>

          {/* load image */}
          {/* {focusS === keyS && focusQ === keyQ + 1 && (
            <div className="survey-write-question-card-title-image">
              <a
                className="survey-home-header-button cursor-default"
                id={qImageEleId}
                onClick={handleOpenUpload}
              >
                <Image />
              </a>
              <input
                type="file"
                accept="image/*"
                //id={'qfile' + (keyQ + 1)}
                //name={'qfile' + (keyQ + 1)}
                ref={uploadImageQRef}
                style={{ display: 'none' }}
                onChange={handleImageQChange}
              />
            </div>
          )} */}

          {/* types */}
          {focusS === keyS && focusQ === keyQ + 1 && (
            <QuestionTypes
              keyS={keyS}
              keyQ={keyQ}
              selectedQType={selectedQType}
              onQuestionTypeChange={onQuestionTypeChange}
              focusEle={focusEle}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionTitleType;
