import React, { useEffect, useMemo, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { mergeLayoutData, getDataByKey } from '@base/utils/helpers/page-layout-utils';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { useGetView } from '@base/services/view';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import Title from '@base/containers/view-field/title';
import { SpanLang, Button } from '@base/components/form';
import { MENU_SETTING_CTA, MENU_TEMPLATE_CALL } from '@base/config/menus';
import Loading from '@base/components/loading';

import displayLayoutConfig from '@settings/template/config/display-layout';
import ViewLeft from '@settings/template/call/layouts/desktop/layout1/view/view-left';
import ViewCenter from '@settings/template/call/layouts/desktop/layout1/view/view-center';
import { callListFilterState } from '@settings/template/call/recoil/atom/list-atom';
import { default as viewConfig } from '@settings/template/call/config/view-field';
import { calllWriteOptionAtom } from '@settings/template/call/recoil/atom/write-atom'; //for write

//import WritePage from '../../../pages/write-form';
import { WritePage } from '../../../config/pages/main-page';
import { KEY_MENU_TEMPLATE_NAME } from '@settings/template/config/key-names';
import { getTabs } from './tabs';
import { SETTING_TEMPLATE_MENUS } from '@base/config/route-menus';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { DELETE_MENU_TEMPLATES } from '@settings/template/services/graphql';
import { toast } from 'react-toastify';
// import { useQueryClient } from 'react-query';
import { useQueryClient } from '@tanstack/react-query'; //v4
import NotFound from '@base/components/errors/notFound';
import { parseFields, parseFieldsList } from '../main/utils';
import Icon from '@base/assets/icons/svg-icons';
import PreviewTemplate from '@settings/template/containers/preview-template';
import ModalPreview from '@settings/template/containers/modal-preview';

interface IViewProps {
  isSplitMode?: boolean;
}

/**
 * Pages: detect layout, device, init props
 */
const ViewPage: React.FC<IViewProps> = (props: IViewProps) => {
  // console.log('view page ....');
  const { isSplitMode } = props;
  // recoil
  const listFilter = useRecoilValue(callListFilterState);
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_TEMPLATE_CALL));

  // params + state
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { activeId, activeTab } = listFilter;
  let id = activeId != params.id ? params.id ?? '' : activeId ?? '';
  let currentTab = params['*'] && activeTab != params['*'] ? params['*'] : activeTab;
  //for write form
  const [writeOption, setWriteOption] = useRecoilState(calllWriteOptionAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [dataPreview, setDataPreview] = useState<any>({ html: '', css: '' });
  const handlePreviewClick = () => {
    if (viewData?.menu) {
      let dataPreviewNew = getDataByKey(viewData, 'html');
      dataPreviewNew = JSON.parse(dataPreviewNew);
      setDataPreview(dataPreviewNew);
    }
    setIsPreview(true);
  };

  /** Hook + Get data */
  let { data: layoutView, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    MENU_SETTING_CTA,
    'view',
  );
  layoutView = parseFields(layoutView, writeOption.writeType);

  // // console.log('layoutView', layoutView);
  const viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  const {
    isLoading,
    data: tplData,
    refetch,
  } = useGetView<any>({ menu: 'setting_menuTemplateNew', schemas: viewSchema, params: { id } });

  // get detail data
  useEffect(() => {
    //if (!isLoading && !isLayoutLoading && campaignData) {
    const layoutData = mergeLayoutData(layoutView, tplData, viewConfig);
    let newLayoutData: any = { ...layoutData };
    newLayoutData.menuSourceId = id;
    newLayoutData.menuSource = MENU_TEMPLATE_CALL;
    setViewData(newLayoutData);
    //}
  }, [tplData, isLoading, isLayoutLoading]);

  // delete mutation
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_MENU_TEMPLATES,
    'setting_deleteMenuTemplates',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Deleted Template successfully!');
        setTimeout(() => {
          queryClient.invalidateQueries(['setting_menuTemplates']);
        }, 500);
        return navigate(`/settings/template/call`);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        toast.error('Deleted Template failed: ' + JSON.parse(error).message);
      },
    },
  );

  // render
  const renderView = () => {
    const keyTitle = `crm_new_${writeOption.writeType}`;
    //props config
    let pageProps: IDestopLayout1ViewProps = {
      headerProps: {
        menu: 'call', //key
        menus: SETTING_TEMPLATE_MENUS,
        isDeleting: false,
        onDelete: () => mutationDelete.mutate({ ids: [id] }),
        onNew: (category: string | undefined) => {
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: 'call',
            writeMenu: 'view',
          }));
        },
        splitViewMode: isSplitMode,
        componentLeft: (
          <>
            <Title
              keyName={KEY_MENU_TEMPLATE_NAME}
              value={tplData?.[KEY_MENU_TEMPLATE_NAME]}
              userPermission={{ isEdit: true }}
              onSave={(params: any) => {}}
              menuSourceId={id}
              menuSource={MENU_TEMPLATE_CALL}
              viewConfig={viewConfig}
            />
          </>
        ),
        componentRight: (
          // Show publish button when publish is unpublish
          <div className="">
            <Button
              color="primary"
              type="button"
              className="btn btn-primary"
              onClick={handlePreviewClick}
              loading={isPreview}
            >
              <span className="mg-r-5">{Icon('preview_template')}</span>
              {`PreView`}
            </Button>
          </div>
        ),
      },
      /*centerProps: {
        showTop: false, //send to all tab's component
        menuSource: MENU_TEMPLATE_CALL, //send to all tab's component
        menuSourceId: id, //send to all tab's component
        tabs: getTabs(),
        currentTab: currentTab,
      },*/
      componentTop: null,
      componentLeft: <ViewLeft id={id} />,
      leftProps: {},
      componentCenter: <ViewCenter menuSourceId={id} menuSource={MENU_TEMPLATE_CALL} />,
      componentRight: null,
      rightProps: {},
    };

    return (
      // <React.Suspense fallback={<></>}>
      <>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="view"
          {...pageProps}
        />
        {/* hook form - write new product write */}
        {writeOption.isOpenWrite &&
          writeOption.writeType === 'call' &&
          writeOption.writeMenu === 'view' && (
            <WritePage
              title={<SpanLang keyLang={keyTitle} />}
              size="lg"
              fullScreen={fullScreen}
              setFullScreen={setFullScreen}
              isOpen={writeOption.isOpenWrite}
              onClose={() =>
                setWriteOption((filter) => ({
                  ...filter,
                  isOpenWrite: false,
                }))
              }
              onReload={refetch}
              menuApi={MENU_TEMPLATE_CALL}
            />
          )}
        {isPreview && (
          <ModalPreview
            size="xl"
            title="Preview Template"
            value={dataPreview}
            onClose={() => setIsPreview(false)}
          />
        )}
      </>
      // </React.Suspense>
    );
  };

  // render
  return <>{isLoading || isLayoutLoading ? <Loading /> : tplData ? renderView() : <NotFound />}</>;
};

export default ViewPage;
