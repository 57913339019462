import Detail from '@settings/site/desk/components/detail';
import Timeline from '@base/containers/timeline';
import AllTickets from '@settings/site/desk/components/all-tickets';

export const getTabs: any = () => {
  return [
    {
      order: 1,
      default: true,
      label: 'Details',
      path: 'detail',
      tabComponent: Detail,
    },
    {
      order: 2,
      label: 'Timeline',
      path: 'timeline',
      tabComponent: Timeline,
    },
    {
      order: 3,
      label: 'All Tickets',
      path: 'allTickets',
      tabComponent: AllTickets,
    },
  ];
};
