import CommonViewField from '@base/containers/view-field/common';
import View from './ActionView';
import Write from './ActionWrite';

function ActionViewField(props: any) {
  return (
    <CommonViewField {...props} componentView={View} componentEdit={Write} value={props.value} />
  );
}

export default ActionViewField;
