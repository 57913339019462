import { atom } from 'recoil';

export const priorityAtom = atom({
  key: 'priorityAtom',
  default: {},
});

export const savingPreferencesAtom = atom({
  key: 'SavingPreferencesAtom',
  default: {
    key: '', //--> eg: 'setting_activity', ...
    isLoading: false,
    errors: {},
  },
});
