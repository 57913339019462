import { SETTING_TEMPLATE_MENUS } from '@base/config/route-menus';
import { IFilterByOption } from '@base/types/interfaces/common';
import { slideToObject } from '@base/utils/helpers/array-utils';
import { lazy } from 'react';

const Selectbox = lazy(() => import('@base/components/form/select'));
const LanguageSelect = lazy(() => import('@base/containers/language-select'));
import { UserAutoComplete } from '@base/containers';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import { parserIdNameToSelect } from '@base/utils/helpers/config-utils';
import {
  TEMPLATE_TYPE_OPTIONS_ENUM,
  TEMPLATE_STAGE_OPTIONS,
  TEMPLATE_TASK_TYPE_OPTIONS,
  TEMPLATE_TASK_TYPE_OPTIONS_ENUM,
} from '@settings/template/config/constants';
import * as keyNames from '@settings/template/config/key-names';
import { LabelValue } from '@base/types/types/app';

export const groupByOptions = [{ label: 'All', value: '' }];

export const dateByOptions = [
  { label: 'Created', value: 'createdAt' },
  { label: 'Updated', value: 'updatedAt' },
];

export const filterByOptions: IFilterByOption[] = [
  {
    label: 'Template Type',
    value: keyNames.KEY_MENU_TEMPLATE_TYPE,
    component: Selectbox,
    componentProps: {
      options: TEMPLATE_TYPE_OPTIONS_ENUM,
      isSearchable: false,
    },
    getValue: (componentValue: any) => {
      return componentValue?.value;
    },
    setValue: (value: any) => {
      return TEMPLATE_TYPE_OPTIONS_ENUM?.find((v: any) => v.value === value);
    },
  },
  {
    label: 'Task Type',
    value: keyNames.KEY_MENU_TEMPLATE_SUB_TYPE,
    component: Selectbox,
    componentProps: {
      options: TEMPLATE_TASK_TYPE_OPTIONS_ENUM,
      isSearchable: false,
    },
    getValue: (componentValue: any) => {
      return componentValue?.value;
    },
    setValue: (value: any) => {
      return TEMPLATE_TASK_TYPE_OPTIONS_ENUM?.find((v: any) => v.value === value);
    },
  },
  {
    label: 'Language',
    value: 'language',
    component: LanguageSelect,
    getValue: (componnetValue: any) => {
      return componnetValue?.value;
    },
  },
  {
    label: 'Product',
    value: keyNames.KEY_MENU_TEMPLATE_PRODUCT,
    component: ProductAutoComplete,
    componentProps: {
      single: true,
    },
    getValue: (value: any) => {
      //let format: any[] = parserIdNameToSelect(value, keyNames.KEY_MENU_TEMPLATE_PRODUCT);
      return value.id;
    },
    setValue: (value: any) => {
      //initial value for component
    },
  },
  {
    label: 'Owner',
    value: 'createdBy',
    component: UserAutoComplete,
    componentProps: {},
    getValue: (value: any) => {
      //return param for query
      return value.length > 0 ? value.map((v: any) => v.id).join(',') : '';
    },
    setValue: (value: any) => {
      //initial value for component
    },
  },
  {
    label: 'Active',
    value: keyNames.KEY_MENU_TEMPLATE_STAGE,
    component: Selectbox,
    componentProps: {
      options: TEMPLATE_STAGE_OPTIONS,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (val: LabelValue) => {
      // console.log('getvalue', val);
      return val?.value;
    },
    setValue: (val: string) => {
      // console.log('setvalue', val);
      return TEMPLATE_STAGE_OPTIONS.find((e) => e.value == val);
    },
  },
  // { label: 'View', value: 'view' },
  // { label: 'Click', value: 'click' },
  // { label: 'Click Rate', value: 'clickRate' },
];

export const searchFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    defaultValue: '',
  },
];

export const categoryOptions = slideToObject(SETTING_TEMPLATE_MENUS, 'value', 'label');
