import classnames from 'classnames';
import { SUBTABS } from '@settings/process/containers/list/Tabs';
import { Link } from 'react-router-dom';

interface TabListProps {
  selectedTab: string;
}

function TabList({ selectedTab }: TabListProps) {
  return (
    <ul className="nav nav-tabs table-tab mg-l-5">
      {SUBTABS.map((item, index) => (
        <li className="nav-item" key={index}>
          <Link to={`/settings/process/setting#${item.value}`}>
            <button
              className={classnames('btn nav-link', {
                active: item.value == selectedTab,
              })}
            >
              {item.label}
            </button>
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default TabList;
