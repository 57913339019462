import { StatusForm } from '@settings/process/types/process';
import {
  PROCESS_STATUS_DIRECTIONS_VIEW,
  PROCESS_STATUS_EVENTS_VIEW,
  PROCESS_STATUS_PROPERTIES_VIEW,
  PROCESS_STATUS_VIEWS_VIEW,
} from '@settings/process/config/constants';
import { useCallback } from 'react';
import React from 'react';
import _ from 'lodash';
import { statusWithParallelFilter } from '@settings/process/recoil/status';
import { useRecoilValue } from 'recoil';
import { checkParallel } from '@settings/process/utils/helper';

interface ActionItemViewProps {
  status: StatusForm;
  parallelIndex: number;
}

function ActionItemView(props: ActionItemViewProps) {
  const { status, parallelIndex } = props;
  const parallelCount = useRecoilValue(statusWithParallelFilter('normal')).length;
  const isParallel = checkParallel(status);

  const renderButton = useCallback(() => {
    return <div className="">{status.button}</div>;
  }, [status]);

  const renderView = useCallback(() => {
    return <div className="">{PROCESS_STATUS_VIEWS_VIEW[status.view.keyName]}</div>;
  }, [status]);

  const renderEvent = useCallback(() => {
    return <div className="">{PROCESS_STATUS_EVENTS_VIEW[status.event.keyName]}</div>;
  }, [status]);

  const renderSequence = useCallback(() => {
    return <div className="">{status.sequence[0] != '' && status.sequence.join('/')}</div>;
  }, [status]);

  return (
    <tr className="ht-55 text-center">
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{renderButton()}</td>
        ) : (
          ''
        )
      ) : (
        <td>{renderButton()}</td>
      )}
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{renderView()}</td>
        ) : (
          ''
        )
      ) : (
        <td>{renderView()}</td>
      )}
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{renderEvent()}</td>
        ) : (
          ''
        )
      ) : (
        <td>{renderEvent()}</td>
      )}
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{status.name}</td>
        ) : (
          ''
        )
      ) : (
        <td>{status.name}</td>
      )}
      <td>
        <div className="">{PROCESS_STATUS_DIRECTIONS_VIEW[status.direction.keyName]}</div>
      </td>
      <td>
        <div className="">{PROCESS_STATUS_PROPERTIES_VIEW[status.property.keyName]}</div>
      </td>
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{renderSequence()}</td>
        ) : (
          ''
        )
      ) : (
        <td>{renderSequence()}</td>
      )}
    </tr>
  );
}

export default React.memo(ActionItemView);
