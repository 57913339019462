import React, { Suspense, useMemo } from 'react';
import NoteAndError from '@base/components/hook-form/error-note';
import { useForm } from 'react-hook-form';
import { MENU_PROCESS_STAGE } from '@base/config/menus';
import { WriteParseFields } from '@base/utils/helpers/no-layout-utils';
import { Field } from '@base/components/hook-form';
import { useStageOpenAtomValue } from '@settings/process/recoil/diagram';
import Button from '@base/components/form/button';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query'; //v4
import { CREATE_PROCESS_STAGE } from '@settings/process/services/process';

interface StageWriteProps {
  processId: string;
  onClose?: () => void;
}

function StageWrite({ processId, onClose }: StageWriteProps) {
  // console.log('stage write');
  const { fields, getParams, defaultValues } = useMemo(
    () => WriteParseFields(MENU_PROCESS_STAGE),
    [],
  );
  const openValue = useStageOpenAtomValue();
  // console.log('step open Value', openValue);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  const queryClient = useQueryClient();
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_PROCESS_STAGE,
    'process_createStage',
    {
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Create Stage successfully!');

        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', processId]);
        }, 500);
        reset();
        onClose && onClose();
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Create Stage failed');
      },
    },
  );

  const onSubmit = (formData: any) => {
    // console.log(formData);
    const newParams = getParams(formData);
    // console.log('configParams => ', newParams);
    mutationAdd.mutate({
      id: processId,
      stage: { ...newParams, ...{ width: 180 } },
      linkStage: openValue.sourceId,
    });
  };

  const onFormSubmit = () => {
    handleSubmit((data) => onSubmit(data))();
  };

  const formHeight = 'calc(100vh - 215px)';

  return (
    <div className="d-flex flex-column pd-0-f" style={{ margin: '-10px' }}>
      <div className="bd-b pd-10">
        <NoteAndError errors={errors} />
      </div>
      <div
        className="pos-relative scroll-box"
        style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <form className="form">
          <div className="form-row pd-15">
            {fields.map((_item) => {
              return (
                <Suspense key={_item.keyName}>
                  <Field
                    item={{
                      ..._item,
                      componentProps: _item.componentProps,
                      columns: _item.columns,
                      hideTitle: _item.hideTitle, //hide title for a specific key name
                    }}
                    control={control}
                    errors={errors}
                  />
                </Suspense>
              );
            })}
          </div>
        </form>
      </div>
      <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <div className="d-flex pd-15 bd-t justify-content-between">
          <button type="button" className="btn btn-light" onClick={onClose}>
            Close
          </button>
          <Button
            color="success"
            onClick={onFormSubmit}
            type="button"
            disabled={mutationAdd.isLoading || !isValid}
            loading={mutationAdd.isLoading}
            name="Save"
          />
        </div>
      </div>
    </div>
  );
}

export default StageWrite;
