import React from 'react';
import {
  TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_PAGE,
  TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_RESOURCE,
  TICKET_FORM_SUBMISSION_DISPLAY_MESSAGE,
} from '@settings/online-digital-content/ticket-form/config/constants';

const View: React.FC = (props: any) => {
  const { value } = props;

  return (
    <>
      {value?.submissionDisplay === TICKET_FORM_SUBMISSION_DISPLAY_MESSAGE && (
        <div className="d-flex flex-column">
          <span>Thank you message</span>
          <span>{value?.displayMessage}</span>
        </div>
      )}

      {value?.submissionDisplay === TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_PAGE && (
        <div className="d-flex flex-column">
          <span>Redirect to another page</span>
          <span>{value?.linkToPage}</span>
        </div>
      )}

      {value?.submissionDisplay === TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_RESOURCE && (
        <div className="d-flex flex-column">
          <span>Link to</span>
          <span>{value?.linkToResource?.name}</span>
        </div>
      )}
    </>
  );
};

export default View;
