import * as keyNames from '@settings/template/config/key-names';
import * as commonComponents from '@base/config/view-field/components';
import * as components from './components';

import {
  TEMPLATE_TYPE_OPTIONS,
  TEMPLATE_STAGE_OPTIONS,
  TEMPLATE_TASK_TYPE_OPTIONS,
  TEMPLATE_MESSAGE_TYPE_OPTIONS,
} from '../../../config/constants';
import * as commonConfig from '@base/config/view-field';
import { IFieldConfig } from '@base/types/interfaces/common';
import {
  parserIdNameToSelect,
  parserSelectToIdName,
  parserSelectToIdNameAndKey,
  parserSelectToIdNameObj,
} from '@base/utils/helpers/config-utils';

const configView: IFieldConfig = {
  ...commonConfig?.default,

  [keyNames.KEY_MENU_TEMPLATE_TYPE]: {
    component: commonComponents.Selectbox,
    schema: `type`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
    componentProps: {
      isSearchable: false,
      options: TEMPLATE_TYPE_OPTIONS,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (apiData: any) => {
      return TEMPLATE_TYPE_OPTIONS?.find(
        (v: any) => v.value === apiData?.[keyNames.KEY_MENU_TEMPLATE_TYPE],
      );
    },
    getValueView: (value: any) => {
      return value?.label;
    },
    getMutationValue: (value: any) => {
      return value.value;
    },
  },
  [keyNames.KEY_MENU_TEMPLATE_SUB_TYPE]: {
    component: commonComponents.Selectbox,
    schema: `subType`,
    viewProps: {
      userPermission: { isEdit: true, isShow: true },
    },
    componentProps: {
      isSearchable: false,
      options: TEMPLATE_TASK_TYPE_OPTIONS,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (apiData: any) => {
      return TEMPLATE_TASK_TYPE_OPTIONS?.find(
        (v: any) => v.value === apiData?.[keyNames.KEY_MENU_TEMPLATE_SUB_TYPE],
      );
    },
    getValueView: (value: any) => {
      return value?.label;
    },
    getMutationValue: (value: any) => {
      return value.value;
    },
  },

  [keyNames.KEY_MENU_TEMPLATE_PRODUCT]: {
    languageKey: 'crm_new_menu_product',
    schema: `
    isAllProducts
    products{
        id
        name
    }`,
    component: commonComponents.ProductSelect,
    getValue: (value: any) => {
      let format: any[] = parserIdNameToSelect(value, keyNames.KEY_MENU_TEMPLATE_PRODUCT);
      if (value?.isAllProducts == true) {
        format = [{ id: 'all', name: 'All', label: 'All' }];
      }
      return format;
    },
    getValueView: (value: string) => value,
    getValueEdit: (value: string) => value,
    getMutationValue: (value: any[]) => {
      let format: any = parserSelectToIdNameAndKey(value, keyNames.KEY_MENU_TEMPLATE_PRODUCT);
      if (format.products?.[0]?.id === 'all') {
        format.isAllProducts = true;
        format.products = [];
      }
      return format;
    },
  },
  [keyNames.KEY_MENU_TEMPLATE_OWNER]: {
    languageKey: 'setting_cta_field_basic_createdby',
    schema: `assignTo{
        id
        name
    }`,
    component: commonComponents.AssignRepView,
    getValue: (value: any) => {
      let format: any[] = parserIdNameToSelect(value, keyNames.KEY_MENU_TEMPLATE_OWNER);
      return format;
    },
    /*getValueView: (value: any) => {
      return value && value?.length > 0 ? value.map((item: any) => item?.name).join(', ') : '';
    },*/
    getValueEdit: (value: string) => value,
    getMutationValue: (value: any[]) => {
      let format: any[] = parserSelectToIdNameObj(value, 'user');
      return {
        [keyNames.KEY_MENU_TEMPLATE_OWNER]: format,
      };
    },
  },
  [keyNames.KEY_MENU_TEMPLATE_LANGUAGE]: {
    component: components.LanguageSelectView,
    schema: `language`,
    componentProps: {
      options: [],
    },
    getValueView: (value: any) => {
      return value;
    },
  },

  [keyNames.KEY_MENU_TEMPLATE_DESCRIPTION]: {
    component: commonComponents.TextAreaView,
    schema: `description`,
  },
  [keyNames.KEY_MENU_TEMPLATE_DESIGN]: {
    schema: `html`,
    component: null,
    getValueEdit: (value: any) => {
      // console.log('...getValueEdit...', value);
      return value;
    },
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_MENU_TEMPLATE_DESIGN]: JSON.stringify(value),
      };
    },
  },
  [keyNames.KEY_MENU_TEMPLATE_DESIGN_VIEW]: {
    schema: `html`,
    component: null,
    viewProps: {
      defaultShowEdit: true,
    },
    getValueEdit: (value: any) => {
      // console.log('...getValueEdit...', value);
      return value;
    },
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_MENU_TEMPLATE_DESIGN]: JSON.stringify(value),
      };
    },
  },
  [keyNames.KEY_MENU_TEMPLATE_STAGE]: {
    schema: `stage`,
    component: commonComponents.Selectbox,
    componentProps: {
      options: TEMPLATE_STAGE_OPTIONS,
      isSearchable: false,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (apiData: any) => {
      return (
        TEMPLATE_STAGE_OPTIONS?.find(
          (v: any) => v.value === apiData?.[keyNames.KEY_MENU_TEMPLATE_STAGE],
        ) ?? null
      );
    },
    getValueView: (value: any) => {
      return value?.label ?? '';
    },
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_MENU_TEMPLATE_STAGE]: value.value,
      };
    },
  },
};

export default configView;
