import React, { useEffect } from 'react';
import useMutationPost from '@base/hooks/useMutationPost';
import withMiModal from '@base/hooks/hocs/withMiModal';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input, TextArea } from '@base/components/form';
import ProductGroupAutoComplete from '@product/product/containers/product-group-auto-complete';
import { PRODUCT_GROUP_CREATE, PRODUCT_GROUP_UPDATE } from '@settings/preferences/services/graphql';

interface IWriteFolderFormProps {
  fullScreen?: boolean;
  category?: any;
  data?: any;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (params: any) => void;
}

const WriteProductGroupForm: React.FC<IWriteFolderFormProps> = (props) => {
  const { onClose, onSuccess, category, data, isOpen, fullScreen = false } = props;
  //state

  //hook form
  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: '',
      parent: null, // --> category/folder
      description: '',
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //init data for Edit
  useEffect(() => {
    if (data) {
      if (data.name) {
        setValue('name', data.name, { shouldValidate: true });
      }
      if (data.parent) {
        setValue('parent', data.parent || '', { shouldValidate: true });
      }
      if (data.description) {
        setValue('description', data.description || '');
      }
    } else {
      reset();
    }
  }, [data]);

  //mutation definition
  const mCreate: any = useMutationPost(PRODUCT_GROUP_CREATE, 'product_createGroup');
  const mUpdate: any = useMutationPost(PRODUCT_GROUP_UPDATE, 'product_updateGroup');

  //check create success
  useEffect(() => {
    if (mCreate.isSuccess) {
      onSuccess && onSuccess({ ...mCreate.variables.group, id: mCreate.data.id });
      onClose && onClose();
    }
  }, [mCreate.isSuccess]);

  //check create success
  useEffect(() => {
    if (mUpdate.isSuccess) {
      onSuccess && onSuccess({ ...mUpdate.variables.group });
      onClose && onClose();
    }
  }, [mUpdate.isSuccess]);

  //submit form
  const onSubmit = (formData: any) => {
    const newParams: any = {
      name: formData.name,
      //description: formData.description,
    };
    if (formData.parent) {
      newParams.parent = {
        id: formData.parent.id,
        name: formData.parent.name,
      };
    }
    if (data?.id) {
      newParams.id = data?.id;
      mUpdate.mutate({ group: newParams });
    } else {
      mCreate.mutate({ group: newParams });
    }
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    // console.log('error', errors, e);
  };

  //form fields
  const renderFields = () => {
    // let formHeight = 'calc(100vh - 370px)';
    // if (fullScreen) {
    //   formHeight = 'calc(100vh - 170px)';
    // }
    return (
      <div
        className="pd-15 form-row scroll-box"
        //style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <div className="form-group col-12">
          <label className="form-item-title">
            Name
            <span className="tx-danger" title="필수 입력">
              *
            </span>
          </label>
          <Controller
            name={'name'}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }: any) => {
              return <Input type="text" value={value} onChange={onChange} />;
            }}
          />
        </div>
        <div className="form-group col-12">
          <label className="form-item-title">Parent Group</label>
          <Controller
            name={'parent'}
            control={control}
            render={({ field: { value, onChange } }: any) => {
              return <ProductGroupAutoComplete single={true} value={value} onChange={onChange} />;
            }}
          />
        </div>
        <div className="form-group col-12">
          <label className="form-item-title">Description</label>
          <Controller
            name={'description'}
            control={control}
            render={({ field: { value, onChange } }: any) => {
              return <TextArea value={value} onChange={onChange} />;
            }}
          />
        </div>
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <Button
            color="success"
            onClick={() => {
              handleSubmit((data) => onSubmit(data), onError)();
            }}
            disabled={mCreate.isLoading || mUpdate.isLoading || !isValid}
            loading={mCreate.isLoading || mUpdate.isLoading}
            name="Save"
          />
          {/* <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                handleSubmit((data) => onSubmit({ formData: data, isPublic: true }), onError)();
              }}
              disabled={parentValue?.display === EDisplay.PRIVATE || mCreate.isLoading || mUpdate.isLoading || !isValid}
              loading={mCreate.isLoading || mUpdate.isLoading}
              name="Save as Public"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  handleSubmit((data) => onSubmit({ formData: data, isPublic: false }), onError)();
                }}
                disabled={mCreate.isLoading || mUpdate.isLoading || !isValid}
                loading={mCreate.isLoading || mUpdate.isLoading}
                name="Save as Private"
              />
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  //// console.log('form values', getValues()); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>is required field</span>
          </span>
        </div>
      </div>
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        {renderFields()}
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(WriteProductGroupForm);
