import { lazy } from 'react';

const SmsMain = lazy(() => import('./main'));

export default [
  {
    path: '/settings/sms/*',
    component: SmsMain,
  },
];
