import React from 'react';
import { Button } from '@base/components/form';

interface IProps {
  title: any;
  isAdd?: boolean;
  onAdd?: () => void;
  children: any;
  disabled?: boolean;
  labelBtn?: any;
}

const CardManagement: React.FC<IProps> = (props: IProps) => {
  const {
    title = '',
    isAdd = false,
    onAdd = () => {},
    children = null,
    disabled = false,
    labelBtn = '',
  } = props;
  return (
    <div className="card">
      <div
        className="card-header d-flex tx-semibold align-items-center"
        style={{ padding: '10px 20px' }}
      >
        <div style={{ flex: 1 }}>{title}</div>
        {isAdd && (
          <Button color="primary" icon="Plus" disabled={disabled} onClick={onAdd}>
            {labelBtn}
          </Button>
        )}
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default CardManagement;
