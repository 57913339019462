import React, { useState, useEffect, Fragment } from 'react';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import Button from '@base/components/form/button';
import { ArrowLeft, ArrowRight } from 'react-feather';
import Field from '@base/components/hook-form/field';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import Loading from '@base/components/loading';
import * as keyNames from '@settings/online-digital-content/survey/config/key-names';
import { finalizeParams } from './payload';
import { SURVEY_CREATE } from '@settings/online-digital-content/survey/services/graphql';

const TABS = [
  {
    value: 1,
    label: 'Basic Info',
    keys: [
      keyNames.KEY_SURVEY_NAME,
      keyNames.KEY_SURVEY_TITLE,
      // keyNames.KEY_SURVEY_TYPE,
      keyNames.KEY_SURVEY_LANGUAGE,
      keyNames.KEY_SURVEY_DESCRIPTION,
    ],
  },
  // {
  //   value: 2,
  //   label: 'Select a Template',
  //   keys: [keyNames.KEY_SURVEY_TEMPLATE],
  // },
  {
    value: 2,
    label: 'Design Questions',
    keys: [keyNames.KEY_SURVEY_CONTENT],
  },
];

interface WriteFormProps {
  id?: string; //survey id - Edit
  fullScreen: boolean;
  menuApi: string;
  onClose: () => void;
  onReload?: () => void;
  //with write form
  defaultValues: any;
  fields: any[];
  tabs: any[];
  getParams: any;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<WriteFormProps> = (props: WriteFormProps) => {
  const { id, fullScreen, defaultValues, fields, onClose, onReload, getParams } = props;

  //state
  const [isReset, setIsReset] = useState(false);
  const [formStep, setFormStep] = useState(TABS[0].value);
  const [isFinish, setIsFinish] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //TODO: hook to get survey questions
  // const { data: surveyData, isLoading: isDataLoading, refetch } = usePost<BaseResponse<any>>(
  //   ['survey_questions', id],
  //   GET_SURVEY_QUESTIONS,
  //   { id },
  //   { enabled: id !== undefined && id !== '' }
  // );

  //init survey content - edit case
  // useEffect(() => {
  //   if (!isDataLoading && surveyData) {
  //     //TODO
  //   }
  // }, [surveyData]);

  // create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    SURVEY_CREATE,
    'setting_createSurvey',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Created Survey successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Created Survey failed: ' + JSON.parse(error).message);
      },
    },
  );

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationAdd.isSuccess) {
      if (isReset) {
        reset();
      } else {
        onClose();
      }
      // refecth data
      onReload && onReload();
    }
  }, [mutationAdd.isSuccess]);

  //watching

  //submit form
  const onSubmit = (formData: any) => {
    let configParams = getParams(formData);
    let newParams = finalizeParams(configParams);
    newParams.isTemplate = false;
    mutationAdd.mutate({ survey: newParams });
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    //// console.log('error', errors, e);
    toast.error('Created Survey failed: ' + JSON.stringify(errors));
  };

  /** ============================== EVENT ==================================== */
  //go to next step - set data
  const goNextStep = () => {
    setFormStep((cur) => cur + 1);
    if (formStep + 1 === 2) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null, { shouldValidate: true });
    }
    if (formStep + 1 === 3) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null, { shouldValidate: true });
    }
  };

  //go previous step - reset data
  const goPreviousStep = () => {
    setFormStep((cur) => cur - 1);
    if (formStep - 1 === 2) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null);
    }
    if (formStep - 1 === 1) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null);
    }
  };

  //======================== RENDER FORM ========================//
  //step tabs
  const renderFormSteps = () => {
    return (
      <div className="w-100 pd-b-10">
        <ul className="steps steps-justified steps-tab w-100">
          {TABS.map((_item: any, index: number) => (
            <li
              key={index}
              className={classnames('step-item', {
                complete: _item.value < formStep,
                active: _item.value == formStep,
                disabed: _item.value > formStep,
              })}
            >
              <button
                type="button"
                className="btn step-link rounded-0"
              // onClick={(e) => setFormStep(_item.value)}
              >
                <span className="step-number">{_item.value}</span>
                <span className="step-title">{_item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  //form view
  const renderForm = () => {
    return (
      <div className="form-row pd-x-15">
        {TABS.map((_step: any) => {
          if (formStep >= _step.value) {
            return fields.map((_item: any, index: number) => {
              const isBelongsTo = _step.keys.includes(_item.keyName);
              if (isBelongsTo) {
                let newComponentProps = { ..._item?.componentProps };
                // custom field's props
                let newItem = { ..._item };
                if (_item.keyName === keyNames.KEY_SURVEY_CONTENT) {
                  newItem.hideTitle = true;
                  newComponentProps.fullScreen = fullScreen;
                }
                if (_item.keyName === keyNames.KEY_SURVEY_TEMPLATE) {
                  newItem.hideTitle = true;
                }
                newItem.componentProps = newComponentProps;

                return (
                  <Fragment key={101 + index}>
                    <Field
                      key={102 + index}
                      item={newItem}
                      control={control}
                      errors={errors}
                      isHidden={formStep != _step.value}
                    />
                  </Fragment>
                );
              }
            });
          }
        })}
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <Button color="link" className="link-03" style={{ textDecoration: 'underline' }} onClick={onClose}>
          Close
        </Button>
        <div className="mg-l-auto">
          {formStep > 1 && (
            <button type="button" className="btn btn-light mg-r-5" onClick={goPreviousStep}>
              <ArrowLeft />
              Previous
            </button>
          )}
          {formStep < 2 && (
            <button
              disabled={!isValid}
              type="button"
              className="btn btn-primary"
              onClick={goNextStep}
            >
              Next
              <ArrowRight />
            </button>
          )}
          {formStep === 2 && (
            <>
              {/* <Button
                className="mg-r-5"
                color="secondary"
                onClick={() => {
                  setIsReset(false);
                  setIsFinish(false);
                  setIsArchived(true);
                  handleSubmit((data) => onSubmit(data), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                children="Archived"
              />
              <Button
                className="mg-r-5"
                color="primary"
                onClick={() => {
                  setIsReset(false);
                  setIsFinish(true);
                  setIsArchived(false);
                  handleSubmit((data) => onSubmit(data), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                children="Finish"
              /> */}
              <Button
                color="success"
                onClick={() => {
                  setIsReset(false);
                  setIsFinish(false);
                  setIsArchived(false);
                  handleSubmit((data) => onSubmit(data), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                children="Save"
              />
            </>
          )}
        </div>
      </div>
    );
  };

  // ======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  // // console.log('form api values', getParams(getValues())); //get form values when inputing
  // // console.log('form errors', errors);
  // // console.log('form fields', fields); //All fields from pagelayout
  // ======================== End Debug ========================//

  //render
  let formHeight = 'calc(100vh - 280px)';
  if (fullScreen) {
    formHeight = 'calc(100vh - 205px)';
  }

  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>You cannot except a required field</span>
          </span>
        </div>
      </div>
      {renderFormSteps()}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        <div
          className={classNames('', {
            'scroll-box': formStep !== 3,
            'overflow-hidden': formStep === 3,
          })}
          style={{ minHeight: '250px', maxHeight: formHeight, overflowX: 'hidden' }}
        >
          <React.Suspense fallback={<Loading />}>{renderForm()}</React.Suspense>
        </div>
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(withWriteForm(WriteForm));
