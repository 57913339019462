import { gql } from 'graphql-request';

export const LANGUAGE_VALUE = `
  language{
    en
    vi
    ko
    jp
    zh
    ido
  }
`;

export const ATTRIBUTES = `
  attributes {
    keyName
    value
    defaultValue
    languageKey
  }
`;

export const OPTIONS = `
  options {
    id
    keyName
    languageKey
    ${LANGUAGE_VALUE}
    dataType
    ${ATTRIBUTES}
  }
`;

export const PERMISSION = `
  permission {
    canMarkRequired
    canSetShowInList
    canSetShowInView
    canSetShowInWrite
    canMoveUnused
    canDelete
    canEditProperty
    canShowSettingButton
    canMovePosition
    settingButtonTooltip
    canChangeFieldName
  }
`;

export const FIELD_ICON = `
  icon {
    icon
    iconType
  }
`;

export const FIELD_DATA = `
    id
    templateFieldId
    keyName
    dataType
    permissionType
    languageKey
    showInList
    showInView
    showInWrite
    defaultViewInList
    orderInList
    orderInView
    orderInWrite
    isBasicField
    isCustom
    isUsed
    ${ATTRIBUTES}
    ${OPTIONS}
    ${PERMISSION}
    ${FIELD_ICON}
    ${LANGUAGE_VALUE}
`;

export const FIELD_CREATE_DATA = `
    id
    keyName
    dataType
    languageKey
    ${LANGUAGE_VALUE}
    showInList
    showInView
    showInWrite
    defaultViewInList
    orderInList
    orderInView
    orderInWrite
    isBasicField
    isCustom
    deleted
    ${ATTRIBUTES}
    ${OPTIONS}
    ${PERMISSION}
    permissionType
    ${FIELD_ICON}
`;

export const SECTION = `
    ${FIELD_DATA}
    children {
      ${FIELD_DATA}
    }
`;

export const GET_PAGE_LAYOUT_MENUS = gql`
  query {
    setting_menus {
      results {
        id
        keyName
        languageKey
        icon {
          icon
          iconType
        }
        layouts {
          id
          keyName
          languageKey
        }
      }
    }
  }
`;

export const GET_LOOKUP_DATA_SOURCES = gql`
  query {
    setting_pagelayoutDataSource {
      results {
        key
        name
      }
    }
  }
`;

export const GET_PAGE_LAYOUT_BASIC_FIELDS = gql`
  query {
    setting_basicFields {
      results {
        type
        name
        languageKey
        fieldIcon {
          icon
          iconType
        }
        attributes {
          keyName
          languageKey
          value
          defaultValue
        }
      }
    }
  }
`;
// # ${ATTRIBUTES}
//# ${PERMISSION}

export const GET_PAGE_LAYOUT_FIELD = gql`
  query ($layoutId: String, $fieldId: String, $dataType: String!, $device: String!) {
    setting_field(id: $layoutId, fieldId: $fieldId, dataType: $dataType, device: $device) {
      ${FIELD_DATA}
    }
  }
`;

export const GET_PAGE_LAYOUT_DATA = gql`
  query q($layoutId: String!, $device: String!) {
    setting_pagelayout(id: $layoutId, device: $device) {
      id
      keyName
      languageKey
      ${ATTRIBUTES}
      sections {
        ${SECTION}
      }
      
    }
    
  }
`;
export const GET_PAGE_LAYOUT_USUSED_FIELDS = gql`
  query q($layoutId: String!, $device: String!) {
    setting_unUsedFields(id: $layoutId, device: $device) {
      results {
        ${FIELD_DATA}
      }
    }
  }
`;

export const GET_PAGE_LAYOUT_FIELD_CREATE = gql`
  query ($id: String, $dataType: String!) {
    setting_field(id: $id, dataType: $dataType) {
      ${FIELD_DATA}
    }
  }
`;

export const CREATE_PAGE_LAYOUT_FIELD = gql`
  mutation q($pgField: IPgField) {
    setting_createField(pgField: $pgField) {
      id
    }
  }
`;
export const UPDATE_PAGE_LAYOUT_FIELD = gql`
  mutation q($pgField: PgField) {
    setting_updateField(pgField: $pgField) {
      id
    }
  }
`;
export const DELETE_PAGE_LAYOUT_FIELD = gql`
  mutation q($id: String, $pagelayoutId: String) {
    setting_deleteField(id: $id, pagelayoutId: $pagelayoutId) {
      id
    }
  }
`;
export const UPDATE_PAGE_LAYOUT_TPL_FIELD = gql`
  mutation q($tplField: TemplateField, $id: String) {
    setting_updateTemplateField(tplField: $tplField, id: $id) {
      id
    }
  }
`;
export const REORDER_PAGE_LAYOUT_FIELDS = gql`
  mutation q($templateFields: [OrderField]) {
    setting_sortTemplateFields(templateFields: $templateFields) {
      ids
    }
  }
`;
