import { Button } from '@base/components/form';
import { ActionProperty } from '@settings/process/types/settings';
import { RefreshCw } from 'react-feather';
import Toolbar from './Toolbar';

interface ListHeaderProps {
  title: string;
  actions?: ActionProperty[];
  selected?: ActionProperty;
  onSelect?: (action: ActionProperty) => void;
  handleAdd?: () => void;
  onRefresh?: () => void;
}

function ListHeader(props: ListHeaderProps) {
  const { title, actions, onSelect, selected, handleAdd, onRefresh } = props;
  return (
    <div className="list-header">
      <div className="d-flex align-items-center mg-r-10 mg-b-5">
        <h1 className="list-header-title">{title}</h1>
      </div>
      <div className="d-flex align-items-center mg-l-auto mg-b-5">
        {onSelect && actions && (
          <div className="btn-group dropdown">
            <Button
              color="primary"
              icon="Plus"
              className="pd-x-10"
              onClick={() => onSelect && onSelect(selected ?? actions[0])}
            >
              New
            </Button>
            <Toolbar actions={actions} onSelect={onSelect} />
          </div>
        )}
        {handleAdd && (
          <Button color="primary" icon="Plus" onClick={() => handleAdd()}>
            New
          </Button>
        )}
        {onRefresh && (
          <button type="button" className="btn btn-icon" aria-label="reload" onClick={onRefresh}>
            <RefreshCw />
          </button>
        )}
      </div>
    </div>
  );
}

export default ListHeader;
