export const KEY_SITE_NAME = 'name';
export const KEY_SITE_TYPE = 'type';
export const KEY_SITE_LANGUAGE = 'language';
export const KEY_SITE_PRODUCT = 'products';
export const KEY_SITE_DESCRIPTION = 'description';
export const KEY_SITE_TEMPLATE = 'template';
export const KEY_SITE_SUBJECT = 'subject';
export const KEY_SITE_DESGIN = 'html';

export const KEY_SITE_CREATED_BY = 'createdBy';
export const KEY_SITE_CREATED_AT = 'createdAt';
export const KEY_SITE_UPDATED_BY = 'updatedBy';
export const KEY_SITE_UPDATED_AT = 'updatedAt';
