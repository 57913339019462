import { SpanLang } from '@base/components';
import { DEVICE_DESKTOP, DEVICE_PHONE, DEVICE_TABLET } from '@base/config/constant';
import classNames from 'classnames';
import React from 'react';

const DEVICES = [
  {
    id: DEVICE_DESKTOP,
    label: 'crm_new_common_desktop',
  },
  {
    id: DEVICE_PHONE,
    label: 'crm_new_common_mobile',
  },
  {
    id: DEVICE_TABLET,
    label: 'crm_new_common_tablet',
  },
];
interface IDeviceProps {
  onChange: (params: any) => void;
  device: string;
  disabled: boolean;
}
const Device: React.FC<IDeviceProps> = (props: IDeviceProps) => {
  const { onChange, device, disabled } = props;
  const getColor = ({ itemID, device }: any) => {
    if (disabled) {
      if (itemID == device) {
        return '#0168fa';
      } else {
        return 'rgba(89, 104, 130, 0.5)';
      }
    } else {
      if (itemID == device) {
        return '#0168fa';
      } else {
        return '#596882';
      }
    }
  };
  return (
    <nav className="nav flex-nowrap">
      {DEVICES.map((item) => (
        <a
          key={item.id}
          style={{
            color: getColor({ itemID: item.id, device: device }),
            cursor: 'pointer',
          }}
          className={classNames('nav-link', {
            active: item.id == device,
          })}
          onClick={(e) => {
            e.preventDefault();
            !disabled && onChange(item);
          }}
        >
          <SpanLang keyLang={`${item.label}`} />
        </a>
      ))}
    </nav>
  );
};

export default Device;
