import { Input } from '@base/components/form';
import { useCallback } from 'react';
import { IdName } from '@base/types/interfaces/common';
import { useRecoilState } from 'recoil';
import statusAtom from '@settings/process/recoil/status';

interface CriteriaStatusProps {
  mode: 'edit' | 'view';
}

function CriteriaStatus({ mode }: CriteriaStatusProps) {
  const [statusesValue, setStatusesValue] = useRecoilState(statusAtom);
  const statusCount = statusesValue.length;

  const onChangeName = useCallback(
    (newValue: string, id: string) => {
      setStatusesValue((old) => {
        const targetIndex = old.findIndex((status) => status.id === id);
        const targetValue = { ...old[targetIndex], name: newValue };
        return [...old.slice(0, targetIndex), targetValue, ...old.slice(targetIndex + 1)];
      });
    },
    [statusesValue],
  );

  return (
    <div className="form-group col-12">
      <div className="pd-15 mg-b-30">
        <label className="form-item-title">Set Status for Process</label>
        {statusesValue.map((status, index) => {
          if (statusCount == index + 1) return;
          const isBackward = statusCount - 2 == index;
          let backward: IdName = { id: '', name: '' };
          if (isBackward) {
            const nextStatus = statusesValue[index + 1];
            backward = {
              id: nextStatus.id,
              name: nextStatus.name,
            };
          }
          return (
            <div key={status.id} className="diagram-item diagram-criteria with-boolean-direction">
              <div className="criteria-shape"></div>
              <div className="diagram-item-name">{status.button}</div>
              <div className="direction-true">
                <div className="true-status">
                  {mode == 'edit' ? (
                    <Input
                      className="wd-150-f"
                      value={status.name}
                      placeholder={'Status name'}
                      onChange={(newValue: string) => onChangeName(newValue, status.id)}
                    />
                  ) : (
                    `${status.name}`
                  )}
                </div>
              </div>
              <div className="direction-false">
                {isBackward && (
                  <div className="false-status">
                    {mode == 'edit' ? (
                      <Input
                        className="wd-150-f"
                        value={backward.name}
                        placeholder={'Status name'}
                        onChange={(newValue: string) => onChangeName(newValue, backward.id)}
                      />
                    ) : (
                      `${backward.name}`
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CriteriaStatus;
