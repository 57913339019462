import { IdName } from '@base/types/interfaces/common';
import {
  CriteriaView,
  WaitView,
  SiteView,
  ActionView,
  AutomationView,
  ChecklistView,
} from '@settings/process/config/view-field/components';
import * as keyNames from '@settings/process/config/keyNames';
import { AssignRepView, TextAreaView, TextView } from '@base/config/view-field/components';
import { Automation } from '@settings/process/types/process';
import { StatusView } from '@settings/process/components/diagram/status';

export const StepViewField: { [index: string]: any } = {
  [keyNames.KEY_NAME_STEP_TYPE]: {
    languageKey: 'process_step_type',
    showFullRow: false,
    isFieldEdit: false,
    component: TextView,
  },
  [keyNames.KEY_NAME_STEP_NAME]: {
    languageKey: 'process_step_name',
    showFullRow: true,
    component: TextView,
    // getValueView: (value: string) => value,
    // getValueEdit: (value: string) => value,
    // getMutationValue: (value: string) => ({
    //   [keyNames.KEY_NAME_STEP_NAME]: value,
    // }),
  },
  [keyNames.KEY_NAME_STEP_DESCRIPTION]: {
    languageKey: 'process_step_description',
    showFullRow: true,
    component: TextAreaView,
  },
  [keyNames.KEY_NAME_STEP_DO]: {
    languageKey: 'process_step_do',
    showFullRow: false,
    isFieldEdit: false,
    component: TextView,
    componentProps: {
      steptype: ['action', 'criteria'],
    },
    getValueView: (value: IdName) => {
      if (value) {
        return value.name;
      } else {
        return 'Manual';
      }
    },
  },
  [keyNames.KEY_NAME_STEP_ACTION]: {
    showFullRow: true,
    hideTitle: true,
    component: ActionView,
    componentProps: {
      steptype: 'action',
    },
  },
  [keyNames.KEY_NAME_STEP_SITE]: {
    showFullRow: true,
    hideTitle: true,
    component: SiteView,
    componentProps: {
      steptype: 'site',
    },
  },
  [keyNames.KEY_NAME_STEP_WAIT]: {
    showFullRow: true,
    hideTitle: true,
    languageKey: '',
    component: WaitView,
    componentProps: {
      steptype: 'wait',
    },
    getMutationValue: (value: any) => {
      let wait: any = {};
      if (value.type == 'WAIT_UNTIL_DATE_TIME') {
        wait.datetime = value.datetime;
      } else if (value.type == 'WAIT_BY_DURATION') {
        wait.duration = value.duration;
      } else if (value.type == 'WAIT_UNTIL_TRIGGER') {
        wait.trigger = value.trigger;
      } else if (value.type == 'WAIT_SCHEDULE_ATTRIBUTE') {
        wait.schedule = {
          duration: value.schedule.duration,
          when: value.schedule.when,
          attr: value.schedule.attr,
        };
      }
      return {
        [keyNames.KEY_NAME_STEP_WAIT]: wait,
      };
    },
  },
  [keyNames.KEY_NAME_STEP_CRITERIA]: {
    component: CriteriaView,
    showFullRow: true,
    languageKey: 'process_step_criteria_rule',
    componentProps: {
      steptype: 'criteria',
    },
  },
  [keyNames.KEY_NAME_STEP_CHECKLIST]: {
    component: ChecklistView,
    hideTitle: true,
    showFullRow: true,
    componentProps: {
      steptype: 'checklist',
    },
  },
  [keyNames.KEY_NAME_STEP_ASSIGN_USER]: {
    component: AssignRepView,
    showFullRow: true,
    componentProps: {
      steptype: 'action',
    },
    languageKey: 'process_step_assign_user',
    getValueEdit: (value: any[]) => {
      return value && value.map((v) => v.id);
    },
    getMutationValue: (value: any[]) => {
      let users: any = null;
      if (value.length > 0) {
        users = value.map((v: any) => {
          const group =
            v.properties?.crmGroups?.length > 0 ? v.properties.crmGroups[0] : { id: '', name: '' };
          return {
            user: {
              id: v.id,
              name: v.name,
            },
            group: {
              id: group?.id ?? '',
              name: group?.name ?? '',
            },
          };
        });
      }

      return { [keyNames.KEY_NAME_STEP_ASSIGN_USER]: users };
    },
  },
  [keyNames.KEY_NAME_STEP_STATUSES]: {
    component: StatusView,
    showFullRow: true,
    hideTitle: true,
    componentProps: {
      steptype: ['action', 'simple', 'wait', 'site'],
    },
  },

  [keyNames.KEY_NAME_STEP_AUTOMATION]: {
    component: AutomationView,
    showFullRow: true,
    hideTitle: true,
    componentProps: {
      steptype: 'action',
    },
    getValueView: (value: any) =>
      value ?? {
        useSleeping: false,
        sleeping: {
          executes: [],
          duration: {
            time: 86400,
            unit: 'UNIT_DAY',
          },
        },
      },
    getValueEdit: (value: any) =>
      value ?? {
        useSleeping: false,
        sleeping: {
          executes: [],
          duration: {
            time: 86400,
            unit: 'UNIT_DAY',
          },
        },
      },
    getMutationValue: (value: Automation) => {
      // console.log('value', value);
      const automation = !value.useSleeping
        ? null
        : {
            useSleeping: value.useSleeping,
            sleeping: {
              duration: value.sleeping.duration,
              executes: value.sleeping.executes.map((execute) => {
                return {
                  useNotify: execute.useNotify,
                  notifyId: execute.notify.id,
                  statusId: execute.status.id,
                  useChangeStep: execute.useChangeStep,
                  changeStepId: execute.changeStep.id,
                  useMywork: execute.useMywork,
                };
              }),
            },
          };
      return {
        [keyNames.KEY_NAME_STEP_AUTOMATION]: automation,
      };
    },
  },
};
