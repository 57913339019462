import { slideToMapByKey } from '@base/utils/helpers/array-utils';

export const parseFields = (layoutView: any, menuApi: string) => {
  let fields: any[] = [];
  if (layoutView?.data && layoutView?.data[0] && layoutView?.data[0]?.children) {
    if (menuApi == 'na') {
      fields = [...layoutView?.data[0]?.children, ...layoutView?.data[1]?.children];
    } else {
      fields = [...layoutView?.data[0]?.children];
    }
  }
  if (fields.length == 0) {
    return layoutView;
  }
  const mapFields = slideToMapByKey(fields, 'keyName');
  if (!mapFields) {
    return layoutView;
  }

  fields = parseFieldsCall(mapFields, fields);
  let tmp = { ...layoutView };
  let tmpData = [...tmp.data];
  tmpData[0] = { ...tmpData[0], children: fields };
  tmp = { ...tmp, data: tmpData };
  return tmp;
};

export const parseFieldsCall = (mapFields: any, fields: any) => {
  let newFields: any[] = [];

  let field = { ...mapFields['name'] };
  field.languageKey = 'Template Subject';
  field.keyName = 'title';
  field.dataType = 'title';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'Template Name';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['type'] };
  field.languageKey = 'Template Type';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['language'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'crm_new_menu_product_product';
  field.keyName = 'products';
  field.dataType = 'products';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'Description';
  field.keyName = 'description';
  field.dataType = 'description';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'setting_cta_field_basic_createdby';
  field.keyName = 'assignTo';
  field.dataType = 'assignTo';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['stage'] };
  field.languageKey = 'Active';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['html'] };
  field.keyName = 'html';
  field.dataType = 'html';
  newFields.push(field);

  return newFields;
};

export const parseFieldsList = (layoutList: any, menuApi: string) => {
  let fields: any[] = [];
  if (layoutList?.data && layoutList?.data[0]) {
    fields = [...layoutList?.data];
  }
  if (fields.length == 0) {
    return layoutList;
  }
  const mapFields = slideToMapByKey(fields, 'keyName');
  if (!mapFields) {
    return layoutList;
  }

  fields = parseFieldsCallList(mapFields, fields);
  let tmp = { ...layoutList };

  tmp = { ...tmp, data: fields };
  return tmp;
};

export const parseFieldsCallList = (mapFields: any, fields: any) => {
  let newFields: any[] = [];
  let field: any = {};

  /*field = { ...mapFields['name'] };
  field.languageKey = 'Group';
  field.keyName = 'group';
  field.dataType = 'group';
  field.id = field.keyName;
  newFields.push(field);*/

  field = { ...mapFields['name'] };
  field.languageKey = 'Template Name';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'Template Type';
  field.keyName = 'type';
  field.dataType = 'type';
  field.id = field.keyName;
  newFields.push(field);
  field = { ...mapFields['name'] };
  field.languageKey = 'Subject';
  field.keyName = 'title';
  field.dataType = 'title';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'Language';
  field.keyName = 'language';
  field.dataType = 'language';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'Active';
  field.keyName = 'stage';
  field.dataType = 'stage';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'Created Date';
  field.keyName = 'createdAt';
  field.dataType = 'createdAt';
  field.id = field.keyName;
  newFields.push(field);

  /*field = { ...mapFields['name'] };
  field.languageKey = 'Created By';
  field.keyName = 'createdBy';
  field.dataType = 'createdBy';
  field.id = field.keyName;
  newFields.push(field);*/

  return newFields;
};
