import React from 'react';
import { SpanLang } from '@base/components';
import { Copy, Trash } from 'react-feather';
import {
  Q_MULTI_CHOICES,
  Q_CHECKBOXES,
  Q_DROPDOWN,
  Q_SHORT_ANSWER,
  Q_PARAGRAPH,
  Q_FILE_UPLOAD,
  Q_DATE,
  Q_TIME,
  Q_MULTI_CHOICES_GRID,
  Q_TICK_BOX_GRID,
} from '@settings/online-digital-content/survey/config/constants';
import { Circle, Square, Clock, Calendar } from 'react-feather';

//render footer
const QuestionIcon: React.FC<any> = (props) => {
  const { indexNo = '', type = Q_MULTI_CHOICES } = props;

  //icon base on type
  const getIcon = () => {
    switch (type) {
      case Q_MULTI_CHOICES:
        return (
          <div className="survey-write-question-item-symbol">
            <Circle />
          </div>
        );
      case Q_CHECKBOXES:
        return (
          <div className="survey-write-question-item-symbol">
            <Square />
          </div>
        );
      case Q_DROPDOWN:
        return <span>{indexNo + '.'}</span>;
      case Q_SHORT_ANSWER:
        return <div></div>;
      case Q_PARAGRAPH:
        return <div></div>;
      case Q_FILE_UPLOAD:
        return <div></div>;
      case Q_DATE:
        return (
          <div className="survey-write-question-item-symbol">
            <Calendar />
          </div>
        );
      case Q_TIME:
        return (
          <div className="survey-write-question-item-symbol">
            <Clock />
          </div>
        );
      case Q_MULTI_CHOICES_GRID:
        return (
          <div className="survey-write-question-item-symbol">
            <Circle />
          </div>
        );
      case Q_TICK_BOX_GRID:
        return (
          <div className="survey-write-question-item-symbol">
            <Square />
          </div>
        );
      default:
        return (
          <div className="survey-write-question-item-symbol">
            <Circle />
          </div>
        );
    }
  };

  //render
  return <>{getIcon()}</>;
};

export default QuestionIcon;
