import { NoData } from '@base/components';
import { Button, Input, Priority, Select } from '@base/components/form';
import { PRIORITIES } from '@base/config/constant';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { generateUUID } from '@base/utils/helpers';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import Duration from '@settings/preferences/components/duration';
import { components } from 'react-select';
import {
  CREATE_TICKET_CATEGORY,
  CREATE_TICKET_CATEGORY_RULE,
  DELETE_TICKET_CATEGORY,
  DELETE_TICKET_CATEGORY_RULE,
  UPDATE_TICKET_CATEGORY,
  UPDATE_TICKET_CATEGORY_RULE,
} from '@settings/preferences/services/graphql';
import { useTicketCategories } from '@settings/preferences/services/service';
import { ITicketCategory, ITicketCategoryRule } from '@settings/preferences/types/interfaces';
import React, { useEffect, useMemo, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { IdName } from '@base/types/interfaces/common';
import PrioritySelect from '@base/containers/priority-select';

interface IDeskCategoriesProps {}
const DeskCategories: React.FC<IDeskCategoriesProps> = (props: IDeskCategoriesProps) => {
  const [items, setItems] = useState<ITicketCategory[]>([]);
  const { data, isLoading, refetch } = useTicketCategories('');
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_TICKET_CATEGORY,
    'desk_updateTicketCategory',
  );
  const mAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_TICKET_CATEGORY,
    'desk_createTicketCategory',
  );
  const mDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_TICKET_CATEGORY,
    'desk_deleteTicketCategory',
  );
  const mUpdateRule: any = useMutationPost<BaseMutationResponse>(
    UPDATE_TICKET_CATEGORY_RULE,
    'desk_updateTicketCategoryRule',
  );
  const mAddRule: any = useMutationPost<BaseMutationResponse>(
    CREATE_TICKET_CATEGORY_RULE,
    'desk_createTicketCategoryRule',
  );
  const mDeleteRule: any = useMutationPost<BaseMutationResponse>(
    DELETE_TICKET_CATEGORY_RULE,
    'desk_deleteTicketCategoryRule',
  );
  const onAddRule = (id: string) => {
    const newItems: ITicketCategory[] = items.map((item) => {
      if (item.id == id) {
        return {
          ...item,
          rules: [
            ...item.rules,
            {
              id: '',
              isAllProducts: false,
              products: [],
              priority: null,
              averageTimeResolve: {
                duration: 3600,
                durationUnit: 'UNIT_HOUR',
              },
            },
          ],
        };
      }
      return item;
    });
    setItems(newItems);
  };
  const RemoveRuleLC = (itemIdx: number, ruleIdx: number) => {
    const newItems: ITicketCategory[] = items.map((item, idx) => {
      if (idx == itemIdx) {
        const nRules = item.rules.filter((rule, rIdx) => {
          return rIdx != ruleIdx;
        });
        return {
          ...item,
          rules: nRules,
        };
      }
      return item;
    });
    setItems(newItems);
  };
  const onDeleteCategory = (id: string) => {
    const nItems = items.filter((item) => {
      return item.id != id;
    });
    mDelete.mutate(
      { ids: [id] },
      {
        onSuccess: (res: any) => {
          toast.success('Data was removed!');
          setItems(nItems);
        },
      },
    );
  };
  const onRemoveRule = (id: string, ruleId: string, itemIdx: number, ruleIdx: number) => {
    if (id == '') {
      // remove local
      RemoveRuleLC(itemIdx, ruleIdx);
    } else {
      // remove server
      if (ruleId == '') {
        // remove local
        RemoveRuleLC(itemIdx, ruleIdx);
      } else {
        mDeleteRule.mutate(
          {
            id,
            ruleId,
          },
          {
            onSuccess: (res: any) => {
              toast.success('Data was saved!');
              RemoveRuleLC(itemIdx, ruleIdx);
            },
          },
        );
      }
    }
  };
  const onAddCategory = () => {
    const newCate: ITicketCategory = {
      id: '',
      name: '',
      rules: [
        {
          id: '',
          isAllProducts: false,
          products: [],
          priority: null,
          averageTimeResolve: {
            duration: 3600,
            durationUnit: 'UNIT_HOUR',
          },
        },
      ],
    };
    const nItems = [newCate, ...items];
    setItems(nItems);
  };
  const updateItemLC = (nItem: ITicketCategory, itemIdx: number) => {
    const nItems = items.map((item, idx) => {
      if (idx == itemIdx) {
        return nItem;
      }
      return item;
    });
    setItems(nItems);
  };
  const onChangeCateName = (nCateName: string, row: ITicketCategory, cIdx: number) => {
    if (nCateName == '' || nCateName == row.name) {
      return;
    }
    let nItem: ITicketCategory = {
      ...row,
      name: nCateName,
    };
    if (row.id && row.id != '') {
      // do update
      const upCate = {
        name: nCateName,
        id: row.id,
      };
      mUpdate.mutate(
        { category: upCate },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
            updateItemLC(nItem, cIdx);
          },
        },
      );
    } else {
      // do create
      nItem.id = generateUUID();
      // update id for rule
      const nRules = nItem.rules.map((rule) => {
        if (rule.id == '') {
          return {
            ...rule,
            id: generateUUID(),
          };
        }
        return rule;
      });
      nItem.rules = nRules;
      mAdd.mutate(
        { category: nItem },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
            updateItemLC(nItem, cIdx);
          },
        },
      );
    }
  };

  const updateRuleLC = (
    row: ITicketCategory,
    nRule: ITicketCategoryRule,
    itemIdx: number,
    ruleIdx: number,
  ) => {
    const nItems = items.map((item, idx) => {
      if (idx == itemIdx) {
        const nRules = row.rules.map((rule, rIdx) => {
          if (rIdx == ruleIdx) {
            if (nRule.isAllProducts) {
              return {
                ...nRule,
                products: [
                  {
                    label: 'All',
                    value: 'all',
                    id: 'all',
                    name: 'All',
                  },
                ],
              };
            } else if (!nRule.isAllProducts && nRule.products) {
              const nProducts = nRule.products.map((prod) => {
                return {
                  ...prod,
                  value: prod.id,
                  label: prod.name,
                };
              });
              return {
                ...nRule,
                products: nProducts,
              };
            }
            return nRule;
          }
          return rule;
        });
        return {
          ...item,
          rules: nRules,
        };
      }
      return item;
    });
    setItems(nItems);
  };
  const onChangeRule = (
    nRule: ITicketCategoryRule,
    row: ITicketCategory,
    itemIdx: number,
    ruleIdx: number,
  ) => {
    // format products
    const nProducs = nRule.isAllProducts
      ? []
      : nRule.products?.map((prod) => {
          return {
            id: prod.id,
            name: prod.name,
          };
        });
    nRule.products = nProducs;
    // console.log(row, nRule);
    if (row.id && row.id != '') {
      // do update
      if (nRule.id && nRule.id != '') {
        // update rule
        const params = {
          id: row.id,
          rule: nRule,
        };
        mUpdateRule.mutate(params, {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
            //updateRuleLC(row, nRule, itemIdx, ruleIdx);
          },
        });
      } else {
        // create rule
        nRule.id = generateUUID();
        const params = {
          id: row.id,
          rule: nRule,
        };
        mAddRule.mutate(params, {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
            //updateRuleLC(row, nRule, itemIdx, ruleIdx);
          },
        });
      }
    }
    // else {
    //   // do update rules to local

    // }
    updateRuleLC(row, nRule, itemIdx, ruleIdx);
  };

  const renderItem = (item: ITicketCategory, itemIdx: number) => {
    const catePrioritiesOptions = PRIORITIES;
    const excludesProducts: string[] = [];
    item.rules.map((rule) => {
      if (rule.isAllProducts) {
        excludesProducts.push('all');
      } else if (!rule.isAllProducts && rule.products?.length > 0) {
        rule.products.map((prod) => {
          excludesProducts.push(prod.id);
        });
      }
    });
    return (
      <React.Fragment key={itemIdx}>
        {item.rules.length > 0 &&
          item.rules.map((rule, idx) => {
            const rowKey = `${item.id}-${itemIdx}-${idx}`;
            // console.log('rowKey', rowKey);
            const rowSpan = idx == 0 ? item.rules.length : 1;
            const found = PRIORITIES.find((item) => {
              return item.keyName == rule.priority?.keyName;
            });
            const selectedPriority = found
              ? {
                  priority: found.keyName,
                  languageKey: found.languageKey,
                }
              : {};
            return (
              <tr key={rowKey}>
                {idx == 0 && (
                  <td rowSpan={rowSpan}>
                    <Input
                      type="text"
                      value={item.name}
                      onBlur={(nCate: string) => {
                        onChangeCateName(nCate, item, itemIdx);
                      }}
                    />
                  </td>
                )}
                <td>
                  <div className="d-flex">
                    <ProductAutoComplete
                      value={rule.products}
                      excludes={excludesProducts}
                      onChange={(nProducts: any) => {
                        const allProducts = nProducts.find((prod: any) => {
                          return prod.value == 'all';
                        });
                        let fProducts: IdName[] = [];
                        nProducts.map((prod: any) => {
                          if (prod.value != 'all') {
                            fProducts.push({
                              id: prod.id,
                              name: prod.name,
                            });
                          }
                        });
                        const nRule: ITicketCategoryRule = {
                          ...rule,
                          isAllProducts: allProducts ? true : false,
                          products: allProducts ? [] : fProducts,
                        };
                        // console.log('newRule', nRule);
                        onChangeRule(nRule, item, itemIdx, idx);
                      }}
                      outSide={true}
                    />
                    <Button
                      color="link"
                      icon="Plus"
                      className="btn-icon han-tooltip--right"
                      data-han-tooltip="추가"
                      onClick={() => {
                        onAddRule(item.id);
                      }}
                    />
                  </div>
                </td>

                <td>
                  {/* <Select
                    className="wd-150-f"
                    options={catePrioritiesOptions}
                    value={selectedPriority}
                    onChange={(nPriority: any) => {
                      const nRule: ITicketCategoryRule = {
                        ...rule,
                        priority: nPriority.keyName,
                      };
                      onChangeRule(nRule, item, itemIdx, idx);
                    }}
                    components={{
                      Option: CustomOption,
                    }}
                    outSide={true}
                    getOptionLabel={(item: any) => {
                      return <Priority value={item.keyName} />;
                    }}
                    getOptionValue={(item: any) => {
                      return item.keyName;
                    }}
                  /> */}
                  <PrioritySelect
                    className="wd-150-f"
                    value={selectedPriority}
                    onChange={(nPriority: any) => {
                      const nRule: ITicketCategoryRule = {
                        ...rule,
                        priority: nPriority
                          ? {
                              keyName: nPriority.priority,
                              languageKey: nPriority.languageKey,
                            }
                          : null,
                      };
                      // console.log('nPriority', nPriority);
                      onChangeRule(nRule, item, itemIdx, idx);
                    }}
                  />
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <Duration
                      value={rule.averageTimeResolve}
                      onChange={(nVal: any) => {
                        // console.log('Duration', nVal);
                        const nRule: ITicketCategoryRule = {
                          ...rule,
                          averageTimeResolve: nVal,
                        };
                        onChangeRule(nRule, item, itemIdx, idx);
                      }}
                    />
                    <Button
                      color=""
                      icon="X"
                      iconClass="tx-danger"
                      className="btn-icon btn-delete-row han-tooltip--left mg-l-auto"
                      data-han-tooltip="삭제"
                      onClick={() => {
                        if (item.rules?.length == 1) {
                          onDeleteCategory(item.id);
                        } else {
                          onRemoveRule(item.id, rule.id, itemIdx, idx);
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
      </React.Fragment>
    );
  };
  useEffect(() => {
    if (!isLoading && data?.results) {
      const nItems = data.results.map((item) => {
        const nRules = item.rules.map((rule) => {
          if (rule.isAllProducts) {
            return {
              ...rule,
              products: [
                {
                  label: 'All',
                  value: 'all',
                  id: 'all',
                  name: 'All',
                },
              ],
            };
          } else if (!rule.isAllProducts && rule.products) {
            const nProducts = rule.products.map((prod) => {
              return {
                ...prod,
                value: prod.id,
                label: prod.name,
              };
            });
            return {
              ...rule,
              products: nProducts,
            };
          }
          return rule;
        });
        return {
          ...item,
          rules: nRules,
        };
      });
      setItems(nItems);
    }
  }, [data]);
  return (
    <>
      <div className="pd-20 scroll-box setting-tab-body">
        <div className="mg-b-10 text-right">
          <Button
            color="primary"
            icon="Plus"
            name="Add Issue"
            onClick={() => {
              onAddCategory();
            }}
          />
          <button
            type="button"
            className="btn btn-icon btn-white mg-l-5"
            aria-label="reload"
            onClick={() => refetch()}
          >
            <RefreshCw />
            <span className="sr-only">새로고침</span>
          </button>
        </div>
        <div className="card table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete">
            <thead>
              <tr>
                <th scope="col" className="text-nowrap">
                  Issue
                </th>
                <th scope="col" className="text-nowrap">
                  Product
                </th>
                <th scope="col" className="text-nowrap">
                  Priority
                </th>
                <th scope="col" className="text-nowrap">
                  Average Time to Resolve
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={4}>
                    <div className="wrap-hanloading">
                      <div className="han-loading" />
                    </div>
                  </td>
                </tr>
              )}
              {!isLoading &&
                items.length > 0 &&
                items.map((item, idx) => {
                  return renderItem(item, idx);
                })}
              {!isLoading && items.length == 0 && (
                <tr>
                  <td colSpan={4}>
                    <NoData />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DeskCategories;
