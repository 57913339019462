import React, { useState } from 'react';
import { ListPagination } from '@base/components/list';
import { useParams } from 'react-router-dom';
import { ASC, DESC } from '@base/config/constant';
import ListReactTable from '@base/components/list/list-react-table';
import { TableNothing } from '@base/components/list/list-loading';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';

interface BusinessListProps {
  fields: any;
  columnRenderRemap: any;
  data: any;
  pagingProps: any;
}

function BusinessList(props: BusinessListProps) {
  const { fields, columnRenderRemap, data, pagingProps } = props;
  const { category } = useParams();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [sort, setSort] = useState({ field: 'createdAt', orderBy: DESC });

  const listTableProps = {
    nodata: <TableNothing />,
    data: data,
    // isFetching,
    // fetching: <ListLoading />,
    columns: makeTableColumns(fields, columnRenderRemap, { category }, []),
    initialState: {
      pageSize: 0,
      pageIndex: 1,
      selectedIds: selectedIds,
    },
    isCheckboxTable: false,
    onRowClick: (row: any, e: any) => {
      e && e.preventEvent && e.preventDefault();
    },
    onSortBy: (clName: any, isSortedDesc: boolean) => {
      let orderBy = isSortedDesc ? DESC : ASC;
      setSort({ field: clName, orderBy: orderBy });
    },
  };

  return (
    <>
      <ListReactTable {...listTableProps} />
      <ListPagination type={'full'} {...pagingProps} />
    </>
  );
}

export default BusinessList;
