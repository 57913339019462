import React from 'react';
import classNames from 'classnames';
import { QUESTION_TYPES } from '@settings/online-digital-content/survey/config/constants';
import { SpanLang } from '@base/components';
import { FormIcon } from '@base/components/form';

//render question types
const QuestionTypes: React.FC<any> = (props) => {
  //props
  const { keyS, keyQ, selectedQType, onQuestionTypeChange, focusEle } = props;

  //local var
  const qQSelectEleId = 'q-dropdown-' + keyS + '-' + (keyQ + 1);

  const renderSelectedType = () => {
    const typeItem: any = QUESTION_TYPES.find((_ele: any) => _ele.type === selectedQType);
    if (typeItem) {
      return (
        <>
          <FormIcon icon={typeItem.icon} /> <SpanLang keyLang={typeItem.languageKey} />
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={classNames('dropdown', { show: focusEle === qQSelectEleId })}>
      <button
        className="btn btn-secondary dropdown-toggle"
        id={qQSelectEleId}
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={focusEle === qQSelectEleId ? true : false}
      >
        {renderSelectedType()}
      </button>
      <div
        className={classNames('dropdown-menu', { show: focusEle === qQSelectEleId })}
        aria-labelledby="dropdownMenuButton"
      >
        {QUESTION_TYPES.map((_item: any, _index: number) => (
          <a
            key={_index}
            className="dropdown-item cursor-default"
            onClick={() => onQuestionTypeChange(_item.type)}
          >
            <FormIcon icon={_item.icon} /> <SpanLang keyLang={_item.languageKey} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default QuestionTypes;
