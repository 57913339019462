import React from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';

import CommonViewField from '@base/containers/view-field/common';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_STEP_STATUSES } from '@settings/process/services/process';
import statusAtom from '@settings/process/recoil/status';

import View from './CriteriaView';
import Write from './CriteriaWrite';

function CriteriaViewField(props: any) {
  const statusesValue = useRecoilValue(statusAtom);
  const queryClient = useQueryClient();
  const mutationCriteria: any = useMutationPost<BaseMutationResponse>(
    UPDATE_STEP_STATUSES,
    'process_updateStatuses',
    {
      onSuccess: (data: any) => {
        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', props.metadata?.processId]);
          queryClient.invalidateQueries(['process_step', props.metadata?.stepId]);
        }, 500);
        // console.log('success data', data);
        toast.success('Update criteria successfully!');
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Update criteria failed');
      },
    },
  );

  const onSubmitHandler = (formData: any) => {
    // console.log('criteria edit', formData);
    const statuses = statusesValue.map((status) => {
      return {
        id: status.id,
        button: status.button,
        name: status.name,
        options: JSON.stringify(status.criteria),
      };
    });

    const updateData = {
      id: props.metadata?.processId,
      stepId: props.metadata?.stepId,
      statuses: statuses,
    };
    // console.log('updateData', updateData);
    mutationCriteria.mutate(updateData);
  };

  return (
    <CommonViewField
      {...props}
      onSubmitHandler={onSubmitHandler}
      componentView={View}
      componentEdit={Write}
      // value={value}
      value={props.value}
    />
  );
}

export default CriteriaViewField;
