import { lazy } from 'react';

const LandingPageMain = lazy(() => import('./main'));

export default [
  {
    path: '/settings/landing-page/*',
    component: LandingPageMain,
  },
];
