import React from 'react';
import CopyableUnused from '../copyable-unused';

interface IItemsUnusedProps {
  id: string;
  items: any[];
  onDeleteUnusedItem: (params: any) => void;
  isLoading: boolean;
}
const ItemsUnused: React.FC<IItemsUnusedProps> = (props: IItemsUnusedProps) => {
  const { id, items, onDeleteUnusedItem, isLoading = false } = props;
  return (
    <CopyableUnused
      droppableId={id}
      className="shop"
      items={items}
      onDeleteUnusedItem={props.onDeleteUnusedItem}
      isLoading={isLoading}
    />
  );
};

export default ItemsUnused;
