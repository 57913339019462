import { Select } from '@base/components/form';
import withLoading from '@base/hooks/hocs/withLoading';
import useMutationPost from '@base/hooks/useMutationPost';
import { formatSettingsAtom } from '@base/recoil/atoms';
import { dateSettingSelector } from '@base/recoil/selectors';
import { BaseResponse } from '@base/types/interfaces/response';
import { convertDateFormat, replaceSeparator } from '@base/utils/helpers/date-utils';
import FirstDayOfWeek from '@settings/general-setting/components/first-day-of-week';
import {
  DATE_FORMATS,
  DATE_SEPARATORS,
  FIRST_DAYS,
  FIRST_WEEK_OF_YEARS,
} from '@settings/general-setting/config/constant';
import { UPDATE_FORMAT_SETTING } from '@settings/general-setting/services/graphql/format';
import { IDateSetting } from '@settings/general-setting/types/interfaces/format';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

interface IDateSettingProps {
  setLoading: (params: any) => void;
  data: IDateSetting | undefined;
}
const DateSetting: React.FC<IDateSettingProps> = (props: IDateSettingProps) => {
  const { setLoading } = props;

  const data: IDateSetting = useRecoilValue(dateSettingSelector);
  const [formatSettings, setFormatSettings] = useRecoilState(formatSettingsAtom);
  let dateValue: IDateSetting = data
    ? data
    : {
        firstDayOfWeek: 1,
        firstWeekOfYear: 1,
        dateFormat: 'yyyy/MM/dd',
        dateSeparator: '/',
      };
  const settingKey = 'date';
  const mUpdateFormat: any = useMutationPost<BaseResponse<string>>(
    UPDATE_FORMAT_SETTING,
    'setting_updateFormatSetting',
    {},
  );
  const updateFormatSetting = (value: any) => {
    // save to server
    dateValue = value;
    mUpdateFormat.mutate(
      { key: settingKey, value: JSON.stringify(value) },
      {
        onSuccess: (res: any) => {
          toast.success('Setting saved!');
          setLoading(false);
          // update format settings
          const newSettings = formatSettings.map((item: any) => {
            if (item.key == settingKey) {
              // item.value = JSON.stringify(dateValue);
              return {
                ...item,
                value: JSON.stringify(value),
              };
            }
            return item;
          });
          setFormatSettings(newSettings);
        },
        onError: (res: any) => {
          toast.error('Saving has failed!');
          setLoading(false);
        },
      },
    );
  };
  const { firstDayOfWeek, firstWeekOfYear, dateFormat, dateSeparator } = dateValue;

  const firstDay = useMemo(
    () => FIRST_DAYS.find((day) => day.value == firstDayOfWeek),
    [firstDayOfWeek],
  );

  const firstWeek = useMemo(
    () => FIRST_WEEK_OF_YEARS.find((week) => week.value == firstWeekOfYear),
    [firstWeekOfYear],
  );

  const dateFormatValue = useMemo(() => ({ value: dateFormat, label: dateFormat }), [dateFormat]);

  const dateSeparatorValue = useMemo(
    () => ({ value: dateSeparator, label: dateSeparator }),
    [dateSeparator],
  );

  const formatPreview = useMemo(() => {
    const format = convertDateFormat(dateFormat);
    let formatString = dayjs('2021/11/23', 'YYYY/MM/DD').format(format);
    return replaceSeparator(formatString, dateSeparator);
  }, [dateFormat, dateSeparator]);

  const exAttrs = { menuPlacement: 'top' };
  const onChangeData = (day: any, key: any) => {
    setLoading(true);
    const value = {
      ...dateValue,
      [key]: day.value,
    };
    updateFormatSetting(value);
  };
  return (
    <div className="card">
      <div className="card-header h6 bg-light">Date</div>
      <div className="card-body">
        <div className="form-row">
          <div className="form-col col-md-6 form-group">
            <FirstDayOfWeek
              value={firstDay}
              onChange={(v) => onChangeData(v, 'firstDayOfWeek')}
              {...exAttrs}
            />
          </div>
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">First Week of Year</label>
            <Select
              className="wd-200-f"
              value={firstWeek}
              options={FIRST_WEEK_OF_YEARS}
              onChange={(v: any) => onChangeData(v, 'firstWeekOfYear')}
            />
          </div>
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">Date Format</label>
            <Select
              menuPlacement="top"
              className="wd-150-f"
              value={dateFormatValue}
              options={DATE_FORMATS}
              onChange={(v: any) => onChangeData(v, 'dateFormat')}
            />
          </div>
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">Date Separator</label>
            <Select
              className="wd-150-f"
              value={dateSeparatorValue}
              options={DATE_SEPARATORS}
              onChange={(v: any) => onChangeData(v, 'dateSeparator')}
            />
          </div>
          <div className="form-col col-md-12 form-group">
            <label className="form-item-title">Preview</label>
            <div className="pd-15 bg-light bd bd-dashed rounded">{formatPreview}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLoading(DateSetting);
