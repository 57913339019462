import * as keyNames from '@settings/online-digital-content/landing-page/config/key-names';
import * as commonComponents from '@base/config/view-field/components';
import * as components from './components';
import { LANDING_PAGE_STAGE_OPTIONS, LANDING_PAGE_TYPES } from '../constants';
import { IFieldConfig } from '@base/types/interfaces/common';
import * as commonConfig from '@base/config/view-field';

const configView: IFieldConfig = {
  ...commonConfig?.default,
  //private fields
  [keyNames.KEY_LANDINGPAGE_NAME]: {
    component: commonComponents.TextView,
    //validate: { require: true },
    schema: 'name',
    getRecoilStateValue: (value: any) => {
      return value || '';
    },
  },
  [keyNames.KEY_LANDINGPAGE_TYPE]: {
    component: components.LandingPageTypeSelectView,
    componentProps: {
      options: LANDING_PAGE_TYPES,
      single: true,
    },
    schema: `type {
      value
      label
      languageKey
    }`,
    getValueView: (value: any) => {
      return value?.label ?? '';
    },
  },
  [keyNames.KEY_LANDINGPAGE_LANGUAGE]: {
    component: components.LanguageSelectView,
    schema: `language`,
    componentProps: {
      options: [],
    },
    getValue: (apiData: any) => {
      return apiData?.[keyNames.KEY_LANDINGPAGE_LANGUAGE];
    },
    getValueView: (value: any) => {
      return value;
    },
  },
  [keyNames.KEY_LANDINGPAGE_PRODUCT]: {
    component: components.ProductView,
    schema: `products {
      id
      name
    }
    isAllProducts
    `,
    componentProps: {
      showAllOption: true,
      single: false,
    },
    viewProps: {
      clickIconToEdit: true,
    },
    getValue: (apiData: any) => {
      return {
        isAllProducts: apiData?.isAllProducts,
        products: apiData?.products,
      };
    },
    getValueView: (value: any) => {
      if (value.isAllProducts) {
        return [{ value: 'all', label: 'All Products', id: 'all', name: 'All Products' }];
      } else {
        return value?.products?.length
          ? value?.products?.map((v: any) => ({ ...v, value: v?.id, label: v?.name }))
          : [];
      }
    },
    getValueEdit: (value: any) => {
      if (value.isAllProducts) {
        return { value: 'all', label: 'All' };
      } else {
        return value?.products?.length > 0
          ? value?.products?.map((v: any) => ({ ...v, value: v?.id, label: v?.name }))
          : [];
      }
    },
    getMutationValue: (value: any) => {
      return {
        products: value?.length ? value?.map((v: any) => ({ id: v?.value, name: v?.label })) : [],
      };
    },
    getChangedValue: (value: any) => {
      return {
        isAllProducts: value?.findIndex((v: any) => v.value === 'all') >= 0 ? true : false,
        products: value,
      };
    },
  },
  [keyNames.KEY_LANDINGPAGE_DESCRIPTION]: {
    component: commonComponents.TextAreaView,
    schema: `description`,
  },
  [keyNames.KEY_LANDINGPAGE_APPROVAL]: {
    component: commonComponents.Switch,
    schema: `approval`,
  },
  [keyNames.KEY_LANDINGPAGE_ASSIGN_REP]: {
    schema: `assignTo {
      id
      name
    }`,
    component: commonComponents.AssignRepView,
    componentProps: {},
  },
  [keyNames.KEY_LANDINGPAGE_TEMPLATE]: {
    schema: `template`,
    component: null,
  },
  [keyNames.KEY_LANDINGPAGE_TITLE]: {
    schema: `title`,
    component: commonComponents.TextView,
  },
  [keyNames.KEY_LANDINGPAGE_AB_TEST]: {
    schema: `abtest`,
    component: commonComponents.Switch,
  },
  [keyNames.KEY_LANDINGPAGE_DESGIN]: {
    schema: `html`,
    component: null,
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_LANDINGPAGE_DESGIN]: JSON.stringify(value),
      };
    },
  },
  [keyNames.KEY_LANDINGPAGE_META_KEYWORD]: {
    component: commonComponents.TextView,
    schema: `metaKeyword`,
  },
  [keyNames.KEY_LANDINGPAGE_META_DESC]: {
    component: commonComponents.TextAreaView,
    schema: `metaDescription`,
  },
  [keyNames.KEY_LANDINGPAGE_IMPLEMENTING_COST]: {
    component: commonComponents.TextView, //TODO
    schema: `implementingCost`,
    //TODO
  },
  [keyNames.KEY_LANDINGPAGE_PREVIEW]: {
    schema: ``,
  },
  [keyNames.KEY_LANDINGPAGE_VIEW]: {
    schema: ``,
  },
  [keyNames.KEY_LANDINGPAGE_CLICK]: {
    schema: ``,
  },
  [keyNames.KEY_LANDINGPAGE_CLICK_RATE]: {
    schema: ``,
  },
  [keyNames.KEY_LANDINGPAGE_STAGE]: {
    schema: `stage`,
    component: commonComponents.Selectbox,
    componentProps: {
      options: LANDING_PAGE_STAGE_OPTIONS,
      isSearchable: false,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (apiData: any) => {
      return (
        LANDING_PAGE_STAGE_OPTIONS?.find(
          (v: any) => v.value === apiData?.[keyNames.KEY_LANDINGPAGE_STAGE],
        ) ?? null
      );
    },
    getValueView: (value: any) => {
      return value?.label;
    },
    getMutationValue: (value: any) => {
      return value.value;
    },
  },
  [keyNames.KEY_LANDINGPAGE_PUBLISH]: {
    schema: `publish
    publishDate
    `,
    component: components.PublishView,
    getValue: (apiData: any) => {
      return {
        publish: apiData.publish,
        publishDate: apiData.publishDate,
      };
    },
    getValueEdit: (value: any) => {
      return value;
    },
    getMutationValue: (value: any) => {
      return {
        publish: value.publish,
        publishDate: value.publishDate,
      };
    },
    refetchQueryKey: ['setting_landingPage'],
  },
  [keyNames.KEY_LANDINGPAGE_PUBLISH_DATE]: {
    schema: `publishDate`,
    component: components.PublishDateView,
  },
};
export default configView;
