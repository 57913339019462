import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Edge, Node } from '@settings/process/types/diagram';
import NodeEdit from './NodeEdit';
import EdgeAdd from '@settings/process/components/diagram/edge/EdgeAdd';
import {
  useDiagramDetailAtomState,
  useShowStepActionAtomState,
} from '@settings/process/recoil/diagram';
import _ from 'lodash';

interface NodeSimpleProps {
  processId: string;
  node: Node;
  stepHelper: any;
}

function NodeSimple(props: NodeSimpleProps) {
  const {
    node: {
      id: sourceId,
      data: { label },
      type: nodeType,
      position,
      className,
      property,
      shape,
      edges,
    },
    processId,
    stepHelper,
  } = props;

  const [showStepAction, setStepShowAction] = useShowStepActionAtomState();
  const [showDetail, setShowDetail] = useDiagramDetailAtomState();

  const onShowAction = useCallback(() => {
    setStepShowAction((old) => {
      return { id: sourceId, show: !old.show };
    });
  }, []);

  useEffect(() => {
    if (showStepAction.show) {
      setShowDetail(true);
    }
  }, [showStepAction]);

  const matchShow = useMemo(() => {
    if (showStepAction.id == sourceId && showStepAction.show) {
      return true;
    } else {
      return false;
    }
  }, [showStepAction, sourceId]);

  return (
    <>
      {(shape == 'SHAPE_FORWARD' ||
        ((shape == 'SHAPE_BACKWARD' || shape == 'SHAPE_MIDDLE') && showDetail)) && (
        <div
          className="diagram-item-wrap"
          style={{ top: `${position.y}px`, left: `${position.x}px` }}
        >
          <div className={classNames('diagram-item diagram-simple', className)}>
            <div className="diagram-item-name" onClick={onShowAction}>
              {label}
            </div>
            {matchShow && (
              <NodeEdit
                processId={processId}
                property={property}
                sourceId={sourceId}
                position={position}
                sourceType={nodeType}
                shape={shape}
              />
            )}
          </div>
          {edges?.map((edge: Edge) => {
            if (edge.sourceDirection == 'DIRECTION_FORWARD_OUTGOING_FORWARD') {
              return (
                <React.Fragment key={edge.id}>
                  <div
                    className={classNames('diagram-direction-forward', {
                      'for-simple-action': edge.height && edge.height > 0,
                      'is-edit': matchShow,
                    })}
                    // 61 : step height (40) + arrow height(11)
                    // 6 : forward line height
                    style={edge.height && edge.height > 0 ? { height: `${edge.height}px` } : {}}
                  >
                    {matchShow && (
                      <>
                        {!edge.target && (
                          <EdgeAdd
                            style={{
                              left: '100%',
                              top: `${edge.height && edge.height > 0 ? 0 : 100}%`,
                              marginTop: '-15px',
                              marginLeft: '15px',
                            }}
                            className={'btn-forward'}
                            sourceId={sourceId}
                            sourceType={nodeType}
                            edge={edge}
                            shape={shape}
                            stepHelper={stepHelper}
                          />
                        )}
                      </>
                    )}
                  </div>
                </React.Fragment>
              );
            } else if (edge.sourceDirection == 'DIRECTION_FORWARD_OUTGOING_BOTTOM') {
              return (
                <React.Fragment key={edge.id}>
                  <div
                    className={classNames('diagram-direction-vertical diagram-direction-bottom', {
                      'is-edit': matchShow,
                    })}
                  >
                    {matchShow && (
                      <>
                        {!edge.target && (
                          <EdgeAdd
                            style={{
                              left: '50%',
                              top: '100%',
                              marginTop: '16px',
                              marginLeft: '-13px',
                            }}
                            className={'btn-down btn-backward'}
                            sourceId={sourceId}
                            sourceType={nodeType}
                            shape={shape}
                            edge={edge}
                            stepHelper={stepHelper}
                          />
                        )}
                      </>
                    )}
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      )}
    </>
  );
}

export default React.memo(NodeSimple);
