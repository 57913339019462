import React, { useMemo, useState } from 'react';
import TransactionTable from '@settings/billing-license/components/transaction-table';
interface IDataTransaction {
  date: Date;
  activity: string;
  item: string;
  note: string;
  amount: number;
  transactionId: string;
}

const HistoryTransactionTable: React.FC = () => {
  // add get data after
  const [dataTransaction, setDataTransaction] = useState<IDataTransaction[]>([
    {
      date: new Date('2022-7-4'),
      activity: 'asd',
      item: 'asd',
      note: 'asd',
      amount: 10,
      transactionId: 'asd',
    },
  ]);
  const columnsTransaction = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.date.toLocaleDateString()}</>;
        },
      },
      {
        Header: 'Activity Type',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.activity}</>;
        },
      },
      {
        Header: 'Item',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.item}</>;
        },
      },
      {
        Header: 'Note',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.note}</>;
        },
      },
      {
        Header: 'Amount',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.amount}</>;
        },
      },
      {
        Header: 'Transaction ID',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.transactionId}</>;
        },
      },
    ],
    [dataTransaction],
  );
  return <TransactionTable columns={columnsTransaction} data={dataTransaction} />;
};
export default HistoryTransactionTable;
