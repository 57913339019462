import { Button } from '@base/components/form';
import { replaceItemAtIndex } from '@base/utils/helpers/array-utils';
import {
  PROCESS_STATUS_DIRECTIONS_SORT,
  PROCESS_STATUS_DIRECTIONS_VIEW,
  PROCESS_STATUS_EVENTS_VIEW,
  PROCESS_STATUS_PROPERTIES_VIEW,
  PROCESS_STATUS_VIEWS_VIEW,
  STATUS_BASIC_DATA,
} from '@settings/process/config/constants';
import { useDefinedFields } from '@settings/process/hooks/useProcess';
import statusAtom from '@settings/process/recoil/status';
import { CriteriaCondition, CriteriaOption } from '@settings/process/types/diagram';
import { BusinessStatus, DefinedField, StatusForm } from '@settings/process/types/process';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import CriteriaRuleWrite from './CriteriaRuleWrite';

interface CriteriaWriteProps {
  statusData?: BusinessStatus[];
}

export const defaultCriteria: CriteriaCondition = {
  aSide: {
    module: 'MODULE_NONE',
    field: '',
    type: 'FIELD_TYPE_TEXT',
  },
  operator: 'CRITERIA_OPERATOR_NONE',
  vType: 'VALUE_TYPE_NONE',
  value: '',
};

function CriteriaWrite(props: CriteriaWriteProps) {
  const { statusData } = props;
  const [statusesValue, setStatusesValue] = useRecoilState(statusAtom);
  const statusCount = statusesValue.length;

  // const [criteriaType, setCriteriaType] = useState<LabelValue>(CRITERIA_TYPES[0]);
  // const handleValueChange = (newType: any) => {
  //   setCriteriaType(newType);
  // };

  // get critiera fields
  const { data: definedFields } = useDefinedFields();

  const moduleFields = useMemo(() => {
    let items: { [index: string]: DefinedField[] } = {};
    if (definedFields) {
      for (const item of definedFields.results) {
        if (!items[item.module]) {
          items[item.module] = [];
        }
        items[item.module].push(item);
      }
    }
    return items;
  }, [definedFields]);

  const initCriteria: CriteriaOption = {
    blocks: [
      {
        conditions: [defaultCriteria],
        pattern: [],
      },
    ],
    pattern: [],
  };

  const addCriteria = useCallback(() => {
    const targetValue: StatusForm = {
      ...STATUS_BASIC_DATA({
        id: uuidv4(),
        direction: 'DIRECTION_FORWARD_OUTGOING_FORWARD',
      }),
      criteria: initCriteria,
    };
    const targetIndex = statusCount - 1;
    setStatusesValue((old) => {
      return replaceItemAtIndex(old, targetIndex, targetValue);
    });
  }, [statusesValue, statusCount]);

  useEffect(() => {
    let statusForms: StatusForm[] = [];
    if (statusData && statusData.length > 0) {
      for (const status of statusData) {
        statusForms.push({
          id: status.id,
          name: status.name,
          button: status.button,
          view: { keyName: status.view, languageKey: PROCESS_STATUS_VIEWS_VIEW[status.view] },
          event: { keyName: status.event, languageKey: PROCESS_STATUS_EVENTS_VIEW[status.event] },
          property: {
            keyName: status.property,
            languageKey: PROCESS_STATUS_PROPERTIES_VIEW[status.property],
          },
          direction: {
            keyName: status.direction,
            languageKey: PROCESS_STATUS_DIRECTIONS_VIEW[status.direction],
          },
          nextStep: { keyName: '', languageKey: '' },
          sequence: status.sequence ?? [],
          new: false,
          reset: true,
          order: PROCESS_STATUS_DIRECTIONS_SORT[status.direction],
          multiple: status.multiple,
          primary: status.primary,
          criteria: status.options ? JSON.parse(status.options) : {},
        });
      }
    } else {
      statusForms = [
        {
          ...STATUS_BASIC_DATA({
            id: uuidv4(),
            direction: 'DIRECTION_FORWARD_OUTGOING_FORWARD',
          }),
          criteria: initCriteria,
        },
        STATUS_BASIC_DATA({
          id: uuidv4(),
          direction: 'DIRECTION_FORWARD_OUTGOING_BACKWARD',
        }),
      ];
    }
    setStatusesValue(statusForms);
  }, [statusData]);

  return (
    <div className="form-group col-12">
      <label className="form-item-title d-flex align-items-center">
        Set Criteria
        <Button
          name="Add"
          color="link"
          icon="Plus"
          className="mg-l-auto pd-0"
          onClick={addCriteria}
        />
      </label>
      <div className="pd-15 bg-light rounded">
        {statusesValue.map((status, index) => {
          if (statusCount == index + 1) return;
          return (
            <div className="row" key={status.id}>
              <div className="col-lg-3">
                <div className="diagram-item diagram-criteria with-boolean-direction">
                  <div className="criteria-shape"></div>
                  <div className="diagram-item-name">{status.button}</div>
                  <div className="direction-true"></div>
                  <div className="direction-false"></div>
                </div>
              </div>
              <div className="col-lg-9">
                <CriteriaRuleWrite
                  criteriaId={status.id}
                  critieraName={status.button}
                  criteriaOption={status.criteria!!}
                  moduleFields={moduleFields}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CriteriaWrite;
