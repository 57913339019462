import { FilterInput } from '@base/types/interfaces/common';
import usePosts from '@base/hooks/usePosts';
import { useRecoilValue } from 'recoil';
import { ILandingPage } from '@settings/online-digital-content/landing-page/types/interfaces';
import { getQueryString } from '@base/services/list';
import { keyStringify } from '@base/utils/helpers';
import { landingPageListFilterState } from '@settings/online-digital-content/landing-page/recoil/atom/list-atom';
import { buildListSchema } from '@base/utils/helpers/schema';
import { default as configFields } from '@settings/online-digital-content/landing-page/config/view-field';
import { LANDINGPAGE_GET_LIST } from './graphql';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';
import { dateByOptions, searchFields } from '../config/list';

export const useLandingPages = (fields: any[]) => {
  const graphQLKey = 'setting_landingPages';

  const { filter } = useRecoilValue(landingPageListFilterState);

  // console.log('...useLandingPages...', filter);

  // build filter
  let filtersQuery: FilterInput = {
    keyword: filter?.keyword ?? '',
    sort: filter?.sort,
    paging: filter?.paging,
    query: ParseFiltersToQuery(filter, dateByOptions, searchFields),
  };

  // build schema
  let listQuerySchema = '';
  if (fields && fields.length > 0) {
    listQuerySchema = buildListSchema({ fields, configFields });
  }
  const queryString = getQueryString(graphQLKey, listQuerySchema);

  let params = {
    filter: filtersQuery,
  };

  // query fields key
  const fieldQueryKey = fields.map((_ele: any) => _ele.keyName).join(',');

  const usePostResult = usePosts<ILandingPage[]>(
    [graphQLKey, keyStringify(filtersQuery, ''), fieldQueryKey],
    queryString,
    params,
    {
      keepPreviousData: true,
      enabled: fields.length > 0,
    },
  );

  return usePostResult;
};

export const useLandingPagesAutoComplete = (params: any) => {
  const usePostResult = usePosts<any[]>(
    ['setting_landingPages', params.filter.keyword],
    LANDINGPAGE_GET_LIST,
    params,
  );

  return usePostResult;
};
