import React, { useState, Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, pageDataByMenuAtom, listPageSettingsAtom } from '@base/recoil/atoms/app';
import SplitView, { EmptySplitViewFC } from '@base/components/split-view';
import Loading from '@base/components/loading';
import { isUndefined } from 'lodash';
import { ListType } from '@base/types/enums';
import { useLanguageByMenu } from '@base/services/i18n';

import ListPage from '@settings/online-digital-content/landing-page/pages/list';
import ViewPage from '@settings/online-digital-content/landing-page/pages/view';
import { MENU_SETTING, MENU_SETTING_LANDINGPAGE } from '@base/config/menus';

const LandingPageMain: React.FC = () => {
  const [{ innerHeight, isDesktop }] = useRecoilState(deviceAtom);
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_SETTING_LANDINGPAGE));
  // const listPageSettings = useRecoilValue(listPageSettingsAtom);
  const [isLoadingSetting, setIsLoadingSetting] = useState(false);

  const { isSplitMode } = pageData;
  const setIsSplitMode = (isSplitMode: boolean) => {
    setPageData({
      ...pageData,
      isSplitMode,
    });
  };
  const splitViewProps = {
    isSplitMode,
    styles: {
      height: innerHeight !== 0 ? innerHeight - 60 : 0,
    },
  };

  const listProps = {
    isSplitMode,
    setIsSplitMode,
  };

  // load menu language
  useLanguageByMenu([MENU_SETTING_LANDINGPAGE]);

  // useEffect(() => {
  //   if (listPageSettings && listPageSettings[MENU_SETTING_LANDINGPAGE]) {
  //     const menuListPage: any = listPageSettings[MENU_SETTING_LANDINGPAGE];
  //     const nPageData = {
  //       ...pageData,
  //       isSplitMode: menuListPage?.listType === ListType.SPLIT,
  //       listType: menuListPage?.listType,
  //     };
  //     setPageData(nPageData);
  //   }
  //   if (
  //     listPageSettings &&
  //     (isUndefined(listPageSettings[MENU_SETTING_LANDINGPAGE]) ||
  //       listPageSettings[MENU_SETTING_LANDINGPAGE])
  //   ) {
  //     setIsLoadingSetting(false);
  //   }
  // }, [listPageSettings]);

  return (
    <>
      {isLoadingSetting ? (
        <Loading />
      ) : (
        <Routes>
          <Route
            path=""
            element={
              isSplitMode && isDesktop ? (
                <SplitView
                  {...splitViewProps}
                  leftPane={<ListPage {...listProps} />}
                  rightPane={<EmptySplitViewFC />}
                />
              ) : (
                <ListPage {...listProps} />
              )
            }
          />
          <Route
            path=":id/*"
            element={
              isSplitMode && isDesktop ? (
                <SplitView
                  {...splitViewProps}
                  leftPane={<ListPage {...listProps} />}
                  rightPane={
                    <div className="pane-content" style={{ flex: 1 }}>
                      <Suspense fallback={<Loading />}>
                        <ViewPage isSplitMode={isSplitMode} />
                      </Suspense>
                    </div>
                  }
                />
              ) : (
                <ViewPage />
              )
            }
          />
        </Routes>
      )}
    </>
  );
};

export default LandingPageMain;
