import BasicTable from '@base/components/table/basic';
import withLoading from '@base/hooks/hocs/withLoading';
import useMutationPost from '@base/hooks/useMutationPost';
import { formatSettingsAtom } from '@base/recoil/atoms';
import { timezoneSettingSelector } from '@base/recoil/selectors';
import { BaseResponse } from '@base/types/interfaces/response';
import { UPDATE_FORMAT_SETTING } from '@settings/general-setting/services/graphql/format';
import { ITimezoneSetting } from '@settings/general-setting/types/interfaces/format';
import React, { useMemo } from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import TimezoneSelect from '../timezone-select';

interface IProps {
  setLoading: (params: any) => void;
}

const TimezoneSetting: React.FC<IProps> = (props: IProps) => {
  const { setLoading } = props;
  const reData: ITimezoneSetting[] = useRecoilValue(timezoneSettingSelector);
  const [formatSettings, setFormatSettings] = useRecoilState(formatSettingsAtom);
  let timezoneValue: ITimezoneSetting[] = reData ? reData : [];
  const settingKey = 'timezone';
  // const [keyword, setKeyword] = useState('');
  // const { data, isLoading } = useTimezones(keyword);
  // // console.log('timezone', data, isLoading);
  const mUpdateFormat: any = useMutationPost<BaseResponse<string>>(
    UPDATE_FORMAT_SETTING,
    'setting_updateFormatSetting',
    {},
  );
  const updateFormatSetting = (value: any) => {
    // save to server
    timezoneValue = value;
    mUpdateFormat.mutate(
      { key: settingKey, value: JSON.stringify(value) },
      {
        onSuccess: (res: any) => {
          toast.success('Setting saved!');
          setLoading(false);
          // update format settings
          const newSettings = formatSettings.map((item: any) => {
            if (item.key == settingKey) {
              // item.value = JSON.stringify(timezoneValue);
              return {
                ...item,
                value: JSON.stringify(value),
              };
            }
            return item;
          });
          setFormatSettings(newSettings);
        },
        onError: (res: any) => {
          toast.error('Saving has failed!');
          setLoading(false);
        },
      },
    );
  };
  const onChange = (timezoneSelect: any) => {
    const isExist = timezoneValue.find((timezone: any) => timezone.tzone == timezoneSelect.tzone);
    if (isExist) {
      toast.warning('Time zone is existed');
    } else {
      setLoading(true);
      const timezones = timezoneValue.concat([timezoneSelect]);
      updateFormatSetting(timezones);
    }
  };

  const onDelete = (timezoneSelect: any) => {
    setLoading(true);
    const timezones = timezoneValue.filter(
      (timezone: any) => timezone.tzone != timezoneSelect.tzone,
    );
    updateFormatSetting(timezones);
  };

  const onSetDefault = (timezoneSelect: any) => {
    setLoading(true);
    const timezones = timezoneValue.map((timezone: any) => ({
      ...timezone,
      isDefault: timezone.tzone == timezoneSelect.tzone,
    }));
    updateFormatSetting(timezones);
  };

  const timezoneTable = useMemo(
    () => [
      {
        Header: 'Time Zone',
        accessor: (row: any, rowIndex: any) => `[GMT ${row.sdutc}] ${row.tzone}`,
      },
      {
        Header: 'Default',
        accessor: (row: any, rowIndex: any) => (
          <div className="custom-control custom-radio">
            <input
              type="radio"
              className="custom-control-input"
              name={`defaultTimezone${rowIndex}`}
              id={`defaultTimezone${rowIndex}`}
              checked={row.isDefault}
              onChange={() => onSetDefault(row)}
            />
            <label className="custom-control-label" htmlFor={`defaultTimezone${rowIndex}`}></label>
          </div>
        ),
      },
      {
        Header: 'Delete',
        accessor: (row: any, rowIndex: any) => (
          <button type="button" className="btn btn-icon pd-0" onClick={() => onDelete(row)}>
            <X className="tx-danger" />
          </button>
        ),
      },
    ],
    [timezoneValue],
  );

  return (
    <div className="card">
      <div className="card-header h6 bg-light">Time zone</div>
      <BasicTable
        wrapperClassName=""
        className="settings-tb"
        columns={timezoneTable}
        data={timezoneValue}
      />
      <div className="card-footer">
        <TimezoneSelect onChange={onChange} />
      </div>
    </div>
  );
};
export default withLoading(TimezoneSetting);
