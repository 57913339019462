import React from 'react';
import { useRecoilValue } from 'recoil';
import { MENU_CUSTOMER } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { ViewFields } from '@base/components/hook-form/view';
import * as keyNames from '@customer/customer/config/key-names';
import Loading from '@base/components/loading';
import ViewFieldsPreview from '../view-field';

interface IDetailViewProps {
  section: any;
}

const PreviewDetailView: React.FC<IDetailViewProps> = (props) => {
  const { section } = props;
  return (
    <div className="pd-t-15">
      <div className="scroll-box" style={{ height: 'calc(100vh - 160px)' }}>
        <div className="view-aside-section-body">
          <div className="form-row mg-x-0 mg-t-10">
            <ViewFieldsPreview column={2} fields={section?.children} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewDetailView;
