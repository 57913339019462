import React from 'react';
import { useRecoilValue } from 'recoil';
import ViewSummary from '@base/containers/view-summary';
import * as keyNames from '@settings/online-digital-content/survey/config/key-names';
import { viewDataByMenuAtom } from '@base/recoil/atoms';

interface IViewLeftProps {
  id: string;
  category: string;
  showTop?: boolean;
  onRefetch?: () => void;
}

const ViewLeft: React.FC<IViewLeftProps> = (props) => {
  const { showTop, category, id, onRefetch } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(category));

  //hide fields
  const hiddenFields: string[] = [
    keyNames.KEY_SURVEY_NAME,
    keyNames.KEY_SURVEY_CONTENT,
    keyNames.KEY_SURVEY_TYPE,
    keyNames.KEY_SURVEY_TEMPLATE,
    keyNames.KEY_SURVEY_UPDATED_AT,
    keyNames.KEY_SURVEY_UPDATED_BY,
    keyNames.KEY_SURVEY_STAGE,
  ];

  const allowRefetchFields: string[] = [
    keyNames.KEY_SURVEY_TITLE,
    keyNames.KEY_SURVEY_LANGUAGE,
    keyNames.KEY_SURVEY_DESCRIPTION,
  ];

  return (
    <div className="h-100">
      <ViewSummary
        bodyClassName="scroll-box"
        bodyStyle={{ height: showTop ? 'calc(100vh - 290px)' : 'calc(100vh - 160px)' }}
        data={viewData}
        //ignoreFields={[]} //FOR displaying TOP part such as photo, ...
        hiddenFields={hiddenFields} //FOR hiding display
        refetchFields={allowRefetchFields}
        onRefetch={onRefetch}
      />
    </div>
  );
};
export default ViewLeft;
