import { IARAssignToItem } from '@settings/assignment-rule/types/interfaces';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Trash2 } from 'react-feather';

interface IDeleteAssignToProps {
  data: IARAssignToItem;
  onAfterDeleted: (data: IARAssignToItem) => {};
}
const DeleteAssignTo: React.FC<IDeleteAssignToProps> = (props: IDeleteAssignToProps) => {
  const { data, onAfterDeleted } = props;
  const [isSaving, setIsSaving] = useState(false);
  const onDelete = () => {
    onAfterDeleted && onAfterDeleted(data);
  };
  return (
    <>
      <div className="align-items-center justify-content-between">
        <a className={'cursor-pointer tx-danger'} onClick={onDelete}>
          {isSaving ? (
            <span
              className={classNames('spinner-border spinner-border-sm')}
              role="status"
              aria-hidden="true"
            />
          ) : (
            <Trash2 size={16} />
          )}
        </a>
      </div>
    </>
  );
};

export default DeleteAssignTo;
