import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { MENU_PROCESS_CLOSED } from '@base/config/menus';
import { WriteParseFields } from '@base/utils/helpers/no-layout-utils';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query'; //v4
import AsideWrite from '@base/containers/aside-modal/write';
import { CREATE_PROCESS_STEP } from '@settings/process/services/process';

interface ClosedWriteProps {
  processId: string;
  onClose?: () => void;
}

function ClosedWrite({ processId, onClose }: ClosedWriteProps) {
  const { fields, getParams, defaultValues } = useMemo(
    () => WriteParseFields(MENU_PROCESS_CLOSED),
    [],
  );

  //state
  const [isSaving, setIsSaving] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isNew, setIsNew] = useState(false);

  //create mutation
  const queryClient = useQueryClient();
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_PROCESS_STEP,
    'process_createStep',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Create Process successfully!');

        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', processId]);
        }, 500);
        setIsSaving(false);
        setIsReset(true);

        if (!isNew) {
          onClose && onClose();
        }
      },
      onError: (error: any) => {
        setIsSaving(false);
        // console.log('failed', error);
        toast.success('Create Process failed');
      },
    },
  );

  const onSubmit = (formData: any, isNew: boolean) => {
    setIsNew(isNew);
    const newParams = getParams(formData);
    // console.log('configParams => ', newParams);
    const addData = {
      name: newParams.name,
      type: 'TYPE_CLOSE',
      description: newParams.description,
      meta: {
        jump: newParams.jump,
        view: newParams.view,
        property: newParams.property,
      },
    };
    mutationAdd.mutate({ id: processId, step: addData });
  };

  return (
    <AsideWrite
      onSubmit={onSubmit}
      isSaving={isSaving}
      isReset={isReset}
      defaultValues={defaultValues}
      fields={fields}
    />
  );
}

export default ClosedWrite;
