import * as keyNames from '@settings/assignment-rule/config/key-names';
import * as components from '@settings/assignment-rule/config/write-field/components';
import * as commonComponents from '@base/config/write-field/components';
import { AR_MODULE_OPTIONS } from '../constant';
import validators from '@base/utils/validation/field-validator';

const ARWriteField = {
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_NAME]: {
    component: commonComponents.Input,
    showFullRow: true,
    defaultValue: '',
    languageKey: 'Name',
    validate: {
      required: validators.required,
    },
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_DESC]: {
    component: commonComponents.TextArea,
    showFullRow: true,
    languageKey: 'Description',
    defaultValue: '',
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_MODULE]: {
    component: commonComponents.SelectBox,
    showFullRow: true,
    languageKey: 'Module',
    componentProps: {
      fieldValue: 'keyName',
      fieldLabel: 'languageKey',
      options: AR_MODULE_OPTIONS,
    },
    defaultValue: [],
    parseParam: (value: any) => {
      return [value];
    },
  },
  /*[keyNames.KEY_NAME_ASSIGNMENT_RULE_MODULE]: {//checkbox
    component: commonComponents.CheckboxGroup,
    showFullRow: true,
    languageKey: 'Module',
    componentProps: {
      fieldValue: 'keyName',
      fieldLabel: 'languageKey',
      options: AR_MODULE_OPTIONS,
    },
    defaultValue: [],
    parseParam: (value: any) => {
      return value;
    },
  },*/
  /*[keyNames.KEY_NAME_ASSIGNMENT_RULE_CRITERIA]: {
    component: components.Criteria,
    componentProps: {},
    showFullRow: true,
    defaultValue: [],
    languageKey: 'Criteria',
    parseParam: (value: any) => {
      // console.log('Criteria', value);
      return value;
    },
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_ASSIGN_TO]: {
    component: components.AssignTo,
    componentProps: {},
    showFullRow: true,
    defaultValue: [],
    languageKey: 'Assign To',
    parseParam: (value: any) => {
      // console.log('Assign To', value);
      return value;
    },
  },*/
};

export default ARWriteField;
