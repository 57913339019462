import { t } from 'i18next';

export const LANDING_PAGE_TYPE_NONE = 'LANDING_PAGE_TYPE_NONE';
export const LANDING_PAGE_TYPE_TICKET_FORM = 'LANDING_PAGE_TYPE_TICKET_FORM';
export const LANDING_PAGE_TYPE_ABOUT_US = 'LANDING_PAGE_TYPE_ABOUT_US';
export const LANDING_PAGE_TYPE_THANK_YOU = 'LANDING_PAGE_TYPE_THANK_YOU';
export const LANDING_PAGE_TYPE_LEAN_MORE = 'LANDING_PAGE_TYPE_LEAN_MORE';
export const LANDING_PAGE_TYPE_TESTIMONIALS = 'LANDING_PAGE_TYPE_TESTIMONIALS';
export const LANDING_PAGE_TYPE_CORPORATE_MAGAZINE = 'LANDING_PAGE_TYPE_CORPORATE_MAGAZINE';
export const LANDING_PAGE_TYPE_DIGITAL_REPORT = 'LANDING_PAGE_TYPE_DIGITAL_REPORT';

export const LANDING_PAGE_TYPES: any = [
  {
    value: LANDING_PAGE_TYPE_TICKET_FORM,
    isDefault: true,
    // label: t('landingpage_type_ticket_form'),
    label: 'Ticket form',
    languageKey: 'landingpage_type_ticket_form',
  },
  {
    value: LANDING_PAGE_TYPE_ABOUT_US,
    isDefault: false,
    // label: t('landingpage_type_about_us'),
    label: 'About us',
    languageKey: 'landingpage_type_about_us',
  },
  {
    value: LANDING_PAGE_TYPE_THANK_YOU,
    isDefault: false,
    // label: t('landingpage_type_thank_you'),
    label: 'Thank you',
    languageKey: 'landingpage_type_thank_you',
  },
  {
    value: LANDING_PAGE_TYPE_LEAN_MORE,
    isDefault: false,
    // label: t('landingpage_type_lean_more'),
    label: 'Lean more',
    languageKey: 'landingpage_type_lean_more',
  },
  {
    value: LANDING_PAGE_TYPE_TESTIMONIALS,
    isDefault: false,
    // label: t('landingpage_type_testimonials'),
    label: 'Testimonials',
    languageKey: 'landingpage_type_testimonials',
  },
  {
    value: LANDING_PAGE_TYPE_CORPORATE_MAGAZINE,
    isDefault: false,
    // label: t('landingpage_type_corporate_magazine'),
    label: 'Corporate magazine',
    languageKey: 'landingpage_type_corporate_magazine',
  },
  {
    value: LANDING_PAGE_TYPE_DIGITAL_REPORT,
    isDefault: false,
    // label: t('landingpage_type_digital_report'),
    label: 'Digital report',
    languageKey: 'landingpage_type_digital_report',
  },
];

export const LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH = 'PUBLISH_UNBLISH';
export const LANDING_PAGE_PUBLISH_SCHEDULE_PUBLISH_NOW = 'PUBLISH_NOW';
export const LANDING_PAGE_PUBLISH_SCHEDULE_LATER = 'PUBLISH_LATER';

export const PUBLISH_SCHEDULE_OPTIONS: any = [
  {
    value: LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH,
    label: 'Unpublish',
  },
  {
    value: LANDING_PAGE_PUBLISH_SCHEDULE_PUBLISH_NOW,
    label: 'Publish now',
  },
  {
    value: LANDING_PAGE_PUBLISH_SCHEDULE_LATER,
    label: 'Schedule for later',
  },
];

export const LANDING_PAGE_STAGE_BUILD = 'STAGE_BUILD';
export const LANDING_PAGE_STAGE_PUBLISHED = 'STAGE_PUBLISHED';
export const LANDING_PAGE_STAGE_SCHEDULED = 'STAGE_SCHEDULED';
export const LANDING_PAGE_STAGE_UNPUBLISH = 'STAGE_UNPUBLISH';
export const LANDING_PAGE_STAGE_ARCHIVED = 'STAGE_ARCHIVED';

export const LANDING_PAGE_STAGE_OPTIONS: any[] = [
  {
    value: LANDING_PAGE_STAGE_BUILD,
    label: 'Build',
  },
  {
    value: LANDING_PAGE_STAGE_PUBLISHED,
    label: 'Published',
  },
  {
    value: LANDING_PAGE_STAGE_SCHEDULED,
    label: 'Scheduled',
  },
  {
    value: LANDING_PAGE_STAGE_UNPUBLISH,
    label: 'UnPublish',
  },
  {
    value: LANDING_PAGE_STAGE_ARCHIVED,
    label: 'Archived',
  },
];
