import usePost from '@base/hooks/usePost';
import { BaseResponse } from '@base/types/interfaces/response';
import { IBasicField, IField, IPagelayout, IPagelayoutMenu } from '../types/interfaces/pagelayout';
import {
  GET_PAGE_LAYOUT_BASIC_FIELDS,
  GET_PAGE_LAYOUT_DATA,
  GET_PAGE_LAYOUT_MENUS,
  GET_PAGE_LAYOUT_USUSED_FIELDS,
} from './graphql/pagelayout';

export const usePagelayoutMenus = () => {
  let queryKey = ['setting_menus'];

  const response = usePost<BaseResponse<IPagelayoutMenu>>(queryKey, GET_PAGE_LAYOUT_MENUS, {});
  return response;
};

export const usePagelayoutData = (layoutId: string, device: string) => {
  let queryKey = ['setting_pagelayout', layoutId, device];

  const response = usePost<IPagelayout>(
    queryKey,
    GET_PAGE_LAYOUT_DATA,
    {
      layoutId,
      device,
    },
    {
      enabled: layoutId != '' && device != '',
    },
  );
  return response;
};

export const usePagelayoutUnusedFields = (layoutId: string, device: string) => {
  let queryKey = ['setting_unUsedFields', layoutId, device];

  const response = usePost<BaseResponse<IField>>(
    queryKey,
    GET_PAGE_LAYOUT_USUSED_FIELDS,
    {
      layoutId,
      device,
    },
    {
      enabled: layoutId != '' && device != '',
    },
  );
  return response;
};

export const usePagelayoutBasicFields = () => {
  let queryKey = ['setting_basicFields'];

  const response = usePost<BaseResponse<IBasicField>>(queryKey, GET_PAGE_LAYOUT_BASIC_FIELDS, {});
  return response;
};

export const usePagelayoutFieldData = () => {
  let queryKey = ['setting_basicFields'];

  const response = usePost<BaseResponse<IField>>(queryKey, GET_PAGE_LAYOUT_BASIC_FIELDS, {});
  return response;
};
