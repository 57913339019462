import { SpanLang } from '@base/components';
import Canvas from '@base/components/canvas';
import { Button, Input, RadioGroup, Select, Switch } from '@base/components/form';
import Portal from '@base/components/portal';
import useMutationPost from '@base/hooks/useMutationPost';
import { IdName } from '@base/types/interfaces/common';
import { GENERATE_SHORTEN_URLS } from '@base/services/graphql/shortenUrls';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { generateUUID } from '@base/utils/helpers';
import { ATYPE_OPTIONS, CTYPE_OPTIONS } from '@settings/preferences/config/constant';
import { CREATE_DESK_CHANNEL, UPDATE_DESK_CHANNEL } from '@settings/preferences/services/graphql';
import { EChannelType, EDeskAssignType } from '@settings/preferences/types/enums';
import { IDeskChannel } from '@settings/preferences/types/interfaces';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AssignedGroupsAutoComplete from '../assigned-groups-auto-complete';
import AssignedUsersAutoComplete from '../assigned-users-auto-complete';
import TicketFormsAutoComplete from '../ticket-forms-auto-complete';

interface INewChannelProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (channel: IDeskChannel, mode: string) => void;
  data: IDeskChannel | null;
}
const LANDING_PAGE_URL = 'https://desk.ncrm.io/landingpage'; //"https://*.vora.net/landingpage/filename'"
const NewChannel: React.FC<INewChannelProps> = (props: INewChannelProps) => {
  const { isOpen, onClose, onSave, data } = props;
  // console.log('data', data);
  const [type, setType] = useState(data ? data.type : EChannelType.LANDING_PAGE);
  const [name, setName] = useState(data ? data.name : '');
  const [desc, setDesc] = useState(data ? data.description : '');
  const [isSaving, setIsSaving] = useState(false);
  const defaultType = CTYPE_OPTIONS.find((op) => {
    return op.value === type;
  });
  // console.log('Data', data);
  const [selType, setSelType] = useState(defaultType);
  const [selForm, setSelForm] = useState<IdName | null>(data?.form ? data.form : null);
  let defaultAType = ATYPE_OPTIONS[0];
  if (data?.assignType) {
    const found = ATYPE_OPTIONS.find((op) => op.value === data.assignType);
    defaultAType = found ? found : defaultAType;
  }

  const [selAType, setSelAType] = useState(defaultAType);
  const [useAssign, setUseAssign] = useState(data?.useAssign ?? true);
  const [realUrl, setRealUrl] = useState(
    data?.realUrl && data?.realUrl != '' ? data?.realUrl : LANDING_PAGE_URL,
  );
  const [shortUrl, setShortUrl] = useState(
    data?.shortUrl && data?.shortUrl != '' ? data?.shortUrl : '',
  );
  const [email, setEmail] = useState(data?.email ?? 'msr@desk.ncrm.io'); //desk@*.vora.net
  const [assignedGroups, setAssignedGroups] = useState<IdName[]>(data?.assignedGroups ?? []);
  const [assignedUsers, setAssignedUsers] = useState<IdName[]>(data?.assignedUsers ?? []);
  const mGenerateLink: any = useMutationPost<BaseMutationResponse>(
    GENERATE_SHORTEN_URLS,
    'common_shortenUrls',
  );
  const mAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_DESK_CHANNEL,
    'desk_createChannel',
  );
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_DESK_CHANNEL,
    'desk_updateChannel',
  );
  const handleSaveChange = () => {
    if (name == '') {
      return;
    }
    let mode = 'add';
    let channel: IDeskChannel = {
      id: '',
      type: EChannelType.LANDING_PAGE,
      name: '',
      description: '',
      active: false,
    };
    setIsSaving(true);
    if (data?.id) {
      mode = 'update';
      channel = {
        ...data,
        name,
        type,
        description: desc,
        assignType: useAssign ? selAType.value : EDeskAssignType.NONE,
        assignedGroups: useAssign && selAType.value === EDeskAssignType.GROUP ? assignedGroups : [],
        assignedUsers: useAssign && selAType.value === EDeskAssignType.USER ? assignedUsers : [],
        useAssign,
        realUrl,
        shortUrl,
        email,
        form: selForm ?? undefined,
      };
      mUpdate.mutate(
        { channel },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
            onSave && onSave(channel, mode);
            setIsSaving(false);
          },
          onError: () => {
            setIsSaving(false);
          },
        },
      );
    } else {
      channel = {
        id: generateUUID(),
        name,
        type,
        description: desc,
        active: true,
        assignType: useAssign ? selAType.value : EDeskAssignType.NONE,
        assignedGroups: useAssign && selAType.value === EDeskAssignType.GROUP ? assignedGroups : [],
        assignedUsers: useAssign && selAType.value === EDeskAssignType.USER ? assignedUsers : [],
        useAssign,
        realUrl,
        shortUrl,
        form: selForm ?? undefined,
      };
      mAdd.mutate(
        { channel },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
            onSave && onSave(channel, mode);
            setIsSaving(false);
          },
          onError: () => {
            setIsSaving(false);
          },
        },
      );
    }
  };
  const onGenerate = () => {
    mGenerateLink.mutate(
      { longUrls: [realUrl] },
      {
        onSuccess: (res: any) => {
          if (res && res?.results.length) {
            setShortUrl(res.results[0].shortUrl);
            toast.success('Short Url has generated!');
          }
        },
      },
    );
  };
  const onCopy = () => {
    navigator?.clipboard?.writeText(shortUrl);
    toast.success('Coppied!');
  };

  return (
    <>
      <Portal>
        <Canvas
          isVisible={isOpen}
          onCloseSideBar={onClose}
          onClose={onClose}
          className={'page-sidebar-container-wrap wd-500'}
          customStyles={{ zIndex: '1052' }}
          backdropStyles={{ zIndex: '1051' }}
        >
          <Canvas.Header
            title={
              !data || data?.id == '' ? (
                <SpanLang keyLang="Create Channel" />
              ) : (
                <SpanLang keyLang="Edit Channel" />
              )
            }
          />
          <Canvas.Body style={{ height: 'calc(100vh - 130px)' }}>
            <div className="form-group">
              <label className="form-item-title">
                Channel Name<span className="tx-danger">*</span>
              </label>
              <Input
                type="text"
                value={name}
                onChange={(nVal: string) => {
                  setName(nVal);
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">
                Channel Type<span className="tx-danger">*</span>
              </label>
              <Select
                options={CTYPE_OPTIONS}
                value={selType}
                onChange={(nVal: any) => {
                  setSelType(nVal);
                  setType(nVal.value);
                }}
                isDisabled={true}
              />
            </div>
            {type === EChannelType.LANDING_PAGE && (
              <>
                <div className="form-group">
                  <label className="form-item-title">
                    Form Name<span className="tx-danger">*</span>
                  </label>
                  <TicketFormsAutoComplete
                    value={selForm}
                    onChange={(nVal: any) => {
                      setSelForm(nVal);
                      setRealUrl(realUrl + '/' + nVal.id);
                    }}
                    single
                  />
                </div>
                <div className="form-group">
                  <label className="form-item-title">
                    Real URL<span className="tx-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    value={realUrl}
                    disabled={true}
                    onChange={(nVal: string) => {
                      setRealUrl(nVal);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-item-title">
                    Short URL<span className="tx-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    value={shortUrl}
                    disabled={true}
                    onChange={(nVal: string) => {
                      setShortUrl(nVal);
                    }}
                  />
                  <div className="d-flex justify-content-end mg-t-5">
                    <Button
                      name="Generate"
                      icon="Settings"
                      className="mg-r-5"
                      color="primary"
                      onClick={() => {
                        onGenerate();
                      }}
                    />
                    <Button
                      name="Copy"
                      icon="Copy"
                      color="success"
                      onClick={() => {
                        onCopy();
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {type === EChannelType.EMAIL && (
              <div className="form-group">
                <label className="form-item-title">
                  Email Address<span className="tx-danger">*</span>
                </label>
                <Input
                  type="email"
                  value={email}
                  onChange={(nVal: any) => {
                    setEmail(nVal);
                  }}
                />
              </div>
            )}

            <div className="form-group">
              <Switch
                value={useAssign}
                label={'Assigned Rep '}
                onChange={() => setUseAssign(!useAssign)}
              />
            </div>
            {useAssign && (
              <>
                <div className="form-group">
                  <RadioGroup
                    options={ATYPE_OPTIONS}
                    value={selAType}
                    onChange={(nVal: any) => setSelAType(nVal)}
                  />
                  {selAType.value === EDeskAssignType.USER && (
                    <AssignedUsersAutoComplete
                      value={assignedUsers}
                      onChange={(nVals: any[]) => {
                        // console.log('nVals', nVals);
                        if (nVals.length > 0) {
                          const values = nVals.map((v) => {
                            return {
                              id: v.id,
                              name: v.name,
                            };
                          });
                          setAssignedUsers(values);
                        }
                      }}
                    />
                  )}
                  {selAType.value === EDeskAssignType.GROUP && (
                    <AssignedGroupsAutoComplete
                      value={assignedGroups}
                      onChange={(nVals: any[]) => {
                        // console.log('nVals', nVals);
                        if (nVals.length > 0) {
                          const values = nVals.map((v) => {
                            return {
                              id: v.id,
                              name: v.name,
                            };
                          });
                          setAssignedGroups(values);
                        }
                      }}
                    />
                  )}
                </div>
              </>
            )}
            <div className="form-group">
              <label className="form-item-title">Descriptions</label>
              <Input
                type="text"
                value={desc}
                onChange={(nVal: string) => {
                  setDesc(nVal);
                }}
              />
            </div>
          </Canvas.Body>
          <Canvas.Footer
            onClose={onClose}
            onSave={handleSaveChange}
            saveColor="success"
            saveLoading={isSaving}
            disabled={name === '' || selForm === null}
          />
        </Canvas>
      </Portal>
    </>
  );
};

export default NewChannel;
