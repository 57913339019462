import * as keyNames from '@settings/process/config/keyNames';
import {
  SelectBox,
  Input,
  TextArea,
  UserAutoComplete,
  ProductAutoComplete,
  CheckBox,
} from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';

import { MODULE_OPTIONS } from '@base/config/constant';
import { PROCESS_TRIGGER_OPTIONS } from '@settings/process/config/constants';

const BusinessWriteField = {
  [keyNames.KEY_NAME_BUSINESS_MODULE]: {
    component: SelectBox,
    showFullRow: true,
    defaultValue: MODULE_OPTIONS[0],
    validate: {},
    useTooltip: false,
    tooltipLangKey: '',
    hideTtile: false,
    languageKey: 'process_business_module',
    componentProps: {
      options: MODULE_OPTIONS,
    },
    parseParam: (value: any) => value.keyName,
  },
  [keyNames.KEY_NAME_BUSINESS_NAME]: {
    component: Input,
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    languageKey: 'process_business_name',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_BUSINESS_DESCRIPTION]: {
    component: TextArea,
    showFullRow: true,
    languageKey: 'process_business_description',
    defaultValue: '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_NAME_BUSINESS_ASSIGN_USER]: {
    component: UserAutoComplete,
    componentProps: {
      single: false,
      showAvatar: true,
      placement: 'left',
    },
    showFullRow: true,
    defaultValue: [],
    languageKey: 'process_business_assign_user',
    parseParam: (_ele: any) => {
      if (_ele.length == 0) {
        return _ele;
      }

      return _ele.map((v: any) => {
        // const group =
        //   v.properties?.crmGroups?.length > 0 ? v.properties.crmGroups[0] : { id: '', name: '' };
        return {
          user: {
            id: v.id,
            name: v.name,
          },
          group: {
            // id: group.id,
            // name: group.name,
            id: '',
            name: '',
          },
        };
      });
    },
  },
  [keyNames.KEY_NAME_BUSINESS_PRODUCT]: {
    component: ProductAutoComplete,
    showFullRow: true,
    defaultValue: [],
    languageKey: 'process_business_product',
    parseParam: (value: any) => value?.map((_ele: any) => ({ id: _ele.id, name: _ele.name })),
  },
  [keyNames.KEY_NAME_BUSINESS_SEND_EMAIL]: {
    component: CheckBox,
    showFullRow: true,
    defaultValue: 'no',
    componentProps: {
      label: 'SEND EMAIL',
    },
    hideTitle: true,
    languageKey: 'process_business_send_email',
    parseParam: (value: string) => (value == 'yes' ? true : false),
  },
  [keyNames.KEY_NAME_BUSINESS_TRIGGER]: {
    component: SelectBox,
    showFullRow: true,
    defaultValue: PROCESS_TRIGGER_OPTIONS[0],
    languageKey: 'process_business_trigger',
    componentProps: {
      options: PROCESS_TRIGGER_OPTIONS,
    },
    parseParam: (value: any) => value.keyName,
  },
};

export default BusinessWriteField;
