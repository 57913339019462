import { lazy } from 'react';

const Main = lazy(() => import('@settings/online-digital-content/ticket-form/pages'));

export default [
  {
    path: '/settings/ticket-form/*',
    component: Main,
  },
];
