import { SpanLang } from '@base/components';
import { generateUUID, nanoid } from '@base/utils/helpers';
import { CONFIG } from '@settings/page-layout/config/common';
import Item from '@settings/page-layout/containers/item';
import { IBasicField } from '@settings/page-layout/types/interfaces/pagelayout';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { propTypes } from 'react-bootstrap/esm/Image';

const AddSection = ({ label = '' }: any) => {
  const COLUMNS = new Array(2).fill(generateUUID());

  return (
    <div className="section-view d-flex card">
      <div className="card-header d-flex align-items-center bg-light">
        <div className="card-title mg-b-0">{label}</div>
      </div>
      <div className="card-body d-flex">
        {COLUMNS.map((column, index) => (
          <div className="col pd-l-0 pd-r-0" key={index}>
            <div className="shopping-bag pd-5">
              <div className="decription-drag-drop tx-gray-500 tx-center">
                <SpanLang keyLang="crm_new_pagelayout_msg_drag_drop_item" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// This method is needed for rendering clones of draggables
const getRenderItem =
  // eslint-disable-next-line react/display-name
  (items: any, componentItem: any) => (provided: any, snapshot: any, rubric: any) => {
    const item = items[rubric.source.index];
    const isAddSection = rubric.source.droppableId == CONFIG.sections;
    return (
      <>
        <li
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={provided.draggableProps.style}
          className={snapshot.isDragging ? 'dragging' : ''}
        >
          {isAddSection ? (
            <AddSection label={item.label} />
          ) : (
            <Item
              // type={componentItem} @TODO re-check
              label={<SpanLang keyLang={item.languageKey} />}
              icon={item?.fieldIcon?.icon ?? ''}
              iconType={item?.fieldIcon?.iconType ?? ''}
            />
          )}
        </li>
      </>
    );
  };

interface ICopyableProps {
  droppableId: string;
  items: IBasicField[];
  className: string;
  isCenter: boolean;
  componentItem?: any;
  isAddCustomField?: boolean;
  isLoading?: boolean;
}
const Copyable: React.FC<ICopyableProps> = (props: ICopyableProps) => {
  const {
    items,
    componentItem = null,
    droppableId,
    className,
    isCenter,
    isAddCustomField = true,
    isLoading = false,
  } = props;
  // console.log('droppableId', droppableId);
  return (
    <Droppable
      renderClone={getRenderItem(items, componentItem)}
      droppableId={droppableId}
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <ul ref={provided.innerRef} className={className}>
          {items.map((item, index) => {
            const shouldRenderClone = item.type === snapshot.draggingFromThisWith;
            return (
              <React.Fragment key={index}>
                {shouldRenderClone ? (
                  <li className="react-beatiful-dnd-copy">
                    <Item
                      // type={props.componentItem}
                      label={<SpanLang keyLang={item.languageKey} />}
                      icon={item?.fieldIcon?.icon ?? ''}
                      iconType={item?.fieldIcon?.iconType ?? ''}
                      isCenter={isCenter}
                    />
                  </li>
                ) : (
                  <Draggable
                    draggableId={`basic-field-${nanoid()}`}
                    index={index}
                    isDragDisabled={!isAddCustomField || isLoading} // || item.isLoading
                  >
                    {(provided, snapshot) => (
                      <React.Fragment>
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={snapshot.isDragging ? 'dragging' : ''}
                        >
                          <Item
                            label={<SpanLang keyLang={item.name} />}
                            icon={item?.fieldIcon?.icon ?? ''}
                            iconType={item?.fieldIcon?.iconType ?? ''}
                            isCenter={isCenter}
                            isLoading={isLoading}
                            isDisabled={!isAddCustomField}
                          />
                        </li>
                      </React.Fragment>
                    )}
                  </Draggable>
                )}
              </React.Fragment>
            );
          })}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export default Copyable;
