import { GET_AUTOMATION_RULE } from './../services/automation';
import { AutomationRule } from '@settings/process/types/automation';
import { IdName } from '@base/types/interfaces/common';
import usePost from '@base/hooks/usePost';
import { BaseResponse } from '@base/types/interfaces/response';
import { GET_DEFINED_FIELDS } from '@settings/process/services/custom';
import { GET_PROCESS_STEPS, MODULE_PROCESSES } from '@settings/process/services/process';
import {
  DefinedItemType,
  BusinessStep,
  DefinedField,
  DefinedItems,
} from '@settings/process/types/process';
import { SettingType } from '../types/settings';

export const useGetModuleProcesses = ({ module }: any) => {
  const queryKey = ['process_moduleProcesses', module];
  const response = usePost<BaseResponse<IdName>>(
    queryKey,
    MODULE_PROCESSES,
    {
      module: module,
    },
    {
      enabled: !!module,
    },
  );
  return response;
};

export const useDefinedFields = (module?: string) => {
  const queryKey = ['process_definedFields'];
  const response = usePost<BaseResponse<DefinedField>>(
    queryKey,
    GET_DEFINED_FIELDS,
    {
      isTenant: false,
      module: module,
    },
    {
      cacheTime: 1000 * 5,
    },
  );
  return response;
};

export const useGetProcessSteps = (processId?: string, query?: string) => {
  const queryKey = ['process_processSteps'];
  const response = usePost<BaseResponse<BusinessStep>>(
    queryKey,
    GET_PROCESS_STEPS(query),
    {
      processId: processId,
    },
    {
      enabled: !!processId,
    },
  );
  return response;
};

export const useGetDefinedItems = (
  query: string,
  type: DefinedItemType | undefined,
  tab: SettingType,
  page: number,
) => {
  const queryKey = ['process_definedItems', tab, page];
  const response = usePost<DefinedItems>(
    queryKey,
    query,
    {
      type: type,
    },
    {
      enabled: !!tab,
    },
  );

  return response;
};

export const useGetAutomationRule = (id: string | undefined) => {
  const queryKey = ['process_automationRule', id];
  const response = usePost<AutomationRule>(
    queryKey,
    GET_AUTOMATION_RULE,
    {
      id: id,
    },
    {
      enabled: !!id,
    },
  );
  return response;
};
