import * as keyNames from '@settings/online-digital-content/cta/config/key-names';
import * as commonComponents from '@base/config/view-field/components';
import * as components from './components';
import {
  SETTING_CTA_CONTENT_TYPES,
  SETTING_CTA_LINK_TYPES,
  SETTING_CTA_STAGE_OPTIONS,
  SETTING_CTA_TEXT_FONT_WEIGHT_OPTIONS,
  SETTING_CTA_TYPES,
} from '../constants';
import * as commonConfig from '@base/config/view-field';
import { IFieldConfig } from '@base/types/interfaces/common';

const configView: IFieldConfig = {
  ...commonConfig?.default,
  // private fields
  [keyNames.KEY_SETTING_CTA_VALUE]: {
    schema: ``,
  },
  [keyNames.KEY_SETTING_CTA_TYPE]: {
    component: commonComponents.Selectbox,
    schema: `type`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
    componentProps: {
      isSearchable: false,
      options: SETTING_CTA_TYPES,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (apiData: any) => {
      return SETTING_CTA_TYPES?.find(
        (v: any) => v.value === apiData?.[keyNames.KEY_SETTING_CTA_TYPE],
      );
    },
    getValueView: (value: any) => {
      return value?.label;
    },
    getMutationValue: (value: any) => {
      return value.value;
    },
  },
  [keyNames.KEY_SETTING_CTA_LANGUAGE]: {
    component: components.LanguageSelectView,
    schema: `language`,
    componentProps: {
      options: [],
    },
    getValueView: (value: any) => {
      return value;
    },
  },
  [keyNames.KEY_SETTING_CTA_IMAGE_URL]: {
    component: null,
    schema: keyNames.KEY_SETTING_CTA_IMAGE_URL,
  },
  [keyNames.KEY_SETTING_CTA_IMAGE_SIZE]: {
    component: components.ImageSizeView,
    schema: `imgSize {
      width
      height
    }`,
    getValue: (apiData: any) => {
      return apiData?.imgSize;
    },
    getValueView: (value: any) => {
      return value;
    },
  },
  [keyNames.KEY_SETTING_CTA_IMAGE_ALT]: {
    component: commonComponents.TextView,
    schema: keyNames.KEY_SETTING_CTA_IMAGE_ALT,
  },
  [keyNames.KEY_SETTING_CTA_LINK_TYPE]: {
    schema: `linkType`,
    component: commonComponents.Selectbox,
    componentProps: {
      options: SETTING_CTA_LINK_TYPES,
      isSearchable: false,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (apiData: any) => {
      return (
        SETTING_CTA_LINK_TYPES?.find(
          (v: any) => v.value === apiData?.[keyNames.KEY_SETTING_CTA_LINK_TYPE],
        ) ?? null
      );
    },
    getValueView: (value: any) => {
      return value?.label;
    },
    getMutationValue: (value: any) => {
      return value.value;
    },
  },
  [keyNames.KEY_SETTING_CTA_EXT_SITE_NAME]: {
    component: commonComponents.TextView,
    schema: `externalSiteName`,
  },
  [keyNames.KEY_SETTING_CTA_LINK_URL]: {
    schema: `linkUrl
    openPageInNewWindow
    `,
    component: components.LinkUrlView,
    getValue: (apiData: any) => {
      return {
        link: apiData?.linkUrl,
        openNewWindow: apiData?.openPageInNewWindow,
      };
    },
  },
  [keyNames.KEY_SETTING_CTA_RESOURCE]: {
    schema: `resource {
      id
      name
    }
    contentType
    `,
    component: components.ResourceView,
    componentProps: {
      single: true,
    },
    getValue: (apiData: any) => {
      return {
        contentType: apiData?.contentType,
        resource: apiData?.resource,
      };
    },
    getValueView: (value: any) => {
      return value?.resource ?? null;
    },
    getValueEdit: (value: any) => {
      const resourceData = value?.resource?.id
        ? { ...value.resource, value: value?.resource?.id, label: value?.resource?.name }
        : null;
      return resourceData;
    },
    getMutationValue: (value: any) => {
      return { resource: { id: value?.value, name: value?.label } };
    },
    getChangedValue: (value: any) => {
      return {
        resource: { id: value?.id, name: value?.name },
      };
    },
  },
  [keyNames.KEY_SETTING_CTA_RESOURCE_TYPE]: {
    schema: ``,
    component: commonComponents.TextView,
    componentProps: {},
    viewProps: { userPermission: { isEdit: false, isShow: false } },
  },
  [keyNames.KEY_SETTING_CTA_CONTENT_TYPE]: {
    schema: `contentType`,
    component: commonComponents.Selectbox,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
    componentProps: {
      options: SETTING_CTA_CONTENT_TYPES,
      isSearchable: false,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (apiData: any) => {
      return (
        SETTING_CTA_CONTENT_TYPES?.find(
          (v: any) => v.value === apiData?.[keyNames.KEY_SETTING_CTA_CONTENT_TYPE],
        ) ?? null
      );
    },
    getValueView: (value: any) => {
      return value?.label;
    },
    getMutationValue: (value: any) => {
      return value?.value;
    },
    getRecoilStateValue: (value: any) => {
      return value;
    },
    getExtraMutationParam: (apiData: any) => {
      return {
        resource: null,
      };
    },
  },
  [keyNames.KEY_SETTING_CTA_PAGE_TITLE]: {
    component: commonComponents.TextView,
    schema: `pageTitle`,
  },
  [keyNames.KEY_SETTING_CTA_DESCRIPTION]: {
    component: commonComponents.TextAreaView,
    schema: `description`,
  },
  [keyNames.KEY_SETTING_CTA_AB_TEST]: {
    schema: `abTest`,
    component: commonComponents.Switch,
  },
  [keyNames.KEY_SETTING_CTA_STAGE]: {
    schema: `stage`,
    component: commonComponents.Selectbox,
    componentProps: {
      options: SETTING_CTA_STAGE_OPTIONS,
      isSearchable: false,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (apiData: any) => {
      return (
        SETTING_CTA_STAGE_OPTIONS?.find(
          (v: any) => v.value === apiData?.[keyNames.KEY_SETTING_CTA_STAGE],
        ) ?? null
      );
    },
    getValueView: (value: any) => {
      return value?.label ?? '';
    },
    getMutationValue: (value: any) => {
      return value.value;
    },
  },
  [keyNames.KEY_SETTING_CTA_VIEW]: {
    schema: ``,
  },
  [keyNames.KEY_SETTING_CTA_CLICK]: {
    schema: ``,
  },
  [keyNames.KEY_SETTING_CTA_CLICK_RATE]: {
    schema: ``,
  },
  [keyNames.KEY_SETTING_CTA_PREVIEW]: {
    schema: ``,
  },
  [keyNames.KEY_SETTING_CTA_TEXT_FONT_WEIGHT]: {
    component: commonComponents.Selectbox,
    schema: keyNames.KEY_SETTING_CTA_TEXT_FONT_WEIGHT,
    componentProps: {
      isSearchable: false,
      options: SETTING_CTA_TEXT_FONT_WEIGHT_OPTIONS,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    getValue: (apiData: any) => {
      return SETTING_CTA_TEXT_FONT_WEIGHT_OPTIONS?.find(
        (v: any) => v.value === apiData?.[keyNames.KEY_SETTING_CTA_TEXT_FONT_WEIGHT],
      );
    },
    getValueView: (value: any) => {
      return value?.label ?? '';
    },
  },
  [keyNames.KEY_SETTING_CTA_TEXT_BG_COLOR]: {
    schema: keyNames.KEY_SETTING_CTA_TEXT_BG_COLOR,
    component: components.InputColorView,
  },
  [keyNames.KEY_SETTING_CTA_TEXT_TEXT_COLOR]: {
    schema: keyNames.KEY_SETTING_CTA_TEXT_TEXT_COLOR,
    component: components.InputColorView,
  },
  [keyNames.KEY_SETTING_CTA_TEXT_FONT_SIZE]: {
    schema: keyNames.KEY_SETTING_CTA_TEXT_FONT_SIZE,
    component: commonComponents.TextView,
    componentProps: {
      type: 'number',
    },
  },
  [keyNames.KEY_SETTING_CTA_TEXT_ROUNDED]: {
    schema: keyNames.KEY_SETTING_CTA_TEXT_ROUNDED,
    component: commonComponents.TextView,
    componentProps: {
      type: 'range',
    },
  },
  [keyNames.KEY_SETTING_CTA_QR_CODE]: {
    schema: `qrCode`,
  },
};

export default configView;
