import React, { lazy, memo, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { MENU_SETTING_PAGELAYOUT } from '@base/config/menus';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayout from '@settings/general-setting/config/display-layout';
import '@settings/general-setting/assets/scss/style.scss';
import { ISideMenuItem } from '@base/types/interfaces/app';
import { usePagelayoutMenus } from '@settings/page-layout/services/pagelayout-service';
import '@settings/page-layout/assets/scss/styles.scss';
import WriteCustomFieldForm from '@settings/page-layout/containers/write-custom-field-form';
import { IField, ITransferData } from '@settings/page-layout/types/interfaces/pagelayout';
import { toast } from 'react-toastify';

const LayoutPage = lazy(() => import('@settings/page-layout/pages/layout'));

const getPreviousUrl = (params: any, pageData: any): string => {
  let prePath = '';
  // prePath empty in case params same with recoil data
  if (
    params.activeMenu == pageData.activeMenu &&
    params.activeId == pageData.activeId &&
    params.activeTab == pageData.activeTab
  ) {
    return prePath;
  }
  if (params.activeMenu == '' && pageData.activeMenu && pageData.activeMenu != '') {
    prePath = `/settings/page-layout/${pageData.activeMenu}`;
  }
  if (
    prePath != '' &&
    params.activeId == '' &&
    pageData.activeMenu &&
    pageData.activeMenu != '' &&
    pageData.activeId &&
    pageData.activeId != ''
  ) {
    prePath = `${prePath}/${pageData.activeId}`;
  }

  return prePath;
};

const MainPage: React.FC = () => {
  const params = useParams();
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_SETTING_PAGELAYOUT));
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const navigate = useNavigate();
  const aParams = params['*'] ? params['*'].split('/') : [];
  const activeMenu = aParams[0] ? aParams[0] : '';
  const activeId = aParams[1] ? aParams[1] : '';
  const activeTab = aParams[2] ? aParams[2] : '';
  const prePath = getPreviousUrl({ activeMenu, activeId, activeTab }, pageData);
  const { data: menuResp, isLoading: isMenuLoading, refetch } = usePagelayoutMenus();
  const [menus, setMenus] = useState<ISideMenuItem[]>([]);
  const [isOpenWrite, setIsOpenWrite] = useState(false);
  const [itemEdit, setItemEdit] = useState<ITransferData>({
    time: 0,
    item: { id: '' },
    totalDefaultViewInList: 0,
  });
  const [dataFromEdit, setDataFromEdit] = useState<ITransferData>({
    time: 0,
    item: { id: '' },
  });
  // console.log('MainPage', pageData); //, menuResp, isMenuLoading);
  // // console.log('pageData main', pageData);
  // load menu language
  // useLanguageByMenu(['pagelayout']);
  const pageProps = {
    menus: menus,
    activeMenu: 'settings/page-layout',
    pageTitle: 'Page layout',
    loading: false,
    match: {
      params: {
        menu: activeMenu,
        id: activeId,
      },
    },
    setItemEdit,
    setIsOpenWrite,
    setDataFromEdit,
    dataFromEdit,
  };
  // //update a item property
  const onSavedFieldData = (savedData: IField) => {
    setDataFromEdit({
      time: new Date().getTime(),
      item: {
        ...savedData,
      },
    });
    // clear edit item data
    setItemEdit({
      time: 0,
      item: { id: '' },
      totalDefaultViewInList: 0,
    });
  };
  useEffect(() => {
    let hasUpdate = false;
    if (activeMenu != '' && pageData.activeMenu != activeMenu) {
      hasUpdate = true;
    }
    if (pageData.activeId != activeId) {
      hasUpdate = true;
    }
    if (hasUpdate) {
      // set default filter
      let newData = {
        ...pageData,
        activeMenu: activeMenu,
        activeId: activeId,
      };
      setPageData(newData);
    }
    if (prePath != '') {
      navigate(prePath);
    }
  }, [activeMenu, activeId]);

  useEffect(() => {
    if (!isMenuLoading && menuResp?.results?.length) {
      let isNotDefault = true;
      let newMenus: ISideMenuItem[] = [];
      menuResp.results.forEach((menu: any) => {
        let nLayouts: ISideMenuItem[] = [];
        if (menu.layouts && menu.layouts.length > 0) {
          menu.layouts.forEach((layout: any) => {
            let isDefault = isNotDefault ? true : false;
            isNotDefault = false;
            nLayouts.push({
              ...layout,
              component: LayoutPage,
              default: isDefault,
            });
          });
        }
        let nMenu: ISideMenuItem = {
          id: menu.id,
          keyName: menu.keyName,
          languageKey: menu.languageKey,
          icon: menu.icon,
          layouts: nLayouts,
          component: LayoutPage,
        };
        newMenus.push(nMenu);
      });
      setMenus(newMenus);
    }
  }, [menuResp]);

  return (
    <>
      <BaseLayoutDisplay
        displayConfig={displayLayout}
        device={device}
        layout={layout}
        form="setting"
        {...pageProps}
      />
      <WriteCustomFieldForm
        isOpen={isOpenWrite}
        setIsOpen={setIsOpenWrite}
        onSavedFieldData={onSavedFieldData}
        initFieldData={itemEdit.item}
        initTotalDefaultViewInList={itemEdit.totalDefaultViewInList}
      />
    </>
  );
};

export default MainPage;
