import * as components from './components';

export default {
  desktop: {
    layout1: {
      setting: components.SettingDesktopLayout1,
      list: components.ListDesktopLayout1,
      view: components.ViewDesktopLayout1,
    },
  },
  tablet: {
    layout1: {},
  },
  mobile: {
    layout1: {},
  },
};
