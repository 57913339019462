export const finalizeParams = (formData: any) => {
  let aR = {
    ...formData,
    active: true,
    module: formData.module?.length ? formData.module[0].keyName : '',
  };

  let newParams: any = {
    ar: aR,
  };

  return newParams;
};
