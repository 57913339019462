import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DiagramStage from './stage/StageList';
import { useParams } from 'react-router-dom';
import Loading from '@base/components/loading';
import './diagram-new.scss';
import { ModalContainer } from '@base/containers/aside-modal';
import {
  useClosedOpenAtomState,
  useNextStepAtomState,
  useNodeEdgesAtomState,
  useStageAtomState,
  useStageOpenAtomState,
} from '@settings/process/recoil/diagram';
import { StepView, StepWrite } from './step';
import { StageWrite, StageView } from './stage';
import usePost from '@base/hooks/usePost';
import { INextSteps } from '@settings/process/types/process';
import { GET_NEXT_STEPS } from '@settings/process/services/process';
import { OptionValue } from '@base/types/interfaces/common';
import {
  NodeStatus,
  NodeSimple,
  NodeAction,
  NodeChecklist,
  NodeCriteria,
  NodeWait,
  NodeSite,
} from '@settings/process/components/diagram/node';
import { Edge, Node, NodeEdges, ResultDiagram } from '@settings/process/types/diagram';
import { Plus } from 'react-feather';
import { ClosedView, ClosedWrite } from './closed';
import { checkStep } from '@settings/process/utils/helper';
import {
  EdgeAction,
  EdgeSimple,
  EdgeCriteria,
  EdgeChecklist,
  EdgeWait,
  EdgeSite,
} from '@settings/process/utils';
import _ from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { stepAddOpenAtom, stepWithClose } from '@settings/process/recoil/step';
import { useModalSizeAtomState } from '@base/recoil/atoms';

export interface DiagramContainerProps {
  data: ResultDiagram | undefined;
  isLoading: boolean;
}

function DiagramContainer({ data, isLoading }: DiagramContainerProps) {
  // console.log('diagram view');
  const { id: processId } = useParams();
  const stepWrite = useRecoilValue(stepAddOpenAtom);
  const [stageView, setStageView] = useStageOpenAtomState();
  const [stages, setStages] = useStageAtomState();
  const [, setNextSteps] = useNextStepAtomState();
  const [nodeEdges, setNodeEdges] = useNodeEdgesAtomState();
  const [closedOpen, setClosedOpen] = useClosedOpenAtomState();
  const [closedX, setClosedX] = useState(0);
  const resetStepClose = useSetRecoilState(stepWithClose);
  const setModalSize = useModalSizeAtomState();

  const onStepClose = useCallback(() => {
    resetStepClose();
  }, []);

  const onStageViewClose = useCallback(() => {
    setStageView({ sourceId: '', stage: null });
  }, []);

  const onClosedStatusClose = useCallback(() => {
    setClosedOpen({ sourceId: '', open: false });
  }, []);

  const onClosedStatusOpen = useCallback(() => {
    setClosedOpen({ sourceId: '', open: true });
  }, []);

  const { data: stepsData } = usePost<INextSteps>(
    ['process_nextSteps', processId],
    GET_NEXT_STEPS,
    {
      id: processId,
    },
  );

  useEffect(() => {
    if (
      stepWrite.sourceType == 'TYPE_ACTION' ||
      stepWrite.sourceType == 'TYPE_SIMPLE_ACTION' ||
      stepWrite.sourceType == 'TYPE_SITE'
    ) {
      setModalSize('wd-1100');
    } else if (stepWrite.sourceType == 'TYPE_WAIT') {
      setModalSize('wd-800');
    } else if (stepWrite.sourceType == 'TYPE_CRITERIA') {
      setModalSize('wd-800');
    } else {
      setModalSize('wd-600');
    }
  }, [stepWrite.sourceType]);

  useEffect(() => {
    let stepOptions: OptionValue[] = [
      {
        keyName: '',
        languageKey: 'None',
      },
    ];
    if (stepsData && stepsData.steps) {
      for (const step of stepsData.steps) {
        stepOptions.push({ keyName: step.id, languageKey: step.name });
      }
    }
    setNextSteps(stepOptions);
  }, [stepsData?.steps]);

  useEffect(() => {
    let edgesIndex: { [index: string]: Edge[] } = {};
    if (data?.edges) {
      for (const edge of data.edges) {
        const e: Edge = {
          id: edge.id, // status id
          source: edge.source, // source step id
          target: edge.target, // target step id (next step)
          sourceDirection: edge.directionS, // status direction
          targetDirection: edge.directionT, // direction : backward(bottom), middle(r-middle) -> top, forward(right) -> left
          label: edge.label, // status name
          multiple: edge.multiple,
          primary: edge.primary,
          multipleOrder: 0,
          shape: 'SHAPE_FORWARD',
          height: 0,
          position: { x: 0, y: 0 },
          property: edge.property,
          options: edge.options,
        };
        if (!edgesIndex[edge.source]) {
          edgesIndex[edge.source] = [];
        }
        edgesIndex[edge.source].push(e);
      }
    }

    if (data?.nodes) {
      let closedLeft = 0;
      let nodes: NodeEdges = { others: {}, closed: {} };
      for (const node of data.nodes) {
        let className = (node.shape as string).toLowerCase();
        if (node.shape == 'SHAPE_BACKWARD' || node.shape == 'SHAPE_MIDDLE') {
          className += ' not-forward';
        }

        const n: Node = {
          id: node.id,
          type: node.type,
          data: {
            label: node.label,
            tooltip: node.label,
            method: node.method,
          },
          shape: node.shape,
          position: { x: node.positionX, y: node.positionY },
          property: node.property,
          edges: edgesIndex[node.id],
          className: className,
        };
        if (node.type == 'TYPE_CLOSE') {
          nodes.closed[n.id] = n;
        } else {
          nodes.others[n.id] = n;
        }

        if (node.positionX > closedLeft) {
          closedLeft = node.positionX;
        }
      }

      if (data.stages) {
        // 180 : stage width
        // 44 : arrow width for each step
        // -44 : minus last stage arrow
        // -140 : last stage width
        const stagesWidth = data.stages.map((stage) => stage.width);
        const sumWidth = _.sum(stagesWidth);
        // 120 - last step width
        // 130 - step arrow width
        // 20 - spare space
        // 200 - close step width
        const spareSpace = 120 + 130 + 20 + 200;
        closedLeft += spareSpace;
        if (closedLeft < sumWidth) {
          closedLeft = sumWidth;
        }
        let stageX = 0;
        const newStages = data.stages.map((stage) => {
          stageX += stage.width;
          return { ...stage, ...{ axisX: stageX } };
        });
        setStages(newStages);
      }
      setClosedX(closedLeft);
      setNodeEdges(nodes);
    }
  }, [data]);

  // // console.log('node edges', nodeEdges);

  const stepHelper = useMemo(() => {
    return checkStep(nodeEdges.others);
  }, [nodeEdges.others]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="scroll-box d-flex flex-column flex-grow-1 pd-20">
            {stages && closedX > 0 && <DiagramStage closedX={closedX} processId={processId!!} />}
            <div className="pos-relative flex-grow-1">
              {Object.entries(nodeEdges.others).map(([key, node]) => {
                if (node.type == 'TYPE_SIMPLE_ACTION') {
                  const edges = EdgeSimple({
                    node: node,
                    stepHelper: stepHelper,
                  });
                  return (
                    <NodeSimple
                      key={key}
                      processId={processId!!}
                      node={{ ...node, ...{ edges: edges } }}
                      stepHelper={stepHelper}
                    />
                  );
                } else if (node.type == 'TYPE_CRITERIA') {
                  const edges = EdgeCriteria({
                    node: node,
                    stepHelper: stepHelper,
                  });
                  return (
                    <NodeCriteria
                      key={key}
                      processId={processId!!}
                      node={{ ...node, ...{ edges: edges } }}
                      stepHelper={stepHelper}
                    />
                  );
                } else if (node.type == 'TYPE_CHECKLIST') {
                  const edges = EdgeChecklist({
                    node: node,
                    stepHelper: stepHelper,
                  });
                  return (
                    <NodeChecklist
                      key={key}
                      processId={processId!!}
                      node={{ ...node, ...{ edges: edges } }}
                      stepHelper={stepHelper}
                    />
                  );
                } else if (node.type == 'TYPE_WAIT') {
                  const edges = EdgeWait({
                    node: node,
                  });
                  return (
                    <NodeWait
                      key={key}
                      processId={processId!!}
                      node={{ ...node, ...{ edges: edges } }}
                      stepHelper={stepHelper}
                    />
                  );
                } else if (node.type == 'TYPE_SITE') {
                  const edges = EdgeSite({
                    node: node,
                    stepHelper: stepHelper,
                  });
                  return (
                    <NodeSite
                      key={key}
                      processId={processId!!}
                      node={{ ...node, ...{ edges: edges } }}
                      stepHelper={stepHelper}
                    />
                  );
                } else {
                  const edges = EdgeAction({
                    node: node,
                    stepHelper: stepHelper,
                  });
                  return (
                    <NodeAction
                      key={key}
                      processId={processId!!}
                      node={{ ...node, ...{ edges: edges } }}
                      stepHelper={stepHelper}
                    />
                  );
                }
              })}
              {closedX > 0 && (
                <div
                  className="close-status-wrap"
                  style={{ top: '130px', left: `${closedX - 200}px` }}
                >
                  {Object.entries(nodeEdges.closed).map(([key, node]) => {
                    return <NodeStatus key={key} processId={processId!!} node={node} />;
                  })}
                  <button
                    type="button"
                    className="btn btn-xs btn-outline-primary btn-icon mg-t-10"
                    onClick={() => onClosedStatusOpen()}
                  >
                    <Plus />
                    <span className="sr-only">추가</span>
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* <NewStep /> */}
          <ModalContainer
            isOpen={!stepWrite.edit && stepWrite.sourceId != ''}
            isView={true}
            onClose={onStepClose}
            title={'Create Step'}
            viewComponent={
              !stepWrite.edit &&
              stepWrite.sourceId && <StepWrite processId={processId!} onClose={onStepClose} />
            }
            isFooter={false}
          />

          {/* Edit/View Step */}
          <ModalContainer
            isOpen={stepWrite.edit && stepWrite.sourceId != ''}
            isView={true}
            onClose={onStepClose}
            title={'View Step'}
            viewComponent={
              stepWrite.edit &&
              stepWrite.sourceId && <StepView processId={processId!} stepId={stepWrite.sourceId} />
            }
            isFooter={false}
          />

          {/* New/EditProcessStage */}
          <ModalContainer
            isOpen={stageView.sourceId != ''}
            isView={stageView.stage != null}
            onClose={onStageViewClose}
            title={stageView.stage != null ? 'View Stage' : 'Create Stage'}
            modalSize={'wd-400'}
            viewComponent={
              stageView.stage != null && (
                <StageView processId={processId!} stage={stageView.stage!} />
              )
            }
            writeComponent={
              stageView.sourceId != '' && (
                <StageWrite processId={processId!} onClose={onStageViewClose} />
              )
            }
            isFooter={false}
          />

          {/* Closed status */}
          <ModalContainer
            isOpen={closedOpen.open}
            isView={closedOpen.sourceId != ''}
            onClose={onClosedStatusClose}
            title={closedOpen.sourceId != '' ? 'View Closed Status' : 'Create Closed Status'}
            writeComponent={<ClosedWrite processId={processId!} onClose={onClosedStatusClose} />}
            viewComponent={
              closedOpen.sourceId != '' && (
                <ClosedView processId={processId!} stepId={closedOpen.sourceId!} />
              )
            }
            isFooter={false}
          />
        </>
      )}
    </>
  );
}

export default DiagramContainer;
