import withMiModal from '@base/hooks/hocs/withMiModal';
import { goParseFields } from '@base/utils/helpers/page-layout-utils';
import React from 'react';
import WriteForm from './write-form';

const WriteCustomer: React.FC<any> = (props: any) => {
  const { sections } = props;
  const { fields } = goParseFields(sections, 'customer_account');
  // console.log(sections,fields)
  return <WriteForm {...props} fields={fields} />;
};
export default withMiModal(WriteCustomer);
