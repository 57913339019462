import { EAREntryAssignToMode, EARAssignToType } from '@settings/assignment-rule/types/enums';
interface ChangeCondition {
  category: {
    id: string;
    name: string;
  };
  product: {
    id: string;
    name: string;
  };
}
interface ClassificationCondition {
  language: {
    id: string;
    name: string;
  };
  region: {
    id: string;
    name: string;
  };
}
export const finalizeParams = (formData: any) => {
  let criteriaFormat: any[] = [];
  let assignsToFormat: any[] = [];
  if (formData.criteria) {
    formData.criteria.map((item: any) => {
      const nVal = item.condition;
      if (item.key == 'category' && nVal?.category && nVal?.product) {
        let setValTmp: ChangeCondition = {
          category: { id: nVal?.category?.id, name: nVal?.category?.name },
          product: { id: nVal?.product?.id, name: nVal?.product?.name },
        };

        item = {
          ...item,
          condition: JSON.stringify(setValTmp),
        };
        criteriaFormat.push(item);
      } else if (item.key == 'ticket_classification' && nVal?.language && nVal?.region) {
        let setValTmp: ClassificationCondition = {
          language: {
            id: nVal?.language?.id,
            name: nVal?.language?.value ? nVal?.language?.value : nVal?.language?.name,
          },
          region: {
            id: nVal?.region?.id,
            name: nVal?.region?.value ? nVal?.region?.value : nVal?.region?.name,
          },
        };

        item = {
          ...item,
          condition: JSON.stringify(setValTmp),
        };
        criteriaFormat.push(item);
      } else if (item.condition) {
        item = { ...item, condition: JSON.stringify(item.condition) };
        criteriaFormat.push(item);
      }
      return item;
    });
  }
  if (formData.assignTo.assignsTo) {
    if (formData.assignTo.mode === EAREntryAssignToMode.USERS) {
      formData.assignTo.assignsTo.map((assignTo: any) => {
        assignsToFormat.push({ user: assignTo?.user ? assignTo?.user : assignTo });
      });
    }
  }
  let aR = {
    ...formData,
    criteria: criteriaFormat,
    assignTo: { ...formData.assignTo, assignsTo: assignsToFormat },
  };

  let newParams: any = {
    arEntry: aR,
  };

  return newParams;
};
