import React, { useState, useEffect, useRef } from 'react';
import Icons from '@base/assets/icons/svg-icons';
import { Image, Trash, Copy } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Loading from '@base/components/loading';
import {
  Q_IMAGE,
  Q_TITLE,
  Q_VIDEO,
} from '@settings/online-digital-content/survey/config/constants';
//import QNextPrevMove from '../q-next-prev-move';
import { Draggable } from 'react-beautiful-dnd';
import { SpanLang } from '@base/components';

//Question Media: title, image, video
const QuestionMedia: React.FC<any> = (props) => {
  const {
    keyS,
    keyQ,
    focusS,
    focusQ,
    focusEle,
    value, //=question content: {type, title, image, required, options}
    onChange, //handle question change
    setFocusEle,
    onFocusQuestionChange,
    //focused - buttons event
    onRemoveQuestion,
    onCopyQuestion,
    // onQMoveNext,
    // onQMovePrev,
    // onQMoveNextSection,
    // onQMovePrevSection,
    //mouse down event
    //onCopyQ,
    //onRemoveQ
  } = props;
  //lang
  const { t } = useTranslation();

  //local state
  const [isLoading, setIsLoading] = useState(false);
  const [questionValue, setQuestionValue] = useState<any>(value);
  const uploadImgRef = useRef<any>(null);
  const descRef = useRef<any>(null);
  //let initialRef = useRef<any>(null); //for only first Run

  //monitor init
  // useEffect(() => {
  //   const qImageEleId = 'q-mediaimage-' + keyS + '-' + (keyQ + 1); //for question image
  //   //open file dialog
  //   if (focusEle === qImageEleId) {
  //     if (!initialRef.current) {
  //       openUpload();
  //       initialRef = uploadImgRef;
  //     }
  //   }
  // }, []); //focusEle

  useEffect(() => {
    //set pointer to end
    if (descRef && descRef.current) {
      descRef.current.selectionStart = descRef.current.value.length;
      descRef.current.selectionEnd = descRef.current.value.length;
    }
  }, []); //descRef

  //handle mouseup to check element event click and focus
  // useEffect(() => {
  //   document.addEventListener('mousedown', handleEleClick);
  //   return () => {
  //     document.removeEventListener('mousedown', handleEleClick);
  //   };
  // }, []);

  //add question when focusing on child (question) component
  // function handleEleClick(e: any) {
  //   const el = e.path;
  //   //// console.log('el q', el);

  //   //handle focusing
  //   let qEleId = el[0].id ? el[0].id : el[1].id;
  //   qEleId = qEleId ? qEleId : el[2].id;
  //   qEleId = qEleId ? qEleId : el[3].id;
  //   //// console.log('qEleId', qEleId);

  //   //format: q-optinput-0-5-1 --> 0: section index, 5: question index, 1: option index
  //   if (qEleId) {
  //     const items = qEleId.split('-');
  //     const nameEle = items[0] + '-' + items[1];
  //     const elKeyS = parseInt(items[2]); //keyS in element id
  //     const elKeyQ = parseInt(items[3]); //keyQ in element id
  //     if (
  //       //nameEle === 'q-mediaimage'
  //       nameEle === 'q-mediacopy'
  //       || nameEle === 'q-mediaremove'
  //     ) {
  //       setFocusEle(qEleId); //reset focus element
  //       //copy question when on-focus
  //       if (nameEle === 'q-mediacopy' && focusS === elKeyS && focusQ === elKeyQ) {
  //         onCopyQ();
  //       }
  //       //remove question when on-focus
  //       if (nameEle === 'q-mediaremove' && focusS === elKeyS && focusQ === elKeyQ) {
  //         onRemoveQ();
  //       }
  //     }
  //   } else {
  //     //setFocusEle('');
  //   }
  // }

  //open dialog file and upload
  function openUpload() {
    // `current` points to the mounted file input element
    if (uploadImgRef.current) {
      uploadImgRef.current.click();
    }
  }

  //TODO: get file and upload
  function handleImageQChange(e: any) {
    const files: any = e.target.files;
    // console.log('files e', e);

    //TODO: upload file
    if (files.length > 0) {
      //save to state
      handleQValueChange('image', { name: files[0].name, url: files[0] });
      //change focus on title
      const qTitleEleId = 'q-mediatitle-' + keyS + '-' + (keyQ + 1);
      setFocusEle(qTitleEleId);

      //call upload
      //let formData = new FormData();
      //formData.append('file', files[0]);
      // myAxios.post(apis.uploadImage, formData).then((res) => {
      //     setFocusEle('');
      //     setIsLoading(false);
      //     if (res.data.success) {
      //         //update image state
      //         const newImage = { ...imageQ };
      //         newImage.name = res.data.data.name;
      //         newImage.url = apis.getImage + '?path=' + res.data.data.path + res.data.data.name;
      //         //set local state
      //         setImageQ(newImage);

      //         //set in write component
      //         onImageQChange(keyQ, newImage);
      //     }
      // })
      // .catch(function (error) {
      //     //// console.log(error);
      // });
    }
  }

  //question value change
  const handleQValueChange = (keyName: string, keyValue: any) => {
    const newQValue = { ...questionValue };
    newQValue[keyName] = keyValue;
    setQuestionValue(newQValue);
    //callback
    onChange && onChange(keyS, keyQ, newQValue);
  };

  //render placehoder for question
  const renderPlaceholder = () => {
    switch (questionValue?.type) {
      case Q_TITLE:
        return t('Untitled title');
      case Q_IMAGE:
        return t('Untitled image');
      case Q_VIDEO:
        return t('Untitled video');
    }
  };

  const qTitleEleId = 'q-mediatitle-' + keyS + '-' + (keyQ + 1);
  const qDescEleId = 'q-mediadesc-' + keyS + '-' + (keyQ + 1);
  const qVideoEleId = 'q-mediavideo-' + keyS + '-' + (keyQ + 1);

  const qImageEleId = 'q-mediaimage-' + keyS + '-' + (keyQ + 1);
  const qCopyEleId = 'q-mediacopy-' + keyS + '-' + (keyQ + 1);
  const qRemoveEleId = 'q-mediaremove-' + keyS + '-' + (keyQ + 1);

  const dragQuestionId = `question-${keyS}-${keyQ}`;
  return (
    <Draggable key={dragQuestionId} draggableId={dragQuestionId} index={keyQ}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className="pos-relative pd-t-5"
          onClick={() => {
            onFocusQuestionChange(keyS, keyQ + 1);
          }}
        >
          <div className="survey-write-question-card-root-container">
            <div className="survey-write-question-card-root">
              <div className="card cursor-move">
                {/* blue vertical line */}
                {focusS === keyS && focusQ === keyQ + 1 && (
                  <div className="survey-write-tab-content-cursor-root survey-write-tab-content-cursor-root-full"></div>
                )}
                {/* title+desc input */}
                <div className="card-body">
                  {/* <QNextPrevMove
                  keyS={keyS}
                  keyQ={keyQ}
                  focusEle={focusEle}
                  onQMoveNext={onQMoveNext}
                  onQMovePrev={onQMovePrev}
                  onQMoveNextSection={onQMoveNextSection}
                  onQMovePrevSection={onQMovePrevSection}
                /> */}
                  {/* title */}
                  <div className="survey-write-question-card-title-wrapper pd-b-10">
                    <div className="survey-write-question-card-title-row">
                      <div className="survey-write-question-card-title-input">
                        {/* title */}
                        <div className="survey-write-question-card-title-name">
                          <input
                            autoComplete="off"
                            type="text"
                            id={qTitleEleId}
                            className="form-control survey-write-input"
                            placeholder={renderPlaceholder()}
                            value={questionValue?.title}
                            onChange={(e: any) => handleQValueChange('title', e.target.value)}
                            autoFocus={qTitleEleId === focusEle}
                          />
                        </div>
                        {/* copy, delete */}
                        {focusS === keyS && focusQ === keyQ + 1 && (
                          <div className="survey-write-question-card-title-image">
                            {/* for image question */}
                            {questionValue?.type === Q_IMAGE && (
                              <a
                                id={qImageEleId}
                                className="survey-home-header-button mg-r-10 cursor-default"
                                onClick={openUpload}
                              >
                                <Image />
                              </a>
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              //id='file'
                              name="file"
                              ref={uploadImgRef}
                              style={{ display: 'none' }}
                              onChange={handleImageQChange} //TODO
                            />
                            <a
                              id={qCopyEleId}
                              className="survey-home-header-button mg-r-10 cursor-default"
                              onClick={() => onCopyQuestion(keyS, keyQ)}
                            >
                              <Copy />
                            </a>
                            <a
                              id={qRemoveEleId}
                              className="survey-home-header-button cursor-default"
                              onClick={() => onRemoveQuestion(keyS, keyQ)}
                            >
                              <Trash />
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* content */}
                  {questionValue?.type === Q_TITLE && (
                    <div className="pd-b-10">
                      <textarea
                        id={qDescEleId}
                        ref={descRef}
                        rows={2}
                        className="form-control survey-write-input"
                        placeholder={t('Untitled description')}
                        value={questionValue?.description || ''}
                        onChange={(e: any) => handleQValueChange('description', e.target.value)}
                        autoFocus={qDescEleId === focusEle}
                      />
                    </div>
                  )}

                  {questionValue?.type === Q_IMAGE && questionValue?.image?.url instanceof File && (
                    <div className="survey-write-question-card-image pd-b-10">
                      <div className="survey-write-question-card-image-pos">
                        {/* custom image menu */}
                        {focusS === keyS && focusQ === keyQ + 1 && (
                          <div className="survey-write-question-card-image-menu">
                            <div className="dropdown survey-home-grid-item-overflow">
                              <a
                                className="btn survey-home-btn-more cursor-default"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {Icons('more_vertical')}
                              </a>
                              <div className="dropdown-menu">
                                <a
                                  className="dropdown-item cursor-default"
                                  onClick={() => handleQValueChange('image', { name: '', url: '' })}
                                >
                                  <Trash /> <SpanLang keyLang="Remove" />
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                        <img
                          src={URL.createObjectURL(questionValue.image.url)}
                          className="survey-write-question-card-image-view"
                          alt="Q_image"
                          style={{ width: '680px' }}
                        />
                      </div>
                    </div>
                  )}

                  {questionValue?.type === Q_VIDEO && (
                    <div className="pd-b-10">
                      <input
                        autoComplete="off"
                        type="text"
                        id={qVideoEleId}
                        className="form-control survey-write-input"
                        placeholder={t('Youtube link')}
                        value={questionValue?.video || ''}
                        onChange={(e: any) => handleQValueChange('video', e.target.value)}
                        autoFocus={qVideoEleId === focusEle}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isLoading && <Loading />}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default QuestionMedia;
