import { Select } from '@base/components/form';
import withLoading from '@base/hooks/hocs/withLoading';
import useMutationPost from '@base/hooks/useMutationPost';
import { formatSettingsAtom } from '@base/recoil/atoms';
import { timeSettingSelector } from '@base/recoil/selectors';
import { BaseResponse } from '@base/types/interfaces/response';
import { UPDATE_FORMAT_SETTING } from '@settings/general-setting/services/graphql/format';
import { ITimeSetting } from '@settings/general-setting/types/interfaces/format';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

interface IProps {
  setLoading: (params: any) => void;
  data: ITimeSetting | undefined;
}

const timeFormats = [
  { value: 'h:mm tt', label: 'h:mm tt' },
  { value: 'hh:mm tt', label: 'hh:mm tt' },
  { value: 'H:mm', label: 'H:mm' },
  { value: 'HH:mm', label: 'HH:mm' },
];
const timeSeparators = [{ value: ':', label: ':' }];
const getRegex = () => {
  const separators = timeSeparators.reduce((oldV, value) => oldV + value.value, '');
  return new RegExp(`[${separators}]`);
};
const regexReplace = getRegex();
const TimeSetting: React.FC<IProps> = (props: IProps) => {
  const { setLoading } = props;
  const data: ITimeSetting = useRecoilValue(timeSettingSelector);
  const [formatSettings, setFormatSettings] = useRecoilState(formatSettingsAtom);
  let timeValue: ITimeSetting = data
    ? data
    : {
        timeFormat: 'hh:mm tt',
        timeSeperator: ';',
      };

  const { timeFormat, timeSeperator } = timeValue;
  const timeFormatValue = useMemo(() => ({ value: timeFormat, label: timeFormat }), [timeFormat]);
  const timeSeparatorValue = useMemo(
    () => ({ value: timeSeperator, label: timeSeperator }),
    [timeSeperator],
  );

  const formatPreview = useMemo(() => {
    let formatString = timeFormat.replace('tt', 'a');
    formatString = dayjs(new Date()).format(formatString);
    formatString = formatString.replace(regexReplace, timeSeperator);
    return formatString;
  }, [timeFormat, timeSeperator]);

  const mUpdateFormat: any = useMutationPost<BaseResponse<string>>(
    UPDATE_FORMAT_SETTING,
    'setting_updateFormatSetting',
    {},
  );
  const updateFormatSetting = (value: any) => {
    // save to server
    timeValue = value;
    mUpdateFormat.mutate(
      { key: 'time', value: JSON.stringify(value) },
      {
        onSuccess: (res: any) => {
          toast.success('Setting saved!');
          setLoading(false);
          // update format settings
          const newSettings = formatSettings.map((item: any) => {
            if (item.key == 'time') {
              // item.value = JSON.stringify(timeValue);
              return {
                ...item,
                value: JSON.stringify(value),
              };
            }
            return item;
          });
          setFormatSettings(newSettings);
        },
        onError: (res: any) => {
          toast.error('Saving has failed!');
          setLoading(false);
        },
      },
    );
  };
  const onChangeTimeFormat = (timeFormat: any) => {
    setLoading(true);
    const value = {
      ...timeValue,
      timeFormat: timeFormat.value,
    };
    updateFormatSetting(value);
  };
  const onChangeTimeSeparator = (timeSeparator: any) => {
    setLoading(true);
    const value = {
      ...timeValue,
      timeSeperator: timeSeparator.value,
    };
    updateFormatSetting(value);
  };
  return (
    <div className="card">
      <div className="card-header h6 bg-light">Time</div>
      <div className="card-body">
        <div className="form-row">
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">Time Format</label>
            <Select
              className="wd-150-f"
              value={timeFormatValue}
              options={timeFormats}
              onChange={onChangeTimeFormat}
            />
          </div>
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">Time Seperator</label>
            <Select
              className="wd-150-f"
              value={timeSeparatorValue}
              options={timeSeparators}
              onChange={onChangeTimeSeparator}
            />
          </div>
        </div>
        <div className="alert bd">
          What the notations mean:
          <br />
          h = hour m = minute t = am or pm
          <br />
          h/H = 12/24 hour
          <br />
          hh, mm =&quot;display leading zero&quot;
          <br />
          h, m = &quot;do not display leading zero&quot;
        </div>
        <div className="form-group">
          <label className="form-item-title">Preview</label>
          <div className="pd-15 bg-light bd bd-dashed rounded">{formatPreview}</div>
        </div>
      </div>
    </div>
  );
};

export default withLoading(TimeSetting);
