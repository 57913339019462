import { ViewFields } from '@base/components/hook-form/view';
import { ViewFieldParse } from '@base/utils/helpers/no-layout-utils';
import { useEffect, useState } from 'react';
import { Stage } from '@settings/process/types/diagram';
import StageViewField from '@settings/process/config/view-field/stage';
import React from 'react';

interface StageViewProps {
  processId: string;
  stage: Stage;
}

function StageView({ processId, stage }: StageViewProps) {
  // console.log('stage View');
  const [fields, setFields] = useState<any[]>([]);

  useEffect(() => {
    if (stage) {
      const fields = ViewFieldParse(stage, StageViewField, true, ['process_diagram']);
      setFields(fields);
    }
  }, [stage]);

  const ignoreFields: string[] = [];

  return (
    <div className="form-row mg-0">
      <ViewFields
        fields={fields}
        ignoreFields={ignoreFields}
        menuSource={'processStage'}
        menuSourceId={processId}
      />
    </div>
  );
}

export default StageView;
