import { RadioGroup } from '@base/components/form';
import { PAYMENT_OPTION } from '@settings/billing-license/config/constant';
import React, { useEffect, useState } from 'react';

interface IProps {
  value: any;
  onChange: (keyName: string, keyValue: string) => any;
}

const BillingInfoPayment: React.FC<IProps> = (props: IProps) => {
  const { value, onChange } = props;
  const paymentOption = PAYMENT_OPTION;
  const [selectedPayment, setSelectedPayment] = useState(value.paymentMethod);
  useEffect(() => {
    if (value.paymentMethod != selectedPayment) onChange('paymentMethod', selectedPayment);
  }, [selectedPayment]);
  return (
    <div className="card">
      <div className="card-header h6 bg-light">Payment Method</div>
      <div className="card-body">
        <RadioGroup
          options={paymentOption}
          value={selectedPayment}
          isVertical={true}
          onChange={(data: any) => setSelectedPayment(data)}
        ></RadioGroup>
      </div>
    </div>
  );
};
export default BillingInfoPayment;
