import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  PROCESS_STATUS_DIRECTIONS_SORT,
  PROCESS_STATUS_DIRECTIONS_VIEW,
  PROCESS_STATUS_EVENTS_VIEW,
  PROCESS_STATUS_PROPERTIES_VIEW,
  PROCESS_STATUS_VIEWS_VIEW,
} from '@settings/process/config/constants';
import { checkParallel } from '@settings/process/utils/helper';
import ActionItemEdit from './ActionItemEdit';
import ActionItemView from './ActionItemView';
import ActionStatusList from './ActionStatusList';
import { BusinessStatus, StatusForm } from '@settings/process/types/process';
import { useRecoilState } from 'recoil';
import statusAtom from '@settings/process/recoil/status';
import { initStatusForm } from '@settings/process/components/diagram/status/StatusWrite';

interface ActionStatusProps {
  statusData?: BusinessStatus[];
  fixed?: boolean;
}

function ActionStatus(props: ActionStatusProps) {
  const { statusData, fixed } = props;
  const [statusesValue, setStatusesValue] = useRecoilState(statusAtom);

  useEffect(() => {
    let statusForms: StatusForm[] = [];
    if (statusData) {
      statusForms = statusData?.map((status) => {
        return {
          id: status.definedId,
          name: status.name,
          button: status.button,
          view: { keyName: status.view, languageKey: PROCESS_STATUS_VIEWS_VIEW[status.view] },
          event: { keyName: status.event, languageKey: PROCESS_STATUS_EVENTS_VIEW[status.event] },
          property: {
            keyName: status.property,
            languageKey: PROCESS_STATUS_PROPERTIES_VIEW[status.property],
          },
          direction: {
            keyName: status.direction,
            languageKey: PROCESS_STATUS_DIRECTIONS_VIEW[status.direction],
          },
          nextStep: { keyName: '', languageKey: '' },
          sequence: status.sequence ?? [],
          new: false,
          reset: true,
          order: PROCESS_STATUS_DIRECTIONS_SORT[status.direction],
          multiple: status.multiple,
          primary: status.primary,
        };
      });
    } else {
      statusForms = initStatusForm.slice(0, 2);
    }
    setStatusesValue(statusForms);
  }, [statusData]);

  let parallelIndex = 0;

  return (
    <div className="form-group col-12">
      <label className="form-item-title">Buttons/Status</label>
      <ActionStatusList fixed={fixed}>
        {statusesValue?.map((status) => {
          if (checkParallel(status)) {
            parallelIndex++;
          }
          return (
            <React.Fragment key={status.id}>
              {fixed ? (
                <ActionItemView status={status} parallelIndex={parallelIndex} />
              ) : (
                <ActionItemEdit status={status} parallelIndex={parallelIndex} />
              )}
            </React.Fragment>
          );
        })}
      </ActionStatusList>
    </div>
  );
}

export default ActionStatus;
