import { SpanLang } from '@base/components';
import React from 'react';

const LOCATIONS = [
  {
    id: 'isRequired',
    label: 'crm_new_pagelayout_required',
    shortLabel: '*',
    backgroundColor: '#f4f5f8',
    color: '#dc3545',
  },
  {
    id: 'showInList',
    label: 'crm_new_pagelayout_list',
    shortLabel: 'L',
    backgroundColor: '#00b8d4',
    color: 'white',
  },
  {
    id: 'showInView',
    label: 'crm_new_pagelayout_view',
    shortLabel: 'V',
    backgroundColor: '#ffc107',
    color: 'white',
  },
  {
    id: 'showInWrite',
    label: 'crm_new_pagelayout_write',
    shortLabel: 'W',
    backgroundColor: '#10b759',
    color: 'white',
  },
  // {
  //   id: 'isEmployee',
  //   label: 'crm_new_common_employee',
  //   shortLabel: 'E',
  //   backgroundColor: '#7987a1',
  //   color: 'white',
  // },
];
interface IShowInProps {
  isLabel?: boolean;
  locationsDisplay?: any;
}
const ShowIn: React.FC<IShowInProps> = (props: IShowInProps) => {
  const {
    isLabel = false,
    locationsDisplay = {
      showInList: false,
      showInView: false,
      showInWrite: false,
      isEdit: true,
      isRequired: true,
    },
  } = props;
  return (
    <div className="page-layout-show-in">
      {LOCATIONS.map((item, index) =>
        locationsDisplay[item.id] ? (
          <div className="container-show mg-x-2" key={index}>
            <div
              className="view-show"
              style={{
                backgroundColor: item.backgroundColor,
                color: item.color,
              }}
            >
              {item.shortLabel}
            </div>
            {isLabel && <SpanLang keyLang={item.label} />}
            {isLabel && <div className="line-between" />}
          </div>
        ) : null,
      )}
    </div>
  );
};

export default ShowIn;
