import { SpanLang } from '@base/components';
import { Input } from '@base/components/form';
import { AR_CRITERIA_OPTIONS_LANG } from '@settings/assignment-rule/config/constant';
import React, { useState } from 'react';

interface ICriteriaProps {
  value: any;
  onChange: (nVal: any) => void;
}
const Criteria: React.FC<ICriteriaProps> = (props: ICriteriaProps) => {
  const { value, onChange } = props;
  const defaultItems: any[] = [
    {
      key: 'product',
      condition: '',
    },
    {
      key: 'region',
      condition: '',
    },
    {
      key: 'language',
      condition: '',
    },
    {
      key: 'category',
      condition: '',
    },
    {
      key: 'tag',
      condition: '',
    },
  ];
  const [items, setItems] = useState<any[]>(value.length ? value : defaultItems);
  const onChangeItem = (key: string, nVal: string) => {
    let nItems = items.map((item: any) => {
      if (item.key === key) {
        return {
          ...item,
          condition: nVal,
        };
      }
      return item;
    });
    setItems(nItems);
    onChange && onChange(nItems);
  };
  return (
    <>
      <ul className="list-group">
        {items.length
          ? items.map((item, index) => {
              return (
                <li
                  key={index}
                  className="list-group-item d-flex align-items-center justify-content-between pd-x-10 bg-white"
                >
                  <SpanLang keyLang={AR_CRITERIA_OPTIONS_LANG[item.key]} />
                  <Input
                    className="wd-250"
                    value={item.condition}
                    onChange={(nVal: string) => onChangeItem(item.key, nVal)}
                  />
                </li>
              );
            })
          : ''}
      </ul>
    </>
  );
};

export default Criteria;
