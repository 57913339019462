import { Checkbox } from '@base/components/form';
import _ from 'lodash';
import { Automation } from '@settings/process/types/process';
import { parseDurationValueToString } from '@base/utils/helpers/date-utils';

interface AutomationViewProps {
  value: Automation;
}

function AutomationView({ value }: AutomationViewProps) {
  return (
    <div className="form-group">
      <label className="form-item-title">Automation</label>
      <Checkbox checked={value?.useSleeping} label={'Sleeping'} disabled={true} />
      {value?.useSleeping && (
        <div className="mg-t-15 mg-l-25">
          <div className="d-flex flex-wrap align-items-center">
            <span className="mg-r-10">If this Step is sleeping for</span>
            {parseDurationValueToString({
              duration: value.sleeping?.duration?.time,
              durationUnit: value.sleeping?.duration?.unit,
            })}
          </div>
          {value.sleeping?.executes && (
            <div className="card mg-y-10">
              {value.sleeping.executes.map((execute, index) => {
                return (
                  <div key={index} className="card-body">
                    <label className="form-item-title">
                      Execute Action: {execute.status?.name}
                    </label>
                    <div className="d-flex align-items-center mg-b-5">
                      <Checkbox
                        checked={execute.useNotify}
                        className="mg-r-15"
                        label={'Notification'}
                        disabled={true}
                      />
                      {execute.notify.name}
                    </div>
                    <div className="d-flex align-items-center">
                      <Checkbox
                        checked={execute.useChangeStep}
                        className="mg-r-15"
                        label={'Change Step'}
                        disabled={true}
                      />
                      {execute.changeStep.name}
                    </div>
                    <div className="d-flex align-items-center">
                      <Checkbox
                        className="mg-r-15 mg-t-5"
                        label={'My work listing for assigned user/group'}
                        checked={execute.useMywork}
                        disabled={true}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AutomationView;
