import _ from 'lodash';
import { Action } from '@settings/process/types/process';
import { parseDurationValueToString } from '@base/utils/helpers/date-utils';
import { Checkbox } from '@base/components/form';
import { MethodView } from '@settings/process/config/view-field/components';
import { useStepSettingAtomValue } from '@settings/process/recoil/process';

interface ActionViewProps {
  value: Action;
}

function ActionView({ value }: ActionViewProps) {
  const stepSetting = useStepSettingAtomValue();

  return (
    <>
      {value && (
        <div className="form-row">
          {stepSetting.template && (
            <div className="form-group col-6">
              <label className="form-item-title">Template</label>
              <div>{value.template?.name}</div>
            </div>
          )}
          {stepSetting.method && (
            <div className="form-group col-12">
              <MethodView value={value.method} />
            </div>
          )}
          {stepSetting.email && (
            <div className="form-group col-6">
              <Checkbox
                checked={value.sendEmail}
                className="mg-r-15"
                label={'Send by email'}
                disabled={true}
              />
            </div>
          )}
          {stepSetting.due && (
            <div className="form-group col-6">
              <label className="form-item-title">Duration</label>
              <div>
                {parseDurationValueToString({
                  duration: value.duration?.time,
                  durationUnit: value.duration?.unit,
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ActionView;
