import { Button } from '@base/components/form';
import { moneyFormat } from '@base/utils/helpers';
import { useListLicense } from '@settings/billing-license/services';
import { ILicense } from '@settings/billing-license/types/interfaces/license';
import React, { useEffect, useState } from 'react';
import ReduceView from '../actions-views/reduce-view';
import TerminationView from '../actions-views/terminate-view';

const LicenseUsed: React.FC = () => {
  const { data, refetch, isLoading } = useListLicense('');
  const [dataTable, setDataTable] = useState<ILicense[]>([]);
  const [modalOpen, setModalOpen] = useState<string>('');
  const [selectedLicense, setSelectedLicense] = useState({ id: '' });
  useEffect(() => {
    if (data && data.data) {
      setDataTable(data.data);
    }
  }, [data]);

  return (
    <>
      <div className="card">
        <div className="card-header d-flex align-items-center bg-light">
          <h2 className="card-title h6 mg-b-0">Licenses Used</h2>
          <Button
            outline={false}
            color="danger"
            className="mg-l-auto"
            data-toggle="modal"
            data-target="#terminationConfirm"
          >
            Terminate all
          </Button>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col">Product</th>
                <th scope="col">Item</th>
                <th scope="col">Description</th>
                <th scope="col">Quantity</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Unit</th>
                <th scope="col">Amount</th>
                <th scope="col">Billing</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {dataTable?.map((_itemProduct: any, indexProduct: number) => {
                return _itemProduct.listItem.map((_item: any, index: number) => {
                  return (
                    <tr key={index}>
                      {index == 0 ? (
                        <td rowSpan={_itemProduct.listItem.length} className="align-middle">
                          {_itemProduct.name}
                        </td>
                      ) : (
                        <></>
                      )}
                      <td>{_item.name}</td>
                      <td>{_item.description}</td>
                      <td>{_item.quantity}</td>
                      <td>{_item.unitPrice != 'Free' ? moneyFormat(_item.unitPrice) : 'Free'}</td>
                      <td>{_item.unit}</td>
                      <td>{_item.amount}</td>
                      <td>{_item.billing}</td>
                      <td>
                        <Button
                          outline={true}
                          color="danger"
                          className="btn-sm"
                          data-toggle="modal"
                          data-target={_item.action == 'Terminate' ? '#terminationConfirm' : ''}
                          onClick={() => {
                            // console.log(_item.action);
                            setModalOpen(_item.action);
                            setSelectedLicense(_item);
                          }}
                        >
                          {_item.action}
                        </Button>
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ReduceView
        value={selectedLicense}
        isVisible={modalOpen == 'Reduce'}
        onClose={() => setModalOpen('')}
        onSubmit={(data: number) => {
          const newVal: ILicense[] = [];
          for (let item of dataTable) {
            for (let listItem of item.listItem) {
              if (listItem.id == selectedLicense.id) {
                if (listItem.quantity && data) {
                  listItem.quantity -= data;
                }
              }
            }
            newVal.push(item);
          }
          setDataTable(newVal);
          setModalOpen('');
        }}
      />
      <TerminationView
        isVisible={modalOpen == 'Terminate'}
        onClose={() => setModalOpen('')}
        onSubmit={() => console.log('submit')}
      />
    </>
  );
};
export default LicenseUsed;
