import { MENU_SETTING_ASSIGNMENT_RULE } from '@base/config/menus';
import { lazy } from 'react';

/** MAIN PAGE CONFIG */
export const MAIN_MENU = MENU_SETTING_ASSIGNMENT_RULE;
export const LIST_ROUTE = 'list';
export const VIEW_ROUTE = 'list/:id/*';
export const DEFAULT_ROUTE = '/settings/assignment-rule/list';
export const DEFAULT_CATEGORY = 'rule';
export const PRIMARY_KEY = 'id';
export const NAME_KEY = 'name';
export const MENU_TITLE = 'Assignment Rule';
export const CREATE_MENU_TITLE = 'Create Assignment Rule';
export const VIEW_MENU_TITLE = 'Assignment Rule';
export const CREATE_RULE_ENTRY_TITLE = 'Enter the rule entry';

export const MainPage = lazy(() => import('@settings/assignment-rule/pages/index'));
export const ListPage = lazy(() => import('@settings/assignment-rule/pages/list'));
export const ViewPage = lazy(() => import('@settings/assignment-rule/pages/view'));
// export const WritePage = lazy(() => import('@settings/assignment-rule/pages/write'));
