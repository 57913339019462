import { gql } from 'graphql-request';

export const SETTING_CTA_CREATE = gql`
  mutation q($cta: cta) {
    setting_createCta(cta: $cta) {
      id
    }
  }
`;

export const SETTING_CTA_LIST = gql`
  query q($filter: SearchFilter) {
    setting_ctas(filter: $filter) {
      results {
        id
        type
        imgUrl
        imgSize {
          width
          height
        }
        imgAlt
        txtValue
        txtBgColor
        txtColor
        txtFontSize
        txtFontWeight
        txtRounded
        name
        language
        linkType
        externalSiteName
        linkUrl
        openPageInNewWindow
        contentType
        resource {
          id
          name
        }
        pageTitle
        description
        stage
      }
      paging {
        totalItems
        totalPage
        currentPage
      }
    }
  }
`;

export const SETTING_CTA_DELETE = gql`
  mutation q($ids: [String!]) {
    setting_deleteCta(ids: $ids) {
      ids
    }
  }
`;

export const SETTING_CTA_QR_CODE_GET = gql`
  query q($id: String!) {
    setting_cta(id: $id) {
      id
      type
      qrCode
      linkType
      externalSiteName
      linkUrl
    }
  }
`;

export const COMMON_QR_CODE_CREATE = gql`
  mutation m(
    $content: String!
    $width: Number!
    $margin: Number!
    $scale: Number!
    $darkColor: String!
    $lightColor: String!
  ) {
    common_generateQRCode(
      content: $content
      width: $width
      margin: $margin
      scale: $scale
      darkColor: $darkColor
      lightColor: $lightColor
    ) {
      image
    }
  }
`;
