import React, { useEffect, useMemo, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { useGetView } from '@base/services/view';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import Title from '@base/containers/view-field/title';
import { SpanLang } from '@base/components/form';
import { MENU_SETTING_CTA, MENU_TEMPLATE_EMAIL } from '@base/config/menus';
import Loading from '@base/components/loading';

import displayLayoutConfig from '@settings/template/config/display-layout';
import ViewLeft from '@settings/template/layouts/desktop/layout1/view/view-left';
import { ctaListFilterState } from '@settings/online-digital-content/cta/recoil/atom/list-atom';
import { default as viewConfigEmail } from '@settings/template/config/view-field/email/index';
import { default as viewConfigSMS } from '@settings/template/config/view-field/sms/index';
import { ctaWriteOptionAtom } from '@settings/online-digital-content/cta/recoil/atom/write-atom'; //for write

import { KEY_MENU_TEMPLATE_NAME } from '@settings/template/config/key-names';

//import { getTabs } from './tabs';
import { SETTING_TEMPLATE_MENUS } from '@base/config/route-menus';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { DELETE_MENU_TEMPLATES } from '@settings/template/services/graphql';
import { toast } from 'react-toastify';
// import { useQueryClient } from 'react-query';
import { useQueryClient } from '@tanstack/react-query'; //v4
import NotFound from '@base/components/errors/notFound';
import ViewCenter from '@settings/template/layouts/desktop/layout1/view/view-center';
import { tplEmailDataState } from '@settings/template/recoil/atom/view-atom';
import { parseFields } from './utils';
import { DEFAULT_CATEGORY, DEFAULT_GROUP, WritePage } from '../../config/pages/main-page';
import WriteFormPage from '../write-form';

interface IViewProps {
  isSplitMode?: boolean;
}

/**
 * Pages: detect layout, device, init props
 */
const ViewPage: React.FC<IViewProps> = (props: IViewProps) => {
  const { isSplitMode } = props;

  // recoil
  const listFilter = useRecoilValue(ctaListFilterState);
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_TEMPLATE_EMAIL));

  // params + state
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { activeId, activeTab } = listFilter;
  let id = activeId != params.id ? params.id ?? '' : activeId ?? '';
  const aParams = params['*'] ? params['*'].split('/') : [];
  const templateType = aParams[0] ? aParams[0] : DEFAULT_CATEGORY;
  const templateGroup = aParams[1] ? aParams[1] : DEFAULT_GROUP;
  //for write form
  const [writeOption, setWriteOption] = useRecoilState(ctaWriteOptionAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page

  /** Hook + Get data */
  let { data: layoutView, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    MENU_SETTING_CTA,
    'view',
  );
  layoutView = parseFields(layoutView, templateGroup);

  // console.log('>>>> layoutView', layoutView);
  // const viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  // let { isLoading, data: tplData, refetch } = useGetView<any>('setting_cta', viewSchema, { id });
  let viewConfig = viewConfigEmail;
  let menuSource = MENU_TEMPLATE_EMAIL;
  if (templateGroup == 'sms') {
    viewConfig = viewConfigSMS;
    menuSource = MENU_TEMPLATE_EMAIL;
  }
  const viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  let {
    isLoading,
    data: tplData,
    refetch,
  } = useGetView<any>({ menu: 'setting_menuTemplateNew', schemas: viewSchema, params: { id } });

  //dummy data
  //tplData = useRecoilValue(tplEmailDataState);
  // get detail data
  useEffect(() => {
    //if (!isLoading && !isLayoutLoading && campaignData) {
    const layoutData = mergeLayoutData(layoutView, tplData, viewConfig);
    let newLayoutData: any = { ...layoutData };
    newLayoutData.menuSourceId = id;
    newLayoutData.menuSource = menuSource;
    setViewData(newLayoutData);
    //}
  }, [tplData, isLoading, isLayoutLoading]);

  // delete mutation
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_MENU_TEMPLATES,
    'setting_deleteMenuTemplates',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Deleted Template successfully!');
        setTimeout(() => {
          queryClient.invalidateQueries(['setting_menuTemplates']);
        }, 500);
        return navigate(`/settings/template/menus/` + templateGroup);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        toast.error('Deleted Template failed: ' + JSON.parse(error).message);
      },
    },
  );

  // render
  const renderView = () => {
    const keyTitle = `crm_new_${writeOption.writeType}`;
    //props config
    let pageProps: IDestopLayout1ViewProps = {
      headerProps: {
        menu: templateGroup, //key
        menus: SETTING_TEMPLATE_MENUS,
        isDeleting: false,
        onDelete: () => mutationDelete.mutate({ ids: [id] }),
        onNew: (category: string | undefined) => {
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: templateGroup,
            writeMenu: 'view',
          }));
        },
        splitViewMode: isSplitMode,
        componentLeft: (
          <>
            <Title
              keyName={KEY_MENU_TEMPLATE_NAME}
              value={tplData?.[KEY_MENU_TEMPLATE_NAME]}
              userPermission={{ isEdit: true }}
              onSave={(params: any) => {}}
              menuSourceId={id}
              menuSource={menuSource}
              viewConfig={viewConfig}
            />
          </>
        ),
      },
      /*centerProps: {
        showTop: false, //send to all tab's component
        menuSource: MENU_SETTING_CTA, //send to all tab's component
        menuSourceId: id, //send to all tab's component
        tabs: getTabs(),
        currentTab: currentTab,
      },*/
      componentTop: null,
      componentLeft: <ViewLeft id={id} />,
      leftProps: {},
      componentCenter: <ViewCenter menuSourceId={id} menuSource={'template_email'} />,
      componentRight: null,
      rightProps: {},
    };

    return (
      <>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="view"
          {...pageProps}
        />
        {/* hook form - write new product write */}
        {writeOption.isOpenWrite &&
          ['email'].includes(templateGroup) &&
          writeOption.writeMenu === 'view' && (
            <WritePage
              title={<SpanLang keyLang={keyTitle} />}
              size="lg"
              fullScreen={fullScreen}
              setFullScreen={setFullScreen}
              isOpen={writeOption.isOpenWrite}
              onClose={() =>
                setWriteOption((filter) => ({
                  ...filter,
                  isOpenWrite: false,
                }))
              }
              onReload={refetch}
              menuApi={MENU_TEMPLATE_EMAIL}
              templateType={templateType}
              templateGroup={templateGroup}
            />
          )}
        {writeOption.isOpenWrite &&
          ['knowledgebase', 'task', 'call', 'sms'].includes(templateGroup) && (
            <WriteFormPage
              title={<SpanLang keyLang={keyTitle} />}
              onClose={() => {
                setWriteOption((filter: any) => ({
                  ...filter,
                  isOpenWrite: false,
                }));
                refetch();
              }}
              templateType={templateType}
              templateGroup={templateGroup}
            />
          )}
      </>
    );
  };

  // render
  return <>{isLoading || isLayoutLoading ? <Loading /> : tplData ? renderView() : <NotFound />}</>;
};

export default ViewPage;
