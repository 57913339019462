import React from 'react';
import TextView from '@base/containers/view-field/text/view';

interface MethodViewProps {
  value: string;
}

function MethodView({ value }: MethodViewProps) {
  return <TextView value={value} />;
}

export default MethodView;
