import React, { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';

import { ListBody, ListContainer } from '@base/components/list';
import { deviceAtom } from '@base/recoil/atoms';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import displayLayoutConfig from '@settings/process/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import ListHeader from '@settings/process/components/list-header/ListHeader';
import { BusinessListContainer } from '@settings/process/containers/list';
import { ModalContainer } from '@base/containers/aside-modal';
import BusinessWrite from '@settings/process/containers/write/BusinessWrite';
import { BusinessProcess } from '@settings/process/types/process';
import usePosts from '@base/hooks/usePosts';
import { GET_BUSINESS_PROCESS } from '@settings/process/services/process';
import Loading from '@base/components/loading';
import { OptionValue } from '@base/types/interfaces/common';

interface BusinessListPageProps {}

function BusinessListPage(props: BusinessListPageProps) {
  // console.log('BusinessListPage');
  const [{ isDesktop, isMobile, isTablet, device, layout = 'layout1' }] =
    useRecoilState(deviceAtom);
  const [opening, setOpening] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data, isLoading, isPreviousData } = usePosts<BusinessProcess[]>(
    ['process_processes', pageNumber],
    GET_BUSINESS_PROCESS,
    {
      filter: {
        sort: {
          field: 'createdAt',
          orderBy: 'DESC',
        },
        paging: {
          page: pageNumber,
          size: pageSize,
        },
      },
    },
    {
      keepPreviousData: true,
    },
  );

  const pagingProps = {
    totalPage: data?.paging?.totalPage || 1,
    totalItems: data?.paging?.totalItems || 0,
    currentPage: data?.paging?.currentPage || 1,
    itemPerPage: data?.paging?.itemPerPage || 10,
    nextPage: data?.paging?.nextPage || null,
    previousPage: data?.paging?.previousPage || null,
    isPreviousData,
    onChange: (page: number, size: number) => {
      if (pageSize !== size) {
        setPageSize(size);
      }
      setPageNumber(page);
    },
  };

  const fields: OptionValue[] = [
    {
      keyName: 'module',
      languageKey: 'Module',
    },
    {
      keyName: 'name',
      languageKey: 'Name',
    },
    {
      keyName: 'step',
      languageKey: 'Step',
    },
    {
      keyName: 'ruleCriteria',
      languageKey: 'Rule Criteria',
    },
    {
      keyName: 'product',
      languageKey: 'Product',
    },
    {
      keyName: 'createdAt',
      languageKey: 'Created At',
    },
    {
      keyName: 'mode',
      languageKey: 'Mode',
    },
  ];

  const columnRenderRemap = {
    name(col: string, data: any) {
      const name = data[col] ? data[col] : '';
      const id = data.id ? data.id : '';
      return <Link to={`/settings/process/business/${id}`}>{name}</Link>;
    },
    createdAt(col: string, data: any) {
      const createdAt = data[col] ? data[col] : '';
      return convertDateTimeServerToClient({ date: createdAt, isTime: false });
    },
    module(col: string, data: any) {
      const module = data[col] ? data[col] : null;
      return module;
    },
    step(col: string, data: any) {
      const step = data[col] ? data[col] : null;
      return step;
    },
    ruleCriteria(col: string, data: any) {
      const ruleCriteria = data[col] ? data[col] : null;
      return ruleCriteria;
    },
    product(col: string, data: any) {
      const product = data[col] ? data[col] : null;
      return product;
    },
    mode(col: string, data: any) {
      const mode = data[col] ? data[col] : null;
      return mode;
    },
  };

  const handleAdd = useCallback(() => {
    setOpening(true);
  }, []);

  const onClose = useCallback(() => {
    setOpening(false);
  }, []);

  const pageProps = {
    toolbar: '',
    header: <ListHeader handleAdd={handleAdd} title="Business Process" />,
    body: (
      <ListBody>
        <BusinessListContainer
          data={data?.data ?? []}
          fields={fields}
          columnRenderRemap={columnRenderRemap}
          pagingProps={pagingProps}
        />
      </ListBody>
    ),
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <ListContainer isDesktop={isDesktop}>
          <BaseLayoutDisplay
            displayConfig={displayLayoutConfig}
            device={device}
            layout={layout}
            form="list"
            {...pageProps}
          />
        </ListContainer>
      )}

      {opening && (
        <ModalContainer
          isOpen={opening}
          onClose={onClose}
          isFooter={false}
          title={'Business Process'}
          writeComponent={<BusinessWrite onClose={onClose} />}
        />
      )}
    </>
  );
}

export default BusinessListPage;
