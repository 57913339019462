import { ListHeader } from '@base/components/list';
import { ListType } from '@base/types/enums';
import React from 'react';
import { useRecoilState } from 'recoil';

interface IPageHeader {
  // columns: any[];
}
const PageHeader: React.FC<IPageHeader> = (props: IPageHeader) => {
  // const { refetch } = props;
  //render
  return <></>;
};

export default PageHeader;
