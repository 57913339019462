import { EPermissionType } from '../types/enums';

export const CONFIG = {
  items: 'ITEMS',
  items2: 'ITEMS_2',
  sections: 'SECTIONS',
  addSection: 'ADD_SECTION',
  container: 'CONTAINER',
  unusedItems: 'UNUSED_ITEMS',
  itemsTemplate: 'ITEM_TEMPLATE',
};

export const PREMISSION_CONFIG = {
  [EPermissionType.BASIC_FIXED]: {
    color: '#dc3545',
    label: 'Basic fixed',
  },
  [EPermissionType.CUSTOM]: {
    color: '#28a745',
    label: 'Custom',
  },
  [EPermissionType.BASIC]: {
    color: '#007bff',
    label: 'Basic',
  },
  [EPermissionType.SYSTEM]: {
    color: '#596882',
    label: 'System',
  },
};
