import { X } from 'react-feather';
import { Link } from 'react-router-dom';
import { ActionProperty } from '@settings/process/types/settings';

export const SUBTABS: ActionProperty[] = [
  {
    value: 'action',
    label: 'Action',
    component: 'writeAction',
    type: 'TYPE_ACTION',
    iconType: 'icon',
    icon: 'diagram_action',
  },
  // {
  //   value: 'wait',
  //   label: 'Wait',
  //   component: 'writeWait',
  //   type: 'TYPE_WAIT',
  //   iconType: 'icon',
  //   icon: 'diagram_wait',
  // },
  {
    value: 'trigger',
    label: 'Trigger',
    component: 'writeTrigger',
    type: 'TYPE_TRIGGER',
    iconType: 'feather',
    icon: 'Command',
  },
  {
    value: 'criteria',
    label: 'Criteria',
    component: 'writeCriteria',
    type: 'TYPE_CRITERIA',
    iconType: 'icon',
    icon: 'diagram_criteria',
  },
  {
    value: 'attribute',
    label: 'Attribute',
    component: 'writeAttribute',
    type: 'TYPE_ATTRIBUTE',
    iconType: 'feather',
    icon: 'Tool',
  },
];

export const TABPROPS = (
  editFn: (data: any) => void,
  deleteFn: (id: string) => void,
): { [index: string]: any } => {
  return {
    action: {
      fields: [
        {
          keyName: 'name',
          languageKey: 'Name',
        },
        {
          keyName: 'ma',
          languageKey: 'M/A',
        },
        {
          keyName: 'description',
          languageKey: 'Description',
        },
        {
          keyName: 'createdAt',
          languageKey: 'Created on',
        },
        {
          keyName: 'mode',
          languageKey: 'Mode',
        },
      ],
      columnRenderRemap: {
        name(col: string, data: any) {
          const field = data[col] ? data[col] : '';
          return (
            <button type="button" className="btn btn-link" onClick={() => editFn(data)}>
              {field}
            </button>
          );
        },
        ma(col: string, data: any) {
          if (data.setting) {
            return data.setting.method;
          }
          return '';
        },
        description(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        createdAt(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        mode(col: string, data: any) {
          if (!data.fixed) {
            return (
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon"
                onClick={() => deleteFn(data.id)}
              >
                <X />
              </button>
            );
          }
          return '';
        },
      },
    },
    wait: {
      fields: [
        {
          keyName: 'name',
          languageKey: 'Name',
        },
        {
          keyName: 'type',
          languageKey: 'Type',
        },
        {
          keyName: 'trigger',
          languageKey: 'Trigger',
        },
        {
          keyName: 'dateTime',
          languageKey: 'Date time',
        },
        {
          keyName: 'duration',
          languageKey: 'Duration',
        },
        {
          keyName: 'otherProcess',
          languageKey: 'Other Process',
        },
        {
          keyName: 'action',
          languageKey: 'Action',
        },
        {
          keyName: 'mode',
          languageKey: 'Mode',
        },
      ],
      columnRenderRemap: {
        name(col: string, data: any) {
          const field = data[col] ? data[col] : '';
          const id = data.id ? data.id : '';
          return <Link to={`/settings/process/wait/${id}`}>{field}</Link>;
        },
        mode(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        type(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        trigger(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        dateTime(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        duration(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        otherProcess(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        action(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
      },
    },
    trigger: {
      fields: [
        {
          keyName: 'name',
          languageKey: 'Name',
        },
        {
          keyName: 'trigger',
          languageKey: 'Type',
        },
        {
          keyName: 'description',
          languageKey: 'Description',
        },
        {
          keyName: 'createdAt',
          languageKey: 'Created on',
        },
        {
          keyName: 'mode',
          languageKey: 'Mode',
        },
      ],
      columnRenderRemap: {
        name(col: string, data: any) {
          const field = data[col] ? data[col] : '';
          return (
            <button type="button" className="btn btn-link" onClick={() => editFn(data)}>
              {field}
            </button>
          );
        },
        trigger(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          // console.log('field', field);
          return field.trigger;
        },
        description(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        createdAt(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        mode(col: string, data: any) {
          return (
            <button
              type="button"
              className="btn btn-xs btn-link link-03 btn-icon"
              onClick={() => deleteFn(data.id)}
            >
              <X />
            </button>
          );
        },
      },
    },
    criteria: {
      fields: [
        {
          keyName: 'name',
          languageKey: 'Name',
        },
        {
          keyName: 'type',
          languageKey: 'Type',
        },
        {
          keyName: 'description',
          languageKey: 'Description',
        },
        // {
        //   keyName: 'automation',
        //   languageKey: 'Automation Rule Related',
        // },
        {
          keyName: 'createdAt',
          languageKey: 'Created on',
        },
        {
          keyName: 'mode',
          languageKey: 'Mode',
        },
      ],
      columnRenderRemap: {
        name(col: string, data: any) {
          const field = data[col] ? data[col] : '';
          return (
            <button type="button" className="btn btn-link" onClick={() => editFn(data)}>
              {field}
            </button>
          );
        },
        type(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        description(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        // automation(col: string, data: any) {
        //   const field = data[col] ? data[col] : null;
        //   return field;
        // },
        createdAt(col: string, data: any) {
          const field = data[col] ? data[col] : null;
          return field;
        },
        mode(col: string, data: any) {
          return (
            <button
              type="button"
              className="btn btn-xs btn-link link-03 btn-icon"
              onClick={() => deleteFn(data.id)}
            >
              <X />
            </button>
          );
        },
      },
    },
    attribute: {
      fields: [
        {
          keyName: 'name',
          languageKey: 'Name',
        },
      ],
      columnRenderRemap: {
        name(col: string, data: any) {
          const field = data[col] ? data[col] : '';
          const id = data.id ? data.id : '';
          return <Link to={`/settings/process/attribute/${id}`}>{field}</Link>;
        },
      },
    },
  };
};
