import React, { useState, useEffect } from 'react';
import { Image, PlusCircle, Type, Video, Server } from 'react-feather';
import {
  Q_TITLE,
  Q_IMAGE,
  Q_VIDEO,
} from '@settings/online-digital-content/survey/config/constants';
import { useTranslation } from 'react-i18next';

//Survey title class
const SurveyToolbar: React.FC<any> = (props) => {
  const {
    focusS,
    focusQ,
    onAddNewOptionRow
  } = props;
  const { t } = useTranslation();

  const addNormalQEleId = 'q-addnormal-' + focusS + '-' + focusQ;

  return (
    <div className="survey-write-content-toolbar-container" id="survey-toolbar">
      <div className="survey-write-content-toolbar">
        {/* first item */}
        <div
          className="survey-write-content-toolbar-item pd-y-8 han-tooltip han-tooltip--left"
          id={addNormalQEleId}
          data-han-tooltip={t('Add question')}
        >
          <a className="survey-write-content-toolbar-item-button" onClick={onAddNewOptionRow}>
            <PlusCircle />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SurveyToolbar;
