import React, { useEffect, useState } from 'react';
import { ChevronUp } from 'react-feather';
import { Portal, Canvas } from '@base/components';
import { Button } from '@base/components/form';

/**
 *
 * @param {*} props
 * @returns
 */
const Write: React.FC<any> = (props: any) => {
  const { isShow, onClose, defaultValue, onAdd, onAddValue } = props;

  //state
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  // validate before save
  const onValid = () => {
    let valid = value.length == 0 ? false : true;
    setIsValid(valid);
    return valid;
  };

  useEffect(() => {
    onValid();
  }, [value, title]);

  // save new
  const onSave = (isApply = false) => {
    if (!onValid()) return;
  };

  // add and apply
  const handleAddApply = () => {
    onSave(true);
  };

  //add and create new
  const handleAddCreate = () => {
    onSave(false);
  };

  // save buttons
  const renderSaveButton = () => {
    return (
      <div className="btn-group dropup mg-l-5">
        <Button color="success" loading={isSaving} disabled={!isValid}>
          Add
        </Button>
        <Button
          color="success"
          className="dropdown-toggle-split"
          aria-label="toggle dropdown"
          data-toggle="dropdown"
        >
          <ChevronUp />
        </Button>
        <div className="dropdown-menu">
          <Button className="dropdown-item" onClick={handleAddApply} disabled={!isValid}>
            Add and apply
          </Button>
          <Button className="dropdown-item" onClick={handleAddCreate} disabled={!isValid}>
            Add and create new
          </Button>
        </div>
      </div>
    );
  };

  // render
  return (
    <Portal>
      <Canvas
        isVisible={isShow}
        onCloseSideBar={onClose}
        className={'page-sidebar-container-wrap'}
        customStyles={{ zIndex: '1052' }}
        backdropStyles={{ zIndex: '1051' }}
      >
        <Canvas.Header title={'New Type'} onClose={onClose} />
        <Canvas.Body>
          <div className="pos-relative pd-10 wd-600">
            <div className="form-group">
              <label className="form-item-title">
                Value<span color="red">*</span>
              </label>
              <input
                type="text"
                className="form-control ht-38"
                // placeholder="e.g Color"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Title</label>
              <input
                type="text"
                className="form-control ht-38"
                // placeholder="e.g Red,Blue,"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {!isValid && <p className="tx-danger">* Value are required.</p>}
          </div>
        </Canvas.Body>
        <Canvas.Footer closeLabel="Close" onClose={onClose} saveBtn={renderSaveButton()} />
      </Canvas>
    </Portal>
  );
};

export default Write;
