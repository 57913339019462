import React from 'react';
import { Button, Input, Select } from '@base/components/form';
import { ChevronLeft, ChevronRight, Search } from 'react-feather';

const AllTickets: React.FC<any> = (props: any) => {
  const { txtValue, bgColor, textColor, bdRounded, fontSize, fontWeight } = props;

  return (
    <>
      <div className="tab-content bg-white bd bd-gray-200 bd-t-0">
        <div className="d-flex flex-wrap pd-5 bd-b">
          <Button color="" icon="ChevronDown" isIconsEnd name="All Tickets" />
          <Button color="" icon="ChevronDown" isIconsEnd name="All" />
          <Button color="" icon="ChevronDown" isIconsEnd name="Filters" />
          <div className="d-flex mg-l-auto">
            <Input rightIcon={<Search />} className="wd-300" />
            <Button color="white" icon="RefreshCw" className="btn-icon mg-l-5" />
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered dataTable mg-b-0 bd-0">
            <thead>
              <tr>
                <th scope="col" className="sorting tx-nowrap">
                  Ticket No
                </th>
                <th scope="col" className="sorting tx-nowrap">
                  Subject
                </th>
                <th scope="col" className="sorting tx-nowrap">
                  Status
                </th>
                <th scope="col" className="sorting tx-nowrap">
                  Assigned Rep
                </th>
                <th scope="col" className="sorting tx-nowrap">
                  Created on
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0000001</td>
                <td>
                  <Button color="link" name="Desk Template 01" className="pd-0" />
                </td>
                <td>
                  <span className="badge badge-info tx-normal tx-13">New</span>
                </td>
                <td>Assigned Rep</td>
                <td>2022-08-10 13:22:46</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pd-y-6-f pd-r-20 pagination align-items-center justify-content-end bd-t">
          <button
            type="button"
            className="btn btn-link link-02 btn-icon"
            aria-label="move to first"
          >
            {/* {Icon('move_first')} */}
            <span className="sr-only">처음으로</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="previous">
            <ChevronLeft />
            <span className="sr-only">이전</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="next">
            <ChevronRight />
            <span className="sr-only">다음</span>
          </button>
          <button type="button" className="btn btn-link link-02 btn-icon" aria-label="move to last">
            {/* {Icon('move_last')} */}
            <span className="sr-only">마지막으로</span>
          </button>
          <input type="number" className="paging-input" defaultValue="1" />/ 1
          <Select
            width={150}
            defaultValue={{ value: 5, label: '5 per page' }}
            className="mg-l-10"
          />
        </div>
      </div>
    </>
  );
};

export default AllTickets;
