import { MENU_PROCESS_BUSINESS } from '@base/config/menus';
import AsideWrite from '@base/containers/aside-modal/write';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { WriteParseFields } from '@base/utils/helpers/no-layout-utils';
import { useBusinessProcessAtomValue } from '@settings/process/recoil/process';
import { CREATE_PROCESS } from '@settings/process/services/process';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

// import { useQueryClient } from 'react-query';
interface BusinessWriteProps {
  onClose: () => void;
}

function BusinessWrite(props: BusinessWriteProps) {
  // console.log('business process write');
  const { onClose } = props;
  const business = useBusinessProcessAtomValue();
  const { fields, getParams, defaultValues } = WriteParseFields(MENU_PROCESS_BUSINESS, business);
  const queryClient = useQueryClient();

  //state
  const [isSaving, setIsSaving] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isNew, setIsNew] = useState(false);

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_PROCESS,
    'process_createProcess',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Create Process successfully!');

        setTimeout(() => {
          queryClient.invalidateQueries(['process_processes']);
        }, 500);
        setIsSaving(false);
        setIsReset(true);

        if (!isNew) {
          onClose && onClose();
        }
      },
      onError: (error: any) => {
        setIsSaving(false);
        // console.log('failed', error);
        toast.warn('Create Process failed');
      },
    },
  );

  const onSubmit = (formData: any, isNew: boolean) => {
    setIsNew(isNew);
    const newParams = getParams(formData);
    // console.log('configParams => ', newParams);
    mutationAdd.mutate({ process: newParams });
  };

  return (
    <AsideWrite
      onSubmit={onSubmit}
      isSaving={isSaving}
      isReset={isReset}
      defaultValues={defaultValues}
      fields={fields}
    />
  );
}

export default BusinessWrite;
