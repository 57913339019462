import usePost from '@base/hooks/usePost';
import { BaseResponse } from '@base/types/interfaces/response';
import { graphQLApi, graphQLGetsApi } from '@base/utils/axios/graphql';
import { ISelection } from '../types/interfaces/selection';
import {
  GET_ALL_SELECTION_FIELDS,
  GET_ALL_SELECTION_GROUPS,
  GET_SELECTION_FIELD_ITEMS,
  GET_SELECTION_GROUP_ITEMS,
} from './graphql/selection';

export const useListSelectionFields = () => {
  let queryKey = ['setting_selectionFields'];

  const response = usePost<BaseResponse<ISelection>>(queryKey, GET_ALL_SELECTION_FIELDS, {});
  return response;
};
export const useListSelectionFieldItems = (keyName: string) => {
  let queryKey = ['setting_selectionFieldItems', keyName];

  const response = usePost<BaseResponse<ISelection>>(
    queryKey,
    GET_SELECTION_FIELD_ITEMS,
    {
      keyName,
    },
    {
      enabled: keyName != '',
    },
  );
  return response;
};

export const useListSelectionGroups = () => {
  let queryKey = ['setting_selectionGroups'];

  const response = usePost<BaseResponse<ISelection>>(queryKey, GET_ALL_SELECTION_GROUPS, {});
  return response;
};
export const useListSelectionGroupItems = (parentId: string) => {
  let queryKey = ['setting_selectionGroupData', parentId];

  const response = usePost<BaseResponse<ISelection>>(
    queryKey,
    GET_SELECTION_GROUP_ITEMS,
    {
      parentId,
    },
    {
      enabled: parentId != '',
    },
  );
  return response;
};

export const loadSelectionGroupItemsAPI = (parentId: string) => {
  let variables = {
    parentId: parentId,
  };
  let key = 'setting_selectionGroupData';
  return graphQLApi<BaseResponse<ISelection>>(key, GET_SELECTION_GROUP_ITEMS, variables);
};
