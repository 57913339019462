import React from 'react';
import classnames from 'classnames';
import LandingPageAutoComplete from '@settings/online-digital-content/landing-page/containers/landing-page-auto-complete';
import { useRecoilValue } from 'recoil';
import { MENU_SETTING_CTA } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import * as keyNames from '@settings/online-digital-content/cta/config/key-names';
import {
  SETTING_CTA_CONTENT_TYPE_LANDING_PAGE,
  SETTING_CTA_CONTENT_TYPE_SURVEY,
} from '@settings/online-digital-content/cta/config/constants';
import SurveyAutoComplete from '@settings/online-digital-content/survey/containers/survey-auto-complete';

const Edit: React.FC = (props: any) => {
  const { value, errors, componentProps, onChange } = props;

  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_SETTING_CTA));

  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
  const contentType = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_SETTING_CTA_CONTENT_TYPE,
  );

  // console.log('...resourceEdit...', value, viewData);

  return (
    <div>
      <div className={classnames({ 'is-required': errors.isRequired })}>
        {contentType?.data?.value === SETTING_CTA_CONTENT_TYPE_LANDING_PAGE && (
          <LandingPageAutoComplete {...componentProps} value={value} onChange={onChange} />
        )}
        {contentType?.data?.value === SETTING_CTA_CONTENT_TYPE_SURVEY && (
          <SurveyAutoComplete {...componentProps} value={value} onChange={onChange} />
        )}
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </div>
  );
};

export default Edit;
