import React, { lazy, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { useLanguageByMenu } from '@base/services/i18n'; //getLanguageByMenu
import { MENU_SETTING_GENERAL_SETTING } from '@base/config/menus';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayout from '@settings/billing-license/config/display-layout';
import { ISideMenuItem } from '@base/types/interfaces/app';
import { SUB_MENUS } from '@settings/billing-license/config/sub_menus';
import '@settings/billing-license/assets/scss/style.scss';

const getPreviousUrl = (params: any, pageData: any): string => {
  let prePath = '';
  // prePath empty in case params same with recoil data
  if (
    params.activeMenu == pageData.activeMenu &&
    params.activeId == pageData.activeId &&
    params.activeTab == pageData.activeTab
  ) {
    return prePath;
  }
  if (params.activeMenu == '' && pageData.activeMenu && pageData.activeMenu != '') {
    prePath = `/settings/billing-license/${pageData.activeMenu}`;
  }
  if (
    prePath != '' &&
    params.activeId == '' &&
    pageData.activeMenu &&
    pageData.activeMenu != '' &&
    pageData.activeId &&
    pageData.activeId != ''
  ) {
    prePath = `${prePath}/${pageData.activeId}`;
  }

  return prePath;
};

const MainPage: React.FC = () => {
  const params = useParams();
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_SETTING_GENERAL_SETTING));
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const navigate = useNavigate();
  const aParams = params['*'] ? params['*'].split('/') : [];
  const activeMenu = aParams[0] ? aParams[0] : '';
  const activeId = aParams[1] ? aParams[1] : '';
  const activeTab = aParams[2] ? aParams[2] : '';
  const prePath = getPreviousUrl({ activeMenu, activeId, activeTab }, pageData);
  // console.log('MainPage', pageData);
  // load menu language
  // useLanguageByMenu(['generalsetting']);
  const pageProps = {
    children: <div>child component</div>,
    menus: SUB_MENUS,
    activeMenu: 'settings/billing-license',
    pageTitle: 'Bill & License',
    loading: false,
    match: {
      params: {
        menu: activeMenu,
        id: activeId,
      },
    },
  };
  // console.log('pageData', pageData);
  // console.log('settings page', pageProps);
  useEffect(() => {
    let hasUpdate = false;
    if (activeMenu != '' && pageData.activeMenu != activeMenu) {
      hasUpdate = true;
    }
    if (pageData.activeId != activeId) {
      hasUpdate = true;
    }
    if (hasUpdate) {
      // set default filter
      let newData = {
        ...pageData,
        activeMenu: activeMenu,
        activeId: activeId,
      };
      setPageData(newData);
    }
    if (prePath != '') {
      // console.log('pageData prePath', prePath);
      navigate(prePath);
    }
  }, [prePath]);

  return (
    <>
      <BaseLayoutDisplay
        displayConfig={displayLayout}
        device={device}
        layout={layout}
        form="setting"
        {...pageProps}
      />
    </>
  );
};

export default MainPage;
