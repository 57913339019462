import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { NoData, SpanLang } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { SETTING_SELECTION_FIELD_UPDATE, UPDATE_MENU_SETTING } from '@settings/general-setting/services/graphql/format';
import { useSetRecoilState } from 'recoil';
import { savingPreferencesAtom } from '@settings/preferences/recoil/atoms';
import { useTranslation } from 'react-i18next';

const ActivityCallResult: React.FC<any> = (props) => {
  const { resultData } = props;
  const { t } = useTranslation();
  //state
  const setSavingOption = useSetRecoilState(savingPreferencesAtom);
  const [resultItems, setResultItems] = useState<any>([]);
  const [debResultItems, setDebResultItems] = useState<any>([]);
  const setResultItemsDebounced = useRef(
    _.debounce((newItems) => setDebResultItems(newItems), 1000),
  ).current;
  //const defaultResultValue: any = { languageKey: '', priority: '', isDefault: false, active: false };
  const initialResultLoad = useRef<any>(false);

  //initial data
  useEffect(() => {
    if (resultData) {
      if (JSON.stringify(resultData) !== JSON.stringify(resultItems)) {
        setResultItems(resultData);
      }
    } else {
      setResultItems([]);
    }
  }, [resultData]);

  //mutation
  const mUpdate: any = useMutationPost(SETTING_SELECTION_FIELD_UPDATE, 'setting_updateSelection');

  //set saving option
  useEffect(() => {
    setSavingOption((curOption: any) => ({ ...curOption, isLoading: mUpdate.isLoading }));
  }, [mUpdate.isLoading]);

  //debounced saving for input text
  useEffect(() => {
    if (initialResultLoad?.current) {
      handleSaveDebounce(debResultItems);
    } else {
      initialResultLoad.current = true;
    }
    () => {
      initialResultLoad.current = false;
    };
  }, [debResultItems]);

  //save item
  const handleSave = (newItems: any) => {
    const newDefaultItem = newItems.find((_ele: any) => _ele.isDefault === true);
    const params: any = {
      id: newDefaultItem.id,
      isDefault: true,
      keyGroup: newDefaultItem.keyGroup
    };
    mUpdate.mutate({ selection: params });
  };
  //debounce function
  const handleSaveDebounce = useCallback(_.debounce(handleSave, 500), [resultItems]);

  //value change
  const handleValueChange = (index: number, keyName: string, keyValue: string) => {
    const newItems = [...resultItems];
    newItems[index][keyName] = keyValue;
    if (keyName === 'isDefault') {
      //reset other to false
      newItems.map((_item: any, _index: number) => {
        if (index !== _index) {
          _item.isDefault = false;
        }
      });
    }
    setResultItems(newItems);
    setResultItemsDebounced(newItems); //for saving...
  };

  //list
  const CallResultsRender = useMemo(() => {
    return (
      <>
        {resultItems.length === 0 && <NoData />}
        {resultItems.length > 0 && (
          <div className="table-responsive bd rounded">
            <table className="table table-bordered mg-b-0 bd-0 hover-delete">
              <thead>
                <tr>
                  <th scope="col" className="text-nowrap">
                    Call Result
                  </th>
                  <th scope="col" className="text-nowrap">
                    Default
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultItems.map((_item: any, index: number) => (
                  <tr key={index}>
                    <td className="align-middle">
                      <SpanLang keyLang={_item.languageKey} />
                    </td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center">
                        <div className="custom-control custom-radio mg-l-15">
                          <input
                            type="radio"
                            id={'resultRadio' + index}
                            name="activityCallResultRadio"
                            className="custom-control-input"
                            checked={_item.isDefault}
                            onChange={(e: any) =>
                              handleValueChange(index, 'isDefault', e.target.checked)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={'resultRadio' + index}
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }, [resultItems]);

  return (
    <div className="pos-relative pd-10">
      {CallResultsRender}
    </div>
  );
};

export default ActivityCallResult;
