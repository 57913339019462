import { lazy } from 'react';
export const AssignTo = lazy(
  () => import('@settings/assignment-rule/containers/view-field/assign-to'),
);
/*export const RuleEntry = lazy(
  () => import('@settings/assignment-rule/containers/view-field/rule-entry'),
);*/
export const RuleEntry = lazy(() => import('@settings/assignment-rule/containers/rule-entry'));
export const Criteria = lazy(
  () => import('@settings/assignment-rule/containers/view-field/criteria'),
);
export const Switch = lazy(() => import('@base/containers/view-field/switch'));
