import UsageTable from '@settings/billing-license/components/usage-table';
import { useListSMSUsage } from '@settings/billing-license/services';
import { IUsageHistory } from '@settings/billing-license/types/interfaces/usage-history';
import React, { useEffect, useMemo, useState } from 'react';
interface IDataTable {
  date: Date;
  userName: string;
  subjectName: string;
  amount: number;
}
interface IUsageHistoryTable {}
const HistoryUsageTables: React.FC<IUsageHistoryTable> = (props: IUsageHistoryTable) => {
  // DATA FOR USAGE_HISTORY_TABLES
  const { data, isLoading, refetch } = useListSMSUsage();
  const [dataUsage, setDataUsage] = useState<IUsageHistory[]>();
  // get list data
  // data here include 5 (less or more) tables
  useEffect(() => {
    if (data?.data) {
      setDataUsage(data.data);
    }
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (row: IDataTable) => {
          return <>{row.date.toLocaleDateString()}</>;
        },
      },
      {
        Header: 'User',
        accessor: (row: IDataTable) => {
          return <>{row.userName}</>;
        },
      },
      {
        Header: 'Subject',
        accessor: (row: IDataTable) => {
          return <>{row.subjectName}</>;
        },
      },
      {
        Header: 'Amount',
        accessor: (row: IDataTable) => {
          return <>{row.amount}</>;
        },
      },
    ],
    [],
  );

  return (
    <>
      {dataUsage?.map((_item: IUsageHistory, index: number) => {
        return <UsageTable columns={columns} title={_item.name} data={_item.data} key={index} />;
      })}
    </>
  );
};
export default HistoryUsageTables;
