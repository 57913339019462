import CommonViewField from '@base/containers/view-field/common';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_STEP_STATUS } from '@settings/process/services/process';
import View from './ChecklistView';
import Write from './ChecklistWrite';
import { useRecoilValue } from 'recoil';
import statusAtom from '@settings/process/recoil/status';

function ChecklistViewField(props: any) {
  const statusesValue = useRecoilValue(statusAtom);
  const queryClient = useQueryClient();
  const mutationEdit: any = useMutationPost<BaseMutationResponse>(
    UPDATE_STEP_STATUS,
    'process_updateStatus',
    {
      onSuccess: (data: any) => {
        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', props.metadata?.processId]);
          queryClient.invalidateQueries(['process_step', props.metadata?.stepId]);
        }, 500);
        // console.log('success data', data);
        toast.success('Update checklist successfully!');
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Update checklist failed');
      },
    },
  );

  const onSubmitHandler = () => {
    const status = statusesValue.find((status) => status.property.keyName == 'PROPERTY_TODO_CLOSE');
    if (status) {
      mutationEdit.mutate({
        id: props.metadata?.processId,
        stepId: props.metadata?.stepId,
        status: {
          id: status.id,
          options: JSON.stringify(status.checklist),
        },
      });
    }
  };

  return (
    <CommonViewField
      {...props}
      componentView={View}
      componentEdit={Write}
      value={props.value}
      onSubmitHandler={onSubmitHandler}
    />
  );
}

export default ChecklistViewField;
