import Input from '@base/components/form/input';
import Select from '@base/components/form/select';
import SpanLang from '@base/components/form/span-lang';
import { DurationValue } from '@base/types/interfaces/common';
import {
  parseDurationValueToSecond,
  parseDurationValueToString,
} from '@base/utils/helpers/date-utils';
import { DURATION_OPTIONS } from '@settings/preferences/config/constant';
import { isString } from 'lodash';
import React, { useEffect, useState } from 'react';

interface DurationProps {
  value: DurationValue;
  onChange: (params: any) => void;
}
const Duration: React.FC<DurationProps> = (props: DurationProps) => {
  const { value, onChange } = props;

  let defaultUnit = DURATION_OPTIONS.find((_item) => _item.value === 'UNIT_HOUR');
  const [durationUnit, setDurationUnit] = useState(defaultUnit);
  const [duration, setDuration] = useState<number>(0);
  const onHandleChangeInput = (duration: any) => {
    let nDuration = 0;
    if (duration > 0) {
      nDuration = parseDurationValueToSecond({
        duration: duration,
        durationUnit: durationUnit ? durationUnit.value : 'UNIT_HOUR',
      });
    }
    let newUnit = durationUnit ? durationUnit.value : 'UNIT_HOUR';
    let newValue = {
      durationUnit: newUnit,
      duration: nDuration,
    };
    onChange(newValue);
    setDuration(duration);
  };
  const onHandleChangeSelect = (durationUnit: any) => {
    let newUnit = durationUnit ? durationUnit.value : 'UNIT_HOUR';
    const nDuration = isString(duration) ? parseInt(duration, 10) : duration;
    let newValue = {
      durationUnit: durationUnit?.value,
      duration: parseDurationValueToSecond({
        duration: nDuration * 1,
        durationUnit: newUnit,
      }),
    };
    onChange(newValue);
    setDurationUnit(durationUnit);
  };
  useEffect(() => {
    if (value) {
      let newDurationUnit = DURATION_OPTIONS.find((_item) => _item.value === value.durationUnit);
      let newDuration = value.duration;
      if (value.duration > 0) {
        newDuration = +parseDurationValueToString(value, false);
      }
      setDuration(newDuration);

      setDurationUnit(newDurationUnit);
    }
  }, [value]);
  return (
    <div className="d-flex align-items-center">
      <Input
        type={'number'}
        className="wd-100 mg-r-5"
        value={duration > 0 ? duration : ''}
        onBlur={onHandleChangeInput}
      />
      <div className="wd-150-f mg-r-5">
        <Select
          outSide={true}
          isSearchable={false}
          getOptionLabel={(option: any) => <SpanLang keyLang={option.label} />}
          options={DURATION_OPTIONS}
          value={durationUnit}
          onChange={onHandleChangeSelect}
        />
      </div>
    </div>
  );
};

export default Duration;
