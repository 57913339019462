import React, { FC, useState } from 'react';

import { isFunction, isUndefined } from 'lodash';

import ListGridItemBase, {
  defaultColumnRender,
  ListGridItemBody,
} from '@base/components/list/list-grid/list-grid-item';
import { DEFAULT_CATEGORY, MAIN_MENU, PRIMARY_KEY } from '@settings/site/config/pages/main-page';
import { ListPageConfig } from '@settings/site/config/pages/list-page';
import GridItemFooter from '../list-grid-footer';
import ListGridItemHeader from './header';
import { PREVENT_DELETE_SITES } from '@settings/site/config/constants';

export interface IListGridItem {
  primaryKey: string | number;
  data: any;
  isSplitMode: boolean;
  onCheckedRow: (checkedValue: string | number) => void;
  selectedIds: string[];
  columns: {
    name: string;
    title: string;
  }[];
  columnsOnSplitMode: {
    [key: string]: {
      icon?: React.ReactNode;
      isTag: boolean;
    };
  };
  columnRender?: (col: string) => any;
  category: string;
  siteGroup: string;
  onDelete: (id: string) => void;
  onAfterSaved: (nTitle: string) => void;
}

const ListGridItem: FC<IListGridItem> = ({
  data,
  isSplitMode,
  onCheckedRow,
  selectedIds,
  columns,
  columnRender,
  category,
  onDelete,
  onEdit,
  onAfterSaved,
}: any) => {
  const title = ListPageConfig.getTitle(data, isSplitMode);
  const src = data?.thumbnail ?? '';
  const [gTitle, setGTitle] = useState(title);
  // // console.log('Title', title, src);
  const canDelete = PREVENT_DELETE_SITES.indexOf(category) != -1 ? false : true;
  return (
    <ListGridItemBase
      primaryKey={ListPageConfig.primaryKey}
      data={data}
      isSplitMode={isSplitMode}
      onCheckedRow={onCheckedRow}
      selectedIds={selectedIds}
      photoRender={{
        src: src,
        alt: title ?? 'No title',
      }}
      listItemHeaderComponent={
        <ListGridItemHeader
          id={data.id}
          title={gTitle}
          titleLink={`/settings/${MAIN_MENU}/${category}/${data.id}`}
          onAfterSaved={(nVal: string) => {
            setGTitle(nVal);
          }}
          isDefault={data.isDefault}
        />
      }
    >
      <ListGridItemBody isSplitMode={isSplitMode}>
        <>
          {columns &&
            columns.map((col: any, i: number) => {
              const columnComponent =
                isFunction(columnRender) && columnRender(col.name)
                  ? columnRender(col.name)(col.name, data)
                  : defaultColumnRender(col.name, data);

              return (
                <div className="grid-list-item-col" key={i}>
                  <span className="grid-list-item-col-title">{col.title}:</span>
                  {col?.dataType && col.dataType === 'text' ? (
                    <span className="grid-list-item-col-content">{columnComponent}</span>
                  ) : (
                    columnComponent
                  )}
                </div>
              );
            })}
        </>
      </ListGridItemBody>
      <GridItemFooter onDelete={onDelete} onEdit={onEdit} row={data} canDelete={canDelete} />
    </ListGridItemBase>
  );
};

ListGridItem.defaultProps = {
  primaryKey: PRIMARY_KEY,
};

export default React.memo(ListGridItem);
