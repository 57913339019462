import React, { useState } from 'react';
import ProductGroupTree from '@settings/preferences/containers/product-group-tree';
import ProductAttributes from '@settings/preferences/containers/product-attributes';
import SKUGeneratorForm from '@product/item/containers/sku-generator-form';
import ProductGeneral from '@settings/preferences/containers/product-general';
import { useRecoilState } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@settings/preferences/config/display-layout';

const getTabs = () => {
  return (
    [
      {
        default: true,
        label: 'General',
        path: 'general',
        order: 1,
        tabComponent: ProductGeneral,
        tabComponentProps: {
        },
        show: true,
      },
      {
        default: false,
        label: 'Product Group',
        path: 'product-group',
        order: 2,
        tabComponent: ProductGroupTree,
        tabComponentProps: {
          treeId: 'product-group',
        },
        show: true,
      },
      {
        default: false,
        label: 'Attributes',
        path: 'attributes',
        order: 3,
        tabComponent: ProductAttributes,
        tabComponentProps: {
        },
        show: true,
      },
      {
        default: false,
        label: 'SKU Generator',
        path: 'sku',
        order: 4,
        tabComponent: SKUGeneratorForm,
        tabComponentProps: {
          className: "pd-10"
        },
        show: true,
      },
    ]
  );
}

const ProductSettings: React.FC<any> = () => {
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);

  const viewProps = {
    tabs: getTabs()
  };

  return (
    <>
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={device}
        layout={layout}
        form="view"
        {...viewProps}
      />
    </>
  );
};

export default ProductSettings;
