import React from 'react';
import {
  Q_MULTI_CHOICES,
  Q_CHECKBOXES,
  Q_DROPDOWN,
  Q_SHORT_ANSWER,
  Q_PARAGRAPH,
  Q_FILE_UPLOAD,
  Q_DATE,
  Q_TIME,
  Q_MULTI_CHOICES_GRID,
  Q_TICK_BOX_GRID,
} from '@settings/online-digital-content/survey/config/constants';
import { Circle, Square } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { SpanLang } from '@base/components';
import { DatePicker } from '@base/components/date-picker';

//render question multiple choices
const QuestionStaticView: React.FC<any> = (props) => {
  const { keyQ, selectedQType, title, image, required, options } = props;
  const { t } = useTranslation();

  //local state

  //render title
  function renderTitle() {
    return (
      <div className="survey-write-question-card-title-wrapper">
        <div className="survey-write-question-card-title-row">
          <div className="survey-write-question-card-title-input">
            {/* title */}
            <div className="">
              <span className="survey-write-question-card-title-name">
                {title ? title : t('Untitled Question')}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //render question image
  function renderQImage() {
    return (
      image &&
      image.url && (
        <div className="survey-write-question-card-image">
          <div className="survey-write-question-card-image-pos">
            <img
              src={image.url}
              className="survey-write-question-card-image-view"
              alt="Q_image"
              style={{ width: '680px' }}
            />
          </div>
        </div>
      )
    );
  }

  //render options
  function renderOption() {
    //render based on question type
    switch (selectedQType) {
      case Q_MULTI_CHOICES:
        return options.map((opt: any, idx: number) => {
          return (
            <div key={idx} className="survey-write-question-option-container">
              <div className="survey-write-question-card-list-item">
                <div className="d-flex flex-row">
                  <div className="survey-write-question-item-symbol mg-r-10">
                    <Circle />
                  </div>
                  {opt.isOther ? (
                    <SpanLang keyLang="Other" />
                  ) : (
                    <span>{opt.value ? opt.value : t('Untitled Option')}</span>
                  )}
                </div>
              </div>
              {opt.image && opt.image.url && (
                <div className="survey-write-question-card-image">
                  <div className="survey-write-question-card-image-pos">
                    <img
                      src={opt.image.url}
                      className="survey-write-question-card-image-view"
                      alt="opt_image"
                      style={{ width: '620px' }}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        });
      case Q_CHECKBOXES:
        return options.map((opt: any, idx: number) => {
          return (
            <div key={idx} className="survey-write-question-option-container">
              <div className="survey-write-question-card-list-item">
                <div className="d-flex flex-row">
                  <div className="survey-write-question-item-symbol mg-r-10">
                    <Square />
                  </div>
                  {opt.isOther ? (
                    <SpanLang keyLang="Other" />
                  ) : (
                    <span>{opt.value ? opt.value : t('Untitled Option')}</span>
                  )}
                </div>
              </div>
              {opt.image && opt.image.url && (
                <div className="survey-write-question-card-image">
                  <div className="survey-write-question-card-image-pos">
                    <img
                      src={opt.image.url}
                      className="survey-write-question-card-image-view"
                      alt="opt_image"
                      style={{ width: '620px' }}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        });
      case Q_DROPDOWN:
        return options.map((opt: any, idx: number) => {
          return (
            <div key={idx} className="survey-write-question-option-container">
              <div className="survey-write-question-card-list-item">
                <div className="d-flex flex-row">
                  <span className="mg-r-10">{idx + 1 + '.'}</span>
                  {opt.isOther ? (
                    <SpanLang keyLang="Other" />
                  ) : (
                    <span>{opt.value ? opt.value : t('Untitled Option')}</span>
                  )}
                </div>
              </div>
              {opt.image && opt.image.url && (
                <div className="survey-write-question-card-image">
                  <div className="survey-write-question-card-image-pos">
                    <img
                      src={opt.image.url}
                      className="survey-write-question-card-image-view"
                      alt="opt_image"
                      style={{ width: '620px' }}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        });
      case Q_SHORT_ANSWER:
        return options.map((opt: any, idx: number) => {
          return (
            <div key={idx} className="survey-write-question-card-list-item">
              <div className="survey-write-question-card-list-item-inline w-100">
                <div className="w-100">
                  <input
                    type="text"
                    className="form-control survey-write-input"
                    placeholder={t('Your answer')}
                    disabled
                  />
                </div>
              </div>
            </div>
          );
        });
      case Q_PARAGRAPH:
        return options.map((opt: any, idx: number) => {
          return (
            <div key={idx} className="survey-write-question-card-list-item">
              <div className="survey-write-question-card-list-item-inline w-100">
                <div className="w-100">
                  <textarea
                    className="form-control survey-write-input mg-b-15 mg-r-50 w-100"
                    rows={2}
                    placeholder={t('Your answer')}
                    disabled
                  />
                </div>
              </div>
            </div>
          );
        });
      case Q_FILE_UPLOAD:
        //return options.map((opt, idx) => {
        //dynamic option key
        //const optionKey = 'option' + (keyQ + 1).toString() + (idx + 1).toString();

        return (
          <div className="survey-write-question-card-list-item">
            <div className="survey-write-question-card-list-item-inline w-100">
              <div className="custom-file w-50">
                <input
                  type="file"
                  className="custom-file-input"
                  //id={optionKey}
                  disabled
                />
                <label className="custom-file-label" htmlFor="customFile">
                  <SpanLang keyLang="Choose file" />
                </label>
              </div>
              {/* <Upload files={files}/> */}
            </div>
          </div>
        );
      //});
      case Q_DATE:
        return options.map((opt: any, idx: number) => {
          //dynamic option key
          //const optionKey = 'option' + (keyQ + 1).toString() + (idx + 1).toString();
          //const selectedDate = opt.answer ? new Date(opt.answer) : new Date();
          return (
            <div key={idx} className="survey-write-question-card-list-item">
              <div className="survey-write-question-card-list-item-inline w-100">
                <div className="w-50">
                  <DatePicker
                    disabled={true}
                    selected={new Date()}
                    onChange={(date: any) => null}
                  />
                </div>
              </div>
            </div>
          );
        });
      case Q_TIME:
        return options.map((opt: any, idx: number) => {
          //dynamic option key
          return (
            <div key={idx} className="survey-write-question-card-list-item">
              <div className="survey-write-question-card-list-item-inline w-100">
                <span className="pd-r-10">
                  <SpanLang keyLang="Time" /> (HH:MM)
                </span>
                <div className="wd-80">
                  <input
                    type="number"
                    className="form-control survey-write-input tx-center"
                    min="0"
                    max="23"
                    //value={selectedTime[0]}
                    maxLength={2}
                    //onChange={(e) => onOptionTimeChange(e, idx, 'H')}
                    disabled
                  />
                </div>
                :
                <div className="wd-80">
                  <input
                    type="number"
                    className="form-control survey-write-input tx-center"
                    min="0"
                    max="60"
                    //value={selectedTime[1]}
                    maxLength={2}
                    //onChange={(e) => onOptionTimeChange(e, idx, 'M')}
                    disabled
                  />
                </div>
              </div>
            </div>
          );
        });
      case Q_MULTI_CHOICES_GRID:
        return (
          <div className="survey-write-question-card-list-container pd-t-10">
            <table className="table survey-write-question-grid-table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  {/* column headers */}
                  {options.cols.map((col: any, idx: number) => (
                    <th key={idx} scope="col">
                      {col.value ? col.value : t('Column') + ' ' + (idx + 1)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* rows content */}
                {options.rows.map((row: any, rIdx: number) => (
                  <tr key={rIdx}>
                    <td scope="row">{row.value ? row.value : t('Row') + ' ' + (rIdx + 1)}</td>
                    {options.cols.map((col: any, cIdx: number) => {
                      //render option radio check
                      return (
                        <td key={cIdx} scope="row">
                          <div className="survey-write-question-item-symbol">
                            <Circle />
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      case Q_TICK_BOX_GRID:
        return (
          <div className="survey-write-question-card-list-container pd-t-10">
            <table className="table survey-write-question-grid-table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  {/* column headers */}
                  {options.cols.map((col: any, idx: number) => (
                    <th key={idx} scope="col">
                      {col.value ? col.value : t('Column') + ' ' + (idx + 1)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* rows content */}
                {options.rows.map((row: any, rIdx: number) => (
                  <tr key={rIdx}>
                    <td scope="row">{row.value ? row.value : t('Row') + ' ' + (rIdx + 1)}</td>
                    {options.cols.map((col: any, cIdx: any) => {
                      //render option radio check
                      return (
                        <td key={cIdx} scope="row">
                          <div className="survey-write-question-item-symbol">
                            <Square />
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
    }
  }

  //render options group
  function renderOptionsGroup() {
    //other types
    return (
      <div className="survey-write-question-card-list pd-t-10">
        {/* options */}
        {renderOption()}
      </div>
    );
  }

  //component render
  return (
    <div className="survey-write-question-view-container">
      <div className="survey-write-question-card-root">
        <div className="card">
          {/* <img src="..." class="card-img-top" alt="..."> */}

          {/* question options */}
          <div className="card-body">
            {/* title */}
            {renderTitle()}

            {renderQImage()}

            {/* question options group */}
            {renderOptionsGroup()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionStaticView;
