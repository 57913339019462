export const FIRST_DAYS = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];

export const FIRST_WEEK_OF_YEARS = [
  {
    value: 1,
    label: 'Starts on Jan 1',
  },
  {
    value: 2,
    label: 'First 4-day week',
  },
  {
    value: 3,
    label: 'First full week',
  },
];
export const DATE_FORMATS = [
  { value: 'M/d/yyyy', label: 'M/d/yyyy' },
  { value: 'M/d/yy', label: 'M/d/yy' },
  { value: 'MM/dd/yy', label: 'MM/dd/yy' },
  { value: 'MM/dd/yyyy', label: 'MM/dd/yyyy' },
  { value: 'yy/MM/dd', label: 'yy/MM/dd' },
  { value: 'yyyy-MM-dd', label: 'yyyy-MM-dd' },
  { value: 'dd-MMM-yy', label: 'dd-MMM-yy' },
];

export const DATE_SEPARATORS = [
  { value: '/', label: '/' },
  { value: '-', label: '-' },
  { value: '.', label: '.' },
];

export const MENU_SETTING_KEY_PAIN_POINT = 'pain_point';
