import { useCallback, useEffect, useRef, useState } from 'react';
import { Checkbox, Input, RadioGroup, Switch } from '@base/components/form';
import { UserAutoComplete } from '@base/containers';
import { useMenuSettings } from '@settings/general-setting/services/format-service';
import useMutationPost from '@base/hooks/useMutationPost';
import { UPDATE_MENU_SETTING } from '@settings/general-setting/services/graphql/format';
import _ from 'lodash';
import Loading from '@base/components/loading';
import { useSetRecoilState } from 'recoil';
import { savingPreferencesAtom } from '@settings/preferences/recoil/atoms';
import { DIMENSION_UNIT_OPTIONS, WEIGHT_UNIT_OPTIONS } from '@product/main/config/constants';

const ProductGeneral: React.FC<any> = () => {
  //state
  const setSavingOption = useSetRecoilState(savingPreferencesAtom);
  const [selectedDimension, setSelectedDimension] = useState(DIMENSION_UNIT_OPTIONS[0]);
  const [selectedWeight, setSelectedWeight] = useState(WEIGHT_UNIT_OPTIONS[0]);
  const [costPercent, setCostPercent] = useState<number>(0);
  const [debCostPercent, setDebCostPercent] = useState<number>(0);
  const setCostPercentDebounced = useRef(
    _.debounce((newValue) => setDebCostPercent(newValue), 1000),
  ).current;
  const [showNoti, setShowNoti] = useState(true);
  const defaultNotiValue = [
    {
      type: 'sales_end_date',
      used: false,
      before: 0,
      sendEmail: false,
      sendSms: false,
      sendNotification: false,
      sendTo: [],
    },
    {
      type: 'experation_date',
      used: false,
      before: 0,
      sendEmail: false,
      sendSms: false,
      sendNotification: false,
      sendTo: [],
    },
  ];
  const [notiValue, setNotiValue] = useState<any>(defaultNotiValue);
  const [debNotiValue, setDebNotiValue] = useState<any>(defaultNotiValue);
  const setNotiValueDebounced = useRef(
    _.debounce((newValue) => setDebNotiValue(newValue), 1000),
  ).current;
  const initialCostLoad = useRef<any>(false);
  const initialNotiLoad = useRef<any>(false);

  //mutation
  const mUpdate: any = useMutationPost(UPDATE_MENU_SETTING, 'setting_updateMenuSetting');

  //set saving option
  useEffect(() => {
    setSavingOption((curOption: any) => ({ ...curOption, isLoading: mUpdate.isLoading }));
  }, [mUpdate.isLoading]);

  //get
  const params = {
    keys: ['item_measurement', 'cost_of_good', 'notifications'],
    menus: ['product'],
  };
  const { data: postResult, isLoading: isMeasureLoading } = useMenuSettings(params);
  // console.log('postResult', postResult);

  //init value
  useEffect(() => {
    if (postResult?.data) {
      //item_measurement
      const measureSetting = postResult.data.find((_ele: any) => _ele.key === 'item_measurement');
      if (measureSetting) {
        try {
          const measureValue = JSON.parse(measureSetting.value);
          //dimension
          const fDim = DIMENSION_UNIT_OPTIONS.find(
            (_ele: any) => _ele.value === measureValue.dimension,
          );
          if (fDim) {
            setSelectedDimension(fDim);
          }
          //weight
          const fWeight = WEIGHT_UNIT_OPTIONS.find(
            (_ele: any) => _ele.value === measureValue.weight,
          );
          if (fWeight) {
            setSelectedWeight(fWeight);
          }
        } catch {
          // console.log('Parse error.');
        }
      }

      //cost_of_good
      const costSetting = postResult.data.find((_ele: any) => _ele.key === 'cost_of_good');
      if (costSetting) {
        setCostPercent(costSetting.value || 0);
      }

      //notifications
      const notiSetting = postResult.data.find((_ele: any) => _ele.key === 'notifications');
      if (notiSetting) {
        try {
          const notiValue = JSON.parse(notiSetting.value);
          //on off
          setShowNoti(notiValue.used);
          //rules
          setNotiValue(notiValue.rules || defaultNotiValue);
        } catch {
          // console.log('Parse error.');
        }
      }
    }
  }, [postResult]);

  useEffect(() => {
    if (initialCostLoad?.current) {
      handleCostSave(debCostPercent);
    } else {
      initialCostLoad.current = true;
    }
    () => {
      initialCostLoad.current = false;
    };
  }, [debCostPercent]);

  useEffect(() => {
    if (initialNotiLoad?.current) {
      handleNotiSave(showNoti, debNotiValue);
    } else {
      initialNotiLoad.current = true;
    }
    () => {
      initialNotiLoad.current = false;
    };
  }, [debNotiValue]);

  //mesurement setting
  const handleMeasureSave = (dimension: string, weight: string) => {
    const params: any = {
      menu: 'product',
      key: 'item_measurement',
      value: JSON.stringify({ dimension, weight }),
    };
    mUpdate.mutate({ menuSetting: params });
  };
  //debounce function
  const handleMeasureSaveDebounce = useCallback(_.debounce(handleMeasureSave, 500), [
    selectedDimension,
    selectedWeight,
  ]);

  //cost settting
  const handleCostSave = (percent: number) => {
    const params: any = {
      menu: 'product',
      key: 'cost_of_good',
      value: percent,
    };
    mUpdate.mutate({ menuSetting: params });
  };

  //notification settting
  const handleNotiSave = (isOnOff: boolean, notiValue: any) => {
    const params: any = {
      menu: 'product',
      key: 'notifications',
      value: JSON.stringify({ used: isOnOff, rules: notiValue }),
    };
    mUpdate.mutate({ menuSetting: params });
  };

  //dimension change
  const handleDimensionOptionChange = (newOption: any) => {
    setSelectedDimension(newOption);
    //save
    handleMeasureSaveDebounce(newOption.value, selectedWeight.value);
  };

  //weight change
  const handleWeightOptionChange = (newOption: any) => {
    setSelectedWeight(newOption);
    //save
    handleMeasureSaveDebounce(selectedDimension.value, newOption.value);
  };

  //notification change
  const handleNotiChange = (index: number, keyName: string, keyValue: any) => {
    const newNotiValue = [...notiValue];
    newNotiValue[index][keyName] = keyValue;
    setNotiValue(newNotiValue);
    setNotiValueDebounced(newNotiValue);
  };

  return (
    <div className="pd-10 scroll-box" style={{ maxHeight: 'calc(100vh - 200px)' }}>
      {isMeasureLoading && <Loading />}
      <div className="card-columns settings-columns">
        {/* Item Measurement */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">Item Measurement</div>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label className="form-item-titme">Default dimension</label>
              <RadioGroup
                value={selectedDimension.value}
                options={DIMENSION_UNIT_OPTIONS}
                onChange={handleDimensionOptionChange}
              />
            </div>
            <div className="form-group">
              <label className="form-item-titme">Default weight</label>
              <RadioGroup
                value={selectedWeight.value}
                options={WEIGHT_UNIT_OPTIONS}
                onChange={handleWeightOptionChange}
              />
            </div>
          </div>
        </div>

        {/* Cost of Goods */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">
              Cost of Goods
              <br />
              <small className="mg-t-5 tx-color-03 tx-normal">
                The Cost to physically produce/purchase/service/delivery
              </small>
            </div>
          </div>
          <div className="card-body d-flex align-items-center">
            Approximately
            <Input
              type="number"
              rightIcon="%"
              className="wd-200 mg-x-10"
              value={costPercent}
              onChange={(newValue: number) => {
                setCostPercent(newValue);
                setCostPercentDebounced(newValue);
              }}
            />
            Of Base Price
          </div>
        </div>

        {/* Notification */}
        <div className="card">
          <div className="card-header h6 bg-light">
            <div className="card-header-title">
              Notification
              <br />
              <small className="mg-t-5 tx-color-03 tx-normal">
                Send Notification to Assigned Reps
              </small>
            </div>
            <Switch
              className="mg-l-auto"
              value={showNoti}
              onChange={() => {
                setShowNoti(!showNoti);
                handleNotiSave(!showNoti, notiValue);
              }}
            />
          </div>
          {showNoti && (
            <div className="table-responsive">
              <table className="table table-bordered mg-b-0 bd-0 settings-tb">
                <thead>
                  <tr>
                    <th scope="col" className="bd-t-0-f">
                      Notification
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Email
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      SMS
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Notification
                    </th>
                    <th scope="col" className="bd-t-0-f">
                      Notify to
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {notiValue.map((_item: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <Checkbox
                          label="Send notification on Sales End Date"
                          checked={_item.used}
                          onChange={(e: any) => handleNotiChange(index, 'used', e.target.checked)}
                        />
                        {_item.used && (
                          <div className="d-flex align-items-center mg-t-5 mg-l-30">
                            <Input
                              type="number"
                              rightIcon="day(s)"
                              className="wd-150 mg-r-10"
                              value={_item.before}
                              onChange={(newBefore: any) =>
                                handleNotiChange(index, 'before', newBefore)
                              }
                            />{' '}
                            before
                          </div>
                        )}
                      </td>
                      <td className="text-center">
                        <Checkbox
                          checked={_item.sendEmail}
                          onChange={(e: any) =>
                            handleNotiChange(index, 'sendEmail', e.target.checked)
                          }
                        />
                      </td>
                      <td className="text-center">
                        <Checkbox
                          checked={_item.sendSms}
                          onChange={(e: any) =>
                            handleNotiChange(index, 'sendSms', e.target.checked)
                          }
                        />
                      </td>
                      <td className="text-center">
                        <Checkbox
                          checked={_item.sendNotification}
                          onChange={(e: any) =>
                            handleNotiChange(index, 'sendNotification', e.target.checked)
                          }
                        />
                      </td>
                      <td>
                        <UserAutoComplete
                          single={false}
                          showAvatar
                          value={_item.sendTo}
                          onChange={(newUsers: any) => handleNotiChange(index, 'sendTo', newUsers)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductGeneral;
