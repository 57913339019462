import React, { useCallback, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { ListBody, ListPagination, ListQuickAction } from '@base/components/list';
import ListReactTable from '@base/components/list/list-react-table';
import ListLoading, { TableNothing } from '@base/components/list/list-loading';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import * as keyNames from '@settings/assignment-rule/config/key-names';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';
import { cloneDeep } from 'lodash';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MENU_SETTING_ASSIGNMENT_RULE } from '@base/config/menus';
import { DESC, ASC } from '@base/config/constant';
import { Trash2 } from 'react-feather';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import {
  DELETE_ASSIGNMENT_RULE,
  DELETE_ASSIGNMENT_RULES,
} from '@settings/assignment-rule/services/graphql'; //for write
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

interface IPageBody {
  fields: any[];
  isFetching: boolean;
  itemsList: any[];
  paging: any;
  isPreviousData: any;
  columnRenderRemap: any;
  hideColumns: any[];
}
const PageBody: React.FC<any> = (props) => {
  const {
    isFetching,
    fields,
    itemsList = [],
    paging,
    isPreviousData,
    columnRenderRemap,
    hideColumns,
  } = props;
  //state
  const { device } = useRecoilValue(deviceAtom);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_SETTING_ASSIGNMENT_RULE));
  const [quickLoading, setQuickLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const goToView = useCallback((row: any) => {
    const url = `/${'assignment-rule'}/${row.id}`;
    navigate(url);
  }, []);
  let listData = itemsList;

  //render row data and field-column: for table + full-grid
  const onCheckedRow = useCallback(
    (checkedValue: string | number) => {
      const checkedValueStr = checkedValue as string;
      let newSelectedIds = selectedIds;
      switch (checkedValueStr) {
        case 'all':
          newSelectedIds = [];
          listData?.map((prod: any) => {
            newSelectedIds.push(prod?.id);
          });
          break;
        case 'notAll':
          newSelectedIds = [];
          break;
        default:
          if (selectedIds.indexOf(checkedValueStr) !== -1) {
            newSelectedIds.splice(selectedIds.indexOf(checkedValueStr), 1);
          } else {
            newSelectedIds = [...selectedIds, checkedValueStr];
          }
          break;
      }
      setSelectedIds(cloneDeep(newSelectedIds));
    },
    [selectedIds, setSelectedIds],
  );
  let { filter, listType, settingColumns } = pageData;
  const sort = filter?.sort;
  const setSort = (sort: any) => {
    let newFilter = {
      ...pageData,
      filter: {
        ...pageData.filter,
        sort: sort,
      },
    };
    setPageData(newFilter);
  };
  //table props
  // console.log('>>>>>> itemsList', itemsList);
  const listTableProps: any = {
    nodata: <TableNothing />,
    data: itemsList ?? [],
    isFetching,
    fetching: <ListLoading />,
    columns: makeTableColumns(fields, columnRenderRemap, {}, hideColumns),
    initialState: {
      pageSize: paging?.totalPage || 0,
      pageIndex: paging?.currentPage || 1,
      selectedIds: selectedIds,
      sort: sort,
    },
    //isCheckboxTable: false,
    onCheckedRow: onCheckedRow,
    onRowClick: (row: any, e: any) => {
      e && e.preventEvent && e.preventDefault();
      goToView(row);
    },
    onSortBy: (clName: any, isSortedDesc: boolean) => {
      let orderBy = isSortedDesc ? DESC : ASC;
      setSort({ field: clName, orderBy: orderBy });
    },
  };
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_ASSIGNMENT_RULES,
    'setting_deleteAssignmentRules',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Deleted Assignment Rule successfully!');
        setTimeout(() => {
          queryClient.invalidateQueries(['setting_deleteAssignmentRules']);
        }, 500);
        return navigate(`/settings/assignment-rule/list`);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        toast.error('Deleted Assignment Rule failed: ' + JSON.parse(error).message);
      },
    },
  );
  const quickButtons = [
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: (selected: any) => {
        mutationDelete.mutate({ ids: selectedIds });
      },
    },
  ];
  //table paging
  const pagingProps: any = {
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: paging?.itemPerPage || 10,
    nextPage: paging?.nextPage || null,
    previousPage: paging?.previousPage || null,
    isPreviousData,
    onChange: (page: number, size: number) => {},
  };

  //main
  return (
    <>
      <ListBody>
        <>
          {/* table list */}
          <ListReactTable {...listTableProps} />
        </>
        <ListPagination isSplitMode={false} type={'full'} {...pagingProps} />
      </ListBody>
      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons || []}
        resetSelected={() => onCheckedRow('notAll')}
        visible={!!selectedIds.length}
      />
    </>
  );
};

export default PageBody;
