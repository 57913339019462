import { atom } from 'recoil';

export const arWriteOptionAtom = atom({
  key: 'ArWriteOptionAtom',
  default: {
    writeMode: 'list', //write
    writeType: '', //'rule', 'report', ...
    writeParam: {},
    isOpenWrite: false,
  },
});
