import React, { Fragment } from 'react';
//import { Pie, HorizontalBar, Bar } from 'react-chartjs-2';
import ReactApexChart from 'react-apexcharts';
import {
  Q_MULTI_CHOICES,
  Q_CHECKBOXES,
  Q_DROPDOWN,
  Q_SHORT_ANSWER,
  Q_PARAGRAPH,
  Q_FILE_UPLOAD,
  Q_DATE,
  Q_TIME,
  Q_MULTI_CHOICES_GRID,
  Q_TICK_BOX_GRID,
} from '@settings/online-digital-content/survey/config/constants';
import { useTranslation } from 'react-i18next';
import { SpanLang } from '@base/components';
import { indexOf } from 'lodash';
//import ViewPage from '@settings/site/pages/view';

const COLORS = [
  '#4285F4',
  '#8430CE',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  'Green',
  'Purple',
  'Pink',
  'Orange',
  'Teal',
  'Yellow',
  'Red',
  'Blue',
  'Gray',
  '#414549',
];

/**
 * Response summary
 * @param {*} props
 */
const Summary: React.FC<any> = (props) => {
  //component render
  // radio, dropdown: (Circle) Pie/Donuts chart
  // checkbox: Horizontal Bar chart
  // multiple radio grid, tick box grid: Vertical Bar chart
  // short answer, long answer, date, time, file: count number + list answers
  //

  const { responseS } = props;
  const { t } = useTranslation();

  //create all questions belongs to section 1..N to one array
  let sectionResponses: any = [];
  responseS.map((surResp: any) => {
    surResp.sections.map((section: any, index: number) => {
      if (!sectionResponses[index]) {
        sectionResponses[index] = [];
      }
      sectionResponses[index] = sectionResponses[index].concat(section.questions);
    });
  });

  //"group_by" by option title
  function groupBy(data: any, property: any) {
    return data.reduce(function (acc: any, obj: any) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  //render question summary displaying by listview
  function renderListView(ansResp: any, typeQ: number) {
    let answers: string[] = [];
    ansResp?.map((answer: any) => {
      if (typeQ === Q_FILE_UPLOAD) {
        answers.push(answer.options[0].answer[0].name);
      } else {
        answers.push(answer.options[0].answer);
      }
    });

    let rows: any[] = [];
    answers?.map((answer: string) => {
      if (rows?.findIndex((v: any) => v.value === answer) === -1) {
        rows.push({
          value: answer,
          count: answers.filter((x) => x === answer).length,
        });
      }
    });

    return (
      ansResp.length > 0 && (
        <div className="card mg-b-15">
          <div className="card-body">
            <div className="d-flex flex-column justify-content-start">
              <div className="d-flex tx-16 tx-bold">
                {ansResp[0].title ? ansResp[0].title : t('Untitled question')}
              </div>
              <div className="d-flex pd-t-10">
                {ansResp.length + (ansResp.length === 1 ? ' ' + t('Single') : ' ' + t('Plural'))}
              </div>
              <div className="d-flex flex-column justify-content-start pd-t-20">
                {rows?.map((answer: any, index: number) => {
                  return (
                    <div key={index} className="d-flex ht-40 bg-gray-100 mg-t-5 align-items-center">
                      <span className="pd-10">{answer?.value}</span>
                      {answer?.count > 1 && (
                        <span className="badge badge-danger">{answer?.count}</span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  //render Pie chart
  function renderPie(ansResp: any, typeQ: number) {
    //chart dataset
    let dataQ: number[] = []; //[300, 50, 100, 40, 120];
    //get data labels (= options in question)
    let chartLabels: string[] = [];
    let labelColors: string[] = [];
    let chartTitle: string = t('Untitled question');
    if (ansResp.length > 0) {
      // ansResp[0].options.map((_item: any, _index: number) => {
      //   chartLabels.push(_item.value);
      //   labelColors.push(COLORS[_index % 15]);
      // });
      ansResp?.map((answer: any, index: number) => {
        answer?.options?.map((_item: any) => {
          let vOption;
          if (!_item.isOther) {
            vOption = _item.value;
          }
          if (_item.isOther && _item.checked) {
            vOption = _item?.answer ?? '';
          }
          if (vOption && chartLabels.indexOf(vOption) === -1) {
            chartLabels.push(vOption);
            labelColors.push(COLORS[index % 15]);
          }
        });
      });

      // Chart title
      if (ansResp[0].title.length > 0) {
        chartTitle = ansResp[0].title;
      }
    }
    //count checked answer by label (item.value == option)
    chartLabels.map((label: any, index: number) => {
      dataQ[index] = 0;
      ansResp.map((resp: any) => {
        resp.options.map((opt: any) => {
          // if (opt.value === label && opt.checked === true) {
          //   dataQ[index] += 1;
          // }
          let vOption = opt.value;
          if (opt.isOther && opt.checked) {
            vOption = opt?.answer ?? '';
          }
          if (vOption === label && opt.checked) {
            dataQ[index] += 1;
          }
        });
      });
    });

    //count total answered responses
    let totalCount = 0;
    dataQ.map((count: number) => (totalCount += count));

    //FOR apexChart
    const chartOptions: any = {
      chart: {
        type: 'pie',
        width: 380,
      },
      labels: chartLabels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };

    return (
      ansResp.length > 0 && (
        <div className="card mg-b-15">
          <div className="card-body">
            <div className="d-flex flex-column justify-content-start">
              <div className="d-flex tx-16 tx-bold">{chartTitle}</div>
              <div className="d-flex pd-t-10">
                {totalCount + (totalCount === 1 ? ' ' + t('Single') : ' ' + t('Plural'))}
              </div>
              <div className="d-flex flex-column justify-content-start align-items-center pd-t-20">
                <ReactApexChart type="pie" width={380} options={chartOptions} series={dataQ} />
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  //render horizontal bar chart - for checkboxes question
  function renderHorizontalBar(ansResp: any, typeQ: number) {
    //chart dataset
    let dataQ: number[] = []; //[300, 50, 100, 40, 120];
    //get data labels (= options in question)
    let chartLabels: string[] = [];
    let labelColors: string[] = [];
    let chartTitle: string = t('Untitled question');
    if (ansResp.length > 0) {
      // ansResp[0].options.map((_item: any, _index: number) => {
      //   chartLabels.push(_item.value);
      //   labelColors.push(COLORS[_index % 15]);
      // });
      ansResp?.map((answer: any, index: number) => {
        answer?.options?.map((_item: any) => {
          let vOption;
          if (!_item.isOther) {
            vOption = _item.value;
          }
          if (_item.isOther && _item.checked) {
            vOption = _item?.answer ?? '';
          }
          if (vOption && chartLabels.indexOf(vOption) === -1) {
            chartLabels.push(vOption);
            labelColors.push(COLORS[index % 15]);
          }
        });
      });
      if (ansResp[0].title.length > 0) {
        chartTitle = ansResp[0].title;
      }
    }
    //count checked answer by label (item.value == option)
    chartLabels.map((label: any, index: number) => {
      dataQ[index] = 0;
      ansResp.map((resp: any) => {
        resp.options.map((opt: any) => {
          // if (opt.value === label && opt.checked === true) {
          //   dataQ[index] += 1;
          // }
          let vOption = opt.value;
          if (opt.isOther && opt.checked) {
            vOption = opt?.answer ?? '';
          }
          if (vOption === label && opt.checked) {
            dataQ[index] += 1;
          }
        });
      });
    });

    //count total answered responses
    let totalCount: number = 0;
    dataQ.map((count: number) => (totalCount += count));

    //FOR apexChart
    const chartOptions: any = {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          barHeight: '40%',
          borderRadius: 4,
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
        },
      },
      colors: labelColors, //['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B']
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff'],
        },
        formatter: function (val: any, opt: any) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories: chartLabels, //['South Korea', 'Viet Nam', 'United Kingdom', 'USA', 'Italy'],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      // title: {
      //   text: chartTitle,
      //   align: 'center',
      //   floating: true,
      // },
      // subtitle: {
      //   text: 'Question Description',
      //   align: 'center',
      // },
      tooltip: {
        theme: 'dark',
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return '';
            },
          },
        },
      },
    };

    //// console.log('labelColors', labelColors);
    //// console.log('dataQ', dataQ);
    return (
      ansResp.length > 0 && (
        <div className="card mg-b-15">
          <div className="card-body">
            <div className="d-flex flex-column justify-content-start">
              <div className="d-flex tx-16 tx-bold">{chartTitle}</div>
              <div className="d-flex pd-t-10">
                {totalCount + (totalCount === 1 ? ' ' + t('Single') : ' ' + t('Plural'))}
              </div>
              <div className="d-flex flex-column justify-content-start pd-t-20">
                <ReactApexChart
                  type="bar"
                  height={380}
                  options={chartOptions}
                  series={[{ data: dataQ }]}
                />
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  //render vertical bar chart - for grid question
  function renderVerticalBar(ansResp: any, typeQ: number) {
    //chart dataset
    let dataQ: number[] = []; //[300, 50, 100, 40, 120];
    //get data labels (= options in question)
    let chartLabels: string[] = [];
    //let labelColors: string[] = [];
    let chartTitle: string = t('Untitled question');
    if (ansResp.length > 0) {
      ansResp[0].options.rows.map((row: any) => {
        ansResp[0].options.cols.map((col: any, _cIdx: number) => {
          const newLabel = row.value + '-' + col.value;
          chartLabels.push(newLabel);
          //labelColors.push(COLORS[_cIdx % 15]);
        });
      });
      if (ansResp[0].title.length > 0) {
        chartTitle = ansResp[0].title;
      }
    }

    //count checked on row-col
    chartLabels.map((label: any, index: number) => {
      dataQ[index] = 0;
      ansResp.map((resp: any) => {
        resp.options.rows.map((row: any, rIdx: number) => {
          resp.options.cols.map((col: any, cIdx: number) => {
            const newLabel = row.value + '-' + col.value;
            //is checked for this combination rol-col
            const optChecked =
              resp.options.answer && resp.options.answer[rIdx]
                ? resp.options.answer[rIdx][cIdx]
                : false;
            if (label === newLabel && optChecked == true) {
              dataQ[index] += 1;
            }
          });
        });
      });
    });

    //count total answered responses
    let totalCount = 0;
    dataQ.map((count) => (totalCount += count));

    //FOR apexChart
    const chartOptions: any = {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          //borderRadius: 5,
          horizontal: false,
          columnWidth: '50%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      grid: {
        row: {
          colors: ['#fff', '#f2f2f2'],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: chartLabels,
        tickPlacement: 'on',
      },
      yaxis: {
        title: {
          text: 'N (responses)',
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100],
        },
      },
      tooltip: {
        y: {
          formatter: function (val: string) {
            return parseInt(val);
          },
        },
      },
    };

    //// console.log('chartLabels', chartLabels);
    //// console.log('dataQ', dataQ);
    return (
      ansResp.length > 0 && (
        <div className="card mg-b-15">
          <div className="card-body">
            <div className="d-flex flex-column justify-content-start">
              <div className="d-flex tx-16 tx-bold">{chartTitle}</div>
              <div className="d-flex pd-t-10">
                {totalCount + (totalCount === 1 ? ' ' + t('Single') : ' ' + t('Plural'))}
              </div>
              <div className="d-flex flex-column justify-content-start pd-t-20">
                <ReactApexChart
                  type="bar"
                  height={350}
                  options={chartOptions}
                  series={[{ name: 'Responses', data: dataQ }]}
                />
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  //summary for each question type
  function renderQTypeSummay(questions: any, typeQ: number) {
    //get all questions having answer by type
    let ansResp: any = [];
    let choiceQ: any = [];
    switch (typeQ) {
      case Q_SHORT_ANSWER:
      case Q_PARAGRAPH:
      case Q_DATE:
      case Q_TIME:
      case Q_FILE_UPLOAD:
        ansResp = questions.filter((item: any) => item.type === typeQ && item.options[0].answer);
        choiceQ = groupBy(ansResp, 'title'); //group by questions by key: 'title'
        //get each array for displaying chart
        return Object.keys(choiceQ).map((key) => {
          return <Fragment key={key}>{renderListView(choiceQ[key], typeQ)}</Fragment>;
        });
      case Q_DROPDOWN:
      case Q_MULTI_CHOICES:
        ansResp = questions.filter((item: any) => item.type === typeQ);
        choiceQ = groupBy(ansResp, 'title'); //group by questions by key: 'title'
        //get each array for displaying chart
        return Object.keys(choiceQ).map((key) => {
          return <Fragment key={key}>{renderPie(choiceQ[key], typeQ)}</Fragment>;
        });
      case Q_CHECKBOXES:
        ansResp = questions.filter((item: any) => item.type === typeQ);
        choiceQ = groupBy(ansResp, 'title'); //group by questions by key: 'title'
        //get each array for displaying chart
        return Object.keys(choiceQ).map((key) => {
          return <Fragment key={key}>{renderHorizontalBar(choiceQ[key], typeQ)}</Fragment>;
        });
      case Q_MULTI_CHOICES_GRID:
      case Q_TICK_BOX_GRID:
        ansResp = questions.filter((item: any) => item.type === typeQ);
        choiceQ = groupBy(ansResp, 'title'); //group by questions by key: 'title'
        //get each array for displaying chart
        return Object.keys(choiceQ).map((key) => {
          return <Fragment key={key}>{renderVerticalBar(choiceQ[key], typeQ)}</Fragment>;
        });
    }
  }

  return (
    <div
      className="pos-relative mg-t-10 pd-b-10 scroll-box"
      style={{ height: 'calc(100vh - 300px)' }}
    >
      {sectionResponses.map((questions: any, index: number) => {
        //index=0: main section
        //index=1: section 1
        //...
        return (
          <div key={index} className="pos-relative">
            {/* section header */}
            {index > 0 && (
              <div className="d-flex ht-50 bg-indigo rounded-5 mg-t-15">
                <div className="d-flex align-items-center tx-white tx-bold pd-l-10">
                  <SpanLang keyLang="Section" /> {index}
                </div>
              </div>
            )}
            {renderQTypeSummay(questions, Q_MULTI_CHOICES)}
            {renderQTypeSummay(questions, Q_CHECKBOXES)}
            {renderQTypeSummay(questions, Q_DROPDOWN)}
            {renderQTypeSummay(questions, Q_SHORT_ANSWER)}
            {renderQTypeSummay(questions, Q_PARAGRAPH)}
            {renderQTypeSummay(questions, Q_DATE)}
            {renderQTypeSummay(questions, Q_TIME)}
            {renderQTypeSummay(questions, Q_FILE_UPLOAD)}
            {renderQTypeSummay(questions, Q_TICK_BOX_GRID)}
            {renderQTypeSummay(questions, Q_MULTI_CHOICES_GRID)}
          </div>
        );
      })}
    </div>
  );
};

export default Summary;
