import Icon from '@base/assets/icons/svg-icons';
import React, { lazy, useEffect, useRef, useState } from 'react';
import { Check, ChevronDown } from 'react-feather';
import { MENU_SETTING_TICKET_FORM } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { useRecoilValue } from 'recoil';
import * as keyNames from '@settings/online-digital-content/ticket-form/config/key-names';
import GrapesTSViewField from '@base/containers/view-field/grapes-ts';
import { default as viewConfig } from '@settings/online-digital-content/ticket-form/config/view-field';
import GrapesTS from '@base/components/grapes-ts';
import { Button } from '@base/components/form';
import { useStorageDownloadMutation } from '@base/hooks/useFileUploadMutation';
import { BaseMutationResponse } from '@base/types/interfaces/response';

// const Notes = lazy(() => import('@base/containers/note'));

const ViewCenter: React.FC<any> = (props: any) => {
  const { id } = props;

  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_SETTING_TICKET_FORM));

  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
  const htmlData = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_TICKET_FORM_HTML,
  );
  const config = viewConfig[keyNames.KEY_TICKET_FORM_HTML];

  const [htmlJson, setHtmlJson] = useState<any>(null);
  const [isDownloading, setIsDownloading] = useState(true);

  const mStorageDownload: any = useStorageDownloadMutation<BaseMutationResponse>({
    onSuccess: (data: any, variables: any, context: any) => {
      // // console.log('data', data);
      // const base64Data = data.substring(data.indexOf(',') + 1);
      // const decodeData = base64URLDecode(base64Data);
      //// console.log('base64URLDecode', base64URLDecode(base64Data));
      setHtmlJson(data);
      setIsDownloading(false);
    },
    onError: (error: any, variables: any, context: any) => {
      setIsDownloading(false);
      //// console.log('mutation error', error);
      //toast.success('There is an error during uploading: ' + JSON.parse(error).message);
    },
  });

  useEffect(() => {
    if (htmlData?.data) {
      const params = {
        filename: htmlData.data, //TODO
        module: 'ticketform',
      };
      mStorageDownload.mutate(params);
    }
  }, [htmlData]);

  return (
    <div className="pd-20">
      {!isDownloading && (
        <GrapesTS
          height={'calc(100vh - 235px)'}
          storageId={'ticketform-view-gts'}
          //value={htmlJson ? htmlJson : null}
          value={htmlJson ?? null}
          //onChange={onChange}
        />
      )}

      <div className="d-flex mg-t-10">
        <Button color="success" className="mg-l-auto">
          <Check className="mg-r-5" />
          Save
        </Button>
      </div>
    </div>
  );
};

export default ViewCenter;
