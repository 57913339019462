import { Button, Input, Select } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import { useListContact } from '@settings/billing-license/services';
import { IContact } from '@settings/billing-license/types/interfaces/contact';
import React, { useEffect, useMemo, useState } from 'react';
import { Trash } from 'react-feather';

interface IBillingInfoContact {
  value: any;
  onChange: (keyName: string, keyValue: any) => any;
}

const BillingInfoContact: React.FC<IBillingInfoContact> = (props: IBillingInfoContact) => {
  const { value, onChange } = props;
  // keep init data from api
  const [dataInit, setDataInit] = useState<IContact[]>([]);

  // init data -> options: {value, label} to render select options
  const { data, refetch, isLoading } = useListContact('');
  const [listOpts, setListOpts] = useState<{ value: string; label: string }[]>([]);
  useEffect(() => {
    if (data?.data) {
      // set init data
      setDataInit(data?.data);
      const lops = data?.data.map((_item: IContact) => {
        return {
          value: _item.id,
          label: _item.name,
        };
      });
      setListOpts(lops); // list options
      // console.log(lops);
    }
  }, [data]);

  // list contact selected to render on table
  const [listData, setListData] = useState<IContact[]>(value?.contact);
  useEffect(() => {
    if (value?.contact.length != listData.length) onChange && onChange('contact', listData);
  }, [listData]);
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row: any) => (
          <Input
            type="text"
            value={row.name}
            // onChange={(data: any) => onChangeValue('name', data)}
          />
        ),
      },
      {
        Header: 'Role',
        accessor: (row: any) => <Input type="text" value={row.role} />,
      },
      {
        Header: 'Email',
        accessor: (row: any) => <Input type="text" value={row.email} />,
      },
      {
        Header: 'Phone',
        accessor: (row: any) => <Input type="tel" value={row.phone} />,
      },
      {
        Header: 'Mobile',
        accessor: (row: any) => <Input type="tel" value={row.mobile} />,
      },
      {
        Header: 'Delete',
        accessor: (row: any) => (
          <Button
            type="button"
            outline={false}
            color="white"
            className="btn btn-link link-03 btn-icon pd-y-0 border-0"
            onClick={() => {
              setListData(
                listData.filter(function (item) {
                  return item.id != row.id;
                }),
              );
            }}
          >
            <Trash />
          </Button>
        ),
      },
    ],
    [listData],
  );
  const addContactItem = (data: any) => {
    let itemAdd: any;
    for (let item of dataInit) {
      if (data.value == item.id) {
        itemAdd = item;
      }
    }
    for (let item of listData) {
      if (itemAdd.id == item.id) {
        return;
      }
    }
    setListData([...listData, itemAdd]);
  };
  // const onChangeValue = (keyName: string, keyValue: any) => {
  //   const newVal = { ...listData };
  // };

  return (
    <div className="card">
      <div className="card-header h6 bg-light">Contact</div>
      <div className="table-responsive">
        <BasicTable
          className="table table-bordered mg-b-0 bd-0 settings-tb"
          columns={columns}
          data={listData}
        />
      </div>
      <div className="pd-12">
        <Select
          options={listOpts}
          placeholder="Type or click to select a contact"
          onChange={(data: any) => {
            addContactItem(data);
          }}
        ></Select>
      </div>
    </div>
  );
};
export default BillingInfoContact;
