import React from 'react';
import AutoCloseTicket from './auto-close-ticket';
import DeskHours from './desk-hours';
import FirstRespondPriority from './first-respond-priority';
import PrioritySetting from './priority-setting';
import SLA from './sla';
import SurveyTemplate from './survey-template';
import TicketClassification from './ticket-classification';
import TimeResolveSla from './time-resolve-sla';

interface IDeskGeneralProps {}
const DeskGeneral: React.FC<IDeskGeneralProps> = (props: IDeskGeneralProps) => {
  return (
    <>
      {' '}
      <div className="pd-20 scroll-box setting-tab-body">
        <div className="row row-xs">
          <div className="col-lg-6">
            {/* Desk Hours */}
            <DeskHours />
            {/* SLA */}
            <SLA />
          </div>

          <div className="col-lg-6">
            {/* Priority */}
            <PrioritySetting />
            {/* Ticket Classification */}
            <TicketClassification />
          </div>

          <div className="col-12">
            {/* Time to 1st Respond by Priority */}
            <FirstRespondPriority />
          </div>

          <div className="col-lg-6">
            {/* Time to Resolve by SLA */}
            <TimeResolveSla />
            {/* SurveyTemplate */}
            <SurveyTemplate />
          </div>

          <div className="col-lg-6">
            {/* Auto Close Ticket */}
            <AutoCloseTicket />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeskGeneral;
