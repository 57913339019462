import Icon from '@base/assets/icons/svg-icons';
import { Button, FormIcon, Input, Priority } from '@base/components/form';
import { ListQuickAction } from '@base/components/list';
import { TableNothing } from '@base/components/list/list-loading';
import ListReactTable from '@base/components/list/list-react-table';
import BasicTable from '@base/components/table/basic';
import { PRIORITY_URGENT } from '@base/config/constant';
import { CheckBox } from '@base/config/write-field/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { IdName } from '@base/types/interfaces/common';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { generateUUID } from '@base/utils/helpers';
import { handleCheckbox } from '@base/utils/helpers/list-page-utils';
import Items from '@settings/page-layout/containers/items';
import {
  UPDATE_DESK_TAG,
  CREATE_DESK_TAG,
  DELETE_DESK_TAG,
} from '@settings/preferences/services/graphql';
import { useTags, useTicketsByTag } from '@settings/preferences/services/service';
import { IDeskTag } from '@settings/preferences/types/interfaces';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight, ChevronsRight, RefreshCw, Trash2 } from 'react-feather';
import { toast } from 'react-toastify';
import AddTags from './add-tags';
import KnowledgeList from './KnowledgeList';
import TicketList from './TicketList';

interface ITicket {
  id: string;
  subject: string;
  customer: IdName;
  process: IdName;
  priority: IdName;
  assignedGroups?: IdName[];
  assignedUsers?: IdName[];
}
interface IKnowledgebase {
  id: string;
  subject: string;
  category: IdName;
  folder: IdName;
  viewed: number;
  inserted: number;
  helpful: number;
  notHelpful: number;
}
interface IDeskTagProps {}
const DeskTag: React.FC<IDeskTagProps> = (props: IDeskTagProps) => {
  const [showTicketDetail, setShowTicketDetail] = useState(false);
  const [showArticleDetail, setShowArticleDetail] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState<IDeskTag[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [editItem, setEditItem] = useState<IDeskTag | null>(null);
  const [viewTag, setViewTag] = useState<IDeskTag | null>(null);
  const { data, isLoading, isFetching, refetch } = useTags('');

  const mUpdate: any = useMutationPost<BaseMutationResponse>(UPDATE_DESK_TAG, 'desk_updateTag');

  const mDelete: any = useMutationPost<BaseMutationResponse>(DELETE_DESK_TAG, 'desk_deleteTag');
  const onClose = () => {
    setEditItem(null);
    setIsOpen(false);
  };

  const onSave = (tags: IDeskTag[], mode: string) => {
    let nItems = items;
    if (mode == 'add') {
      nItems = [...tags, ...items];
    } else {
      const tag = tags.length ? tags[0] : null;
      nItems = items.map((item) => {
        if (tag && item.id === tag.id) {
          return tag;
        }
        return item;
      });
    }

    setItems(nItems);
    onClose();
  };
  const onDelete = (ids: string[]) => {
    const nItems = items.filter((item) => {
      return !ids.includes(item.id);
    });
    mDelete.mutate(
      { ids },
      {
        onSuccess: (res: any) => {
          toast.success('Data was removed!');
          setSelectedIds([]);
        },
      },
    );
    setItems(nItems);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row: IDeskTag, rowIndex: any) => {
          return <>{row.name}</>;
        },
      },
      {
        Header: 'Linked Tickets',
        accessor: (row: IDeskTag, rowIndex: any) => {
          return (
            <>
              <Button
                color="link"
                name={row.linkedTickets}
                className="pd-0"
                onClick={() => {
                  setViewTag(row);
                  setShowTicketDetail(true);
                }}
              />
            </>
          );
        },
      },
      {
        Header: 'Linked Articles',
        accessor: (row: IDeskTag, rowIndex: any) => {
          return (
            <>
              <div className="d-flex align-items-center">
                <Button
                  color="link"
                  name={row.linkedArticles}
                  className="pd-0"
                  onClick={() => {
                    setViewTag(row);
                    setShowArticleDetail(true);
                  }}
                />
                <Button
                  color="link"
                  icon="Edit2"
                  className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                  data-han-tooltip="수정"
                  onClick={() => {
                    setEditItem(row);
                    setIsOpen(true);
                  }}
                />
                <Button
                  color=""
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon btn-delete-row han-tooltip--left pd-y-0"
                  data-han-tooltip="삭제"
                  onClick={() => {
                    onDelete([row.id]);
                  }}
                />
              </div>
            </>
          );
        },
      },
    ],
    [items, selectedIds],
  );

  const quickButtons = [
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: (selected: any) => {
        if (selectedIds.length > 0) {
          onDelete(selectedIds);
        }
      },
    },
  ];
  const onCheckedRow = (checkedValue: string | number) => {
    const checkedValueStr = checkedValue as string;
    // console.log('checkedValueStr', checkedValueStr, selectedIds);
    let newSelectedIds = handleCheckbox(checkedValueStr, selectedIds, items);
    setSelectedIds([...newSelectedIds]);
  };
  const listTagsTableProps = {
    nodata: <TableNothing />,
    data: items,
    loading: isFetching,
    columns: columns, //tableHideColumns
    onCheckedRow: onCheckedRow,
    initialState: {
      pageSize: 1,
      pageIndex: 1,
      selectedIds: selectedIds,
    },
  };
  useEffect(() => {
    if (!isLoading && data) {
      setItems(data.results ?? []);
    }
  }, [data]);
  return (
    <>
      {!showArticleDetail && !showTicketDetail && (
        <div className="pd-20 scroll-box setting-tab-body">
          <div className="mg-b-10 text-right">
            <Button color="primary" icon="Plus" name="Add Tags" onClick={() => setIsOpen(true)} />
            <button
              type="button"
              className="btn btn-icon btn-white mg-l-5"
              aria-label="reload"
              onClick={() => refetch()}
            >
              <RefreshCw />
              <span className="sr-only">새로고침</span>
            </button>
          </div>
          <div className="card">
            <ListReactTable
              {...listTagsTableProps}
              className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete"
              // wrapperClassName="card"
            ></ListReactTable>
          </div>
        </div>
      )}
      {showTicketDetail && (
        <TicketList
          viewTag={viewTag}
          goList={() => {
            setShowTicketDetail(false);
            setViewTag(null);
          }}
        />
      )}
      {showArticleDetail && (
        <KnowledgeList
          viewTag={viewTag}
          goList={() => {
            setShowArticleDetail(false);
            setViewTag(null);
          }}
        />
      )}
      {isOpen && (
        <>
          <AddTags isOpen={isOpen} onClose={onClose} onSave={onSave} data={editItem} />
        </>
      )}
      {selectedIds?.length && (
        <ListQuickAction
          checked={selectedIds}
          buttons={quickButtons}
          resetSelected={() => setSelectedIds([])}
          visible={selectedIds.length > 0}
        />
      )}
    </>
  );
};

export default DeskTag;
