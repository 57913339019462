import Icon from '@base/assets/icons/svg-icons';
import React, { lazy } from 'react';
import { ChevronDown } from 'react-feather';
import { MENU_SETTING_LANDINGPAGE, MENU_TEMPLATE_EMAIL } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { useRecoilValue } from 'recoil';
import * as keyNames from '@settings/template/config/key-names';
import GrapesTSViewField from '@base/containers/view-field/grapes-ts';
//import { default as viewConfig } from '@settings/online-digital-content/landing-page/config/view-field';
import { default as viewConfig } from '@settings/template/config/view-field/email/index';
import { slideToMapByKey, filtersArray } from '@base/utils/helpers/array-utils';
import { ViewFields } from '@base/components/hook-form/view';

const Notes = lazy(() => import('@base/containers/note'));

interface Props {
  menuSource: string;
  menuSourceId?: string;
  ignoreFields?: string[];
  column?: number;
  onAction?: Function;
}
const ViewCenter: React.FC<Props> = (props: Props) => {
  const { menuSource, menuSourceId, ignoreFields = [], column = 2, onAction } = props;

  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_TEMPLATE_EMAIL));

  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
  // // console.log('basicFields', basicFields);
  const html = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_MENU_TEMPLATE_DESIGN,
  );
  let htmlData = '';
  if (html == undefined || html?.data == '') {
    htmlData = JSON.stringify({ html: '', css: '' });
  } else {
    htmlData = html.data;
  }
  const config = viewConfig[keyNames.KEY_MENU_TEMPLATE_DESIGN];
  let configView = viewConfig[keyNames.KEY_MENU_TEMPLATE_DESIGN_VIEW];
  configView = { ...configView, viewData: config.viewData };

  let moreFields: any[] = [];
  moreFields = filtersArray(basicFields, [keyNames.KEY_MENU_TEMPLATE_SUBJECT]);

  return (
    <div className="pd-20 scroll-box" style={{ height: 'calc(100vh - 110px)' }}>
      <div className="view-content-section">
        {/* <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Design</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#LPPreview"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div> */}
        <ViewFields
          fields={moreFields}
          column={column}
          ignoreFields={ignoreFields}
          menu={viewData?.menu ?? ''}
          menuSource={viewData?.menuSource ?? ''}
          menuSourceId={viewData?.menuSourceId ?? ''}
        />
        <div id="LPPreview" className="card collapse show">
          <GrapesTSViewField
            keyName={keyNames.KEY_MENU_TEMPLATE_DESIGN}
            value={JSON.parse(htmlData)}
            userPermission={{ isEdit: true }}
            menuSourceId={menuSourceId}
            menuSource={MENU_TEMPLATE_EMAIL}
            viewConfig={viewConfig}
            config={configView}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewCenter;
