import Portal from '@base/components/portal';
import Canvas from '@base/components/canvas';
import React, { useMemo, useState } from 'react';
import BasicTable from '@base/components/table/basic';
import { Input } from '@base/components/form';

interface IReduceView {
  isVisible: boolean;
  value: any;
  onClose: (params: any) => any;
  onSubmit: (params: any) => any;
}

const ReduceView: React.FC<IReduceView> = (props: IReduceView) => {
  const { isVisible, value, onClose, onSubmit } = props;
  const [submitValue, setSubmitValue] = useState(0);
  const columns = useMemo(
    () => [
      {
        Header: 'Item',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.name}</>;
        },
      },
      {
        Header: 'Purchased',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.quantity}</>;
        },
      },
      {
        Header: 'Reduce',
        accessor: (row: any, rowIndex: any) => {
          return (
            <>
              <Input
                type="number"
                value={submitValue}
                onChange={(data: any) => setSubmitValue(data)}
              ></Input>
            </>
          );
        },
        width: '45%',
      },
      {
        Header: 'User',
        accessor: (row: any, rowIndex: any) => {
          return <></>;
        },
      },
    ],
    [],
  );
  return (
    <Portal>
      <Canvas
        isVisible={isVisible}
        onCloseSideBar={onClose}
        onClose={onClose}
        className={'page-sidebar-container-wrap'}
        customStyles={{ width: '420px' }}
      >
        <Canvas.Header title="Reduce a User" />
        <Canvas.Body style={{ height: 'calc(100vh - 130px)', width: '420px' }}>
          <BasicTable columns={columns} data={[value]} className="table mg-b-0 bd-0 settings-tb" />
        </Canvas.Body>
        <Canvas.Footer
          onClose={onClose}
          onSave={() => onSubmit(submitValue)}
          saveColor="danger"
          saveLabel="Reduce"
        />
      </Canvas>
    </Portal>
  );
};
export default ReduceView;
