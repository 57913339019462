import * as keyNames from '../key-names';
import * as components from './components';
import validators from '@base/utils/validation/field-validator';
import {
  TICKET_FORM_LINK_TYPE_LANDING_PAGE,
  TICKET_FORM_SUBMISSION_DISPLAY_MESSAGE,
} from '../constants';

export default {
  [keyNames.KEY_TICKET_FORM_NAME]: {
    component: components.Input,
    componentProps: {
      menu: 'text',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_TICKET_FORM_LANGUAGE]: {
    component: components.LanguageSelect,
    componentProps: {},
    showFullRow: true,
    defaultValue: 'en',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_TICKET_FORM_PRODUCTS]: {
    component: components.ProductAutoComplete,
    validate: {},
    defaultValue: null,
    showFullRow: true,
    parseParam: (value: any) => {
      return value?.length > 0
        ? value?.map((v: any) => {
            return {
              id: v?.id,
              name: v?.name,
            };
          })
        : [];
    },
  },
  [keyNames.KEY_TICKET_FORM_DESCRIPTION]: {
    component: components.TextArea,
    validate: {},
    defaultValue: '',
    showFullRow: true,
    //parseValue: (valueApi: any) => valueApi || [],
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_TICKET_FORM_TEMPLATE]: {
    component: components.TemplateSelect,
    componentProps: {},
    showFullRow: true,
    validate: {},
    defaultValue: null,
    parseParam: (value: any) => {
      if (value && value?.id) {
        return {
          id: value?.id,
          name: value?.name,
        };
      } else {
        return null;
      }
    },
  },
  [keyNames.KEY_TICKET_FORM_TITLE]: {
    component: components.Input,
    componentProps: {
      type: 'text',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_TICKET_FORM_HTML]: {
    component: components.GrapesTS,
    componentProps: {
      height: 'calc(100vh - 70px)',
      storageId: 'landingpage-gts',
      templateType: 'page',
    },
    showFullRow: true,
    validate: {},
    defaultValue: null,
    parseParam: (value: any) => {
      return JSON.stringify(value);
    },
  },
  [keyNames.KEY_TICKET_FORM_SUBMISSION_DISPLAY]: {
    component: components.SubmissionDisplay,
    showFullRow: true,
    defaultValue: {
      submissionDisplay: TICKET_FORM_SUBMISSION_DISPLAY_MESSAGE,
      displayMessage: '',
      linkToPage: '',
      linkToType: TICKET_FORM_LINK_TYPE_LANDING_PAGE,
      linkToResource: null,
    },
  },
  [keyNames.KEY_TICKET_FORM_SUBMISSION_BEHAVIOR]: {
    component: components.SubmissionBehavior,
    showFullRow: true,
    defaultValue: {
      createTicket: true,
      ticketName: '',
    },
  },
};
