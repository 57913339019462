import React, { lazy, useCallback, useEffect, useState } from 'react';
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { MENU_SETTING_SITE } from '@base/config/menus';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayout from '@settings/site/config/display-layout';
import displayLayoutConfig from '@settings/site/config/display-layout';

import '@settings/site/assets/scss/style.scss';
import { mainIcons } from '@base/assets/icons/svg-icons';
import { WRITE_OPTION_ATOM } from '../config/pages/write-page';
import { ListContainer } from '@base/components/list';
import { keys } from 'lodash';
import { ChevronDown } from 'react-feather';
import classNames from 'classnames';
import { FormIcon, SpanLang } from '@base/components/form';
const EmptyComponent = lazy(() => import('@base/components/empty'));
import { MAIN_MENU, TabConentPage, WritePage } from '@settings/site/config/pages/main-page';
import { useLanguageByMenu } from '@base/services/i18n';
import { ListType } from '@base/types/enums';

const getPreviousUrl = (params: any, pageData: any): string => {
  let prePath = '';
  // prePath empty in case params same with recoil data
  if (
    params.activeMenu == pageData.activeMenu &&
    params.activeId == pageData.activeId &&
    params.activeTab == pageData.activeTab
  ) {
    return prePath;
  }
  if (params.activeMenu == '' && pageData.activeMenu && pageData.activeMenu != '') {
    prePath = `/settings/site/${pageData.activeMenu}`;
  }
  if (
    prePath != '' &&
    params.activeId == '' &&
    pageData.activeMenu &&
    pageData.activeMenu != '' &&
    pageData.activeId &&
    pageData.activeId != ''
  ) {
    prePath = `${prePath}/${pageData.activeId}`;
  }

  return prePath;
};

const MainPage: React.FC = () => {
  // recoil
  const { isDesktop, device, layout } = useRecoilValue(deviceAtom);
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_SETTING_SITE));
  const [writeOption, setWriteOption] = useRecoilState(WRITE_OPTION_ATOM);
  useLanguageByMenu([MAIN_MENU]);

  // const
  const navigate = useNavigate();
  const params = useParams();

  const ADD_OPTIONS: { [index: string]: any } = {
    ['desk']: {
      name: 'Desk',
      icon: mainIcons?.desk,
    },
  };

  const tabs = [
    {
      label: 'Desk',
      path: 'desk',
      component: TabConentPage,
      componentProps: {
        category: 'desk',
      },
    },
  ];

  const { activeId, activeTab } = pageData;
  const paramsArr = params['*']?.split('/') || [];
  const currentTab = paramsArr[0] && activeTab != paramsArr[0] ? paramsArr[0] : activeTab;

  // state
  const [tab, setTab] = useState<any>(null);
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const toggleTab = useCallback((tab: any) => {
    setTab(tab);
  }, []);

  useEffect(() => {
    if (!currentTab || currentTab == '') {
      // default tab
      navigate(`/settings/site/desk`);
    } else {
      toggleTab(tabs?.find((v: any) => v.path === currentTab));
    }
  }, [currentTab]);

  const onAdd = (category: string) => {
    setWriteOption((filter) => ({
      ...filter,
      writeType: category,
      isOpenWrite: true,
    }));
  };

  return (
    <>
      <ListContainer isLoading={false} isSplitMode={false} isDesktop={isDesktop}>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={
            <div className="list-header">
              <div className="d-flex align-items-center mg-r-10 mg-b-5">
                <h1 className="list-header-title" style={{ maxWidth: '100%' }}>
                  {`Sites`}
                </h1>
              </div>
              <div className="d-flex align-items-center mg-l-auto mg-b-5">
                <div className="btn-group dropdown">
                  {ADD_OPTIONS && keys(ADD_OPTIONS).length > 0 && (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle-split pd-x-5"
                        data-toggle="dropdown"
                      >
                        {`New`} <ChevronDown />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        {keys(ADD_OPTIONS).map((key: string, i: number) => (
                          <button
                            key={i}
                            type="button"
                            className="dropdown-item"
                            onClick={() => onAdd(key)}
                          >
                            {ADD_OPTIONS[key]?.icon ? ADD_OPTIONS[key]?.icon : ''}{' '}
                            {ADD_OPTIONS[key]?.name}
                          </button>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          }
          // header={}
          body={
            <div
              className={classNames('list-body scroll-box-1', '')}
              style={{ height: 'calc(100vh - 170px)', overflow: 'hidden' }}
            >
              <div className="d-flex align-items-start bg-white bd-b pd-t-8">
                <div className="view-tabs flex-grow-1">
                  <ul className="nav nav-tabs flex-grow-1 bd-0">
                    {tabs.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={classNames('nav-item', {
                            active: item?.path == tab?.path,
                          })}
                        >
                          <button
                            type="button"
                            className="btn btn-xs btn-move-tab"
                            aria-label="Drag-and-drop to reorder"
                          >
                            <FormIcon icon="move" iconType="icon" />
                            <span className="sr-only">드래그앤드롭으로 이동</span>
                          </button>
                          <Link
                            to={`${item.path}`}
                            className="btn nav-link"
                            onClick={(e: any) => {
                              toggleTab(item);
                            }}
                          >
                            {item?.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div style={{ height: 'calc(100vh - 170px)' }}>
                <Routes>
                  {tabs.length
                    ? tabs.map((tab: any) => {
                        const TabComponent = tab?.component ?? EmptyComponent;
                        const tabComponentProps = tab?.componentProps ?? {};
                        return (
                          <React.Fragment key={`${tab?.path}`}>
                            {
                              <Route
                                path={`${tab?.path}/*`} // view detail on tabs
                                element={
                                  <React.Suspense fallback={<></>}>
                                    <TabComponent {...tabComponentProps} />
                                  </React.Suspense>
                                }
                              />
                            }
                          </React.Fragment>
                        );
                      })
                    : ''}
                </Routes>
              </div>
            </div>
          }
        />

        {/* Tablet layout here */}
        {/* {isTablet && null} */}

        {/* Mobile layout here */}
        {/* {isMobile && null} */}
      </ListContainer>

      {writeOption.isOpenWrite && (
        <WritePage
          title={<SpanLang keyLang={'Create New Desk Site'} />}
          size="xl"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() => {
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }));
          }}
          onReload={() => {}}
          //menuApi={writeCategory}
          id={''}
        />
      )}
    </>
  );
};

export default MainPage;
