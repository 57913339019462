import { Input, Select, TextArea } from '@base/components/form';
import LandingPageAutoComplete from '@settings/online-digital-content/landing-page/containers/landing-page-auto-complete';
import SurveyAutoComplete from '@settings/online-digital-content/survey/containers/survey-auto-complete';
import React from 'react';
import {
  TICKET_FORM_LINK_TYPES,
  TICKET_FORM_LINK_TYPE_LANDING_PAGE,
  TICKET_FORM_LINK_TYPE_SURVEY,
  TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_PAGE,
  TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_RESOURCE,
  TICKET_FORM_SUBMISSION_DISPLAY_MESSAGE,
} from '../../config/constants';

const SubmissionDisplay: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  const handleDisplayChange = (newValue: string) => {
    onChange && onChange({ ...value, submissionDisplay: newValue });
  };

  const handleMessageChange = (newValue: string) => {
    onChange && onChange({ ...value, displayMessage: newValue });
  };

  const handleLinkToPageChange = (newValue: string) => {
    onChange && onChange({ ...value, linkToPage: newValue });
  };

  const handleLinkToTypeChange = (newValue: any) => {
    onChange && onChange({ ...value, linkToType: newValue?.value ?? '', linkToResource: null });
  };

  const handleLinkToResourceChange = (newValue: any) => {
    onChange && onChange({ ...value, linkToResource: newValue });
  };

  return (
    <div className="">
      {/* <label className="form-item-title">
        What should display after a visitor submits this form
      </label> */}
      <div className="custom-control custom-radio mg-b-10">
        <input
          type="radio"
          id="displayOptionRadio1"
          name="displayOptionRadio"
          className="custom-control-input"
          checked={value?.submissionDisplay === TICKET_FORM_SUBMISSION_DISPLAY_MESSAGE}
          onChange={() => {
            handleDisplayChange(TICKET_FORM_SUBMISSION_DISPLAY_MESSAGE);
          }}
        />
        <label className="custom-control-label" htmlFor="displayOptionRadio1">
          Thank you message
        </label>
      </div>
      <TextArea
        rows={3}
        cols={10}
        className="form-control"
        value={value?.displayMessage ?? ''}
        onChange={handleMessageChange}
      ></TextArea>

      <div className="custom-control custom-radio mg-b-10 mg-t-10">
        <input
          type="radio"
          id="displayOptionRadio2"
          name="displayOptionRadio"
          className="custom-control-input"
          checked={value?.submissionDisplay === TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_PAGE}
          onChange={() => {
            handleDisplayChange(TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_PAGE);
          }}
        />
        <label className="custom-control-label" htmlFor="displayOptionRadio2">
          Redirect to another page.
        </label>
      </div>
      <Input
        type="url"
        className="form-control"
        onChange={handleLinkToPageChange}
        value={value?.linkToPage ?? ''}
      />

      <div className="d-flex mg-t-10 align-items-center">
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="displayOptionRadio3"
            name="displayOptionRadio"
            className="custom-control-input"
            checked={value?.submissionDisplay === TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_RESOURCE}
            onChange={() => {
              handleDisplayChange(TICKET_FORM_SUBMISSION_DISPLAY_LINK_TO_RESOURCE);
            }}
          />
          <label className="custom-control-label" htmlFor="displayOptionRadio3">
            Link
          </label>
        </div>
        <Select
          width={250}
          className={'mg-l-10'}
          options={TICKET_FORM_LINK_TYPES}
          value={TICKET_FORM_LINK_TYPES?.find((v: any) => v.value == value?.linkToType)}
          onChange={handleLinkToTypeChange}
        />
      </div>
      {value?.linkToType === TICKET_FORM_LINK_TYPE_LANDING_PAGE && (
        <LandingPageAutoComplete
          className={'mg-t-10'}
          single={true}
          value={value?.linkToResource ?? null}
          onChange={handleLinkToResourceChange}
        />
      )}
      {value?.linkToType === TICKET_FORM_LINK_TYPE_SURVEY && (
        <SurveyAutoComplete
          className={'mg-t-10'}
          single={true}
          value={value?.linkToResource ?? null}
          onChange={handleLinkToResourceChange}
        />
      )}
    </div>
  );
};
export default SubmissionDisplay;
