import LicenseGuide from '@settings/billing-license/containers/license/license-guide';
import LicenseUsed from '@settings/billing-license/containers/license/license-used';
import LicenseStorageAndRecharge from '@settings/billing-license/containers/license/storage-and-recharge';
import React, { useState } from 'react';

const License: React.FC = () => {
  const [submitValue, setSubmitValue] = useState({});
  const onChange = (keyName: string, keyValue: string) => {
    setSubmitValue({ ...submitValue, [keyName]: keyValue });
    // console.log(keyName, keyValue);
  };
  return (
    <div className="scroll-box pd-20" style={{height:"calc(100vh - 150px)"}}>
      {/* Licenses Used */}
      <LicenseUsed />

      {/* Online Storage Used AND Current Recharge Status*/}
      <LicenseStorageAndRecharge />

      {/* Licensing Guide */}
      <LicenseGuide />
    </div>
  );
};
export default License;
