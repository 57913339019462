import React from 'react';
import { useTranslation } from 'react-i18next';
import QuestionIcon from '../../symbol';

//render question options
const OptionGridView: React.FC<any> = (props) => {
  //props
  const {
    type = '',
    optionsQ = [],
  } = props;
  const { t } = useTranslation();
  //state

  /** ================================== HANDLER ========================================== */

  /** ================================== RENDER ========================================== */

  //render
  return (
    <div className="survey-write-question-card-list-container pd-t-10">
      <table className="table survey-write-question-grid-table">
        <thead>
          <tr>
            <th scope="col"></th>
            {optionsQ.cols.map((col: any, idx: number) => (
              <th key={idx} scope="col">{col.value ? col.value : (t('Column') + ' ' + (idx + 1))}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {optionsQ.rows.map((row: any, idx: number) => (
            <tr key={idx}>
              <td scope="row">{row.value ? row.value : (t('Row') + ' ' + (idx + 1))}</td>
              {optionsQ.cols.map((col: any, idx: number) => (
                <td key={idx} scope="row"><QuestionIcon type={type} /></td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OptionGridView;