import { lazy } from 'react';

const ProcessFirst = lazy(() => import('./main/MainPage'));

export default [
  {
    path: '/settings/process/*',
    component: ProcessFirst,
  },
];
