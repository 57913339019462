import { SpanLang } from '@base/components';
import Canvas from '@base/components/canvas';
import { Input } from '@base/components/form';
import Portal from '@base/components/portal';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { generateUUID } from '@base/utils/helpers';
import { CREATE_DESK_TAG, UPDATE_DESK_TAG } from '@settings/preferences/services/graphql';
import { IDeskTag } from '@settings/preferences/types/interfaces';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

interface IAddTagsProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (params: any, mode: string) => void;
  data: IDeskTag | null;
}
const AddTags: React.FC<IAddTagsProps> = (props: IAddTagsProps) => {
  const { isOpen, onClose, onSave, data } = props;
  const [value, setValue] = useState(data?.id ? data.name : '');
  const mAdd: any = useMutationPost<BaseMutationResponse>(CREATE_DESK_TAG, 'desk_createTag');
  const mUpdate: any = useMutationPost<BaseMutationResponse>(UPDATE_DESK_TAG, 'desk_updateTag');

  const handleSaveChange = () => {
    if (value != '') {
      if (data?.id) {
        mUpdate.mutate(
          {
            tag: {
              id: data.id,
              name: value,
            },
          },
          {
            onSuccess: (res: any) => {
              toast.success('Data was saved!');
              onSave && onSave([{ ...data, name: value }], 'update');
            },
          },
        );
      } else {
        let tags: IDeskTag[] = [];
        const arVals = value.split(',');
        arVals.map((tag) => {
          if (tag != '') {
            tags.push({
              id: generateUUID(),
              name: tag,
              linkedTickets: 0,
              linkedArticles: 0,
            });
          }
        });
        if (tags.length > 0) {
          tags.map((tag) => {
            mAdd.mutate(
              { tag },
              {
                onSuccess: (res: any) => {
                  toast.success('Data was saved!');
                  onSave && onSave([{ ...data, name: value }], 'update');
                },
              },
            );
          });

          onSave && onSave(tags, 'add');
        }
      }
    }
  };

  return (
    <>
      <Portal>
        <Canvas
          isVisible={isOpen}
          onCloseSideBar={onClose}
          onClose={onClose}
          className={'page-sidebar-container-wrap wd-500'}
          customStyles={{ zIndex: '1052' }}
          backdropStyles={{ zIndex: '1051' }}
        >
          <Canvas.Header
            title={data?.id ? <SpanLang keyLang="Edit Tags" /> : <SpanLang keyLang="Add Tags" />}
          />
          <Canvas.Body style={{ height: 'calc(100vh - 130px)' }}>
            <div className="form-group">
              <label className="form-item-title">Tags</label>
              <Input
                type="text"
                placeholder={data?.id ? 'Enter Tag Name' : 'Seperate with ,'}
                value={value}
                onChange={(nVal: string) => {
                  setValue(nVal);
                }}
              />
            </div>
          </Canvas.Body>
          <Canvas.Footer onClose={onClose} onSave={handleSaveChange} saveColor="success" />
        </Canvas>
      </Portal>
    </>
  );
};

export default AddTags;
