import React, { useState } from 'react';
import classNames from 'classnames';
import { X, Layout, Check, Image } from 'react-feather';
// import Loading from '@base/components/loading';
import { SpanLang } from '@base/components';

//Constant COLORS
const THEME_COLORS: string[] = [
  '#673ab7',
  '#db4437',
  '#3f51b5',
  '#4285f4',
  '#03a9f4',
  '#00bcd4',
  '#ff5722',
  '#ff9800',
  '#009688',
  '#4caf50',
  '#607d8b',
  '#9e9e9e',
];
const BG_COLORS: any = {
  '#673ab7': ['#f0ebf8', '#e1d8f1', '#d1c4e9', '#f6f6f6'],
  '#db4437': ['#fae3e1', '#f6d0cd', '#f2beb9', '#f6f6f6'],
  '#3f51b5': ['#eceef8', '#d9dcf0', '#c5cbe9', '#f6f6f6'],
  '#4285f4': ['#e3edfd', '#d0e1fc', '#bdd4fb', '#f6f6f6'],
  '#03a9f4': ['#d9f2fd', '#c0eafc', '#a7e1fb', '#f6f6f6'],
  '#00bcd4': ['#d9f5f9', '#bfeef4', '#a6e8f0', '#f6f6f6'],
  '#ff5722': ['#ffe6de', '#ffd5c8', '#ffc4b2', '#f6f6f6'],
  '#ff9800': ['#fff0d9', '#ffe5bf', '#ffdba6', '#f6f6f6'],
  '#009688': ['#d9efed', '#bfe5e1', '#a6dad5', '#f6f6f6'],
  '#4caf50': ['#e4f3e5', '#d2ebd3', '#c0e3c2', '#f6f6f6'],
  '#607d8b': ['#e7ecee', '#d7dfe2', '#c7d2d6', '#f6f6f6'],
  '#9e9e9e': ['#f0f0f0', '#e7e7e7', '#dddddd', '#f6f6f6'],
};

/**
 *
 * @param {*} props
 */
const QuestionTheme: React.FC<any> = (props) => {
  const {
    //surveyId,
    closeCanvas,
    headerImg,
    themeColor,
    bgColor,
    onSetSurveyHeaderImg,
    onSetSurveyThemeColor,
    onSetSurveyBgColor,
  } = props;

  //state
  //const [selectedTheme, setSelectedTheme] = useState(THEME_COLORS[0]);
  //const [selectedBg, setSelectedBg] = useState(BG_COLORS['#673ab7'][0]);
  //const [isLoading, setIsLoading] = useState(false);

  //update survey header image
  // function saveTheme(themeColor: any, bgColor: any) {
  //   //if(newPath) {
  //   const params = {
  //     survey_id: surveyId,
  //     theme_color: themeColor,
  //     bg_color: bgColor
  //   };
  //   // myAxios.put(apis.updateSurveyTheme, params).then((res) => {
  //   //     onSetSaving(false);
  //   // })
  //   // .catch(function (error) {
  //   //     //// console.log(error);
  //   // });
  //   //}
  // }

  //change theme color
  function handleChangeThemeColor(color: any) {
    //set selected theme color
    onSetSurveyThemeColor(color, BG_COLORS[color][0]);
    //save changes
    //saveTheme(color, BG_COLORS[color][0]);
  }

  //bg color change
  function handleChangeBgColor(color: any) {
    //set state
    onSetSurveyBgColor(color);
    //save changes
    //saveTheme(themeColor, color);
  }

  //update survey header image
  // function saveHeaderImage(newPath: any) {
  //   //if(newPath) {
  //   const params = {
  //     survey_id: surveyId,
  //     header_image: newPath
  //   };
  //   // myAxios.put(apis.updateSurveyHeaderImage, params).then((res) => {
  //   //     onSetSaving(false);
  //   // })
  //   // .catch(function (error) {
  //   //     //// console.log(error);
  //   // });
  //   //}
  // }

  //upload new file
  function handleFileUploadChange(e: any) {
    //setIsLoading(true);
    //upload file
    const files = e.target.files;
    if (files.length > 0) {
      //call upload
      let formData = new FormData();
      formData.append('file', files[0]);
      // myAxios.post(apis.uploadImage, formData).then((res) => {
      //     setIsLoading(false);
      //     if (res.data.success) {
      //         //update image state
      //         const newFile = {};
      //         newFile.name = res.data.data.name;
      //         newFile.path = apis.getImage + '?path=' + res.data.data.path + res.data.data.name;
      //         //newFile.size = res.data.data.size;
      //         onSetSurveyHeaderImg(newFile.path);
      //         //save changes
      //         saveHeaderImage(newFile.path);
      //     }
      // })
      // .catch(function (error) {
      //     //// console.log(error);
      // });
    }
  }

  //reset header image
  function handleRemoveImage() {
    onSetSurveyHeaderImg(null);
    //save changes
    //saveHeaderImage("");
  }

  //main render
  return (
    <div className="pos-relative">
      <div className="off-canvas-header">
        <Layout className="tx-orange" />
        <div className="tx-16 tx-bold">Theme Options</div>
        <a className="survey-home-header-button pd-r-10" onClick={() => closeCanvas(false)}>
          <X />
        </a>
      </div>
      <div className="pos-relative">
        {/* header image */}
        {/* <div className="d-flex flex-column pd-l-20 pd-r-30 pd-t-30 pd-b-30 bd-b">
          <div>HEADER IMAGE</div>
          {headerImg ?
            <div className="wd-250 ht-50 bd rounded-5 mg-t-15 pd-10">
              <div className="d-flex h-100 align-items-center justify-content-between">
                <Image className="tx-danger" />
                <span>Image Uploaded</span>
                <a className="survey-home-header-button" onClick={handleRemoveImage}>
                  <X className="tx-danger" />
                </a>
              </div>
            </div>
            :
            <div className="custom-file mg-t-15 mg-r-10">
              <input
                type="file"
                accept="image/*"
                className="custom-file-input"
                //id={optionKey}
                onChange={handleFileUploadChange}
              />
              <label className="custom-file-label" htmlFor="customFile">
                <SpanLang keyLang='Choose image' />
              </label>
            </div>
          }
        </div> */}
        {/* theme color */}
        <div className="d-flex flex-column pd-l-20 pd-r-20 pd-t-30 pd-b-30 bd-b">
          <div>THEME COLOR</div>
          <div className="d-flex flex-row flex-wrap mg-t-15">
            {THEME_COLORS.map((color: string, index: number) => (
              <div
                key={index}
                className={classNames('rounded-20 mg-10', {
                  'ht-40 wd-40': themeColor === color,
                  'ht-30 wd-30': themeColor !== color,
                })}
                style={{ backgroundColor: color }}
                onClick={() => handleChangeThemeColor(color)}
              >
                <div className="d-flex h-100 align-items-center justify-content-center">
                  {themeColor === color && <Check className="tx-white" />}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* background color */}
        <div className="d-flex flex-column pd-l-20 pd-r-20 pd-t-30 pd-b-30 bd-b">
          <div>BACKGROUND COLOR</div>
          <div className="d-flex flex-row flex-wrap mg-t-15">
            {BG_COLORS[themeColor].map((color: string, index: number) => (
              <div
                key={index}
                className={classNames('rounded-20 mg-10 bd', {
                  'ht-40 wd-40': bgColor === color,
                  'ht-30 wd-30': bgColor !== color,
                })}
                style={{ backgroundColor: color }}
                onClick={() => handleChangeBgColor(color)}
              >
                <div className="d-flex h-100 align-items-center justify-content-center">
                  {bgColor === color && <Check className="tx-black" />}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionTheme;
