import { FieldType } from './../types/diagram';
import { useEffect, useMemo, useState } from 'react';
import { DefinedTrigger } from '@settings/process/types/process';
import { GET_DEFINED_TRIGGER } from '@settings/process/services/custom';
import { useGetDefinedItems } from '@settings/process/hooks/useProcess';
import { OptionValue } from '@base/types/interfaces/common';
import { TriggerType } from '@settings/process/types/diagram';
import { ModuleType } from '@base/types/types/common';
import { PropertyType, ProcessType } from '@settings/process/types/process';
import { IdName } from '@base/types/interfaces/common';

const useTrigger = (
  triggerType?: string,
  filter?: string,
): [DefinedTrigger | undefined, OptionValue[]] => {
  const [defined, setDefined] = useState<DefinedTrigger>();
  const { data: definedTriggers } = useGetDefinedItems(
    GET_DEFINED_TRIGGER,
    'TYPE_TRIGGER',
    'trigger',
    10,
  );

  const triggerOptions = useMemo(() => {
    let options: OptionValue[] = [];
    if (definedTriggers && definedTriggers.results) {
      options = definedTriggers.results.map((trigger) => {
        return {
          keyName: trigger.id,
          languageKey: trigger.name,
          extra: trigger.trigger,
        };
      });
      if (filter == 'wait') {
        options = options.filter(
          (opt) =>
            opt.extra.trigger == 'TRIGGER_TYPE_FIELD_UPDATED' ||
            opt.extra.trigger == 'TRIGGER_TYPE_PROCESS_PROPERTY_UPDATED',
        );
      }
    }
    return options;
  }, [definedTriggers]);

  useEffect(() => {
    if (triggerType != '') {
      const find = triggerOptions.find((trigger) => trigger.keyName == triggerType);
      if (find) {
        setDefined({
          trigger: find.extra.trigger as TriggerType,
          module: find.extra.module as ModuleType,
          field: find.extra.field as string,
          ftype: find.extra.ftype as FieldType,
          ptype: find.extra.ptype as ProcessType,
          process: find.extra.process as IdName,
          step: find.extra.step as IdName,
          property: find.extra.property as PropertyType,
        });
      }
    }
  }, [triggerType, triggerOptions]);

  return [defined, triggerOptions];
};

export default useTrigger;
