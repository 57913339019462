import React from 'react';
import Copyable from '../copyable';

interface IItemsProps {
  id: string;
  items: any[];
  isCenter?: boolean;
  isLoading?: boolean;
  isAddCustomField: boolean;
}
const Items: React.FC<IItemsProps> = (props: IItemsProps) => {
  const { id, items, isCenter = true, isLoading = false, isAddCustomField = true } = props;
  return (
    <Copyable
      droppableId={id}
      className="shop"
      items={items}
      isCenter={isCenter}
      isLoading={isLoading}
      isAddCustomField={isAddCustomField}
    />
  );
};

export default Items;
