import React, { forwardRef } from 'react';
import classNames from 'classnames';
import './styles.scss';
import { FormIcon } from '@base/components/form';
import { IconType } from '@base/types/types/app';

interface IItemManagementProps {
  className?: string;
  isLoading: boolean;
  isActive?: boolean;
  icon?: string;
  iconType?: IconType;
  label: string;
  isBadge?: boolean;
  badge?: string;
  isEdit?: boolean;
  onEdit?: () => void;
  isDelete?: boolean;
  onDelete?: () => void;
  onClick?: () => void;
}

const ItemManagement = (props: IItemManagementProps, ref: any) => {
  const {
    className = '',
    isLoading = false,
    isActive = false,
    icon = '',
    iconType = undefined,
    label = '',
    isBadge = '',
    badge = '',
    isEdit = false,
    onEdit = () => {},
    isDelete = false,
    onDelete = () => {},
    onClick = () => {},
    ...attrs
  } = props;
  if (isLoading) {
    return (
      <a
        className={classNames(`nav-link ${className} navata-placeholder`)}
        style={{ height: 33 }}
      />
    );
  }
  return (
    <a
      ref={ref}
      className={classNames(`navata-manage-item nav-link ${className}`, {
        active: isActive,
      })}
      onClick={(e) => onClick()}
      style={{ height: 33 }}
      {...attrs}
    >
      <FormIcon icon={icon} iconType={iconType} />
      <span>{label}</span>
      <span className="badge">
        {isBadge && badge}
        {isEdit && (
          <span
            className="btn-edit mg-r-5"
            onClick={(e) => {
              e.preventDefault();
              onEdit();
            }}
          >
            <FormIcon icon="Edit3" iconType="feather" size={16} />
          </span>
        )}
        {isDelete && (
          <span
            className="btn-delete"
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
          >
            <FormIcon icon="Trash2" iconType="feather" size={16} />
          </span>
        )}
      </span>
    </a>
  );
};

export default forwardRef(ItemManagement);
