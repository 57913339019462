import { Button } from '@base/components/form';
import ListLoading, { TableNothing } from '@base/components/list/list-loading';
import ListReactTable from '@base/components/list/list-react-table';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { ListPageConfig } from '@settings/assignment-rule/config/pages/list-page';
import {
  CREATE_ASSIGNMENT_RULE_ASSIGN_TO,
  DELETE_ASSIGNMENT_RULE_ASSIGN_TO,
} from '@settings/assignment-rule/services/graphql';
import { IARAssignToItem } from '@settings/assignment-rule/types/interfaces';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import AssignToAdd from './add';
import { ruleEntriesData } from './dummy-data';

interface IAssingToProps {
  value: IARAssignToItem[];
  onChange: (nVal: any) => void;
  menuSourceId?: string;
}

const AssingTo: React.FC<IAssingToProps> = (props: IAssingToProps) => {
  let { menuSourceId = '', value, onChange } = props;
  value = ruleEntriesData;
  const [items, setItems] = useState<IARAssignToItem[]>(value ?? []);
  const [isAdd, setIsAdd] = useState(false);
  const mAddAssign: any = useMutationPost<BaseMutationResponse>(
    CREATE_ASSIGNMENT_RULE_ASSIGN_TO,
    'setting_createAssignTo',
    {},
  );
  const mDeleteAssign: any = useMutationPost<BaseMutationResponse>(
    DELETE_ASSIGNMENT_RULE_ASSIGN_TO,
    'setting_deleteAssignTo',
    {},
  );
  const onAfterAdded = (aItems: IARAssignToItem[]) => {
    // console.log('onAfterAdded', aItems);
    if (menuSourceId != '') {
      mAddAssign.mutate(
        { id: menuSourceId, assignsTo: aItems },
        {
          onSuccess: (res: any) => {
            toast.success('Data has added!');
            let nItems = items;
            nItems.push(...aItems);
            setItems(nItems);
            onChange && onChange(nItems);
          },
        },
      );
    } else {
      let nItems = items;
      nItems.push(...aItems);
      setItems(nItems);
      onChange && onChange(nItems);
    }
  };
  const onAfterDeleted = (nItem: IARAssignToItem) => {
    // console.log('onAfterDeleted', nItem);
    let nItems = items.filter((item) => {
      return item.id != nItem.id;
    });
    if (menuSourceId != '') {
      mDeleteAssign.mutate(
        { id: menuSourceId, assignToId: nItem.id },
        {
          onSuccess: (res: any) => {
            toast.success('Data has deleted!');
            setItems(nItems);
            onChange && onChange(nItems);
          },
        },
      );
    } else {
      setItems(nItems);
      onChange && onChange(nItems);
    }
  };
  const tableProps: any = {
    nodata: <TableNothing />,
    data: items,
    isFetching: false,
    fetching: <ListLoading />,
    columns: makeTableColumns(
      ListPageConfig.assignToFields,
      ListPageConfig.getAssignToColumnRenderRemap({ onAfterDeleted }),
      {},
      [],
    ),
    isCheckboxTable: false,
  };
  const onAdd = () => {
    setIsAdd(true);
  };
  const onClose = () => {
    setIsAdd(false);
  };

  // console.log('initItems', items);
  return (
    <>
      <ListReactTable {...tableProps} />
      <Button
        color="link"
        icon="Plus"
        iconClass="mg-r-5"
        name="Add"
        className="pd-x-0"
        onClick={onAdd}
      />
      {isAdd && <AssignToAdd isOpen={isAdd} onClose={onClose} onAfterAdded={onAfterAdded} />}
    </>
  );
};

export default AssingTo;
