import { Select } from '@base/components/form';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Write from './write';

const LandingPageTypeSelect: React.FC<any> = (props: any) => {
  const { className, value, options, onChange, single = true } = props;

  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [showAdd, setShowAdd] = useState<boolean>(false);

  useEffect(() => {
    if (value && JSON.stringify(value) != JSON.stringify(selectedValue)) {
      setSelectedValue(value);
    }
  }, [value]);

  const handleSelectChange = (newValue: any) => {
    setSelectedValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: '250px' }}>
      <Select
        isMulti={!single}
        outSide={false}
        noOptionsMessage={() => 'No data found.'}
        placeholder={<div className="react-select-placeholder">{'Click to select'}</div>}
        isClearable
        isSearchable={false}
        isLoading={false}
        value={selectedValue}
        options={options}
        onChange={handleSelectChange}
        // addLabel="Add new type"
        // onAdd={() => {
        //   setShowAdd(true);
        // }}
      />
      {showAdd && (
        <Write
          isShow={showAdd}
          onClose={() => setShowAdd(false)}
          onAdd={(item: any) => handleSelectChange(item)}
        />
      )}
    </div>
  );
};

export default LandingPageTypeSelect;
