import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Button } from '@base/components/form';
import SmsInfo from '@settings/preferences/components/sms-info';
import { NoData } from '@base/components';
import { UserAutoComplete } from '@base/containers';
import useMutationPost from '@base/hooks/useMutationPost';
import { UPDATE_MENU_SETTING } from '@settings/general-setting/services/graphql/format';
import { useSetRecoilState } from 'recoil';
import { savingPreferencesAtom } from '@settings/preferences/recoil/atoms';

const ActivitySMSTerm: React.FC<any> = (props) => {
  const { phoneData, mobileData } = props;
  //state
  const setSavingOption = useSetRecoilState(savingPreferencesAtom);
  const [smsType, setSmsType] = useState<any>({ company: true, individual: false });
  const [showSMSInfo, setShowSMSInfo] = useState(false);
  const [phoneItems, setPhoneItems] = useState<any>([]); //{ number: '123456', description: 'a', isDefault: false }
  const [debPhoneItems, setDebPhoneItems] = useState<any>([]);
  const setPhoneItemsDebounced = useRef(
    _.debounce((newItems) => setDebPhoneItems(newItems), 1000),
  ).current;
  const [mobileItems, setMobileItems] = useState<any>([]); //{ number: '123456', user: { id: '1w', name: 'MSR', value: '1w', label: 'MSR' } }
  const [debMobileItems, setDebMobileItems] = useState<any>([]);
  const setMobileItemsDebounced = useRef(
    _.debounce((newItems) => setDebMobileItems(newItems), 1000),
  ).current;
  const defaultPhoneValue: any = { number: '', description: '', isDefault: false };
  const defaultMobileValue: any = { user: null, number: '' };
  const initialPhoneLoad = useRef<any>(false);
  const initialMobileLoad = useRef<any>(false);

  //initial data
  useEffect(() => {
    if (phoneData) {
      if (JSON.stringify(phoneData) !== JSON.stringify(phoneItems)) {
        setPhoneItems(phoneData);
      }
    } else {
      setPhoneItems([]);
    }
  }, [phoneData]);

  //initial data
  useEffect(() => {
    if (mobileData) {
      if (JSON.stringify(mobileData) !== JSON.stringify(mobileItems)) {
        setMobileItems(mobileData);
      }
    } else {
      setMobileItems([]);
    }
  }, [mobileData]);

  //mutation
  const mUpdate: any = useMutationPost(UPDATE_MENU_SETTING, 'setting_updateMenuSetting');

  //set saving option
  useEffect(() => {
    setSavingOption((curOption: any) => ({ ...curOption, isLoading: mUpdate.isLoading }));
  }, [mUpdate.isLoading]);

  //debounced saving for input text
  useEffect(() => {
    if (initialPhoneLoad?.current) {
      handleSaveDebounce('company_phones', debPhoneItems);
    } else {
      initialPhoneLoad.current = true;
    }
    () => {
      initialPhoneLoad.current = false;
    };
  }, [debPhoneItems]);

  useEffect(() => {
    if (initialMobileLoad?.current) {
      handleSaveDebounce('personal_phones', debMobileItems);
    } else {
      initialMobileLoad.current = true;
    }
    () => {
      initialMobileLoad.current = false;
    };
  }, [debMobileItems]);

  //save item
  const handleSave = (key: string, newItems: any) => {
    //const newItems = emailType.email ? [...emailItems] : [...domainItems];
    const params: any = {
      menu: 'activity',
      key: key, //smsType.company ? 'company_phones' : 'personal_phones',
      value: JSON.stringify(newItems),
    };
    mUpdate.mutate({ menuSetting: params });
  };
  //debounce function
  const handleSaveDebounce = useCallback(_.debounce(handleSave, 500), [phoneItems, mobileItems]);

  //add new
  const handleAdd = () => {
    const newItems = smsType.company ? [...phoneItems] : [...mobileItems];
    if (smsType.company) {
      newItems.push(defaultPhoneValue);
      setPhoneItems(newItems);
    } else {
      newItems.push(defaultMobileValue);
      setMobileItems(newItems);
    }
    //save changes
    const key = smsType.company ? 'company_phones' : 'personal_phones';
    handleSaveDebounce(key, newItems);
  };

  //remove item
  const handlePhoneRemove = (index: number) => {
    const newItems = [...phoneItems];
    newItems.splice(index, 1);
    setPhoneItems(newItems);
    //save changes
    handleSaveDebounce('company_phones', newItems);
  };

  const handleMobileRemove = (index: number) => {
    const newItems = [...mobileItems];
    newItems.splice(index, 1);
    setMobileItems(newItems);
    //save changes
    handleSaveDebounce('personal_phones', newItems);
  };

  //value change
  const handlePhoneValueChange = (index: number, keyName: string, keyValue: string) => {
    const newItems = [...phoneItems];
    newItems[index][keyName] = keyValue;
    if (keyName === 'isDefault') {
      //reset other to false
      newItems.map((_item: any, _index: number) => {
        if (index !== _index) {
          _item.isDefault = false;
        }
      });
    }
    setPhoneItems(newItems);
    setPhoneItemsDebounced(newItems);
  };

  const handleMobileValueChange = (index: number, keyName: string, keyValue: any) => {
    const newItems = [...mobileItems];
    newItems[index][keyName] = keyValue;
    setMobileItems(newItems);
    setMobileItemsDebounced(newItems);
  };

  //phones list
  const CompanyPhones = useMemo(() => {
    //// console.log('phone items', phoneItems);
    return (
      <>
        {phoneItems.length === 0 && <NoData />}
        {phoneItems.length > 0 && (
          <div className="table-responsive bd bd-t-0 rounded">
            <table className="table table-bordered mg-b-0 bd-0 hover-delete">
              <thead>
                <tr>
                  <th scope="col" className="text-nowrap">
                    Phone Number
                  </th>
                  <th scope="col" className="text-nowrap">
                    Description
                  </th>
                  <th scope="col" className="text-nowrap">
                    Default
                  </th>
                </tr>
              </thead>
              <tbody>
                {phoneItems.map((_item: any, index: number) => (
                  <tr key={index}>
                    <td className="align-middle">
                      {/* {_item.id === selectedPhone?.id || _item.id === '' ? */}
                      <input
                        type={'number'}
                        className="form-control ht-38"
                        value={_item.number}
                        onChange={(e) => handlePhoneValueChange(index, 'number', e.target.value)}
                      />
                      {/* : */}
                      {/* <span>{_item.number}</span> */}
                      {/* } */}
                    </td>
                    <td className="align-middle">
                      {/* {_item.id === selectedPhone?.id || _item.id === '' ? */}
                      <input
                        type={'text'}
                        className="form-control ht-38"
                        value={_item.description}
                        onChange={(e) =>
                          handlePhoneValueChange(index, 'description', e.target.value)
                        }
                      />
                      {/* : */}
                      {/* <span>{_item.description}</span> */}
                      {/* } */}
                    </td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center">
                        <div className="custom-control custom-radio mg-l-15">
                          <input
                            type="radio"
                            id={'phoneRadio' + index}
                            name="companyPhoneRadio"
                            className="custom-control-input"
                            checked={_item.isDefault}
                            onChange={(e: any) =>
                              handlePhoneValueChange(index, 'isDefault', e.target.checked)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={'phoneRadio' + index}
                          ></label>
                        </div>
                        <div className="mg-l-auto wd-50">
                          {/* {_item.id === selectedPhone?.id || _item.id === '' ?
                            <>
                              <Button
                                color=""
                                icon="Check"
                                iconClass="tx-primary"
                                className="btn-icon btn-delete-row han-tooltip--top"
                                data-han-tooltip="Save"
                              //onClick={() => handlePhoneSave(index)}
                              />
                              {_item.id === selectedPhone?.id &&
                                <Button
                                  color=""
                                  icon="RefreshCw"
                                  iconClass="tx-secondary"
                                  className="btn-icon btn-delete-row han-tooltip--top"
                                  data-han-tooltip="Cancel"
                                  onClick={() => setSelectedPhone(null)}
                                />
                              }
                            </>
                            :
                            <Button
                              color=""
                              icon="Edit2"
                              iconClass="tx-secondary"
                              className="btn-icon btn-delete-row han-tooltip--top"
                              data-han-tooltip="Edit"
                              onClick={() => setSelectedPhone(_item)}
                            />
                          } */}
                          {/* {(selectedPhone === null || _item.id === '') && */}
                          <Button
                            color=""
                            icon="Trash"
                            iconClass="tx-danger"
                            className="btn-icon btn-delete-row han-tooltip--top"
                            data-han-tooltip="삭제"
                            onClick={() => handlePhoneRemove(index)}
                          />
                          {/* } */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }, [phoneItems]);

  //mobile list
  const IndividualMobiles = useMemo(() => {
    //// console.log('mobile items', mobileItems);
    return (
      <>
        {mobileItems.length === 0 && <NoData />}
        {mobileItems.length > 0 && (
          <div className="table-responsive bd bd-t-0 rounded">
            <table className="table table-bordered mg-b-0 bd-0 hover-delete">
              <thead>
                <tr>
                  <th scope="col" className="text-nowrap">
                    User
                  </th>
                  <th scope="col" className="text-nowrap">
                    Mobile Number
                  </th>
                </tr>
              </thead>
              <tbody>
                {mobileItems.map((_item: any, index: number) => (
                  <tr key={index}>
                    <td className="align-middle">
                      {/* {_item.id === selectedMobile?.id || _item.id === '' ? */}
                      <UserAutoComplete
                        single={true}
                        showAvatar={true}
                        value={_item.user}
                        onChange={(newUser: any) => handleMobileValueChange(index, 'user', newUser)}
                      />
                      {/* : */}
                      {/* <span>{_item.user.name}</span> */}
                      {/* } */}
                    </td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center">
                        {/* {_item.id === selectedMobile?.id || _item.id === '' ? */}
                        <input
                          type={'number'}
                          className="form-control ht-38"
                          value={_item.number}
                          onChange={(e) => handleMobileValueChange(index, 'number', e.target.value)}
                        />
                        {/* : */}
                        {/* <span>{_item.number}</span> */}
                        {/* } */}
                        <div className="mg-l-auto wd-50">
                          {/* {_item.id === selectedMobile?.id || _item.id === '' ?
                            <>
                              <Button
                                color=""
                                icon="Check"
                                iconClass="tx-primary"
                                className="btn-icon btn-delete-row han-tooltip--top"
                                data-han-tooltip="Save"
                              //onClick={() => handleMobileSave(index)}
                              />
                              {_item.id === selectedMobile?.id &&
                                <Button
                                  color=""
                                  icon="RefreshCw"
                                  iconClass="tx-secondary"
                                  className="btn-icon btn-delete-row han-tooltip--top"
                                  data-han-tooltip="Cancel"
                                  onClick={() => setSelectedMobile(null)}
                                />
                              }
                            </>
                            :
                            <Button
                              color=""
                              icon="Edit2"
                              iconClass="tx-secondary"
                              className="btn-icon btn-delete-row han-tooltip--top"
                              data-han-tooltip="Edit"
                              onClick={() => setSelectedMobile(_item)}
                            />
                          } */}
                          {/* {(selectedMobile === null || _item.id === '') && */}
                          <Button
                            color=""
                            icon="Trash"
                            iconClass="tx-danger"
                            className="btn-icon btn-delete-row han-tooltip--top"
                            data-han-tooltip="삭제"
                            onClick={() => handleMobileRemove(index)}
                          />
                          {/* } */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }, [mobileItems]);

  //sms term
  const renderSMSTerm = () => {
    return (
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="d-flex flex-grow-1 card-header-title">
            <div className="d-flex w-40">
              Company / Personal
              <Button
                color="link link-03"
                icon="Info"
                className="btn-icon pd-y-0 lh-1"
                onClick={() => setShowSMSInfo(true)}
              />
            </div>
          </div>
        </div>
        <div className="card-body scroll-box" style={{ maxHeight: 'calc(100vh - 220px)' }}>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <Button
                color=""
                className={`nav-link rounded-0 ${smsType.company ? 'active tx-semibold' : ''}`}
                name="Company Phone Number"
                onClick={() => setSmsType({ company: true })}
              />
            </li>
            <li className="nav-item">
              <Button
                color=""
                className={`nav-link rounded-0 ${smsType.individual ? 'active tx-semibold' : ''}`}
                name="Personal Mobile Number"
                onClick={() => setSmsType({ individual: true })}
              />
            </li>
          </ul>

          {smsType.company && CompanyPhones}
          {smsType.individual && IndividualMobiles}
          <Button
            color="primary"
            icon="Plus"
            iconClass="mg-r-5"
            name="Add"
            className="mg-t-10"
            onClick={handleAdd}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="pos-relative pd-10">
      {renderSMSTerm()}
      {showSMSInfo && <SmsInfo title="SMS Information" onClose={() => setShowSMSInfo(false)} />}
    </div>
  );
};

export default ActivitySMSTerm;
