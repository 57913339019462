import Usage from '@settings/online-digital-content/cta/components/usage';
import ABTest from '@settings/online-digital-content/cta/components/abtest';
import Related from '@settings/online-digital-content/cta/components/related';
import DetailView from '@settings/online-digital-content/cta/components/detail-view';

export const getTabs = () => {
  return [
    // {
    //   order: 1,
    //   default: true,
    //   label: 'Usage',
    //   path: 'usage',
    //   tabComponent: Usage,
    // },
    {
      order: 2,
      default: true,
      label: 'Link',
      path: 'link',
      tabComponent: DetailView,
    },
    // {
    //   order: 3,
    //   label: 'A/B Test',
    //   path: 'abtest',
    //   tabComponent: ABTest,
    // },
    // {
    //   order: 4,
    //   label: 'Related',
    //   path: 'related',
    //   tabComponent: Related,
    // },
  ];
};
