import React from 'react';
import CommonViewField from '@base/containers/view-field/common';

import View from './view';
import Edit from './edit';

const PublishViewField: React.FC = (props: any) => {
  const { keyName, value, menuSource, menuSourceId, userPermission, ...remainProps } = props;

  return (
    <CommonViewField
      keyName={keyName}
      value={value}
      menuSource={menuSource}
      menuSourceId={menuSourceId}
      userPermission={userPermission}
      componentView={View}
      componentEdit={Edit}
      {...remainProps}
    />
  );
};

export default PublishViewField;
