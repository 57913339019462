import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { UploadCloud } from 'react-feather';
import UploaderImage from '@base/components/form/image-uploader';

import image1 from '@base/assets/img/cta-sample-1.png';
import image2 from '@base/assets/img/cta-sample-2.png';
import image3 from '@base/assets/img/cta-sample-3.png';
import image4 from '@base/assets/img/cta-sample-4.png';
import image5 from '@base/assets/img/cta-sample-5.png';
import image6 from '@base/assets/img/cta-sample-6.png';
import { isString } from 'lodash';

const SAMPLE_IMAGES = [
  { name: 'cta-sample-1', image: image1 },
  { name: 'cta-sample-2', image: image2 },
  { name: 'cta-sample-3', image: image3 },
  { name: 'cta-sample-4', image: image4 },
  { name: 'cta-sample-5', image: image5 },
  { name: 'cta-sample-6', image: image6 },
];

const ImageUploadSample: React.FC<any> = (props) => {
  const { value, onChange } = props;

  // state
  const [showButtonSample, setShowButtonSample] = useState<boolean>(true);
  const [curValue, setCurValue] = useState<any>({ name: '', image: null }); //{name: '', image: FILE}
  const fileRef = useRef<any>(null);

  // init
  useEffect(() => {
    if (value) {
      if (value?.name !== curValue.name) {
        setCurValue(value);
      }
    } else {
      setCurValue({ name: '', image: null });
    }
  }, [value]);

  // files select
  const handleFileChange = (files: any) => {
    if (files.length > 0) {
      const newValue = { name: files[0].name, image: files[0] };
      setCurValue(newValue);
      // callback
      onChange && onChange(newValue);
    } else {
      // console.log('...cancleUpload...');
    }
  };

  // sample select
  const handleSampleChange = (item: any) => {
    if (JSON.stringify(item) != JSON.stringify(curValue)) {
      setCurValue(item);
      // callback
      onChange && onChange(item);
    }
  };

  return (
    <>
      <button
        type="button"
        className={classnames('btn btn-outline-primary mg-r-10', { active: !showButtonSample })}
        onClick={() => {
          setShowButtonSample(false);
        }}
      >
        <UploadCloud className="mg-r-5" />
        Upload
      </button>
      <button
        type="button"
        className={classnames('btn btn-outline-info', { active: showButtonSample })}
        onClick={() => {
          setShowButtonSample(true);
        }}
      >
        Sample
      </button>
      {showButtonSample && (
        <div className="cta-sample-list pd-15 ht-250 overflow-auto">
          <div className="mg-b-10">Sample List</div>
          {SAMPLE_IMAGES.map((_item: any, index: number) => (
            <React.Suspense key={index} fallback={<></>}>
              <button
                key={index}
                type="button"
                className={classnames('btn', { active: _item?.name === curValue?.name })}
                onClick={(e: any) => {
                  handleSampleChange(_item);
                }}
              >
                <img src={_item.image} alt={_item.name} />
              </button>
            </React.Suspense>
          ))}
        </div>
      )}

      {!showButtonSample && (
        <UploaderImage
          type={'img'}
          preview={true}
          isSingle={true}
          onChange={(files: any) => {
            handleFileChange(files);
          }}
        />
      )}
    </>
  );
};

export default ImageUploadSample;
