import { IFilterByOption } from '@base/types/interfaces/common';
import { lazy } from 'react';

const UserAutoComplete = lazy(() => import('@base/containers/user-auto-complete'));

export const categoryOptions = {
  ['ticket-form']: 'Ticket Form',
};

export const groupByOptions = [{ label: 'All Forms', value: 'all' }];

export const dateByOptions = [
  { label: 'Created', value: 'createdAt' },
  { label: 'Updated', value: 'updatedAt' },
];

export const filterByOptions: IFilterByOption[] = [
  {
    label: 'Owner',
    value: 'createdBy',
    component: UserAutoComplete,
    componentProps: { single: true },
    getValue: (componentValue: any) => {
      return componentValue?.id ?? '';
    },
  },
];

export const searchFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    defaultValue: '',
  },
];

export const sortsBy = [
  {
    value: 'createdAt',
    label: 'Created At',
  },
  {
    value: 'updatedAt',
    label: 'Updated At',
  },
];
