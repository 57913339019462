import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import NoteAndError from '@base/components/hook-form/error-note';
import Field from '@base/components/hook-form/field';
import { FooterContainer } from '@base/containers/aside-modal';
import { isObject, isUndefined } from 'lodash';

type AsideWriteProps = {
  onSubmit: (formData: any, isNew: boolean) => void;
  defaultValues: any;
  fields: any[];
  isSaving: boolean;
  isReset: boolean;
};

const AsideWrite = (props: AsideWriteProps) => {
  const { onSubmit, defaultValues, fields, isSaving, isReset } = props;

  const {
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });
  // const scType: any = watch(KEY_NAME_SALES_COMMISSION_TYPE);
  useEffect(() => {
    if (isReset) {
      reset(); //back to default values
    }
  }, [isReset]);

  const onFormSubmit = () => {
    handleSubmit((data) => onSubmit(data, false))();
  };

  const onFormNewSubmit = () => {
    handleSubmit((data) => onSubmit(data, true))();
  };

  return (
    <div className="pd-5 overflow-auto" style={{ height: 'calc(100vh - 120px)' }}>
      <div className="bd-b pd-b-10">
        <NoteAndError errors={errors} />
      </div>
      <form className="form">
        <div className="pd-15 form-row">
          {fields.map((_item, _index) => {
            let newComponentProps = { ..._item?.componentProps };
            // if (scType && _item.keyName === KEY_NAME_SALES_COMMISSION_RATE) {
            //   // newComponentProps.kbType = ScType.keyName;
            //   newComponentProps.type = !isUndefined(scType.keyName) ? scType.keyName : scType;
            // }
            return (
              <Field
                key={_index}
                item={{
                  ..._item,
                  componentProps: newComponentProps,
                  columns: _item.columns,
                  hideTitle: _item.hideTitle, //hide title for a specific key name
                }}
                control={control}
                errors={errors}
              />
            );
          })}
        </div>
        <FooterContainer
          isSaving={isSaving}
          isReset={isReset}
          isValid={isValid}
          onSubmit={onFormSubmit}
          onNewSubmit={onFormNewSubmit}
        />
      </form>
    </div>
  );
};

export default AsideWrite;
