import { slideToMapByKey } from '@base/utils/helpers/array-utils';

export const parseFields = (layoutView: any, menuApi: string) => {
  let fields: any[] = [];
  if (layoutView?.data && layoutView?.data[0] && layoutView?.data[0]?.children) {
    if (menuApi == 'na') {
      fields = [...layoutView?.data[0]?.children, ...layoutView?.data[1]?.children];
    } else {
      fields = [...layoutView?.data[0]?.children];
    }
  }
  if (fields.length == 0) {
    return layoutView;
  }
  const mapFields = slideToMapByKey(fields, 'keyName');
  if (mapFields == {}) {
    return layoutView;
  }

  if (menuApi == 'sms') {
    fields = parseFieldsSMS(mapFields, fields);
    let tmp = { ...layoutView };
    let tmpData = [...tmp.data];
    tmpData[0] = { ...tmpData[0], children: fields };
    tmp = { ...tmp, data: tmpData };
    return tmp;
  } else {
    fields = parseFieldsEmail(mapFields, fields);
    let tmp = { ...layoutView };
    let tmpData = [...tmp.data];
    tmpData[0] = { ...tmpData[0], children: fields };
    tmp = { ...tmp, data: tmpData };
    return tmp;
  }
};

export const parseFieldsEmail = (mapFields: any, fields: any) => {
  let newFields: any[] = [];

  let field = { ...mapFields['name'] };
  field.keyName = 'title';
  field.dataType = 'title';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['type'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['language'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'crm_new_menu_product_product';
  field.keyName = 'products';
  field.dataType = 'products';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['description'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'setting_cta_field_basic_createdby';
  field.keyName = 'assignTo';
  field.dataType = 'assignTo';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['stage'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['html'] };
  field.keyName = 'html';
  field.dataType = 'html';
  newFields.push(field);

  return newFields;
};

export const parseFieldsSMS = (mapFields: any, fields: any) => {
  let newFields: any[] = [];

  let field = { ...mapFields['name'] };
  field.keyName = 'title';
  field.dataType = 'title';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['type'] };
  field.languageKey = 'Message Type';
  field.keyName = 'messageType';
  field.dataType = 'messageType';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['type'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['language'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'crm_new_menu_product_product';
  field.keyName = 'products';
  field.dataType = 'products';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['description'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['name'] };
  field.languageKey = 'setting_cta_field_basic_createdby';
  field.keyName = 'assignTo';
  field.dataType = 'assignTo';
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['stage'] };
  field.id = field.keyName;
  newFields.push(field);

  field = { ...mapFields['html'] };
  field.keyName = 'html';
  field.dataType = 'html';
  newFields.push(field);

  return newFields;
};
