import { atom } from 'recoil';
import { DESC, LIST_TABLE_PAGE_SIZE } from '@base/config/constant';
import { ListType } from '@base/types/enums';

export const surveyListFilterState = atom({
  key: 'SurveyListFilterState',
  default: {
    isSplitMode: false,
    activeMenu: 'survey',
    activeId: '',
    activeTab: '',
    listType: ListType.LIST,
    settingColumns: [], //for viewing on table
    filter: {
      /* query definition:  
          ONE PARAMETER: { [keyName]: { value: '', operator: ''} }
            *with operator is : (like), = (equal), ...
          MANY PARAMETERS: { condition: OR | AND, criteria: [ { [keyName]: { value: '', operator: ''} }, ... ] } 
        */
      query: {} as any,
      paging: {
        page: 1,
        size: LIST_TABLE_PAGE_SIZE,
      },
      sort: {
        field: 'createdAt',
        orderBy: DESC,
      },
    } as any,
  },
});
