import { OptionValue } from '@base/types/interfaces/common';
import { Input } from '@base/components/form';
import { DatePicker } from '@base/components/date-picker';
import SelectBox from '@base/containers/view-field/selectbox/edit';
import { useCallback, useMemo } from 'react';
import { useDefinedFields } from '@settings/process/hooks/useProcess';
import dayjs from 'dayjs';

export interface FieldProperty {
  field: OptionValue;
  value: string;
}

interface FieldValueProps {
  module?: string;
  value: FieldProperty;
  onChange: (val: FieldProperty) => void;
}

function FieldValue(props: FieldValueProps) {
  const { module, value: fieldValue, onChange } = props;
  // console.log(fieldValue);
  const { data: definedFields } = useDefinedFields(module);
  const fieldOptions = useMemo(() => {
    let options: OptionValue[] = [];
    if (definedFields && definedFields.results) {
      options = definedFields.results.map((field) => {
        return {
          keyName: field.fieldName,
          languageKey: field.fieldName,
          extra: field.fieldType,
        };
      });
    }
    return options;
  }, [definedFields]);

  const onChangeField = useCallback((option: OptionValue) => {
    onChange && onChange({ ...fieldValue, field: option });
  }, [fieldValue]);

  const onChangeValue = useCallback((newValue: string | number) => {
    if (typeof newValue === 'number') {
      newValue = newValue.toString();
    }
    onChange && onChange({ ...fieldValue, value: newValue });
  }, [fieldValue]);

  const onChangeDate = useCallback((newValue: Date) => {
    const newDate = dayjs(newValue).format('YYYY-MM-DD');
    onChange && onChange({ ...fieldValue, value: newDate });
  }, [fieldValue]);

  // console.log('fieldValue', fieldValue);

  return (
    <div className="d-flex flex-column mg-x-10">
      <label className="form-item-title">Field</label>
      <SelectBox
        value={fieldValue.field}
        onChange={(newValue: OptionValue) => onChangeField(newValue)}
        options={fieldOptions}
        isSearchable={false}
        errors={{}}
      />
      <label className="form-item-title mg-t-10">Value</label>
      {(!fieldValue.field?.extra || fieldValue.field?.extra == 'FIELD_TYPE_TEXT') && (
        <Input
          type="text"
          value={fieldValue.value}
          onChange={(newValue: string) => onChangeValue(newValue)}
        />
      )}
      {fieldValue.field?.extra == 'FIELD_TYPE_DATE' && (
        <DatePicker
          selected={fieldValue.value ? new Date(fieldValue.value) : null}
          onChange={(newValue: Date) => onChangeDate(newValue)}
          startDate={new Date()}
          placement={'bottom-start'}
        />
      )}
      {fieldValue.field?.extra == 'FIELD_TYPE_NUMBER' && (
        <Input
          type="number"
          value={fieldValue.value}
          onChange={(newValue: string) => onChangeValue(newValue)}
        />
      )}
    </div>
  );
}

export default FieldValue;
