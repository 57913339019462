import { SETTING_ONLINE_DIGITAL_MENUS } from '@base/config/route-menus';
import { IFilterByOption } from '@base/types/interfaces/common';
import { slideToObject } from '@base/utils/helpers/array-utils';
import { lazy } from 'react';
import { SETTING_CTA_CONTENT_TYPES, SETTING_CTA_CONTENT_TYPES_ENUM } from '../constants';

const Selectbox = lazy(() => import('@base/components/form/select'));
const LanguageSelect = lazy(() => import('@base/containers/language-select'));

export const groupByOptions = [
  { label: 'All CTAs', value: 'all' },
  { label: 'Image CTAs', value: 'image' },
  { label: 'Text CTAs', value: 'text' },
  { label: 'QR Code CTAs', value: 'qrCode' },
  { label: 'Url CTAs', value: 'url' },
];

export const dateByOptions = [
  { label: 'Created', value: 'createdAt' },
  { label: 'Updated', value: 'updatedAt' },
];

export const filterByOptions: IFilterByOption[] = [
  {
    label: 'Content Type',
    value: 'contentType',
    component: Selectbox,
    componentProps: {
      options: SETTING_CTA_CONTENT_TYPES_ENUM,
      isSearchable: false,
    },
    getValue: (componentValue: any) => {
      return componentValue?.value;
    },
    setValue: (value: any) => {
      return SETTING_CTA_CONTENT_TYPES_ENUM?.find((v: any) => v.value === value);
    },
  },
  {
    label: 'Language',
    value: 'language',
    component: LanguageSelect,
    getValue: (componnetValue: any) => {
      return componnetValue?.value;
    },
  },
  // { label: 'View', value: 'view' },
  // { label: 'Click', value: 'click' },
  // { label: 'Click Rate', value: 'clickRate' },
];

export const searchFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    defaultValue: '',
  },
];

export const categoryOptions = slideToObject(SETTING_ONLINE_DIGITAL_MENUS, 'value', 'label');

export const sortsBy = [
  { label: 'Name', value: 'name' },
  { label: 'Created At', value: 'createdAt' },
  { label: 'Updated At', value: 'updatedAt' },
];
