import * as keyNames from '@settings/online-digital-content/satisfaction-survey/config/key-names';
import * as components from '@settings/online-digital-content/satisfaction-survey/config/write-field/components';
import * as commonComponents from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';

export default {
  [keyNames.KEY_SATISFACTION_SURVEY_NAME]: {
    component: commonComponents.Input,
    componentProps: {
      type: 'text',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SATISFACTION_SURVEY_TITLE]: {
    component: commonComponents.Input,
    componentProps: {
      type: 'text',
    },
    showFullRow: true,
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SATISFACTION_SURVEY_TYPE]: {
    component: commonComponents.Select,
    componentProps: {
      options: [],
    },
    showFullRow: true,
    defaultValue: null,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_SATISFACTION_SURVEY_LANGUAGE]: {
    component: components.LanguageSelect,
    componentProps: {},
    showFullRow: true,
    defaultValue: 'en',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SATISFACTION_SURVEY_DESCRIPTION]: {
    component: commonComponents.TextArea,
    validate: {},
    defaultValue: '',
    showFullRow: true,
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_SATISFACTION_SURVEY_TEMPLATE]: {
    component: components.SiteTemplateSelect,
    componentProps: {},
    showFullRow: true,
    validate: {},
    defaultValue: null,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_SATISFACTION_SURVEY_CONTENT]: {
    component: components.SurveyForm,
    componentProps: {},
    showFullRow: true,
    validate: {},
    defaultValue: null,
    parseParam: (value: any) => value,
  },
};
