import { SpanLang } from '@base/components';
import BasicTable from '@base/components/table/basic';
import useMutationPost from '@base/hooks/useMutationPost';
import { IdName } from '@base/types/interfaces/common';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import RowItem from '@product/item/containers/write-form/prepaid-items/row-item';
import { SLA_LANGS } from '@settings/preferences/config/constant';
import { UPDATE_SLA_SETTING } from '@settings/preferences/services/graphql';
import { useSLASetting } from '@settings/preferences/services/service';
import { ISLA } from '@settings/preferences/types/interfaces';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import ViewCustomer from './view-customer';

interface ISLAProps {}
const SLA: React.FC<ISLAProps> = (props: ISLAProps) => {
  const [items, setItems] = useState<ISLA[]>([]);
  const [viewItem, setViewItem] = useState<ISLA | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading } = useSLASetting();
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_SLA_SETTING,
    'setting_updateMenuSetting',
  );

  const onClose = () => {
    setViewItem(null);
    setIsOpen(false);
  };

  const onSave = (newItems: ISLA[], callback?: any) => {
    const nSetting = {
      menu: 'desk',
      key: 'sla',
      value: JSON.stringify(newItems),
    };
    mUpdate.mutate(
      { menuSetting: nSetting },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
          callback && callback();
        },
      },
    );
  };
  const onSetDefault = (row: ISLA) => {
    const newItems = items.map((item: ISLA) => {
      let isDefault = false;
      if (item.sla == row.sla) {
        isDefault = true;
      }
      return {
        ...item,
        isDefault: isDefault,
      };
    });
    setItems(newItems);
    // save data
    onSave(newItems);
  };
  const onUpdateCustomers = (row: ISLA) => {
    const newItems = items.map((item: ISLA) => {
      if (item.sla == row.sla) {
        return row;
      }
      return {
        ...item,
      };
    });
    setItems(newItems);
    // save data
    onSave(newItems, () => {
      onClose();
    });
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row: ISLA, rowIndex: any) => {
          return (
            <>
              <SpanLang keyLang={SLA_LANGS[row.sla]} />
            </>
          );
        },
        width: 'auto',
      },
      {
        Header: 'Default',
        accessor: (row: ISLA, rowIndex: any) => {
          return (
            <>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id={`slaDefaultRadio${row.sla}`}
                  name={`slaDefaultRadio${row.sla}`}
                  className="custom-control-input"
                  checked={row.isDefault}
                  onChange={() => {
                    onSetDefault(row);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`slaDefaultRadio${row.sla}`}
                ></label>
              </div>
            </>
          );
        },
        width: 70,
      },
      {
        Header: 'Customers',
        accessor: (row: ISLA, rowIndex: any) => {
          return (
            <>
              <button
                type="button"
                className="btn btn-link pd-0"
                onClick={() => {
                  setIsOpen(true);

                  setViewItem(row);
                }}
              >
                View
              </button>
            </>
          );
        },
        width: 70,
      },
    ],
    [items],
  );

  useEffect(() => {
    if (!isLoading && data?.value) {
      try {
        const slas: ISLA[] = JSON.parse(data.value);
        setItems(slas);
      } catch (err: any) {}
    }
  }, [data]);

  return (
    <>
      <div className="card mg-b-15">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Service Level Agreement(SLA)</div>
        </div>
        <BasicTable
          columns={columns}
          data={items || []}
          className=" settings-tb"
          wrapperClassName="remove-min-width"
          isLoading={isLoading}
        ></BasicTable>
        {isOpen && (
          <>
            <ViewCustomer
              isOpen={isOpen}
              onClose={onClose}
              onSave={onUpdateCustomers}
              data={viewItem}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SLA;
