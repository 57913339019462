import React from 'react';
import {
  Q_MULTI_CHOICES,
  Q_CHECKBOXES,
  Q_DROPDOWN,
  Q_SHORT_ANSWER,
  Q_PARAGRAPH,
  Q_FILE_UPLOAD,
  Q_DATE,
  Q_TIME,
  Q_MULTI_CHOICES_GRID,
  Q_TICK_BOX_GRID,
} from '@settings/online-digital-content/survey/config/constants';
import { useTranslation } from 'react-i18next';
import { SpanLang } from '@base/components';

/**
 *
 * @param {*} props
 */
const OptionResponse: React.FC<any> = (props) => {
  const { type, count, options } = props;
  const { t } = useTranslation();

  //for multiple choices grid
  function renderAnwserMultiGid(option: any) {
    let answers: any = [];
    option.rows &&
      option.rows.length > 0 &&
      option.rows.map((row: any, rIdx: number) => {
        option.cols.map((col: any, cIdx: number) => {
          const optChecked =
            option.answer && option.answer[rIdx] ? option.answer[rIdx][cIdx] : false;
          if (optChecked) {
            answers.push([row.value, col.value].join('-'));
          }
        });
      });
    return answers.join(', ');
  }

  //render empty answer
  function renderBlank() {
    return (
      <div key={'blank'} className="survey-write-question-card-list-item">
        <div className="survey-write-question-card-list-item-inline w-100">
          <div className="w-100">
            <span className="tx-gray-500 tx-italic tx-16">
              <SpanLang keyLang="Question left blank (no anwswer)" />
            </span>
          </div>
        </div>
      </div>
    );
  }

  function renderOption() {
    //render based on question type
    switch (type) {
      case Q_MULTI_CHOICES:
        if (options.length > 0) {
          return options.map((opt: any, idx: number) => {
            const optTitle = opt.value ? opt.value : t('Untitled option');
            return (
              <div key={idx} className="survey-write-question-option-container">
                <div className="survey-write-question-card-list-item">
                  <div className="survey-write-question-card-list-item-inline w-100">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        defaultChecked={opt.checked}
                      />
                      <label className="custom-control-label tx-16">
                        {/* {opt.isOther ? t('Other') : optTitle} */}
                        {optTitle}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        } else {
          return renderBlank();
        }
      case Q_CHECKBOXES:
        if (options.length > 0) {
          return options.map((opt: any, idx: number) => {
            //dynamic option key
            //const optionKey = 'option' + (keyQ + 1).toString() + (idx + 1).toString();
            const optTitle = opt.value ? opt.value : t('Untitled option');
            return (
              <div key={idx} className="survey-write-question-option-container">
                <div className="survey-write-question-card-list-item">
                  <div className="survey-write-question-card-list-item-inline w-100">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        //id={optionKey}
                        //name={'q-checkbox' + (keyQ + 1)}
                        className="custom-control-input"
                        defaultChecked={opt.checked}
                      />
                      <label className="custom-control-label tx-16">
                        {/* {opt.isOther ? t('Other') : optTitle} */}
                        {optTitle}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        } else {
          return renderBlank();
        }
      case Q_DROPDOWN:
        if (options.length > 0) {
          return options.map((opt: any, idx: number) => {
            //dynamic option key
            //const optionKey = 'option' + (keyQ + 1).toString() + (idx + 1).toString();
            const optTitle = opt.value ? opt.value : t('Untitled option');
            return (
              <div key={idx} className="survey-write-question-option-container">
                <div className="survey-write-question-card-list-item">
                  <div className="survey-write-question-card-list-item-inline w-100">
                    <div className="custom-control custom-checkbox">
                      <span className="tx-16">
                        {/* {opt.isOther ? t('Other') : optTitle} */}
                        {optTitle}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        } else {
          return renderBlank();
        }
      case Q_SHORT_ANSWER:
      case Q_PARAGRAPH:
      case Q_DATE:
      case Q_TIME:
        if (options.length > 0) {
          return options.map((opt: any, idx: number) => {
            return (
              <div key={idx} className="survey-write-question-card-list-item">
                <div className="survey-write-question-card-list-item-inline w-100">
                  <div className="w-100">
                    <span className="tx-16">{opt.answer}</span>
                  </div>
                </div>
              </div>
            );
          });
        } else {
          return renderBlank();
        }
      case Q_FILE_UPLOAD:
        if (options.length > 0) {
          return (
            options[0].answer &&
            options[0].answer.length > 0 &&
            options[0].answer.map((opt: any, idx: number) => {
              return (
                <div key={idx} className="survey-write-question-card-list-item">
                  <div className="survey-write-question-card-list-item-inline w-100">
                    <div className="w-100">
                      <div className="survey-write-question-link-other">
                        <a className="tx-16">{opt.name}</a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          );
        } else {
          return renderBlank();
        }
      case Q_MULTI_CHOICES_GRID:
      case Q_TICK_BOX_GRID:
        if (options.length > 0) {
          return options.map((opt: any, idx: number) => {
            return (
              <div key={idx} className="survey-write-question-option-container">
                <div className="survey-write-question-card-list-item">
                  <div className="survey-write-question-card-list-item-inline w-100">
                    {type === Q_MULTI_CHOICES_GRID && (
                      <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" defaultChecked />
                        <label className="custom-control-label tx-16">
                          {renderAnwserMultiGid(opt)}
                        </label>
                      </div>
                    )}
                    {type === Q_TICK_BOX_GRID && (
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" defaultChecked />
                        <label className="custom-control-label tx-16">
                          {renderAnwserMultiGid(opt)}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          });
        } else {
          return renderBlank();
        }
      default:
        return <div></div>;
    }
  }

  //component render
  return (
    //question options
    <div className="card mg-t-15">
      <div className="card-body">
        <div className="d-flex flex-column justify-content-start">
          {renderOption()}
          <div className="survey-write-question-card-list-footer">
            <a className="survey-question-link">
              {count} {count === 1 ? t('Single') : t('Plural')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionResponse;
