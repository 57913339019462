import React from 'react';

interface ViewLayoutProps {
  header?: React.ReactNode;
  body: React.ReactNode;
}

function ViewLayout({ header, body }: ViewLayoutProps) {
  // console.log('desktop view');
  return (
    <div className="view-wrap">
      {header}
      {body}
    </div>
  );
}

export default ViewLayout;
