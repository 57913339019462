import { SelectBox } from '@base/config/write-field/components';
import { MODULE, MODULE_OPTIONS } from '@base/config/constant';
import {
  PROCESS_STATUS_PROPERTIES,
  PROCESS_STATUS_PROPERTIES_VIEW,
  PROCESS_TYPE,
  PROCESS_TYPE_OPTIONS,
  TRIGGER_TYPE_OPTIONS,
} from '@settings/process/config/constants';
import { DefinedField, DefinedTrigger } from '@settings/process/types/process';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTriggerFormAtomState } from '@settings/process/recoil/process';
import { OptionValue } from '@base/types/interfaces/common';
import {
  useDefinedFields,
  useGetModuleProcesses,
  useGetProcessSteps,
} from '@settings/process/hooks/useProcess';
import _ from 'lodash';

interface TriggerWriteProps {
  triggerData: DefinedTrigger | undefined;
}

export const initTriggerTypeOption: OptionValue = {
  keyName: '',
  languageKey: 'Select Type',
};

export const initModuleOption: OptionValue = {
  keyName: '',
  languageKey: 'Select Module',
};

export const initProcessOption: OptionValue = {
  keyName: '',
  languageKey: 'Select Process',
};

export const initStepOption: OptionValue = {
  keyName: '',
  languageKey: 'Select Step',
};

export const initProcessTypeOption: OptionValue = {
  keyName: '',
  languageKey: 'Select Process Type',
};

export const initStepPropertyOption: OptionValue = {
  keyName: '',
  languageKey: 'Select Step Property',
};

export const initFieldOption: OptionValue = {
  keyName: '',
  languageKey: 'Select Field',
};

function TriggerWrite(props: TriggerWriteProps) {
  const { triggerData } = props;
  const [triggerForm, setTriggerForm] = useTriggerFormAtomState();
  const [processOptions, setProcessOptions] = useState<OptionValue[]>();
  const [stepOptions, setStepOptions] = useState<OptionValue[]>();

  const { data: definedFields } = useDefinedFields();
  const { data: processData } = useGetModuleProcesses({ module: triggerForm.module?.keyName });
  const { data: stepData } = useGetProcessSteps(triggerForm.process?.keyName);

  const moduleFields = useMemo(() => {
    let items: { [index: string]: DefinedField[] } = {};
    if (definedFields) {
      for (const item of definedFields.results) {
        if (!items[item.module]) {
          items[item.module] = [];
        }
        items[item.module].push(item);
      }
    }
    return items;
  }, [definedFields]);

  const fieldOptions = useMemo(() => {
    if (triggerForm.module) {
      return moduleFields[triggerForm.module.keyName]?.map((field) => {
        return {
          keyName: field.fieldName,
          languageKey: field.fieldName,
          extra: field.fieldType,
        };
      });
    }
    return [];
  }, [moduleFields, triggerForm.module]);

  const onTypeChange = useCallback((newVal: OptionValue) => {
    setTriggerForm((old) => {
      return { ...old, trigger: newVal };
    });
  }, []);

  const onModuleChange = useCallback((newVal: OptionValue) => {
    setTriggerForm((old) => {
      return { ...old, module: newVal };
    });
  }, []);

  const onFieldChange = useCallback((newVal: OptionValue) => {
    setTriggerForm((old) => {
      return { ...old, field: newVal };
    });
  }, []);

  const onProcessTypeChange = useCallback((newVal: OptionValue) => {
    setTriggerForm((old) => {
      return { ...old, ptype: newVal };
    });
  }, []);

  const onProcessNameChange = useCallback((newVal: OptionValue) => {
    setTriggerForm((old) => {
      return { ...old, process: newVal };
    });
  }, []);

  const onStepNameChange = useCallback((newVal: OptionValue) => {
    setTriggerForm((old) => {
      return { ...old, step: newVal };
    });
  }, []);

  const onStepPropertyChange = useCallback((newVal: OptionValue) => {
    setTriggerForm((old) => {
      return { ...old, property: newVal };
    });
  }, []);

  useEffect(() => {
    if (processData?.results) {
      const options = processData.results.map((process) => {
        return {
          keyName: process.id,
          languageKey: process.name,
        };
      });
      setProcessOptions(options);
    }
  }, [processData]);

  useEffect(() => {
    if (stepData?.results) {
      const options = stepData.results.map((step) => {
        return {
          keyName: step.id,
          languageKey: step.name,
        };
      });
      setStepOptions(options);
    }
  }, [stepData]);

  useEffect(() => {
    if (triggerData) {
      setTriggerForm((old) => {
        let newTrigger = _.cloneDeep(old);
        if (triggerData.trigger) {
          newTrigger.trigger = TRIGGER_TYPE_OPTIONS.find(
            (opt) => opt.keyName == triggerData.trigger,
          )!!;
        }
        if (triggerData.module) {
          newTrigger.module = {
            keyName: triggerData.module,
            languageKey: MODULE[triggerData.module],
          };
        }
        if (triggerData.field) {
          newTrigger.field = { keyName: triggerData.field, languageKey: triggerData.field };
        }
        if (triggerData.process?.id) {
          newTrigger.process = {
            keyName: triggerData.process.id,
            languageKey: triggerData.process.name,
          };
        }
        if (triggerData.step?.id) {
          newTrigger.step = { keyName: triggerData.step.id, languageKey: triggerData.step.name };
        }
        if (triggerData.property) {
          newTrigger.property = {
            keyName: triggerData.property,
            languageKey: PROCESS_STATUS_PROPERTIES_VIEW[triggerData.property],
          };
        }
        if (triggerData.ptype) {
          newTrigger.ptype = {
            keyName: triggerData.ptype,
            languageKey: PROCESS_TYPE[triggerData.ptype],
          };
        }

        return newTrigger;
      });
    }
  }, [triggerData]);

  return (
    <>
      <div className="form-group col-12">
        <label className="form-item-title">Module</label>
        <SelectBox
          options={TRIGGER_TYPE_OPTIONS}
          value={triggerForm.trigger}
          onChange={(newVal) => onTypeChange(newVal)}
        />
      </div>
      {(triggerForm.trigger.keyName == 'TRIGGER_TYPE_RECORD_CREATED' ||
        triggerForm.trigger.keyName == 'TRIGGER_TYPE_RECORD_UPDATED' ||
        triggerForm.trigger.keyName == 'TRIGGER_TYPE_RECORD_CREATED_UPDATED' ||
        triggerForm.trigger.keyName == 'TRIGGER_TYPE_RECORD_DELETED') && (
        <>
          <div className="form-group-title col-12">Record</div>
          <div className="form-group col-12">
            <label className="form-item-title">Module</label>
            <SelectBox
              options={MODULE_OPTIONS}
              value={triggerForm.module!!}
              onChange={(newVal) => onModuleChange(newVal)}
            />
          </div>
        </>
      )}
      {triggerForm.trigger.keyName == 'TRIGGER_TYPE_FIELD_UPDATED' && (
        <>
          <div className="form-group-title col-12">Field</div>
          <div className="form-group col-6">
            <label className="form-item-title">Module</label>
            <SelectBox
              options={MODULE_OPTIONS}
              value={triggerForm.module!!}
              onChange={(newVal) => onModuleChange(newVal)}
            />
          </div>
          <div className="form-group col-6">
            <label className="form-item-title">Field</label>
            <SelectBox
              options={fieldOptions}
              value={triggerForm.field!!}
              onChange={(newVal) => onFieldChange(newVal)}
            />
          </div>
        </>
      )}
      {triggerForm.trigger.keyName == 'TRIGGER_TYPE_PROCESS_PROPERTY_UPDATED' && (
        <>
          <div className="form-group-title col-12">Process</div>
          <div className="form-group col-6">
            <label className="form-item-title">Process Type</label>
            <SelectBox
              options={PROCESS_TYPE_OPTIONS}
              value={triggerForm.ptype!!}
              onChange={(newVal) => onProcessTypeChange(newVal)}
            />
          </div>
          <div className="form-group col-6">
            <label className="form-item-title">Module</label>
            <SelectBox
              options={MODULE_OPTIONS}
              value={triggerForm.module!!}
              onChange={(newVal) => onModuleChange(newVal)}
            />
          </div>
          <div className="form-group col-6">
            <label className="form-item-title">Process Name</label>
            <SelectBox
              options={processOptions ?? []}
              value={triggerForm.process!!}
              onChange={(newVal) => onProcessNameChange(newVal)}
            />
          </div>
          <div className="form-group col-6">
            <label className="form-item-title">Step Name</label>
            <SelectBox
              options={stepOptions ?? []}
              value={triggerForm.step!!}
              onChange={(newVal) => onStepNameChange(newVal)}
            />
          </div>
          <div className="form-group col-6">
            <label className="form-item-title">Step Property</label>
            <SelectBox
              options={PROCESS_STATUS_PROPERTIES}
              value={triggerForm.property!!}
              onChange={(newVal) => onStepPropertyChange(newVal)}
            />
          </div>
        </>
      )}
    </>
  );
}

export default TriggerWrite;
