import { atom } from 'recoil';

export const calllWriteOptionAtom = atom({
  key: 'CallWriteOptionAtom',
  default: {
    writeType: 'call',
    writeMenu: 'list',
    isOpenWrite: false,
  },
});
