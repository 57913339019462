import React, { useState, useEffect } from 'react';
import { Mail, Link2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { confirmAlert, MiModal, SpanLang } from '@base/components';

const SendModal: React.FC<any> = (props) => {
  const { isOpen = false, onClose, defaultSubject = '' } = props;
  const { t } = useTranslation();
  //state
  const [tab, setTab] = useState<number>(1);
  const [requiredMailTo, setRequiredMailTo] = useState<any>(false);
  const [mailTo, setMailTo] = useState<string>(''); //trungtm.hanbiro@hanbiro.vn
  const [subject, setSubject] = useState<string>(defaultSubject);
  const [message, setMessage] = useState<string>("I've invite you to fill in a survey:");
  const initLink = window.location.href.replace('/write/', '/all/');
  const [link, setLink] = useState(initLink);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //close modal
  const handleClose = () => {
    onClose && onClose();
    //clear error
    setRequiredMailTo(false);
  };
  const handleTabChange = (tab: any) => setTab(tab);

  //copy link
  function handleCopy() {
    navigator.clipboard.writeText(link);
    handleClose(); //close modal
  }

  //send survey via email
  function handleSend() {
    if (!mailTo) {
      //set error
      setRequiredMailTo(true);
      return;
    }

    setIsLoading(true);

    //clear error
    setRequiredMailTo(false);

    //send
    let newMsg = message + '<br />' + link;
    const params = {
      mail_to: mailTo,
      subject: subject,
      message: newMsg,
    };
    // myAxios.post(apis.sendSurvey, params).then((res) => {
    //     setIsLoading(false);
    // 	if (res.data.success) {
    //         noty({
    //             title: t('activity.activity_survey_success'),
    //             content: t('activity.activity_survey_sent_success'),
    //             type: 'success'
    //         })();
    //         //close
    //         handleClose(); //close modal
    // 	} else {
    //         //show error
    //         noty({
    //             title: t('activity.activity_survey_error'),
    //             content: t('activity.activity_survey_sent_failed'),
    //             type: 'danger'
    //         })();
    // 	}
    // })
    // .catch(function (error) {
    // 	//// console.log(error);
    // });
  }

  return (
    <MiModal title={t('Send Survey')} size="md" isOpen={isOpen} onClose={handleClose}>
      <MiModal.Body>
        <div className="pos-relative d-flex flex-row bd-b justify-content-start pd-y-10">
          <div className="tx-16">
            <SpanLang keyLang="Send via" />
          </div>
          {/* send tabs */}
          <div className="pos-absolute" style={{ top: '3px', left: '40%' }}>
            <ul className="nav nav-line survey-write-nav-tab-no-line" id="send-tab" role="tablist">
              {/* tab 1 */}
              <li className="nav-item mg-r-20">
                <a
                  className="nav-link active"
                  id="send-email-tab"
                  data-toggle="tab"
                  href="#send-email"
                  role="tab"
                  aria-controls="send"
                  aria-selected="true"
                  onClick={() => handleTabChange(1)}
                >
                  <Mail />
                </a>
              </li>
              {/* tab 2 */}
              <li className="nav-item mg-r-100">
                <a
                  className="nav-link"
                  id="send-link-tab"
                  data-toggle="tab"
                  href="#send-link"
                  role="tab"
                  aria-controls="send"
                  aria-selected="false"
                  onClick={() => handleTabChange(2)}
                >
                  <Link2 />
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* tab content */}
        <div className="tab-content pd-t-20 pd-b-20" id="sendTabContent">
          {/* Mail tab */}
          <div
            className="tab-pane fade active show"
            id="send-email"
            role="tabpanel"
            aria-labelledby="send-email-tab"
          >
            <div className="d-flex flex-column">
              <span className="tx-16 tx-bold">
                <SpanLang keyLang="Email" />{' '}
                {requiredMailTo && (
                  <span className="mg-l-10 tx-12" style={{ color: 'red' }}>
                    * <SpanLang keyLang="Required field" />
                  </span>
                )}
              </span>
              <input
                type="text"
                //id={qTitleEleId}
                className="form-control survey-write-input pd-t-15"
                placeholder={t('Email to')}
                value={mailTo}
                onChange={(e) => setMailTo(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column pd-t-20">
              <span className="tx-16">
                <SpanLang keyLang="Subject" />
              </span>
              <input
                type="text"
                //id={qTitleEleId}
                className="form-control survey-write-input pd-t-15"
                placeholder={t('Subject')}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column pd-t-20">
              <span className="tx-16">
                <SpanLang keyLang="Message" />
              </span>
              <input
                type="text"
                //id={qTitleEleId}
                className="form-control survey-write-input pd-t-15"
                placeholder={t('Message')}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>

          {/* Link tab */}
          <div
            className="tab-pane fade"
            id="send-link"
            role="tabpanel"
            aria-labelledby="send-link-tab"
          >
            <div className="d-flex flex-column pd-t-20">
              <span className="tx-16 tx-bold">
                <SpanLang keyLang="Link" />
              </span>
              <input
                type="text"
                className="form-control survey-write-input pd-t-15"
                placeholder={t('Link')}
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* spinner */}
        {/* <div className="pos-absolute z-index-100" style={{ top: "40%", left: "50%" }}>
            {isLoading && <Loading />}
          </div> */}
      </MiModal.Body>
      <MiModal.Footer>
        <div className="d-flex justify-content-end">
          <button type="button" className="btn btn-outline-secondary mg-r-5" onClick={handleClose}>
            <SpanLang keyLang="Cancel" />
          </button>
          {tab === 1 && (
            <button type="button" className="btn btn-primary" onClick={handleSend}>
              <SpanLang keyLang="Send" />
            </button>
          )}
          {tab === 2 && (
            <button type="button" className="btn btn-primary" onClick={handleCopy}>
              <SpanLang keyLang="Copy" />
            </button>
          )}
        </div>
      </MiModal.Footer>
    </MiModal>
  );
};

export default SendModal;
