import { lazy } from 'react';

const KBMain = lazy(() => import('./main'));

export default [
  {
    path: '/settings/knowledgebase/*',
    component: KBMain,
  },
];
