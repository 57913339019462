import { atom } from 'recoil';

export const ctaWriteOptionAtom = atom({
  key: 'CtaWriteOptionAtom',
  default: {
    writeType: '',
    writeMenu: 'list',
    isOpenWrite: false,
  },
});
