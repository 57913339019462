import { lazy } from 'react';

const MainPage = lazy(() => import('@settings/site/main/pages/main'));

export default [
  {
    path: '/settings/site/*',
    component: MainPage,
  },
];
