import React, { useEffect, useState } from 'react';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
//import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { useParams } from 'react-router-dom';
import { useGetView } from '@base/services/view';
import Title from '@base/containers/view-field/title';
import { SETTING_ONLINE_DIGITAL_MENUS } from '@base/config/route-menus';
import { MENU_SETTING_SURVEY } from '@base/config/menus';
import { SpanLang } from '@base/components';
import { getTabs } from './tabs';
import { surveyListFilterState } from '@settings/online-digital-content/survey/recoil/atom/list-atom';
import { settingODCWriteOptionAtom } from '@settings/online-digital-content/main/recoil/atom/write-atom';
import { KEY_SURVEY_NAME } from '@settings/online-digital-content/survey/config/key-names';
import WritePage from '@settings/online-digital-content/survey/pages/write'; //for write
import { default as viewConfig } from '@settings/online-digital-content/survey/config/view-field';
import displayLayoutConfig from '@settings/online-digital-content/survey/config/display-layout';
import ViewLeft from '@settings/online-digital-content/survey/layouts/desktop/layout1/view/view-left';
// import ViewRight from '@settings/online-digital-content/survey/layouts/desktop/layout1/view/view-right';

interface IViewProps {
  isSplitMode?: boolean;
  className?: string;
}

const ViewPage: React.FC<IViewProps> = (props: IViewProps) => {
  const { className = '', isSplitMode } = props;
  //state
  const params = useParams();
  const layoutMenu = MENU_SETTING_SURVEY; //for list
  const writeCategory = MENU_SETTING_SURVEY; //for write
  const keyTitle = `crm_new_${writeCategory}`;
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom); //app data
  const listFilter = useRecoilValue(surveyListFilterState);
  const { activeId, activeTab } = listFilter;
  let id = activeId != params.id ? params.id ?? '' : activeId ?? '';
  let currentTab = activeTab != params['*'] ? params['*'] : activeTab ?? '';
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_SETTING_SURVEY)); //view data
  const [writeOption, setWriteOption] = useRecoilState(settingODCWriteOptionAtom); //write form
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page

  /** Get data */
  const { data: layoutView, isLoading: isLayoutLoading } = usePageLayoutByMenu(layoutMenu, 'view');
  let viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });

  const {
    isLoading,
    data: surveyData,
    isFetching: dataLoading,
    refetch,
  } = useGetView<any>({ menu: 'setting_survey', schemas: viewSchema, params: { id } });
  //// console.log('surveyData', surveyData);

  //get detail data
  useEffect(() => {
    if (!isLayoutLoading && !isLoading && surveyData) {
      const layoutData = mergeLayoutData(layoutView, surveyData, viewConfig);
      let newLayoutData: any = { ...layoutData };
      newLayoutData.menuSourceId = id;
      newLayoutData.menuSource = MENU_SETTING_SURVEY;
      newLayoutData.menu = 'survey';
      setViewData(newLayoutData);
    }
  }, [surveyData, isLoading, isLayoutLoading]);

  /** ================================== VIEW PROPS ================================== */
  //ticket subject
  const keyNameIndex = viewData?.layout?.data[0]?.children?.findIndex(
    (_ele: any) => _ele.keyName === KEY_SURVEY_NAME,
  );
  const pageTitle = keyNameIndex > -1 ? viewData.layout.data[0].children[keyNameIndex].data : '';

  const moreActions: any[] = [];
  const viewProps = {
    className,
    isLoading: dataLoading,
    headerProps: {
      menu: 'survey',
      menus: SETTING_ONLINE_DIGITAL_MENUS, //menu list
      isDeleting: false,
      splitViewMode: isSplitMode,
      onPrint: () => {},
      onDelete: () => {},
      onNew: (category: string | undefined) => {
        setWriteOption((filter) => ({
          ...filter,
          isOpenWrite: true,
          writeType: 'survey',
          writeMenu: 'view',
        }));
      },
      moreActions: moreActions,
      componentLeft: (
        <>
          <Title
            value={pageTitle}
            keyName={KEY_SURVEY_NAME}
            menuSourceId={id}
            menuSource={MENU_SETTING_SURVEY}
            userPermission={{ isEdit: true }}
            viewConfig={viewConfig}
          />
        </>
      ),
    },
    centerProps: {
      showTop: true,
      menuSource: MENU_SETTING_SURVEY,
      menuSourceId: id,
      tabs: getTabs({ data: surveyData }),
      currentTab: currentTab,
    },
    componentLeft: <ViewLeft id={id} category={MENU_SETTING_SURVEY} onRefetch={refetch} />,
    leftProps: {},
    // componentRight: <ViewRight id={id} category={MENU_SETTING_SURVEY} />,
  };

  return (
    <>
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={device}
        layout={layout}
        form="view"
        {...viewProps}
      />
      {/* hook form - write new write */}
      {writeOption.isOpenWrite &&
        writeOption.writeMenu === 'view' &&
        writeOption.writeType === 'survey' && (
          <WritePage
            title={<SpanLang keyLang={`Create Survey`} />}
            size="xl"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            //onReload={refetch}
            //onGoView={handleGoView}
            menuApi={writeCategory}
          />
        )}
    </>
  );
};

export default ViewPage;
