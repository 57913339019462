import { atom } from 'recoil';

export const smslWriteOptionAtom = atom({
  key: 'SmsWriteOptionAtom',
  default: {
    writeType: 'sms',
    writeMenu: 'list',
    isOpenWrite: false,
  },
});
