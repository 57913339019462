import { confirmAlert } from '@base/components/confirm-alert';
import { FormIcon } from '@base/components/form';
import { IconType } from '@base/types/types/app';
import classNames from 'classnames';
import React from 'react';
import PlaceholderItemUnused from '../placeholder/item-unused';
import ShowIn from '../show-in';

interface IItemUnusedProps {
  icon: string;
  iconType: IconType;
  label: any;
  isRequired: boolean;
  locationsDisplay: any;
  isDelete: boolean;
  onDelete: () => void;
  isLoading?: boolean;
  isBasicField: any;
}
const ItemUnused: React.FC<IItemUnusedProps> = (props: IItemUnusedProps) => {
  const {
    icon = '',
    iconType = 'icon',
    label = '',
    isRequired = false,
    locationsDisplay = [],
    isDelete = false,
    onDelete = () => {},
    isLoading = false,
    isBasicField = false,
  } = props;
  const handleOnDelete = () => {
    confirmAlert({
      title: 'Delete Field',
      message: 'Are you sure you want to delete this field permanently?',
      buttons: [
        {
          label: 'No',
          className: 'btn-secondary',
        },
        {
          label: 'Yes',
          className: 'btn-primary',
          onClick: () => {
            onDelete();
          },
        },
      ],
    });
  };

  if (isLoading) {
    return <PlaceholderItemUnused />;
  }

  return (
    <div
      className={classNames('page-layout-item-unused bd', {
        // required: isRequired,
      })}
    >
      <div className={classNames('view-name')}>
        <div className="d-flex align-items-center pd-r-10">
          <div
            className={classNames('wd-20', {
              ['is-custom-field']: !isBasicField,
            })}
          >
            <FormIcon icon={icon} iconType={iconType} />
          </div>
          <div
            className={classNames('pd-l-3', {
              ['txt-custom-field']: !isBasicField,
            })}
          >
            {label}
            <span className="is-required">{isRequired && '*'}</span>
          </div>
        </div>
      </div>

      <ShowIn locationsDisplay={locationsDisplay} />
      {isDelete && (
        <div className="pd-l-4" onClick={handleOnDelete}>
          <FormIcon icon="Trash2" iconType="feather" size={16} color={'#dc3545'} />
        </div>
      )}
    </div>
  );
};

export default ItemUnused;
