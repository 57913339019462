import {
  initFieldOption,
  initModuleOption,
  initProcessOption,
  initProcessTypeOption,
  initStepOption,
  initStepPropertyOption,
  initTriggerTypeOption,
} from '@settings/process/components/trigger/TriggerWrite';
import {
  StatusForm,
  StepType,
  BusinessProcess,
  ActionType,
  StepSiteForm,
  ITriggerForm,
} from '@settings/process/types/process';
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
  selectorFamily,
} from 'recoil';
import { STEP_TYPES } from '@settings/process/config/constants';
import { CtaProperty, NodeType } from '@settings/process/types/diagram';
import {
  categoryOptions,
  initTypeOption,
  initContentOption,
} from '@settings/process/components/diagram/site';

const businessProcessAtom = atom<BusinessProcess | null>({
  key: 'businessProcessAtom',
  default: null,
});

export const useBusinessProcessAtomState = () => {
  return useRecoilState(businessProcessAtom);
};

export const useBusinessProcessAtomValue = () => {
  return useRecoilValue(businessProcessAtom);
};

// const stepTypeAtom = atom<StepType>({
//   key: 'stepTypeAtom',
//   default: STEP_TYPES[0],
// });

// export const useStepTypeAtomState = () => {
//   return useRecoilState(stepTypeAtom);
// };

// export const useStepTypeAtomValue = () => {
//   return useRecoilValue(stepTypeAtom);
// };

// export const useStepTypeAtomReset = () => {
//   return useResetRecoilState(stepTypeAtom);
// };

const stepSettingAtom = atom({
  key: 'stepSettingAtom',
  default: {
    method: 'ACTION_METHOD_MANUAL',
    email: true,
    auto: false,
    cta: false,
    due: true,
    assign: true,
    template: false,
  },
});

export const useStepSettingAtomState = () => {
  return useRecoilState(stepSettingAtom);
};

export const useStepSettingAtomValue = () => {
  return useRecoilValue(stepSettingAtom);
};

export const useStepSettingAtomReset = () => {
  return useResetRecoilState(stepSettingAtom);
};

const stepDoAtom = atom({
  key: 'stepDoAtom',
  default: 'ACTION_CUSTOM',
});

export const useStepDoAtomState = () => {
  return useRecoilState(stepDoAtom);
};

export const useStepDoAtomValue = () => {
  return useRecoilValue(stepDoAtom);
};

// const customStatusAtom = atom<StatusForm[]>({
//   key: 'customStatusAtom',
//   default: [],
// });

// export const useCustomStatusAtomState = () => {
//   return useRecoilState(customStatusAtom);
// };

// export const useCustomStatusAtomValue = () => {
//   return useRecoilValue(customStatusAtom);
// };

// const showActionMultipleAtom = atom({
//   key: 'showActionMultipleAtom',
//   default: false,
// });

// export const useShowActionMultipleAtomState = () => {
//   return useRecoilState(showActionMultipleAtom);
// };

// export const useShowActionMultipleAtomValue = () => {
//   return useRecoilValue(showActionMultipleAtom);
// };

const stepSiteAtom = atom<StepSiteForm>({
  key: 'stepSiteAtom',
  default: {
    category: categoryOptions[0],
    type: initTypeOption,
    template: initContentOption,
    html: '',
  },
});

export const useStepSiteAtomState = () => {
  return useRecoilState(stepSiteAtom);
};

export const useStepSiteAtomValue = () => {
  return useRecoilValue(stepSiteAtom);
};

const siteCtaAtom = atom<CtaProperty[]>({
  key: 'siteCtaAtom',
  default: [],
});

export const useSiteCtaAtomState = () => {
  return useRecoilState(siteCtaAtom);
};

export const useSiteCtaAtomValue = () => {
  return useRecoilValue(siteCtaAtom);
};

const triggerFormAtom = atom<ITriggerForm>({
  key: 'triggerFormAtom',
  default: {
    trigger: initTriggerTypeOption,
    module: initModuleOption,
    field: initFieldOption,
    process: initProcessOption,
    ptype: initProcessTypeOption,
    step: initStepOption,
    property: initStepPropertyOption,
  },
});

export const useTriggerFormAtomState = () => {
  return useRecoilState(triggerFormAtom);
};

export const useTriggerFormAtomValue = () => {
  return useRecoilValue(triggerFormAtom);
};
