import { lazy } from 'react';

export const Input = lazy(() => import('@base/components/form/input'));
export const TextArea = lazy(() => import('@base/components/form/text-area'));
export const RadioGroup = lazy(() => import('@base/components/form/radio-group'));
export const Switch = lazy(() => import('@base/components/form/switch'));
export const Select = lazy(() => import('@base/components/form/select'));
export const LanguageSelect = lazy(() => import('@base/containers/language-select'));
export const ImageUploadSample = lazy(
  () => import('@settings/online-digital-content/cta/components/image-upload-sample'),
);
export const ImageSize = lazy(
  () => import('@settings/online-digital-content/cta/components/image-size'),
);
export const LinkUrl = lazy(
  () => import('@settings/online-digital-content/cta/components/link-url'),
);
export const LandingPageAutoComplete = lazy(
  () =>
    import('@settings/online-digital-content/landing-page/containers/landing-page-auto-complete'),
);
