import { Input } from '@base/components/form';
import SelectBox from '@base/containers/view-field/selectbox/edit';
import { StatusForm } from '@settings/process/types/process';
import { Minus, Plus, X } from 'react-feather';
import {
  PROCESS_STATUS_DIRECTIONS,
  PROCESS_STATUS_DIRECTIONS_VIEW,
  PROCESS_STATUS_EVENTS,
  PROCESS_STATUS_EVENTS_VIEW,
  PROCESS_STATUS_PROPERTIES,
  PROCESS_STATUS_PROPERTIES_VIEW,
  PROCESS_STATUS_VIEWS,
  PROCESS_STATUS_VIEWS_VIEW,
} from '@settings/process/config/constants';
import { OptionValue } from '@base/types/interfaces/common';
import { ChangeEvent, useCallback, useMemo } from 'react';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { checkParallel } from '@settings/process/utils/helper';
import { statusWithParallelFilter } from '@settings/process/recoil/status';
import useStatus from '@settings/process/hooks/useStatus';

interface ActionItemEditProps {
  status: StatusForm;
  parallelIndex: number;
}

function ActionItemEdit({ status, parallelIndex }: ActionItemEditProps) {
  const parallelCount = useRecoilValue(statusWithParallelFilter('normal')).length;
  const isParallel = checkParallel(status);
  const {
    onChangeKeyName,
    onChangeDirection,
    onChangeStatus,
    onSequenceChange,
    onSequenceAdd,
    onSequenceDelete,
    onChangeMultiplePrimary,
    onReset,
  } = useStatus(status);

  const sequenceOptions = useMemo<OptionValue[]>(() => {
    let options: OptionValue[] = [{ keyName: '', languageKey: '' }];
    for (let i = 1.5; i < 5.5; i = i + 0.5) {
      options.push({ keyName: '' + i, languageKey: '' + i });
    }
    return options;
  }, []);

  const renderButton = useCallback(() => {
    return (
      <div className="">
        {status.button == '-' ? (
          '-'
        ) : (
          <Input
            type="text"
            value={status.button}
            onChange={(newValue: string) => onChangeStatus({ button: newValue })}
          />
        )}
      </div>
    );
  }, [status]);

  const renderStatus = useCallback(() => {
    return (
      <div className="">
        {status.name == '-' ? (
          '-'
        ) : (
          <Input
            type="text"
            value={status.name}
            onChange={(newValue: string) => onChangeStatus({ name: newValue })}
          />
        )}
      </div>
    );
  }, [status]);

  const renderView = useCallback(() => {
    return (
      <div className="">
        {status.view.keyName == 'VIEW_DISABLE' ||
        status.property.keyName == 'PROPERTY_TODO' ||
        status.property.keyName == 'PROPERTY_TODO_DOING' ? (
          PROCESS_STATUS_VIEWS_VIEW[status.view.keyName]
        ) : (
          <SelectBox
            value={status.view}
            onChange={(newValue: OptionValue) => onChangeKeyName({ view: newValue })}
            options={PROCESS_STATUS_VIEWS}
            isSearchable={false}
          />
        )}
      </div>
    );
  }, [status]);

  const renderEvent = useCallback(() => {
    return (
      <div className="">
        {status.event.keyName == 'EVENT_DISABLE' ||
        status.property.keyName == 'PROPERTY_TODO' ||
        status.property.keyName == 'PROPERTY_TODO_DOING' ? (
          PROCESS_STATUS_EVENTS_VIEW[status.event.keyName]
        ) : (
          <SelectBox
            value={status.event}
            onChange={(newValue: OptionValue) => onChangeKeyName({ event: newValue })}
            options={PROCESS_STATUS_EVENTS}
            isSearchable={false}
          />
        )}
      </div>
    );
  }, [status]);

  const renderSequence = useCallback(() => {
    return (
      <div className="d-flex align-content-between justify-content-center">
        {status.property.keyName == 'PROPERTY_TODO' ||
        status.property.keyName == 'PROPERTY_TODO_DOING' ? (
          status.sequence?.join('/')
        ) : (
          <>
            <div className="d-flex flex-column">
              {status.sequence.map((seq, index) => {
                return (
                  <SelectBox
                    key={index}
                    value={{ keyName: '' + seq, languageKey: '' + seq }}
                    onChange={(newValue: OptionValue) => onSequenceChange(newValue, index)}
                    options={sequenceOptions}
                    isSearchable={false}
                    className={'mg-b-5'}
                  />
                );
              })}
            </div>
            <>
              {status.sequence.length < 2 && (
                <button
                  type="button"
                  className="btn btn-xs btn-link tx-danger btn-icon"
                  onClick={() => onSequenceAdd()}
                >
                  <Plus />
                </button>
              )}
              {status.sequence.length == 2 && (
                <button
                  type="button"
                  className="btn btn-xs btn-link tx-danger btn-icon"
                  onClick={() => onSequenceDelete()}
                >
                  <Minus />
                </button>
              )}
            </>
          </>
        )}
      </div>
    );
  }, [status]);

  return (
    <tr className="ht-55">
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{renderButton()}</td>
        ) : (
          ''
        )
      ) : (
        <td>{renderButton()}</td>
      )}
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{renderView()}</td>
        ) : (
          ''
        )
      ) : (
        <td>{renderView()}</td>
      )}
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{renderEvent()}</td>
        ) : (
          ''
        )
      ) : (
        <td>{renderEvent()}</td>
      )}
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{renderStatus()}</td>
        ) : (
          ''
        )
      ) : (
        <td>{renderStatus()}</td>
      )}
      <td>
        {(status.multiple == 'MULTIPLE_CHOICE' || status.multiple == 'MULTIPLE_PARALLEL') && (
          <div className="form-group">
            <label className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                className="custom-control-input"
                name="multiple-primary"
                value="yes"
                id={status.id}
                checked={status.primary}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMultiplePrimary(e)}
              />
              <label className="custom-control-label" htmlFor={status.id}>
                Primary
              </label>
            </label>
          </div>
        )}

        <div>
          {status.direction.keyName == 'DIRECTION_DISABLE' ||
          status.property.keyName == 'PROPERTY_TODO' ||
          status.property.keyName == 'PROPERTY_TODO_DOING' ? (
            PROCESS_STATUS_DIRECTIONS_VIEW[status.direction.keyName]
          ) : (
            <SelectBox
              value={status.direction}
              onChange={(newValue: OptionValue) => onChangeDirection(newValue)}
              options={PROCESS_STATUS_DIRECTIONS}
              isSearchable={false}
            />
          )}
        </div>
      </td>
      <td>
        <div>
          {status.property.keyName == 'PROPERTY_DISABLE' ||
          status.property.keyName == 'PROPERTY_TODO' ||
          status.property.keyName == 'PROPERTY_TODO_DOING' ? (
            PROCESS_STATUS_PROPERTIES_VIEW[status.property.keyName]
          ) : (
            <SelectBox
              value={status.property}
              onChange={(newValue: OptionValue) => onChangeKeyName({ property: newValue })}
              options={PROCESS_STATUS_PROPERTIES}
              isSearchable={false}
            />
          )}
        </div>
      </td>
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{renderSequence()}</td>
        ) : (
          ''
        )
      ) : (
        <td>{renderSequence()}</td>
      )}
      <td>
        <div className="d-flex align-items-center justify-content-center">
          {status.reset &&
            status.property.keyName !== 'PROPERTY_NEW' &&
            status.property.keyName !== 'PROPERTY_TODO' &&
            status.property.keyName !== 'PROPERTY_TODO_DOING' && (
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon"
                onClick={() => onReset()}
              >
                <X />
              </button>
            )}
        </div>
      </td>
    </tr>
  );
}

export default React.memo(ActionItemEdit);
