import { Checkbox, Input, Switch } from "@base/components/form";
import { useEffect, useState } from "react";

const LinkUrl: React.FC<any> = (props) => {
  const { value, onChange } = props;

  //state
  const [curValue, setCurValue] = useState<any>({ link: '', openNewWindow: false });

  //init 
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(curValue)) {
        setCurValue(value);
      }
    } else {
      setCurValue({ link: '', openNewWindow: false });
    }
  }, [value]);

  //open file dialog
  const handleValueChange = (keyName: string, keyValue: any) => {
    const newValue = { ...curValue };
    newValue[keyName] = keyValue;
    setCurValue(newValue);
    //callback
    onChange && onChange(newValue);
  }

  return (
    <>
      <Input
        type='text'
        value={curValue.link}
        onChange={(newText: string) => handleValueChange('link', newText)}
      />
      <Checkbox
        className="mg-t-5"
        label='Open page in new window'
        checked={curValue.openNewWindow}
        onChange={(e: any) => handleValueChange('openNewWindow', e.target.checked)}
      />
    </>
  );
}

export default LinkUrl;