import { useEffect, useState } from 'react';
import { Check, Edit, Edit2, Plus, PlusCircle, Save, Trash2, X } from 'react-feather';
import { Button, Input } from '@base/components/form';
import classNames from 'classnames';
import useMutationPost from '@base/hooks/useMutationPost';
import {
  PRODUCT_ATTRIBUTE_CREATE,
  PRODUCT_ATTRIBUTE_DELETE,
  PRODUCT_ATTRIBUTE_UPDATE,
  PRODUCT_ATTRIBUTE_VALUE_CREATE,
  PRODUCT_ATTRIBUTE_VALUE_DELETE,
  PRODUCT_ATTRIBUTE_VALUE_UPDATE,
} from '@product/product/services/graphql';
import { BaseResponse } from '@base/types/interfaces/response';
import { useProductAttributes } from '@product/product/services/product-service';
import { NoData } from '@base/components';
import Loading from '@base/components/loading';

const ProductAttributes: React.FC<any> = (props) => {
  const { className } = props;
  //state
  const [items, setItems] = useState<any>([]);
  const [attrValue, setAttrValue] = useState<any>({ name: '', value: '' });
  const [isValid, setIsValid] = useState(true);
  const [deleteItem, setDeleteItem] = useState<any>(null); //attribute
  const [deleteValue, setDeleteValue] = useState<any>(null); //attribute value
  const [selectedItem, setSelectedItem] = useState<any>(null); //attribute
  const [selectedValue, setSelectedValue] = useState<any>(null); //attribute value
  const [isAddingValue, setIsAddingValue] = useState(false); //attribute value
  const [inputValue, setInputValue] = useState(''); //attribute value

  //mutation
  const mCreateAttribute: any = useMutationPost<BaseResponse<any>>(
    PRODUCT_ATTRIBUTE_CREATE,
    'product_createAttribute',
  );
  const mUpdateAttribute: any = useMutationPost<BaseResponse<any>>(
    PRODUCT_ATTRIBUTE_UPDATE,
    'product_updateAttribute',
  );
  const mUpdateAttributeValue: any = useMutationPost<BaseResponse<any>>(
    PRODUCT_ATTRIBUTE_VALUE_UPDATE,
    'product_updateAttributeValue',
  );
  const mDeleteAttribute: any = useMutationPost<BaseResponse<any>>(
    PRODUCT_ATTRIBUTE_DELETE,
    'product_deleteAttribute',
  );
  const mDeleteAttributeValue: any = useMutationPost<BaseResponse<any>>(
    PRODUCT_ATTRIBUTE_VALUE_DELETE,
    'product_deleteAttributeValue',
  );
  const mCreateAttributeValue: any = useMutationPost<BaseResponse<any>>(
    PRODUCT_ATTRIBUTE_VALUE_CREATE,
    'product_createAttributeValues',
  );

  //get
  const { data: postData, isFetching, refetch } = useProductAttributes({ keyword: '' });

  //init items list
  useEffect(() => {
    if (postData?.data) {
      setItems(postData.data);
    }
  }, [postData]);

  //check success
  useEffect(() => {
    if (mCreateAttribute.isSuccess) {
      //update list
      const newItems = [...items];
      newItems.push(mCreateAttribute.data);
      setItems(newItems);
      //reset
      setAttrValue({ name: '', value: '' });
    }
  }, [mCreateAttribute.isSuccess]);

  useEffect(() => {
    if (mUpdateAttribute.isSuccess) {
      //update list
      const newItems = [...items];
      const fIndex = newItems.findIndex((_ele: any) => _ele.id === selectedItem.id);
      newItems[fIndex].name = selectedItem.name;
      setItems(newItems);
      //reset
      setSelectedItem(null);
    }
  }, [mUpdateAttribute.isSuccess]);

  useEffect(() => {
    if (mCreateAttributeValue.isSuccess) {
      const newItems = [...items];
      const fAttrIdx = newItems.findIndex((_ele: any) => _ele.id === selectedItem.id);
      if (newItems[fAttrIdx]?.values?.length > 0) {
        newItems[fAttrIdx].values = newItems[fAttrIdx].values.concat(
          mCreateAttributeValue.data.values,
        );
      } else {
        newItems[fAttrIdx].values = mCreateAttributeValue.data.values;
      }
      setItems(newItems);
      setSelectedValue(null);
      setSelectedItem(null);
      setIsAddingValue(false);
      setInputValue('');
    }
  }, [mCreateAttributeValue.isSuccess]);

  useEffect(() => {
    if (mUpdateAttributeValue.isSuccess) {
      const newItems = [...items];
      const fAttrIdx = newItems.findIndex((_ele: any) => _ele.id === selectedValue.attribute.id);
      const fValIdx = newItems[fAttrIdx].values.findIndex(
        (_ele: any) => _ele.id === selectedValue.id,
      );
      newItems[fAttrIdx].values[fValIdx].name = selectedValue.name;
      setItems(newItems);
      //reset
      setSelectedValue(null);
      setSelectedItem(null);
      setIsAddingValue(false);
      setInputValue('');
    }
  }, [mUpdateAttributeValue.isSuccess]);

  //check success
  useEffect(() => {
    if (mDeleteAttribute.isSuccess) {
      //update list
      const newItems = [...items];
      const fIndex = newItems.findIndex((_ele: any) => _ele.id === deleteItem.id);
      newItems.splice(fIndex, 1);
      setItems(newItems);
      //reset
      setDeleteItem(null);
    }
  }, [mDeleteAttribute.isSuccess]);

  //check success
  useEffect(() => {
    if (mDeleteAttributeValue.isSuccess) {
      //update list
      const newItems = [...items];
      const fAttrIndex = newItems.findIndex((_ele: any) => _ele.id === deleteItem.id);
      if (fAttrIndex > -1) {
        const fValIndex = newItems[fAttrIndex].values.findIndex(
          (_val: any) => _val.id === deleteValue.id,
        );
        if (fValIndex > -1) {
          newItems[fAttrIndex].values.splice(fValIndex, 1);
        }
      }
      setItems(newItems);
      //reset
      setDeleteItem(null);
      setDeleteValue(null);
    }
  }, [mDeleteAttributeValue.isSuccess]);

  //validate before save
  const onValid = () => {
    let valid = attrValue.name.length == 0 || attrValue.value.length == 0 ? false : true;
    setIsValid(valid);
    return valid;
  };

  //value change
  const handleValueChange = (keyName: string, keyValue: string) => {
    const newAttribute = { ...attrValue };
    newAttribute[keyName] = keyValue;
    setAttrValue(newAttribute);
  };

  //edit item
  const handleEdit = (item: any) => {
    setSelectedItem(item);
    setIsAddingValue(false);
  };

  //name change
  const handleAttrNameChange = (newName: string) => {
    const newItem = { ...selectedItem };
    newItem.name = newName;
    setSelectedItem(newItem);
  };

  //value change
  const handleValueNameChange = (newName: string) => {
    const newValue = { ...selectedValue };
    newValue.name = newName;
    setSelectedValue(newValue);
  };

  //save
  const handleSave = () => {
    if (!onValid()) return;
    //create NEW
    const params = {
      attribute: {
        name: attrValue.name,
        values: attrValue.value.split(',').map((_item: string) => ({ name: _item })),
      },
    };
    mCreateAttribute.mutate(params);
  };

  //update attribute
  const handleUpdateAttribute = () => {
    if (selectedItem.name.length === 0) return;
    //create NEW
    const params = {
      attribute: {
        id: selectedItem.id,
        name: selectedItem.name,
      },
    };
    mUpdateAttribute.mutate(params);
  };

  //update value
  const handleUpdateValue = () => {
    if (selectedValue.name.length === 0) return;
    // update attributeValue
    const params = {
      value: {
        id: selectedValue.id,
        name: selectedValue.name,
        attr: {
          id: selectedValue?.attribute?.id,
        },
      },
    };
    mUpdateAttributeValue.mutate(params);
  };

  //add new value to attribute
  const handleAddValue = () => {
    if (inputValue.length === 0) return;
    const params = {
      attrId: selectedItem.id,
      values: inputValue.split(',').map((_item: string) => ({ name: _item })),
    };
    mCreateAttributeValue.mutate(params);
  };

  //delete
  const handleDelete = (item: any) => {
    setDeleteItem(item);
    const params = {
      ids: [item.id],
    };
    mDeleteAttribute.mutate(params);
  };

  //delete attribute value
  const handleDeleteAttrValue = (attribute: any, value: any) => {
    setDeleteItem(attribute);
    setDeleteValue(value);
    const params = {
      id: value.id,
      attrId: attribute.id,
    };
    mDeleteAttributeValue.mutate(params);
  };

  //render attribute
  const renderAttribute = (item: any, index: number) => {
    return (
      <div className="kb-tree">
        <div className="tree-btn-wrap">
          {selectedItem?.id === item.id && !isAddingValue ? (
            <div>
              <input
                type="text"
                className="form-control"
                value={selectedItem.name}
                onChange={(e: any) => handleAttrNameChange(e.target.value)}
              />
            </div>
          ) : (
            <div className="text-truncate">{item.name}</div>
          )}
          <div className="tree-btn-actions">
            {selectedItem?.id === item.id && !isAddingValue ? (
              <>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  onClick={handleUpdateAttribute}
                >
                  {mUpdateAttribute.isLoading && (
                    <span
                      className="spinner-border spinner-border-sm tx-success mg-r-5"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  <Check className="tx-success" />
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  onClick={() => setSelectedItem(null)}
                >
                  <X />
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  onClick={() => handleEdit(item)}
                >
                  <Edit2 />
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  onClick={() => handleDelete(item)}
                >
                  {mDeleteAttribute.isLoading && (
                    <span
                      className="spinner-border spinner-border-sm tx-primary mg-r-5"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  <Trash2 className="tx-danger" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  //attribute values
  const renderValue = (item: any, index: number) => {
    return (
      <div className="kb-tree">
        <div className="tree-btn-wrap">
          <div className="d-flex flex-wrap align-items-center">
            {item?.values?.map((_value: any, vIdx: number) => (
              <div key={vIdx} className="badge badge-secondary bg-gray-300 mg-3">
                <div className="d-flex justify-content-center align-items-center pd-2">
                  {selectedValue?.id === _value.id ? (
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control"
                        value={selectedValue.name}
                        onChange={(e: any) => handleValueNameChange(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-xs btn-link link-03 btn-icon"
                        onClick={handleUpdateValue}
                      >
                        {mUpdateAttributeValue.isLoading && (
                          <span
                            className="spinner-border spinner-border-sm tx-success mg-r-5"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        <Check className="tx-success" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-xs btn-link link-03 btn-icon"
                        onClick={() => {
                          setSelectedValue(null);
                        }}
                      >
                        <X />
                      </button>
                    </div>
                  ) : (
                    <>
                      <span className="tx-black pd-4 tx-center">{_value.name}</span>
                      <div className="tree-btn-actions">
                        <Button
                          color="link"
                          size="sm"
                          className="link-03 pd-0"
                          onClick={() => setSelectedValue({ ..._value, attribute: item })}
                        >
                          <Edit2 style={{ width: '13px' }} />
                        </Button>
                        <Button
                          color="link"
                          size="sm"
                          className="link-03 pd-0"
                          onClick={() => handleDeleteAttrValue(item, _value)}
                        >
                          {mDeleteAttributeValue.isLoading && deleteValue.id === _value.id && (
                            <span
                              className="spinner-border spinner-border-sm tx-primary mg-r-5"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          <X className="tx-danger" style={{ width: '13px' }} />
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
            {selectedItem?.id === item.id && isAddingValue && (
              <div className="d-flex mg-1">
                <input
                  type="text"
                  className="form-control"
                  placeholder="separate value with ,"
                  value={inputValue}
                  onChange={(e: any) => setInputValue(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  onClick={handleAddValue}
                >
                  {mCreateAttributeValue.isLoading && (
                    <span
                      className="spinner-border spinner-border-sm tx-success mg-r-5"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  <Check className="tx-success" />
                </button>
                <button
                  type="button"
                  className="btn btn-xs btn-link link-03 btn-icon"
                  onClick={() => {
                    setSelectedItem(null);
                    setIsAddingValue(false);
                  }}
                >
                  <X />
                </button>
              </div>
            )}
          </div>
          <div className="tree-btn-actions">
            {(selectedItem?.id != item.id || !isAddingValue) && (
              <Button
                color="link"
                //size="sm"
                className="link-03 pd-0"
                onClick={() => {
                  setSelectedItem(item);
                  setIsAddingValue(true);
                }}
              >
                <PlusCircle className="tx-primary" style={{ width: '20px', height: '20px' }} />
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  //// console.log('attr', attrValue);
  return (
    <div className={classNames('pos-relative pd-10', className)}>
      <div className="scroll-box" style={{ maxHeight: 'calc(100vh - 220px)' }}>
        <div className="table-responsive bd-t bd-r bd-l">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col" className="bd-t-0-f wd-200">
                  Attributes
                </th>
                <th scope="col" className="bd-t-0-f">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td colSpan={2}>
                    <Loading />
                  </td>
                </tr>
              )}
              {items?.length === 0 && (
                <tr>
                  <td colSpan={2}>
                    <NoData />
                  </td>
                </tr>
              )}
              {items?.map((_item: any, index: number) => (
                <tr key={index}>
                  <td style={{ verticalAlign: 'middle' }}>{renderAttribute(_item, index)}</td>
                  <td style={{ verticalAlign: 'middle' }}>{renderValue(_item, index)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-footer d-flex align-items-center pd-10 bd">
          <div className="d-flex">
            <Input
              type="text"
              placeholder="e.g Color"
              value={attrValue.name}
              onChange={(text: string) => handleValueChange('name', text)}
            />
          </div>
          <div className="d-flex flex-grow-1 mg-l-20 mg-r-10">
            <Input
              type="text"
              placeholder="separate value with ,"
              value={attrValue.value}
              onChange={(text: string) => handleValueChange('value', text)}
            />
          </div>
          <Button
            color="primary"
            icon={attrValue?.id ? 'Save' : 'Plus'}
            iconClass="mg-r-5"
            name={attrValue?.id ? 'Save' : 'Add'}
            disabled={attrValue.name.length > 0 ? false : true}
            loading={mCreateAttribute.isLoading}
            onClick={handleSave}
          />
          {attrValue?.id && (
            <Button
              color="light"
              icon="RefreshCw"
              iconClass="mg-r-5"
              className="mg-l-5"
              name={'Reset'}
              onClick={() => setAttrValue({ name: '', value: '' })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductAttributes;
