import React from 'react';
import * as keyNames from '@settings/assignment-rule/config/key-names';
import * as components from '@settings/assignment-rule/config/write-rule-field/components';
import * as commonComponents from '@base/config/write-field/components';
import { AR_MODULE_OPTIONS } from '../constant';
import validators from '@base/utils/validation/field-validator';
import { IFieldConfig } from '@base/types/interfaces/common';
import Order from '@demo-page/settings/desktop/theme1/order';

export interface ReplaceTitle {
  step: number;
  text: string;
}
const replaceTitle: ReplaceTitle = {
  step: 1,
  text: 'Set the Order in which this rule entry will be processed',
};

const ARWriteRuleField = {
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_ENTRY_ASSIGN_ID]: {
    component: commonComponents.Input,
    componentProps: { disabled: true, isHidden: true },
    showFullRow: true,
    languageKey: 'Assign Rule Id',
    //defaultValue: '',
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_ENTRY_ORDER]: {
    component: commonComponents.Input,
    componentProps: {
      type: 'number',
      replacetitle: replaceTitle,
    },
    hideTitle: true,
    showFullRow: true,
    languageKey: 'Order',
    parseParam: (value: any) => {
      return parseInt(value);
    },
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_CRITERIA]: {
    hideTitle: true,
    component: components.Criteria,
    componentProps: {},
    showFullRow: true,
    languageKey: 'Criteria',
  },

  [keyNames.KEY_NAME_ASSIGNMENT_RULE_ASSIGN_TO]: {
    hideTitle: true,
    component: components.AssignTo,
    componentProps: {},
    showFullRow: true,
    languageKey: 'Assign To',
  },
};

export default ARWriteRuleField;
