import { Button, Checkbox, FormIcon, Input, Priority } from '@base/components/form';
import { ListQuickAction } from '@base/components/list';
import { TableNothing } from '@base/components/list/list-loading';
import { ListPagination } from '@base/components/list';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import { FilterInput } from '@base/types/interfaces/common';
import { Paging } from '@base/types/interfaces/response';
import { IDeskTag } from '@desk/main/types/interfaces';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
import { useTicketsByTag } from '@settings/preferences/services/service';
import { ColumnDef } from '@tanstack/react-table';
import { data } from 'jquery';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trash2 } from 'react-feather';
import Loading from '@base/components/loading';

interface ITicketListProps {
  viewTag: IDeskTag | null;
  goList: () => void;
}
const TicketList: React.FC<ITicketListProps> = (props: ITicketListProps) => {
  const { viewTag, goList } = props;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [listData, setListData] = useState<ITicket[]>([]);
  const [paging, setPaging] = useState<Paging | null>(null);
  const [keyword, setKeyword] = useState('');
  const getParam = () => {
    let filter: FilterInput | null = null;
    if (viewTag) {
      filter = {
        query: 'tags=' + viewTag?.id + ' subject:"' + keyword + '"',
        paging: {
          page: paging?.currentPage ?? 1,
          size: paging?.itemPerPage ?? 15,
        },
      };
    }
    return filter;
  };
  const handleDelete = (selectedIds: string[]) => {};
  const quickButtons = [
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: (selected: any) => {
        if (selectedIds.length > 0) {
          handleDelete(selectedIds);
        }
      },
    },
  ];
  const {
    data: ticketsList,
    isFetching: ticketFetching,
    refetch: reloadTickets,
  } = useTicketsByTag(getParam());
  const handleCheckItem = useCallback(
    (checkedValue: any[]) => {
      setSelectedIds(checkedValue);
    },
    [selectedIds],
  );
  const handleSearch = (nVal: string) => {
    setKeyword(nVal);
    reloadTickets();
  };
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      // {
      //   id: 'select',
      //   width: '45px',
      //   header: ({ table }) => (
      //     <Checkbox
      //       {...{
      //         checked: table.getIsAllRowsSelected(),
      //         indeterminate: table.getIsSomeRowsSelected(),
      //         onChange: table.getToggleAllRowsSelectedHandler(),
      //       }}
      //     />
      //   ),
      //   cell: ({ row }) => (
      //     <div className="pd-x-1">
      //       <Checkbox
      //         {...{
      //           checked: row.getIsSelected(),
      //           indeterminate: row.getIsSomeSelected(),
      //           onChange: row.getToggleSelectedHandler(),
      //         }}
      //       />
      //     </div>
      //   ),
      // },
      {
        id: 'priority',
        header: ({ table }) => <span>Priority</span>,
        cell: ({ row }: any) => {
          // console.log('row', row);
          return (
            <>
              {row.original?.priority?.keyName ? (
                <Priority value={row.original?.priority.keyName} />
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        id: 'customer',
        header: ({ table }) => <span>Customer</span>,
        cell: ({ row }: any) => {
          return <>{row.original?.customer?.name ?? ''}</>;
        },
      },
      {
        id: 'subject',
        header: ({ table }) => <span>Subject</span>,
        cell: ({ row }: any) => {
          return <>{row.original?.subject}</>;
        },
      },
      {
        id: 'process',
        header: ({ table }) => <span>Process</span>,
        cell: ({ row }: any) => {
          return <>{row.original?.process?.name ?? ''}</>;
        },
      },
      {
        id: 'assignedGroup',
        header: ({ table }) => <span>Assigned Group</span>,
        cell: ({ row }: any) => {
          return <>{row.original?.assignedGroup?.name ?? ''}</>;
        },
      },
      {
        id: 'assignedUser',
        header: ({ table }) => <span>Assigned Rep</span>,
        cell: ({ row }: any) => {
          return <>{row.original?.assignedUser?.user ? row.assignedUser?.user.name : ''}</>;
        },
      },
    ],
    [listData, selectedIds],
  );
  const listTableProps = {
    nodata: <TableNothing />,
    data: listData,
    loading: ticketFetching,
    columns: columns, //tableHideColumns
    initialState: {
      pageSize: ticketsList?.paging?.itemPerPage || 15,
    },
    onRowSelect: handleCheckItem,
    // rowSelected: selectedIds,
    className: 'tag-refer-list',
  };
  //table paging
  const pagingProps = {
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: paging?.itemPerPage || 15,
    //isPreviousData,
    onChange: (page: number, size: number) => {
      const nPaging: Paging | null = paging
        ? {
            ...paging,
            currentPage: page,
            itemPerPage: size,
          }
        : null;
      setPaging(nPaging);
    },
  };
  useEffect(() => {
    if (ticketsList && !ticketFetching) {
      setListData(ticketsList.results ?? []);
      setPaging(ticketsList.paging);
    }
  }, [ticketsList]);

  return (
    <>
      {ticketFetching ? <Loading /> : ''}
      <div className="pd-20 scroll-box setting-tab-body">
        <div className="card list-wrap">
          <div className="card-header d-flex align-items-center pd-10">
            <Button
              color="link"
              icon="ArrowLeft"
              className="btn-icon mg-r-10"
              onClick={() => {
                goList();
              }}
            />
            {viewTag?.name ?? ''}
            <div className="input-group wd-300 mg-l-auto">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <FormIcon icon="Search" className="tx-gray-400" />
                </span>
              </div>
              <Input type="text" value={keyword} onChange={(nVal: string) => handleSearch(nVal)} />
            </div>
          </div>
          <ListReactTable8 {...listTableProps} />
          <ListPagination isSplitMode={false} type={'full'} {...pagingProps} />
          {/* {selectedIds?.length && (
            <ListQuickAction
              checked={selectedIds}
              buttons={quickButtons}
              resetSelected={() => setSelectedIds([])}
              visible={selectedIds.length > 0}
            />
          )} */}
        </div>
      </div>
    </>
  );
};

export default TicketList;
