import { Input, RadioGroup, TextArea } from '@base/config/write-field/components';
import { OptionValue } from '@base/types/interfaces/common';
import { LabelValue } from '@base/types/types/app';
import validators from '@base/utils/validation/field-validator';
import { CriteriaValue } from '@settings/process/components/criteria-automation/CriteriaAutomation';
import {
  KEY_NAME_AUTOMATION_CRITERIA,
  KEY_NAME_AUTOMATION_DESCRIPTION,
  KEY_NAME_AUTOMATION_NAME,
  KEY_NAME_AUTOMATION_TRIGGER,
  KEY_NAME_AUTOMATION_TYPE,
} from '@settings/process/config/keyNames';
import {
  CriteriaAutomation,
  TriggerAutomation,
} from '@settings/process/config/write-field/components';
import { InstantType, InstantValue } from '@settings/process/types/automation';

import { AUTOMATION_TYPE_OPTIONS } from '../constants';

const AutomationWriteField = {
  [KEY_NAME_AUTOMATION_NAME]: {
    component: Input,
    showFullRow: false,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    languageKey: 'automation_name',
    parseParam: (value: string) => value,
  },
  [KEY_NAME_AUTOMATION_TYPE]: {
    component: RadioGroup,
    defaultValue: AUTOMATION_TYPE_OPTIONS[0],
    componentProps: {
      options: AUTOMATION_TYPE_OPTIONS,
      isHorizontal: true,
    },
    showFullRow: false,
    languageKey: 'automation_type',
    parseParam: (v: LabelValue) => v.value,
  },
  [KEY_NAME_AUTOMATION_DESCRIPTION]: {
    component: TextArea,
    showFullRow: true,
    languageKey: 'automation_description',
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [KEY_NAME_AUTOMATION_TRIGGER]: {
    component: TriggerAutomation,
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    componentProps: {
      triggerModule: '',
    },
    languageKey: 'automation_trigger',
    parseParam: (v: OptionValue) => {
      return { id: v.keyName, name: v.languageKey, module: v.extra?.module };
    },
  },
  [KEY_NAME_AUTOMATION_CRITERIA]: {
    component: CriteriaAutomation,
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    languageKey: 'automation_criteria',
    parseParam: (v: CriteriaValue) => {
      let instants: InstantValue[] = [];
      Object.keys(v.instants).forEach((idx) => {
        const instant = v.instants[idx];
        instant.forEach((o) => {
          let newInstant: InstantValue = {
            name: o.name,
            type: o.type.keyName as InstantType,
            criteria: idx,
          };

          if (o.template) {
            newInstant.template = {
              id: o.template.keyName,
              name: o.template.languageKey,
            };
          }

          if (o.type.keyName == 'ACTION_TYPE_TASK') {
            newInstant.targets = o.targetUsers?.map((target) => ({
              id: target.value as string,
              name: target.label,
            }));
          } else if (o.type.keyName == 'ACTION_TYPE_EMAIL') {
            newInstant.targets = o.targetCustomers?.map((target) => ({
              id: target.value as string,
              name: target.label,
            }));
          } else if (o.type.keyName == 'ACTION_TYPE_FIELD_UPDATE') {
            newInstant.field = o.field && {
              name: o.field.field.keyName,
              value: o.field?.value,
              type: o.field.field.extra,
            };
          } else if (o.type.keyName == 'ACTION_TYPE_OUTBOUND_MESSAGE') {
            newInstant.message = o.message;
          }
          instants.push(newInstant);
        });
      });
      return {
        criteria: v.criteria && {
          id: v.criteria.keyName,
          name: v.criteria.languageKey,
        },
        instants: instants,
      };
    },
  },
};

export default AutomationWriteField;
