import { formatSettingsAtom } from '@base/recoil/atoms';
import { ICountry } from '@base/types/interfaces/setting';
import { parseJSONTo } from '@base/utils/helpers';
import BusinessHours from '@settings/general-setting/containers/business-hours';
import CalendarSetting from '@settings/general-setting/containers/calendar-setting';
import CountrySetting from '@settings/general-setting/containers/country-setting';
import CurrencyStting from '@settings/general-setting/containers/currency-setting';
import DateSetting from '@settings/general-setting/containers/date-setting';
import FiscalPeriod from '@settings/general-setting/containers/fiscal-period';
import NumberSetting from '@settings/general-setting/containers/number-setting';
import TimeSetting from '@settings/general-setting/containers/time-setting';
import TimezoneSetting from '@settings/general-setting/containers/timezone-setting';
import { useFormatSettings } from '@settings/general-setting/services/format-service';
import React, { FC, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

const FormatSettingPage: FC<any> = (props: any) => {
  const { data, isLoading } = useFormatSettings();
  const setFormatSettings = useSetRecoilState(formatSettingsAtom);

  useEffect(() => {
    if (!isLoading && data && data.results.length > 0) {
      setFormatSettings(data.results);
    }
  }, [data]);

  return (
    <div className="ht-100p pd-15 overflow-auto general-setting-container">
      <div className="card-columns settings-columns">
        {/* Number of Decimal */}
        <NumberSetting />

        {/* Currency */}
        <CurrencyStting />
        {/* Time Format */}
        <TimeSetting />

        {/* Date Format */}
        <DateSetting />

        {/* Country and region calling code */}
        <CountrySetting />

        {/* Time zone */}
        <TimezoneSetting />

        {/* Calendar */}
        {/* <CalendarSetting /> */}

        {/* Business Hours */}
        {/* <BusinessHours /> */}

        {/* Fiscal Period */}
        {/* <FiscalPeriod /> */}
      </div>
    </div>
  );
};
export default FormatSettingPage;
