import BasicTable from '@base/components/table/basic';
import withLoading from '@base/hooks/hocs/withLoading';
import useMutationPost from '@base/hooks/useMutationPost';
import { countriesAtom, formatSettingsAtom } from '@base/recoil/atoms';
import { countrySettingSelector } from '@base/recoil/selectors';
import { BaseResponse } from '@base/types/interfaces/response';
import { ICountry } from '@base/types/interfaces/setting';
import { UPDATE_FORMAT_SETTING } from '@settings/general-setting/services/graphql/format';
import React, { useMemo } from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import CountrySelect from '../country-select';

interface ICountrySettingProps {
  setLoading: (params: any) => void;
  data: ICountry[] | undefined;
}
const CountrySetting: React.FC<ICountrySettingProps> = (props: ICountrySettingProps) => {
  const { setLoading } = props;
  const data: ICountry[] = useRecoilValue(countrySettingSelector);
  const setUsedCountries = useSetRecoilState(countriesAtom);
  const [formatSettings, setFormatSettings] = useRecoilState(formatSettingsAtom);
  let countryValue: ICountry[] = data ? data : [];
  const settingKey = 'country';
  const mUpdateFormat: any = useMutationPost<BaseResponse<string>>(
    UPDATE_FORMAT_SETTING,
    'setting_updateFormatSetting',
    {},
  );
  const updateFormatSetting = (value: any) => {
    // save to server
    countryValue = value;
    mUpdateFormat.mutate(
      { key: settingKey, value: JSON.stringify(value) },
      {
        onSuccess: (res: any) => {
          toast.success('Setting saved!');
          setLoading(false);
          // update format settings
          const newSettings = formatSettings.map((item: any) => {
            if (item.key == settingKey) {
              // item.value = JSON.stringify(countryValue);
              return {
                ...item,
                value: JSON.stringify(value),
              };
            }
            return item;
          });
          setFormatSettings(newSettings);
          setUsedCountries(value);
        },
        onError: (res: any) => {
          toast.error('Saving has failed!');
          setLoading(false);
        },
      },
    );
  };

  const onChange = (countrySelect: any) => {
    const isExist = countryValue.find((country: any) => country.isoCode3 == countrySelect.isoCode3);
    if (isExist) {
      toast.warning('Country code is existed');
    } else {
      setLoading(true);
      const countries = countryValue.concat([countrySelect]);
      updateFormatSetting(countries);
      // update usedCountries
    }
  };

  const onDelete = (countrySelect: any) => {
    setLoading(true);
    const countries = countryValue.filter(
      (country: any) => country.isoCode3 != countrySelect.isoCode3,
    );
    updateFormatSetting(countries);
  };

  const onSetDefault = (countrySelect: any) => {
    setLoading(true);
    const countries = countryValue.map((country: any) => ({
      ...country,
      isDefault: country.isoCode3 == countrySelect.isoCode3,
    }));
    updateFormatSetting(countries);
  };
  const columnsTable = useMemo(
    () => [
      {
        Header: 'Country Code',
        accessor: (row: any, rowIndex: any) => `${row.country} +${row.phoneCode}`,
      },
      {
        Header: 'Default',
        accessor: (row: any, rowIndex: any) => (
          <div className="custom-control custom-radio">
            <input
              type="radio"
              className="custom-control-input"
              name={`defaultCountryCode${rowIndex}`}
              id={`defaultCountryCode${rowIndex}`}
              checked={row.isDefault}
              onChange={() => onSetDefault(row)}
            />
            <label
              className="custom-control-label"
              htmlFor={`defaultCountryCode${rowIndex}`}
            ></label>
          </div>
        ),
      },
      {
        Header: 'Delete',
        accessor: (row: any, rowIndex: any) => (
          <button type="button" className="btn btn-icon pd-0" onClick={() => onDelete(row)}>
            <X className="tx-danger" />
          </button>
        ),
      },
    ],
    [countryValue],
  );

  return (
    <div className="card">
      <div className="card-header h6 bg-light">Country/region code</div>
      <BasicTable
        wrapperClassName=""
        className="settings-tb"
        columns={columnsTable}
        data={countryValue || []}
      />
      <div className="card-footer">
        <CountrySelect onChange={onChange} />
      </div>
    </div>
  );
};

export default withLoading(CountrySetting);
