import { keys } from 'lodash';

export const finalizeParams = (params: any, variableKey = 'cta') => {
  let newParams: any = {
    linkUrl: params?.linkUrl?.link ?? '',
    openPageInNewWindow: params?.linkUrl?.openNewWindow ?? false.valueOf,
  };

  delete params.ctaValue;
  delete params.linkUrl;
  delete params.resourceType;

  const { ...remainParams } = params;

  newParams = {
    ...newParams,
    ...remainParams,
  };

  return {
    [variableKey]: newParams,
  };
};
