import React from 'react';
import CommonViewField from '@base/containers/view-field/common';
import { useRecoilValue } from 'recoil';
import { MENU_SETTING_LANDINGPAGE } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import * as keyNames from '@settings/online-digital-content/landing-page/config/key-names';
import { LANDING_PAGE_PUBLISH_SCHEDULE_LATER } from '@settings/online-digital-content/landing-page/config/constants';

import View from './view';
import Edit from './edit';

const PublishDateViewField: React.FC = (props: any) => {
  const { keyName, value, menuSource, menuSourceId } = props;

  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_SETTING_LANDINGPAGE));

  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
  const publishData = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_LANDINGPAGE_PUBLISH,
  );

  const userPermission =
    publishData?.data === LANDING_PAGE_PUBLISH_SCHEDULE_LATER
      ? { isShow: true, isEdit: true }
      : { isShow: true, isEdit: false };

  return (
    <CommonViewField
      keyName={keyName}
      value={value}
      menuSource={menuSource}
      menuSourceId={menuSourceId}
      userPermission={userPermission}
      componentView={View}
      componentEdit={Edit}
    />
  );
};

export default PublishDateViewField;
