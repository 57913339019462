import { Input } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_RESOLVE_SLA_SETTING } from '@settings/preferences/services/graphql';
import { useResolveSLASetting } from '@settings/preferences/services/service';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

interface IResolveSLA {
  standardSla: string;
  premiumSla: number;
}

interface ITimeResolveSlaProps {}
const TimeResolveSla: React.FC<ITimeResolveSlaProps> = (props: ITimeResolveSlaProps) => {
  const defaultData: IResolveSLA[] = [
    {
      standardSla: 'Followed by Category',
      premiumSla: 0,
    },
  ];
  const [items, setItems] = useState<IResolveSLA[] | null>(defaultData);
  const { data, isLoading } = useResolveSLASetting();
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_RESOLVE_SLA_SETTING,
    'setting_updateMenuSetting',
  );
  const onSave = (nData: IResolveSLA) => {
    const nSetting = {
      menu: 'desk',
      key: 'resolve_sla',
      value: JSON.stringify(nData),
    };
    mUpdate.mutate(
      { menuSetting: nSetting },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
        },
      },
    );
  };
  const onChangePremimuSLA = (nRow: IResolveSLA) => {
    onSave(nRow);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Standard SLA',
        accessor: (row: IResolveSLA, rowIndex: any) => {
          return <>{row.standardSla}</>;
        },
      },
      {
        Header: 'Premium SLA',
        accessor: (row: IResolveSLA, rowIndex: any) => {
          return (
            <>
              <div className="d-flex align-items-center wd-150">
                -
                <Input
                  type="number"
                  rightIcon="%"
                  value={row.premiumSla}
                  className="mg-l-5"
                  onBlur={(nVal: number) => {
                    const nRow: IResolveSLA = {
                      ...row,
                      premiumSla: nVal,
                    };
                    onChangePremimuSLA(nRow);
                  }}
                />
              </div>
            </>
          );
        },
      },
    ],
    [items],
  );

  useEffect(() => {
    if (!isLoading && data?.value) {
      try {
        const slas: IResolveSLA = JSON.parse(data.value);
        // console.log('data', slas);
        setItems([slas]);
      } catch (err: any) {}
    }
  }, [data]);
  // console.log('resolve-sla', items);
  return (
    <>
      <div className="card mg-b-15">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Time to Resolve by SLA</div>
        </div>
        <BasicTable
          columns={columns}
          data={items ?? []}
          className="settings-tb"
          wrapperClassName="remove-min-width"
          isLoading={isLoading}
        ></BasicTable>
      </div>
    </>
  );
};

export default TimeResolveSla;
