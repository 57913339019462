import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, pageDataByMenuAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { useGetView } from '@base/services/view';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import Title from '@base/containers/view-field/title';
import { Button, SpanLang } from '@base/components/form';
import { SETTING_ONLINE_DIGITAL_MENUS } from '@base/config/route-menus';
import { MENU_SETTING_LANDINGPAGE } from '@base/config/menus';
import Loading from '@base/components/loading';

import displayLayoutConfig from '@settings/online-digital-content/landing-page/config/display-layout';
import ViewLeft from '@settings/online-digital-content/landing-page/layouts/desktop/layout1/view/view-left';
import { default as viewConfig } from '@settings/online-digital-content/landing-page/config/view-field';
import { landingPageWriteOptionAtom } from '@settings/online-digital-content/landing-page/recoil/atom/write-atom'; //for write
import WritePage from '../write';
import { KEY_LANDINGPAGE_NAME } from '@settings/online-digital-content/landing-page/config/key-names';
// import { getTabs } from './tabs';
// import LandingPageStatus from '@settings/online-digital-content/landing-page/containers/landing-page-status';
// import ViewCenter from '../../layouts/desktop/layout1/view/view-center';
import NotFound from '@base/components/errors/notFound';
import {
  LANDING_PAGE_PUBLISH_SCHEDULE_PUBLISH_NOW,
  LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH,
} from '../../config/constants';
import { useQueryClient } from '@tanstack/react-query';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { LANDINGPAGE_DELETE, LANDINGPAGE_UPDATE } from '../../services/graphql';
import Icon from '@base/assets/icons/svg-icons';
import { getTabs } from './tabs';

interface IViewProps {
  isSplitMode?: boolean;
}

/**
 * Pages: detect layout, device, init props
 */
const ViewPage: React.FC<IViewProps> = (props: IViewProps) => {
  const { isSplitMode } = props;

  //recoil
  const listFilter = useRecoilValue(pageDataByMenuAtom(MENU_SETTING_LANDINGPAGE));
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_SETTING_LANDINGPAGE));
  //params + state
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { activeId, activeTab } = listFilter;
  let id = activeId != params.id ? params.id ?? '' : activeId ?? '';
  let currentTab = params['*'] && activeTab != params['*'] ? params['*'] : activeTab;
  //for write form
  const [writeOption, setWriteOption] = useRecoilState(landingPageWriteOptionAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  /** Hook + Get data */
  const { data: layoutView, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    MENU_SETTING_LANDINGPAGE,
    'view',
  );
  //// console.log('layoutView', layoutView);
  const viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  const {
    isLoading,
    data: landingPageData,
    refetch,
  } = useGetView<any>({ menu: 'setting_landingPage', schemas: viewSchema, params: { id } });

  // delete mutation
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    LANDINGPAGE_DELETE,
    'setting_deleteLandingPage',
    {
      onMutate: () => {
        setIsDeleting(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Deleted Landing Page successfully!');
        setIsDeleting(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['setting_landingPages']);
        }, 500);
        return navigate(`/settings/online-digital-content/landing-page`);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        setIsDeleting(false);
        toast.error('Deleted Landing Page failed: ' + JSON.parse(error).message);
      },
    },
  );

  // publish mutation
  const mutationPublish: any = useMutationPost<BaseMutationResponse>(
    LANDINGPAGE_UPDATE,
    'setting_updateLandingPage',
    {
      onMutate: () => {
        setIsPublishing(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Published Landing Page successfully!');
        setIsPublishing(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['setting_landingPage']);
        }, 500);
        // return navigate(`/settings/online-digital-content/landing-page`);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        setIsPublishing(false);
        toast.error('Published Landing Page failed: ' + JSON.parse(error).message);
      },
    },
  );

  //get detail data
  useEffect(() => {
    const layoutData = mergeLayoutData(layoutView, landingPageData, viewConfig);
    let newLayoutData: any = { ...layoutData };
    newLayoutData.menuSourceId = id;
    newLayoutData.menuSource = MENU_SETTING_LANDINGPAGE;
    setViewData(newLayoutData);
  }, [landingPageData, isLoading, isLayoutLoading]);

  const handlePublishClick = () => {
    const params = {
      landingPage: {
        id: id,
        publish: LANDING_PAGE_PUBLISH_SCHEDULE_PUBLISH_NOW,
      },
    };
    mutationPublish.mutate(params);
  };

  //render
  const renderView = () => {
    const keyTitle = `crm_new_${writeOption.writeType}`;

    //props config
    let pageProps: IDestopLayout1ViewProps = {
      headerProps: {
        menu: 'landing-page',
        menus: SETTING_ONLINE_DIGITAL_MENUS,
        isDeleting: isDeleting,
        onDelete: () => mutationDelete.mutate({ ids: [id] }),
        onNew: (category: string | undefined) => {
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: 'landingPage',
            writeMenu: 'view',
          }));
        },
        splitViewMode: isSplitMode,
        componentLeft: (
          <>
            <Title
              keyName={KEY_LANDINGPAGE_NAME}
              value={landingPageData?.[KEY_LANDINGPAGE_NAME] || ''}
              userPermission={{ isEdit: true }}
              menuSourceId={id}
              menuSource={MENU_SETTING_LANDINGPAGE}
              viewConfig={viewConfig}
            />
          </>
        ),
        componentRight: (
          // Show publish button when publish is unpublish
          <div className="">
            {landingPageData?.publish == LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH && (
              <Button
                color="primary"
                type="button"
                className="btn btn-primary"
                onClick={handlePublishClick}
                loading={isPublishing}
              >
                <span className="mg-r-5">{Icon('publish')}</span>
                {`Publish`}
              </Button>
            )}
          </div>
        ),
      },
      //componentCenter: <ViewCenter id={id} />,
      centerProps: {
        menuSource: MENU_SETTING_LANDINGPAGE,
        menuSourceId: id,
        tabs: getTabs(id),
        currentTab: currentTab,
      },
      // componentTop: <LandingPageStatus />,
      // topProps: {},
      componentLeft: <ViewLeft id={id} />,
      leftProps: {},
      componentRight: null,
      rightProps: {},
    };

    return (
      <>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="view"
          {...pageProps}
        />
        {/* hook form - write new product write */}
        {writeOption.isOpenWrite &&
          writeOption.writeType === 'landingPage' &&
          writeOption.writeMenu === 'view' && (
            <WritePage
              title={<SpanLang keyLang={`Create LandingPage`} />}
              size="xl"
              fullScreen={fullScreen}
              setFullScreen={setFullScreen}
              isOpen={writeOption.isOpenWrite}
              onClose={() =>
                setWriteOption((filter) => ({
                  ...filter,
                  isOpenWrite: false,
                }))
              }
              onReload={refetch}
              menuApi={MENU_SETTING_LANDINGPAGE}
            />
          )}
      </>
    );
  };

  //render
  return (
    <>
      {isLoading || isLayoutLoading ? <Loading /> : landingPageData ? renderView() : <NotFound />}
    </>
  );
};

export default ViewPage;
