import { Input } from '@base/components/form';
import ImageUploader from '@base/components/form/image-uploader';
import React, { useState } from 'react';

interface IBillingAccount {
  value: any;
  onChange: (keyName: string, keyValue: string) => any;
}

const BillingInfoAccount: React.FC<IBillingAccount> = (props: IBillingAccount) => {
  const { value, onChange } = props;
  const handleChange = (keyName: string, keyValue: any) => {
    const newVal = { ...value.account };
    newVal[keyName] = keyValue;

    onChange && onChange('account', newVal);
  };
  return (
    <div className="card">
      <div className="card-body">
        <div className="form-group">
          <label className="form-item-title">Account Holder Name</label>
          <Input
            type="text"
            value={value?.account?.name}
            onChange={(data: any) => handleChange('name', data)}
          />
        </div>
        <div className="form-group">
          <label className="form-item-title">Your Logo</label>
          <ImageUploader
            isSingle={true}
            type={'img'}
            value={value?.account?.logo}
            onChange={(data: any) => handleChange('logo', data)}
          />
          <div className="mg-t-5 tx-info">This logo will apeear on the many documents.</div>
        </div>
      </div>
    </div>
  );
};
export default BillingInfoAccount;
