import {
  LOCAL_SITE_BUILDER_URL,
  LOCAL_SITE_BUILDER_VENDOR_URL,
  SITE_BUILDER_URL,
  SITE_BUILDER_VENDOR_URL,
} from '@base/config/site-builder';

export function getBuilderUrl() {
  let serverAPI = SITE_BUILDER_URL;
  if (document.location.origin.indexOf('localhost') != -1) {
    serverAPI = LOCAL_SITE_BUILDER_URL;
  }
  // console.log('server api', serverAPI);
  return serverAPI;
}
export function getVendorBuilderUrl() {
  let serverAPI = SITE_BUILDER_VENDOR_URL;
  if (document.location.origin.indexOf('localhost') != -1) {
    serverAPI = LOCAL_SITE_BUILDER_VENDOR_URL;
  }
  // console.log('server api', serverAPI);
  return serverAPI;
}
