import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { MENU_SETTING_TICKET_FORM } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { ViewFields } from '@base/components/hook-form/view';
import { Button, Select } from '@base/components/form';
import * as keyNames from '@settings/online-digital-content/ticket-form/config/key-names';
import Icon from '@base/assets/icons/svg-icons';

interface IViewLeftProps {
  id: string;
}

const ViewLeft: React.FC<IViewLeftProps> = (props) => {
  const { id } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_SETTING_TICKET_FORM));

  // hidden fields
  const ignoreFields: string[] = [];
  const summaryKeyNames: any[] = [
    keyNames.KEY_TICKET_FORM_LANGUAGE,
    keyNames.KEY_TICKET_FORM_PRODUCTS,
    keyNames.KEY_TICKET_FORM_DESCRIPTION,
    'createdBy',
    'updatedAt',
  ];
  const optionKeyNames: any[] = [
    keyNames.KEY_TICKET_FORM_SUBMISSION_DISPLAY,
    keyNames.KEY_TICKET_FORM_SUBMISSION_BEHAVIOR,
  ];

  // get fields by group
  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
  const moreFields = viewData?.layout?.data?.[1]?.children ?? [];

  // get summary fields
  const summaryFields: any[] = [];
  const optionFields: any[] = [];
  basicFields.map((_field: any) => {
    if (summaryKeyNames.includes(_field.keyName)) {
      summaryFields.push(_field);
    }
    if (optionKeyNames.includes(_field.keyName)) {
      optionFields.push(_field);
    }
  });

  moreFields?.map((_field: any) => {
    if (summaryKeyNames.includes(_field.keyName)) {
      summaryFields.push(_field);
    }
  });

  // render sections
  const SummarySection = useMemo(() => {
    return (
      <>
        <div className="view-aside-section">
          <div className="view-aside-section-header">
            <button type="button" className="view-aside-section-move mg-r-5">
              {Icon('move')}
            </button>
            <span className="view-aside-section-header-title">Summary</span>
            <Button
              color="link link-03"
              className="mg-l-auto pd-0"
              icon="ChevronDown"
              data-toggle="collapse"
              data-target="#abmSummary"
            />
          </div>
          <div id="abmSummary" className="view-aside-section-body collapse fade show">
            <div className="form-row mg-x-0 mg-t-10">
              <ViewFields
                fields={summaryFields}
                ignoreFields={ignoreFields}
                menu={viewData?.menu ?? ''}
                menuSource={viewData?.menuSource ?? ''}
                menuSourceId={viewData?.menuSourceId ?? ''}
              />
            </div>
          </div>
        </div>
        <div className="view-aside-section">
          <div className="view-aside-section-header">
            <button type="button" className="view-aside-section-move mg-r-5">
              {Icon('move')}
            </button>
            <span className="view-aside-section-header-title">Submission setting</span>
            <Button
              color="link link-03"
              className="mg-l-auto pd-0"
              icon="ChevronDown"
              data-toggle="collapse"
              data-target="#abmOptions"
            />
          </div>
          <div id="abmOptions" className="view-aside-section-body collapse fade show">
            <div className="form-row mg-x-0 mg-t-10">
              <ViewFields
                fields={optionFields}
                ignoreFields={ignoreFields}
                menu={viewData?.menu ?? ''}
                menuSource={viewData?.menuSource ?? ''}
                menuSourceId={viewData?.menuSourceId ?? ''}
              />
            </div>
          </div>
        </div>
      </>
    );
  }, [id, basicFields]);

  return (
    <div className="scroll-box" style={{ height: 'calc(100vh - 110px)' }}>
      {SummarySection}
    </div>
  );
};

export default ViewLeft;
