import { EChannelType, EDeskAssignType, EOperationalHours } from '../types/enums';

export const SLA_LANGS: any = {
  SLA_PREMIUM: 'Premium SLA',
  SLA_STANDARD: 'Standard SLA',
};

export const OPERATIONAL_HOURS_OPTIONS = [
  { value: EOperationalHours.BUSINESS_HOURS, label: 'Business Hours' },
  { value: EOperationalHours.CALENDAR_HOURS, label: 'Calendar Hours' },
];

export const DURATION_OPTIONS = [
  { value: 'UNIT_MINUTE', label: 'mins' },
  { value: 'UNIT_HOUR', label: 'hrs' },
  { value: 'UNIT_DAY', label: 'days' },
];

export const CTYPE_OPTIONS = [
  { value: EChannelType.DIRECT_INPUT, label: 'Direct Input' },
  { value: EChannelType.EMAIL, label: 'Email' },
  { value: EChannelType.LANDING_PAGE, label: 'Landing Page' },
];

export const ATYPE_OPTIONS = [
  { value: EDeskAssignType.USER, label: 'User' },
  { value: EDeskAssignType.GROUP, label: 'Group' },
];
