import classNames from 'classnames';
import React from 'react';

interface IPlaceholderItemUnusedProps {}
const PlaceholderItemUnused: React.FC<IPlaceholderItemUnusedProps> = (
  props: IPlaceholderItemUnusedProps,
) => {
  return (
    <div className={classNames('page-layout-item-unused bd')}>
      <div className={classNames('view-name')}>
        <div className="d-flex align-items-center pd-r-10 ht-10">
          <div className="wd-20 ht-20 navata-placeholder"></div>
          <div className="ht-10 navata-placeholder mg-x-10" style={{ flex: 1 }}></div>
          <div className="wd-40 ht-10 navata-placeholder"></div>
        </div>
      </div>
    </div>
  );
};

export default PlaceholderItemUnused;
