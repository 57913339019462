import { SpanLang } from '@base/components';
import { confirmAlert } from '@base/components/confirm-alert';
import { FormIcon, Tooltip } from '@base/components/form';
import { EPermissionType } from '@settings/page-layout/types/enums';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const IS_SHOW_IN_LIST = 'showInList';
const IS_SHOW_IN_VIEW = 'showInView';
const IS_SHOW_IN_WRITE = 'showInWrite';

const LOCATIONS = [
  {
    id: IS_SHOW_IN_LIST,
    label: 'crm_new_pagelayout_show_in_list',
    idShow: 'canShowInList',
  },
  {
    id: IS_SHOW_IN_VIEW,
    label: 'crm_new_common_show_in_view',
    idShow: 'canShowInView',
  },
  {
    id: IS_SHOW_IN_WRITE,
    label: 'crm_new_common_show_in_write',
    idShow: 'canShowInWrite',
  },
];

let timeOutClick: any = null;
interface IContexMenuItemProps {
  permissionType: EPermissionType;
  canDelete: boolean;
  settingButtonTooltip: string;
  canShowSettingButton: string;
  canMarkRequired: string;
  canShowInList: string;
  canShowInView: string;
  canShowInWrite: string;
  canMoveUnused: string;
  canEditProperty: string;
  isRequired: boolean;
  locationsDisplay: any;
  setIsRequired: (params: any) => void;
  setLocationsDisplay: (params: any) => void;
  totalColumn?: number;
  onDelete: () => void;
  onEdit: () => void;
  onUnused: () => void;
}
const ContexMenuItem: React.FC<IContexMenuItemProps> = (props: IContexMenuItemProps) => {
  const {
    permissionType = '',
    canDelete = false,
    settingButtonTooltip = 'crm_new_pagelayout_setting_button',
    canShowSettingButton = false,
    canMarkRequired = false,
    canShowInList = false,
    canShowInView = false,
    canShowInWrite = false,
    canMoveUnused = false,
    canEditProperty = false,
    isRequired = false,
    locationsDisplay = {
      showInList: false,
      showInView: false,
      showInWrite: false,
    },
    setIsRequired = () => {},
    setLocationsDisplay = () => {},
    totalColumn = 2,
    onDelete = () => {},
    onEdit = () => {},
    onUnused = () => {},
  } = props;
  const [isChange, setIsChange] = useState<any>(null);
  const [locationsDisplayBackup, setLocationsDisplayBackup] = useState(locationsDisplay);

  useEffect(() => {
    if (isChange) {
      clearTimeout(timeOutClick);
      timeOutClick = setTimeout(() => {
        setLocationsDisplay(locationsDisplayBackup);
      }, 1000);
    }
  }, [isChange]);

  const permission: any = {
    canShowInList: canShowInList,
    canShowInView: canShowInView,
    canShowInWrite: canShowInWrite,
  };
  const permissionOther: any = {
    canMarkRequired: canMarkRequired,
    canMoveUnused: canMoveUnused,
    canEditProperty: canEditProperty,
  };
  const isShowTitle = () => {
    let isShow = false;
    for (const key of Object.keys(permission)) {
      if (permission[key]) {
        isShow = true;
        break;
      }
    }
    return isShow;
  };

  const isShowOther = () => {
    let isShow = false;
    for (const key of Object.keys(permissionOther)) {
      if (permissionOther[key]) {
        isShow = true;
        break;
      }
    }
    return isShow;
  };

  const handleOnDelete = () => {
    confirmAlert({
      title: 'Delete Field',
      message: 'Are you sure you want to delete this field permanently?',
      buttons: [
        {
          label: 'No',
          className: 'btn-secondary',
        },
        {
          label: 'Yes',
          className: 'btn-primary',
          onClick: () => {
            onDelete();
          },
        },
      ],
    });
  };

  if (!canShowSettingButton || permissionType === EPermissionType.BASIC_FIXED) {
    return (
      <div className="page-layout-context-menu-item ">
        <Tooltip overlay={<SpanLang keyLang={settingButtonTooltip} />} placement="left">
          <i>
            <FormIcon icon="MoreVertical" iconType="feather" size={16} />
          </i>
        </Tooltip>
      </div>
    );
  }

  const isPermissionTypeSystem = () => {
    return permissionType == EPermissionType.SYSTEM;
  };

  const handleClickShow = (item: any) => {
    const { id } = item;
    const isShow = !locationsDisplayBackup[item.id];

    if (isPermissionTypeSystem()) {
      setLocationsDisplayBackup({
        ...locationsDisplayBackup,
        [id]: isShow,
      });
      setIsChange(new Date().getTime());
      return;
    }

    if (id != IS_SHOW_IN_WRITE && !locationsDisplayBackup[IS_SHOW_IN_WRITE]) {
      return;
    }
    if (id == IS_SHOW_IN_WRITE) {
      if (!isShow) {
        setLocationsDisplayBackup({
          showInList: false,
          showInView: false,
          showInWrite: isShow,
        });
      } else {
        setLocationsDisplayBackup({
          ...locationsDisplayBackup,
          [id]: isShow,
        });
      }
    } else {
      setLocationsDisplayBackup({
        ...locationsDisplayBackup,
        [id]: isShow,
      });
    }
    setIsChange(new Date().getTime());
  };

  if (!isShowOther() && !isShowTitle()) {
    return null;
  }
  return (
    <div className="page-layout-context-menu-item">
      <div className="dropdown">
        <div
          className="cursor-pointer"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FormIcon icon="MoreVertical" iconType="feather" size={16} />
        </div>
        <div className="dropdown-menu">
          <form>
            {isShowTitle() && (
              <>
                <h6 className="dropdown-header tx-uppercase tx-11 tx-bold tx-inverse tx-spacing-1">
                  <SpanLang keyLang="crm_new_pagelayout_location_display" />
                </h6>
                {!isPermissionTypeSystem() && (
                  <div className="tx-gray-500 pd-l-15">
                    <SpanLang
                      className="tx-10"
                      keyLang="crm_new_pagelayout_description_location_display"
                    />
                  </div>
                )}
                {LOCATIONS.map((item, index) =>
                  permission[item.idShow] ? (
                    <a
                      key={index}
                      className={classNames('dropdown-item', {
                        active: totalColumn == 1,
                      })}
                    >
                      <div
                        className="custom-control custom-checkbox"
                        onClick={(e) => {
                          e.preventDefault();
                          handleClickShow(item);
                        }}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={locationsDisplayBackup[item.id]}
                          disabled={
                            !isPermissionTypeSystem() &&
                            item.id != IS_SHOW_IN_WRITE &&
                            !locationsDisplayBackup[IS_SHOW_IN_WRITE]
                          }
                          onChange={(e) => {}}
                        />
                        <label className="custom-control-label">
                          <SpanLang keyLang={item.label} />
                        </label>
                      </div>
                    </a>
                  ) : null,
                )}
              </>
            )}
            {isShowOther() && isShowTitle() ? <div className="dropdown-divider"></div> : null}
            {canMarkRequired && (
              <a className="dropdown-item" onClick={() => setIsRequired(!isRequired)}>
                <i className="pd-r-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M19,5L5,19 M5,5l14,14 M12,3v18 M3,12h18"></path>
                  </svg>
                </i>
                {isRequired ? (
                  <SpanLang keyLang="crm_new_pagelayout_unmark_as_required" />
                ) : (
                  <SpanLang keyLang="crm_new_pagelayout_mark_as_required" />
                )}
              </a>
            )}
            {canEditProperty && (
              <a className="dropdown-item" onClick={() => onEdit()}>
                <i className="pd-r-4">
                  <FormIcon icon="Edit2" iconType="feather" size={16} />
                </i>
                <SpanLang keyLang="crm_new_pagelayout_edit_property" />
              </a>
            )}
            {canMoveUnused && (
              <a className="dropdown-item" onClick={() => onUnused()}>
                <i className="pd-r-4">
                  <FormIcon icon="EyeOff" iconType="feather" size={16} />
                </i>
                <SpanLang keyLang="crm_new_common_unused_fields" />
              </a>
            )}
            {canDelete && (
              <a className="dropdown-item item-delete" onClick={handleOnDelete}>
                <i className="pd-r-4">
                  <FormIcon icon="Trash2" iconType="feather" size={16} />
                </i>
                <SpanLang keyLang="crm_new_common_delete" />
              </a>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContexMenuItem;
