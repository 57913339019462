import { SpanLang } from '@base/components';
import { Input } from '@base/components/form';
import { AR_CRITERIA_OPTIONS_LANG } from '@settings/assignment-rule/config/constant';
import React, { useState } from 'react';
import SelectboxEdit from '@base/containers/view-field/selectbox/edit';
import LookupEdit from '@base/containers/view-field/lookup/edit';
import TagEdit from '@base/containers/view-field/tags/edit';
import ProductCategory from '@desk/ticket/containers/product-category';
import Classification from '@desk/ticket/containers/classification';

import {
  AR_MODULE_OPTIONS,
  AR_CRITERIA_CONDITION,
} from '@settings/assignment-rule/config/constant';
import {
  useDeleteTicketTag,
  useTicketChannels,
  useTicketTags,
} from '@desk/ticket/services/ticket-service';

interface ICriteriaProps {
  value: any;
  onChange: (nVal: any) => void;
}

const Criteria: React.FC<ICriteriaProps> = (props: ICriteriaProps) => {
  const { value, onChange } = props;
  const defaultItems: any[] = [
    {
      key: 'channel',
      condition: '',
    },
    {
      key: 'tag',
      condition: '',
    },
    {
      key: 'ticket_classification',
      condition: '',
    },
    {
      key: 'category',
      condition: '',
    },
  ];
  //B merge data and default
  let initItems = [...defaultItems];
  if (value.length) {
    value.map((item: any) => {
      initItems.map((item2, index2) => {
        if (item2.key == item.key) {
          initItems[index2] = item;
        }
      });
    });
  }
  //E merge data and default
  const [items, setItems] = useState<any[]>(initItems);

  const onChangeItem = (key: string, nVal: any) => {
    let nItems = items.map((item: any) => {
      if (item.key === key) {
        let setVal = nVal;
        return {
          ...item,
          condition: setVal,
        };
      }
      return item;
    });
    setItems(nItems);
    // console.log('>>>>>>>>> onChange nItems', nItems);
    onChange && onChange(nItems);
  };

  const handleRender = (item: any) => {
    if (item.key == 'channel') {
      return renderChannel(item);
    } else if (item.key == 'tag') {
      return renderTags(item);
    } else if (item.key == 'category') {
      return renderCategory(item);
    } else if (item.key == 'ticket_classification') {
      return renderClassification(item);
    } else {
      return renderSelect(item);
    }
  };

  const renderClassification = (item: any) => {
    return (
      <div className={'pos-relative wd-450'}>
        <Classification
          value={item.condition}
          onChange={(nVal: any) => onChangeItem(item.key, nVal)}
        />
      </div>
    );
  };
  const renderCategory = (item: any) => {
    return (
      <div className={'pos-relative wd-450'}>
        <ProductCategory
          value={item.condition}
          onChange={(nVal: any) => onChangeItem(item.key, nVal)}
        />
      </div>
    );
  };
  const renderTags = (item: any) => {
    return (
      <div className={'pos-relative wd-450'}>
        <TagEdit
          fetchList={useTicketTags}
          fieldValue={'id'}
          fieldLabel={'name'}
          value={item.condition}
          onChange={(nVal: any) => onChangeItem(item.key, nVal)}
        />
      </div>
    );
  };
  const renderChannel = (item: any) => {
    return (
      <div className={'pos-relative wd-450'}>
        <LookupEdit
          fetchList={useTicketChannels}
          fieldValue={'id'}
          fieldLabel={'name'}
          isSearch={false}
          isMultiple={false}
          value={item.condition}
          onChange={(nVal: any) => onChangeItem(item.key, nVal)}
        />
      </div>
    );
  };
  const renderSelect = (item: any) => {
    return (
      <SelectboxEdit
        options={AR_CRITERIA_CONDITION[item.key]}
        className="wd-450"
        value={item.condition}
        onChange={(nVal: string) => onChangeItem(item.key, nVal)}
      />
    );
  };

  const renderCondition = () => {
    return items?.map((m, index) => {
      let value: any = '';
      let label = m.key;
      //// console.log('m.condition >>>>>>>', m.condition);
      if (m.condition) {
        const condition = m.condition;
        if (m.key == 'channel') {
          label = 'Channel';
          value = condition.name;
        } else if (m.key == 'ticket_classification' && condition.region && condition.language) {
          label = 'Ticket Classification';
          value = condition.region.name + '/' + condition.language.name;
        } else if (m.key == 'category' && condition.product && condition.category) {
          label = 'Category';
          value = condition.product.name + '/' + condition.category.name;
        } else if (m.key == 'tag' && condition) {
          label = 'Tags';
          let values: string[] = [];
          condition?.map((m2: any) => {
            values.push(m2.name);
          });
          value = values.join(', ');
        }
      }
      if (value) {
        return (
          <span key={index}>
            {index > 0 && ` and `}
            <div className="tag-item">{label + ': ' + value}</div>
            {/* <br></br> */}
          </span>
        );
      } else {
        return <React.Fragment key={index}></React.Fragment>;
      }
    });
  };
  return (
    <>
      <div className="d-flex">
        <span className="mg-r-10 pd-x-12 pd-y-5 bg-primary tx-white">2</span>
        <span className="flex-grow-1 pd-t-5">Select the criteria</span>
      </div>
      <ul className="list-group mg-t-10 mg-b-20">
        {items.length
          ? items.map((item, index) => {
              return (
                <li
                  key={index}
                  className="list-group-item d-flex align-items-center justify-content-between pd-x-10 bg-white"
                >
                  <SpanLang keyLang={AR_CRITERIA_OPTIONS_LANG[item.key]} />
                  {handleRender(item)}
                </li>
              );
            })
          : ''}
        <li
          key={items.length + 1}
          className="list-group-item d-flex align-items-center justify-content-between pd-x-10 bg-white"
        >
          <SpanLang keyLang={'Conditions Selected'} />
          <div className={'pos-relative wd-450'}>{renderCondition()}</div>
        </li>
      </ul>
    </>
  );
};

export default Criteria;
