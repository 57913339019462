import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { convertDateTimeServerToClient } from '@base/utils/helpers/general.utils';

const View: React.FC = (props: any) => {
  const { value } = props;
  return (
    <TextView
      value={convertDateTimeServerToClient({ date: value, isTime: true, humanize: true })}
    />
  );
};

export default View;
