import { Input } from '@base/components/form';
import useMutationPost from '@base/hooks/useMutationPost';
import { IdName } from '@base/types/interfaces/common';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { generateUUID } from '@base/utils/helpers';
import SurveyAutoComplete from '@settings/online-digital-content/survey/containers/survey-auto-complete';
import { UPDATE_SURVEY_TEMPLATE_SETTING } from '@settings/preferences/services/graphql';
import { useSurveyTemplateSetting } from '@settings/preferences/services/service';
import React, { useEffect, useState } from 'react';
import { Plus, X, List } from 'react-feather';
import { toast } from 'react-toastify';

interface ISurveyTemplateProps {}
const SurveyTemplate: React.FC<ISurveyTemplateProps> = (props: ISurveyTemplateProps) => {
  const [items, setItems] = useState<IdName[]>([]);
  const [addTemplate, setAddTemplate] = useState('');
  const { data, isLoading } = useSurveyTemplateSetting();
  const [selSurvey, setSelSurvey] = useState<any>(null);

  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_SURVEY_TEMPLATE_SETTING,
    'setting_updateMenuSetting',
  );
  const onSave = (nData: IdName[]) => {
    const nSetting = {
      menu: 'desk',
      key: 'survey_template',
      value: JSON.stringify(nData),
    };
    mUpdate.mutate(
      { menuSetting: nSetting },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
          setSelSurvey(null);
        },
      },
    );
  };
  const onAdd = (item: any) => {
    const nItem = {
      id: item.id,
      name: item.name,
    };
    const found = items.find((s) => {
      return s.id == item.id;
    });
    if (found) {
      return;
    }
    const nItems = [nItem, ...items];
    onSave(nItems);
    setItems(nItems);
  };
  const onRemove = (deIdx: number) => {
    const nItems = items.filter((item, idx) => {
      return idx != deIdx;
    });
    onSave(nItems);
    setItems(nItems);
  };
  useEffect(() => {
    if (!isLoading && data?.value) {
      try {
        const templates: IdName[] = JSON.parse(data.value);
        // console.log('data', templates);

        setItems(templates);
      } catch (err: any) {}
    }
  }, [data]);
  return (
    <>
      <div className="card mg-b-15">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Satisfaction Survey Tempate of Desk Site</div>
        </div>
        <div className="card-body pd-0-f overflow-auto">
          {isLoading && (
            <div className="wrap-hanloading">
              <div className="han-loading" />
            </div>
          )}

          <ul className="list-group survey-template">
            {!isLoading &&
              items.map((item, idx) => {
                return (
                  <li
                    className="list-group-item d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bg-white"
                    key={idx}
                  >
                    <div className="media align-items-center">
                      <List />
                      <div className="media-body mg-l-10">{item.name}</div>
                    </div>
                    <button
                      type="button"
                      className="btn mg-l-auto pd-0"
                      data-han-tooltip="Delete"
                      onClick={() => {
                        onRemove(idx);
                      }}
                    >
                      <X className="tx-danger" />
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="card-footer">
          <div className="d-flex">
            <SurveyAutoComplete
              value={selSurvey}
              onChange={(nVal: any) => {
                setSelSurvey(nVal);
                onAdd(nVal);
              }}
              single
              className="wd-100p"
            />
            {/* <Input
              type="text"
              className="form-control mg-r-5"
              value={addTemplate}
              onBlur={(nVal: string) => {
                setAddTemplate(nVal);
              }}
            />
            <button
              type="button"
              className="btn btn-sm btn-primary flex-shrink-0"
              onClick={() => {
                onAdd();
              }}
            >
              <Plus className="mg-r-5" />
              Add
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyTemplate;
