import { ViewFields } from '@base/components/hook-form/view';
import Loading from '@base/components/loading';
import usePost from '@base/hooks/usePost';
import { useModalSizeAtomState } from '@base/recoil/atoms';
import { ViewFieldParse } from '@base/utils/helpers/no-layout-utils';
import { PROCESS_STATUS_DIRECTIONS_SORT, STEP_TYPES } from '@settings/process/config/constants';
import {
  KEY_NAME_STEP_AUTOMATION,
  KEY_NAME_STEP_CRITERIA,
} from '@settings/process/config/keyNames';
import { StepViewField } from '@settings/process/config/view-field/step';
import { useStepDoAtomState, useStepSettingAtomState } from '@settings/process/recoil/process';
import statusAtom from '@settings/process/recoil/status';
import stepTypeAtom from '@settings/process/recoil/step';
import { GET_STEP } from '@settings/process/services/process';
import { BusinessStep, StatusForm } from '@settings/process/types/process';
import _ from 'lodash';
import { Suspense, useEffect, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

interface StepViewProps {
  processId: string;
  stepId: string;
}

function StepView({ processId, stepId }: StepViewProps) {
  // console.log('stepView=================');
  const [stepType, setStepType] = useRecoilState(stepTypeAtom);
  const [, setStepSetting] = useStepSettingAtomState();
  const [, setStepDo] = useStepDoAtomState();
  const setModalSize = useModalSizeAtomState();
  const setStatusesValue = useSetRecoilState(statusAtom);
  const { data, isLoading } = usePost<BusinessStep>(['process_step', stepId], GET_STEP, {
    id: processId,
    stepId: stepId,
  });

  useEffect(() => {
    if (data) {
      const stepType = STEP_TYPES.find((type) => type.value == data.type)!!;
      setStepDo(data.definedId);
      setStepType(stepType);
      if (data.setting) {
        setStepSetting(data.setting);
      }
    }
  }, [data]);

  const viewFields = useMemo(() => {
    let newFields: any = {};
    if (data) {
      for (const [key, value] of Object.entries(StepViewField)) {
        let componentProps: any = value?.componentProps;
        if (componentProps?.steptype) {
          if (_.isArray(componentProps.steptype)) {
            if (!_.includes(componentProps.steptype, stepType.key)) {
              continue;
            }
          } else {
            if (stepType.key !== componentProps.steptype) {
              continue;
            }
          }
        }

        if (key == KEY_NAME_STEP_AUTOMATION && !data.setting?.auto) {
          continue;
        }
        if (key == KEY_NAME_STEP_CRITERIA) {
          componentProps.statuses = data.statuses;
        }
        newFields[key] = { ...value, componentProps: componentProps };
      }
    }
    return newFields;
  }, [data, stepType]);

  const fields = useMemo(() => {
    return ViewFieldParse(data, viewFields, true, [['process_diagram', processId]]);
  }, [data, viewFields]);

  useEffect(() => {
    let statusForms: StatusForm[] = [];
    if (data?.statuses) {
      for (const status of data?.statuses) {
        let newStatus: StatusForm = {
          id: status.id,
          name: status.name,
          button: status.button,
          view: { keyName: status.view, languageKey: status.view },
          event: { keyName: status.event, languageKey: status.event },
          property: { keyName: status.property, languageKey: status.property },
          direction: { keyName: status.direction, languageKey: status.direction },
          nextStep: { keyName: status.nextStep?.id, languageKey: status.nextStep?.name },
          sequence: status.sequence ?? [],
          new: false,
          reset: true,
          order: PROCESS_STATUS_DIRECTIONS_SORT[status.direction],
          multiple: status.multiple,
          primary: status.primary,
          ctaId: status.ctaId ?? '',
        };

        if (status.options) {
          if (stepType.value == 'TYPE_CHECKLIST') {
            newStatus.checklist = JSON.parse(status.options);
          } else if (stepType.value == 'TYPE_CRITERIA') {
            newStatus.criteria = JSON.parse(status.options);
          }
        }
        statusForms.push(newStatus);
      }
    }
    setStatusesValue(statusForms);
  }, [data?.statuses, stepType.value]);

  const ignoreFields = useMemo(() => {
    let ignore: string[] = [];
    return ignore;
  }, [stepType]);

  useEffect(() => {
    if (
      stepType.value == 'TYPE_ACTION' ||
      stepType.value == 'TYPE_SIMPLE_ACTION' ||
      stepType.value == 'TYPE_SITE'
    ) {
      setModalSize('wd-1100');
    } else if (stepType.value == 'TYPE_WAIT') {
      setModalSize('wd-800');
    } else if (stepType.value == 'TYPE_CRITERIA') {
      setModalSize('wd-800');
    } else {
      setModalSize('wd-600');
    }
  }, [stepType.value]);

  const formHeight = 'calc(100vh - 80px)';

  return (
    <>
      <div
        className="pos-relative scroll-box"
        style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <div className="form-row pd-15">
          {isLoading ? (
            <Loading />
          ) : (
            <Suspense fallback={<Loading />}>
              {fields && (
                <>
                  <ViewFields
                    fields={fields}
                    ignoreFields={ignoreFields}
                    menuSource={'processStep'}
                    menuSourceId={processId}
                    metadata={{
                      processId: processId,
                      stepId: stepId,
                      method: data?.action?.method,
                    }}
                  />
                </>
              )}
            </Suspense>
          )}
        </div>
      </div>
    </>
  );
}

export default StepView;
