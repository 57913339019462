import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpanLang } from '@base/components';
import { X } from 'react-feather';

//render question options
const QuestionOption: React.FC<any> = (props) => {
  //props
  const {
    focusS,
    focusQ,
    keyS,
    keyQ,
    focusEle,
    optionsQ = [],
    onRowOptionValueChange,
    onGridOptionCheckChange,
    onRemoveOptionRow
  } = props;
  const { t } = useTranslation();

  /** ================================== HANDLER ========================================== */

  //render
  return (
    <div className="survey-write-question-card-list-container pd-t-10">
      <table className="table table-striped survey-write-question-grid-table">
        <thead>
          <tr>
            <th scope="col"></th>
            {optionsQ.cols.map((_col: any, idx: number) => (
              <th key={idx} scope="col">
                {_col.value ? _col.value : (t('Column') + ' ' + (idx + 1))}
              </th>
            ))}
            {focusS === keyS && (focusQ === keyQ + 1) && <th scope="col"></th>}
          </tr>
        </thead>
        <tbody>
          {optionsQ.rows.map((_row: any, rIdx: number) => {
            const qRowRemoveEleId = 'q-removerowopt-' + keyS + '-' + (keyQ + 1) + '-' + (rIdx + 1); //TODO - remove button id
            const qRowOptionInputEleId = 'q-rowopt-' + keyS + '-' + (keyQ + 1) + '-' + (rIdx + 1);
            return (
              <tr key={rIdx} className=''>
                <td scope="row" className='tx-left'>
                  {focusS === keyS && focusQ === keyQ + 1 ?
                    <input
                      autoComplete='off'
                      type="text"
                      id={qRowOptionInputEleId}
                      className="form-control wd-200"
                      placeholder={t('Row') + " " + (rIdx + 1)}
                      value={_row.value}
                      onChange={(e) => onRowOptionValueChange(e, rIdx)}
                      autoFocus={qRowOptionInputEleId === focusEle}
                    />
                    :
                    _row.value ? _row.value : (t('Row') + ' ' + (rIdx + 1))
                  }
                </td>
                {optionsQ.cols.map((_col: any, cIdx: number) => {
                  const qRowCheckEleId = 'q-rowchk-' + keyS + '-' + (keyQ + 1) + '-' + (rIdx + 1) + '-' + (cIdx + 1); //TODO
                  const optChecked =
                    optionsQ.answer && optionsQ.answer[rIdx]
                      ? optionsQ.answer[rIdx][cIdx]
                      : false;
                  return (
                    <td key={cIdx} scope="row" style={{ verticalAlign: 'middle' }}>
                      <div className="custom-control custom-radio mg-r-10">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id={`${rIdx.toString()}-${cIdx.toString()}`}
                          name={rIdx.toString()}
                          checked={optChecked}
                          onChange={(e: any) => onGridOptionCheckChange(e, rIdx, cIdx)}
                        />
                        <SpanLang
                          tag={'label'}
                          //keyLang={item[fieldLabel]}
                          forID={`${rIdx.toString()}-${cIdx.toString()}`}
                          className="custom-control-label"
                        />
                      </div>
                    </td>
                  )
                })}
                {focusS === keyS && (focusQ === keyQ + 1) &&
                  <th scope="row" style={{ verticalAlign: 'middle' }}>
                    <a
                      id={qRowRemoveEleId}
                      className="survey-home-header-button"
                      onClick={() => onRemoveOptionRow(rIdx)}
                    >
                      <X className='wd-20' />
                    </a>
                  </th>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default QuestionOption;
