import Timeline from '@base/containers/timeline';
import Notes from '@base/containers/note';
import TeamChannel from '@desk/ticket/containers/team-channel';
//import SurveyView from '@settings/online-digital-content/survey/containers/survey-view';
import SurveyForm from '@settings/online-digital-content/survey/containers/survey-form';
import SurveyResponse from '@settings/online-digital-content/survey/components/response';

export const getTabs = ({ showTop = false, data = null }: any) => {
  let curSections: any[] = [];
  try {
    if (data?.sections) {
      curSections = JSON.parse(data.sections);
    }
  } catch {
    // console.log('parse json error');
  }
  return [
    // {
    //   order: 1,
    //   default: true,
    //   label: 'Preview',
    //   path: 'preview',
    //   tabComponent: SurveyView,
    //   tabComponentProps: {
    //     className: 'pd-15',
    //     id: data?.id || '',
    //     previewData: {
    //       headerImg: data?.headerImg,
    //       headerLineColor: data?.headerLineColor,
    //       bgColor: data?.bgColor,
    //       //image: surveyImage,
    //       sections: curSections,
    //     }
    //   }
    // },
    {
      order: 1,
      default: true,
      label: 'Design',
      path: 'design',
      tabComponent: SurveyForm,
      tabComponentProps: {
        className: 'pd-t-10',
        minScrollHeight: 220,
        id: data?.id || '',
        survey: data,
        value: {
          headerImg: data?.headerImg,
          headerLineColor: data?.headerLineColor,
          bgColor: data?.bgColor,
          //image: surveyImage,
          sections: curSections,
        },
      },
    },
    {
      order: 2,
      label: 'Responses',
      path: 'response',
      tabComponent: SurveyResponse,
      tabComponentProps: {
        className: 'pd-15',
        surveyId: data?.id || '',
        surveySections: curSections,
      },
    },
    {
      order: 3,
      label: 'Timeline',
      path: 'timeline',
      tabComponent: Timeline,
    },
    {
      order: 4,
      label: 'Note',
      path: 'note',
      tabComponent: Notes,
    },
    // {
    //   order: 5,
    //   label: 'Team channel',
    //   path: 'team-channel',
    //   tabComponent: TeamChannel,
    // },
  ];
};
