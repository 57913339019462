export const DEFAULT_CATEGORY = 'dashboard';
import { isUndefined } from 'lodash';
import { Link } from 'react-router-dom';
import { MAIN_MENU, NAME_KEY, PRIMARY_KEY } from '@settings/template/config/pages/main-page';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import { SpanLang } from '@base/components';
import { useMenuTemplates } from '../../services/service';
import { IMenuTemplate } from '@settings/template/types/interfaces';

/** LIST PAGE CONFIG */
export const ListPageConfig = {
  primaryKey: PRIMARY_KEY,
  nameKey: NAME_KEY,
  useFectchList: useMenuTemplates,
  getTitle: (row: any, isSplitMode: boolean): string => {
    const name = row && isUndefined(row[NAME_KEY]) ? '' : row[NAME_KEY];
    return name;
  },
  searchFields: [
    {
      name: 'title',
      type: 'text',
      label: 'Subject',
      defaultValue: '',
    },
  ],
  groupByOptions: [],
  dateByOptions: [
    { label: 'Created', value: 'createdAt' },
    { label: 'Updated', value: 'updatedAt' },
  ],
  filterByOptions: [],
  getColumnRenderRemap: (extraParams: any) => {
    const { templateType, templateGroup } = extraParams;
    return {
      name(col: string, row: IMenuTemplate) {
        return (
          <Link to={`/settings/${MAIN_MENU}/${templateType}/${templateGroup}/${row.id}`}>
            {row.name}
          </Link>
        );
      },
      templateGroup(col: string, row: IMenuTemplate) {
        let languageKey = row.templateGroup;
        if (row.templateGroup == 'order_detail') {
          languageKey = 'Order Detail';
        } else if (row.templateGroup == 'product_detail') {
          languageKey = 'Product detail';
        } else if (row.templateGroup == 'sales_order') {
          languageKey = 'Sales Order';
        } else if (row.templateGroup == 'quote') {
          languageKey = 'Quote';
        } else if (row.templateGroup == 'invoice') {
          languageKey = 'Invoice';
        } else if (row.templateGroup == 'purchase_order') {
          languageKey = 'Purchase Order';
        } else if (row.templateGroup == 'bill_receipt') {
          languageKey = 'Bill & Receipt';
        }
        return <SpanLang keyLang={languageKey} />;
      },
    };
  },
  getColumns: () => {
    let columns: any = [
      { name: 'id', keyName: 'id', title: 'id' },
      {
        name: 'name',
        keyName: 'name',
        languageKey: 'Template Name',
        title: 'name',
      },
      {
        name: 'group',
        keyName: 'group',
        languageKey: 'Template Group',
        title: 'group',
      },
      {
        name: 'title',
        keyName: 'title',
        languageKey: 'Subject',
        title: 'title',
      },
      {
        name: 'thumbnail',
        keyName: 'thumbnail',
        languageKey: 'Thumbnail',
        title: 'thumbnail',
      },
      {
        name: 'createdAt',
        keyName: 'createdAt',
        languageKey: 'desk_knowledge_field_createdat',
        title: <SpanLang keyLang="desk_knowledge_field_createdat" />,
      },
      {
        name: 'createdBy',
        keyName: 'createdBy',
        languageKey: 'desk_knowledge_field_createdby',
        title: <SpanLang keyLang="desk_knowledge_field_createdby" />,
      },
    ];
    return columns;
  },
  getHideColumns: () => {
    let columns: string[] = ['id', 'thumbnail', 'name'];
    return columns;
  },
  getHideTableColumns: () => {
    let columns: string[] = ['id', 'thumbnail'];
    return columns;
  },
};
