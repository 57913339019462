import React, { useState, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import Loading from '@base/components/loading';
import QuestionOption from './option';

//render question multiple choices
const Question: React.FC<any> = (props) => {
  const {
    keyS,
    keyQ,
    focusS,
    focusQ,
    focusEle,
    value, //=question content: {type, title, image, required, options}
    onChange, //handle question change
    setFocusEle,
    onFocusQuestionChange,
  } = props;

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [questionValue, setQuestionValue] = useState<any>(value);
  const [isValid, setIsValid] = useState<any>(null); //default validation is OK

  //check duplicate, not for empty
  function checkOptionDuplicate(curOptions: any, newValue: any) {
    let isDuplicated = false;
    curOptions.map((_option: any) => {
      if (_option.value !== '' && _option.value === newValue) {
        isDuplicated = true;
      }
    });
    return isDuplicated;
  }

  /*==================================== HANDLER ===================================== */
  //for grid
  const handleGridOptionValueChange = (gridType: 'rows' | 'cols', e: any, idx: number) => {
    const newQValue = { ...questionValue };
    const preOptionsQ = cloneDeep(newQValue.options); //for check duplicated
    newQValue.options[gridType][idx].value = e.target.value;

    //check duplicate
    const duplicated = checkOptionDuplicate(preOptionsQ[gridType], e.target.value);
    setIsValid({ id: idx, type: gridType, value: !duplicated });
    if (!duplicated) {
      setQuestionValue(newQValue);
      //callback
      onChange && onChange(keyS, keyQ, newQValue);
    }
  };

  //for grid
  const handleRemoveGridOption = (gridType: 'rows' | 'cols', idx: number) => {
    const newQValue = { ...questionValue };
    newQValue.options[gridType].splice(idx, 1);
    setQuestionValue(newQValue);
    //callback
    onChange && onChange(keyS, keyQ, newQValue);
  };

  //radio check
  const handleGridOptionCheckChange = (e: any, rIdx: number, cIdx: number) => {
    const newQValue = { ...questionValue };
    //reset answer
    if (!questionValue.options.answer) {
      questionValue.options.answer = {}; //reset all
      questionValue.options.answer[rIdx] = {};
    } else {
      //reset if exists
      questionValue.options.answer[rIdx] = {};
    }
    //set new answer
    questionValue.options.answer[rIdx][cIdx] = e.target.checked;
    setQuestionValue(newQValue);
    //callback
    onChange && onChange(keyS, keyQ, newQValue);
  }

  /*==================================== RENDER ===================================== */

  //render blue vertical line
  const BlueVerticalLine = useMemo(() => {
    return (
      focusS === keyS &&
      focusQ === keyQ + 1 && (
        <div className="survey-write-tab-content-cursor-root survey-write-tab-content-cursor-root-full"></div>
      )
    );
  }, [focusS, keyS, focusQ, keyQ]);

  //console.log('questionValue', questionValue);
  //component render
  return (
    <div
      className="pos-relative pd-t-5"
      onClick={() => {
        onFocusQuestionChange && onFocusQuestionChange(keyS, keyQ + 1);
        setFocusEle('');
      }}
    >
      <div className="survey-write-question-card-root-container">
        <div className="survey-write-question-card-root">
          <div className="card">
            {BlueVerticalLine}
            <div className="card-body">
              <QuestionOption
                focusS={focusS}
                focusQ={focusQ}
                keyS={keyS}
                keyQ={keyQ}
                focusEle={focusEle}
                optionsQ={questionValue?.options}
                onRowOptionValueChange={(e: any, idx: number) => handleGridOptionValueChange('rows', e, idx)}
                onGridOptionCheckChange={handleGridOptionCheckChange}
                onRemoveOptionRow={(rIdx: number) => handleRemoveGridOption('rows', rIdx)}
              />
            </div>
          </div>
        </div>
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default Question;
