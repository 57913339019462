import {
  BASIC_DATA_TYPE_LOOKUP,
  BASIC_DATA_TYPE_MULTI_CHOICES,
  BASIC_DATA_TYPE_MULTI_LOOKUP,
  BASIC_DATA_TYPE_SINGLE_CHOICE,
  DATA_TYPE_ATTR_LOOKUP_DATA_SOURCE,
} from '@base/config/data-types';
import { IAttribute } from '@base/types/interfaces/pagelayout';
import { generateUUID } from '@base/utils/helpers';
import { EPermissionType } from '@settings/page-layout/types/enums';
import {
  IBasicField,
  IField,
  IPgField,
  ITemplateField,
} from '@settings/page-layout/types/interfaces/pagelayout';

export const isFieldEmptydOption = (field: any) => {
  const dataType = field?.dataType ?? '';
  const attributes = field?.attributes ?? [];
  const options = field?.options ?? [];

  switch (dataType) {
    case BASIC_DATA_TYPE_LOOKUP: {
      const itemAttrLookup = attributes.find(
        (item: any) => item.keyName == DATA_TYPE_ATTR_LOOKUP_DATA_SOURCE,
      );
      return itemAttrLookup && itemAttrLookup.value == '';
    }
    case BASIC_DATA_TYPE_SINGLE_CHOICE:
    case BASIC_DATA_TYPE_MULTI_LOOKUP:
    case BASIC_DATA_TYPE_MULTI_CHOICES:
      return !options || options?.length == 0;
    default:
      return false;
  }
};
/**
 *
 * @param field IBasicField
 * @param order number
 * @param extraParams | {keyName, languageKey, parentFieldId, templateFieldId}
 * @returns
 */
export function initFieldData(field: IBasicField, order: number, extraParams: any) {
  let fData: IField = {
    id: extraParams.id,
    keyName: extraParams.keyName,
    languageKey: extraParams.languageKey,
    language: field.language
      ? field.language
      : {
          en: field.name,
          vi: field.name,
          ko: field.name,
          jp: field.name,
          zh: field.name,
          ido: field.name,
        },
    dataType: field.type,
    dataTypeLangKey: field.name,
    templateFieldId: extraParams.templateFieldId ? extraParams.templateFieldId : '',
    parentFieldId: extraParams.parentFieldId ? extraParams.parentFieldId : '',
    permissionType: EPermissionType.CUSTOM,
    orderInList: order,
    orderInView: order,
    orderInWrite: order,
    showInList: true,
    showInView: true,
    showInWrite: true,
    defaultViewInList: false,
    isBasicField: false,
    isCustom: true,
    isUsed: true,
    attributes: field.attributes ? field.attributes : [],
    options: [],
    icon: field.fieldIcon,
    permission: {
      canMarkRequired: true,
      canSetShowInList: true,
      canSetShowInView: true,
      canSetShowInWrite: true,
      canMoveUnused: true,
      canDelete: true,
      canEditProperty: true,
      canShowSettingButton: true,
      settingButtonTooltip: '',
      canMovePosition: true,
      canChangeFieldName: true,
    },
  };
  return fData;
}
/**
 * init PgField for create/update
 * @param field IBasicField
 * @param order number
 * @param extraParams | {id, keyName, languageKey, parentId, templateId, tplParentId}
 * @returns IPgField
 */
export function initPgFieldData(field: IBasicField, order: number, extraParams: any): IPgField {
  let tplFields: ITemplateField[] = [];
  let devices: string[] = ['desktop', 'mobile', 'tablet'];
  // console.log('extraParmas', extraParams);
  tplFields = devices.map((d: any) => {
    let tplField: ITemplateField = {
      id: generateUUID(),
      fieldId: extraParams.id,
      device: d,
      orderInList: order,
      orderInView: order,
      orderInWrite: order,
      showInList: true,
      showInView: true,
      showInWrite: true,
      defaultViewInList: false,
      isUsed: true,
    };
    return tplField;
  });
  let fData: IPgField = {
    id: extraParams.id,
    keyName: extraParams.keyName,
    languageKey: extraParams.languageKey,
    pagelayoutId: extraParams.pagelayoutId,
    language: field.language
      ? field.language
      : {
          en: field.name,
          vi: field.name,
          ko: field.name,
          jp: field.name,
          zh: field.name,
          ido: field.name,
        },
    attributes: field.attributes,
    dataType: field.type,
    parentId: extraParams.parentId ? extraParams.parentId : '',
    permissionType: EPermissionType.CUSTOM,
    isBasicField: false,
    isCustom: true,
    templateFields: tplFields,
  };
  return fData;
}
export function initPgFieldUpdateData(field: IField): IPgField {
  let fData: IPgField = {
    id: field.id,
    language: field.language
      ? field.language
      : {
          en: '',
          vi: '',
          ko: '',
          jp: '',
          zh: '',
          ido: '',
        },
    attributes: field.attributes,
    languageKey: field.languageKey,
    options: field.options ? field.options : [],
  };
  return fData;
}
export function initTplFieldUpdateData(field: IField): ITemplateField {
  let fData: ITemplateField = {
    id: field.templateFieldId ?? '',
    fieldId: field.id,
    showInList: field.showInList,
    showInView: field.showInView,
    showInWrite: field.showInWrite,
    orderInList: field.orderInList,
    orderInView: field.orderInView,
    orderInWrite: field.orderInWrite,
    defaultViewInList: field.defaultViewInList,
    isUsed: field.isUsed,
  };
  return fData;
}

export function getFieldAttrs(dataType: string) {
  let attrs: IAttribute[] = [
    {
      keyName: 'required',
      value: '0',
      languageKey: 'crm_new_layout_attribute_required',
    },
    {
      keyName: 'maxlength',
      value: '',
      languageKey: 'crm_new_layout_attribute_maxlength',
    },
    {
      keyName: 'allow_duplicate',
      value: '1',
      languageKey: 'crm_new_layout_attribute_allow_duplicate',
    },
    {
      keyName: 'allow_number_character',
      value: '1',
      languageKey: 'crm_new_layout_attribute_allow_number_character',
    },
    {
      keyName: 'tooltip_show',
      value: '1',
      languageKey: 'crm_new_layout_attribute_tooltip_show',
    },
    {
      keyName: 'tooltip_text',
      value: '',
      languageKey: 'crm_new_layout_attribute_tooltip_text',
    },
    {
      keyName: 'tooltip_type',
      value: '1',
      languageKey: 'crm_new_layout_attribute_tooltip_type',
    },
  ];
  return attrs;
}

export const setValueFromAttributes = ({ attributes = [], keyMapping = {} }: any) => {
  return attributes.map((attr: IAttribute) => {
    if (keyMapping.hasOwnProperty(attr.keyName)) {
      return {
        ...attr,
        value: keyMapping[attr.keyName],
      };
    }
    return attr;
  });
};
export function isRequiredField(field: IField): boolean {
  let isRequired = false;
  if (field.attributes) {
    let requiredAttr = field.attributes.find((item) => item.keyName === 'required');
    if (requiredAttr && requiredAttr.value == '1') {
      isRequired = true;
    }
  }
  return isRequired;
}
export function parseFieldData(sections: IField[]): IField[] {
  return sections.map((sec: IField) => {
    return {
      ...sec,
      children: sec.children
        ? sec.children.map((field: IField) => {
            field.isRequired = isRequiredField(field);
            field.isLoading = false;
            return field;
          })
        : [],
    };
  });
}
