import { lazy } from 'react';

export const TriggerAutomation = lazy(() => import('@settings/process/components/trigger/TriggerAutomation'));
// export const CriteriaAutomation = lazy(
//   () => import('@settings/process/components/criteria/CriteriaAutomation'),
// );
export const CriteriaAutomation = lazy(
  () => import('@settings/process/components/criteria-automation/CriteriaAutomation'),
);
export const FieldValue = lazy(() => import('@settings/process/components/field-value/FieldValue'));
export const MethodWrite = lazy(() => import('@settings/process/components/method/MethodWrite'));
export const WaitWrite = lazy(() => import('@settings/process/components/diagram/wait/WaitWrite'));
export const CriteriaWrite = lazy(
  () => import('@settings/process/components/diagram/criteria/CriteriaWrite'),
);
export const AutomationWrite = lazy(
  () => import('@settings/process/components/diagram/automation/AutomationWrite'),
);
export const ActionWrite = lazy(
  () => import('@settings/process/components/diagram/action/ActionWrite'),
);
export const SiteWrite = lazy(() => import('@settings/process/components/diagram/site/SiteWrite'));
export const ChecklistWrite = lazy(
  () => import('@settings/process/components/diagram/checklist/ChecklistWrite'),
);
