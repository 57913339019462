import Portal from '@base/components/portal';
import Canvas from '@base/components/canvas';
import React, { useMemo, useState } from 'react';
import { Input, Select } from '@base/components/form';
import { DatePicker } from '@base/components/date-picker';
import BasicTable from '@base/components/table/basic';

interface UserPurchased {
  isVisible: boolean;
  onClose: (params: any) => any;
  onSubmit: (params: any) => any;
}

const UserPurchased: React.FC<UserPurchased> = (props: UserPurchased) => {
  const { isVisible, onClose, onSubmit } = props;
  const [billingPlan, setBillingPlan] = useState({ value: '', label: '' });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const fakeData = [{ user: 0, term: '6 months 10 days', amount: '' }];
  const columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: (row: any, rowIndex: any) => {
          return (
            <>
              <Input type="number"></Input>
            </>
          );
        },
      },
      {
        Header: 'Term',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.term}</>;
        },
      },
      {
        Header: 'Amount',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.amount}</>;
        },
      },
    ],
    [],
  );
  // console.log(isVisible);
  return (
    <Portal>
      <Canvas
        isVisible={isVisible}
        onCloseSideBar={onClose}
        onClose={onClose}
        className={'page-sidebar-container-wrap'}
        customStyles={{ width: '420px' }}
      >
        <Canvas.Header title="Purchasing" />
        <Canvas.Body style={{ height: 'calc(100vh - 130px)', width: '420px' }}>
          <label className="tx-color-03 mt-3">Item Name</label>
          <div>10G Storage</div>
          <label className="tx-color-03 mt-3">Billing Plan</label>
          <Select
            value={billingPlan}
            options={[]}
            placeholder="Type or click to select a billing plan"
            onChange={(data: any) => {
              setBillingPlan(data);
            }}
          ></Select>
          <label className="tx-color-03 mt-3">Billing Plan</label>
          <DatePicker
            selected={selectedDate}
            onChange={(data: any) => setSelectedDate(data)}
          ></DatePicker>
          <label className="tx-color-03 mt-3">End on</label>
          <div className="mb-3">2021/07/27</div>
          <BasicTable data={fakeData} columns={columns}></BasicTable>
        </Canvas.Body>
        <Canvas.Footer
          onClose={onClose}
          onAplly={onClose}
          onSave={onSubmit}
          saveLabel="Make Payment"
        />
      </Canvas>
    </Portal>
  );
};
export default UserPurchased;
