import { SpanLang } from '@base/components';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from "react-i18next";

//Survey title class
const SurveyTitleView: React.FC<any> = (props) => {
  const {
    className = '',
    title,
    description
  } = props;
  const { t } = useTranslation();
  //local state

  return (
    <div className={classNames("card", className)}>

      {/* pink line */}
      <div className="survey-write-tab-content-theme-stripe survey-write-tab-content-solid-bg"></div>

      {/* title+desc input */}
      <div className="card-body">
        <div>
          <span className="survey-write-tab-content-title-row">{title ? title : t('Untitled section')}</span>
        </div>
        <div className="pd-t-10">
          <p className="survey-write-tab-content-desc-row">{description}</p>
        </div>
        <div className="pd-t-20" style={{ display: "none" }}>
          <div className="survey-write-tab-content-email-title-row">
            <span className="survey-write-tab-content-email-title"><SpanLang keyLang='Email address' /></span>
            <span className="survey-write-tab-content-email-askterisk">*</span>
          </div>
          <div className="survey-write-tab-content-email-input-row">
            <input type="text" className="form-control survey-write-input" placeholder={t('Email address')} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyTitleView;