import React, { useState, useEffect, Fragment, useRef } from 'react';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import Button from '@base/components/form/button';
import { ArrowLeft, ArrowRight, ChevronUp } from 'react-feather';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import Loading from '@base/components/loading';
import * as keyNames from '@settings/template/config/key-names';
import { Input, Select } from '@base/components/form';
import {
  TEMPLATE_TYPE_OPTIONS,
  TEMPLATE_MESSAGE_TYPE_OPTIONS,
  TEMPLATE_TASK_TYPE_OPTIONS,
} from '@settings/template/config/constants';
import LanguageSelect from '@base/containers/language-select';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import classNames from 'classnames';
import SiteTemplateSelect from '../site-template-select';
import { captureTheImage } from '@settings/template/utils/helper';
import { TEMPLATE_GROUP } from '@settings/template/config/constants';
import {
  ADD_MENU_TEMPLATE,
  GET_MENU_TEMPLATE_DETAIL,
  UPDATE_MENU_TEMPLATE,
} from '@settings/template/services/graphql';
import usePost from '@base/hooks/usePost';
const GrapesTS = React.lazy(() => import('@base/components/grapes-ts'));
import { parserSelectToIdName, parserSelectToIdNameObj } from '@base/utils/helpers/config-utils';
//import { finalizeParams } from './payload';
import { UserAutoComplete } from '@base/containers';
import { useFormDefaultValues } from './utils';
import { stubFalse } from 'lodash';
import DomEditor from '@base/components/dom-editor';
import TaskSequenceForm from '@activity/containers/sequence-form';

const TABS = [
  {
    value: 1,
    label: 'Properties',
    keys: [
      keyNames.KEY_MENU_TEMPLATE_NAME,
      keyNames.KEY_MENU_TEMPLATE_TYPE,
      keyNames.KEY_MENU_TEMPLATE_LANGUAGE,
      keyNames.KEY_MENU_TEMPLATE_PRODUCT,
      keyNames.KEY_MENU_TEMPLATE_DESCRIPTION,
      keyNames.KEY_MENU_TEMPLATE_OWNER,
    ],
  },
  {
    value: 2,
    label: 'Select a Template',
    keys: [keyNames.KEY_MENU_TEMPLATE_TEMPLATE],
  },
  {
    value: 3,
    label: 'Design',
    keys: [keyNames.KEY_MENU_TEMPLATE_SUBJECT, keyNames.KEY_MENU_TEMPLATE_DESIGN],
  },
];

interface WriteFormProps {
  id?: string;
  fullScreen: boolean;
  onClose: () => void;
  onReload?: () => void;
  templateGroup: string;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<WriteFormProps> = (props: WriteFormProps) => {
  const {
    id = '', //for edit
    fullScreen,
    onClose,
    onReload,
    templateGroup,
  } = props;
  //// console.log('config defaultValues', defaultValues);

  //state
  const [formStep, setFormStep] = useState(TABS[0].value);
  const [isPreviewing, setIsPreviewing] = useState(false);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    //getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: useFormDefaultValues(templateGroup),
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //get site for edit
  const { data: postData, isLoading: isPostLoading } = usePost<any>(
    ['setting_menuTemplate', id],
    GET_MENU_TEMPLATE_DETAIL,
    { id },
    { enabled: id?.length > 0 },
  );

  //init data
  useEffect(() => {
    if (id) {
      //// console.log('postData', postData);
      if (!isPostLoading && postData) {
        if (postData[keyNames.KEY_MENU_TEMPLATE_NAME]) {
          setValue(keyNames.KEY_MENU_TEMPLATE_NAME, postData[keyNames.KEY_MENU_TEMPLATE_NAME], {
            shouldValidate: true,
          });
        }
        if (postData[keyNames.KEY_MENU_TEMPLATE_LANGUAGE]) {
          setValue(
            keyNames.KEY_MENU_TEMPLATE_LANGUAGE,
            postData[keyNames.KEY_MENU_TEMPLATE_LANGUAGE],
          );
        }
        if (postData[keyNames.KEY_MENU_TEMPLATE_PRODUCT]) {
          setValue(
            keyNames.KEY_MENU_TEMPLATE_PRODUCT,
            postData[keyNames.KEY_MENU_TEMPLATE_PRODUCT],
          );
        }
        if (postData[keyNames.KEY_MENU_TEMPLATE_OWNER]) {
          setValue(keyNames.KEY_MENU_TEMPLATE_OWNER, postData[keyNames.KEY_MENU_TEMPLATE_OWNER]);
        }
        if (postData[keyNames.KEY_MENU_TEMPLATE_DESCRIPTION]) {
          setValue(
            keyNames.KEY_MENU_TEMPLATE_DESCRIPTION,
            postData[keyNames.KEY_MENU_TEMPLATE_DESCRIPTION],
          );
        }
        if (postData[keyNames.KEY_MENU_TEMPLATE_SUBJECT]) {
          setValue(
            keyNames.KEY_MENU_TEMPLATE_SUBJECT,
            postData[keyNames.KEY_MENU_TEMPLATE_SUBJECT],
          );
        }
        if (postData.properties) {
          try {
            setValue(keyNames.KEY_MENU_TEMPLATE_DESIGN, JSON.parse(postData.properties));
          } catch (e) {
            toast.error('Site data parse error.');
          }
        }
      }
    } else {
      reset();
    }
  }, [id, postData]);

  //watching
  const messageType = watch(keyNames.KEY_MENU_TEMPLATE_SUB_TYPE);

  //create
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    ADD_MENU_TEMPLATE,
    'setting_createMenuTemplate',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Created Menu template successfully!');
        // refecth data
        //onReload && onReload();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Created Menu template failed: ' + JSON.parse(error).message);
      },
    },
  );

  //update
  const mutationUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_MENU_TEMPLATE,
    'setting_updateMenuTemplate',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Updated Menu template successfully!');
        // refecth data
        //onReload && onReload();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Updated Menu template failed: ' + JSON.parse(error).message);
      },
    },
  );

  //create success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationAdd.isSuccess) {
      //refecth data
      onReload && onReload();
      onClose();
    }
  }, [mutationAdd.isSuccess]);

  //update success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationUpdate);
    if (mutationUpdate.isSuccess) {
      //refecth data
      onReload && onReload();
      onClose();
    }
  }, [mutationUpdate.isSuccess]);

  //get thumbnail base64
  async function getThumbnail(body: any) {
    const thumbnailBase64 = await captureTheImage(body, 'desk_thumbnail');
    return thumbnailBase64;
  }
  const getSubType = ({ group, formData }: any) => {
    let subType = 'SUB_TYPE_NONE';
    if (group == 'GROUP_TASK') {
      subType = formData[keyNames.KEY_MENU_TEMPLATE_SUB_TYPE].value;
    }
    return subType;
  };

  const getHtml = ({ group, formData }: any) => {
    let html = JSON.stringify({
      html: formData[keyNames.KEY_MENU_TEMPLATE_DESIGN].html,
      css: formData[keyNames.KEY_MENU_TEMPLATE_DESIGN].css,
    });
    let subType = formData[keyNames.KEY_MENU_TEMPLATE_SUB_TYPE].value;
    if (
      group == 'GROUP_TASK' &&
      (subType == 'SUB_TYPE_TASK_CHECK_LIST' || subType == 'SUB_TYPE_TASK_SEQUENCE')
    ) {
      html = JSON.stringify(formData[keyNames.KEY_MENU_TEMPLATE_DESIGN]);
    }
    return html;
  };
  //submit form
  const onSubmit = async ({ formData, isDraft }: any) => {
    //// console.log('formData', formData);
    let designThumbnailBase64: any = '';
    if (formData[keyNames.KEY_MENU_TEMPLATE_DESIGN].body) {
      const designBody = formData[keyNames.KEY_MENU_TEMPLATE_DESIGN].body;
      designThumbnailBase64 = await getThumbnail(designBody);
    }
    //// console.log('designThumbnailBase64', designThumbnailBase64);
    // //TEST preview
    // const imgPreview: any = document.getElementById('img-preview');
    // if (imgPreview) {
    //   imgPreview.src = designThumbnailBase64;
    // }

    //build add params and save
    let index = TEMPLATE_GROUP.findIndex((x) => x.key === templateGroup);
    let group = TEMPLATE_GROUP[index].value;

    const params: any = {
      stage: isDraft === true ? 'STAGE_PREPARE' : 'STAGE_ACTIVE',
      name: formData[keyNames.KEY_MENU_TEMPLATE_NAME],
      description: formData[keyNames.KEY_MENU_TEMPLATE_DESCRIPTION],
      group: group,
      type: formData[keyNames.KEY_MENU_TEMPLATE_TYPE].value,
      subType: getSubType({ group, formData }),
      products: parserSelectToIdName(formData[keyNames.KEY_MENU_TEMPLATE_PRODUCT]),
      assignTo: parserSelectToIdNameObj(formData[keyNames.KEY_MENU_TEMPLATE_OWNER], 'user'),
      language: formData[keyNames.KEY_MENU_TEMPLATE_LANGUAGE],
      title: formData[keyNames.KEY_MENU_TEMPLATE_SUBJECT],
      html: getHtml({ group, formData }),
    };
    if (designThumbnailBase64) {
      params.thumbnail = designThumbnailBase64;
    }
    if (id.length > 0) {
      params.id = id;
      mutationUpdate.mutate({ menuTemplate: params });
    } else {
      mutationAdd.mutate({ menuTemplate: params });
    }
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    //// console.log('error', errors, e);
    toast.error('Created Site Template failed: ' + JSON.stringify(errors));
  };

  /** ============================== EVENT ==================================== */
  //go to next step - set data
  const goNextStep = () => {
    setFormStep((cur) => cur + 1);
    if (formStep + 1 === 2) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null, { shouldValidate: true });
    }
    if (formStep + 1 === 3) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null, { shouldValidate: true });
    }
  };

  //go previous step - reset data
  const goPreviousStep = () => {
    setFormStep((cur) => cur - 1);
    if (formStep - 1 === 2) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null);
    }
    if (formStep - 1 === 1) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null);
    }
  };

  //======================== RENDER FORM ========================//
  //step tabs
  const renderFormSteps = () => {
    return (
      <div className="w-100 pd-b-10">
        <ul className="steps steps-justified steps-tab w-100">
          {TABS.map((_item: any, index: number) => (
            <li
              key={index}
              className={classnames('step-item', {
                complete: _item.value < formStep,
                active: _item.value == formStep,
                disabed: _item.value > formStep,
              })}
            >
              <button
                type="button"
                className="btn step-link rounded-0"
                //onClick={(e) => setFormStep(_item.value)}
              >
                <span className="step-number">{_item.value}</span>
                <span className="step-title">{_item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  //form view
  const handleRenderForm = (group: string) => {
    if (group == 'sms') {
      return renderFormSMS();
    } else if (group == 'task') {
      return renderFormTask();
    } else {
      return renderForm();
    }
  };

  const renderForm = () => {
    return (
      <div className="form-row pd-x-20">
        {formStep >= 1 && (
          <>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">
                Template Name<span className="tx-danger">*</span>
              </label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_NAME}
                control={control}
                rules={{ required: true }}
                //defaultValue={'BUTTON'}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">
                Template Type<span className="tx-danger">*</span>
              </label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_TYPE}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'BUTTON'}
                render={({ field: { onChange, value } }) => (
                  <Select options={TEMPLATE_TYPE_OPTIONS} value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Language</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_LANGUAGE}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <LanguageSelect value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Product</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_PRODUCT}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <ProductAutoComplete value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Description</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_DESCRIPTION}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            {/* <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Owner</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_OWNER}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <UserAutoComplete value={value} onChange={onChange} />
                )}
              />
            </div> */}
          </>
        )}
        {formStep >= 2 && (
          <>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 2 })}>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_DESIGN}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SiteTemplateSelect value={value} onChange={onChange} group={templateGroup} />
                )}
              />
            </div>
          </>
        )}
        {formStep === 3 && (
          <>
            <div className={classNames('form-group pd-0 col-12')}>
              <label className="form-item-title">Subject</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_SUBJECT}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12')} style={{ minHeight: '300px' }}>
              {/* <img id='img-preview' /> */}
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_DESIGN}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <React.Suspense fallback={<Loading />}>
                      <GrapesTS
                        isFullScreen={fullScreen}
                        height={'calc(100vh - 70px)'}
                        storageId={'site-gts'}
                        templateType={templateGroup === 'call' ? 'simple' : 'medium'}
                        value={value}
                        onChange={onChange}
                      />
                    </React.Suspense>
                  </>
                )}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderFormSMS = () => {
    return (
      <div className="form-row pd-x-20">
        {formStep >= 1 && (
          <>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">
                Template Name<span className="tx-danger">*</span>
              </label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_NAME}
                control={control}
                rules={{ required: true }}
                //defaultValue={'BUTTON'}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">
                Message Type<span className="tx-danger">*</span>
              </label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_SUB_TYPE}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'BUTTON'}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={TEMPLATE_MESSAGE_TYPE_OPTIONS}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">
                Template Type<span className="tx-danger">*</span>
              </label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_TYPE}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'BUTTON'}
                render={({ field: { onChange, value } }) => (
                  <Select options={TEMPLATE_TYPE_OPTIONS} value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Language</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_LANGUAGE}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <LanguageSelect value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Product</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_PRODUCT}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <ProductAutoComplete value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Description</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_DESCRIPTION}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            {/* <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Owner</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_OWNER}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <UserAutoComplete value={value} onChange={onChange} />
                )}
              />
            </div> */}
          </>
        )}
        {formStep >= 2 && (
          <>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 2 })}>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_DESIGN}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SiteTemplateSelect value={value} onChange={onChange} group={templateGroup} />
                )}
              />
            </div>
          </>
        )}
        {formStep === 3 && (
          <>
            <div className={classNames('form-group pd-0 col-12')}>
              <label className="form-item-title">Subject</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_SUBJECT}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12')} style={{ minHeight: '300px' }}>
              {/* <img id='img-preview' /> */}
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_DESIGN}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <React.Suspense fallback={<Loading />}>
                    <GrapesTS
                      isFullScreen={fullScreen}
                      height={'calc(100vh - 70px)'}
                      storageId={'site-gts'}
                      templateType={
                        messageType?.value === 'SUB_TYPE_MESSAGE_MMS' ? 'simple' : 'basic'
                      }
                      value={value}
                      onChange={onChange}
                    />
                  </React.Suspense>
                )}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderFormTask = () => {
    return (
      <div className="form-row pd-x-20">
        {formStep >= 1 && (
          <>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">
                Template Name<span className="tx-danger">*</span>
              </label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_NAME}
                control={control}
                rules={{ required: true }}
                //defaultValue={'BUTTON'}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">
                Task Type<span className="tx-danger">*</span>
              </label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_SUB_TYPE}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'BUTTON'}
                render={({ field: { onChange, value } }) => (
                  <Select options={TEMPLATE_TASK_TYPE_OPTIONS} value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">
                Template Type<span className="tx-danger">*</span>
              </label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_TYPE}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'BUTTON'}
                render={({ field: { onChange, value } }) => (
                  <Select options={TEMPLATE_TYPE_OPTIONS} value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Language</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_LANGUAGE}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <LanguageSelect value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Product</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_PRODUCT}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <ProductAutoComplete value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Description</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_DESCRIPTION}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            {/* <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Owner</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_OWNER}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <UserAutoComplete value={value} onChange={onChange} />
                )}
              />
            </div> */}
          </>
        )}
        {formStep >= 2 && (
          <>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 2 })}>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_DESIGN}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SiteTemplateSelect value={value} onChange={onChange} group={templateGroup} />
                )}
              />
            </div>
          </>
        )}
        {formStep === 3 && (
          <>
            <div className={classNames('form-group pd-0 col-12')}>
              <label className="form-item-title">Subject</label>
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_SUBJECT}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12')} style={{ minHeight: '300px' }}>
              {/* <img id='img-preview' /> */}
              <Controller
                name={keyNames.KEY_MENU_TEMPLATE_DESIGN}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DomEditor onPreview={(val: boolean) => setIsPreviewing(val)}>
                    <TaskSequenceForm
                      className="pd-10"
                      mode={isPreviewing ? 'r' : 'w'}
                      value={value}
                      onChange={onChange}
                    />
                  </DomEditor>
                  // <React.Suspense fallback={<Loading />}>
                  //   <GrapesTS
                  //     isFullScreen={fullScreen}
                  //     height={'calc(100vh - 70px)'}
                  //     storageId={'site-gts'}
                  //     templateType={'medium'}
                  //     value={value}
                  //     onChange={onChange}
                  //   />
                  // </React.Suspense>
                )}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="flex-grow-1">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
        </div>
        <div className="mg-l-auto">
          {formStep > 1 && (
            <button type="button" className="btn btn-light mg-r-5" onClick={goPreviousStep}>
              <ArrowLeft />
              Previous
            </button>
          )}
          {formStep < 3 && (
            <button
              disabled={!isValid}
              type="button"
              className="btn btn-primary"
              onClick={goNextStep}
            >
              Next
              <ArrowRight />
            </button>
          )}
          {formStep === 3 && (
            <div className="btn-group dropup">
              <Button
                color="success"
                onClick={() => {
                  //setIsReset(false);
                  handleSubmit((data) => onSubmit({ formData: data, isDraft: false }), onError)();
                }}
                disabled={mutationAdd.isLoading || mutationUpdate.isLoading || !isValid}
                loading={mutationAdd.isLoading | mutationUpdate.isLoading}
                name="Save Now"
              />
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <Button
                  className="dropdown-item"
                  color="primary"
                  onClick={() => {
                    //setIsReset(true);
                    handleSubmit((data) => onSubmit({ formData: data, isDraft: true }), onError)();
                  }}
                  disabled={mutationAdd.isLoading || mutationUpdate.isLoading || !isValid}
                  loading={mutationAdd.isLoading | mutationUpdate.isLoading}
                  name="Save as Draft"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  //// console.log('form api values', getParams(getValues())); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  let formHeight = 'calc(100vh - 280px)';
  if (fullScreen) {
    formHeight = 'calc(100vh - 205px)';
  }

  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>You cannot except a required field</span>
          </span>
        </div>
      </div>
      {renderFormSteps()}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        <div
          className="scroll-box"
          style={
            fullScreen
              ? { height: formHeight, overflowX: 'hidden' }
              : { maxHeight: formHeight, overflowX: 'hidden' }
          }
        >
          {handleRenderForm(templateGroup)}
        </div>
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(WriteForm);
