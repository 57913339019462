export const KEY_NAME_BILLING_INFOMATION_ACCOUNT_NAME = 'name';
export const KEY_NAME_BILLING_INFOMATION_ACCOUNT_LOGO = 'logo';

export const KEY_NAME_BILLING_INFOMATION_REGISTRATION_NAME = 'logo';
export const KEY_NAME_BILLING_INFOMATION_REGISTRATION_NUMBER = 'logo';
export const KEY_NAME_BILLING_INFOMATION_REGISTRATION_PEPRESENRATATIVE = 'representative';
export const KEY_NAME_BILLING_INFOMATION_REGISTRATION_SYNCHRO = 'isSynchronize';
export const KEY_NAME_BILLING_INFOMATION_REGISTRATION_CONDITION = 'condition';
export const KEY_NAME_BILLING_INFOMATION_REGISTRATION_EVENT = 'event';
export const KEY_NAME_BILLING_INFOMATION_REGISTRATION_LOGO = 'logo';
