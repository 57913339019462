import { MENU_SETTING_SITE } from '@base/config/menus';
import { lazy } from 'react';

/** MAIN PAGE CONFIG */
export const MAIN_MENU = MENU_SETTING_SITE;
export const LIST_ROUTE = ':category';
export const VIEW_ROUTE = ':category/:id/*';
export const WRITE_ROUTE = ':category/write';
export const DEFAULT_ROUTE = '/settings/preferences/desk';
export const DEFAULT_CATEGORY = 'desk';
export const ActivityPage = lazy(() => import('@settings/preferences/pages/activity'));
export const DeskPage = lazy(() => import('@settings/preferences/pages/desk'));
export const ProductPage = lazy(() => import('@settings/preferences/pages/product'));
export const CustomerPage = lazy(() => import('@settings/preferences/pages/customer'));