import { useCallback } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';

import { STATUS_BASIC_DATA } from '@settings/process/config/constants';
import { MultipleType } from '@settings/process/types/diagram';
import { Button } from '@base/components/form';
import statusAtom, {
  selectedStatusAtom,
  showStatusMultipleAtom,
} from '@settings/process/recoil/status';

interface ActionStatusListProps {
  children: React.ReactNode;
  fixed?: boolean;
}

function ActionStatusList({ children, fixed }: ActionStatusListProps) {
  const setStatusesValue = useSetRecoilState(statusAtom);
  const setSelectedStatus = useSetRecoilState(selectedStatusAtom);
  const [showMultiple, setShowMultiple] = useRecoilState(showStatusMultipleAtom);

  const handleAdd = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const id = uuidv4();
        const statuses = snapshot.getLoadable(statusAtom).getValue();

        set(statusAtom, [
          ...statuses,
          STATUS_BASIC_DATA({
            id: id,
            button: '',
            name: '',
            newFlag: true,
            resetFlag: true,
          }),
        ]);
        setSelectedStatus(id);
      },
    [],
  );

  const onChangeMultiple = useCallback((multiple: MultipleType) => {
    setStatusesValue((old) => {
      return old.map((v) => {
        if (v.direction.keyName == 'DIRECTION_FORWARD_OUTGOING_FORWARD') {
          return { ...v, ...{ multiple: multiple } };
        } else {
          return v;
        }
      });
    });
    setShowMultiple(false);
  }, []);

  return (
    <>
      <div className="table-responsive bd rounded">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            <tr className="text-center ">
              <th scope="col" className="bd-t-0-f wd-150 text-nowrap">
                'Button'
              </th>
              <th scope="col" className="bd-t-0-f wd-150 text-nowrap">
                View
              </th>
              <th scope="col" className="bd-t-0-f wd-150 text-nowrap">
                Event
              </th>
              <th scope="col" className="bd-t-0-f wd-150 text-nowrap">
                Status
              </th>
              <th scope="col" className="bd-t-0-f wd-150 text-nowrap">
                Direction
              </th>
              <th scope="col" className="bd-t-0-f wd-150 text-nowrap">
                Property
              </th>
              <th scope="col" className="bd-t-0-f wd-150 text-nowrap">
                Sequence
              </th>
              {!fixed && <th scope="col" className="bd-t-0-f wd-50 text-nowrap"></th>}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
      {!fixed && (
        <Button
          name="Add a button"
          color="link"
          icon="Plus"
          className="mg-t-10"
          onClick={handleAdd}
        />
      )}
      <div
        className={classNames('modal fade', {
          show: showMultiple,
        })}
      >
        <div className="modal-dialog  modal-dialog-centered modal-sm">
          <div className="modal-content tx-14">
            <div className="modal-header pd-10">
              <h6 className="modal-title">Choose Split</h6>
            </div>
            <div className="modal-body pd-5 d-flex flex-column">
              <button
                type="button"
                className="dropdown-item"
                onClick={() => onChangeMultiple('MULTIPLE_CHOICE')}
              >
                Choice(Or)
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => onChangeMultiple('MULTIPLE_PARALLEL')}
              >
                Parallel(And)
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActionStatusList;
