import { gql } from 'graphql-request';

export const GET_ALL_ASSIGNMENT_RULE = gql`
  query q($filter: SearchFilter) {
    setting_assignmentRules(filter: $filter) {
      results {
        id
        name
        description
        module
        criteria {
          key
          condition
        }
        assignsTo {
          mode
          type
          assignTo {
            user {
              id
              name
            }
            group {
              id
              name
            }
          }
          capacity
          baseOnWorkDay
        }
        createdAt
        createdBy {
          id
          name
        }
        active
      }
      paging {
        totalPage
        totalItems
        currentPage
      }
    }
  }
`;
export const GET_ASSIGNMENT_RULE = gql`
  query q($id: String) {
    setting_assignmentRule(id: $id) {
      name
      description
      module
      criteria {
        key
        condition
      }
      assignsTo {
        id
        mode
        type
        assignTo {
          user {
            id
            name
          }
          group {
            id
            name
          }
        }
        capacity
        baseOnWorkDay
      }
      createdAt
      createdBy {
        id
        name
      }
      active
    }
  }
`;

export const CREATE_ASSIGNMENT_RULE = gql`
  mutation q($ar: IAssignMentRule) {
    setting_createAssignmentRule(ar: $ar) {
      id
    }
  }
`;

export const UPDATE_ASSIGNMENT_RULE = gql`
  mutation q($ar: IAssignMentRule) {
    setting_updateAssignmentRule(ar: $ar) {
      id
    }
  }
`;

export const DELETE_ASSIGNMENT_RULE = gql`
  mutation q($id: String) {
    setting_deleteAssignmentRule(id: $id) {
      id
    }
  }
`;
export const DELETE_ASSIGNMENT_RULES = gql`
  mutation q($ids: [String!]) {
    setting_deleteAssignmentRules(ids: $ids) {
      ids
    }
  }
`;

export const CREATE_ASSIGNMENT_RULE_ASSIGN_TO = gql`
  mutation q($id: String, $assignTo: IARAssignTo) {
    setting_createAssignTo(id: $id, assignsTo: $assignTo) {
      id
    }
  }
`;

export const DELETE_ASSIGNMENT_RULE_ASSIGN_TO = gql`
  mutation q($id: String, $assignToId: String) {
    setting_deleteAssignTo(id: $id, assignToId: $assignToId) {
      id
    }
  }
`;

export const CREATE_ASSIGNMENT_RULE_ENTRY = gql`
  mutation q($arEntry: IAssignMentRule) {
    setting_createAssignmentRuleEntry(arEntry: $arEntry) {
      id
    }
  }
`;

export const UPDATE_ASSIGNMENT_RULE_ENTRY = gql`
  mutation q($arEntry: IAssignMentRule) {
    setting_updateAssignmentRuleEntry(arEntry: $arEntry) {
      id
    }
  }
`;

export const DELETE_ASSIGNMENT_RULE_ENTRY = gql`
  mutation q($id: String) {
    setting_deleteAssignmentRuleEntry(id: $id) {
      id
    }
  }
`;
