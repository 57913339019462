import { Input } from '@base/components/form';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_AUTO_CLOSE_TICKET_SETTING } from '@settings/preferences/services/graphql';
import { useAutoCloseTicketSetting } from '@settings/preferences/services/service';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface IAutoCloseTicketProps {}
const AutoCloseTicket: React.FC<IAutoCloseTicketProps> = (props: IAutoCloseTicketProps) => {
  const [value, setValue] = useState(18);
  const { data, isLoading } = useAutoCloseTicketSetting();
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_AUTO_CLOSE_TICKET_SETTING,
    'setting_updateMenuSetting',
  );
  const onSave = (nVal: number) => {
    const nSetting = {
      menu: 'desk',
      key: 'auto_close_ticket',
      value: nVal,
    };
    mUpdate.mutate(
      { menuSetting: nSetting },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
        },
      },
    );
  };
  const onChange = (nVal: number) => {
    onSave(nVal);
  };
  useEffect(() => {
    if (!isLoading && data?.value) {
      setValue(parseInt(data.value, 10));
    }
  }, [data]);
  return (
    <>
      <div className="card mg-b-15">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Auto Close Ticket</div>
        </div>
        <div className="card-body">
          {!isLoading && (
            <div className="d-flex flex-wrap align-items-center">
              Close the Resolved Ticket after
              <Input type="number" className="wd-100 mg-x-5" value={value} onBlur={onChange} />
              hours
            </div>
          )}
          {isLoading && (
            <div className="wrap-hanloading">
              <div className="han-loading" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AutoCloseTicket;
