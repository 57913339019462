import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { CriteriaOption } from '@settings/process/types/diagram';
import { CRITERIA_FIELD_TYPE, CRITERIA_OPERATOR } from '@settings/process/config/constants';
import { MODULE } from '@base/config/constant';

interface CriteriaRuleViewProps {
  criteriaId: string;
  criteriaName: string;
  criteriaOption: CriteriaOption;
}

function CriteriaRuleView(props: CriteriaRuleViewProps) {
  const { criteriaName, criteriaOption } = props;
  const blockCount = criteriaOption.blocks.length - 1;

  return (
    <div className="mg-l-5 mg-b-20">
      <div className="d-flex align-items-center mg-b-10">
        <label className="mg-r-10">Criteria Name</label>
        <label>{criteriaName}</label>
      </div>
      {criteriaOption.blocks.map((block, bIndex) => {
        const blockAndOr = criteriaOption.pattern[bIndex];
        const isBlockSplit = bIndex < blockCount;
        const conditionCount = block.conditions.length - 1;

        return (
          <React.Fragment key={bIndex}>
            <div className="pd-15 bg-white bd-3 bd-primary bd-l rounded shadow-sm">
              <ul className="criteria-list">
                {block.conditions.map((condition, cIndex) => {
                  const isConditionSplit = cIndex < conditionCount;
                  const conditionAndOr = block.pattern[cIndex];
                  const valueType = condition.bSide ? 'other' : 'enter';

                  let startDate,
                    endDate = null;
                  if (condition.aSide.type == 'FIELD_TYPE_DATE') {
                    if (condition.value) {
                      if (condition.operator == 'CRITERIA_OPERATOR_BETWEEN') {
                        const splitValue = condition.value.split('|');
                        startDate = splitValue[0] ? new Date(splitValue[0]) : null;
                        endDate = splitValue[1] ? new Date(splitValue[1]) : null;
                      } else {
                        startDate = condition.value ? new Date(condition.value) : null;
                      }
                    }
                  }

                  return (
                    <li key={cIndex}>
                      <div className="d-flex flex-column pd-t-5 pd-b-10 bg-white">
                        <div className="d-flex flex-column">
                          <div className="d-flex justify-content-start align-items-center">
                            <div className="d-flex justify-content-start align-items-start">
                              <div className="d-flex flex-column">
                                <label className="wd-60 tx-10">Module</label>
                                <label className="wd-150">{MODULE[condition.aSide.module]}</label>
                              </div>
                              <div className="d-flex flex-column">
                                <label className="wd-60 tx-10">Field</label>
                                <label className="wd-150">{condition.aSide.field}</label>
                              </div>
                            </div>
                            {valueType == 'other' && (
                              <div className="d-flex justify-content-start align-items-start mg-l-15">
                                <div className="d-flex flex-column">
                                  <label className="wd-60 tx-10">Module</label>
                                  <label className="wd-150">
                                    {condition.bSide && MODULE[condition.bSide.module]}
                                  </label>
                                </div>
                                <div className="d-flex flex-column">
                                  <label className="wd-60 tx-10">Field</label>
                                  <label className="wd-150">
                                    {condition.bSide && condition.bSide.field}
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>

                          <div
                            className={classNames('d-flex align-items-start', {
                              'justify-content-start': valueType == 'enter',
                              'justify-content-center': valueType == 'other',
                            })}
                          >
                            <div className="d-flex flex-column">
                              <label className="wd-60 tx-10">Type</label>
                              <label className="wd-80">
                                {CRITERIA_FIELD_TYPE[condition.aSide.type]}
                              </label>
                            </div>
                            <div className="d-flex flex-column">
                              <label className="wd-60 tx-10">Operator</label>
                              <label className="wd-70">
                                {CRITERIA_OPERATOR[condition.operator]}
                              </label>
                            </div>
                            {valueType == 'enter' && (
                              <div className="d-flex flex-column">
                                <label className="wd-60 tx-10">Value</label>
                                {condition.aSide.type == 'FIELD_TYPE_TEXT' && (
                                  <label>{condition.value}</label>
                                )}
                                {condition.aSide.type == 'FIELD_TYPE_DATE' && (
                                  <div className="d-flex justify-content-start">
                                    <div className="wd-150-f mg-1">
                                      <label>{startDate?.toISOString()}</label>
                                    </div>
                                    {condition.operator == 'CRITERIA_OPERATOR_BETWEEN' && (
                                      <>
                                        <div className="mg-l-10 mg-r-10 mg-t-10"> ~ </div>
                                        <div className="wd-150-f mg-1">
                                          <label>{endDate?.toISOString()}</label>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                                {condition.aSide.type == 'FIELD_TYPE_NUMBER' && (
                                  <label>{condition.value}</label>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {isConditionSplit && conditionAndOr && (
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="dropdown mg-r-5">
                            {conditionAndOr == 'and' ? 'And' : 'Or'}
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            {isBlockSplit && blockAndOr && (
              <div className="add-criteria-block">{blockAndOr == 'and' ? 'And' : 'Or'}</div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default CriteriaRuleView;
