import React from 'react';

interface ListLayoutProps {
  toolbar?: React.ReactNode;
  header?: React.ReactNode;
  body: React.ReactNode | React.FC;
}

function ListLayout({ toolbar, header, body }: ListLayoutProps) {
  return (
    <>
      {toolbar}
      {header}
      {body}
    </>
  );
}

export default ListLayout;
