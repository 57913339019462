import { AsyncSelect, Select } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import withLoading from '@base/hooks/hocs/withLoading';
import useMutationPost from '@base/hooks/useMutationPost';
import { currenciesAtom, formatSettingsAtom } from '@base/recoil/atoms';
import { currencySettingSelector } from '@base/recoil/selectors';
import { BaseResponse } from '@base/types/interfaces/response';
import { UPDATE_FORMAT_SETTING } from '@settings/general-setting/services/graphql/format';
import { ICurrency, ICurrencySetting } from '@settings/general-setting/types/interfaces/format';
import React, { useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import CurrencySelect from '../currency-select';

const currencyFormats = ['1,234,567.89', '1.234.567,89', '1 234 567,89'];
const negativeCurrencyFormats = ['-1,234,567.89', '-1.234.567,89', '-1 234 567,89'];
interface ICurrencySttingProps {
  setLoading: (params: any) => void;
  data: ICurrencySetting | undefined;
}
const CurrencyStting: React.FC<ICurrencySttingProps> = (props: ICurrencySttingProps) => {
  const { setLoading } = props;
  const setUsedCurrencies = useSetRecoilState(currenciesAtom);
  const data: ICurrencySetting = useRecoilValue(currencySettingSelector);
  const [formatSettings, setFormatSettings] = useRecoilState(formatSettingsAtom);
  let defaultValue: ICurrencySetting = {
    currencyFormat: '1,234,567.89',
    negativeCurrencyFormat: '-1,234,567.89',
    usedCurrencies: [],
  };
  const [currencyValue, setCurrencyValue] = useState<ICurrencySetting>(defaultValue);
  const { currencyFormat, negativeCurrencyFormat, usedCurrencies } = currencyValue;
  const settingKey = 'currency';
  const mUpdateFormat: any = useMutationPost<BaseResponse<string>>(
    UPDATE_FORMAT_SETTING,
    'setting_updateFormatSetting',
    {},
  );
  const updateFormatSetting = (value: any) => {
    // console.log('updateFormatSetting', value);
    // save to server
    setCurrencyValue(value);
    mUpdateFormat.mutate(
      { key: settingKey, value: JSON.stringify(value) },
      {
        onSuccess: (res: any) => {
          toast.success('Setting saved!');
          setLoading(false);
          // update format settings
          const newSettings = formatSettings.map((item: any) => {
            if (item.key == settingKey) {
              // item.value = JSON.stringify(currencyValue);
              return {
                ...item,
                value: JSON.stringify(value),
              };
            }
            return item;
          });
          setFormatSettings(newSettings);
          setUsedCurrencies(value.usedCurrencies);
          // console.log('currencyValue', value);
        },
        onError: (res: any) => {
          toast.error('Saving has failed!');
          setLoading(false);
        },
      },
    );
  };

  const currencyFormatData = useMemo(
    () => ({ label: currencyFormat, value: currencyFormat }),
    [currencyFormat],
  );
  const negativeCurrencyFormatData = useMemo(
    () => ({ label: negativeCurrencyFormat, value: negativeCurrencyFormat }),
    [negativeCurrencyFormat],
  );
  const currencyFormatsData = useMemo(
    () => currencyFormats?.map((format: any) => ({ label: format, value: format })),
    [currencyFormats],
  );
  const negativeCurrencyFormatsData = useMemo(
    () =>
      negativeCurrencyFormats?.map((format: any) => ({
        label: format,
        value: format,
      })),
    [negativeCurrencyFormats],
  );

  const onChange = (currencySelect: any) => {
    const isExist = usedCurrencies.find((currency: any) => currency.code == currencySelect.code);
    if (isExist) {
      toast.warning('Country code is existed');
    } else {
      setLoading(true);
      const currencies = usedCurrencies.concat([currencySelect]);
      const value = {
        ...currencyValue,
        usedCurrencies: currencies,
      };
      updateFormatSetting(value);
    }
  };

  const onDelete = (currencySelect: any) => {
    setLoading(true);
    const currencies = usedCurrencies.filter(
      (currency: any) => currency.code != currencySelect.code,
    );
    const value = {
      ...currencyValue,
      usedCurrencies: currencies,
    };
    updateFormatSetting(value);
  };

  const onSetDefault = (currencySelect: any) => {
    setLoading(true);
    const currencies = usedCurrencies.map((currency: any) => ({
      ...currency,
      isDefault: currency.code == currencySelect.code,
    }));
    const value = {
      ...currencyValue,
      usedCurrencies: currencies,
    };
    updateFormatSetting(value);
  };

  const onChangeCurrencyFormat = (data: any) => {
    const value = {
      ...currencyValue,
      currencyFormat: data.value,
    };
    setLoading(true);
    updateFormatSetting(value);
  };

  const onChangeNegativeCurrencyFormat = (data: any) => {
    const value = {
      ...currencyValue,
      negativeCurrencyFormat: data.value,
    };
    setLoading(true);
    updateFormatSetting(value);
  };
  const columnsTable = useMemo(
    () => [
      {
        Header: 'Currency',
        accessor: (row: any, rowIndex: any) =>
          `${row.currencySymbol}(${row.code}) : ${row.currencyName}`,
      },
      {
        Header: 'Default',
        accessor: (row: any, rowIndex: any) => (
          <div className="custom-control custom-radio">
            <input
              type="radio"
              className="custom-control-input"
              name="defaultCurrency"
              id={`defaultCurrency${rowIndex}`}
              checked={row.isDefault}
              onChange={() => onSetDefault(row)}
            />
            <label className="custom-control-label" htmlFor={`defaultCurrency${rowIndex}`}></label>
          </div>
        ),
      },
      {
        Header: 'Delete',
        accessor: (row: any, rowIndex: any) => (
          <button type="button" className="btn btn-icon pd-0" onClick={() => onDelete(row)}>
            <X className="tx-danger" />
          </button>
        ),
      },
    ],
    [currencyValue],
  );
  useEffect(() => {
    if (data) {
      setCurrencyValue(data);
    }
  }, [data]);
  return (
    <div className="card">
      <div className="card-header h6 bg-light">Currency</div>
      <div className="card-body">
        <div className="form-row">
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">Currency Format</label>
            <Select
              className="wd-200-f"
              value={currencyFormatData}
              options={currencyFormatsData}
              onChange={onChangeCurrencyFormat}
            />
          </div>
          <div className="form-col col-md-6 form-group">
            <label className="form-item-title">Negative Currency Format</label>
            <Select
              className="wd-200-f"
              value={negativeCurrencyFormatData}
              options={negativeCurrencyFormatsData}
              onChange={onChangeNegativeCurrencyFormat}
            />
          </div>
        </div>
        <BasicTable
          wrapperClassName="bd rounded-top"
          className="settings-tb"
          columns={columnsTable}
          data={currencyValue.usedCurrencies}
        />
        <div className="pd-10 bd bd-t-0 rounded-bottom">
          <CurrencySelect onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default withLoading(CurrencyStting);
