import { Button } from '@base/components/form';
import ListLoading, { TableNothing } from '@base/components/list/list-loading';
import ListReactTable from '@base/components/list/list-react-table';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { ListPageConfig } from '@settings/assignment-rule/config/pages/list-page';
import {
  CREATE_ASSIGNMENT_RULE_ASSIGN_TO,
  DELETE_ASSIGNMENT_RULE_ASSIGN_TO,
  DELETE_ASSIGNMENT_RULE_ENTRY,
} from '@settings/assignment-rule/services/graphql';
import { IARAssignToItem, IARRuleEntryItem } from '@settings/assignment-rule/types/interfaces';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { CREATE_RULE_ENTRY_TITLE } from '@settings/assignment-rule/config/pages/main-page';
import AREntryWrite from '@settings/assignment-rule/containers/write-rule';
// import { useQueryClient } from '@tanstack/react-query';

interface IARRuleEntryProps {
  value: IARRuleEntryItem[];
  onChange: (nVal: any) => void;
  menuSourceId?: string;
  onRefetch?: () => void;
}

const RuleEntry: React.FC<IARRuleEntryProps> = (props: IARRuleEntryProps) => {
  let { menuSourceId = '', value, onChange, onRefetch } = props;

  // const queryClient = useQueryClient();

  const [items, setItems] = useState<IARRuleEntryItem[]>(value ?? []);
  useEffect(() => {
    setItems(value);
  }, [value]);

  const [isAdd, setIsAdd] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(CREATE_RULE_ENTRY_TITLE);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>(null);

  const mAddAssign: any = useMutationPost<BaseMutationResponse>(
    CREATE_ASSIGNMENT_RULE_ASSIGN_TO,
    'setting_createAssignTo',
    {},
  );
  const mDeleteAssign: any = useMutationPost<BaseMutationResponse>(
    DELETE_ASSIGNMENT_RULE_ASSIGN_TO,
    'setting_deleteAssignTo',
    {},
  );
  const mDeleteRule: any = useMutationPost<BaseMutationResponse>(
    DELETE_ASSIGNMENT_RULE_ENTRY,
    'setting_deleteAssignmentRuleEntry',
    {},
  );

  const onAfterAdded = (aItems: IARRuleEntryItem[]) => {
    // console.log('onAfterAdded', aItems);
    if (menuSourceId != '') {
      mAddAssign.mutate(
        { id: menuSourceId, assignsTo: aItems },
        {
          onSuccess: (res: any) => {
            toast.success('Data has added!');
            let nItems = items;
            nItems.push(...aItems);
            setItems(nItems);
            onChange && onChange(nItems);
          },
        },
      );
    } else {
      let nItems = items;
      nItems.push(...aItems);
      setItems(nItems);
      onChange && onChange(nItems);
    }
  };
  const onAfterDeleted = (nItem: IARRuleEntryItem) => {
    // console.log('onAfterDeleted', nItem);
    let nItems = items.filter((item) => {
      return item.id != nItem.id;
    });
    if (menuSourceId != '') {
      /*mDeleteAssign.mutate(
        { id: menuSourceId, assignToId: nItem.id },
        {
          onSuccess: (res: any) => {
            toast.success('Data has deleted!');
            setItems(nItems);
            onChange && onChange(nItems);
          },
        },
      );*/
      mDeleteRule.mutate(
        { id: nItem.id },
        {
          onSuccess: (res: any) => {
            toast.success('Data has deleted!');
            setItems(nItems);
            onChange && onChange(nItems);
          },
        },
      );
    } else {
      setItems(nItems);
      onChange && onChange(nItems);
    }
  };

  const onOpenEdit = (data: IARRuleEntryItem) => {
    setEditData(data);
    setIsOpen(true);
  };

  const tableProps: any = {
    nodata: <TableNothing />,
    data: items ?? [],
    isFetching: false,
    fetching: <ListLoading />,
    columns: makeTableColumns(
      ListPageConfig.ruleEntryFields,
      ListPageConfig.getRuleEntryColumnRenderRemap({ onAfterDeleted, onOpenEdit }),
      {},
      [],
    ),
    isCheckboxTable: false,
  };

  const onAdd = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    // onRefetch && onRefetch();
    // setTimeout(() => {
    //   queryClient.invalidateQueries(['setting_assignmentRule']);
    // }, 500);
    setIsOpen(false);
  };

  return (
    <>
      <div className="d-flex align-items-center mg-b-5">
        <label className="form-item-title mg-b-0">Rule Entries</label>
        <Button
          color="primary"
          size="xs"
          outline
          className="btn-icon rounded-0 mg-l-auto"
          icon="Plus"
          data-toggle="modal"
          data-target="#newRuleEntry"
          onClick={onAdd}
        />
      </div>
      <ListReactTable {...tableProps} />
      {isOpen && (
        <AREntryWrite
          isOpen={isOpen}
          onClose={onClose}
          assignRuleId={menuSourceId}
          showCanvas={true} // display to right sidbar
          className={''}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          defaultValue={editData}
        />
      )}
    </>
  );
};

export default RuleEntry;
