import React from 'react';

interface IAssignToEditProps {
  value: any;
  onChange: (params: any) => {};
}
const AssignToEdit: React.FC<IAssignToEditProps> = (props: IAssignToEditProps) => {
  const { value, onChange } = props;
  return <>AssignToEdit</>;
};

export default AssignToEdit;
