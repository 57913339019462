import { Select } from '@base/components/form';
import React, { memo, useEffect, useState } from 'react';
import { templateData } from './dummy-data';
import { useGetList } from '@base/services/list';
import { FilterInput } from '@base/types/interfaces/common';
import TypeTemplate from './type-template';
import MyTemplate from './my-template';
import GrapesTSViewField from '@base/containers/view-field/grapes-ts';
import * as keyNames from '@settings/template/config/key-names';
import { MENU_SETTING_LANDINGPAGE, MENU_TEMPLATE_EMAIL } from '@base/config/menus';
import { default as viewConfig } from '@settings/template/config/view-field/email/index';

interface ITemplate {
  id: string;
  name: string;
  thumbnail: string;
  active: boolean;
  html: string;
}

interface Props {
  onChange: Function;
  value: any;
  group?: string;
  template?: ITemplate[];
}

const SiteTemplateSelect: React.FC<Props> = (props: Props) => {
  let initFirst: ITemplate = {
    id: '',
    name: 'Blank',
    thumbnail: '',
    active: true,
    html: JSON.stringify({ html: '<body></body>', css: '' }),
  };
  const group = props?.group ? props.group : 'email';
  const [active, setActive] = useState(initFirst);
  // console.log('>>>>>>>>> Props', props);
  const handleChange = (newValue: ITemplate) => {
    setActive(newValue);
    //callback
    props.onChange && props.onChange(JSON.parse(newValue.html));
  };
  const listQuerySchema = `id
  name
  title
  thumbnail
  type
  html
  language
  createdAt
  stage`;
  let filtersQuery: FilterInput = {
    //query: 'group=2',
    query: 'createdBy=22779486-f43a-4530-b77f-31a932dd0a23',
  };

  // get list data
  const { isLoading, data, isFetching, isPreviousData, refetch } = useGetList<any[]>(
    'setting_menuTemplates',
    listQuerySchema,
    filtersQuery,
  );
  let listTemplate = templateData;

  let myTemplateInit: ITemplate[] = [];
  myTemplateInit.push(initFirst);
  let [myTemplate, setMyTemplate] = useState<ITemplate[]>(myTemplateInit);

  useEffect(() => {
    if (data?.data) {
      let myTemplateInit: ITemplate[] = [];
      myTemplateInit.push(initFirst);
      data?.data.map((item, key) => {
        let tmp = { ...item };
        tmp.active = false;
        myTemplateInit.push(tmp);
      });
      setMyTemplate(myTemplateInit);
    }
  }, [data?.data]);

  const getPreView = (item: ITemplate) => {
    let htmlData = JSON.stringify({ html: '', css: '' });
    if (item.html) {
      htmlData = item.html;
    }
    const config = viewConfig[keyNames.KEY_MENU_TEMPLATE_DESGIN];
    return (
      <div id="LPPreview" className="card collapse show">
        <GrapesTSViewField
          keyName={keyNames.KEY_MENU_TEMPLATE_DESGIN}
          value={JSON.parse(htmlData)}
          userPermission={{ isEdit: false }}
          menuSourceId={item.id}
          menuSource={MENU_TEMPLATE_EMAIL}
          viewConfig={viewConfig}
          config={config}
        />
      </div>
    );
  };

  return (
    <div
      className="row row-xs mg-0"
      // style={{ height: 'calc(100% - 50px)' }}
    >
      <div className="col-lg-6 d-flex flex-column ht-100p">
        <ul className="nav nav-line bd-1">
          <li className="nav-item">
            <button
              type="button"
              data-target="#myLandingPage"
              className="btn nav-link pd-x-10-f active"
              data-toggle="tab"
            >
              My
            </button>
          </li>
          <li className="nav-item mg-l-0-f">
            <button
              type="button"
              data-target="#sampleLandingPage"
              className="btn nav-link pd-x-10-f"
              data-toggle="tab"
            >
              Sample
            </button>
          </li>
        </ul>
        <div className="tab-content pd-x-10">
          <MyTemplate
            listTemplate={myTemplate}
            setTemplate={setMyTemplate}
            onChange={handleChange}
          />
          <TypeTemplate type="sample" onChange={handleChange} group={group} />
        </div>
      </div>
      <div className="col-lg-6 d-flex flex-column ht-100p">
        <div className="pd-y-9">Preview</div>
        <div className="flex-grow-1 overflow-auto mg-b-10 pd-10 bd">{getPreView(active)}</div>
      </div>
    </div>
  );
};

export default memo(SiteTemplateSelect);
