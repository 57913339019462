import React, { FC } from 'react';
import WriteForm from '@settings/template/containers/write-form-new';
import { getBuilderUrl } from '@settings/site/utils/helper';
import { useParams } from 'react-router-dom';
import { DEFAULT_CATEGORY } from '@settings/site/config/pages/main-page';

const WritePage: FC<any> = (props: any) => {
  // const builderUrl = getBuilderUrl();
  // // add siteGroup
  // const params = useParams();
  // const aParams = params['*'] ? params['*'].split('/') : [];
  // const category = aParams[0] ? aParams[0] : DEFAULT_CATEGORY;
  // const url =
  //   editId != ''
  //     ? `${builderUrl}?category=${category}&id=${editId}`
  //     : `${builderUrl}?category=${category}`;

  return (
    <>
      {/* <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={deviceState.device}
        layout={deviceState.layout}
        form={'write'}
        {...props}
      /> */}
      <WriteForm {...props} />
    </>
  );
};

export default WritePage;
