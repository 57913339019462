export const NAME_ITEM_INIT = [
  { id: 'en', value: '' },
  { id: 'ko', value: '' },
  { id: 'vi', value: '' },
];

export const PAYMENT_OPTION = [
  { value: 'receipt', label: '청구서와 가상계좌를 보내주면 결제합니다.' },
  { value: 'online', label: '신용카드로 기한 내 온라인 결제합니다.' },
];

export const LIST_OPTIONS_RECHARGE = [
  {
    value: 'sendingMail',
    label: 'Sending Mail (Free: 100/month)',
  },
  {
    value: 'bulkEmail',
    label: 'Bulk Email',
  },
  {
    value: 'sms',
    label: 'SMS',
  },
  {
    value: 'taxInvoice',
    label: 'Digital Tax Invoice',
  },
  {
    value: 'billingAccount',
    label: 'Virtual Billing Account',
  },
];

export const NAVS = [
  {
    value: 'allItems',
    label: 'All Items',
  },
  {
    value: 'freeItems',
    label: 'Free Items',
  },
  {
    value: 'paidItems',
    label: 'Paid Items',
  },
  {
    value: 'rechargeItems',
    label: 'Recharge Items',
  },
];

export const RENEWAL_OPTION = [
  { value: 'auto', label: 'Auto renews until canceled.' },
  { value: 'cycle', label: 'Expires after' },
];

export const BILLING_CYCLE_OPTION = [
  { value: 'month', label: 'Monthly' },
  { value: 'six-month', label: '6 Monthly' },
  { value: 'annually', label: 'Annually' },
];

export const NAV_ADDRESS = [
  {
    value: 1,
    label: 'Billing Address',
  },
  {
    value: 2,
    label: 'Email Billing Address',
  },
  { value: 3, label: 'Ship To' },
];
