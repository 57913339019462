import { atom } from 'recoil';
import { StepAddValue } from '@settings/process/types/diagram';
import { STEP_TYPES } from '@settings/process/config/constants';
import { StepType } from '@settings/process/types/process';

export const stepAddOpenAtom = atom<StepAddValue>({
  key: 'stepAddOpenAtom',
  default: {
    edit: false,
    sourceId: '',
    directionId: '',
    direction: 'DIRECTION_NONE',
    sourceType: 'TYPE_NONE',
    shape: 'SHAPE_NONE',
    position: {
      x: 0,
      y: 0,
    },
  },
});

const stepTypeAtom = atom<StepType>({
  key: 'stepTypeAtom',
  default: STEP_TYPES[0],
});

export default stepTypeAtom;
