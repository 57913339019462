import React from 'react';

const TextPreview: React.FC<any> = (props: any) => {
  const { txtValue, bgColor, textColor, bdRounded, fontSize, fontWeight } = props;

  return (
    <button
      type={'button'}
      className="btn"
      style={{
        backgroundColor: bgColor ?? '',
        color: textColor ?? '',
        borderRadius: `${bdRounded ?? 0}px`,
      }}
    >
      <span
        style={{
          color: textColor ?? '',
          fontSize: fontSize ?? 11,
          fontWeight: fontWeight ?? 'normal',
        }}
      >
        {txtValue ?? ''}
      </span>
    </button>
  );
};

export default TextPreview;
