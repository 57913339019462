import { Input, RadioGroup, TextArea } from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';
import { LabelValue } from '@base/types/types/app';

import {
  KEY_NAME_SETTING_NAME,
  KEY_NAME_SETTING_DESCRIPTION,
  KEY_NAME_SETTING_CRITERIA_TYPE,
} from '@settings/process/config/keyNames';
import { CRITERIA_TYPES } from '@settings/process/components/criteria';

export const CriteriaWriteField = {
  [KEY_NAME_SETTING_NAME]: {
    component: Input,
    showFullRow: false,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    languageKey: 'process_criteria_name',
    parseParam: (value: string) => value,
  },
  [KEY_NAME_SETTING_CRITERIA_TYPE]: {
    component: RadioGroup,
    defaultValue: CRITERIA_TYPES[0],
    componentProps: {
      options: CRITERIA_TYPES,
      isHorizontal: true,
    },
    showFullRow: false,
    languageKey: 'process_criteria_type',
    parseParam: (v: LabelValue) => v.value,
  },
  [KEY_NAME_SETTING_DESCRIPTION]: {
    component: TextArea,
    showFullRow: true,
    defaultValue: '',
    languageKey: 'process_criteria_description',
    parseParam: (value: string) => value,
  },
};

export default CriteriaWriteField;
