import SelectBox from '@base/containers/view-field/selectbox/edit';
import { Button, Checkbox } from '@base/components/form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionValue } from '@base/types/interfaces/common';
import { useNextStepAtomValue } from '@settings/process/recoil/diagram';
import _ from 'lodash';
import { Automation } from '@settings/process/types/process';
import { Duration } from '@base/config/write-field/components';
import { useRecoilValue } from 'recoil';
import statusAtom from '@settings/process/recoil/status';

interface AutomationWriteProps {
  value: Automation;
  onChange: (val: Automation) => void;
}

function AutomationWrite({ value, onChange }: AutomationWriteProps) {
  // console.log('automation', value);
  const statusesValue = useRecoilValue(statusAtom);
  const nextSteps = useNextStepAtomValue();

  const sleepingStatuses = useMemo(() => {
    let options: OptionValue[] = [];
    statusesValue.forEach((status) => {
      if (
        status.direction.keyName == 'DIRECTION_STAYING'
        //  ||
        // status.direction.keyName == 'DIRECTION_FORWARD_INCOMING_MIDDLE'
      ) {
        options.push({
          keyName: status.id,
          languageKey: status.name,
        });
      }
    });
    return options;
  }, [statusesValue]);

  const [sleepingStatus, setSleepingStatus] = useState(sleepingStatuses[0]);

  useEffect(() => {
    setSleepingStatus(sleepingStatuses[0]);
  }, [sleepingStatuses]);

  const onSleeping = useCallback(() => {
    onChange({ ...value, useSleeping: !value.useSleeping });
  }, [value]);

  const onDuration = useCallback(
    (val: any) => {
      onChange({ ...value, sleeping: { ...value.sleeping, duration: val } });
    },
    [value],
  );

  // const onTerm = useCallback(
  //   (val: OptionValue) => {
  //     onChange({ ...value, sleeping: { ...value.sleeping, term: val.keyName } });
  //   },
  //   [value],
  // );

  const onStatus = useCallback((val: OptionValue) => {
    setSleepingStatus(val);
  }, []);

  const onNotification = useCallback(
    (index: number) => {
      let execute = _.cloneDeep(value.sleeping.executes[index]);
      execute.useNotify = !execute.useNotify;

      onChange({
        ...value,
        sleeping: {
          ...value.sleeping,
          executes: [
            ...value.sleeping.executes.slice(0, index),
            execute,
            ...value.sleeping.executes.slice(index + 1),
          ],
        },
      });
    },
    [value],
  );
  const onNotificationSelect = useCallback(
    (index: number, val: OptionValue) => {
      let execute = _.cloneDeep(value.sleeping.executes[index]);
      execute.notify = { id: val.keyName, name: val.languageKey };

      onChange({
        ...value,
        sleeping: {
          ...value.sleeping,
          executes: [
            ...value.sleeping.executes.slice(0, index),
            execute,
            ...value.sleeping.executes.slice(index + 1),
          ],
        },
      });
    },
    [value],
  );

  const onStep = useCallback(
    (index: number) => {
      let execute = _.cloneDeep(value.sleeping.executes[index]);
      execute.useChangeStep = !execute.useChangeStep;

      onChange({
        ...value,
        sleeping: {
          ...value.sleeping,
          executes: [
            ...value.sleeping.executes.slice(0, index),
            execute,
            ...value.sleeping.executes.slice(index + 1),
          ],
        },
      });
    },
    [value],
  );

  const onStepSelect = useCallback(
    (index: number, val: OptionValue) => {
      let execute = _.cloneDeep(value.sleeping.executes[index]);
      execute.changeStep = { id: val.keyName, name: val.languageKey };

      onChange({
        ...value,
        sleeping: {
          ...value.sleeping,
          executes: [
            ...value.sleeping.executes.slice(0, index),
            execute,
            ...value.sleeping.executes.slice(index + 1),
          ],
        },
      });
    },
    [value],
  );

  const onMywork = useCallback(
    (index: number) => {
      let execute = _.cloneDeep(value.sleeping.executes[index]);
      execute.useMywork = !execute.useMywork;

      onChange({
        ...value,
        sleeping: {
          ...value.sleeping,
          executes: [
            ...value.sleeping.executes.slice(0, index),
            execute,
            ...value.sleeping.executes.slice(index + 1),
          ],
        },
      });
    },
    [value],
  );

  const onExecuteAdd = useCallback(() => {
    if (!value.sleeping.executes) {
      value.sleeping.executes = [];
    }
    const targetIndex = value.sleeping.executes.findIndex(
      (execute) => execute.status.id === sleepingStatus.keyName,
    );
    if (targetIndex != -1) return;

    onChange({
      ...value,
      sleeping: {
        ...value.sleeping,
        executes: [
          ...value.sleeping.executes,
          {
            status: { id: sleepingStatus.keyName, name: sleepingStatus.languageKey },
            useNotify: false,
            notify: { id: '', name: 'Select' },
            useChangeStep: false,
            changeStep: { id: '', name: 'Select' },
            useMywork: false,
          },
        ],
      },
    });
  }, [value, sleepingStatus]);

  const onExecuteDelete = useCallback(
    (index: number) => {
      onChange({
        ...value,
        sleeping: {
          ...value.sleeping,
          executes: [
            ...value.sleeping.executes.slice(0, index),
            ...value.sleeping.executes.slice(index + 1),
          ],
        },
      });
    },
    [value],
  );

  return (
    <div className="form-row">
      <div className="form-group col-8">
        <label className="form-item-title">Automation</label>
        <Checkbox checked={value.useSleeping} onChange={onSleeping} label={'Sleeping'} />
        {value.useSleeping && (
          <div className="mg-t-15 mg-l-25">
            <div className="d-flex flex-wrap align-items-center">
              <span className="mg-r-10">If this Step is sleeping for</span>
              <Duration
                value={{
                  duration: value.sleeping?.duration?.time,
                  durationUnit: value.sleeping?.duration?.unit,
                }}
                onChange={onDuration}
              />
            </div>
            <div className="card mg-y-10">
              <div className="card-header pd-y-10 bg-light">
                Status
                <SelectBox
                  className="wd-150-f mg-l-10"
                  value={sleepingStatuses[0]}
                  onChange={(val: OptionValue) => onStatus(val)}
                  options={sleepingStatuses}
                  isSearchable={false}
                  errors={{}}
                />
                <Button
                  name="Add"
                  icon="Plus"
                  color="link"
                  className="mg-l-25"
                  onClick={onExecuteAdd}
                />
              </div>
              {value.sleeping?.executes?.map((execute, index) => {
                return (
                  <div key={index} className="card-body">
                    <label className="form-item-title">
                      Execute Action: {execute.status.name}
                      <Button
                        name="Delete"
                        icon="Minus"
                        color="link"
                        className="mg-l-25"
                        onClick={() => onExecuteDelete(index)}
                      />
                    </label>
                    <div className="d-flex align-items-center mg-b-5">
                      <Checkbox
                        checked={execute.useNotify}
                        className="mg-r-15"
                        label={'Notification'}
                        onChange={() => onNotification(index)}
                      />
                      <SelectBox
                        className="wd-150-f mg-x-5"
                        value={{ keyName: execute.notify.id, languageKey: execute.notify.name }}
                        onChange={(val: OptionValue) => onNotificationSelect(index, val)}
                        options={[
                          { keyName: '1', languageKey: 'Notification 1' },
                          { keyName: '2', languageKey: 'Notification 2' },
                        ]}
                        isSearchable={false}
                        isDisabled={!execute.useNotify}
                        errors={{}}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <Checkbox
                        className="mg-r-15"
                        label={'Change Step'}
                        onChange={() => onStep(index)}
                        checked={execute.useChangeStep}
                      />
                      <SelectBox
                        className="wd-150-f mg-x-5"
                        value={{
                          keyName: execute.changeStep.id,
                          languageKey: execute.changeStep.name,
                        }}
                        onChange={(val: OptionValue) => onStepSelect(index, val)}
                        options={nextSteps}
                        isSearchable={false}
                        isDisabled={!execute.useChangeStep}
                        errors={{}}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <Checkbox
                        className="mg-r-15 mg-t-5"
                        label={'My work listing for assigned user/group'}
                        checked={execute.useMywork}
                        onChange={() => onMywork(index)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AutomationWrite;
