import React from 'react';
import TextView from '@base/containers/view-field/text/view';

const View: React.FC = (props: any) => {
  const { value } = props;
  //value is object id, name
  return <TextView value={value?.name || ''} />;
};

export default View;
