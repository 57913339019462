import React from 'react';
import WriteFrom from '../../../../containers/write-form';

const WriteFormDesktopLayout1: React.FC<any> = (props: any) => {
  return (
    <>
      <WriteFrom {...props} />
    </>
  );
};

export default WriteFormDesktopLayout1;
