import { StatusForm } from '@settings/process/types/process';
import { Edit2, X } from 'react-feather';
import {
  PROCESS_STATUS_DIRECTIONS_VIEW,
  PROCESS_STATUS_EVENTS_VIEW,
  PROCESS_STATUS_PROPERTIES_VIEW,
  PROCESS_STATUS_VIEWS_VIEW,
} from '@settings/process/config/constants';
import { useCallback } from 'react';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { useQueryClient } from '@tanstack/react-query'; //v4
import { DELETE_STEP_STATUS } from '@settings/process/services/process';
import { toast } from 'react-toastify';
import React from 'react';
import _ from 'lodash';
import { selectedStatusAtom, statusWithParallelFilter } from '@settings/process/recoil/status';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import stepTypeAtom from '@settings/process/recoil/step';
import { checkParallel } from '@settings/process/utils/helper';

interface StatusItemViewProps {
  processId: string;
  stepId: string;
  status: StatusForm;
  parallelIndex: number;
  isCta?: boolean;
  actionMethod?: string;
}

function StatusItemView(props: StatusItemViewProps) {
  const { processId, stepId, status, parallelIndex, isCta, actionMethod } = props;
  const stepType = useRecoilValue(stepTypeAtom);
  const setSelectedStatus = useSetRecoilState(selectedStatusAtom);
  const parallelCount = useRecoilValue(statusWithParallelFilter(isCta ? 'cta' : 'normal')).length;
  const isParallel = checkParallel(status);

  const onEdit = useCallback((id: string) => {
    setSelectedStatus(id);
  }, []);

  const queryClient = useQueryClient();
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_STEP_STATUS,
    'process_deleteStatus',
    {
      onSuccess: (data: any) => {
        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', processId]);
          queryClient.invalidateQueries(['process_step', stepId]);
        }, 500);
        // console.log('success data', data);
        toast.success('Delete status successfully!');
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Delete status failed');
      },
    },
  );

  const onDelete = useCallback((id: string) => {
    alert('Are you sure to delete status => ' + id);
    mutationDelete.mutate({ id: processId, stepId: stepId, statusId: id });
  }, []);

  const renderButton = useCallback(() => {
    return <div className="">{status.button}</div>;
  }, [status]);

  const renderView = useCallback(() => {
    return <div className="">{PROCESS_STATUS_VIEWS_VIEW[status.view.keyName]}</div>;
  }, [status]);

  const renderEvent = useCallback(() => {
    return <div className="">{PROCESS_STATUS_EVENTS_VIEW[status.event.keyName]}</div>;
  }, [status]);

  const renderSequence = useCallback(() => {
    return <div className="">{status.sequence[0] != '' && status.sequence.join('/')}</div>;
  }, [status]);

  return (
    <tr className="ht-55 text-center">
      {isCta && stepType.value === 'TYPE_SITE' && <td>{status?.pageName}</td>}
      {actionMethod != 'ACTION_METHOD_AUTO' &&
        stepType.value !== 'TYPE_WAIT' &&
        (isParallel && parallelCount > 1 ? (
          parallelIndex == 1 ? (
            <td rowSpan={parallelCount}>{renderButton()}</td>
          ) : (
            ''
          )
        ) : (
          <td>{renderButton()}</td>
        ))}
      {actionMethod != 'ACTION_METHOD_AUTO' && stepType.value !== 'TYPE_WAIT' && !isCta && (
        <>
          {isParallel && parallelCount > 1 ? (
            parallelIndex == 1 ? (
              <td rowSpan={parallelCount}>{renderView()}</td>
            ) : (
              ''
            )
          ) : (
            <td>{renderView()}</td>
          )}
        </>
      )}
      {actionMethod != 'ACTION_METHOD_AUTO' &&
        (isParallel && parallelCount > 1 ? (
          parallelIndex == 1 ? (
            <td rowSpan={parallelCount}>{renderEvent()}</td>
          ) : (
            ''
          )
        ) : (
          <td>{renderEvent()}</td>
        ))}
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{status.name}</td>
        ) : (
          ''
        )
      ) : (
        <td>{status.name}</td>
      )}
      <td>
        <div className="">{PROCESS_STATUS_DIRECTIONS_VIEW[status.direction.keyName]}</div>
      </td>
      <td>
        <div className="">{status.nextStep?.languageKey}</div>
      </td>
      <td>
        <div className="">{PROCESS_STATUS_PROPERTIES_VIEW[status.property.keyName]}</div>
      </td>
      {stepType.value !== 'TYPE_SIMPLE_ACTION' && stepType.value !== 'TYPE_WAIT' && !isCta && (
        <>
          {isParallel && parallelCount > 1 ? (
            parallelIndex == 1 ? (
              <td rowSpan={parallelCount}>{renderSequence()}</td>
            ) : (
              ''
            )
          ) : (
            <td>{renderSequence()}</td>
          )}
        </>
      )}
      <td>
        <div className="d-flex align-items-center justify-content-center">
          <button
            type="button"
            className="btn btn-xs btn-link tx-primary btn-icon"
            onClick={() => onEdit(status.id)}
          >
            <Edit2 />
          </button>
          {status.property.keyName !== 'PROPERTY_NEW' &&
            status.property.keyName !== 'PROPERTY_TODO' &&
            status.property.keyName !== 'PROPERTY_TODO_DOING' && (
              <button
                type="button"
                className="btn btn-xs btn-link tx-danger btn-icon"
                onClick={() => onDelete(status.id)}
              >
                <X />
              </button>
            )}
        </div>
      </td>
    </tr>
  );
}

export default React.memo(StatusItemView);
