import { Button } from '@base/components/form';
import { NAV_ADDRESS } from '@settings/billing-license/config/constant';
import React, { useState } from 'react';

interface IBillingInfoAddress {
  value: any;
  onChange: (keyName: string, keyValue: string) => any;
}

const BillingInfoAddress: React.FC<IBillingInfoAddress> = (props: IBillingInfoAddress) => {
  const [navActive, setNavActive] = useState(1);
  return (
    <div className="card">
      <div className="card-header bg-light with-tabs">
        <nav className="nav nav-line">
          {NAV_ADDRESS.map((_item: any) => {
            return (
              <Button
                id={_item.value}
                color="white"
                className={
                  navActive == _item.value
                    ? 'btn nav-link active text-primary bg-transparent border-0'
                    : 'btn nav-link bg-transparent border-0'
                }
                onClick={(data: any) => setNavActive(data.target.id)}
              >
                {_item.label}
              </Button>
            );
          })}
        </nav>
      </div>
      <div className="card-body"></div>
    </div>
  );
};
export default BillingInfoAddress;
