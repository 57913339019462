//basic
export const KEY_SURVEY_ID = 'id';
export const KEY_SURVEY_NAME = 'name';
export const KEY_SURVEY_TITLE = 'title';
export const KEY_SURVEY_TYPE = 'type';
export const KEY_SURVEY_LANGUAGE = 'language';
export const KEY_SURVEY_STAGE = 'stage';
export const KEY_SURVEY_DESCRIPTION = 'description';
export const KEY_SURVEY_TEMPLATE = 'template';
export const KEY_SURVEY_CONTENT = 'content';

export const KEY_SURVEY_CREATED_BY = 'createdBy';
export const KEY_SURVEY_CREATED_AT = 'createdAt';
export const KEY_SURVEY_UPDATED_BY = 'updatedBy';
export const KEY_SURVEY_UPDATED_AT = 'updatedAt';
