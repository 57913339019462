import * as keyNames from '@settings/process/config/keyNames';
import { Input, TextArea } from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';

export const StageWriteField = {
  [keyNames.KEY_NAME_STAGE_NAME]: {
    component: Input,
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    languageKey: 'process_stage_name',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_STAGE_DESCRIPTION]: {
    component: TextArea,
    showFullRow: true,
    languageKey: 'process_stage_description',
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  // [keyNames.KEY_NAME_STAGE_PROPERTY]: {
  //   component: Switch,
  //   showFullRow: true,
  //   defaultValue: false,
  //   componentProps: {
  //     label: 'process_stage_property',
  //   },
  //   hideTitle: true,
  //   parseParam: (value: boolean) => value,
  // },
};

export default StageWriteField;
