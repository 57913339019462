import { Select } from '@base/components/form';
import { useTimezones } from '@settings/general-setting/services/format-service';
import React, { useState } from 'react';
import { useGetList } from '@base/services/list';
import { FilterInput, ISearchFields } from '@base/types/interfaces/common';
import { ParseSearchFieldsToQuery } from '@base/utils/helpers/advanced-search-query';

interface ITimezoneSelectProps {
  onChange: (params: any) => void;
}
const TimezoneSelect: React.FC<ITimezoneSelectProps> = (props: ITimezoneSelectProps) => {
  const { onChange } = props;
  const [keyword, setKeyword] = useState('');
  //const { data, isLoading } = useTimezones(keyword);
  //B new searchFilter

  let searchFields: ISearchFields[] = [
    {
      key: 'nationKo',
      type: 'text',
      label: 'Nation Ko',
      value: '',
    },
    {
      key: 'nationEn',
      type: 'text',
      label: 'Nation En',
      value: '', //Afghanistan
    },
    {
      key: 'nationJp',
      type: 'text',
      label: 'Nation Jp',
      value: '',
    },
    {
      key: 'tzone',
      type: 'text',
      label: 'Timezone',
      value: '',
    },
    {
      key: 'sdtime',
      type: 'string',
      label: 'sdtime',
      value: '',
    },
    {
      key: 'sdutc',
      type: 'string',
      label: 'sdutc',
      value: '',
    },
  ];
  let schemas: string[] = ['nationEn', 'tzone', 'sdtime', 'sdutc'];
  let listQuerySchema = schemas.join('\n');
  let filtersQuery: FilterInput = {
    query: ParseSearchFieldsToQuery(searchFields), //code=AED
  };
  const { isLoading, data, isFetching, isPreviousData, refetch } = useGetList<any[]>(
    'setting_availableTimezones',
    listQuerySchema,
    filtersQuery,
  );
  //E new searchFilter

  const onInputChange = (newValue: string, event: any) => {
    // console.log('onInputChange', newValue);
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setKeyword(newValue);
    }
  };
  return (
    <Select
      onChange={onChange}
      isSearchable={true}
      isLoading={isLoading}
      placeholder={'Select...'}
      options={data?.data ?? []}
      //options={data?.results ?? []}
      getOptionLabel={(timezone: any) => {
        return timezone.nationEn;
      }}
      getOptionValue={(timezone: any) => {
        return timezone;
      }}
      inputValue={keyword}
      onInputChange={onInputChange}
    />
  );
};

export default TimezoneSelect;
