import { SelectBox } from '@base/config/write-field/components';
import _ from 'lodash';
import { useCallback } from 'react';
import { OptionValue } from '@base/types/interfaces/common';
import { MODULE } from '@base/config/constant';
import { PROCESS_STATUS_PROPERTIES_VIEW, TRIGGER_TYPE } from '@settings/process/config/constants';
import useTrigger from '@settings/process/hooks/useTrigger';

interface TriggerAutomationProps {
  value: OptionValue;
  onChange: (option: OptionValue) => void;
}

function TriggerAutomation(props: TriggerAutomationProps) {
  const { value: triggerValue, onChange } = props;
  const [defined, options] = useTrigger(triggerValue?.keyName);
  const onChangeTrigger = useCallback((option: OptionValue) => {
    onChange && onChange(option);
  }, []);

  return (
    <div className="form-row pd-l-15 pd-r-15">
      <div className="form-group col-6">
        <label className="form-item-title">Trigger Name</label>
        <SelectBox
          options={options}
          value={triggerValue}
          onChange={(newVal) => onChangeTrigger(newVal)}
          errors={{}}
        />
      </div>
      {defined && (
        <>
          <div className="form-group col-6">
            <label className="form-item-title">Trigger Type</label>
            <div className="wd-250">{TRIGGER_TYPE[defined.trigger]}</div>
          </div>
          {(defined.trigger == 'TRIGGER_TYPE_RECORD_CREATED' ||
            defined.trigger == 'TRIGGER_TYPE_RECORD_UPDATED' ||
            defined.trigger == 'TRIGGER_TYPE_RECORD_CREATED_UPDATED' ||
            defined.trigger == 'TRIGGER_TYPE_RECORD_DELETED') && (
            <>
              <div className="form-group-title col-12">Record</div>
              <div className="form-group col-12">
                <label className="form-item-title">Module</label>
                <div className="wd-250">{MODULE[defined.module]}</div>
              </div>
            </>
          )}
          {defined.trigger == 'TRIGGER_TYPE_FIELD_UPDATED' && (
            <>
              <div className="form-group-title col-12">Field</div>
              <div className="form-group col-6">
                <label className="form-item-title">Module</label>
                <div className="wd-250">{MODULE[defined.module]}</div>
              </div>
              <div className="form-group col-6">
                <label className="form-item-title">Field</label>
                <div className="wd-250">{defined.field}</div>
              </div>
            </>
          )}
          {defined.trigger == 'TRIGGER_TYPE_PROCESS_PROPERTY_UPDATED' && (
            <>
              <div className="form-group-title col-12">Process</div>
              <div className="form-group col-6">
                <label className="form-item-title">Module</label>
                <div className="wd-250">{MODULE[defined.module]}</div>
              </div>
              <div className="form-group col-6">
                <label className="form-item-title">Process Name</label>
                <div className="wd-250">{defined.process.name}</div>
              </div>
              <div className="form-group col-6">
                <label className="form-item-title">Step Name</label>
                <div className="wd-250">{defined.step.name}</div>
              </div>
              <div className="form-group col-6">
                <label className="form-item-title">Step Property</label>
                <div className="wd-250">{PROCESS_STATUS_PROPERTIES_VIEW[defined.property]}</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default TriggerAutomation;
