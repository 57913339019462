import { FormIcon } from '@base/components/form';
import { IconType } from '@base/types/types/app';
import classNames from 'classnames';
import React from 'react';
import PlaceholderItem from '../placeholder/item';

interface IItemProps {
  label: any | string;
  icon: string;
  iconType: IconType;
  isCenter?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}
const Item: React.FC<IItemProps> = (props: IItemProps) => {
  const {
    label = '',
    icon = '',
    iconType = 'icon',
    isCenter = false,
    isLoading = false,
    isDisabled = false,
  } = props;
  if (isLoading) {
    // console.log('Item Loading');
    return <PlaceholderItem />;
  }

  return (
    <div
      className={classNames('page-layout-item d-flex bg-white', {
        'justify-content-center': isCenter,
        'page-layout-item-disabled': isDisabled,
      })}
    >
      <div className="d-flex align-items-center" style={{ flex: 1 }}>
        <div className="view-icon">
          <FormIcon icon={icon} iconType={iconType} />
        </div>
        <span className="pd-l-6" style={{ flex: 1 }}>
          {label}
        </span>
      </div>
    </div>
  );
};

export default Item;
