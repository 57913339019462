import React, { useState, useEffect, useCallback, Fragment, useMemo } from 'react';
import _ from 'lodash';
import { cloneDeep } from 'lodash';
//import d2i from "dom-to-image";
//import { dataURLtoFile, toDataURL } from '../../utils/ImageUtils';
import {
  Q_MULTI_CHOICES,
  Q_TITLE,
  Q_IMAGE,
  Q_VIDEO,
} from '@settings/online-digital-content/survey/config/constants';
import { storeSurveyToStorage } from '@settings/online-digital-content/survey/services/storage';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { generateUUID } from '@base/utils/helpers';

import SurveyTitle from '../survey-title';
import SurveyToolbar from '../survey-toolbar';
import Question from '../question';
import QuestionMedia from '../question-media';

/**
 *
 * @param {*} props
 */
const QuestionWrite: React.FC<any> = (props) => {
  const {
    storageOn = false,
    questionRef,
    surveyId,
    surveyHeaderImg,
    surveyHeadLineColor,
    surveyBgColor,
    surveyImage, //TODO
    value, //surveySections
    onChange, //onSetSurveySections
  } = props;

  //state
  const [focusS, setFocusS] = useState<number>(0); //focus section
  const [focusQ, setFocusQ] = useState<number>(0); //focus question
  const [surveyS, setSurveyS] = useState<any>([]); //all sections
  const [focusEle, setFocusEle] = useState<any>(null);

  //set init data for survey
  useEffect(() => {
    //// console.log('init sections', value);
    //window.scrollTo({ behavior: 'smooth', top: 980 });
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(surveyS)) {
        setSurveyS(value);
      }
    } else {
      setSurveyS([]); //TODO: init default value
    }
  }, [value]);

  // useEffect(() => {
  //   // console.log('focusEle', focusEle);
  //   //window.scrollTo({ behavior: 'smooth', top: 980 });
  //   const eleItem: any = document.getElementById(focusEle);
  //   const surveyEle: any = document.getElementById('survey-content');

  //   if (eleItem) {
  //     //eleItem.scrollIntoView(true);
  //     //scrollRef.current.scrollTo({ behavior: 'smooth', top: 500 });
  //     //window.scroll(0, 800);
  //     //surveyEle.scrollTop = 10;
  //     // window.scrollTo({
  //     //   top: 900,
  //     //   behavior: "smooth",
  //     // });
  //   }
  // }, [focusEle]); //focusEle, focusS, focusQ, surveyS

  //handle mouseup to check element event click and focus
  useEffect(() => {
    document.addEventListener('mouseup', handleEleClick);
    return () => {
      document.removeEventListener('mouseup', handleEleClick);
    };
  }, []);

  //handle saving when survey content changes
  useEffect(() => {
    if (storageOn) {
      //auto save for text content
      const surveyData = {
        id: surveyId,
        headerImg: surveyHeaderImg || '',
        headerLineColor: surveyHeadLineColor,
        bgColor: surveyBgColor,
        //image: surveyImage,
        sections: surveyS,
      };
      onSaveSurveyDebounce(_.cloneDeep(surveyData));
    }
  }, [surveyS, surveyHeadLineColor]);

  //indexeddb store
  async function storeSurvey(data: any) {
    return await storeSurveyToStorage(data);
  }

  //debounce save survey
  const onSaveSurvey = (surveyData: any) => {
    // console.log('handle indexeddb saving..... ', surveyData);
    //save to indexeddb
    storeSurvey(surveyData);

    //TODO: save to DB - call mutation
    //const params = {};
    //mutationSave.mutate({ survey: params });

    // if (newSurveyS && newSurveyS.length > 0) {
    //   //onSetSaving(true);
    //   //get survey when init write
    //   const surveyName = newSurveyS.length > 0 ? newSurveyS[0].title : '';
    //   const surveyDesc = newSurveyS.length > 0 ? newSurveyS[0].description : '';
    //   const params = {
    //     survey_id: surveyId,
    //     name: surveyName,
    //     description: surveyDesc,
    //     sections: JSON.stringify(newSurveyS)
    //   };
    //   // myAxios.put(apis.updateSurvey, params).then((res) => {
    //   //     onSetSaving(false);
    //   // })
    //   //     .catch(function (error) {
    //   //         //// console.log(error);
    //   //     });
    // }
  };
  //debounce function
  const onSaveSurveyDebounce = useCallback(_.debounce(onSaveSurvey, 1000), []);

  //add question when focusing on child (question) component
  function handleEleClick(e: any) {
    const el = e.path;
    //// console.log('el', el);

    //handle focusing
    let surveyEleId = el[0].id ? el[0].id : el[1].id;
    surveyEleId = surveyEleId ? surveyEleId : el[2].id;
    //surveyEleId = surveyEleId ? surveyEleId : el[3].id;
    //// console.log('surveyEleId', surveyEleId);

    //format: q-optinput-0-5-1 --> 0: section index, 5: question index, 1: option index
    if (surveyEleId) {
      const items = surveyEleId.split('-');
      const nameEle = items[0] + '-' + items[1];
      const elKeyS = parseInt(items[2]);
      const elKeyQ = parseInt(items[3]); //idx

      //for input element
      if (
        nameEle === 'survey-title' ||
        nameEle === 'survey-desc' ||
        nameEle === 'survey-dropdown' ||
        nameEle === 'q-title' ||
        nameEle === 'q-optinput' ||
        //|| nameEle === 'q-move'
        nameEle === 'q-mediatitle' ||
        nameEle === 'q-mediadesc' ||
        nameEle === 'q-mediavideo' ||
        nameEle === 'q-rowopt' ||
        nameEle === 'q-colopt'
      ) {
        setFocusEle(surveyEleId); //focus on element (eg: input)
        setFocusS(elKeyS); //focus on section
        setFocusQ(elKeyQ); //focus on question
      }
    } else {
      //setFocusEle('');
    }
  }

  //handle save survey thumb image
  function handleSaveSurveyImage() {
    //// console.log('start save image...');
    //create and upload image
    // d2i.toPng(questionRef.current)
    //   .then((dataUrl) => {
    //     let formData = new FormData();
    //     const file = dataURLtoFile(dataUrl, 'survey_theme' + surveyId + '.png');
    //     formData.append("file", file);
    //     // myAxios.post(apis.uploadImage, formData).then((res) => {
    //     //     if (res.data.success) {
    //     //         //update image url to template
    //     //         const imgPath = apis.getImage + '?path=' + res.data.data.path + res.data.data.name;
    //     //         const params = {
    //     //             survey_id: surveyId,
    //     //             image: imgPath
    //     //         }
    //     //         myAxios.put(apis.updateSurveyImage, params);
    //     //     }
    //     // })
    //     //     .catch((error) => {
    //     //         // console.log('save image error', error);
    //     //     });
    //   })
    //   .catch((error) => {
    //     console.error("oops, something went wrong!", error);
    //   });
  }

  //change focus to section
  function handleFocusQChange(keyS: any, keyQ: any) {
    setFocusS(keyS);
    setFocusQ(keyQ);
  }

  //add new question to section
  function handleAddNewQuestion(e: any) {
    e.preventDefault();
    if (focusS !== undefined) {
      let newQuestion = {
        id: generateUUID(),
        type: Q_MULTI_CHOICES,
        title: '',
        image: {
          name: '',
          //url: 'http://global3.hanbiro.com/hungtv/laravelnew/public/web/download?path=/home/HanbiroMailcore/GWDATA/global3.hanbiro.com/laravel/app/upload/image-png/2020-07-27/stat1_5ddd6a332016f2220b8666541e61bbcb.png',
          url: '',
          size: '',
        },
        required: false,
        options: [
          {
            id: generateUUID(),
            value: '',
            image: {
              name: '',
              //url: 'http://global3.hanbiro.com/hungtv/laravelnew/public/web/download?path=/home/HanbiroMailcore/GWDATA/global3.hanbiro.com/laravel/app/upload/image-png/2020-07-27/stat1_5ddd6a332016f2220b8666541e61bbcb.png',
              url: '',
              size: '',
            },
            isOther: false,
          },
        ],
      };

      let newSurveyS: any = [...surveyS];
      //newSurveyS[focusS].questions.push(newQuestion);
      newSurveyS[focusS].questions.splice(focusQ, 0, newQuestion);

      //set state
      setSurveyS(newSurveyS);

      //handle save survey thumb
      if (surveyS && surveyS.length > 0) {
        //capture thumb image for survey
        //WHEN: surveyS[0].questions.length=1,2,3 or 4
        if (surveyS[0].questions.length < 5) {
          //save image
          handleSaveSurveyImage();
        }
      }

      //callback
      onChange && onChange(newSurveyS);
    }
  }

  //add new question title to section
  function handleAddNewQuestionMedia(typeQ: number) {
    if (focusS !== undefined) {
      let newQuestion = null;
      switch (typeQ) {
        case Q_TITLE:
          newQuestion = {
            id: generateUUID(),
            type: typeQ,
            title: '',
            description: '',
          };
          break;
        case Q_IMAGE:
          newQuestion = {
            id: generateUUID(),
            type: typeQ,
            title: '',
            image: {
              name: '',
              url: '',
            },
          };
          break;
        case Q_VIDEO:
          newQuestion = {
            id: generateUUID(),
            type: typeQ,
            title: '',
            video: '',
          };
          break;
      }

      let newSurveyS = [...surveyS];
      newSurveyS[focusS].questions.splice(focusQ, 0, newQuestion);

      //set state
      setSurveyS(newSurveyS);

      //callback
      onChange && onChange(newSurveyS);
    }
  }

  //new section
  function handleAddNewSection(e: any) {
    e.preventDefault();
    const newSection = {
      id: generateUUID(),
      title: '',
      description: '',
      questions: [
        {
          id: generateUUID(),
          type: Q_MULTI_CHOICES,
          title: '',
          image: {
            name: '',
            //url: 'http://global3.hanbiro.com/hungtv/laravelnew/public/web/download?path=/home/HanbiroMailcore/GWDATA/global3.hanbiro.com/laravel/app/upload/image-png/2020-07-27/stat1_5ddd6a332016f2220b8666541e61bbcb.png',
            url: '',
            size: '',
          },
          required: false,
          options: [
            {
              id: generateUUID(),
              value: '',
              image: {
                name: '',
                //url: 'http://global3.hanbiro.com/hungtv/laravelnew/public/web/download?path=/home/HanbiroMailcore/GWDATA/global3.hanbiro.com/laravel/app/upload/image-png/2020-07-27/stat1_5ddd6a332016f2220b8666541e61bbcb.png',
                url: '',
                size: '',
              },
              isOther: false,
            },
          ],
        },
      ],
    };

    let newSurveyS = cloneDeep(surveyS); //[...surveyS];
    newSurveyS.push(newSection);

    //set state
    setSurveyS(newSurveyS);

    //callback
    onChange && onChange(newSurveyS);
  }

  //remove section to section
  function handleRemoveSection(keyS: number) {
    let newSurveyS = [...surveyS];
    newSurveyS.splice(keyS, 1);

    //set state
    setSurveyS(newSurveyS);

    //callback
    onChange && onChange(newSurveyS);
  }

  //remove question to section
  function handleRemoveQ(keyS: number, keyQ: number) {
    let newSurveyS = [...surveyS];
    newSurveyS[keyS].questions.splice(keyQ, 1);
    setSurveyS(newSurveyS);

    //callback
    onChange && onChange(newSurveyS);
  }

  //copy Question to section
  function handleCopyQ(keyS: number, keyQ: number) {
    let newSurveyS = [...surveyS];
    //get copied question
    let targetQuestions = newSurveyS[keyS].questions.filter(
      (item: any, key: number) => key === keyQ,
    );
    if (targetQuestions.length > 0) {
      //start copy
      const newQuestion = JSON.parse(JSON.stringify(targetQuestions[0])); //copy in deep
      newQuestion.id = generateUUID(); // new id
      newSurveyS[keyS].questions.push(newQuestion);
      //set state
      setSurveyS(newSurveyS);
      //callback
      onChange && onChange(newSurveyS);
    }
  }

  //survey value change: title, description
  const handleSurveyChange = (indexS: number, keyName: string, keyValue: any) => {
    const newSurveyS = [...surveyS];
    newSurveyS[indexS][keyName] = keyValue;
    setSurveyS(newSurveyS);
    //callback
    onChange && onChange(newSurveyS);
  };

  //question content change
  const handleQuestionChange = (indexS: number, indexQ: number, newQuestion: any) => {
    const newSurveyS = [...surveyS];
    newSurveyS[indexS].questions[indexQ] = newQuestion;
    setSurveyS(newSurveyS);
    //callback
    onChange && onChange(newSurveyS);
  };

  /**====================== DRAG-DROP event ==================================*/

  //drag end, update new position
  const handleDragEnd = (result: any) => {
    //// console.log('handleDragEnd =>', result);
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    //re-order the items
    const newSurveyS = [...surveyS];
    //get source id
    const sourceDropId = source.droppableId.split('-');
    const sourceSectionId = sourceDropId.length > 1 ? parseInt(sourceDropId[1]) : -1;
    if (sourceSectionId > -1) {
      //remove item from source section, source.index = source question index
      const [removedItem] = newSurveyS[sourceSectionId].questions.splice(source.index, 1);
      //insert into destination section
      const destDropId = destination.droppableId.split('-');
      const destSectionId = destDropId.length > 1 ? parseInt(destDropId[1]) : -1;
      if (destSectionId > -1) {
        //destination.index = destination question index
        newSurveyS[destSectionId].questions.splice(destination.index, 0, removedItem);
        setSurveyS(newSurveyS);
        //set focus
        if (focusS !== destSectionId) {
          setFocusS(destSectionId);
        }
        setFocusQ(destination.index + 1);
        setFocusEle('');
      }
    }
  };

  /**====================== RENDER ==================================*/
  //render questions
  const SurveyQuestions = (props: any) => {
    const { surveyQ, keyS, totalS } = props;
    //// console.log('surveyQ write', surveyQ);

    const dropSectionId = `section-${keyS}`;
    return (
      <Droppable droppableId={dropSectionId}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className="survey-write-tab-content"
            // style={{
            //   backgroundColor: snapshot.isDraggingOver
            //     ? 'var(--background-hover-color)'
            //     : 'white',
            // }}
          >
            {/* first section: title+desc */}
            <SurveyTitle
              headLineColor={surveyHeadLineColor}
              keyS={keyS}
              totalS={totalS}
              focusQ={focusQ}
              focusS={focusS}
              focusEle={focusEle}
              onRemoveSection={handleRemoveSection}
              onFocusQuestionChange={handleFocusQChange}
              //value
              surveyQ={surveyQ}
              onSurveyChange={(keyName: string, keyValue: any) =>
                handleSurveyChange(keyS, keyName, keyValue)
              }
            />
            {/* render questions */}
            {surveyQ.questions.map((_item: any, index: number) => (
              <Fragment key={index}>
                {_item.type === Q_TITLE || _item.type === Q_IMAGE || _item.type === Q_VIDEO ? (
                  <QuestionMedia
                    keyS={keyS}
                    keyQ={index}
                    focusQ={focusQ}
                    focusS={focusS}
                    focusEle={focusEle}
                    value={_item}
                    onChange={handleQuestionChange}
                    //focus event
                    setFocusEle={setFocusEle}
                    onFocusQuestionChange={handleFocusQChange}
                    //focused - footer - dropdown buttons
                    onRemoveQuestion={handleRemoveQ}
                    onCopyQuestion={handleCopyQ}
                  />
                ) : (
                  <Question
                    keyS={keyS}
                    keyQ={index}
                    focusQ={focusQ}
                    focusS={focusS}
                    focusEle={focusEle}
                    value={_item}
                    onChange={handleQuestionChange}
                    //focus event
                    setFocusEle={setFocusEle}
                    onFocusQuestionChange={handleFocusQChange}
                    //focused - footer - dropdown buttons
                    onRemoveQuestion={handleRemoveQ}
                    onCopyQuestion={handleCopyQ}
                  />
                )}
              </Fragment>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  //Survey sections
  const SurveySections = useMemo(() => {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        {surveyS.map((_section: any, idx: number) => (
          <div key={idx} className="pd-b-30" ref={idx === 0 ? questionRef : null}>
            <SurveyQuestions surveyQ={_section} keyS={idx} totalS={surveyS.length} />
          </div>
        ))}
      </DragDropContext>
    );
  }, [surveyS, focusS, focusQ, focusEle, surveyHeadLineColor]);

  //// console.log('state surveyS', surveyS);
  //main render
  return (
    <div>
      <SurveyToolbar
        focusS={focusS}
        focusQ={focusQ}
        //scrollTop={scrollTop}
        onAddNewQuestion={handleAddNewQuestion}
        onAddNewQuestionMedia={handleAddNewQuestionMedia}
        onAddNewSection={handleAddNewSection}
      />

      {/* header image */}
      {surveyHeaderImg && (
        <div className="survey-write-header-card">
          <img src={surveyHeaderImg} className="survey-write-header-image" />
        </div>
      )}

      {/* sections, capture for section 1 */}
      {SurveySections}
    </div>
  );
};

export default QuestionWrite;
