import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { NoData, SpanLang } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import {
  SETTING_SELECTION_FIELD_UPDATE,
  SETTING_SELECTION_CREATE,
  SETTING_SELECTION_DELETE
} from '@settings/general-setting/services/graphql/format';
import { useSetRecoilState } from 'recoil';
import { savingPreferencesAtom } from '@settings/preferences/recoil/atoms';
import { useTranslation } from 'react-i18next';
import { Button } from '@base/components/form';
import classNames from 'classnames';
import { Check, Plus, X } from 'react-feather';
import LangInput from '@base/containers/lang-input';
import { getCurrentLang } from '@base/services/i18n';
import { useSelectionFields } from '@settings/general-setting/services/format-service';
import Loading from '@base/components/loading';

interface CustomerOptionsProps {
  keyRoot: string;
}

const CustomerOptions = (props: CustomerOptionsProps) => {
  const { keyRoot } = props;
  const { t } = useTranslation();
  //state
  const setSavingOption = useSetRecoilState(savingPreferencesAtom);
  const [isShowWrite, setIsShowWrite] = useState(false);
  const [formData, setFormData] = useState<any>({ langNames: [], isDefault: false });
  const [error, setError] = useState({ name: false });
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [editItem, setEditItem] = useState<any>(null);
  const [keyItems, setKeyItems] = useState<any>({}); //{keyRoot: []}
  const [debItems, setDebItems] = useState<any>([]);
  const setItemsDebounced = useRef(
    _.debounce((newItems) => setDebItems(newItems), 1000),
  ).current;
  const initialLoad = useRef<any>(false);
  const userLang = getCurrentLang();

  const { data: resultPost, isLoading } = useSelectionFields({ filter: { query: `keyRoot=${keyRoot}` } });

  //set items
  useEffect(() => {
    if (resultPost?.data) {
      const newKeyItems = { ...keyItems };
      newKeyItems[keyRoot] = resultPost.data;
      setKeyItems(newKeyItems);
    }
  }, [resultPost]);

  //mutation
  const mUpdate: any = useMutationPost(SETTING_SELECTION_FIELD_UPDATE, 'setting_updateSelection');
  const mCreate: any = useMutationPost(SETTING_SELECTION_CREATE, 'setting_createSelection');
  const mDelete: any = useMutationPost(SETTING_SELECTION_DELETE, 'setting_deleteSelection');

  //set saving option
  useEffect(() => {
    setSavingOption((curOption: any) => ({ ...curOption, isLoading: mUpdate.isLoading }));
  }, [mUpdate.isLoading]);

  //debounced saving for input text
  useEffect(() => {
    if (initialLoad?.current) {
      handleSaveDebounce(debItems);
    } else {
      initialLoad.current = true;
    }
    () => {
      initialLoad.current = false;
    };
  }, [debItems]);

  //create success
  useEffect(() => {
    if (mCreate.isSuccess) {
      //add new item to current items
      const newKeyItems = { ...keyItems };
      const createdItem = { id: mCreate.data.id, ...mCreate.variables.selection };
      createdItem.languageKey = mCreate.variables.selection.languageData[userLang];
      createdItem.isBase = false;
      //reset default
      if (mCreate.variables.selection.isDefault) {
        newKeyItems[keyRoot].map((_ele: any) => _ele.isDefault = false);
      }
      newKeyItems[keyRoot].unshift(createdItem);
      setKeyItems(newKeyItems);
      //reset form
      setFormData({ langNames: [], isDefault: false });
    }
  }, [mCreate.isSuccess]);

  //set saving option
  useEffect(() => {
    if (mUpdate.isSuccess) {
      if (editItem) {
        const newKeyItems = { ...keyItems };
        const fIdx = newKeyItems[keyRoot].findIndex((_ele: any) => _ele.id === editItem.id);
        if (fIdx > -1) {
          newKeyItems[keyRoot][fIdx].languageKey = mUpdate.variables.selection.languageData[userLang];
          setKeyItems(newKeyItems);
        }
        //reset
        setEditItem(null);
      }
    }
  }, [mUpdate.isSuccess]);

  useEffect(() => {
    if (mDelete.isSuccess) {
      const newKeyItems = { ...keyItems };
      const fIdx = newKeyItems[keyRoot].findIndex((_ele: any) => _ele.id === deleteItem.id);
      if (fIdx > -1) {
        newKeyItems[keyRoot].splice(fIdx, 1);
        setKeyItems(newKeyItems);
      }
      setDeleteItem(null);
    }
  }, [mDelete.isSuccess]);

  //save item
  const handleSave = (newItems: any) => {
    const newDefaultItem = newItems.find((_ele: any) => _ele.isDefault === true);
    const params: any = {
      id: newDefaultItem.id,
      isDefault: true,
      keyGroup: newDefaultItem.keyGroup
    };
    mUpdate.mutate({ selection: params });
  };
  //debounce function
  const handleSaveDebounce = useCallback(_.debounce(handleSave, 500), [keyItems]);

  //add new 
  const handleAddNew = () => {
    // validate
    if (formData.langNames.length === 0) {
      setError((error) => ({ ...error, name: true }));
      return;
    }

    let languageValue: any = {};
    for (const name of formData.langNames) {
      languageValue[name.id] = name.value;
    }

    const newParams = {
      keyGroup: 'options_items',
      keyRoot: keyRoot,
      languageData: languageValue,
      isDefault: formData.isDefault
    }
    mCreate.mutate({ selection: newParams });
  }

  //update item - lang
  const handleUpdate = () => {
    const params = {
      id: editItem.id,
      keyGroup: "options_items",
      languageData: editItem.languageData
    }
    mUpdate.mutate({ selection: params });
  }

  //delete an item
  const handleDelete = (item: any) => {
    setDeleteItem(item);
    const params = {
      id: item.id,
      selectType: "options_items"
    }
    mDelete.mutate(params);
  }

  //value change
  const handleValueChange = (index: number, keyName: string, keyValue: string) => {
    const newItems = [...keyItems[keyRoot]];
    newItems[index][keyName] = keyValue;
    if (keyName === 'isDefault') {
      //reset other to false
      newItems.map((_item: any, _index: number) => {
        if (index !== _index) {
          _item.isDefault = false;
        }
      });
    }
    //setItems(newItems);
    setKeyItems((curKeyItems: any) => ({ ...curKeyItems, [keyRoot]: newItems }));
    setItemsDebounced(newItems); //for saving...
  };

  //new form data change
  const handleFormChange = (keyName: string, keyValue: string) => {
    const newFormData = { ...formData };
    newFormData[keyName] = keyValue;
    if (keyName === 'langNames') {
      setError((error) => ({ ...error, name: false }));
    }
    setFormData(newFormData);
  }

  //change lang name for edit item
  const handleEditChange = (langData: any) => {
    const newEditItem = { ...editItem };
    let languageValue: any = {};
    for (const name of langData) {
      languageValue[name.id] = name.value;
    }
    newEditItem.languageData = languageValue;
    setEditItem(newEditItem);
  }

  //edit form
  const renderEditForm = () => {
    const curlangNames: any = [];
    if (editItem.languageData) {
      Object.keys(editItem.languageData).map((_key: string) => {
        curlangNames.push({ id: _key, value: editItem.languageData[_key] });
      })
    }
    return (
      <>
        <LangInput
          uid={keyRoot + editItem.id} //DOM unique key in one page
          disabled={false}
          value={curlangNames} //ex: [{id, value}]
          onChange={(data: any) => handleEditChange(data)}
        />
        <div className='mg-t-5'>
          <Button
            color='light'
            icon="X"
            iconClass='mg-r-5'
            name='Cancel'
            onClick={() => setEditItem(null)}
          />
          <Button
            color='primary'
            className="mg-l-5"
            icon="Check"
            iconClass='mg-r-5'
            name='Save'
            loading={mUpdate.isLoading}
            disabled={mUpdate.isLoading}
            onClick={handleUpdate}
          />
        </div>
      </>
    );
  }

  //list
  const ItemsRender = useMemo(() => {
    const items = keyItems[keyRoot] || [];
    return (
      <>
        {items.length === 0 && <NoData />}
        {items.length > 0 && (
          <div className="table-responsive bd rounded">
            <table className="table table-bordered mg-b-0 bd-0 hover-delete">
              <thead>
                <tr>
                  <th scope="col" className="text-nowrap wd-80p">
                    Name
                  </th>
                  <th scope="col" className="text-nowrap wd-20p">
                    Default
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((_item: any, index: number) => (
                  <tr key={index}>
                    <td className="align-middle">
                      {editItem?.id === _item.id ?
                        renderEditForm()
                        :
                        <SpanLang keyLang={_item.languageKey} />
                      }

                    </td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center">
                        <div className="custom-control custom-radio mg-l-15">
                          <input
                            type="radio"
                            id={keyRoot + '-radio-' + index}
                            name={keyRoot + '-customer-radio'}
                            className="custom-control-input"
                            checked={_item.isDefault}
                            onChange={(e: any) =>
                              handleValueChange(index, 'isDefault', e.target.checked)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={keyRoot + '-radio-' + index}
                          ></label>
                        </div>
                        <div className='mg-l-auto wd-100'>
                          <Button
                            color=""
                            icon="Edit2"
                            iconClass="tx-info"
                            className="btn-icon btn-delete-row han-tooltip--top"
                            data-han-tooltip="Edit"
                            onClick={() => setEditItem(_item)}
                          />
                          {!_item.isBase &&
                            <Button
                              color=""
                              icon="Trash"
                              iconClass="tx-danger"
                              className="btn-icon btn-delete-row han-tooltip--top"
                              data-han-tooltip="삭제"
                              loading={mDelete.isLoading && _item.id === deleteItem?.id}
                              disabled={mDelete.isLoading && _item.id === deleteItem?.id}
                              onClick={() => handleDelete(_item)}
                            />
                          }
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }, [keyItems, editItem, mDelete.isLoading, mUpdate.isLoading]);

  //write form
  const renderWriteForm = () => {
    return (
      <div id={keyRoot + '-add'} className={classNames('collapse fade mg-t-10')}>
        <div className='card'>
          <div className='card-body'>
            <LangInput
              uid={keyRoot} //DOM unique key in one page
              disabled={false}
              value={formData?.langNames || []} //ex: [{id, value}]
              onChange={(data: any) => handleFormChange('langNames', data)}
            />
            {error.name && <p className="tx-danger">Name is required.</p>}
            <div className="d-flex align-items-center mg-t-10">
              <span>Default</span>
              <div className="custom-control custom-radio mg-l-15">
                <input
                  type="radio"
                  id={keyRoot + '-radio-add-1'}
                  name={keyRoot + 'customer-radio-add'}
                  className="custom-control-input"
                  checked={formData?.isDefault}
                  onChange={(e: any) => handleFormChange('isDefault', e.target.checked)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={keyRoot + '-radio-add-1'}
                ></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //add footer
  const renderAddButtons = () => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary mg-t-10"
          data-toggle="collapse"
          data-target={'#' + keyRoot + '-add'}
          aria-expanded="false"
          onClick={() => {
            setIsShowWrite(!isShowWrite);
            setFormData({ langNames: [], isDefault: false });
          }}
        >
          {isShowWrite ? <><X className='mg-r-5' /> Cancel</> : <><Plus className='mg-r-5' /> Add</>}
        </button>
        {isShowWrite &&
          <Button
            color='success'
            className="mg-t-10 mg-l-5"
            icon="Check"
            iconClass='mg-r-5'
            name='Save'
            loading={mCreate.isLoading}
            disabled={mCreate.isLoading}
            onClick={handleAddNew}
          />
        }
      </>
    );
  }

  return (
    <div className="pos-relative pd-10">
      <div className='pd-b-20 scroll-box' style={{ maxHeight: "calc(100vh - 180px)" }}>
        {isLoading && <Loading />}
        {ItemsRender}
        {renderWriteForm()}
        {renderAddButtons()}
      </div>
    </div>
  );
};

export default CustomerOptions;
