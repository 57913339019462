import { SpanLang } from '@base/components/form';
import { AR_CRITERIA_OPTIONS_LANG } from '@settings/assignment-rule/config/constant';
import React, { useState } from 'react';

interface ICriteriaViewProps {
  value: any;
}
const CriteriaView: React.FC<ICriteriaViewProps> = (props: ICriteriaViewProps) => {
  const { value } = props;
  const defaultItems: any[] = [
    {
      key: 'product',
      condition: '',
    },
    {
      key: 'region',
      condition: '',
    },
    {
      key: 'language',
      condition: '',
    },
    {
      key: 'category',
      condition: '',
    },
    {
      key: 'tag',
      condition: '',
    },
  ];
  const [items, setItems] = useState<any[]>(value.length ? value : defaultItems);
  return (
    <>
      <ul className="list-group wd-100p">
        {items.length
          ? items.map((item, index) => {
              return (
                <li
                  key={index}
                  className="list-group-item d-flex align-items-center justify-content-between pd-x-10 bg-white "
                >
                  <SpanLang keyLang={AR_CRITERIA_OPTIONS_LANG[item.key]} />
                  <span className="ht-38 wd-250">{item.condition}</span>
                </li>
              );
            })
          : ''}
      </ul>
    </>
  );
};

export default CriteriaView;
