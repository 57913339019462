import { FormIcon, Tooltip } from '@base/components/form';

import { PREMISSION_CONFIG } from '@settings/page-layout/config/common';
import { EPermissionType } from '@settings/page-layout/types/enums';
import React from 'react';
import './styles.scss';
interface IHelpTypeFieldProps {}
const HelpTypeField: React.FC<IHelpTypeFieldProps> = (props: IHelpTypeFieldProps) => {
  return (
    <div className="navata-help-type-field">
      <Tooltip
        overlay={
          <div className="navata-help-type-field">
            {Object.keys(PREMISSION_CONFIG).map((key, index) => {
              const type = PREMISSION_CONFIG[key as EPermissionType];
              return (
                <div className="d-flex align-items-center pd-5" key={index}>
                  <div className={'dot-type'} style={{ backgroundColor: type.color }} />
                  <span>{type.label}</span>
                </div>
              );
            })}
          </div>
        }
      >
        <i className="mg-x-5">
          <FormIcon icon="HelpCircle" iconType="feather" size={18} />
        </i>
      </Tooltip>
    </div>
  );
};

export default HelpTypeField;
