import { useSiteCtaAtomState, useStepSiteAtomState } from '@settings/process/recoil/process';
import { useEffect } from 'react';
import _ from 'lodash';
import { SITE_GROUP_KEY } from '@settings/site/config/constants';
import { SUB_MENUS } from '@settings/site/config/sub-menus';
import { Site, StepSiteForm } from '@settings/process/types/process';
import { categoryOptions, initContentOption, initTypeOption, samplePage } from '.';
import { getCtaFromHTML } from '@settings/process/utils/site';

interface SiteViewProps {
  value: Site;
}

// If site has multiple forward, It must be parallel.
// parallel : join, and join
// If parallel is join, It must be Wait step as next step.
// If parallel is and join, It can be anything.
function SiteView({ value }: SiteViewProps) {
  const [stepSite, setStepSite] = useStepSiteAtomState();
  const [, setSiteCta] = useSiteCtaAtomState();

  useEffect(() => {
    let stepSiteForm: StepSiteForm = {
      template: initContentOption,
      category: categoryOptions[0],
      type: initTypeOption,
      html: '',
    };

    if (value.type) {
      const groupKey = SITE_GROUP_KEY[value.type];
      const groupIndex = SUB_MENUS.findIndex((menu) => menu.keyName == groupKey);
      stepSiteForm.type = {
        keyName: groupKey,
        languageKey: SUB_MENUS[groupIndex]?.languageKey,
      };
    }

    if (value.category) {
      const optIndex = categoryOptions.findIndex((opt) => opt.value == value.category);
      stepSiteForm.category = {
        value: value.category,
        label: categoryOptions[optIndex].label,
      };
    }

    if (value.template) {
      stepSiteForm.template = {
        keyName: value.template.id,
        languageKey: value.template.name,
      };
    }

    setStepSite(stepSiteForm);
    setSiteCta(getCtaFromHTML(samplePage));
  }, [value]);

  return (
    <div className="form-row">
      <div className="form-group col-6">
        <label className="form-item-title">LandingPage/Site</label>
        <div>{stepSite.category.label}</div>
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Site Type</label>
        <div>{stepSite.type.languageKey}</div>
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Site Content</label>
        <div>{stepSite.template.languageKey}</div>
      </div>
    </div>
  );
}

export default SiteView;
