import React from 'react';
import CommonViewField from '@base/containers/view-field/common';
import View from './MethodView';
import Write from './MethodWrite';
import { LabelValue } from '@base/types/types/app';

export const MethodOptions: LabelValue[] = [
  {
    label: 'Manual',
    value: 'ACTION_METHOD_MANUAL',
  },
  {
    label: 'Automatic',
    value: 'ACTION_METHOD_AUTO',
  },
];

function MethodViewField(props: any) {
  return (
    <CommonViewField {...props} componentView={View} componentEdit={Write} value={props.value} />
  );
}

export default MethodViewField;
