import { generateUUID } from '@base/utils/helpers';
import { IBillingPlan } from '../types/interfaces/billing-plan';
import { IContact } from '../types/interfaces/contact';
import { IInvoiceIssued } from '../types/interfaces/issued';
import { ILicense } from '../types/interfaces/license';
import { IOrder } from '../types/interfaces/order';
import { IUsageHistory } from '../types/interfaces/usage-history';

export function contactFakeData(): any {
  const NUM = 5;
  let results: IContact[] = [];
  for (let i = 1; i <= NUM; i++) {
    let contact: IContact = {
      id: generateUUID(),
      name: `Contact ${i}`,
      role: 'string',
      email: 'string@gmail.com',
      phone: '0123123123',
      mobile: '0123123123',
    };
    results.push(contact);
  }
  return {
    data: results,
    paging: {
      totalPage: 1,
      totalItems: 5,
      currentPage: 1,
      itemPerPage: 5,
    },
  };
}

export function billingPlanFakeData(): any {
  const NUM = 5;
  let results: IBillingPlan[] = [];
  for (let i = 1; i < NUM; i++) {
    let plan: IBillingPlan = {
      id: generateUUID(),
      name: `Plan ${i}`,
      billingCycle: {
        value: 'six-month',
        label: '6 Monthly',
      },
      isRenewAuto: true,
      startDate: new Date('2022/07/11'),
      endDate: new Date('06/27/2021'),
      invoiceDate: new Date('01/27/2021'),
      billingDate: new Date('01/27/2021'),
    };
    results.push(plan);
  }
  return {
    data: results,
    paging: {
      totalPage: 1,
      totalItems: 5,
      currentPage: 1,
      itemPerPage: 5,
    },
  };
}

export function fakeLicense(): any {
  let results: ILicense[] = [
    {
      id: generateUUID(),
      name: 'CRM',
      listItem: [
        {
          id: generateUUID(),
          name: 'CRM 10 users',
          description: '',
          quantity: 10,
          unitPrice: 'Free',
          unit: 'User',
          amount: '',
          billing: '',
          action: 'Terminate',
        },
        {
          id: generateUUID(),
          name: 'CRM Add a user',
          description: '',
          quantity: 5,
          unitPrice: '3000',
          unit: 'User',
          amount: '15000',
          billing: 'Monthly',
          action: 'Reduce',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Online Storage',
      listItem: [
        {
          id: generateUUID(),
          name: '10G Storage',
          description: '',
          quantity: 1,
          unitPrice: '10000',
          unit: '10G',
          amount: '10000',
          billing: 'Monthly',
          action: 'Terminate',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'HR',
      listItem: [
        {
          id: generateUUID(),
          name: 'HR 10 users',
          description: '',
          quantity: 10,
          unitPrice: 'Free',
          unit: 'User',
          amount: '',
          billing: '',
          action: 'Terminate',
        },
      ],
    },
  ];
  return {
    data: results,
    paging: {
      totalPage: 1,
      totalItems: 5,
      currentPage: 1,
      itemPerPage: 5,
    },
  };
}

export function fakeLicensingGuide(): any {
  let results: ILicense[] = [
    {
      id: generateUUID(),
      name: 'CRM',
      listItem: [
        {
          id: generateUUID(),
          name: 'CRM 10 users',
          description: '',
          unitPrice: 'Free',
          unit: 'User',
          billing: '',
          action: 'addUserPurchasing',
        },
        {
          id: generateUUID(),

          name: 'CRM Add a user',
          description: '',
          unitPrice: 3000,
          unit: 'User',
          billing: 'Monthly',
          action: 'addUserPurchasing',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'CRM Call',
      listItem: [
        {
          id: generateUUID(),

          name: 'Call Setting',
          description: '',
          unitPrice: 180000,
          unit: 'Set',
          billing: 'One time',
          action: 'licensePurchasing',
        },
        {
          id: generateUUID(),

          name: 'Call Add a user',
          description: '',
          unitPrice: '',
          unit: 'User',
          billing: 'Monthly',
          action: 'addUserPurchasing',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'HR',
      listItem: [
        {
          id: generateUUID(),

          name: 'HR 10 users',
          description: '',
          unitPrice: 'Free',
          unit: '',
          billing: '',
          action: 'addUserPurchasing',
        },
        {
          id: generateUUID(),

          name: 'HR Add a user',
          description: '',
          unitPrice: '',
          unit: '',
          billing: 'Monthly',
          action: 'addUserPurchasing',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Online Storage',
      listItem: [
        {
          id: generateUUID(),

          name: '1G Storage',
          description: '',
          unitPrice: 'Free',
          unit: '',
          billing: '',
          action: 'addStoragePurchasing',
        },
        {
          id: generateUUID(),

          name: '10G Storage',
          description: '',
          unitPrice: 10000,
          unit: '10G',
          billing: 'Monthly',
          action: 'addStoragePurchasing',
        },
        {
          id: generateUUID(),

          name: '30G Storage',
          description: '',
          unitPrice: 20000,
          unit: '30G',
          billing: 'Monthly',
          action: 'addStoragePurchasing',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Email',
      listItem: [
        {
          id: generateUUID(),

          name: 'Email',
          description: '',
          unitPrice: 1000,
          unit: '10G',
          billing: 'Monthly',
          action: 'addStoragePurchasing',
        },
        {
          id: generateUUID(),

          name: 'Sending Email',
          description: '',
          unitPrice: 5000,
          unit: '1000 Messages',
          billing: 'Monthly',
          action: 'addStoragePurchasing',
        },
        {
          id: generateUUID(),

          name: 'Recharge sending Email',
          description: '',
          unitPrice: 0.5,
          unit: '',
          billing: 'Recharge',
          action: 'licensePurchasing',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Project',
      listItem: [
        {
          id: generateUUID(),

          name: 'Project 10 users',
          description: '',
          unitPrice: 'Free',
          unit: '',
          billing: '',
          action: 'addStoragePurchasing',
        },
        {
          id: generateUUID(),

          name: 'Project Add a user',
          description: '',
          unitPrice: '',
          unit: '',
          billing: 'Monthly',
          action: 'addUserPurchasing',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Team Channel',
      listItem: [
        {
          id: generateUUID(),

          name: 'Team Channel 10 users',
          description: '',
          unitPrice: 'Free',
          unit: '',
          billing: '',
          action: 'licensePurchasing',
        },
        {
          id: generateUUID(),

          name: 'Team Channel Add a user',
          description: '',
          unitPrice: '',
          unit: '',
          billing: 'Monthly',
          action: 'addUserPurchasing',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'SMS',
      listItem: [
        {
          id: generateUUID(),

          name: 'SMS',
          description: '',
          unitPrice: '',
          unit: 'Message',
          billing: 'Recharge',
          action: 'licensePurchasing',
        },
        {
          id: generateUUID(),

          name: 'LMS',
          description: '',
          unitPrice: '',
          unit: 'Message',
          billing: 'Recharge',
          action: 'licensePurchasing',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Digital Tax Invoice',
      listItem: [
        {
          id: generateUUID(),

          name: null,
          description: '',
          unitPrice: 150,
          unit: '',
          billing: 'Recharge',
          action: 'licensePurchasing',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Virtual Billing Account',
      listItem: [
        {
          id: generateUUID(),

          name: null,
          description: '',
          unitPrice: '',
          unit: 'Billing Account',
          billing: 'Recharge',
          action: 'licensePurchasing',
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Fax',
      listItem: [
        {
          id: generateUUID(),

          name: '',
          description: '',
          unitPrice: '',
          unit: 'Fax number',
          billing: 'Monthly',
          action: 'addStoragePurchasing',
        },
        {
          id: generateUUID(),

          name: '',
          description: '',
          unitPrice: '',
          unit: 'Document',
          billing: 'Recharge',
          action: 'licensePurchasing',
        },
      ],
    },
  ];
  return {
    data: results,
    paging: {
      totalPage: 1,
      totalItems: 5,
      currentPage: 1,
      itemPerPage: 5,
    },
  };
}

export function bnpFakeOrder(): any {
  let results: IOrder[] = [
    {
      id: generateUUID(),
      name: 'CRM 10 users',
      quantity: 10,
      unitPrice: 'Free',
      unit: 'User',
    },
    {
      id: generateUUID(),
      name: '10G Storage',
      quantity: 10,
      unitPrice: 10000,
      unit: '10G',
    },
  ];
  return {
    data: results,
    paging: {
      totalPage: 1,
      totalItems: 5,
      currentPage: 1,
      itemPerPage: 5,
    },
  };
}

export function bnpFakeInvoiceIssued(): any {
  let results: IInvoiceIssued[] = [
    {
      id: generateUUID(),
      name: 'CRM 10 users',
      date: new Date('2022-05-04'),
      amount: 10,
      payment: 'Credit Card',
    },
    {
      id: generateUUID(),
      name: '10G Storage',
      date: new Date('2022-05-04'),
      amount: 10,
      payment: 'Credit Card',
    },
  ];
  return {
    data: results,
    paging: {
      totalPage: 1,
      totalItems: 5,
      currentPage: 1,
      itemPerPage: 5,
    },
  };
}

export function historyUsageFakeData(): any {
  let results: IUsageHistory[] = [
    {
      id: generateUUID(),
      name: 'SMS Usage',
      data: [
        {
          date: new Date('2022-05-04'),
          userName: 'asd',
          subjectName: 'asd',
          amount: 10,
        },
        {
          date: new Date('2022-05-04'),
          userName: 'asd 2',
          subjectName: 'asd 2',
          amount: 10,
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Mail Sending Usage',
      data: [
        {
          date: new Date('2022-05-04'),
          userName: 'asd',
          subjectName: 'asd',
          amount: 10,
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Fax Usage',
      data: [
        {
          date: new Date('2022-05-04'),
          userName: 'asd',
          subjectName: 'asd',
          amount: 10,
        },
      ],
    },
    {
      id: generateUUID(),
      name: 'Virtual Billing Account Usage',
      data: [],
    },
    {
      id: generateUUID(),
      name: 'Digital Tax Invoice Usage',
      data: [
        {
          date: new Date('2022-05-04'),
          userName: 'asd',
          subjectName: 'asd',
          amount: 10,
        },
        {
          date: new Date('2022-05-04'),
          userName: 'asd 2',
          subjectName: 'asd 2',
          amount: 10,
        },
      ],
    },
  ];
  return {
    data: results,
    paging: {
      totalPage: 1,
      totalItems: 5,
      currentPage: 1,
      itemPerPage: 5,
    },
  };
}
