import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icons from '@base/assets/icons/svg-icons';
import { Image, X, AlertTriangle, Trash } from 'react-feather';
import {
  Q_MULTI_CHOICES,
  Q_CHECKBOXES,
  Q_DROPDOWN,
  Q_SHORT_ANSWER,
  Q_PARAGRAPH,
  Q_FILE_UPLOAD,
  Q_DATE,
  Q_TIME,
  Q_MULTI_CHOICES_GRID,
  Q_TICK_BOX_GRID,
} from '@settings/online-digital-content/survey/config/constants';
import { SpanLang } from '@base/components';
import QuestionIcon from '../../symbol';
import { Switch } from '@base/components/form';
import _, { cloneDeep } from 'lodash';

//render question options
const OptionNormal: React.FC<any> = (props) => {
  const {
    focusS,
    focusQ,
    keyS,
    keyQ,
    focusEle,
    type = '',
    optionsQ = [],
    isValid,
    imageOptRefs,
    onOptionValueChange,
    onOptionSelectChange,
    onOpenUpload,
    onImageOptChange,
    onRemoveOptImage,
    onRemoveOption,
    onAddOption,
    onAddOtherOption,
  } = props;
  const { t } = useTranslation();
  //state
  const [locOptions, setLocOptions] = useState<any[]>(cloneDeep(optionsQ));
  const setOptionDebounced = useRef(
    _.debounce((text, idx) => onOptionValueChange(text, idx), 100),
  ).current;
  //let initialRef = useRef<any>(null); //for only first Run
  const isExist =
    type !== Q_MULTI_CHOICES_GRID &&
    type !== Q_TICK_BOX_GRID &&
    optionsQ.filter((_item: any) => _item.isOther).length > 0
      ? true
      : false;

  /** ================================== HANDLER ========================================== */
  //monitor for open file dialog
  // useEffect(() => {
  //   if (focusEle) {
  //     //for option image: q-optimg-0-1-3
  //     const items = focusEle.split('-');
  //     const nameEle = items[0] + '-' + items[1];
  //     if (nameEle === 'q-optimg') {
  //       const idx = parseInt(items[4]) - 1;
  //       if (!initialRef.current) {
  //         onOpenUpload(idx);
  //         initialRef = imageOptRefs[idx];
  //       }
  //     }
  //   }
  // }, []); //focusEle

  //local option changes
  const handleLocOptionChange = (text: string, index: number) => {
    const newOptions = [...locOptions];
    locOptions[index].value = text;
    setLocOptions(newOptions);
  };

  /** ================================== RENDER ========================================== */
  //render options
  const renderOption = (opt: any, idx: number) => {
    //const optKey = 'optfile' + (keyQ + 1).toString() + (idx+1).toString();
    const qOptionInputEleId = 'q-optinput-' + keyS + '-' + (keyQ + 1) + '-' + (idx + 1);
    const qOptionImgEleId = 'q-optimg-' + keyS + '-' + (keyQ + 1) + '-' + (idx + 1);
    const qOptionRemoveEleId = 'q-optremove-' + keyS + '-' + (keyQ + 1) + '-' + (idx + 1);

    if (type === Q_MULTI_CHOICES || type === Q_CHECKBOXES || type === Q_DROPDOWN) {
      return (
        <div key={idx} className="survey-write-question-option-container">
          <div className="survey-write-question-card-list-item">
            <div className="survey-write-question-card-list-item-text">
              <QuestionIcon indexNo={idx + 1} type={type} />
              {opt.isOther ? (
                <span className="form-control pd-l-15">{opt.value}</span>
              ) : (
                <input
                  autoComplete="off"
                  type="text"
                  id={qOptionInputEleId}
                  className="form-control"
                  placeholder={t('Option') + ' ' + (idx + 1)}
                  value={opt.value}
                  onChange={(e: any) => {
                    handleLocOptionChange(e.target.value, idx);
                    setOptionDebounced(e.target.value, idx);
                  }} //save on state
                  autoFocus={qOptionInputEleId === focusEle}
                />
              )}
              {/* duplicated option name */}
              {focusS === keyS &&
                focusQ === keyQ + 1 &&
                isValid &&
                isValid.id === idx &&
                !isValid.value && (
                  <div
                    className="pd-r-10"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Duplicated option"
                  >
                    <AlertTriangle className="tx-danger" />
                  </div>
                )}
            </div>
            {/* upload image and remove option */}
            {focusS === keyS && focusQ === keyQ + 1 && (
              <div className="survey-write-question-card-list-item-action">
                {/* Option Image */}
                {/* <a
                  id={qOptionImgEleId}
                  className="survey-home-header-button mg-r-10 cursor-default"
                  onClick={(e: any) => onOpenUpload(idx)}
                >
                  <Image />
                  <input
                    type="file"
                    accept="image/*"
                    //id={qOptionImgEleId}
                    //name={qOptionImgEleId}
                    ref={imageOptRefs[idx]}
                    style={{ display: 'none' }}
                    onChange={(e) => onImageOptChange(e, idx)}
                  />
                </a> */}
                {/* Option Remove */}
                <a
                  id={qOptionRemoveEleId}
                  className="survey-home-header-button cursor-default"
                  onClick={() => onRemoveOption(idx)}
                >
                  <X />
                </a>
              </div>
            )}
          </div>
          {/* image */}
          {opt?.image && opt?.image?.url instanceof File && (
            <div className="survey-write-question-card-image">
              <div className="survey-write-question-card-image-pos">
                {/* custom image menu */}
                {focusS === keyS && focusQ === keyQ + 1 && (
                  <div className="survey-write-question-card-image-menu">
                    <div className="survey-home-grid-item-overflow">
                      <a
                        className="btn survey-home-btn-more cursor-default"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {Icons('more_vertical')}
                      </a>
                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item cursor-default"
                          onClick={() => onRemoveOptImage(idx)}
                        >
                          <Trash /> <SpanLang keyLang="Remove" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                <img
                  src={URL.createObjectURL(opt.image.url)}
                  className="survey-write-question-card-image-view"
                  alt="opt_image"
                  style={{ width: '620px' }}
                />
              </div>
            </div>
          )}
        </div>
      );
    }

    if (type === Q_SHORT_ANSWER) {
      return (
        <div key={idx} className="survey-write-question-card-list-item">
          <div className="survey-write-question-card-title-name">
            <input
              autoComplete="off"
              type="text"
              id={qOptionInputEleId}
              className="form-control survey-write-input mg-b-15 w-50"
              placeholder={t('Short answer')}
              autoFocus={qOptionInputEleId === focusEle}
              disabled
            />
          </div>
        </div>
      );
    }

    if (type === Q_PARAGRAPH) {
      return (
        <div key={idx} className="survey-write-question-card-list-item">
          <div className="survey-write-question-card-title-name">
            <textarea
              id={qOptionInputEleId}
              className="form-control survey-write-input mg-b-15 mg-r-50 w-100"
              rows={2}
              placeholder={t('Long answer')}
              autoFocus={qOptionInputEleId === focusEle}
              disabled
            />
          </div>
        </div>
      );
    }

    if (type === Q_FILE_UPLOAD) {
      const qOptionTypeEleId = 'q-optfiletype-' + keyS + '-' + (keyQ + 1) + '-' + (idx + 1);
      const qOptionNumberEleId = 'q-optfilenum-' + keyS + '-' + (keyQ + 1) + '-' + (idx + 1);
      const qOptionSizeEleId = 'q-optfilesize-' + keyS + '-' + (keyQ + 1) + '-' + (idx + 1);
      if (idx == 0) {
        return (
          <div key={idx} className="survey-write-question-card-list-item pd-t-20">
            <div className="survey-write-question-card-list-item-wrapper">
              <span className="tx-16">
                <SpanLang keyLang={'Allow file type'} />
              </span>
            </div>
            <div id={qOptionTypeEleId} className="survey-write-question-card-list-control-wrapper">
              <Switch
                value={opt.value}
                onChange={(e: any) => onOptionValueChange(e.target.checked, idx)}
              />
            </div>
          </div>
        );
      } else if (idx == 1) {
        return (
          <div key={idx} className="survey-write-question-card-list-item pd-t-20">
            <div className="survey-write-question-card-list-item-wrapper">
              <span className="tx-16">
                <SpanLang keyLang={'Max files'} />
              </span>
            </div>
            <div className="survey-write-question-card-list-control-wrapper">
              <a
                id={qOptionNumberEleId}
                className="btn pd-0 dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={focusEle === qOptionNumberEleId ? true : false}
              >
                <span className="survey-home-floater-header-owner-title">{opt.value}</span>
              </a>
              <ul className="dropdown-menu">
                <a className="dropdown-item" onClick={() => onOptionSelectChange(1, idx)}>
                  1
                </a>
                <a className="dropdown-item" onClick={() => onOptionSelectChange(5, idx)}>
                  5
                </a>
                <a className="dropdown-item" onClick={() => onOptionSelectChange(10, idx)}>
                  10
                </a>
              </ul>
            </div>
          </div>
        );
      } else {
        return (
          <div key={idx} className="survey-write-question-card-list-item pd-t-20">
            <div className="survey-write-question-card-list-item-wrapper">
              <span className="tx-16">
                <SpanLang keyLang={'Max file size'} />
              </span>
            </div>
            <div className="survey-write-question-card-list-control-wrapper">
              <a
                id={qOptionSizeEleId}
                className="btn pd-0 dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={focusEle === qOptionSizeEleId ? true : false}
              >
                <span className="survey-home-floater-header-owner-title">{opt.value} MB</span>
              </a>
              <ul className="dropdown-menu">
                <a className="dropdown-item" onClick={() => onOptionSelectChange(1, idx)}>
                  1 MB
                </a>
                <a className="dropdown-item" onClick={() => onOptionSelectChange(5, idx)}>
                  5 MB
                </a>
                <a className="dropdown-item" onClick={() => onOptionSelectChange(10, idx)}>
                  10 MB
                </a>
              </ul>
            </div>
          </div>
        );
      }
    }

    if (type === Q_DATE || type === Q_TIME) {
      const placeHolder = type === Q_DATE ? t('Date month year') : t('Time');
      return (
        <div key={idx} className="survey-write-question-card-list-item">
          <div className="survey-write-question-card-title-name align-items-center pd-t-10">
            <input
              autoComplete="off"
              type="text"
              id={qOptionInputEleId}
              className="form-control survey-write-input w-50"
              placeholder={placeHolder}
              autoFocus={qOptionInputEleId === focusEle}
              disabled
            />
            <QuestionIcon type={type} />
          </div>
        </div>
      );
    }
  };

  //render question add buttons
  const renderAddOption = () => {
    const qAddOptEleId = 'q-addopt-' + keyS + '-' + (keyQ + 1);
    const qAddOtherOptEleId = 'q-addotheropt-' + keyS + '-' + (keyQ + 1);

    if (type === Q_MULTI_CHOICES || type === Q_CHECKBOXES || type === Q_DROPDOWN) {
      return (
        <div className="survey-write-question-card-list-item">
          <div className="survey-write-question-card-list-item-text">
            <div className="survey-write-question-card-list-item-inline pd-t-5">
              <QuestionIcon indexNo={optionsQ.length + 1} type={type} />
              <a
                id={qAddOptEleId}
                onClick={onAddOption}
                className="survey-write-question-card-list-item-link pd-l-15"
              >
                <SpanLang keyLang={'Add option'} />
              </a>
              {(type === Q_MULTI_CHOICES || type === Q_CHECKBOXES) && !isExist && (
                <div className="survey-write-question-link-other">
                  <span className="pd-l-10">
                    <SpanLang keyLang={'or'} />
                  </span>
                  <a id={qAddOtherOptEleId} className="pd-l-10" onClick={onAddOtherOption}>
                    <SpanLang keyLang={'Add other'} />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return '';
    }
  };

  //render
  return (
    <div className="survey-write-question-card-list pd-t-10">
      {locOptions.map((opt: any, idx: number) => renderOption(opt, idx))}
      {/* add option button */}
      {focusS === keyS && focusQ === keyQ + 1 && renderAddOption()}
    </div>
  );
};

export default OptionNormal;
