import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { SpanLang } from '@base/components';
import { deviceAtom } from '@base/recoil/atoms';
import { ListBody, ListContainer } from '@base/components/list';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';

import displayLayoutConfig from '@settings/process/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import ListHeader from '@settings/process/components/list-header/ListHeader';
import { SettingListContainer, SettingTabContainer } from '@settings/process/containers/list';
import { SUBTABS, TABPROPS } from '@settings/process/containers/list/Tabs';
import WritePage from '@settings/process/pages/write/WritePage'; //for write
import { DefinedItem } from '@settings/process/types/process';
import { DELETE_DEFINED_ITEM, GET_DEFINED_ITEMS } from '@settings/process/services/custom';
import { ActionProperty, SettingWrite, SettingType } from '@settings/process/types/settings';
import { useGetDefinedItems } from '@settings/process/hooks/useProcess';
import statusAtom from '@settings/process/recoil/status';

interface SettingListPageProps {}

function SettingListPage(props: SettingListPageProps) {
  const [{ isDesktop, isMobile, isTablet, device, layout = 'layout1' }] =
    useRecoilState(deviceAtom);
  const location = useLocation();
  const [fullScreen, setFullScreen] = useState(false); //for write page
  const [writeOption, setWriteOption] = useState<SettingWrite>({}); //for write page
  const setStatusesValue = useSetRecoilState(statusAtom);
  const [selectedTab, setSelecteTab] = useState<SettingType>('action');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const type = (_.trim(location.hash, '#') as SettingType) || 'action';
    setSelecteTab(type);
  }, [location.hash]);

  const definedItemType = useMemo(() => {
    return SUBTABS.find((tab) => tab.value == selectedTab);
  }, [selectedTab]);

  const { data: definedItems, refetch: reloadList } = useGetDefinedItems(
    GET_DEFINED_ITEMS,
    definedItemType?.type,
    selectedTab,
    pageNumber,
  );

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_DEFINED_ITEM,
    'process_deleteDefinedItem',
    {
      onSuccess: (data: any) => {
        setTimeout(() => {
          reloadList && reloadList();
        }, 500);
        // console.log('success data', data);
        toast.success('Delete action successfully!');
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Delete action failed');
      },
    },
  );

  const editFn = useMemo(
    () => (data: DefinedItem) => {
      setWriteOption({
        writeComponent: definedItemType?.component,
        isOpenWrite: true,
        writeTitle: definedItemType?.label,
        writeData: data,
      });
    },
    [definedItemType],
  );

  const deleteFn = useMemo(
    () => (id: string) => {
      if (confirm('Would you like to delete?')) {
        mutationDelete.mutate({ id: id });
      }
    },
    [selectedTab],
  );

  const tabProps = TABPROPS(editFn, deleteFn)[selectedTab];
  const pagingProps = {
    totalPage: 1,
    totalItems: 0,
    currentPage: 1,
    itemPerPage: 10,
    nextPage: null,
    previousPage: null,
    onChange: (page: number, size: number) => {
      if (pageSize !== size) {
        setPageSize(size);
      }
      setPageNumber(page);
    },
  };

  const onSelectMenu = useCallback((selected: ActionProperty) => {
    setSelecteTab(selected.value);
    setWriteOption({
      writeComponent: selected.component,
      isOpenWrite: true,
      writeTitle: selected.label,
    });
  }, []);

  const pageProps = useMemo(
    () => ({
      toolbar: '',
      header: (
        <ListHeader
          title="Steps/Trigger/Attribute"
          actions={SUBTABS}
          onSelect={onSelectMenu}
          selected={definedItemType}
        />
      ),
      body: (
        <ListBody>
          <SettingTabContainer selectedTab={selectedTab} />
          <SettingListContainer
            data={definedItems?.results ?? []}
            fields={tabProps.fields}
            columnRenderRemap={tabProps.columnRenderRemap}
            pagingProps={pagingProps}
          />
        </ListBody>
      ),
    }),
    [selectedTab, definedItems],
  );

  return (
    <>
      <ListContainer isDesktop={isDesktop}>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          {...pageProps}
        />
      </ListContainer>

      {writeOption?.isOpenWrite && (
        <WritePage
          title={<SpanLang keyLang={writeOption.writeTitle} />}
          size={selectedTab == 'trigger' ? 'md' : selectedTab == 'criteria' ? 'lg' : 'xl'}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          component={'writeSetting'}
          onReload={reloadList}
          definedItem={writeOption.writeData}
          settingType={selectedTab}
          onClose={() => {
            setStatusesValue([]);
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }));
          }}
        />
      )}
    </>
  );
}

export default SettingListPage;
