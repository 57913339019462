import usePost from '@base/hooks/usePost';
import usePosts from '@base/hooks/usePosts';
import { FilterInput } from '@base/types/interfaces/common';
import { keyStringify } from '@base/utils/helpers';
import { stringify } from 'query-string';
import { IAssignMentRule } from '../types/interfaces';
import { getListSample } from '../utils/fake-data';
import { GET_ALL_ASSIGNMENT_RULE, GET_ASSIGNMENT_RULE } from './graphql';

export const useAssignmentRulesList = (filter: FilterInput) => {
  let queryKey = ['setting_assignmentRules', keyStringify(filter, '')];
  let params = {
    filter: filter,
  };
  const response = usePosts<IAssignMentRule[]>(queryKey, GET_ALL_ASSIGNMENT_RULE, params, {
    // initialData: getListSample(),
  });
  return response;
};

export const useAssignmentRule = (id: String) => {
  let queryKey = ['setting_assignmentRule', id];
  let params = {
    id,
  };
  const response = usePost<IAssignMentRule>(queryKey, GET_ASSIGNMENT_RULE, params, {
    // initialData: getListSample(),
  });
  return response;
};
