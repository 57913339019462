import { RadioGroup } from '@base/components/form';
import { IAREntryAssignToItem, IAssignToName } from '@settings/assignment-rule/types/interfaces';
import React, { useState } from 'react';
import UserAutoComplete from '@base/containers/user-auto-complete';
import UserGroupSelect from '@base/containers/user-group-select';
import Queue from './queue';
import CheckOption from './check-option';
import { EAREntryAssignToMode, EAREntryAssignToType } from '@settings/assignment-rule/types/enums';
import { Button, Checkbox, Input, Select } from '@base/components/form';

interface IAssingToProps {
  value: IAREntryAssignToItem;
  onChange: (nVal: any) => void;
  menuSourceId?: string;
}

const AssignTo: React.FC<IAssingToProps> = (props: IAssingToProps) => {
  let { menuSourceId = '', value, onChange } = props;

  const initWorkDay: boolean = value?.baseOnWorkDay;
  const [baseOnWorkDay, setBaseOnWorkDay] = useState<boolean>(initWorkDay);

  const initOnline: boolean = value?.online;
  const [online, setOnline] = useState(initOnline);
  const assignToDataInit: IAREntryAssignToItem = {
    id: '',
    mode: EAREntryAssignToMode.USERS,
    type: EAREntryAssignToType.ROUND_ROBIN,
    baseOnWorkDay,
    online,
    capacity: 30,
    assignsTo: [
      /*{
        user: {
          id: '1',
          name: 'User 1',
        },
        group: {
          id: '1',
          name: 'Group 1',
        },
      },*/
    ],
  };
  const groupOptions = [
    { value: 1, label: 'Users' },
    { value: 2, label: 'Groups' },
  ];
  const [groupOption, setGroupOption] = useState(groupOptions[0]);
  const [assign, setAssign] = useState(EAREntryAssignToMode.USERS);
  let handleInit = { ...assignToDataInit };
  if (value?.assignsTo) {
    let assignsTo = value.assignsTo.map((v: any) => {
      return { ...v.user };
    });

    handleInit.assignsTo = assignsTo;
    console.log('>>>>> handleInit', handleInit);
  }
  const [assignToData, setAssignToData] = useState(handleInit);
  console.log('>>>>> assignToData', assignToData);
  const handleOnchange = (e: any) => {
    const { value } = e.target;
    setAssign(value);
    let assignToDataTmp = { ...assignToData };
    assignToDataTmp.mode = value;
    setAssignToData(assignToDataTmp);
    // console.log('>>>>>>>>>>> assignToDataTmp', assignToDataTmp);
    onChange && onChange(assignToDataTmp);
  };

  const handleOnchangeUser = (value: any) => {
    const assignsTo: IAssignToName[] = [{ user: { id: value.id, name: value.name } }];
    let assignToDataTmp = { ...assignToData, assignsTo: assignsTo };
    setAssignToData(assignToDataTmp);
    // console.log('>>>>>>>>>>> assignToDataTmp', assignToDataTmp);
    onChange && onChange(assignToDataTmp);
  };

  const handleOnchangeUsers = (value: any[]) => {
    const assignsTo: any[] = [];
    value.map((v: any) => {
      //const assignTo: IAssignToName = { user: { id: v.id, name: v.name } };
      const assignTo: any = { id: v.id, name: v.name };
      assignsTo.push(assignTo);
    });
    let assignToDataTmp = { ...assignToData, assignsTo: assignsTo };
    setAssignToData(assignToDataTmp);
    // console.log('>>>>>>>>>>> assignToDataTmp', assignToDataTmp);
    onChange && onChange(assignToDataTmp);
  };
  const handleOnchangeOnline = (value: boolean) => {
    setOnline(value);
    let assignToDataTmp = { ...assignToData, online: value };
    setAssignToData(assignToDataTmp);
    onChange && onChange(assignToDataTmp);
  };
  const handleOnchangeWorkDay = (value: boolean) => {
    setBaseOnWorkDay(value);
    let assignToDataTmp = { ...assignToData, baseOnWorkDay: value };
    setAssignToData(assignToDataTmp);
    onChange && onChange(assignToDataTmp);
  };

  const handleOnchangeGroup = (value: any) => {
    const assignsTo: IAssignToName[] = [{ group: { id: value.id, name: value.name } }];
    let assignToDataTmp = { ...assignToData, assignsTo: assignsTo };
    setAssignToData(assignToDataTmp);
    // console.log('>>>>>>>>>>> assignToDataTmp', assignToDataTmp);
    onChange && onChange(assignToDataTmp);
  };

  const handleOnchangeGroups = (value: any[]) => {
    const assignsTo: IAssignToName[] = [];
    value.map((v: any) => {
      const assignTo: IAssignToName = { group: { id: v.id, name: v.name } };
      assignsTo.push(assignTo);
    });
    let assignToDataTmp = { ...assignToData, assignsTo: assignsTo };
    setAssignToData(assignToDataTmp);
    // console.log('>>>>>>>>>>> assignToDataTmp', assignToDataTmp);
    onChange && onChange(assignToDataTmp);
  };

  const OPTIONS = [
    { value: EAREntryAssignToType.ROUND_ROBIN, label: 'Round Robin' },
    { value: EAREntryAssignToType.BALANCE_NUM, label: 'Load Balance by number' },
  ];
  const [options, setOptions] = useState(OPTIONS);
  const [selectedOption, setSelectedOption] = useState(OPTIONS[0]);
  //change
  const handleOptionChange = (newOption: any) => {
    setSelectedOption(newOption);
    let assignToDataTmp = { ...assignToData, type: newOption.value };
    setAssignToData(assignToDataTmp);
    // console.log('>>>>>>>>>>> assignToDataTmp', assignToDataTmp);
    onChange && onChange(assignToDataTmp);
  };

  const handleOnChangeBaseOnWork = (e: any) => {
    const { value } = e.target;
    // console.log('>>>>>>>>. newOption', e.target);
    let assignToDataTmp = { ...assignToData, baseOnWorkDay: value === true ? true : false };
    setAssignToData(assignToDataTmp);
    // console.log('>>>>>>>>>>> assignToDataTmp', assignToDataTmp);
    onChange && onChange(assignToDataTmp);
  };
  const handleOnChangeOnline = (e: any) => {
    const { value } = e.target;
    let assignToDataTmp = { ...assignToData, online: value === true ? true : false };
    setAssignToData(assignToDataTmp);
    // console.log('>>>>>>>>>>> assignToDataTmp', assignToDataTmp);
    onChange && onChange(assignToDataTmp);
  };
  const handleQueueChange = (newOption: any, type: string) => {
    // console.log('>>>>>>>>>>> handleQueueChange', newOption, type);
    if (type == 'type') {
      handleOptionChange(newOption);
    } else if (type == 'baseOnWorkDay') {
      handleOnChangeBaseOnWork(newOption);
    }
  };

  return (
    <>
      <div className="d-flex">
        <span className="mg-r-10 pd-x-12 pd-y-5 bg-primary tx-white">3</span>
        <span className="flex-grow-1 pd-t-5">Select the users/groups to assign</span>
      </div>
      <div className="d-flex mg-l-15 mg-t-10">
        <span className="mg-r-200">Assign to</span>
        <div className="mg-l-10 flex-grow-1">
          {/* a user of several users */}
          {/* <div className="d-flex align-items-center mg-b-10">
            <div className="custom-control custom-radio mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                name="assignRadio"
                id="assignRadio3"
                value={EAREntryAssignToMode.USERS}
                checked={assign === EAREntryAssignToMode.USERS}
                onChange={(e) => handleOnchange(e)}
              />
              <label className="custom-control-label" htmlFor="assignRadio3">
                Users
              </label>
            </div>
          </div>

          {assign === EAREntryAssignToMode.USERS && (
            <div className="mg-l-30">
              <div className="form-group">
                <label className="form-item-title">Add Users</label>
                <UserAutoComplete showAvatar onChange={(e: any) => handleOnchangeUsers(e)} />
              </div>
              <div className="form-group">
                <CheckOption onChange={handleOnChangeBaseOnWork} />
              </div>
            </div>
          )} */}

          {/* a Assignment Group of several Group */}
          {/* <div className="d-flex align-items-center mg-b-10">
            <div className="custom-control custom-radio mg-r-10">
              <input
                type="radio"
                className="custom-control-input"
                name="assignRadio"
                id="assignRadio4"
                value={EAREntryAssignToMode.GROUPS}
                checked={assign === EAREntryAssignToMode.GROUPS}
                onChange={(e) => handleOnchange(e)}
              />
              <label className="custom-control-label" htmlFor="assignRadio4">
                Groups
              </label>
            </div>
          </div>

          {assign === EAREntryAssignToMode.GROUPS && (
            <div className="mg-l-30">
              <div className="form-group">
                <label className="form-item-title">Add Groups</label>
                <UserGroupSelect showAvatar onChange={(e: any) => handleOnchangeGroups(e)} />
              </div>
             
              <CheckOption onChange={handleOnChangeBaseOnWork} />
            </div>
          )} */}
          <div className="mg-b-10 pd-l-25">
            <div className="d-flex align-items-center mg-b-10">
              Choose a round-robin list from
              <Select
                className="flex-shrink-0 mg-l-10"
                options={groupOptions}
                value={groupOption}
                onChange={setGroupOption}
                width={110}
              />
            </div>
            {/* Users list */}
            {groupOption.value === 1 && (
              <UserAutoComplete
                showAvatar
                value={assignToData.assignsTo}
                onChange={(e: any) => handleOnchangeUsers(e)}
              />
            )}
            {/* Group list */}
            {groupOption.value === 2 && (
              <UserGroupSelect showAvatar onChange={(e: any) => handleOnchangeGroups(e)} />
            )}
            <div className="mg-t-10">
              <label className="tx-color-03">Check availability based on</label>
              <Checkbox
                checked={online}
                label="Online Status"
                onChange={() => handleOnchangeOnline(!online)}
              />
              <Checkbox
                checked={baseOnWorkDay}
                label="Work Day"
                onChange={() => handleOnchangeWorkDay(!baseOnWorkDay)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignTo;
