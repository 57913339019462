import { Input } from '@base/components/form';
import SelectBox from '@base/containers/view-field/selectbox/edit';
import { StatusForm } from '@settings/process/types/process';
import { Minus, Plus, Save, X } from 'react-feather';
import {
  PROCESS_STATUS_DIRECTIONS,
  PROCESS_STATUS_DIRECTIONS_SORT,
  PROCESS_STATUS_DIRECTIONS_VIEW,
  PROCESS_STATUS_EVENTS,
  PROCESS_STATUS_EVENTS_VIEW,
  PROCESS_STATUS_PROPERTIES,
  PROCESS_STATUS_PROPERTIES_VIEW,
  PROCESS_STATUS_VIEWS,
  PROCESS_STATUS_VIEWS_VIEW,
} from '@settings/process/config/constants';
import { OptionValue } from '@base/types/interfaces/common';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { CREATE_STEP_STATUS, UPDATE_STEP_STATUS } from '@settings/process/services/process';
import { useQueryClient } from '@tanstack/react-query'; //v4
import _ from 'lodash';
import { checkParallel, checkSequence } from '@settings/process/utils/helper';
import { useNextStepAtomValue, useNodeEdgesAtomValue } from '@settings/process/recoil/diagram';
import { checkStep } from '@settings/process/utils/helper';
import { useRecoilState, useRecoilValue } from 'recoil';
import React from 'react';
import statusAtom, {
  selectedStatusAtom,
  statusWithParallelFilter,
} from '@settings/process/recoil/status';
import stepTypeAtom, { stepAddOpenAtom } from '@settings/process/recoil/step';
import useStatus from '@settings/process/hooks/useStatus';

interface StatusItemWriteProps {
  processId: string;
  stepId?: string;
  status: StatusForm;
  parallelIndex: number;
  isView: boolean;
  isCta: boolean;
  actionMethod?: string;
}

function StatusItemWrite(props: StatusItemWriteProps) {
  const { processId, stepId, status, parallelIndex, isView, isCta, actionMethod } = props;
  // console.log('item write', props);
  const parallelCount = useRecoilValue(statusWithParallelFilter(isCta ? 'cta' : 'normal')).length;
  const statusesValue = useRecoilValue(statusAtom);
  const [selectedStatus, setSelectedStatus] = useRecoilState(selectedStatusAtom);
  const [isSaving, setIsSaving] = useState(false);

  const nodeEdges = useNodeEdgesAtomValue();
  const stepType = useRecoilValue(stepTypeAtom);
  const nextSteps = useNextStepAtomValue();
  const sourceStep = useRecoilValue(stepAddOpenAtom);
  const isParallel = checkParallel(status);
  const {
    onChangeKeyName,
    onChangeDirection,
    onChangeStatus,
    onSequenceChange,
    onSequenceAdd,
    onSequenceDelete,
    onChangeMultiplePrimary,
    onReset,
  } = useStatus(status, isView);

  const stepHelper = useMemo(() => {
    return checkStep(nodeEdges.others);
  }, [nodeEdges.others]);

  const queryClient = useQueryClient();
  const mutationEdit: any = useMutationPost<BaseMutationResponse>(
    UPDATE_STEP_STATUS,
    'process_updateStatus',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any) => {
        setIsSaving(false);
        setSelectedStatus('');
        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', processId]);
          queryClient.invalidateQueries(['process_step', stepId]);
        }, 500);
        // console.log('success data', data);
        toast.success('Update status successfully!');
      },
      onError: (error: any) => {
        setIsSaving(false);
        // console.log('failed', error);
        toast.success('Update status failed');
      },
    },
  );

  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_STEP_STATUS,
    'process_createStatus',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any) => {
        setIsSaving(false);
        setSelectedStatus('');
        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', processId]);
          queryClient.invalidateQueries(['process_step', stepId]);
        }, 500);
        // console.log('success data', data);
        toast.success('Create status successfully!');
      },
      onError: (error: any) => {
        setIsSaving(false);
        // console.log('failed', error);
        toast.success('Create status failed');
      },
    },
  );

  const onSave = useCallback(() => {
    // check sequence number
    let allSequence: string[] = [];
    let splitCount = 0;
    let parallelId = '';
    let addY = 250;
    let height = 0;
    for (const st of statusesValue) {
      if (st.sequence.length > 0) {
        allSequence = _.concat(allSequence, st.sequence);
      }
      if (
        st.multiple == 'MULTIPLE_CHOICE' ||
        st.multiple == 'MULTIPLE_PARALLEL' ||
        st.multiple == 'MULTIPLE_ANY'
      ) {
        if (splitCount > 0) {
          if (parallelId) {
            const yMaxAxis = stepHelper.forwardDepthMultiple(parallelId);
            // 250 : split height
            height += yMaxAxis + 250;
            // if (st.nextStep && st.nextStep.keyName == "") {
            //   updateY += height;
            // } else {
            //   const targetNode = nodeEdges.others[st.nextStep.keyName];
            //   updateY = _.clone(targetNode.position.y);
            //   // console.log('targetNode===>', targetNode, updateY)
            // }
          } else {
            height += 250;
          }
        }
        parallelId = st.nextStep.keyName;
        splitCount += 1;
      }
    }
    // 3 : half of arrow thick
    let updateY = sourceStep.position.y + height - 3;
    if (!checkSequence(_.sortBy(_.uniq(allSequence)))) {
      alert('Sequence Number has to be order');
      return;
    }

    const updateData = {
      id: processId,
      stepId: stepId,
      updateY: updateY,
      addY: addY, // split height
      status: {
        id: status.id,
        button: status.button,
        name: status.name,
        direction: status.direction.keyName,
        property: status.property.keyName,
        view: status.view.keyName,
        event: status.event.keyName,
        sequence: status.sequence,
        nextStep: {
          id: status.nextStep.keyName,
          name: status.nextStep.languageKey,
        },
        multiple: status.multiple,
        primary: status.primary,
        ctaId: status.ctaId ?? '',
      },
    };

    if (status.new) {
      mutationAdd.mutate(updateData);
    } else {
      mutationEdit.mutate(updateData);
    }
  }, [status]);

  const sequenceOptions = useMemo<OptionValue[]>(() => {
    let options: OptionValue[] = [{ keyName: '', languageKey: '' }];
    for (let i = 1.5; i < 5.5; i = i + 0.5) {
      options.push({ keyName: '' + i, languageKey: '' + i });
    }
    return options;
  }, []);

  const directionOptions = useMemo<OptionValue[]>(() => {
    let directions: OptionValue[] = [];
    for (const direction of PROCESS_STATUS_DIRECTIONS) {
      if (stepType.value == 'TYPE_SIMPLE_ACTION') {
        if (
          direction.keyName == 'DIRECTION_FORWARD_OUTGOING_FORWARD' ||
          direction.keyName == 'DIRECTION_FORWARD_OUTGOING_BOTTOM'
        ) {
          directions.push(direction);
        }
      } else if (stepType.value == 'TYPE_WAIT') {
        if (
          direction.keyName == 'DIRECTION_FORWARD_OUTGOING_FORWARD' ||
          direction.keyName == 'DIRECTION_FORWARD_OUTGOING_PROCESS'
        ) {
          directions.push(direction);
        }
      } else {
        if (sourceStep.direction == 'DIRECTION_FORWARD_OUTGOING_BACKWARD') {
          if (
            direction.keyName == 'DIRECTION_BACKWARD_OUTGOING_FORWARD' ||
            direction.keyName == 'DIRECTION_STAYING' ||
            direction.keyName == 'DIRECTION_BACKWARD_OUTGOING_BOTTOM' ||
            direction.keyName == 'DIRECTION_BACKWARD_OUTGOING_MIDDLE'
          ) {
            directions.push(direction);
          }
        } else if (sourceStep.direction == 'DIRECTION_FORWARD_OUTGOING_MIDDLE') {
          if (
            direction.keyName == 'DIRECTION_FORWARD_INCOMING_MIDDLE' ||
            direction.keyName == 'DIRECTION_STAYING'
          ) {
            directions.push(direction);
          }
        } else {
          directions.push(direction);
        }
      }
    }
    return directions;
  }, [sourceStep.position]);

  const renderButton = useCallback(() => {
    return (
      <div className="">
        {status.button == '-' ? (
          '-'
        ) : (
          <Input
            type="text"
            value={status.button}
            onChange={(newValue: string) => onChangeStatus({ button: newValue })}
          />
        )}
      </div>
    );
  }, [status]);

  const renderStatus = useCallback(() => {
    return (
      <div className="">
        {status.name == '-' ? (
          '-'
        ) : (
          <Input
            type="text"
            value={status.name}
            onChange={(newValue: string) => onChangeStatus({ name: newValue })}
          />
        )}
      </div>
    );
  }, [status]);

  const renderView = useCallback(() => {
    return (
      <div className="">
        {status.view.keyName == 'VIEW_DISABLE' ||
        status.definedId != '' ||
        stepType.value == 'TYPE_SIMPLE_ACTION' ? (
          PROCESS_STATUS_VIEWS_VIEW[status.view.keyName]
        ) : (
          <SelectBox
            value={status.view}
            onChange={(newValue: OptionValue) => onChangeKeyName({ view: newValue })}
            options={PROCESS_STATUS_VIEWS}
            isSearchable={false}
          />
        )}
      </div>
    );
  }, [status]);

  const renderEvent = useCallback(() => {
    return (
      <div className="">
        {status.event.keyName == 'EVENT_DISABLE' ||
        status.definedId != '' ||
        stepType.value == 'TYPE_SIMPLE_ACTION' ||
        stepType.value == 'TYPE_WAIT' ? (
          PROCESS_STATUS_EVENTS_VIEW[status.event.keyName]
        ) : (
          <SelectBox
            value={status.event}
            onChange={(newValue: OptionValue) => onChangeKeyName({ event: newValue })}
            options={PROCESS_STATUS_EVENTS}
            isSearchable={false}
          />
        )}
      </div>
    );
  }, [status]);

  const renderSequence = useCallback(() => {
    return (
      <div className="d-flex align-content-between justify-content-center">
        {status.sequence[0] == '' || status.definedId != '' ? (
          status.definedId != '' ? (
            status.sequence.join('/')
          ) : (
            '-'
          )
        ) : (
          <>
            <div className="d-flex flex-column">
              {status.sequence.map((seq, index) => {
                return (
                  <SelectBox
                    key={index}
                    value={{ keyName: '' + seq, languageKey: '' + seq }}
                    onChange={(newValue: OptionValue) => onSequenceChange(newValue, index)}
                    options={sequenceOptions}
                    isSearchable={false}
                    className={'mg-b-5 wd-100'}
                  />
                );
              })}
            </div>
            <>
              {status.sequence.length < 2 && (
                <button
                  type="button"
                  className="btn btn-xs btn-link tx-danger btn-icon"
                  onClick={() => onSequenceAdd()}
                >
                  <Plus />
                </button>
              )}
              {status.sequence.length == 2 && (
                <button
                  type="button"
                  className="btn btn-xs btn-link tx-danger btn-icon"
                  onClick={() => onSequenceDelete()}
                >
                  <Minus />
                </button>
              )}
            </>
          </>
        )}
      </div>
    );
  }, [status]);

  return (
    <tr className="ht-55 text-center">
      {isCta && stepType.value == 'TYPE_SITE' && <td>{status?.pageName}</td>}
      {actionMethod != 'ACTION_METHOD_AUTO' &&
        stepType.value != 'TYPE_WAIT' &&
        (isParallel && parallelCount > 1 ? (
          parallelIndex == 1 ? (
            <td rowSpan={parallelCount}>{renderButton()}</td>
          ) : (
            ''
          )
        ) : (
          <td>{renderButton()}</td>
        ))}
      {actionMethod != 'ACTION_METHOD_AUTO' && !isCta && stepType.value != 'TYPE_WAIT' && (
        <>
          {isParallel && parallelCount > 1 ? (
            parallelIndex == 1 ? (
              <td rowSpan={parallelCount}>{renderView()}</td>
            ) : (
              ''
            )
          ) : (
            <td>{renderView()}</td>
          )}
        </>
      )}
      {actionMethod != 'ACTION_METHOD_AUTO' &&
        (isParallel && parallelCount > 1 ? (
          parallelIndex == 1 ? (
            <td rowSpan={parallelCount}>{renderEvent()}</td>
          ) : (
            ''
          )
        ) : (
          <td>{renderEvent()}</td>
        ))}
      {/* {stepType.value !== 'TYPE_SIMPLE_ACTION' && (
        <> */}
      {isParallel && parallelCount > 1 ? (
        parallelIndex == 1 ? (
          <td rowSpan={parallelCount}>{renderStatus()}</td>
        ) : (
          ''
        )
      ) : (
        <td>{renderStatus()}</td>
      )}
      {/* </>
      )} */}
      <td>
        {!isView &&
          (status.multiple == 'MULTIPLE_CHOICE' || status.multiple == 'MULTIPLE_PARALLEL') && (
            <div className="form-group">
              <label className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="multiple-primary"
                  value="yes"
                  id={status.id}
                  checked={status.primary}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMultiplePrimary(e)}
                />
                <label className="custom-control-label" htmlFor={status.id}>
                  Primary
                </label>
              </label>
            </div>
          )}

        <div>
          {status.direction.keyName == 'DIRECTION_DISABLE' || status.definedId != '' ? (
            PROCESS_STATUS_DIRECTIONS_VIEW[status.direction.keyName]
          ) : (
            <SelectBox
              value={status.direction}
              onChange={(newValue: OptionValue) => onChangeDirection(newValue)}
              options={directionOptions}
              isSearchable={false}
            />
          )}
        </div>
      </td>
      <td>
        <div>
          {/* {status.nextStep.languageKey} */}
          {status.direction.keyName == 'DIRECTION_STAYING' ||
          status.direction.keyName == 'DIRECTION_FORWARD_INCOMING_MIDDLE' ? (
            '-'
          ) : (
            <SelectBox
              value={status.nextStep}
              onChange={(newValue: OptionValue) => onChangeKeyName({ nextStep: newValue })}
              options={nextSteps}
              isSearchable={false}
            />
          )}
        </div>
      </td>
      <td>
        <div>
          {status.property.keyName == 'PROPERTY_DISABLE' ||
          status.definedId != '' ||
          stepType.value == 'TYPE_SIMPLE_ACTION' ? (
            PROCESS_STATUS_PROPERTIES_VIEW[status.property.keyName]
          ) : (
            <SelectBox
              value={status.property}
              onChange={(newValue: OptionValue) => onChangeKeyName({ property: newValue })}
              options={PROCESS_STATUS_PROPERTIES}
              isSearchable={false}
            />
          )}
        </div>
      </td>
      {stepType.value !== 'TYPE_SIMPLE_ACTION' && stepType.value !== 'TYPE_WAIT' && !isCta && (
        <>
          {isParallel && parallelCount > 1 ? (
            parallelIndex == 1 ? (
              <td rowSpan={parallelCount}>{renderSequence()}</td>
            ) : (
              ''
            )
          ) : (
            <td>{renderSequence()}</td>
          )}
        </>
      )}
      <td>
        <div className="d-flex align-items-center justify-content-center">
          {isView && selectedStatus == status.id && (
            <button
              type="button"
              className="btn btn-xs btn-link tx-success btn-icon"
              onClick={onSave}
              disabled={isSaving}
            >
              {isSaving ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <Save />
              )}
            </button>
          )}
          {status.reset &&
            status.property.keyName !== 'PROPERTY_NEW' &&
            status.property.keyName !== 'PROPERTY_TODO' &&
            status.property.keyName !== 'PROPERTY_TODO_DOING' && (
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon"
                onClick={() => onReset()}
              >
                <X />
              </button>
            )}
        </div>
      </td>
    </tr>
  );
}

export default React.memo(StatusItemWrite);
