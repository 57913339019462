import { useEffect, useRef, useState } from 'react';

const ImageSize: React.FC<any> = (props) => {
  const { value, onChange } = props;

  // state
  const [curValue, setCurValue] = useState<any>({ width: 300, height: 300 });

  // init
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(curValue)) {
        setCurValue(value);
      }
    } else {
      setCurValue({ width: 300, height: 300 });
    }
  }, [value]);

  // onChange
  const handleValueChange = (keyName: string, keyValue: number) => {
    const newValue = { ...curValue };
    newValue[keyName] = keyValue;
    setCurValue(newValue);
    // callback
    onChange && onChange(newValue);
  };

  return (
    <div className="row row-xs">
      <div className="col-md-6">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text lh-1">Width</span>
          </div>
          <input
            type="number"
            className="form-control"
            value={curValue.width}
            onChange={(e: any) => handleValueChange('width', parseInt(e.target.value))}
          />
          <div className="input-group-append">
            <span className="input-group-text lh-1">px</span>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text lh-1">Height</span>
          </div>
          <input
            type="number"
            className="form-control"
            value={curValue.height}
            onChange={(e: any) => handleValueChange('height', parseInt(e.target.value))}
          />
          <div className="input-group-append">
            <span className="input-group-text lh-1">px</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSize;
