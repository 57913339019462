import React, { useEffect, useState } from 'react';
import { ViewFieldParse } from '@base/utils/helpers/no-layout-utils';
import { ViewFields } from '@base/components/hook-form/view';
import { BusinessStep } from '@settings/process/types/process';
import usePost from '@base/hooks/usePost';
import { GET_CLOSED_STEP } from '@settings/process/services/process';
import Loading from '@base/components/loading';
import { ClosedViewField } from '@settings/process/config/view-field/closed';

type closedView = {
  processId: string;
  stepId: string;
};

const ClosedView = (props: closedView) => {
  // console.log('closed view');
  const { processId, stepId } = props;
  const { data, isLoading } = usePost<BusinessStep>(['process_step', stepId], GET_CLOSED_STEP, {
    id: processId,
    stepId: stepId,
  });
  const [fields, setFields] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const fields = ViewFieldParse(data, ClosedViewField, true, ['process_diagram']);
      setFields(fields);
    }
  }, [data]);

  // console.log('closed fields', fields);

  const ignoreFields: string[] = [];

  return (
    <div className="form-row mg-0">
      {isLoading ? (
        <Loading />
      ) : (
        <ViewFields
          fields={fields}
          ignoreFields={ignoreFields}
          menuSource={'processStep'}
          menuSourceId={processId}
        />
      )}
    </div>
  );
};

export default React.memo(ClosedView);
