import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { components } from 'react-select';
import { Select } from '@base/components/form';
import { FormIcon } from '@base/components/form/icon';
import { useSurveysAutoComplete } from '@settings/online-digital-content/survey/services/list-service';

interface IAutoCompleteProps {
  className?: string;
  ctrlStyles?: any;
  placeholder?: string;
  single?: boolean;
  visible?: boolean;
  showAllOption?: boolean;
  isDisabled?: boolean;
  exceptItems?: any;
  value: any;
  onChange: any;
}

/**
 *
 * @param {*} props
 * @returns
 */
const SurveyAutoComplete: React.FC<IAutoCompleteProps> = (props) => {
  const {
    className,
    ctrlStyles,
    placeholder = 'Type or click to select a survey...',
    single = false, //
    visible = true, //hide or display selected items
    isDisabled = false,
    value, //[], initial value
    onChange,
  } = props;

  // state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  const [selectedValue, setSelectedValue] = useState<any>(null);

  // initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
            setSelectedValue(value);
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        //single object
        if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
          setSelectedValue(value);
        }
      }
    } else {
      // console.log('AAAAAA');
      setSelectedValue(null);
    }
  }, [value]);

  //build params
  const getSearchParams = () => {
    let params: any = {
      filter: {
        filters: {},
        keyword: searchText,
        paging: {
          page: 1,
          size: 99,
        },
      },
    };

    return params;
  };

  //convert to select options
  const formatSelectOptions = (results: any) => {
    let newOptions = results?.data?.map((_item: any) => {
      return {
        ..._item,
        label: _item.name,
        value: _item.id,
      };
    });
    return newOptions;
  };

  const { data: results, status: searchStatus } = useSurveysAutoComplete(getSearchParams());
  //// console.log('postResult', results);
  let newOptions = formatSelectOptions(results);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    let newItem = newValue;
    if (visible) {
      if (!single) {
        //just apply for multi
        const allIdx = newItem?.findIndex((ele: any) => ele.value === 'all');
        if (allIdx > -1) {
          newItem = [newItem[allIdx]];
        }
      }
      setSelectedValue(newItem);
    }
    //callback
    onChange && onChange(newItem);
  };

  //selected options
  const CustomMultiValueLabel = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.MultiValueLabel {...props}>
        <div className="d-flex align-items-center css-customer-autocomplete">
          <div className="d-flex mg-l-10 css-flex">{children}</div>
        </div>
      </components.MultiValueLabel>
    );
  };

  //single custom option
  const CustomSingleValueLabel = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.SingleValue {...props}>
        <div className="d-flex align-items-center css-customer-autocomplete">
          <div className="d-flex mg-l-10 css-flex">{children}</div>
        </div>
      </components.SingleValue>
    );
  };

  //select custom option
  const CustomOption = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div className="d-flex align-items-center css-customer-autocomplete">
          <div className="d-flex mg-l-10 css-flex">{children}</div>
        </div>
        {data.value === 'all' && <hr className="mg-y-10" />}
      </components.Option>
    );
  };

  //render
  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: '250px' }}>
      <Select
        isMulti={!single}
        ctrlStyles={ctrlStyles}
        noOptionsMessage={() => 'No surveys found.'}
        placeholder={<div className="react-select-placeholder">{placeholder}</div>}
        iconIndicator={
          <div className="icons-size-1">{<FormIcon icon="Link" iconType="feather" />}</div>
        }
        isClearable
        isDisabled={isDisabled}
        // components={{
        //   Option: CustomOption,
        //   MultiValueLabel: CustomMultiValueLabel,
        //   SingleValue: CustomSingleValueLabel,
        // }}
        isLoading={searchStatus === 'loading'}
        inputValue={inputText}
        onInputChange={handleInputChange}
        value={selectedValue}
        options={newOptions}
        //getOptionLabel={color => color.name}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default SurveyAutoComplete;
