import React from 'react';
import { Edit2, Trash } from 'react-feather';

interface IGridItemFooterProps {
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  row: any;
  canDelete?: boolean;
}
const GridItemFooter: React.FC<IGridItemFooterProps> = (props: IGridItemFooterProps) => {
  const { onDelete, onEdit, row, canDelete = true } = props;
  return (
    <>
      <div className="grid-footer">
        <button
          type="button"
          onClick={(ev: any) => {
            onEdit(row.id);
            ev && ev.preventDefault && ev.preventDefault();
            ev && ev.stopPropagation && ev.stopPropagation();
          }}
          className="btn btn-xs btn-warning"
        >
          <Edit2 size={12} /> Edit
        </button>
        {canDelete && onDelete && (
          <button
            type="button"
            onClick={(ev: any) => {
              onDelete(row.id);
              ev && ev.preventDefault && ev.preventDefault();
              ev && ev.stopPropagation && ev.stopPropagation();
            }}
            className="btn btn-xs mg-l-5 btn-danger"
          >
            <Trash size={12} /> Delete
          </button>
        )}
      </div>
    </>
  );
};

export default GridItemFooter;
