import React from 'react';
import { Button } from '@base/components/form';
import withMiModal from '@base/hooks/hocs/withMiModal';

const SmsInfo: React.FC<any> = (props) => {
  const { onClose } = props;

  return (
    <div className="pos-relative">
      <div className='pd-15'>
        <p className="tx-orange">
          발신번호 세칙에 맞는 발신번호만 등록 가능합니다. Enter phone numbers that meet the
          detailed regulations.
        </p>
        <table className="table bd-b bd-l bd-r">
          <tbody>
            <tr>
              <th scope="row" className="bg-light align-middle tx-center">
                유선전화번호
                <br />
                (일반전화번호)
              </th>
              <td className="align-middle">
                17개 지역번호를 앞자리에 포함한 전화번호
                <br />
                <span className="tx-danger">* 통신사가 부여하지 않은 유선번호 등록 불가</span>
                <br />
                예시)02-000-0000, 031-000-0000
              </td>
            </tr>
            <tr>
              <th scope="row" className="bg-light align-middle tx-center">
                휴대폰번호
                <br />
                (이동통신전화번호)
              </th>
              <td className="align-middle">010-000-0000</td>
            </tr>
            <tr>
              <th scope="row" className="bg-light align-middle tx-center">
                전국대표번호
              </th>
              <td className="align-middle">
                8자리만 허용 <br />
                <span className="tx-danger">* 지역번호 또는 내선번호 포함 불가</span>
                <br />
                예시)1544-0000, 1588-0000
              </td>
            </tr>
            <tr>
              <th scope="row" className="bg-light align-middle tx-center">
                특수번호
              </th>
              <td className="align-middle">
                증빙서류 제출 후 등록하여 사용 가능
                <br />
                112, 1335 등 해당 사용자 (국가, 지방자치단체, 공공기관 등)에 한하여 사용 가능
              </td>
            </tr>
            <tr>
              <th scope="row" className="bg-light align-middle tx-center">
                공통서비스 식별번호
                <br />
                (ONO 계약)
              </th>
              <td className="align-middle">번호 앞 지역번호 사용금지</td>
            </tr>
            <tr>
              <th scope="row" className="bg-light align-middle tx-center">
                기업/단체
              </th>
              <td className="align-middle">
                기업/단체 명의의 전화번호 또는 해당 기업/단체 소속 직원의 전화번호
              </td>
            </tr>
            <tr>
              <th scope="row" className="bg-light align-middle tx-center">
                가상번호
              </th>
              <td className="align-middle">030, 050으로 시작하는 번호는 11~12자리만 허용</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex pd-10 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default withMiModal(SmsInfo);
