import { atom } from 'recoil';

export const tasklWriteOptionAtom = atom({
  key: 'TaskWriteOptionAtom',
  default: {
    writeType: 'task',
    writeMenu: 'list',
    isOpenWrite: false,
  },
});
