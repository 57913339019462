import { ISideMenuItem } from '@base/types/interfaces/app';
import { ListPage, ViewPage } from './pages/main-page';

export const SUB_MENUS: ISideMenuItem[] = [
  {
    id: 'desk_site',
    default: true,
    keyName: 'desk',
    languageKey: 'Desk Site', //'crm_new_setting_format_setting',
    icon: {
      icon: 'Globe',
      iconType: 'feather',
    },
    layouts: [],
    component: ListPage,
    componentView: ViewPage,
  },
];
