import CommonViewField from '@base/containers/view-field/common';
import View from './WaitView';
import Write from './WaitWrite';
import { OptionValue } from '@base/types/interfaces/common';

export const TYPE_OPTIONS: OptionValue[] = [
  { keyName: 'WAIT_UNTIL_TRIGGER', languageKey: 'Wait until Trigger' },
  { keyName: 'WAIT_UNTIL_DATE_TIME', languageKey: 'Wait until Date Time' },
  { keyName: 'WAIT_UNTIL_AND_JOIN', languageKey: 'Wait until And-Join' },
  { keyName: 'WAIT_BY_DURATION', languageKey: 'Wait by Duration' },
  { keyName: 'WAIT_SCHEDULE_ATTRIBUTE', languageKey: 'Set Schedule by Attribute' },
];

function WaitViewField(props: any) {
  return (
    <CommonViewField {...props} componentView={View} componentEdit={Write} value={props.value} />
  );
}

export default WaitViewField;
