import React, { useCallback, useMemo } from 'react';
import TriggerWrite from '../trigger/TriggerWrite';
import { DurationValue, OptionValue } from '@base/types/interfaces/common';
import { SelectBox } from '@base/config/write-field/components';
import { DatePicker } from '@base/components/date-picker';
import { WHEN_OPTIONS } from '@base/config/constant';
import { Wait } from '@settings/process/types/process';
import dayjs from 'dayjs';
import { TYPE_OPTIONS } from './WaitViewField';
import _ from 'lodash';
import { Duration } from '@base/config/write-field/components';

interface WaitWriteProps {
  value: Wait;
  onChange: (val: Wait) => void;
}

function WaitWrite({ value, onChange }: WaitWriteProps) {
  const onTypeChange = useCallback(
    (val: OptionValue) => {
      let newVal = _.cloneDeep(value);
      if (val.keyName == 'WAIT_UNTIL_TRIGGER') {
        if (!newVal.trigger) {
          newVal.trigger = '';
        }
      } else if (val.keyName == 'WAIT_UNTIL_DATE_TIME') {
        if (!newVal.datetime) {
          newVal.datetime = new Date().toISOString();
        }
      } else if (val.keyName == 'WAIT_UNTIL_AND_JOIN') {
      } else if (val.keyName == 'WAIT_BY_DURATION') {
        if (!newVal.duration) {
          newVal.duration = {
            time: 1,
            unit: 'TERM_DAY',
          };
        }
      } else if (val.keyName == 'WAIT_SCHEDULE_ATTRIBUTE') {
        if (!newVal.schedule) {
          newVal.schedule = {
            when: 'WHEN_AFTER',
            duration: { time: 1, unit: 'TERM_HOUR' },
            attr: '',
          };
        }
      }
      onChange && onChange({ ...newVal, type: val.keyName });
    },
    [value],
  );

  const onTriggerChange = useCallback(
    (val: string) => {
      onChange && onChange({ ...value, trigger: val });
    },
    [value],
  );

  const onChangeDate = useCallback(
    (val: Date) => {
      onChange && onChange({ ...value, datetime: val.toISOString() });
    },
    [value],
  );

  const onDuration = useCallback(
    (val: DurationValue) => {
      onChange && onChange({ ...value, duration: { time: val.duration, unit: val.durationUnit } });
    },
    [value],
  );

  const onScheduleDuration = useCallback(
    (val: DurationValue) => {
      onChange &&
        onChange({
          ...value,
          schedule: {
            ...value.schedule,
            duration: { time: val.duration, unit: val.durationUnit },
          },
        });
    },
    [value],
  );

  const onScheduleWhen = useCallback(
    (val: OptionValue) => {
      onChange &&
        onChange({
          ...value,
          schedule: {
            ...value.schedule,
            when: val.keyName,
          },
        });
    },
    [value],
  );

  const onScheduleAttribute = useCallback(
    (val: OptionValue) => {
      onChange &&
        onChange({
          ...value,
          schedule: {
            ...value.schedule,
            attr: val.keyName,
          },
        });
    },
    [value],
  );

  const typeValue = useMemo(() => {
    return TYPE_OPTIONS.find((opt) => opt.keyName == (value.type as string))!!;
  }, [value.type]);

  const whenValue = useMemo(() => {
    return WHEN_OPTIONS.find((opt) => opt.keyName == value.schedule?.when)!!;
  }, [value.schedule?.when]);

  return (
    <div className="form-row">
      <div className="form-group col-12">
        <label className="form-item-title">Wait Type</label>
        <SelectBox options={TYPE_OPTIONS} value={typeValue} onChange={onTypeChange} />
      </div>
      {value?.type == 'WAIT_UNTIL_TRIGGER' && (
        <TriggerWrite onChange={onTriggerChange} value={value.trigger} />
      )}
      {value?.type == 'WAIT_UNTIL_DATE_TIME' && (
        <div className="form-group col-12">
          <label className="form-item-title">Date/Time</label>
          <div className="input-group wd-200">
            <DatePicker
              selected={value.datetime ? dayjs(value.datetime).toDate() : null}
              onChange={(val: Date) => onChangeDate(val)}
              startDate={value.datetime ? dayjs(value.datetime).toDate() : new Date()}
              placement={'bottom-start'}
              showTimeInput={true}
              dateFormat={'yyyy/MM/dd HH:mm'}
            />
          </div>
        </div>
      )}
      {value?.type == 'WAIT_BY_DURATION' && (
        <div className="form-group col-12">
          <label className="form-item-title">Duration</label>
          <div className="d-flex align-items-center">
            <span className="mg-r-10">Wait</span>
            <Duration
              value={{
                duration: value.duration?.time,
                durationUnit: value.duration?.unit,
              }}
              onChange={onDuration}
            />
          </div>
        </div>
      )}
      {value?.type == 'WAIT_SCHEDULE_ATTRIBUTE' && (
        <div className="form-group col-12">
          <label className="form-item-title">Schedule</label>
          <div className="d-flex flex-wrap align-items-center justify-content-start">
            <span className="mg-r-10">Wait until</span>
            <Duration
              value={{
                duration: value.schedule?.duration?.time,
                durationUnit: value.schedule?.duration?.unit,
              }}
              onChange={onScheduleDuration}
            />
            <SelectBox
              className="wd-100-f mg-r-5"
              options={WHEN_OPTIONS}
              value={whenValue}
              onChange={(val) => onScheduleWhen(val)}
            />
            <SelectBox
              className="wd-200-f"
              options={[]}
              value={{ keyName: value.schedule?.attr, languageKey: 'Select Attribute' }}
              onChange={(val) => onScheduleAttribute(val)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default WaitWrite;
