import { Input } from '@base/components/form';
import React, { useCallback } from 'react';
import { CriteriaOption } from '@settings/process/types/diagram';
import _ from 'lodash';
import { CRITERIA_FIELD_TYPE, CRITERIA_OPERATOR } from '@settings/process/config/constants';
import { MODULE } from '@base/config/constant';
import { DatePicker } from '@base/components/date-picker';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import statusAtom from '@settings/process/recoil/status';

interface CriteriaRuleProps {
  id: string;
  name: string;
  option: CriteriaOption;
  mode: 'edit' | 'view';
}

function CriteriaRule(props: CriteriaRuleProps) {
  const { mode, name, option, id } = props;
  const [statusesValue, setStatusesValue] = useRecoilState(statusAtom);
  const blockCount = option.blocks.length - 1;
  // console.log('statusValue', statusesValue);

  const onChangeValue = useCallback(
    (bIndex: number, cIndex: number, newValue: string | number) => {
      let newOption = _.cloneDeep(option);
      let condition = newOption.blocks[bIndex].conditions[cIndex];
      if (typeof newValue === 'number') {
        condition.value = newValue.toString();
      } else {
        condition.value = newValue;
      }
      changeCriteriaStatus(newOption);
    },
    [option],
  );

  const onChangeDate = useCallback(
    (bIndex: number, cIndex: number, range: string, newValue: Date) => {
      let newOption = _.cloneDeep(option);
      let condition = newOption.blocks[bIndex].conditions[cIndex];
      const newDate = dayjs(newValue).format('YYYY-MM-DD');
      if (condition.operator == 'CRITERIA_OPERATOR_BETWEEN') {
        let splitDate: string[] = ['', ''];
        if (condition.value) {
          splitDate = condition.value.split('|');
        }
        if (range == 'start') {
          splitDate[0] = newDate;
        } else {
          splitDate[1] = newDate;
        }
        condition.value = splitDate.join('|');
      } else {
        condition.value = newDate;
      }
      changeCriteriaStatus(newOption);
    },
    [option],
  );

  const changeCriteriaStatus = useCallback(
    (newOption: CriteriaOption) => {
      setStatusesValue((old) => {
        const targetIndex = old.findIndex((status) => status.id === id);
        const targetValue = { ...old[targetIndex], criteria: newOption };
        return [...old.slice(0, targetIndex), targetValue, ...old.slice(targetIndex + 1)];
      });
    },
    [id],
  );

  const onChangeName = useCallback(
    (newValue: string) => {
      setStatusesValue((old) => {
        const targetIndex = old.findIndex((status) => status.id === id);
        const targetValue = { ...old[targetIndex], button: newValue };
        return [...old.slice(0, targetIndex), targetValue, ...old.slice(targetIndex + 1)];
      });
    },
    [option, id],
  );

  return (
    <div className="mg-b-20">
      <div className="d-flex align-items-center mg-b-10">
        <label className="mg-r-10">Criteria Name</label>
        {mode == 'edit' ? (
          <Input
            className="wd-200-f"
            value={name}
            onChange={(newValue: string) => onChangeName(newValue)}
          />
        ) : (
          <label>{name}</label>
        )}
      </div>
      {option.blocks.map((block, bIndex) => {
        const blockAndOr = option.pattern[bIndex];
        const isBlockSplit = bIndex < blockCount;
        const conditionCount = block.conditions.length - 1;

        return (
          <React.Fragment key={bIndex}>
            <div className="pd-15 bg-white bd-3 bd-primary bd-l rounded shadow-sm">
              <ul className="criteria-list">
                {block.conditions.map((condition, cIndex) => {
                  const isConditionSplit = cIndex < conditionCount;
                  const conditionAndOr = block.pattern[cIndex];
                  const valueType = condition.bSide ? 'other' : 'enter';

                  let startDate = null;
                  let endDate = null;
                  if (condition.aSide.type == 'FIELD_TYPE_DATE') {
                    if (condition.value) {
                      if (condition.operator == 'CRITERIA_OPERATOR_BETWEEN') {
                        const splitValue = condition.value.split('|');
                        startDate = splitValue[0] ? new Date(splitValue[0]) : null;
                        endDate = splitValue[1] ? new Date(splitValue[1]) : null;
                      } else {
                        startDate = condition.value ? new Date(condition.value) : null;
                      }
                    }
                  }

                  return (
                    <li key={cIndex}>
                      <div className="d-flex flex-column pd-t-5 pd-b-10 bg-white">
                        <div className="d-flex flex-column">
                          <div className="d-flex justify-content-start align-items-center">
                            <div className="d-flex justify-content-start align-items-start">
                              <div className="d-flex flex-column">
                                <label className="wd-60 tx-10">Module</label>
                                <label className="wd-150">{MODULE[condition.aSide.module]}</label>
                              </div>
                              <div className="d-flex flex-column">
                                <label className="wd-60 tx-10">Field</label>
                                <label className="wd-150">{condition.aSide.field}</label>
                              </div>
                            </div>
                            {valueType == 'other' && (
                              <div className="d-flex justify-content-start align-items-start mg-l-15">
                                <div className="d-flex flex-column">
                                  <label className="wd-60 tx-10">Module</label>
                                  <label className="wd-150">
                                    {condition.bSide && MODULE[condition.bSide.module]}
                                  </label>
                                </div>
                                <div className="d-flex flex-column">
                                  <label className="wd-60 tx-10">Field</label>
                                  <label className="wd-150">
                                    {condition.bSide && condition.bSide.field}
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>

                          <div
                            className={classNames('d-flex align-items-start', {
                              'justify-content-start': valueType == 'enter',
                              'justify-content-center': valueType == 'other',
                            })}
                          >
                            <div className="d-flex flex-column">
                              <label className="wd-60 tx-10">Type</label>
                              <label className="wd-80">
                                {CRITERIA_FIELD_TYPE[condition.aSide.type]}
                              </label>
                            </div>
                            <div className="d-flex flex-column">
                              <label className="wd-60 tx-10">Operator</label>
                              <label className="wd-70">
                                {CRITERIA_OPERATOR[condition.operator]}
                              </label>
                            </div>
                            {valueType == 'enter' && (
                              <div className="d-flex flex-column">
                                <div className="wd-60 tx-10">Value</div>
                                {condition.aSide.type == 'FIELD_TYPE_TEXT' &&
                                  (mode == 'edit' ? (
                                    <Input
                                      type="text"
                                      className="wd-150-f mg-1"
                                      value={condition.value}
                                      onChange={(newValue: string) =>
                                        onChangeValue(bIndex, cIndex, newValue)
                                      }
                                    />
                                  ) : (
                                    `${condition.value}`
                                  ))}
                                {condition.aSide.type == 'FIELD_TYPE_DATE' && (
                                  <div className="d-flex justify-content-start">
                                    <div className="wd-150-f mg-1">
                                      {mode == 'edit' ? (
                                        <DatePicker
                                          selected={startDate}
                                          onChange={(newValue: Date) =>
                                            onChangeDate(bIndex, cIndex, 'start', newValue)
                                          }
                                          placement={'bottom-start'}
                                        />
                                      ) : (
                                        `${dayjs(startDate).format('YYYY-MM-DD')}`
                                      )}
                                    </div>
                                    {condition.operator == 'CRITERIA_OPERATOR_BETWEEN' && (
                                      <>
                                        <div className="mg-l-10 mg-r-10 mg-t-10"> ~ </div>
                                        <div className="wd-150-f mg-1">
                                          {mode == 'edit' ? (
                                            <DatePicker
                                              selected={endDate}
                                              onChange={(newValue: Date) =>
                                                onChangeDate(bIndex, cIndex, 'end', newValue)
                                              }
                                              placement={'bottom-start'}
                                            />
                                          ) : (
                                            `${dayjs(endDate).format('YYYY-MM-DD')}`
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                                {condition.aSide.type == 'FIELD_TYPE_NUMBER' &&
                                  (mode == 'edit' ? (
                                    <Input
                                      type="number"
                                      className="wd-150-f mg-1"
                                      value={condition.value}
                                      onChange={(newValue: string) =>
                                        onChangeValue(bIndex, cIndex, newValue)
                                      }
                                    />
                                  ) : (
                                    `${condition.value}`
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {isConditionSplit && conditionAndOr && (
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="dropdown mg-r-5">
                            <button
                              type="button"
                              className="btn btn-lg btn-white bg-light rounded-30"
                              data-toggle="dropdown"
                            >
                              {conditionAndOr == 'and' ? 'And' : 'Or'}
                            </button>
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            {isBlockSplit && blockAndOr && (
              <div className="add-criteria-block">
                <button
                  type="button"
                  className="btn btn-lg btn-white bg-light rounded-30"
                  data-toggle="dropdown"
                >
                  {blockAndOr == 'and' ? 'And' : 'Or'}
                </button>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default CriteriaRule;
