import React, { useState, useEffect, useRef } from 'react';
import Icons from '@base/assets/icons/svg-icons';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { confirmAlert, SpanLang } from '@base/components';
import { Trash } from 'react-feather';
import Loading from '@base/components/loading';
import classNames from 'classnames';
//import ReactToPrint from 'react-to-print';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { SURVEY_RESPONSE_DELETE } from '@settings/online-digital-content/survey/services/graphql';
import SurveyTitleView from '@settings/online-digital-content/survey/components/survey-title-view';
import { useSurveyResponses } from '@settings/online-digital-content/survey/services/list-service';

import Summary from './summary';
import Question from './question';
import Individual from './individual';
import SurveyResponse from './individual/survey-response';

interface IResponseProps {
  className?: string;
  surveyId?: string;
  surveySections?: any;
}

const Response: React.FC<IResponseProps> = (props) => {
  const { className = '', surveyId = '', surveySections } = props;
  const { t } = useTranslation();

  //state
  //const [acceptResponse, setAcceptResponse] = useState(true);
  const [responseS, setResponseS] = useState<any>([]);
  const responsesRef = useRef<any>(null);

  //mutation delete
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    SURVEY_RESPONSE_DELETE,
    'setting_deleteAnswer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Create Ticket successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during processing: ' + JSON.parse(error).message);
      },
    },
  );

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationDelete.isSuccess) {
      //reset list
      setResponseS([]);
    }
  }, [mutationDelete.isSuccess]);

  //survey responses
  const { data: surveyRData, isFetching, refetch: refetchR } = useSurveyResponses(surveyId);
  //// console.log('survey Response Data', surveyRData);

  //create questions list
  let initListQ: any = [];
  surveySections.map((section: any, secIdx: number) => {
    const newQuestions = [...section.questions];
    //set survey id, section id
    newQuestions.map((q, idx) => {
      q.id = idx; //set id for question
      q.sectionId = secIdx; //set section for question
      q.surveyId = surveyId; //set survey id
    });

    initListQ = initListQ.concat(newQuestions);
  }); //merge all questions/answer to one list

  //track changes and init
  useEffect(() => {
    if (surveyRData?.data) {
      //setResponseS(surveyRData.data || []);
      const newResponses: any[] = [];
      surveyRData.data.map((_ele: any) => {
        try {
          const sectionResp = JSON.parse(_ele.answers); //array answers of all sections
          if (sectionResp) {
            newResponses.push({ id: _ele.id, sections: sectionResp });
          }
        } catch {
          // console.log('Parse response error.');
        }
      });
      setResponseS(newResponses);
    }
  }, [surveyRData]);

  //remove all responses for current survey
  function handleRemoveAllResponses() {
    const ids = responseS.map((_ele: any) => _ele.id);
    if (ids.length > 0) {
      mutationDelete.mutate({ ids });
    }
  }

  // console.log('responseS', responseS);
  //component render
  return (
    <div className={classNames('survey-write-question-card-root', className)}>
      <div className="card">
        <div className="card-body" style={{ height: '120px' }}>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex">
              <span className="tx-22 tx-bold">
                {responseS.length} {responseS.length === 1 ? t('Single') : t('Plural')}
              </span>
            </div>
            <div className="d-flex">
              <a
                className="btn survey-home-btn-more-big"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {Icons('more_vertical')}
              </a>
              <ul className="dropdown-menu dropdown-menu-right">
                {/* <li>
                  <ReactToPrint
                    trigger={() => <a className="dropdown-item" href="javascript:void(0);"><Printer /> {t('Print all')}</a>}
                    content={() => responsesRef.current}
                    onBeforeGetContent={() => setIsLoading(true)}
                    onBeforePrint={() => setIsLoading(false)}
                  />
                </li> */}
                <li className="dropdown-item" onClick={handleRemoveAllResponses}>
                  <Trash /> <SpanLang keyLang="Remove all" />
                </li>
              </ul>
            </div>
          </div>

          {/* accepting responses option */}
          {/* <div className="d-flex flex-row justify-content-end pd-t-20">
              <span className="mg-r-10">Accepting responses</span>
              <div class="custom-control custom-switch">
                  <input
                      type="checkbox"
                      class="custom-control-input"
                      id="chk-accept-resp"
                      name="chk-accept-resp"
                      checked={acceptResponse}
                      onChange={(e) => setAcceptResponse(e.target.checked)}
                  />
                  <label class="custom-control-label" for="chk-accept-resp"></label>
              </div>
          </div> */}

          {/* response tabs */}
          <div className="pos-relative">
            <div className="pos-absolute" style={{ top: '22px', left: '32%' }}>
              <ul
                className="nav nav-line survey-write-nav-tab-no-line"
                id="response-tab"
                role="tablist"
              >
                {/* tab 1 */}
                <li className="nav-item mg-r-100">
                  <a
                    className="nav-link active"
                    id="res-summary-tab"
                    data-toggle="tab"
                    href="#res-summary"
                    role="tab"
                    aria-controls="response"
                    aria-selected="true"
                  >
                    <SpanLang keyLang="Summary" />
                  </a>
                </li>
                {/* tab 2 */}
                <li className="nav-item mg-r-100">
                  <a
                    className="nav-link"
                    id="res-question-tab"
                    data-toggle="tab"
                    href="#res-question"
                    role="tab"
                    aria-controls="response"
                    aria-selected="false"
                  >
                    <SpanLang keyLang="Question" />
                  </a>
                </li>
                {/* tab 3 */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="res-individual-tab"
                    data-toggle="tab"
                    href="#res-individual"
                    role="tab"
                    aria-controls="response"
                    aria-selected="false"
                  >
                    <SpanLang keyLang="Individual" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {isFetching && <Loading />}
      </div>

      {/* tab content */}
      <div className="tab-content" id="writeTabContent">
        {/* Summary tab */}
        <div
          className="tab-pane fade active show"
          id="res-summary"
          role="tabpanel"
          aria-labelledby="res-summary-tab"
        >
          {responseS.length > 0 && <Summary responseS={responseS} />}
        </div>
        {/* Question tab */}
        <div
          className="tab-pane fade"
          id="res-question"
          role="tabpanel"
          aria-labelledby="res-question-tab"
        >
          {responseS.length > 0 && <Question responseS={responseS} listQ={initListQ} />}
        </div>
        {/* Individual */}
        <div
          className="tab-pane fade"
          id="res-individual"
          role="tabpanel"
          aria-labelledby="res-individual-tab"
        >
          {responseS.length > 0 && (
            <Individual responseS={responseS} onSetResponseS={setResponseS} />
          )}
        </div>
      </div>

      {/* waiting */}
      {responseS.length === 0 && (
        <div className="card mg-t-15">
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <SpanLang keyLang="Waiting" />
              ...
            </div>
          </div>
        </div>
      )}

      {/* invisible - print component */}
      <div style={{ display: 'none' }}>
        <div ref={responsesRef}>
          {/* render all responses by individual */}
          {responseS.map((resp: any) => {
            return resp.sections.map((section: any, sIdx: number) => (
              <div key={sIdx} className="pd-t-15">
                {/* survey title */}
                <SurveyTitleView title={section.title} description={section.description} />
                {/* response survey content */}
                {section.questions.map((item: any, index: number) => (
                  <div key={index} className="pd-t-10">
                    <SurveyResponse
                      keyQ={index}
                      title={item.title}
                      image={item.image}
                      required={item.required}
                      selectedQType={item.type}
                      options={item.options}
                    />
                  </div>
                ))}
              </div>
            ));
          })}
        </div>
      </div>
    </div>
  );
};

export default Response;
