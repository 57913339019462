import { FilterInput } from '@base/types/interfaces/common';
import { IMenuTemplate, IPreviewTemplateDetail, ITemplateDetail } from '../types/interfaces';
import {
  GET_ALL_MENU_TEMPLATE,
  GET_MENU_TEMPLATE_DETAIL,
  GET_PREVIEW_MENU_TEMPLATE,
} from './graphql';
import { stringify } from 'query-string';
import { keyStringify } from '@base/utils/helpers';
import usePosts from '@base/hooks/usePosts';
import usePost from '@base/hooks/usePost';

export const useMenuTemplates = (filter: FilterInput, query?: string, options?: any) => {
  let queryKey = ['setting_menuTemplates', keyStringify(filter, '')];
  let params = {
    filter,
  };
  const response = usePosts<IMenuTemplate[]>(
    queryKey,
    GET_ALL_MENU_TEMPLATE(query),
    params,
    options,
  );
  return response;
};

export const useMenuTemplate = (id: String, templateGroup: String) => {
  let queryKey = ['setting_menuTemplate', templateGroup, id];
  let params = {
    id,
    templateGroup,
  };
  const response = usePost<ITemplateDetail>(queryKey, GET_MENU_TEMPLATE_DETAIL, params, {});
  return response;
};

export const usePreviewMenuTemplate = (previewJsonData: String, templateGroup: String) => {
  let queryKey = ['setting_previewMenuTemplate', templateGroup, previewJsonData];
  let params = {
    jsonData: previewJsonData,
    templateGroup,
  };
  const response = usePost<IPreviewTemplateDetail>(queryKey, GET_PREVIEW_MENU_TEMPLATE, params, {
    enabled: previewJsonData != '',
  });
  return response;
};
