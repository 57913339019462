import { DatePicker } from '@base/components/date-picker';
import { Input, RadioGroup } from '@base/components/form';
import { MODULE, MODULE_OPTIONS } from '@base/config/constant';
import SelectBox from '@base/containers/view-field/selectbox/edit';
import { OptionValue } from '@base/types/interfaces/common';
import { ModuleType } from '@base/types/types/common';
import { removeItemAtIndex, replaceItemAtIndex } from '@base/utils/helpers/array-utils';
import {
  CRITERIA_FIELD_TYPE,
  CRITERIA_OPERATOR,
  CRITERIA_OPERATOR_TYPE,
} from '@settings/process/config/constants';
import statusAtom from '@settings/process/recoil/status';
import { CriteriaOperator, CriteriaOption, FieldType } from '@settings/process/types/diagram';
import { DefinedField } from '@settings/process/types/process';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { ChevronDown, Plus, X } from 'react-feather';
import { useSetRecoilState } from 'recoil';

import { defaultCriteria } from './CriteriaWrite';

interface CriteriaRuleWriteProps {
  criteriaId: string;
  critieraName: string;
  criteriaOption: CriteriaOption;
  moduleFields: { [index: string]: DefinedField[] };
}

function CriteriaRuleWrite(props: CriteriaRuleWriteProps) {
  const { critieraName, criteriaOption, criteriaId, moduleFields } = props;
  const setStatusesValue = useSetRecoilState(statusAtom);
  const blockCount = criteriaOption.blocks.length - 1;

  const fieldOptionsFn = useCallback(
    (module: string): OptionValue[] => {
      return moduleFields[module]
        ? moduleFields[module].map((field) => {
            return {
              keyName: field.fieldName,
              languageKey: field.fieldName,
              extra: field.fieldType,
            };
          })
        : [];
    },
    [moduleFields],
  );

  const operatorOptionsFn = useCallback((type: string): OptionValue[] => {
    return CRITERIA_OPERATOR_TYPE[type]
      ? CRITERIA_OPERATOR_TYPE[type].map((field) => {
          return { keyName: field.keyName, languageKey: field.operator };
        })
      : [];
  }, []);

  const selectValueType = [
    {
      label: 'Enter a value',
      value: 'enter',
    },
    {
      label: 'Select other value',
      value: 'other',
    },
  ];

  const addCondition = useCallback(
    (separate: string, bIndex: number) => {
      let block = _.cloneDeep(criteriaOption.blocks[bIndex]);
      if (block.conditions) {
        block.conditions.push(defaultCriteria);
        block.pattern.push(separate);

        const newOption = {
          pattern: criteriaOption.pattern,
          blocks: replaceItemAtIndex(criteriaOption.blocks, bIndex, block),
        };
        changeCriteriaStatus(newOption);
      }
    },
    [criteriaOption],
  );

  const deleteCondition = useCallback(
    (bIndex: number, cIndex: number) => {
      let block = _.cloneDeep(criteriaOption.blocks[bIndex]);
      if (block.conditions) {
        const removeIndex = block.conditions.length - 1 == cIndex ? cIndex - 1 : cIndex;
        block.pattern = [
          ...block.pattern.slice(0, removeIndex),
          ...block.pattern.slice(cIndex + 1),
        ];
        block.conditions = removeItemAtIndex(block.conditions, cIndex);

        const newOption = {
          pattern: criteriaOption.pattern,
          blocks: replaceItemAtIndex(criteriaOption.blocks, bIndex, block),
        };
        changeCriteriaStatus(newOption);
      }
    },
    [criteriaOption],
  );

  const AndOrCondition = useCallback(
    (separate: string, bIndex: number, andOrIndex: number) => {
      let block = _.cloneDeep(criteriaOption.blocks[bIndex]);
      if (block.conditions) {
        block.pattern[andOrIndex] = separate;

        const newOption = {
          pattern: criteriaOption.pattern,
          blocks: replaceItemAtIndex(criteriaOption.blocks, bIndex, block),
        };
        changeCriteriaStatus(newOption);
      }
    },
    [criteriaOption],
  );

  const addBlock = useCallback(
    (separate: string) => {
      let newOption = _.cloneDeep(criteriaOption);
      newOption.blocks.push({
        conditions: [defaultCriteria],
        pattern: [],
      });
      newOption.pattern.push(separate);
      changeCriteriaStatus(newOption);
    },
    [criteriaOption],
  );

  const deleteBlock = useCallback(
    (bIndex: number) => {
      let block = _.cloneDeep(criteriaOption.blocks[bIndex]);
      if (block) {
        const removeIndex = criteriaOption.blocks.length - 1 == bIndex ? bIndex - 1 : bIndex;
        const newOption = {
          pattern: [
            ...criteriaOption.pattern.slice(0, removeIndex),
            ...criteriaOption.pattern.slice(bIndex + 1),
          ],
          blocks: removeItemAtIndex(criteriaOption.blocks, bIndex),
        };
        changeCriteriaStatus(newOption);
      }
    },
    [criteriaOption],
  );

  const andOrBlock = useCallback(
    (separate: string, andOrIndex: number) => {
      let newOption = _.cloneDeep(criteriaOption);
      newOption.pattern[andOrIndex] = separate;
      changeCriteriaStatus(newOption);
    },
    [criteriaOption],
  );

  const onChangeName = useCallback(
    (newValue: string) => {
      setStatusesValue((old) => {
        const targetIndex = old.findIndex((status) => status.id === criteriaId);
        const targetValue = { ...old[targetIndex], button: newValue };
        return replaceItemAtIndex(old, targetIndex, targetValue);
      });
    },
    [criteriaOption, criteriaId],
  );

  const deleteCriteria = useCallback(() => {
    setStatusesValue((old) => {
      const targetIndex = old.findIndex((status) => status.id === criteriaId);
      return removeItemAtIndex(old, targetIndex);
    });
  }, [criteriaOption, criteriaId]);

  const onChangeModule = useCallback(
    (bIndex: number, cIndex: number, side: string, keyName: string) => {
      let newOption = _.cloneDeep(criteriaOption);
      let condition = newOption.blocks[bIndex].conditions[cIndex];
      if (side == 'aSide') {
        condition.aSide.module = keyName as ModuleType;
      } else {
        if (!condition.bSide) {
          condition.bSide = {
            module: keyName as ModuleType,
            field: '',
            type: 'FIELD_TYPE_TEXT',
          };
        } else {
          condition.bSide.module = keyName as ModuleType;
        }
      }
      changeCriteriaStatus(newOption);
    },
    [criteriaOption],
  );

  const onChangeField = useCallback(
    (bIndex: number, cIndex: number, side: string, newValue: OptionValue) => {
      let newOption = _.cloneDeep(criteriaOption);
      let condition = newOption.blocks[bIndex].conditions[cIndex];
      if (side == 'aSide') {
        condition.aSide.field = newValue.keyName;
        condition.aSide.type = newValue.extra as FieldType;
      } else {
        if (condition.bSide) {
          condition.bSide.field = newValue.keyName;
          condition.bSide.type = newValue.extra as FieldType;
        }
      }
      condition.value = '';

      changeCriteriaStatus(newOption);
    },
    [criteriaOption],
  );

  const onChangeOperator = useCallback(
    (bIndex: number, cIndex: number, keyName: string) => {
      let newOption = _.cloneDeep(criteriaOption);
      let condition = newOption.blocks[bIndex].conditions[cIndex];
      condition.operator = keyName as CriteriaOperator;
      changeCriteriaStatus(newOption);
    },
    [criteriaOption],
  );

  // const onChangeValue = useCallback(
  //   (bIndex: number, cIndex: number, newValue: string | Date | number) => {
  //     let newOption = _.cloneDeep(criteriaOption);
  //     let condition = newOption.blocks[bIndex].conditions[cIndex];
  //     if (typeof newValue === 'number') {
  //       condition.value = newValue.toString();
  //     } else if (typeof newValue === 'string') {
  //       condition.value = newValue;
  //     } else {
  //       condition.value = newValue.toISOString();
  //     }
  //     changeCriteriaStatus(newOption);
  //   },
  //   [criteriaOption],
  // );

  const onChangeValueType = useCallback(
    (bIndex: number, cIndex: number, valueType: string) => {
      let newOption = _.cloneDeep(criteriaOption);
      let condition = newOption.blocks[bIndex].conditions[cIndex];
      if (valueType == 'other') {
        condition.bSide = {
          module: 'MODULE_NONE',
          field: 'Field',
          type: 'FIELD_TYPE_TEXT',
        };
      } else {
        delete condition.bSide;
      }
      changeCriteriaStatus(newOption);
    },
    [criteriaOption],
  );

  const onChangeValue = useCallback(
    (bIndex: number, cIndex: number, newValue: string | number) => {
      let newOption = _.cloneDeep(criteriaOption);
      let condition = newOption.blocks[bIndex].conditions[cIndex];
      if (typeof newValue === 'number') {
        condition.value = newValue.toString();
      } else {
        condition.value = newValue;
      }
      changeCriteriaStatus(newOption);
    },
    [criteriaOption],
  );

  const onChangeDate = useCallback(
    (bIndex: number, cIndex: number, range: string, newValue: Date) => {
      let newOption = _.cloneDeep(criteriaOption);
      let condition = newOption.blocks[bIndex].conditions[cIndex];
      const newDate = dayjs(newValue).format('YYYY-MM-DD');
      // console.log('newdate', newDate);
      if (condition.operator == 'CRITERIA_OPERATOR_BETWEEN') {
        let splitDate: string[] = ['', ''];
        if (condition.value) {
          splitDate = condition.value.split('|');
        }
        if (range == 'start') {
          splitDate[0] = newDate;
        } else {
          splitDate[1] = newDate;
        }
        // console.log('splitDate', splitDate);
        condition.value = splitDate.join('|');
        // console.log('condition value', condition.value);
      } else {
        condition.value = newDate;
      }
      changeCriteriaStatus(newOption);
    },
    [criteriaOption],
  );

  const changeCriteriaStatus = useCallback(
    (newOption: CriteriaOption) => {
      setStatusesValue((old) => {
        const targetIndex = old.findIndex((status) => status.id === criteriaId);
        const targetValue = { ...old[targetIndex], criteria: newOption };
        return replaceItemAtIndex(old, targetIndex, targetValue);
      });
    },
    [criteriaId],
  );

  return (
    <div className="mg-b-20">
      <div className="d-flex align-items-center mg-b-10">
        <label className="mg-r-10">Criteria Name</label>
        <Input
          className="wd-200-f"
          value={critieraName}
          onChange={(newValue: string) => onChangeName(newValue)}
        />
        <button type="button" className="btn btn-icon mg-l-auto" onClick={deleteCriteria}>
          <X className="tx-danger" />
          <span className="sr-only">삭제</span>
        </button>
      </div>
      {criteriaOption.blocks.map((block, bIndex) => {
        const blockAndOr = criteriaOption.pattern[bIndex];
        const isBlockSplit = bIndex < blockCount;
        const conditionCount = block.conditions.length - 1;

        return (
          <React.Fragment key={bIndex}>
            <div className="pd-15 bg-white bd-3 bd-primary bd-l rounded shadow-sm">
              <ul className="criteria-list">
                {block.conditions.map((condition, cIndex) => {
                  const isConditionSplit = cIndex < conditionCount;
                  const conditionAndOr = block.pattern[cIndex];
                  const fieldOptionsAside = fieldOptionsFn(condition.aSide.module);
                  const operatorOptions = operatorOptionsFn(condition.aSide.type);
                  const fieldOptionsBside = condition.bSide
                    ? fieldOptionsFn(condition.bSide.module)
                    : [];

                  const moduleValueAside = {
                    keyName: condition.aSide.module,
                    languageKey: MODULE[condition.aSide.module],
                  };
                  const fieldValueAside = {
                    keyName: condition.aSide.field,
                    languageKey: condition.aSide.field == '' ? 'Field' : condition.aSide.field,
                  };
                  const moduleValueBside = condition.bSide
                    ? {
                        keyName: condition.bSide.module,
                        languageKey: MODULE[condition.bSide.module],
                      }
                    : { keyName: '', languageKey: '' };
                  const fieldValueBside = condition.bSide
                    ? {
                        keyName: condition.bSide.field,
                        languageKey: condition.bSide.field == '' ? 'Field' : condition.bSide.field,
                      }
                    : { keyName: '', languageKey: '' };

                  const operatorValue = {
                    keyName: condition.operator,
                    languageKey: CRITERIA_OPERATOR[condition.operator],
                  };
                  const valueType = condition.bSide ? 'other' : 'enter';

                  let startDate = null;
                  let endDate = null;
                  if (condition.aSide.type == 'FIELD_TYPE_DATE') {
                    if (condition.value) {
                      if (condition.operator == 'CRITERIA_OPERATOR_BETWEEN') {
                        const splitValue = condition.value.split('|');
                        startDate = splitValue[0] ? new Date(splitValue[0]) : null;
                        endDate = splitValue[1] ? new Date(splitValue[1]) : null;
                      } else {
                        startDate = condition.value ? new Date(condition.value) : null;
                      }
                      // } else {
                      //   startDate = new Date();
                      //   endDate = plusDate(startDate, 3, 'day').toDate();
                    }
                  }

                  return (
                    <li key={cIndex}>
                      <div className="d-flex flex-column pd-t-5 pd-b-10 bg-white">
                        <div className="d-flex justify-content-start align-items-center">
                          <RadioGroup
                            value={valueType}
                            options={selectValueType}
                            onChange={(newVal) => onChangeValueType(bIndex, cIndex, newVal.value)}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex flex-column">
                            <div className="d-flex justify-content-start align-items-center">
                              <div className="d-flex justify-content-start align-items-center">
                                <SelectBox
                                  className="wd-150-f mg-1 mg-r-10"
                                  value={moduleValueAside}
                                  onChange={(newValue: OptionValue) =>
                                    onChangeModule(bIndex, cIndex, 'aSide', newValue.keyName)
                                  }
                                  options={MODULE_OPTIONS}
                                  isSearchable={false}
                                />
                                <SelectBox
                                  className="wd-150-f mg-1"
                                  value={fieldValueAside}
                                  onChange={(newValue: OptionValue) =>
                                    onChangeField(bIndex, cIndex, 'aSide', newValue)
                                  }
                                  options={fieldOptionsAside}
                                  isSearchable={false}
                                />
                              </div>
                              {valueType == 'other' && (
                                <div className="d-flex justify-content-start align-items-center mg-l-30">
                                  <SelectBox
                                    className="wd-150-f mg-1 mg-r-10"
                                    value={moduleValueBside}
                                    onChange={(newValue: OptionValue) =>
                                      onChangeModule(bIndex, cIndex, 'bSide', newValue.keyName)
                                    }
                                    options={MODULE_OPTIONS}
                                    isSearchable={false}
                                  />
                                  <SelectBox
                                    className="wd-150-f mg-1"
                                    value={fieldValueBside}
                                    onChange={(newValue: OptionValue) =>
                                      onChangeField(bIndex, cIndex, 'bSide', newValue)
                                    }
                                    options={fieldOptionsBside}
                                    isSearchable={false}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="d-flex justify-content-center align-items-center mg-t-5">
                              <div className="wd-60 mg-l-15">
                                {CRITERIA_FIELD_TYPE[condition.aSide.type]}
                              </div>
                              {valueType == 'other' && <div className="wd-60 mg-l-15">Value</div>}
                              <SelectBox
                                className="wd-150-f mg-1 mg-r-10"
                                value={operatorValue}
                                onChange={(newValue: OptionValue) =>
                                  onChangeOperator(bIndex, cIndex, newValue.keyName)
                                }
                                options={operatorOptions}
                                isSearchable={false}
                              />
                              {valueType == 'enter' && (
                                <div className="d-flex flex-column">
                                  {condition.aSide.type == 'FIELD_TYPE_TEXT' && (
                                    <Input
                                      type="text"
                                      className="wd-150-f mg-1"
                                      value={condition.value}
                                      onChange={(newValue: string) =>
                                        onChangeValue(bIndex, cIndex, newValue)
                                      }
                                    />
                                  )}
                                  {condition.aSide.type == 'FIELD_TYPE_DATE' && (
                                    <div className="d-flex justify-content-start">
                                      <div className="wd-150-f mg-1">
                                        <DatePicker
                                          selected={startDate}
                                          onChange={(newValue: Date) =>
                                            onChangeDate(bIndex, cIndex, 'start', newValue)
                                          }
                                          startDate={new Date()}
                                          placement={'bottom-start'}
                                        />
                                      </div>
                                      {condition.operator == 'CRITERIA_OPERATOR_BETWEEN' && (
                                        <>
                                          <div className="mg-l-10 mg-r-10 mg-t-10"> ~ </div>
                                          <div className="wd-150-f mg-1">
                                            <DatePicker
                                              selected={endDate}
                                              onChange={(newValue: Date) =>
                                                onChangeDate(bIndex, cIndex, 'end', newValue)
                                              }
                                              startDate={new Date()}
                                              placement={'bottom-start'}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  )}
                                  {condition.aSide.type == 'FIELD_TYPE_NUMBER' && (
                                    <Input
                                      type="number"
                                      className="wd-150-f mg-1"
                                      value={condition.value}
                                      onChange={(newValue: string) =>
                                        onChangeValue(bIndex, cIndex, newValue)
                                      }
                                    />
                                  )}
                                  {condition.aSide.type == 'FIELD_TYPE_SELECT' && (
                                    <SelectBox
                                      className="wd-150-f mg-1 mg-r-10"
                                      value={operatorValue}
                                      onChange={(newValue: OptionValue) => console.log(newValue)}
                                      options={operatorOptions}
                                      isSearchable={false}
                                    />
                                  )}
                                </div>
                              )}
                              {valueType == 'other' && <div className="wd-60 mg-l-15">Value</div>}
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-icon mg-l-auto"
                            onClick={() => deleteCondition(bIndex, cIndex)}
                          >
                            <X className="tx-danger" />
                            <span className="sr-only">삭제</span>
                          </button>
                        </div>
                      </div>

                      {isConditionSplit && conditionAndOr && (
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="dropdown mg-r-5">
                            <button
                              type="button"
                              className="btn btn-lg btn-white bg-light rounded-30"
                              data-toggle="dropdown"
                            >
                              {conditionAndOr == 'and' ? 'And' : 'Or'}
                              <ChevronDown className="tx-color-03" />
                            </button>
                            <div className="dropdown-menu">
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() => AndOrCondition('and', bIndex, cIndex)}
                              >
                                And
                              </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() => AndOrCondition('or', bIndex, cIndex)}
                              >
                                OR
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
                <li>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-white rounded-30"
                      data-toggle="dropdown"
                    >
                      <Plus className="tx-primary" />
                      <span className="mg-x-10">Add</span>
                      <ChevronDown className="tx-color-03" />
                    </button>
                    <div className="dropdown-menu">
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => addCondition('and', bIndex)}
                      >
                        And
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => addCondition('or', bIndex)}
                      >
                        OR
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {isBlockSplit && blockAndOr && (
              <div className="add-criteria-block">
                <button
                  type="button"
                  className="btn btn-lg btn-white bg-light rounded-30"
                  data-toggle="dropdown"
                >
                  {blockAndOr == 'and' ? 'And' : 'Or'}
                  <ChevronDown className="tx-color-03" />
                </button>
                <div className="dropdown-menu">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => andOrBlock('and', bIndex)}
                  >
                    And
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => andOrBlock('or', bIndex)}
                  >
                    OR
                  </button>
                  {/* <button type="button" className="dropdown-item">
                  Exclude
                </button> */}
                </div>
                <button
                  type="button"
                  className="btn btn-icon mg-l-auto"
                  onClick={() => deleteBlock(bIndex)}
                >
                  <X className="tx-danger" />
                  <span className="sr-only">삭제</span>
                </button>
              </div>
            )}
          </React.Fragment>
        );
      })}

      <div className="dropdown mg-t-15">
        <button type="button" className="btn btn-link" data-toggle="dropdown">
          <Plus className="mg-r-5" />
          Add a block
        </button>
        <div className="dropdown-menu">
          <button type="button" className="dropdown-item" onClick={() => addBlock('and')}>
            And
          </button>
          <button type="button" className="dropdown-item" onClick={() => addBlock('or')}>
            OR
          </button>
          {/* <button type="button" className="dropdown-item">
            Exclude
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default CriteriaRuleWrite;
