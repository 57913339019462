// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-assign-to-add{z-index:99999}.assign-to-view{display:block}", "",{"version":3,"sources":["webpack://./src/settings/assignment-rule/assets/scss/style.scss"],"names":[],"mappings":"AAAA,qBACE,aAAA,CAEF,gBACE,aAAA","sourcesContent":[".modal-assign-to-add {\n  z-index: 99999;\n}\n.assign-to-view {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-assign-to-add": "modal-assign-to-add",
	"assign-to-view": "assign-to-view"
};
export default ___CSS_LOADER_EXPORT___;
