import { useCallback, useState } from 'react';
import { Edit2, Plus, X } from 'react-feather';
import { Stage } from '@settings/process/types/diagram';
import classNames from 'classnames';
import { useStageOpenAtomState } from '@settings/process/recoil/diagram';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query'; //v4
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { DELETE_PROCESS_STAGE } from '@settings/process/services/process';

interface StageItemProps {
  processId: string;
  stage: Stage;
  className: string;
}

function StageItem(props: StageItemProps) {
  const [showEdit, setShowEdit] = useState(false);
  const { stage, className, processId } = props;
  const [, setStageOpen] = useStageOpenAtomState();

  const onStageAdd = useCallback(() => {
    setStageOpen({
      sourceId: stage.id,
      stage: null,
    });
  }, [stage]);

  const onStageEdit = useCallback(() => {
    setStageOpen({
      sourceId: stage.id,
      stage: stage,
    });
  }, [stage]);

  const queryClient = useQueryClient();
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_PROCESS_STAGE,
    'process_deleteStage',
    {
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Delete Stage successfully!');

        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', processId]);
        }, 500);
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Delete Stage failed');
      },
    },
  );

  const onDelete = useCallback(
    (stageId: string) => {
      if (confirm('Do you want to delete this stage?')) {
        mutationDelete.mutate({ id: processId, stageId: stageId });
      }
    },
    [stage],
  );

  return (
    <div className={classNames('stage-item', className)} onClick={() => setShowEdit((cur) => !cur)}>
      <div>
        <div className="text-truncate">{stage.name}</div>
        {showEdit && (
          <>
            <div className="stage-actions">
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon"
                onClick={() => onStageEdit()}
              >
                <Edit2 />
                <span className="sr-only">Edit</span>
              </button>
              <button
                type="button"
                className="btn btn-xs btn-link link-03 btn-icon"
                data-han-tooltip="Delete"
                onClick={() => onDelete(stage.id)}
              >
                <X />
              </button>
              {stage.property !== 'PROPERTY_CLOSE' && (
                <button
                  type="button"
                  className="mg-l-10 btn btn-xs btn-outline-primary btn-icon btn-add-stage"
                  onClick={() => onStageAdd()}
                >
                  <Plus />
                  <span className="sr-only">Add</span>
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(StageItem);
