import Icon from '@base/assets/icons/svg-icons';
import { Button } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import { generateUUID, moneyFormat } from '@base/utils/helpers';
import { NAVS } from '@settings/billing-license/config/constant';
import { useListLicensingGuide } from '@settings/billing-license/services';
import { ILicense } from '@settings/billing-license/types/interfaces/license';
import React, { useEffect, useState } from 'react';
import LicensePurchased from '../purchased-views/license-purchased';
import StoragePurchased from '../purchased-views/storage-purchased';
import UserPurchased from '../purchased-views/user-purchased';

type Data = {
  productName: string;
  itemName: string | null;
  description: string;
  unitPrice: string | number;
  unit: string;
  billing: string;
  action: string;
};

const LicenseGuide: React.FC = () => {
  const [navState, setNavState] = useState('allItems');
  const { data, refetch, isLoading } = useListLicensingGuide('');
  const [dataTable, setDataTable] = useState<ILicense[]>([]);
  const [modalOpen, setModalOpen] = useState<string>('');
  useEffect(() => {
    if (data && data.data) {
      setDataTable(data.data);
    }
  }, [data]);
  const onOpenModal = (action: string) => {
    setModalOpen(action);
  };
  const navs = NAVS;

  // const newData: Array<Data> = [];
  // data?.data.forEach((licenseObj) => {
  //   licenseObj.listItem.forEach((item) => {
  //     newData.push({
  //       productName: licenseObj.name,
  //       itemName: item.name,
  //       description: item.description,
  //       unitPrice: item.unitPrice,
  //       unit: item.unit,
  //       billing: item.billing,
  //       action: item.action,
  //     });
  //   });
  // });
  // const dataTable = React.useMemo(() => newData, []);
  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: 'Product',
  //       accessor: (row: any) => {
  //         return row.productName;
  //       },
  //       enableRowSpan: true,
  //     },
  //     {
  //       Header: 'Item',
  //       accessor: (row: any) => {
  //         return row.itemName !== null ? row.itemName : <></>;
  //       },
  //     },
  //     {
  //       Header: 'Description',
  //       accessor: 'description',
  //     },
  //     {
  //       Header: 'Unit Price',
  //       accessor: 'unitPrice',
  //     },
  //     {
  //       Header: 'Unit',
  //       accessor: 'unit',
  //     },
  //     {
  //       Header: 'Billing',
  //       accessor: 'billing',
  //     },
  //     {
  //       Header: 'Purchasing',
  //       accessor: (row: any) => (
  //         <Button
  //           outline={true}
  //           color="primary"
  //           onClick={() => {
  //             onOpenModal(row.action);
  //           }}
  //         >
  //           {Icon('cart_plus')}
  //         </Button>
  //       ),
  //     },
  //   ],
  //   [dataTable],
  // );
  // console.log(data?.data);
  return (
    <>
      <div className="card">
        <div className="card-header bg-light">
          <h2 className="card-title h6 mg-b-0">Licensing Guide</h2>
        </div>
        <nav className="nav nav-line bd-1">
          {navs.map((_item: any, index: number) => {
            return (
              <a
                href={'#' + _item.value}
                className={_item.value == navState ? 'nav-link pd-15-f active' : 'nav-link pd-15-f'}
                data-toggle="tab"
                onClick={() => setNavState(_item.value)}
              >
                {_item.label}
              </a>
            );
          })}
        </nav>
        {/* <BasicTable columns={columns} data={dataTable} /> */}
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th scope="col">Product</th>
                <th scope="col">Item</th>
                <th scope="col">Description</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Unit</th>
                <th scope="col">Billing</th>
                <th scope="col">Purchasing</th>
              </tr>
            </thead>
            <tbody>
              {dataTable?.map((_itemProduct: any, indexProduct: number) => {
                return _itemProduct.listItem.map((_item: any, index: number) => {
                  return (
                    <tr key={index}>
                      {index == 0 ? (
                        <td
                          rowSpan={_itemProduct.listItem.length}
                          colSpan={_item.name === null ? 2 : 1}
                          className="align-middle"
                        >
                          {_itemProduct.name}
                        </td>
                      ) : (
                        <></>
                      )}
                      {_item.name === null ? <></> : <td> {_item.name}</td>}
                      <td>{_item.description}</td>
                      <td>{_item.unitPrice != 'Free' ? moneyFormat(_item.unitPrice) : 'Free'}</td>
                      <td>{_item.unit}</td>
                      <td>{_item.billing}</td>
                      <td>
                        <Button
                          outline={true}
                          color="primary"
                          onClick={() => {
                            onOpenModal(_item.action);
                          }}
                        >
                          {Icon('cart_plus')}
                        </Button>
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        </div>
      </div>
      <StoragePurchased
        isVisible={modalOpen == 'addStoragePurchasing'}
        onClose={() => setModalOpen('')}
        onSubmit={() => console.log('submit')}
      />
      <UserPurchased
        isVisible={modalOpen == 'addUserPurchasing'}
        onClose={() => setModalOpen('')}
        onSubmit={() => console.log('submit')}
      />
      <LicensePurchased
        isVisible={modalOpen == 'licensePurchasing'}
        onClose={() => setModalOpen('')}
        onSubmit={() => console.log('submit')}
      />
    </>
  );
};

export default LicenseGuide;
