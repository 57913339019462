import React, { useMemo } from 'react';
import { RadioGroup } from '@base/components/form';
import { LabelValue } from '@base/types/types/app';
import { useStepSettingAtomValue } from '@settings/process/recoil/process';
import { MethodOptions } from './MethodViewField';

interface MethodWriteProps {
  value: string;
  onChange: (val: string) => void;
}

function MethodWrite(props: MethodWriteProps) {
  const { onChange, value } = props;
  const stepSetting = useStepSettingAtomValue();
  const selectedValue = useMemo(() => {
    return MethodOptions.find((_ele: LabelValue) => _ele.value === value)!!;
  }, [value]);

  const methodOptions = useMemo(() => {
    if (stepSetting.method == 'ACTION_METHOD_MANUAL') {
      return [MethodOptions[0]];
    } else if (stepSetting.method == 'ACTION_METHOD_AUTO') {
      return [MethodOptions[1]];
    } else {
      return MethodOptions;
    }
  }, [stepSetting]);

  const handleValueChange = (newOption: LabelValue) => {
    onChange && onChange(newOption.value as string);
  };

  return (
    <RadioGroup
      isVertical={false}
      options={methodOptions}
      value={selectedValue}
      onChange={handleValueChange}
    />
  );
}

export default MethodWrite;
