import Button from '@base/components/form/button';
import { Field } from '@base/components/hook-form';
import NoteAndError from '@base/components/hook-form/error-note';
import { ViewFields } from '@base/components/hook-form/view';
import {
  MENU_PROCESS_SETTING_ACTION,
  MENU_PROCESS_SETTING_CRITERIA,
  MENU_PROCESS_SETTING_TRIGGER,
} from '@base/config/menus';
import withMiModal from '@base/hooks/hocs/withMiModal';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { ViewFieldParse, WriteParseFields } from '@base/utils/helpers/no-layout-utils';
import ActionStatus from '@settings/process/components/action-status/ActionStatus';
import CriteriaWrite from '@settings/process/components/criteria/CriteriaWrite';
import { initStatusForm } from '@settings/process/components/diagram/status/StatusWrite';
import TriggerWrite from '@settings/process/components/trigger/TriggerWrite';
import {
  KEY_NAME_SETTING_DESCRIPTION,
  KEY_NAME_SETTING_NAME,
} from '@settings/process/config/keyNames';
import ActionViewField from '@settings/process/config/view-field/action';
import { SUBTABS } from '@settings/process/containers/list/Tabs';
import { useTriggerFormAtomValue } from '@settings/process/recoil/process';
import statusAtom from '@settings/process/recoil/status';
import { CREATE_DEFINED_ITEM, UPDATE_DEFINED_ITEM } from '@settings/process/services/custom';
import { DefinedItem } from '@settings/process/types/process';
import { SettingType } from '@settings/process/types/settings';
import { checkSequence } from '@settings/process/utils/helper';
import _ from 'lodash';
import { Suspense, useMemo, useState } from 'react';
import { ChevronUp } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

interface SettingWriteProps {
  settingType: SettingType;
  fullScreen: boolean;
  definedItem?: DefinedItem;
  onClose: () => void;
  onReload?: () => void;
}

function SettingWrite(props: SettingWriteProps) {
  const { fullScreen, onClose, onReload, definedItem, settingType } = props;
  const [isReset, setIsReset] = useState(false);
  const [statusesValue, setStatusesValue] = useRecoilState(statusAtom);
  const triggerForm = useTriggerFormAtomValue();

  const initValue = useMemo(() => {
    if (definedItem) {
      let commonField: any = {
        [KEY_NAME_SETTING_NAME]: definedItem.name,
        [KEY_NAME_SETTING_DESCRIPTION]: definedItem.description,
      };
      return commonField;
    }
    return {};
  }, [definedItem]);

  const writeParse = useMemo(() => {
    if (settingType == 'action') {
      return MENU_PROCESS_SETTING_ACTION;
    } else if (settingType == 'criteria') {
      return MENU_PROCESS_SETTING_CRITERIA;
    } else if (settingType == 'trigger') {
      return MENU_PROCESS_SETTING_TRIGGER;
    }
    return '';
  }, [settingType]);

  const { fields, getParams, defaultValues } = useMemo(
    () => WriteParseFields(writeParse, initValue),
    [initValue, writeParse],
  );

  const fields2 = useMemo(() => {
    return ViewFieldParse(initValue, ActionViewField, false);
  }, [initValue]);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_DEFINED_ITEM,
    'process_createDefinedItem',
    {
      onSuccess: (data: any) => {
        setTimeout(() => {
          onReload && onReload();
        }, 500);
        // console.log('success data', data);
        toast.success('Save action successfully!');
        if (isReset) {
          reset();
          if (settingType != 'trigger') {
            setStatusesValue(initStatusForm.slice(0, 2));
          }
        } else {
          onClose && onClose();
        }
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.warn('Save action failed');
      },
    },
  );

  const mutationUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_DEFINED_ITEM,
    'process_updateDefinedItem',
    {
      onSuccess: (data: any) => {
        setTimeout(() => {
          onReload && onReload();
        }, 500);
        // console.log('success data', data);
        toast.success('Update action successfully!');
        onClose && onClose();
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.warn('Update action failed');
      },
    },
  );

  const onSubmit = (formData: any) => {
    const params = getParams(formData);
    let data = '';
    let shape = 'SHAPE_NONE';
    if (settingType == 'trigger') {
      data = JSON.stringify({
        trigger: triggerForm.trigger.keyName,
        module: triggerForm.module.keyName,
        field: triggerForm.field.keyName,
        ftype: triggerForm.field.extra,
        ptype: triggerForm.ptype.keyName,
        process: triggerForm.process.keyName,
        step: triggerForm.step.keyName,
        property: triggerForm.property.keyName,
      });
    } else {
      shape = 'SHAPE_FORWARD';
      let allSequence: string[] = [];
      let statuses = [];
      // So it has to set multiple : true
      // if forward is over 2 in criteria, That is multiple choice.
      const statusCount = statusesValue.length;
      for (const idx in statusesValue) {
        const st = statusesValue[idx];
        let options = '';
        let multiple = _.clone(st.multiple);
        let primary = _.clone(st.primary);
        let sequence = _.clone(st.sequence);
        if (settingType == 'action') {
          if (st.sequence.length > 0) {
            allSequence = _.concat(allSequence, st.sequence);
          }
        } else if (settingType == 'criteria') {
          const index = Number(idx);
          if (statusCount > 2 && statusCount - 1 > index) {
            multiple = 'MULTIPLE_CHOICE';
            if (index == 0) {
              primary = true;
            }
          }
          sequence = [String(index + 1)];
          options = st.criteria ? JSON.stringify(st.criteria) : '';
        }
        statuses.push({
          id: st.id,
          button: st.button,
          name: st.name,
          direction: st.direction.keyName,
          property: st.property.keyName,
          view: st.view.keyName,
          event: st.event.keyName,
          sequence: sequence,
          multiple: multiple,
          primary: primary,
          options: options,
        });
      }
      if (allSequence.length > 0 && !checkSequence(_.sortBy(_.uniq(allSequence)))) {
        alert('Sequence Number has to be order');
        return;
      }
      data = JSON.stringify(statuses);
    }

    const stepType = SUBTABS.find((tab) => tab.value == settingType)!!;
    let addData: any = {
      id: definedItem?.id,
      name: params.name,
      description: params.description,
      type: stepType.type,
      shape: shape,
      data: data,
    };

    if (stepType.type == 'TYPE_ACTION') {
      addData.setting = {
        method: 'ACTION_METHOD_MANUAL',
        template: false,
        cta: false,
        auto: false,
        email: true,
        due: true,
        assign: true,
      };
    }

    // console.log(addData);
    if (addData.id) {
      mutationUpdate.mutate({ definedItem: addData });
    } else {
      mutationAdd.mutate({ definedItem: addData });
    }
  };

  //render footer
  const renderFooter = (fixed: boolean | undefined) => {
    return (
      <div className="d-flex pd-15 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          {!fixed && (
            <div className="btn-group dropup">
              <Button
                color="success"
                onClick={() => {
                  setIsReset(false);
                  handleSubmit((data) => onSubmit(data))();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                name="Save"
              />
              <button
                type="button"
                className="btn btn-success dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <Button
                  className="dropdown-item"
                  color="primary"
                  onClick={() => {
                    setIsReset(true);
                    handleSubmit((data) => onSubmit(data))();
                  }}
                  disabled={mutationAdd.isLoading || !isValid}
                  loading={mutationAdd.isLoading}
                  name="Save and Create New"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const formHeight = fullScreen ? 'calc(100vh - 160px)' : 'calc(100vh - 300px)';

  return (
    <>
      <div className="bd-b pd-10">
        <NoteAndError errors={errors} />
      </div>
      <div
        className="pos-relative pd-t-10 scroll-box"
        style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <div className="pd-15 form-row">
          {definedItem?.fixed ? (
            <ViewFields
              menuSource={'settingWrite'}
              menuSourceId={definedItem?.id}
              fields={fields2}
            />
          ) : (
            fields.map((_item) => {
              return (
                <Suspense key={_item.keyName}>
                  <Field
                    item={{
                      ..._item,
                      // componentProps: _item.componentProps,
                      columns: _item.columns,
                      // hideTitle: _item.hideTitle, //hide title for a specific key name
                    }}
                    control={control}
                    errors={errors}
                  />
                </Suspense>
              );
            })
          )}
          {settingType == 'action' && (
            <ActionStatus statusData={definedItem?.statuses} fixed={definedItem?.fixed} />
          )}
          {settingType == 'criteria' && <CriteriaWrite statusData={definedItem?.statuses} />}
          {settingType == 'trigger' && <TriggerWrite triggerData={definedItem?.trigger} />}
        </div>
      </div>
      {renderFooter(definedItem?.fixed)}
    </>
  );
}

export default withMiModal(SettingWrite);
