import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLanguageByMenu } from '@base/services/i18n';
import { Helmet } from 'react-helmet-async';
import {
  SettingListPage,
  BusinessListPage,
  AutomationListPage,
} from '@settings/process/pages/list';
import BusinessViewPage from '@settings/process/pages/view/ViewPage';

const ProcessFirstPage = () => {
  // console.log('business first page');

  // load menu language
  useLanguageByMenu(['setting_process']);

  return (
    <>
      <Helmet>
        <title>Hanbiro CRM - Process</title>
      </Helmet>
      <Routes>
        <Route path="business" element={<BusinessListPage />} />
        <Route path="business/:id" element={<BusinessViewPage />} />
        <Route path="setting" element={<SettingListPage />} />
        <Route path="automation" element={<AutomationListPage />} />
        <Route path="/" element={<Navigate replace to="/settings/process/business" />} />
      </Routes>
    </>
  );
};

export default ProcessFirstPage;
