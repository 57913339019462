import React, { useEffect, useState } from 'react';
import ActivityEmailTerm from '@settings/preferences/containers/activity-email-term';
import ActivitySMSTerm from '@settings/preferences/containers/activity-sms-term';
import { useMenuSettings, useSelectionFields } from '@settings/general-setting/services/format-service';
import Loading from '@base/components/loading';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@settings/preferences/config/display-layout';
import { useRecoilState } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms';
import ActivityPriority from '@settings/preferences/containers/activity-priority';
import ActivityPurpose from '@settings/preferences/containers/activity-purpose';
import ActivityCallResult from '@settings/preferences/containers/activity-call-result';

const getTabs = ({ emailValue, domainValue, phoneValue, mobileValue, priorityValue, purposeValue, callResultValue }: any) => {
  return [
    {
      default: true,
      label: 'Email',
      path: 'email',
      order: 1,
      tabComponent: ActivityEmailTerm,
      tabComponentProps: {
        emailData: emailValue,
        domainData: domainValue,
      },
      show: true,
    },
    {
      default: false,
      label: 'SMS',
      path: 'sms',
      order: 2,
      tabComponent: ActivitySMSTerm,
      tabComponentProps: {
        phoneData: phoneValue,
        mobileData: mobileValue,
      },
      show: true,
    },
    {
      default: false,
      label: 'Priority',
      path: 'priority',
      order: 3,
      tabComponent: ActivityPriority,
      tabComponentProps: {
        priorityData: priorityValue,
      },
      show: true,
    },
    {
      default: false,
      label: 'Purpose',
      path: 'purpose',
      order: 4,
      tabComponent: ActivityPurpose,
      tabComponentProps: {
        purposeData: purposeValue,
      },
      show: true,
    },
    {
      default: false,
      label: 'Call Result',
      path: 'call-result',
      order: 5,
      tabComponent: ActivityCallResult,
      tabComponentProps: {
        resultData: callResultValue,
      },
      show: true,
    },
  ];
};

const ActivitySettings: React.FC<any> = () => {
  //state
  const [emailValue, setEmailValue] = useState<any>([]);
  const [domainValue, setDomainValue] = useState<any>([]);
  const [phoneValue, setPhoneValue] = useState<any>([]);
  const [mobileValue, setMobileValue] = useState<any>([]);
  const [priorityValue, setPriorityValue] = useState<any>([]);
  const [purposeValue, setPurposeValue] = useState<any>([]);
  const [callResultValue, setCallResultValue] = useState<any>([]);
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);

  //get
  const params = {
    keys: ['emails', 'domains', 'company_phones', 'personal_phones'],
    menus: ['activity', 'desk'],
  };
  const { data: postResult, isLoading } = useMenuSettings(params);
  //console.log('setting data', postResult);
  const { data: priorityPost, isLoading: isPriorityLoading } = useSelectionFields({ filter: { query: 'keyRoot=priority' } });
  //console.log('setting priorityPost', priorityPost);
  const { data: purposePost, isLoading: isPurposeLoading } = useSelectionFields({ filter: { query: 'keyRoot=activity_purpose' } });
  const { data: callResultPost, isLoading: isCallResultLoading } = useSelectionFields({ filter: { query: 'keyRoot=activity_call_result' } });

  //init value
  useEffect(() => {
    if (postResult?.data) {
      const newEmailValue = postResult.data.find((_ele: any) => _ele.key === 'emails');
      if (newEmailValue) {
        try {
          setEmailValue(JSON.parse(newEmailValue.value));
        } catch {
          // console.log('Parse error.');
        }
      }
      const newDomainValue = postResult.data.find((_ele: any) => _ele.key === 'domains');
      if (newDomainValue) {
        try {
          setDomainValue(JSON.parse(newDomainValue.value));
        } catch {
          // console.log('Parse error.');
        }
      }
      const newPhoneValue = postResult.data.find((_ele: any) => _ele.key === 'company_phones');
      if (newPhoneValue) {
        try {
          setPhoneValue(JSON.parse(newPhoneValue.value));
        } catch {
          // console.log('Parse error.');
        }
      }
      const newMobileValue = postResult.data.find((_ele: any) => _ele.key === 'personal_phones');
      if (newMobileValue) {
        try {
          setMobileValue(JSON.parse(newMobileValue.value));
        } catch {
          // console.log('Parse error.');
        }
      }
    }
  }, [postResult]);

  useEffect(() => {
    if (priorityPost?.data) {
      setPriorityValue(priorityPost.data);
    }
  }, [priorityPost]);

  useEffect(() => {
    if (purposePost?.data) {
      setPurposeValue(purposePost.data);
    }
  }, [purposePost]);

  useEffect(() => {
    if (callResultPost?.data) {
      setCallResultValue(callResultPost.data);
    }
  }, [callResultPost]);

  const viewProps = {
    tabs: getTabs({ emailValue, domainValue, phoneValue, mobileValue, priorityValue, purposeValue, callResultValue }),
  };

  return (
    <>
      {isLoading && <Loading />}
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={device}
        layout={layout}
        form="view"
        {...viewProps}
      />
    </>
  );
};

export default ActivitySettings;
