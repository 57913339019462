import React from 'react';
import _ from 'lodash';
import ItemWrite from './StatusItemWrite';
import ItemView from './StatusItemView';
import StatusList from './StatusList';
import { useStepSettingAtomValue } from '@settings/process/recoil/process';
import { BusinessStatus } from '@settings/process/types/process';
import SiteButtons from '@settings/process/components/diagram/site/SiteButtons';
import { useRecoilValue } from 'recoil';
import { selectedStatusAtom, statusWithFilter } from '@settings/process/recoil/status';
import stepTypeAtom from '../../../recoil/step';

interface StatusViewProps {
  metadata: any;
  value: BusinessStatus[];
}

function StatusView({ metadata, value }: StatusViewProps) {
  // console.log('status view');
  const { processId, stepId, method } = metadata;
  const selectedStatus = useRecoilValue(selectedStatusAtom);
  const stepSetting = useStepSettingAtomValue();
  const stepType = useRecoilValue(stepTypeAtom);
  const normalStatuses = useRecoilValue(statusWithFilter('normal'));
  const ctaStatuses = useRecoilValue(statusWithFilter('cta'));

  let normalParallelIndex = 0;
  let ctaParallelIndex = 0;

  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Buttons/Status</label>
        <StatusList isCta={false} actionMethod={method}>
          {normalStatuses?.map((status) => {
            const isEdit = status.id == selectedStatus ? true : false;
            if (
              status.direction.keyName == 'DIRECTION_FORWARD_OUTGOING_FORWARD' &&
              status.multiple == 'MULTIPLE_PARALLEL'
            ) {
              normalParallelIndex++;
            }
            return (
              <React.Fragment key={status.id}>
                {isEdit ? (
                  <ItemWrite
                    processId={processId}
                    stepId={stepId}
                    status={status}
                    parallelIndex={normalParallelIndex}
                    isView={true}
                    isCta={false}
                    // actionMethod={method}
                  />
                ) : (
                  <ItemView
                    processId={processId}
                    stepId={stepId}
                    status={status}
                    parallelIndex={normalParallelIndex}
                    isCta={false}
                    actionMethod={method}
                  />
                )}
              </React.Fragment>
            );
          })}
        </StatusList>
      </div>
      {(stepSetting.cta || stepType.value == 'TYPE_SITE') && (
        <>
          <div className="form-group">
            <label className="form-item-title">CTA</label>
            <StatusList isCta={true}>
              {ctaStatuses?.map((status) => {
                const isEdit = status.id == selectedStatus ? true : false;
                if (
                  status.direction.keyName == 'DIRECTION_FORWARD_OUTGOING_FORWARD' &&
                  status.multiple == 'MULTIPLE_PARALLEL'
                ) {
                  ctaParallelIndex++;
                }
                return (
                  <React.Fragment key={status.id}>
                    {isEdit ? (
                      <ItemWrite
                        processId={processId}
                        stepId={stepId}
                        status={status}
                        parallelIndex={ctaParallelIndex}
                        isView={true}
                        isCta={true}
                      />
                    ) : (
                      <ItemView
                        processId={processId}
                        stepId={stepId}
                        status={status}
                        parallelIndex={ctaParallelIndex}
                        isCta={true}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </StatusList>
          </div>
          {stepType.value == 'TYPE_SITE' && <SiteButtons mode={'view'} />}
        </>
      )}
    </>
  );
}

export default StatusView;
