import Icon from '@base/assets/icons/svg-icons';
import { Button } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import classNames from 'classnames';
import React from 'react';
interface IPurchasedTable {
  columns: any[];
  purchasedItem: IPurchasedItem[];
  listType: {
    isPeriod: boolean;
  };
  onChange: (keyName: string, keyValue: boolean) => any;
}
const PurchasedTable: React.FC<IPurchasedTable> = (props: IPurchasedTable) => {
  const { columns, purchasedItem, listType, onChange } = props;
  return (
    <div className="card">
      <div className="card-header d-flex align-items-center bg-light">
        <h2 className="card-title h6 mg-b-0">Payment Schedule per a Item Purchased</h2>
        <div className="btn-group mg-l-auto">
          <Button
            color="white"
            className={classNames('btn-icon', { active: listType.isPeriod })}
            onClick={() => onChange('isPeriod', true)}
          >
            {Icon('bar_chart')}
          </Button>
          <Button
            color="white"
            className={classNames('btn-icon', { active: !listType.isPeriod })}
            onClick={() => onChange('isPeriod', false)}
          >
            {Icon('table')}
          </Button>
        </div>
      </div>
      {listType.isPeriod && (
        <div className="table-responsive">
          <table className="table table-bordered mg-b-0 bd-0 settings-tb">
            <thead>
              <tr>
                <th rowSpan={2} scope="col" className="align-middle">
                  Item
                </th>
                <th colSpan={2} scope="colgroup" className="pd-y-10-f bd-1-f text-center">
                  Billing Period
                </th>
              </tr>
              <tr>
                <th scope="col" className="pd-y-10-f text-center">
                  2020
                </th>
                <th scope="col" className="pd-y-10-f text-center">
                  2021
                </th>
              </tr>
            </thead>
            <tbody>
              {purchasedItem?.map((_item: any, index: number) => {
                if (_item.startDate.getFullYear() == 2020 && _item.endDate.getFullYear() == 2021)
                  return (
                    <tr key={index}>
                      <td>{_item.name}</td>
                      <td>{_item.startDate.toLocaleDateString()}</td>
                      <td>{_item.endDate.toLocaleDateString()}</td>
                    </tr>
                  );
              })}
            </tbody>
          </table>
        </div>
      )}

      {!listType.isPeriod && (
        <div className="table-responsive">
          <BasicTable
            columns={columns}
            data={purchasedItem || []}
            className="table table-bordered mg-b-0 bd-0 settings-tb border-top-0"
          ></BasicTable>
        </div>
      )}

      <div className="card-footer">
        <Button outline={true} color="primary" className="mg-r-5">
          Edit
        </Button>
        <Button color="primary">Save</Button>
      </div>
    </div>
  );
};
export default PurchasedTable;
