import { lazy } from 'react';

const DeskMain = lazy(() => import('./main'));

export default [
  {
    path: '/settings/desk/*',
    component: DeskMain,
  },
];
