import { EARAssignToType, EAssignmentRuleModule, EAssignmentRuleCriteria } from '../types/enums';

export const AR_MODULE_OPTIONS = [
  /*{
    keyName: EAssignmentRuleModule.SALES,
    languageKey: 'Sales',
  },*/
  {
    keyName: EAssignmentRuleModule.CUSTOMER,
    languageKey: 'Customer',
  },
  {
    keyName: EAssignmentRuleModule.DESK,
    languageKey: 'Desk',
  },
  {
    keyName: EAssignmentRuleModule.PRODUCT,
    languageKey: 'Product',
  },
];

export const AR_CRITERIA_OPTIONS_LANG: any = {
  product: 'Product',
  channel: 'Channel',
  region: 'Region',
  language: 'Language',
  category: 'Category',
  tag: 'Tag',
  ticket_classification: 'Ticket Classification',
};

export const AR_ASSIGNTO_TYPE_OPTIONS = [
  {
    keyName: EARAssignToType.USERS,
    languageKey: 'Users',
  },
  {
    keyName: EARAssignToType.GROUPS,
    languageKey: 'Groups',
  },
];

export const AR_CRITERIA_OPTIONS_CHANNEL = [
  {
    keyName: 'channel_01',
    languageKey: 'Channel 01',
  },
  {
    keyName: 'channel_02',
    languageKey: 'Channel 02',
  },
  {
    keyName: 'channel_03',
    languageKey: 'Channel 03',
  },
];
export const AR_CRITERIA_OPTIONS_TAG = [
  {
    keyName: 'tag_error',
    languageKey: 'Error',
  },
  {
    keyName: 'tag_qa',
    languageKey: 'Q&A',
  },
  {
    keyName: 'tag_crm',
    languageKey: 'CRM',
  },
  {
    keyName: 'tag_others',
    languageKey: 'Others',
  },
];
export const AR_CRITERIA_OPTIONS_TICKET_CLASSI = [
  {
    keyName: 'Language',
    languageKey: 'Language',
  },
  {
    keyName: 'region',
    languageKey: 'Region',
  },
];
export const AR_CRITERIA_OPTIONS_CATEGORY = [
  {
    keyName: 'error',
    languageKey: 'Error',
  },
  {
    keyName: 'others',
    languageKey: 'Others',
  },
];
export const AR_CRITERIA_CONDITION: any = {
  channel: AR_CRITERIA_OPTIONS_CHANNEL,
  tag: AR_CRITERIA_OPTIONS_TAG,
  ticket_classification: AR_CRITERIA_OPTIONS_TICKET_CLASSI,
  category: AR_CRITERIA_OPTIONS_CATEGORY,
};
