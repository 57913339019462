import classnames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { Edge, Node } from '@settings/process/types/diagram';
import {
  useDiagramDetailAtomState,
  useShowStepActionAtomState,
} from '@settings/process/recoil/diagram';
import { Checkbox } from '@base/components/form';
import NodeEdit from './NodeEdit';
import classNames from 'classnames';
import EdgeAdd from '@settings/process/components/diagram/edge/EdgeAdd';
import { Checklist } from '@settings/process/types/process';

interface NodeChecklistProps {
  processId: string;
  node: Node;
  stepHelper: any;
}

function NodeChecklist(props: NodeChecklistProps) {
  const {
    node: {
      id: sourceId,
      data: { label },
      type: nodeType,
      position,
      className,
      property,
      shape,
      edges,
    },
    processId,
    stepHelper,
  } = props;

  const [showStepAction, setStepShowAction] = useShowStepActionAtomState();
  const [showDetail, setShowDetail] = useDiagramDetailAtomState();

  const onShowAction = useCallback(() => {
    setStepShowAction((old) => {
      return { id: sourceId, show: !old.show };
    });
  }, []);

  useEffect(() => {
    if (showStepAction.show) {
      setShowDetail(true);
    }
  }, [showStepAction]);

  const matchShow = useMemo(() => {
    if (showStepAction.id == sourceId && showStepAction.show) {
      return true;
    } else {
      return false;
    }
  }, [showStepAction, sourceId]);

  const checklist = useMemo<Checklist[]>(() => {
    const edge = edges?.find((edge) => edge.property == 'PROPERTY_TODO_CLOSE');
    if (edge && edge.options) {
      return JSON.parse(edge.options);
    }
    return [];
  }, [edges]);

  return (
    <>
      {(shape == 'SHAPE_FORWARD' || (shape == 'SHAPE_BACKWARD' && showDetail)) && (
        <div
          className={classnames('diagram-item-wrap', {
            active: matchShow,
          })}
          style={{ top: `${position.y}px`, left: `${position.x}px` }}
        >
          <div className={classNames('diagram-item diagram-checklist', className)}>
            <div className="diagram-item-name" onClick={onShowAction}>
              {label}
            </div>
            <div className="checklist-no">
              Checklist:
              {checklist && (
                <>
                  <button type="button" className="btn" data-toggle="dropdown">
                    {checklist.length}
                  </button>
                  <div className="dropdown-menu dropdown-menu-right diagram-item-status-detail">
                    {checklist.map((ck) => (
                      <Checkbox key={ck.id} label={ck.name} />
                    ))}
                  </div>
                </>
              )}
            </div>
            {matchShow && (
              <NodeEdit
                processId={processId}
                property={property}
                sourceId={sourceId}
                position={position}
                sourceType={nodeType}
                shape={shape}
              />
            )}
          </div>
          {edges?.map((edge: Edge) => {
            let styles: any = {};
            if (edge.sourceDirection == 'DIRECTION_FORWARD_OUTGOING_FORWARD') {
              styles = edge.height ? { height: `${edge.height}px` } : {};

              return (
                <div
                  className={classNames('diagram-direction-forward', {
                    'is-edit': matchShow,
                  })}
                  style={...styles}
                  key={edge.id}
                >
                  {!matchShow && edge.label && (
                    <div className="diagram-direction-text">{edge.label}</div>
                  )}{' '}
                  {matchShow && !edge.target && (
                    <EdgeAdd
                      style={{
                        left: '100%',
                        top: '100%',
                        marginTop: '-15px',
                        marginLeft: '15px',
                      }}
                      className={'btn-forward'}
                      sourceId={sourceId}
                      sourceType={nodeType}
                      shape={shape}
                      edge={edge}
                      stepHelper={stepHelper}
                    />
                  )}
                </div>
              );
              // } else if (edge.sourceDirection == 'DIRECTION_FORWARD_OUTGOING_BACKWARD') {
              //   return (
              //     <React.Fragment key={edge.id}>
              //       {showDetail && (
              //         <div
              //           className={classNames(
              //             'diagram-direction-vertical diagram-direction-backward',
              //             {
              //               'is-edit': matchShow,
              //             },
              //           )}
              //         >
              //           {!matchShow && edge.label && (
              //             <div className="diagram-direction-text">{edge.label}</div>
              //           )}
              //           {matchShow && !edge.target && (
              //             <EdgeAdd
              //               style={{
              //                 left: '50%',
              //                 top: '100%',
              //                 marginTop: '16px',
              //                 marginLeft: '-13px',
              //               }}
              //               className={'btn-backward'}
              //               sourceId={sourceId}
              //               sourceType={nodeType}
              //               shape={shape}
              //               edge={edge}
              //               stepHelper={stepHelper}
              //             />
              //           )}
              //         </div>
              //       )}
              //     </React.Fragment>
              //   );
            }
          })}
        </div>
      )}
    </>
  );
}

export default NodeChecklist;
