import { Select } from '@base/components/form';
//import { templateData } from './dummy-data';
import { useGetList } from '@base/services/list';
import { FilterInput } from '@base/types/interfaces/common';
import React, { useState, useEffect } from 'react';

interface ITemplate {
  id: string;
  name: string;
  type: string;
  thumbnail: string;
  active: boolean;
  html: string;
}
interface Props {
  type: string;
  onChange: Function;
  group?: string;
}

const TypeTemplate: React.FC<Props> = (props: Props) => {
  const listQuerySchema = `id
  name
  title
  html
  thumbnail
  type
  language
  createdAt
  stage`;

  const optionGroup = [
    { key: 'email', value: 2 },
    { key: 'sms', value: 3 },
    { key: 'task', value: 4 },
    { key: 'call', value: 5 },
    { key: 'knowledgebase', value: 1 },
  ];
  const group = props?.group ? props.group : 'email';
  let selectGroup = optionGroup?.find((v: any) => v.key === group);

  let filtersQuery: FilterInput = {
    query: `group=${selectGroup?.value} sample=true`,
  };

  // get list data
  let { isLoading, data, isFetching, isPreviousData, refetch } = useGetList<any[]>(
    'setting_menuTemplates',
    listQuerySchema,
    filtersQuery,
  );
  let templateData: ITemplate[] = [];
  let [listTemplate, setTemplate] = useState<ITemplate[]>(templateData);
  let [activeType, setActiveType] = useState<{ value: number; label: string; key: string }>({
    value: 0,
    label: 'All',
    key: 'ALL',
  });

  const optionTypeSelect = [
    { value: 0, label: 'All', key: 'ALL' },
    { value: 1, label: 'General', key: 'TYPE_GENERAL' },
    { value: 2, label: 'Survey', key: 'TYPE_SURVEY' },
    { value: 3, label: 'Thank You', key: 'TYPE_THANK_YOU' },
    { value: 4, label: 'Follow Up', key: 'TYPE_FOLLOW_UP' },
  ];

  useEffect(() => {
    if (data?.data) {
      let listTemplateInit: ITemplate[] = [];
      data?.data.map((item, key) => {
        let tmp = { ...item };
        tmp.active = false;

        if (activeType.value == 0) {
          listTemplateInit.push(tmp);
        } else if (item.type === activeType.key) {
          listTemplateInit.push(tmp);
        }
      });
      setTemplate(listTemplateInit);
    }
  }, [data?.data, activeType]);

  const handleOnChange = (itemActive: ITemplate) => {
    let listTemplateNew: ITemplate[] = [];

    listTemplate.map((item, key) => {
      let tmp = { ...item };
      tmp.active = false;
      if (itemActive.id == item.id) {
        tmp.active = true;
      }
      listTemplateNew.push(tmp);
    });
    setTemplate(listTemplateNew);
    props.onChange(itemActive);
  };

  const onChangetype = (typeActive: { value: number; label: string; key: string }) => {
    setActiveType(typeActive);
  };

  const getSampleTemplate = () => {
    return (
      <div id="sampleLandingPage" className="tab-pane fade">
        <Select
          className="wd-200-f mg-t-10"
          options={optionTypeSelect}
          defaultValue={optionTypeSelect[0]}
          onChange={onChangetype}
        />
        <div className="card-columns temp-columns">
          {listTemplate.map((item, key) => {
            return (
              <div className="card shadow-sm" key={key} onClick={() => handleOnChange(item)}>
                <div className="card-body ht-150 overflow-hidden tx-center">
                  <img className="image is-loaded" src={item.thumbnail}></img>
                </div>
                <div className="card-footer">{item.name}</div>
                <button type="button" className={'btn btn-select ' + (item.active ? 'active' : '')}>
                  <span className="sr-only">선택</span>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return getSampleTemplate();
};

export default TypeTemplate;
