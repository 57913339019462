import { Button, Checkbox, Input, Switch } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import UserAutoComplete from '@base/containers/user-auto-complete';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { generateUUID } from '@base/utils/helpers';
import {
  ADD_ASSIGNMENT_REPS,
  CREATE_ASSIGNMENT_USER,
  DELETE_ASSIGNMENT_GROUP,
  DELETE_ASSIGNMENT_REPS,
  DELETE_ASSIGNMENT_USER,
  UPDATE_ASSIGNMENT_GROUP,
  UPDATE_ASSIGNMENT_REP,
  UPDATE_ASSIGNMENT_USER,
} from '@settings/preferences/services/graphql';
import {
  useAssignmentGroups,
  useAssignmentReps,
  useAssignmentUsers,
} from '@settings/preferences/services/service';
import {
  IAssignmentGroup,
  IAssignmentRep,
  IAssignmentUser,
} from '@settings/preferences/types/interfaces';
import { group } from 'console';
import React, { useEffect, useMemo, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { toast } from 'react-toastify';
import GroupDesc from '../group-desc';
import GroupName from '../group-name';
import NewGroup from './new-group';

interface IDeskAssignmentGroupProps {}
const DeskAssignmentGroup: React.FC<IDeskAssignmentGroupProps> = (
  props: IDeskAssignmentGroupProps,
) => {
  const [groups, setGroups] = useState<IAssignmentGroup[]>([]);
  const [reps, setReps] = useState<IAssignmentRep[]>([]);
  const [users, setUsers] = useState<IAssignmentUser[]>([]);
  const [groupId, setGroupId] = useState('');
  const [editItem, setEditItem] = useState<IAssignmentGroup | null>(null);
  const [viewItem, setViewItem] = useState<IAssignmentGroup | null>(null);
  const [showGroupDetail, setShowGroupDetail] = useState(false);
  const [isOpenNewGroup, setIsOpenNewGroup] = useState(false);
  const [assignmentType, setAssignmentType] = useState<any>({ user: true });
  const { data: listGroups, isLoading, refetch } = useAssignmentGroups('');
  const {
    data: listUsers,
    isLoading: isLoadingUser,
    refetch: reloadUsers,
  } = useAssignmentUsers('');
  const mDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_ASSIGNMENT_GROUP,
    'desk_deleteAssignmentGroup',
  );
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_ASSIGNMENT_GROUP,
    'desk_UpdateAssignmentGroup',
  );
  const mDeleteReps: any = useMutationPost<BaseMutationResponse>(
    DELETE_ASSIGNMENT_REPS,
    'desk_deleteAssignmentReps',
  );
  const mAddReps: any = useMutationPost<BaseMutationResponse>(
    ADD_ASSIGNMENT_REPS,
    'desk_createAssignmentReps',
  );
  const mUpdateRep: any = useMutationPost<BaseMutationResponse>(
    UPDATE_ASSIGNMENT_REP,
    'desk_updateAssignmentRep',
  );
  const mDeleteUsers: any = useMutationPost<BaseMutationResponse>(
    DELETE_ASSIGNMENT_USER,
    'desk_deleteAssignmentUsers',
  );
  const mAddUsers: any = useMutationPost<BaseMutationResponse>(
    CREATE_ASSIGNMENT_USER,
    'desk_createAssignmentUsers',
  );
  const mUpdateUsers: any = useMutationPost<BaseMutationResponse>(
    UPDATE_ASSIGNMENT_USER,
    'desk_updateAssignmentUsers',
  );
  const {
    data: listReps,
    isLoading: isLoadingReps,
    refetch: reloadReps,
  } = useAssignmentReps(groupId);
  const onCloseNewGroup = () => {
    setEditItem(null);
    setIsOpenNewGroup(false);
  };
  const onSaveNewGroup = (group: IAssignmentGroup, mode: string) => {
    let nItems = groups;
    if (mode == 'add') {
      nItems = [group, ...groups];
    } else if (mode == 'update') {
      nItems = groups.map((item) => {
        if (item.id == group.id) {
          return group;
        }
        return item;
      });
    }
    setGroups(nItems);
    onCloseNewGroup();
  };

  const onDeleteGroup = (id: string) => {
    const nItems = groups.filter((item) => {
      return item.id != id;
    });
    mDelete.mutate(
      { ids: [id] },
      {
        onSuccess: (res: any) => {
          toast.success('Data was removed!');
          setGroups(nItems);
        },
      },
    );
  };
  const onDeleteUser = (user: IAssignmentUser, delIdx: number) => {
    const nUsers = users.filter((u, idx) => {
      return idx != delIdx;
    });
    if (user.id !== '') {
      mDeleteUsers.mutate(
        { ids: [user.id] },
        {
          onSuccess: (res: any) => {
            toast.success('Data was removed!');
          },
        },
      );
    }

    setUsers(nUsers);
  };
  const onChangeUser = (user: IAssignmentUser, uIdx: number) => {
    if (user.user) {
      const nUser = {
        ...user,
        id: user.id == '' ? generateUUID() : user.id,
      };
      if (user.id === '') {
        // do add
        const param = {
          ...nUser,
          user: {
            id: nUser.user?.id,
            name: nUser.user?.name,
          },
        };
        mAddUsers.mutate(
          { user: param },
          {
            onSuccess: (res: any) => {
              toast.success('Data was saved!');
            },
          },
        );
      } else {
        // do update
        const param = {
          ...nUser,
          user: {
            id: nUser.user?.id,
            name: nUser.user?.name,
          },
        };
        mUpdateUsers.mutate(
          { user: param },
          {
            onSuccess: (res: any) => {
              toast.success('Data was saved!');
            },
          },
        );
      }
      const nUsers = users.map((oUser, idx) => {
        if (idx === uIdx) {
          return nUser;
        }
        return oUser;
      });
      setUsers(nUsers);
    }
  };
  const onDeleteRep = (rep: IAssignmentRep, delIdx: number) => {
    const nItems = reps.filter((item, idx) => {
      return idx != delIdx;
    });
    if (rep.id !== '') {
      // delete on Server
      mDeleteReps.mutate(
        {
          reps: [
            {
              user: {
                id: rep.user?.id,
                name: rep.user?.name,
              },
              capacity: rep.capacity,
            },
          ],
          id: groupId,
        },
        {
          onSuccess: (res: any) => {
            toast.success('Data was removed!');
          },
        },
      );
    }
    setReps(nItems);
  };
  const onAddRep = () => {
    const nReps = [
      ...reps,
      {
        id: '',
        user: null,
        capacity: 0,
      },
    ];
    setReps(nReps);
  };
  const onChangeNewRep = (rep: IAssignmentRep, uIdx: number) => {
    const nRep = {
      ...rep,
      id: rep.id === '' ? generateUUID() : rep.id,
    };
    const params = {
      ...nRep,
      user: {
        id: nRep.user?.id,
        name: nRep.user?.name,
      },
    };
    if (rep.id === '' && rep.user) {
      // add to server
      mAddReps.mutate(
        { id: groupId, reps: [params] },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
          },
        },
      );
    } else {
      // do update
      mUpdateRep.mutate(
        { id: groupId, rep: params },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
          },
        },
      );
    }

    // update local
    const nReps = reps.map((oRep, idx) => {
      if (idx == uIdx) {
        return nRep;
      }
      return oRep;
    });
    setReps(nReps);
  };
  const getTotalCapacity = (): number => {
    let total = 0;
    if (reps.length > 0) {
      reps.map((rep) => {
        total += rep.capacity ? rep.capacity * 1 : 0;
      });
    }
    return total;
  };
  const onAddUser = () => {
    const nUser: IAssignmentUser = {
      id: '',
      user: null,
      isEmail: false,
      isSms: false,
      active: true,
    };
    const nUsers = [...users, nUser];
    setUsers(nUsers);
  };
  const onActiveGroup = (nGroup: IAssignmentGroup, uIdx: number) => {
    const nGroups = groups.map((oG, idx) => {
      if (idx === uIdx) {
        return nGroup;
      }
      return oG;
    });
    mUpdate.mutate(
      {
        group: {
          id: nGroup.id,
          active: nGroup.active,
        },
      },
      {
        onSuccess: (res: any) => {
          toast.success('Data was updated!');
        },
      },
    );
    setGroups(nGroups);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Group Name',
        accessor: (row: IAssignmentGroup, rowIndex: any) => {
          return (
            <>
              <Button
                color="link"
                name={row.name}
                className="pd-0"
                onClick={() => {
                  setViewItem(row);
                  setGroupId(row.id);
                  setShowGroupDetail(true);
                }}
              />
            </>
          );
        },
      },
      {
        Header: 'Descriptions',
        accessor: (row: IAssignmentGroup, rowIndex: any) => {
          return <>{row.description}</>;
        },
        width: 70,
      },
      {
        Header: 'Reps',
        accessor: (row: IAssignmentGroup, rowIndex: any) => {
          return <>{row.reps ? row.reps.length : 0}</>;
        },
        width: 70,
      },
      {
        Header: 'Active',
        accessor: (row: IAssignmentGroup, rowIndex: any) => {
          return (
            <>
              <div className="d-flex align-items-center">
                <Switch
                  value={row.active}
                  onChange={() => {
                    const nGroup = {
                      ...row,
                      active: !row.active,
                    };
                    onActiveGroup(nGroup, rowIndex);
                  }}
                />

                <Button
                  color=""
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                  data-han-tooltip="삭제"
                  onClick={() => {
                    onDeleteGroup(row.id);
                  }}
                />
              </div>
            </>
          );
        },
        width: 70,
      },
    ],
    [groups],
  );

  const userColumns = useMemo(
    () => [
      {
        Header: 'User Type',
        accessor: (row: IAssignmentUser, rowIndex: any) => {
          return <>{row.user?.role?.name}</>;
        },
        width: 100,
      },
      {
        Header: 'User Name',
        accessor: (row: IAssignmentUser, rowIndex: any) => {
          return (
            <>
              {row.user ? (
                <>
                  <span className="d-inline-block avatar avatar-xs mg-r-5">
                    <img
                      src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                      className="rounded-circle"
                      alt="MSR"
                    />
                  </span>
                  {row.user.name}
                </>
              ) : (
                <UserAutoComplete
                  showAvatar
                  single
                  onChange={(nVal: any) => {
                    // console.log('selected User', nVal);
                    // check exist or not
                    const found = users.find((u) => {
                      return u.user?.id == nVal.id;
                    });
                    if (found) {
                      toast.error("User '" + nVal.name + "' has existed!");
                      return;
                    }
                    const nUser = {
                      ...row,
                      user: {
                        id: nVal.id,
                        name: nVal.name,
                        phone: nVal.phone ?? '0701111111',
                        email: nVal.email ?? 'hanbirotest@vora.net',
                        role: {
                          id: generateUUID(),
                          name: 'Administrator',
                        },
                      },
                    };
                    onChangeUser(nUser, rowIndex);
                  }}
                />
              )}
            </>
          );
        },
        width: 200,
      },
      {
        Header: 'Email',
        accessor: (row: IAssignmentUser, rowIndex: any) => {
          return <>{row.user?.email}</>;
        },
      },
      {
        Header: 'Phone',
        accessor: (row: IAssignmentUser, rowIndex: any) => {
          return <>{row.user?.phone}</>;
        },
      },
      {
        Header: 'Active',
        accessor: (row: IAssignmentUser, rowIndex: any) => {
          return (
            <>
              <div className="d-flex align-items-center">
                <Switch
                  value={row.active}
                  onChange={() => {
                    const nUser = {
                      ...row,
                      active: !row.active,
                    };
                    onChangeUser(nUser, rowIndex);
                  }}
                />
              </div>
            </>
          );
        },
      },
      {
        Header: 'Notification',
        accessor: (row: IAssignmentUser, rowIndex: any) => {
          return (
            <>
              <div className="d-flex align-items-center">
                <Checkbox
                  label="Email"
                  className="mg-r-10"
                  checked={row.isEmail}
                  onChange={(nVal: boolean) => {
                    const nUser = {
                      ...row,
                      isEmail: !row.isEmail,
                    };
                    onChangeUser(nUser, rowIndex);
                  }}
                />
                <Checkbox
                  label="SMS"
                  className="mg-r-10"
                  checked={row.isSms}
                  onChange={(nVal: boolean) => {
                    const nUser = {
                      ...row,
                      isSms: !row.isSms,
                    };
                    onChangeUser(nUser, rowIndex);
                  }}
                />

                <Button
                  color=""
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                  data-han-tooltip="삭제"
                  onClick={() => {
                    onDeleteUser(row, rowIndex);
                  }}
                />
              </div>
            </>
          );
        },
      },
    ],
    [users],
  );
  useEffect(() => {
    if (!isLoading && listGroups) {
      setGroups(listGroups.results ?? []);
    }
  }, [listGroups]);
  useEffect(() => {
    if (!isLoadingUser && listUsers) {
      setUsers(listUsers.results ?? []);
    }
  }, [listUsers]);
  useEffect(() => {
    if (!isLoadingReps && listReps) {
      setReps(listReps.results ?? []);
    }
  }, [listReps, groupId]);
  return (
    <>
      <div className="pd-20 scroll-box setting-tab-body">
        {!showGroupDetail && (
          <>
            <div className="d-flex">
              <ul className="nav nav-tabs flex-grow-1">
                <li className="nav-item">
                  <Button
                    color=""
                    className={`nav-link rounded-0 ${
                      assignmentType.user ? 'active tx-semibold' : ''
                    }`}
                    name="User"
                    onClick={() => setAssignmentType({ user: true })}
                  />
                </li>
                <li className="nav-item">
                  <Button
                    color=""
                    className={`nav-link rounded-0 ${
                      assignmentType.group ? 'active tx-semibold' : ''
                    }`}
                    name="Group"
                    onClick={() => setAssignmentType({ group: true })}
                  />
                </li>
              </ul>
              {assignmentType.group && (
                <div className="mg-l-auto bd-b pd-r-5">
                  <Button
                    color="primary"
                    icon="Plus"
                    name="Add"
                    onClick={() => {
                      setIsOpenNewGroup(true);
                    }}
                  />
                </div>
              )}
              <div className="mg-l-auto bd-b">
                <Button
                  color="white"
                  icon="RefreshCw"
                  name=""
                  onClick={() => {
                    if (assignmentType.group) {
                      refetch();
                    } else {
                      reloadUsers();
                    }
                  }}
                />
              </div>
            </div>
            <div className="tab-content bg-white bd bd-gray-200 bd-t-0">
              {assignmentType.user && (
                <BasicTable
                  columns={userColumns}
                  data={users || []}
                  className="table-th-white  hover-delete"
                  isLoading={isLoadingUser}
                  wrapperClassName=""
                ></BasicTable>
              )}
              {assignmentType.group && (
                <BasicTable
                  columns={columns}
                  data={groups || []}
                  className="table-th-white  hover-delete"
                  wrapperClassName="bg-white"
                ></BasicTable>
              )}
            </div>
            {assignmentType.user && (
              <Button
                color="link"
                icon="Plus"
                name="Add user"
                onClick={() => {
                  onAddUser();
                }}
              />
            )}
          </>
        )}
        {showGroupDetail && (
          <div className="card">
            <div className="card-header d-flex align-items-center pd-10">
              <Button
                color="link"
                icon="ArrowLeft"
                className="btn-icon mg-r-10"
                onClick={() => {
                  refetch(); //reload group
                  setShowGroupDetail(false);
                }}
              />
              <h2 className="h6 mg-0 wd-400">
                {viewItem && (
                  <GroupName
                    name={viewItem.name}
                    id={viewItem.id}
                    onAfterSaved={(nVal: string) => {
                      const nData = {
                        ...viewItem,
                        name: nVal,
                      };
                      setViewItem(nData);
                    }}
                  />
                )}
              </h2>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-item-title">Description</label>
                <div className="bd rounded pd-10">
                  {viewItem && (
                    <GroupDesc
                      desc={viewItem.description}
                      id={viewItem.id}
                      onAfterSaved={(nVal: string) => {
                        const nData = {
                          ...viewItem,
                          description: nVal,
                        };
                        setViewItem(nData);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="table-responsive bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete">
                  <thead>
                    <tr>
                      <th scope="col">User Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      {/* <th scope="col">Capacity</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoadingReps &&
                      reps.map((rep, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              {rep.user ? (
                                <>
                                  <span className="d-inline-block avatar avatar-xs mg-r-5">
                                    <img
                                      src="http://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
                                      className="rounded-circle"
                                      alt="MSR"
                                    />
                                  </span>
                                  {rep.user.name}
                                </>
                              ) : (
                                <UserAutoComplete
                                  showAvatar
                                  single
                                  onChange={(nUser: any) => {
                                    const nRep = {
                                      ...rep,
                                      user: {
                                        id: nUser.id,
                                        name: nUser.name,
                                        phone: nUser.phone ?? '0701111111',
                                        email: nUser.email ?? 'hanbirotest@vora.net',
                                      },
                                    };
                                    // check exist or not

                                    const found = reps?.find((rep) => {
                                      return rep.user?.id === nUser.id;
                                    });
                                    if (found) {
                                      toast.error("User '" + nUser.name + "' has existed!");
                                      return;
                                    }
                                    onChangeNewRep(nRep, idx);
                                  }}
                                />
                              )}
                            </td>
                            <td> {rep.user?.email}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                {rep.user?.phone}
                                <Button
                                  color=""
                                  icon="X"
                                  iconClass="tx-danger"
                                  className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                                  data-han-tooltip="삭제"
                                  onClick={() => {
                                    onDeleteRep(rep, idx);
                                  }}
                                />
                              </div>
                            </td>
                            {/* <td>
                              <div className="d-flex align-items-center">
                                <Input
                                  type="number"
                                  rightIcon="%"
                                  className="wd-120"
                                  value={rep.capacity}
                                  onBlur={(nVal: number) => {
                                    if (rep.capacity === nVal * 1) {
                                      return;
                                    }
                                    const nRep = {
                                      ...rep,
                                      capacity: nVal * 1,
                                    };
                                    onChangeNewRep(nRep, idx);
                                  }}
                                />
                                <Button
                                  color=""
                                  icon="X"
                                  iconClass="tx-danger"
                                  className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                                  data-han-tooltip="삭제"
                                  onClick={() => {
                                    onDeleteRep(rep, idx);
                                  }}
                                />
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center justify-content-between pd-10 bd bd-t-0 rounded-bottom">
                <span>Total {reps.length}</span>
                {/* <span>{getTotalCapacity()}%</span> */}
              </div>
              <Button color="link" icon="Plus" name="Add line" onClick={() => onAddRep()} />
            </div>
          </div>
        )}
        {isOpenNewGroup && (
          <NewGroup
            isOpen={isOpenNewGroup}
            onClose={onCloseNewGroup}
            onSave={onSaveNewGroup}
            data={editItem}
          />
        )}
      </div>
    </>
  );
};

export default DeskAssignmentGroup;
