import React from 'react';
import TextView from '@base/containers/view-field/text/view';

const View: React.FC = (props: any) => {
  const { value } = props;
  return value ? (
    <div>{`Width:${value?.width ?? 0}px; Height:${value?.height ?? 0}px`}</div>
  ) : (
    <TextView value={''} />
  );
};

export default View;
