import { Select } from '@base/components/form';
import { templateData } from './dummy-data';
import { useGetList } from '@base/services/list';
import { FilterInput } from '@base/types/interfaces/common';
import React, { useState, memo, useEffect } from 'react';

interface ITemplate {
  id: string;
  name: string;
  thumbnail: string;
  active: boolean;
  html: string;
}
interface Props {
  listTemplate: ITemplate[];
  setTemplate: Function;
  onChange: Function;
}

const MyTemplate: React.FC<Props> = (props: Props) => {
  let [listTemplateView, setTemplate] = useState<ITemplate[]>(props.listTemplate);

  useEffect(() => {
    setTemplate(props.listTemplate ?? []);
  }, [props.listTemplate]);

  const handleOnChange = (itemActive: ITemplate) => {
    let listTemplateNew: ITemplate[] = [];

    listTemplateView.map((item, key) => {
      let tmp = { ...item };
      tmp.active = false;
      if (itemActive.id == item.id) {
        tmp.active = true;
      }
      listTemplateNew.push(tmp);
    });

    setTemplate(listTemplateNew);
    props.onChange(itemActive);
  };

  const getMyTemplate = () => {
    return (
      <div id="myLandingPage" className="tab-pane fade show active">
        <div className="card-columns temp-columns">
          {listTemplateView.map((item, key) => {
            return (
              <div className="card shadow-sm" key={key} onClick={() => handleOnChange(item)}>
                <div className="card-body ht-150 overflow-hidden tx-center">
                  {item.id !== '' && <img className="img-fit-cover" alt={item.name} src={item.thumbnail} />}
                </div>
                <div className="card-footer">{item.name}</div>
                <button type="button" className={'btn btn-select ' + (item.active ? 'active' : '')}>
                  <span className="sr-only">선택</span>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return getMyTemplate();
};

export default memo(MyTemplate);
