import React, { useState, useEffect } from 'react';
//import Icons from '@base/assets/icons/svg-icons';
//import { Link } from 'react-router-dom';
import { ChevronDown, Settings, Trash } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from '@base/components';
//import Loading from '@base/components/loading';
import { deleteSurveyFromStorage } from '@settings/online-digital-content/survey/services/storage';
import SendModal from '@settings/online-digital-content/survey/components/survey-send-modal';
import { Button } from '@base/components/form';

interface ISurveyHeaderProps {
  isPreview?: boolean;
  isLoading?: boolean;
  surveyId?: string;
  surveyTitle?: string;
  setShowSideBar?: (isShow: boolean) => void;
  onReset?: () => void;
  onSave?: () => void;
  onSaveAsTemplate?: () => void;
}

const SurveyHeader: React.FC<ISurveyHeaderProps> = (props) => {
  const {
    isPreview = false,
    isLoading = false,
    surveyId,
    surveyTitle,
    setShowSideBar,
    onReset,
    onSave,
    onSaveAsTemplate
  } = props;
  const { t } = useTranslation();

  //local state
  const [show, setShow] = useState<boolean>(false);

  //delete data from indexeddb
  async function deleteStoreSurvey(id: string) {
    return await deleteSurveyFromStorage(id);
  }

  //empty content
  const handleConfirmEmpty = () => {
    if (surveyId) {
      deleteStoreSurvey(surveyId);
      onReset && onReset();
    }
  };

  //main render
  return (
    <div className="survey-write-header-form pd-0-f ht-50-f">
      {/* title + actions */}
      <div className="d-flex flex-row align-items-center">
        <div className="survey-home-header-form-title">
          {/* <div className="survey-home-header-button">
            <a onClick={goBack}>{Icons('arrow_left')}</a>
          </div> */}
          <h1 className="toolbar-title tx-uppercase pd-l-15 pd-t-5">
            {surveyId ? 'Update survey' : 'New survey'} [{surveyTitle ? surveyTitle : 'Survey Title'}]
          </h1>
        </div>

        {/* saving */}
        {/* {isSaving && (
          <div className="d-flex flex-1 justify-content-center tx-16 tx-italic">Saving...</div>
        )} */}

        <div className="d-flex flex-1 flex-row justify-content-end align-items-center">
          {!isPreview &&
            <>
              <div
                className="survey-home-header-button han-tooltip han-tooltip--bottom"
                data-han-tooltip="Empty Editor"
              >
                <a onClick={handleConfirmEmpty}>
                  <Trash className="ht-20" />
                </a>
              </div>
              <div
                className="survey-home-header-button mg-r-10 han-tooltip han-tooltip--bottom"
                data-han-tooltip="Theme Setting"
              >
                <a onClick={() => setShowSideBar && setShowSideBar(true)}>
                  <Settings className="ht-20" />
                </a>
              </div>
            </>
          }
          {/* <div
            className="survey-home-header-button mg-r-10 han-tooltip han-tooltip--bottom"
            data-han-tooltip="Preview"
          >
            <Link to={'/settings/survey/all/' + surveyId} target="_blank">
              <Eye />
            </Link>
          </div> */}
          {!isPreview &&
            <>
              {surveyId ?
                <div className="btn-group mg-r-10">
                  <Button
                    color="success"
                    size='sm'
                    onClick={onSave}
                    disabled={isLoading}
                    loading={isLoading}
                    name="Save"
                  />
                  {/* <button
                    type="button"
                    className="btn btn-sm btn-success dropdown-toggle-split"
                    data-toggle="dropdown"
                  >
                    <ChevronDown />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Button
                      className="dropdown-item"
                      color="primary"
                      size='sm'
                      onClick={onSaveAsTemplate}
                      name="Save as template"
                    />
                  </div> */}
                </div>
                :
                <div>
                  {/* <button
                    type="button"
                    className="btn btn-sm btn-info mg-r-10"
                    onClick={onSaveAsTemplate}
                  >
                    Save as template
                  </button> */}
                </div>
              }
            </>
          }
          {/* <div>
            <button
              type="button"
              className="btn btn-sm btn-warning mg-r-20"
              onClick={() => setShow(true)}
            >
              Send
            </button>
          </div> */}
        </div>
      </div>

      {/* spinner */}
      {/* <div className="pos-absolute z-index-100" style={{ top: '40%', left: '50%' }}>
        {isLoading && <Loading />}
      </div> */}

      {/* send modal */}
      {show &&
        <SendModal
          isOpen={show}
          onClose={() => setShow(false)}
          defaultSubject={surveyTitle}
        />
      }
    </div>
  );
};

export default SurveyHeader;
