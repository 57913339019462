import React, { useState } from 'react';
import ModalHTML from '@base/components/html-modal';
import Preview from '@settings/template/containers/preview';
import GrapesTSViewFieldView from '@base/containers/view-field/grapes-ts/view';
import withMiModal from '@base/hooks/hocs/withMiModal';
interface IPreviewTemplateProps {
  onClose: () => void;
  value: any;
  size: string;
  title: string;
}

const ModalPreview: React.FC<IPreviewTemplateProps> = (props: IPreviewTemplateProps) => {
  const { onClose, value, size, title } = props;
  const [iframeRef, setIframeRef] = useState<any>(null);

  return (
    <div className="pos-relative">
      <GrapesTSViewFieldView showTools={true} value={value} />
    </div>
  );
};

ModalPreview.defaultProps = {
  size: 'xl',
  title: 'Preview Template',
};

export default withMiModal(ModalPreview);
