import * as keyNames from '@settings/template/config/key-names';
import { TEMPLATE_GROUP } from '@settings/template/config/constants';
import { parserSelectToIdName, parserSelectToIdNameObj } from '@base/utils/helpers/config-utils';

export const finalizeParams = (formData: any, templateGroup: string) => {
  //build add params and save

  let params: any = {};
  if (templateGroup == 'sms') {
    params = parseParamsSms(formData, templateGroup);
  } else {
    params = parseParamsEmail(formData, templateGroup);
  }

  return params;
};

const parseParamsEmail = (formData: any, templateGroup: string) => {
  //build add params and save
  let index = TEMPLATE_GROUP.findIndex((x) => x.key === templateGroup);
  let group = TEMPLATE_GROUP[index].value;

  const params: any = {
    name: formData[keyNames.KEY_MENU_TEMPLATE_NAME],
    description: formData[keyNames.KEY_MENU_TEMPLATE_DESCRIPTION],
    group: group,
    type: formData[keyNames.KEY_MENU_TEMPLATE_TYPE].value,
    products: parserSelectToIdName(formData[keyNames.KEY_MENU_TEMPLATE_PRODUCT]),
    assignTo: parserSelectToIdNameObj(formData[keyNames.KEY_MENU_TEMPLATE_OWNER], 'user'),
    language: formData[keyNames.KEY_MENU_TEMPLATE_LANGUAGE],
    title: formData[keyNames.KEY_MENU_TEMPLATE_SUBJECT],
  };

  return params;
};
const parseParamsSms = (formData: any, templateGroup: string) => {
  //build add params and save
  let index = TEMPLATE_GROUP.findIndex((x) => x.key === templateGroup);
  let group = TEMPLATE_GROUP[index].value;

  const params: any = {
    name: formData[keyNames.KEY_MENU_TEMPLATE_NAME],
    description: formData[keyNames.KEY_MENU_TEMPLATE_DESCRIPTION],
    group: group,
    type: formData[keyNames.KEY_MENU_TEMPLATE_TYPE].value,
    messageType: formData[keyNames.KEY_MENU_TEMPLATE_SMS_TYPE].value,
    products: parserSelectToIdName(formData[keyNames.KEY_MENU_TEMPLATE_PRODUCT]),
    assignTo: parserSelectToIdNameObj(formData[keyNames.KEY_MENU_TEMPLATE_OWNER], 'user'),
    language: formData[keyNames.KEY_MENU_TEMPLATE_LANGUAGE],
    title: formData[keyNames.KEY_MENU_TEMPLATE_SUBJECT],
  };

  return params;
};
