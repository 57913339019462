import { FormIcon } from '@base/components/form';
import React, { useState } from 'react';
import HelpTypeField from '../help-type-field';
import ShowIn from '../show-in';

interface ICollapseSectionProps {
  isShowInit?: boolean;
  label: any;
  children?: any;
  keyName: string;
}
const CollapseSection: React.FC<ICollapseSectionProps> = (props: ICollapseSectionProps) => {
  const { isShowInit = true, label = '', children = null, keyName = '' } = props;
  const [isShow, setIsShow] = useState(isShowInit);
  let isShowInList = true;
  let isShowInView = true;
  let isShowInWrite = true;
  let isRequired = true;
  // if (typeof keyName != "undefined" && keyName == "more"){
  //     isShowInWrite = false
  // }

  return (
    <>
      <div className="card-header d-flex align-items-center bg-light">
        <div className="card-title mg-b-0" style={{ flex: 1 }}>
          {label}
          <HelpTypeField />
        </div>

        <ShowIn
          isLabel={true}
          locationsDisplay={{
            isShowInList: isShowInList,
            isShowInView: isShowInView,
            isShowInWrite: isShowInWrite,
            isRequired: isRequired,
          }}
        />
        <i className="pd-l-10" onClick={() => setIsShow(!isShow)}>
          <FormIcon icon={isShow ? 'ChevronDown' : 'ChevronRight'} iconType="feather" size={16} />
        </i>
      </div>
      <div>{isShow && children}</div>
    </>
  );
};

export default CollapseSection;
