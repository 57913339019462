import React, { FC, useEffect, useState } from 'react';
import { SpanLang, Canvas } from '@base/components';
import NoDataIcon from '@base/components/form/icon/nodata';
import CardManagement from '@settings/general-setting/components/card-management';
import ItemManagement from '@settings/general-setting/components/item-management';
import { useTranslation } from 'react-i18next';
import LangInput from '@base/containers/lang-input';
import Loading from '@base/components/loading';

import './styles.scss';
import {
  loadSelectionGroupItemsAPI,
  useListSelectionGroupItems,
  useListSelectionGroups,
} from '@settings/general-setting/services/selection-service';
import { ISelection } from '@settings/general-setting/types/interfaces/selection';
import { confirmAlert } from '@base/components/confirm-alert';
import useMutationPost from '@base/hooks/useMutationPost';
import {
  ADD_SELECTION_ITEM,
  UPDATE_SELECTION_ITEM,
  DELETE_SELECTION_ITEM,
} from '@settings/general-setting/services/graphql/selection';
import { toast } from 'react-toastify';
import { BaseMutationResponse, BaseResponse } from '@base/types/interfaces/response';
import { getCurrentLang, updateLanguageI18n } from '@base/services/i18n';
import TreeFolder from '@settings/general-setting/containers/tree-folder';
import { ILanguageData } from '@base/types/interfaces/common';
// let loadDataCallback: any = {};
const ManageGroupFieldsPage: FC<any> = (props: any) => {
  const NAME_ITEM_INIT = [
    { id: 'en', value: '' },
    { id: 'ko', value: '' },
    { id: 'vi', value: '' },
    { id: 'jp', value: '' },
    { id: 'zh', value: '' },
    { id: 'ido', value: '' },
  ];
  const regexCleanString = new RegExp(/[^\w]/gi);
  //lang
  const { t, i18n } = useTranslation();
  const [categoryChoosed, setCategoryChoosed] = useState<ISelection>();
  const [openAddItem, setOpenAddItem] = useState(false);
  const [nameItem, setNameItem] = useState(NAME_ITEM_INIT);
  const [itemChoosed, setItemChoosed] = useState<ISelection>({
    id: '',
    keyName: '',
    languageKey: '',
  });
  const [itemParentChoosed, setItemParentChoosed] = useState<ISelection>({
    id: '',
    keyName: '',
    languageKey: '',
  });
  const userLang = getCurrentLang();
  const [parentId, setParentId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [loading, setLoading] = useState(false);
  const [expand, setExpand] = useState({ time: 0, data: {} });
  const [groups, setGroups] = useState<ISelection[]>([]);
  const { data: fieldsResponse, isLoading: isFieldLoading } = useListSelectionGroups();
  const {
    data: fieldItemsResponse,
    isFetching: isFieldItemLoading,
    refetch,
  } = useListSelectionGroupItems(parentId);
  const {
    data: subItemsResponse,
    isLoading: isSubItemLoading,
    refetch: reloadSubItem,
  } = useListSelectionGroupItems(groupId);

  // console.log('render component', itemChoosed);
  const handleReloadSubGroups = (reloadGId: string) => {
    if (reloadGId != groupId) {
      setGroupId(reloadGId);
    } else {
      reloadSubItem();
    }
  };
  const deleteSection = (id: string, groups: ISelection[]) => {
    let nGroups = groups;
    if (groups.length) {
      nGroups = [];
      groups.map((g) => {
        if (g.id != id) {
          let nG = {
            ...g,
            children: deleteSection(id, g.children ?? []),
          };
          nGroups.push(nG);
        }
      });
    }
    return nGroups;
  };
  const reloadGroupChildren = (id: string, subItems: ISelection[]) => {
    // console.log('reloadGroupChildren', id, parentId);
    if (id === parentId) {
      refetch();
      return;
    }
    let newGroups = updateGroupData(
      id,
      { children: subItems, expand: true, isLoaded: true },
      groups,
    );
    setGroups(newGroups);
    // loadSelectionGroupItemsAPI(id)
    //   .then((res: BaseResponse<ISelection>) => {
    //     setLoading(false);
    //     if (res.results && res.results.length > 0) {
    //       let newGroups = updateGroupData(id, { children: res.results, expand: true }, groups);
    //       setGroups(newGroups);
    //     }
    //   })
    //   .catch((e: any) => {
    //     // console.log('loadSelectionGroupItemsAPI error', e);
    //     setLoading(false);
    //   });
  };
  const mAddSelection: any = useMutationPost<BaseMutationResponse>(
    ADD_SELECTION_ITEM,
    'setting_createSelection',
    {
      onSuccess: (res: any) => {
        // update language in local
        let languageValue: any = {};
        for (const name of nameItem) {
          languageValue[name.id] = name.value;
        }
        let { keyName } = itemParentChoosed;
        keyName += '_' + languageValue.en.toLowerCase().replace(regexCleanString, '_');
        let languageKey = 'options_groups_' + keyName + '__' + res.id.replaceAll('-', '_');
        updateLanguageI18n(languageKey, languageValue);
        // turn off loading
        setLoading(false);
        // turn off Add modal
        setOpenAddItem(false);
        // reloadGroupChildren(itemParentChoosed.id);
        toast.success('Data was added!');
        handleReloadSubGroups(itemParentChoosed.id);
      },
      onError: (res: any) => {
        setLoading(false);
      },
    },
  );
  const mUpdateSelection: any = useMutationPost<BaseMutationResponse>(
    UPDATE_SELECTION_ITEM,
    'setting_updateSelection',
    {
      onSuccess: (res: any) => {
        setLoading(false);
        setOpenAddItem(false);
        let languageValue: any = {};
        for (const name of nameItem) {
          languageValue[name.id] = name.value;
        }
        let { languageKey } = itemChoosed;
        // console.log('itemChoosed', itemChoosed);
        updateLanguageI18n(languageKey, languageValue);
        // reload group parent
        handleReloadSubGroups(itemChoosed?.parentId ?? '');
        toast.success('Data was updated!');
      },
      onError: (res: any) => {
        setLoading(false);
      },
    },
  );
  const mDeleteSelection: any = useMutationPost<BaseMutationResponse>(
    DELETE_SELECTION_ITEM,
    'setting_deleteSelection',
    {
      onSuccess: (res: any) => {
        // console.log('delete item', itemChoosed);
        setLoading(false);
        if (itemChoosed?.parentId) {
          handleReloadSubGroups(itemChoosed?.parentId);
        }

        toast.success('Data has deleted!');
      },
      onError: (res: any) => {
        setLoading(false);
      },
    },
  );

  useEffect(() => {
    setLoading(isFieldLoading);
    if (!isFieldLoading && fieldsResponse?.results) {
      // get frist category
      let category = fieldsResponse.results.length ? fieldsResponse.results[0] : null;
      if (category) {
        setParentId(category.id);
        setCategoryChoosed(category);
      }
    }
  }, [fieldsResponse]);

  useEffect(() => {
    setLoading(isFieldItemLoading);
    if (!isFieldItemLoading && fieldItemsResponse?.results) {
      setGroups(fieldItemsResponse.results);
    }
  }, [fieldItemsResponse]);
  useEffect(() => {
    if (expand.time) {
      onExpandHandle(expand.data);
    }
  }, [expand.time]);

  useEffect(() => {
    if (!isSubItemLoading && subItemsResponse) {
      reloadGroupChildren(groupId, subItemsResponse.results ?? []);
    }
  }, [subItemsResponse]);
  const closeAddItem = () => {
    setOpenAddItem(false);
  };

  const onSaveItem = () => {
    if (!categoryChoosed) {
      return;
    }
    setLoading(true);
    let languageValue: any = {};
    for (const name of nameItem) {
      languageValue[name.id] = name.value;
    }
    if (itemChoosed.id) {
      // edit category
      let input = {
        id: itemChoosed.id,
        parentId: itemChoosed.parentId,
        languageKey: itemChoosed.languageKey,
        languageData: languageValue,
        keyGroup: 'options_groups',
      };
      // update selection item
      mUpdateSelection.mutate({ input: input });
    } else {
      // add
      let keyName = languageValue.en;
      keyName = keyName.toLowerCase().replace(regexCleanString, '_');
      // create new selection
      let selection = {
        parentId: itemParentChoosed.id,
        languageData: languageValue,
        keyRoot: categoryChoosed.keyName,
        keyName: keyName,
        keyGroup: 'options_groups',
      };
      mAddSelection.mutate({ input: selection });
    }
  };

  const onDeleteItem = ({ item, index }: any) => {
    // delete item
    setLoading(true);
    // console.log('itemChoosed', item);
    mDeleteSelection.mutate({ id: item.id, selectType: 'options_groups' });
    let nGroups = deleteSection(item.id, groups);
    setGroups(nGroups);
  };

  const onStartAddItem = ({
    item = { id: '', keyName: '', languageKey: '' },
    loadData = () => {},
    setLoading = () => {},
    setIsExpand = () => {},
  }) => {
    setNameItem(NAME_ITEM_INIT);
    setItemChoosed({ id: '', keyName: '', languageKey: '' });
    setItemParentChoosed(item);
    setOpenAddItem(true);
  };

  const onStartEditItem = ({ item, index }: any) => {
    setItemParentChoosed({ id: '', keyName: '', languageKey: '' });
    if (!item) {
      return;
    }

    setNameItem(
      NAME_ITEM_INIT.map((itemName) => {
        return {
          ...itemName,
          value: item.language && item.language[itemName.id] ? item.language[itemName.id] : '',
        };
      }),
    );
    setItemChoosed(item);
    setOpenAddItem(true);
  };

  const onChangeCategory = (category: ISelection) => {
    // console.log(category);
    setLoading(true);
    // load Field item
    setCategoryChoosed(category);
    setParentId(category.id);
  };

  const onExpand = (data: any) => {
    //// console.log("data", data);
    setExpand({
      time: new Date().getTime(),
      data: data,
    });
  };

  const updateExpandInGroups = ({ expand = false, item = {} }: any) => {
    return updateGroupData(item.id, { expand }, groups);
  };
  const updateGroupData = (id: string, newData: any, parentGroups: ISelection[]) => {
    let newGroups = parentGroups;
    if (newGroups.length > 0) {
      newGroups = parentGroups.map((group) => {
        let newGroup = group;
        if (newGroup.id == id) {
          // console.log('found', id, newData);
          newGroup = {
            ...newGroup,
            ...newData,
          };
          return newGroup;
        }
        if (newGroup.children && newGroup.children.length > 0) {
          newGroup.children = updateGroupData(id, newData, newGroup.children);
        }
        return newGroup;
      });
    }
    return newGroups;
  };

  const onExpandHandle = (params: any) => {
    const { item, isExpand, setLoading = (input: boolean) => {} } = params;
    // console.log('onExpandHandle', params);
    const itemParentChoosed = item;
    if (isExpand) {
      if (itemParentChoosed.isLoaded) {
        let nGroups = updateExpandInGroups({
          expand: isExpand,
          item: itemParentChoosed,
        });
        setGroups(nGroups);
        return;
      }
      setLoading(true);
      handleReloadSubGroups(itemParentChoosed.id);
    } else {
      let nGroups = updateExpandInGroups({ expand: isExpand, item: itemParentChoosed });
      setGroups(nGroups);
    }
  };

  const handleDelete = (data: any) => {
    // console.log('handleDelete', data.item);
    setItemChoosed(data.item);
    confirmAlert({
      title: t('crm_new_common_delete'),
      message: t('crm_new_common_delete_msg'),
      buttons: [
        {
          label: 'No',
          className: 'btn-secondary',
        },
        {
          label: 'Yes',
          className: 'btn-primary',
          onClick: () => {
            onDeleteItem(data);
          },
        },
      ],
    });
  };
  return (
    <div className="general-setting-manage-group row">
      {isFieldLoading || isFieldItemLoading ? <Loading /> : ''}
      <div className="col-4">
        <CardManagement
          title={<SpanLang keyLang="crm_new_generalsetting_categories" />}
          isAdd={false}
        >
          <div className="content-categories" style={{ paddingTop: 0 }}>
            <nav className="nav nav-sidebar tx-13">
              {!isFieldLoading &&
                fieldsResponse?.results?.map((category: ISelection) => (
                  <ItemManagement
                    key={category.id}
                    isLoading={false}
                    isActive={categoryChoosed && category.id == categoryChoosed.id}
                    label={t(category.languageKey)}
                    onClick={() => onChangeCategory(category)}
                    icon="Folder"
                    iconType="feather"
                  />
                ))}
            </nav>
          </div>
        </CardManagement>
      </div>
      <div className="col-8">
        <CardManagement
          title={<SpanLang keyLang="crm_new_generalsetting_groups" />}
          labelBtn={<SpanLang keyLang="crm_new_generalsetting_add_group" />}
          isAdd={categoryChoosed?.isBase ? false : true}
          disabled={loading}
          onAdd={() => onStartAddItem({ item: categoryChoosed })}
        >
          <div className="content-items">
            {!isFieldItemLoading &&
            (!fieldItemsResponse?.results || fieldItemsResponse?.results?.length == 0) ? (
              <NoDataIcon
                style={{
                  height: 'calc(100vh - 240px)',
                }}
              />
            ) : (
              <TreeFolder
                groups={groups}
                onStartEditItem={onStartEditItem}
                onDeleteItem={handleDelete}
                onStartAddItem={onStartAddItem}
                onExpand={onExpand}
              />
            )}
          </div>
          <Canvas
            isVisible={openAddItem}
            onCloseSideBar={closeAddItem}
            className={'layout-sidebar-container'}
            width="auto"
            backdrop={false}
          >
            <Canvas.Header
              title={
                <SpanLang
                  keyLang={
                    itemChoosed.id
                      ? 'crm_new_generalsetting_edit_group'
                      : 'crm_new_generalsetting_add_group'
                  }
                />
              }
            />
            <Canvas.Body>
              <div className="container-canvas pd-20">
                <form>
                  {itemParentChoosed.id ? (
                    <div className="form-group">
                      <label htmlFor="formGroupExampleInput" className="d-block">
                        <SpanLang keyLang="crm_new_generalsetting_parent_group" />
                      </label>
                      <input
                        value={
                          itemParentChoosed.language &&
                          itemParentChoosed.language[userLang as keyof ILanguageData]
                            ? itemParentChoosed.language[userLang as keyof ILanguageData]
                            : ''
                        }
                        type="text"
                        className="form-control"
                        placeholder={t('crm_new_generalsetting_parent_group_name')}
                        disabled
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="form-group">
                    <label htmlFor="formGroupExampleInput" className="d-block">
                      <SpanLang keyLang="crm_new_generalsetting_group_name" />
                    </label>
                    <LangInput
                      uid={'name_category'} //DOM unique key in one page
                      disabled={false}
                      value={nameItem} //ex: [{id, value}]
                      onChange={(data) => setNameItem(data)}
                    />
                  </div>
                </form>
              </div>
            </Canvas.Body>
            <Canvas.Footer onClose={closeAddItem} onSave={onSaveItem} saveLoading={loading} />
          </Canvas>
        </CardManagement>
      </div>
    </div>
  );
};
export default ManageGroupFieldsPage;
