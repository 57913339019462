import { lazy } from 'react';

export const ListDesktopLayout1 = lazy(
  () => import('@settings/process/layouts/desktop/layout1/list/ListLayout'),
);
export const DesktopLayout1View = lazy(
  () => import('@settings/process/layouts/desktop/layout1/view/ViewLayout'),
);
export const DesktopLayout1WriteSetting = lazy(
  () => import('@settings/process/layouts/desktop/layout1/write/SettingWriteLayout'),
);
export const DesktopLayout1WriteAutomation = lazy(
  () => import('@settings/process/layouts/desktop/layout1/write/AutomationWriteLayout'),
);
