import { ISideMenuItem } from '@base/types/interfaces/app';
import { DeskPage, ProductPage, ActivityPage, CustomerPage } from './pages/main-page';

export const SUB_MENUS: ISideMenuItem[] = [
  {
    id: 'desk_settings',
    default: true,
    keyName: 'desk',
    path: 'desk/*',
    languageKey: 'Desk', //'crm_new_setting_format_setting',
    icon: {
      icon: 'helpdesk',
      iconType: 'main',
    },
    layouts: [],
    component: DeskPage,
    // componentView: DeskPage,
  },
  {
    id: 'product_settings',
    default: true,
    keyName: 'product',
    path: 'product/*',
    languageKey: 'Product', //'crm_new_setting_format_setting',
    icon: {
      icon: 'product',
      iconType: 'main',
    },
    layouts: [],
    component: ProductPage,
    // componentView: DeskPage,
  },
  {
    id: 'activity_settings',
    default: true,
    keyName: 'activity',
    path: 'activity/*',
    languageKey: 'Activity', //'crm_new_setting_format_setting',
    icon: {
      icon: 'activity',
      iconType: 'main',
    },
    layouts: [],
    component: ActivityPage,
  },
  {
    id: 'customer_settings',
    default: true,
    keyName: 'customer',
    path: 'customer/*',
    languageKey: 'Customer', //'crm_new_setting_format_setting',
    icon: {
      icon: 'customer',
      iconType: 'main',
    },
    layouts: [],
    component: CustomerPage,
  },
];
