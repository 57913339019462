import { gql } from 'graphql-request';

export const getListQuery = (schemas: string) => {
  return gql`
    query q($filter: SearchFilter) {
      setting_landingPages(filter: $filter) {
        results {
          ${schemas}
        }
      }
    }
  `;
};

export const LANDINGPAGE_GET_LIST = gql`
  query q($filter: SearchFilter) {
    setting_landingPages(filter: $filter) {
      paging {
        totalItems
        totalPage
        currentPage
      }
      results {
        id
        name
        type {
          value
          label
          languageKey
        }
        language
        products {
          id
          name
        }
        description
        assignTo {
          id
          name
        }
        title
        createdBy {
          id
          name
          fullName
        }
        createdAt
        updatedBy {
          id
          name
          fullName
        }
        updatedAt
      }
    }
  }
`;

export const LANDINGPAGE_DELETE = gql`
  mutation q($ids: String!) {
    setting_deleteLandingPage(ids: $ids) {
      ids
    }
  }
`;

export const LANDINGPAGE_CREATE = gql`
  mutation q($landingPage: LandingPageData!) {
    setting_createLandingPage(landingPage: $landingPage) {
      id
    }
  }
`;

export const LANDINGPAGE_UPDATE = gql`
  mutation m($landingPage: LandingPageData!) {
    setting_updateLandingPage(landingPage: $landingPage) {
      id
    }
  }
`;
