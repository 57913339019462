import BasicTable from '@base/components/table/basic';
import withLoading from '@base/hooks/hocs/withLoading';
import { useCallback, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { Button } from '@base/components/form';
import { useMergeFields } from '@settings/general-setting//hooks/usePersonalize';
import { IMergeField } from '@settings/general-setting/types/interfaces/personalize';
import WriteMergeField from './write';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { DELETE_MERGE_FIELD } from '@settings/general-setting/services/graphql/personalize';

const MergeField = () => {
  const [showAdd, setShowAdd] = useState(false);
  const { data: mergeFields, refetch } = useMergeFields();
  // console.log(mergeFields);

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_MERGE_FIELD,
    'setting_deleteMergeField',
    {
      onSuccess: (data: any) => {
        setTimeout(() => {
          refetch();
        }, 500);
        // console.log('success data', data);
        toast.success('Delete mergefield successfully!');
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Delete mergefield failed');
      },
    },
  );

  const onDelete = useCallback((id: string) => {
    mutationDelete.mutate({ id: id });
  }, []);

  const onAdd = useCallback((val: boolean) => {
    setShowAdd(val);
  }, []);

  const columnsTable = useMemo(
    () => [
      {
        Header: 'Placeholder',
        width: '40%',
        accessor: (row: IMergeField, rowIndex: any) => {
          return `$$${row.menuTag ? row.menuTag + '.' : ''}${row.fieldTag}$$`;
        },
      },
      {
        Header: 'Replacement',
        width: '40%',
        accessor: (row: IMergeField, rowIndex: any) => {
          return row.replace;
        },
      },
      {
        Header: 'Delete',
        width: '20%',
        accessor: (row: IMergeField, rowIndex: any) => (
          <div className="d-flex justify-content-center">
            <button type="button" className="btn btn-icon" onClick={() => onDelete(row.id)}>
              <X className="tx-danger" />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <div className="card">
      <div
        className="card-header d-flex tx-semibold bg-light align-items-center"
        style={{ padding: '10px 20px' }}
      >
        <div style={{ flex: 1 }}>Merge Field</div>
        <Button color="primary" icon="Plus" className="btn-sm" onClick={() => onAdd(true)}></Button>
      </div>
      <BasicTable
        wrapperClassName=""
        className="settings-tb"
        columns={columnsTable}
        data={mergeFields?.results ?? []}
      />
      {showAdd && (
        <div className="card-footer">
          <WriteMergeField onAdd={onAdd} refetch={refetch} />
        </div>
      )}
    </div>
  );
};

export default withLoading(MergeField);
