import { useState } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';

import { LabelValue } from '@base/types/types/app';
import { CRITERIA_TYPES } from '@settings/process/components/criteria';
import statusAtom from '@settings/process/recoil/status';

import Rule from './CriteriaRule';
import Status from './CriteriaStatus';

interface CriteriaWriteProps {}

function CriteriaWrite(props: CriteriaWriteProps) {
  const statusesValue = useRecoilValue(statusAtom);
  const statusCount = statusesValue.length;
  const [criteriaType, setCriteriaType] = useState<LabelValue>(CRITERIA_TYPES[0]);

  return (
    <div className="form-row">
      {statusesValue.length > 0 && (
        <div className="form-group col-12">
          <div className="pd-15 bg-light rounded">
            {statusesValue.map((status, index) => {
              if (statusCount == index + 1) return;
              return (
                <div className="row" key={status.id}>
                  <div className="col-lg-3">
                    <div className="diagram-item diagram-criteria with-boolean-direction">
                      <div className="criteria-shape"></div>
                      <div className="diagram-item-name">{status.button}</div>
                      <div className="direction-true"></div>
                      <div className="direction-false"></div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <Rule
                      id={status.id}
                      name={status.button}
                      option={status.criteria!!}
                      mode={'edit'}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {statusesValue.length > 0 && criteriaType.value === 'boolean' && <Status mode={'edit'} />}
    </div>
  );
}

export default CriteriaWrite;
