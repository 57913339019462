import * as components from './components';

export default {
  desktop: {
    layout1: {
      setting: components.SettingFormDesktopLayout1,
    },
  },
  tablet: {
    layout1: {},
  },
  mobile: {
    layout1: {},
  },
};
