import React, { useCallback, useEffect, useMemo } from 'react';
import usePost from '@base/hooks/usePost';
import { HISTORY_MODULE_PROCESS, UPDATE_MODULE_PROCESS } from '@settings/process/services/process';
import {
  BusinessStatus,
  Checklist,
  ModuleProcess,
  ModuleStep,
} from '@settings/process/types/process';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { CheckCircle, ChevronDown, Circle } from 'react-feather';
import Loading from '@base/components/loading';
import { StageBar } from '@settings/process/types/diagram';
import _ from 'lodash';
import classNames from 'classnames';
import { Checkbox } from '@base/components/form';
import { IdName } from '@base/types/interfaces/common';

interface StatusBarProps {
  docId: string;
  processId: string;
  menu: string;
}

function StatusBar(props: StatusBarProps) {
  // console.log('process status', props);
  const { docId, processId, menu } = props;
  const {
    data: processData,
    refetch: refethProcess,
    isLoading,
  } = usePost<ModuleProcess>(
    ['history_moduleProcess', menu, docId],
    HISTORY_MODULE_PROCESS,
    {
      source: {
        menu: menu,
        id: docId,
      },
      processId: processId,
    },
    {
      cacheTime: 0,
      enabled: !!processId,
    },
  );

  const mutationUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_MODULE_PROCESS,
    'history_updateModuleProcess',
    {
      onSuccess: (data: any) => {
        setTimeout(() => {
          refethProcess();
        }, 500);
        // console.log('success data', data);
      },
      onError: (error: any) => {
        // console.log('failed', error);
      },
    },
  );

  const onChangeStatus = useCallback(
    (status: BusinessStatus, ck?: IdName) => {
      const { id: stepId } = status.nextStep ?? { id: '' };
      if (status.direction !== 'DIRECTION_STAYING' && stepId == '') {
        alert('There is nothing next step');
      } else {
        const prev = processData?.steps.find((step) => step.step.id == status.step) as ModuleStep;
        let updateData: any = {
          source: {
            id: docId,
            menu: menu,
          },
          processId: processId,
          prev: {
            step: prev.step.id,
            status: prev.status.id,
            sequence: prev.sequence ?? '',
          },
          next: {
            step: stepId,
            status: status.id,
          },
        };
        if (ck) {
          updateData.next.checklist = ck;
        }
        if (prev.checklist) {
          updateData.prev.checklist = { id: prev.checklist };
        }
        mutationUpdate.mutate(updateData);
      }
    },
    [processData],
  );

  const onChecklist = useCallback(
    (ck: Checklist, lastChecklist: boolean, status: BusinessStatus) => {
      if (!lastChecklist) {
        status.direction = 'DIRECTION_STAYING';
        status.nextStep.id = '';
      }
      onChangeStatus(status, { id: ck.id, name: ck.name });
    },
    [processData],
  );

  const singleButtons = useMemo(() => {
    let buttons: BusinessStatus[] = [];
    if (processData && processData.steps) {
      for (const step of processData.steps) {
        if (step.statuses) {
          buttons = _.concat(
            buttons,
            step.statuses.filter((status) => status.view !== 'VIEW_MORE_BOX'),
          );
        }
      }
      const closed = processData.closed?.filter((status) => status.view !== 'VIEW_MORE_BOX');
      return _.concat(buttons, closed);
    } else {
      return buttons;
    }
  }, [processData]);

  const moreButtons = useMemo(() => {
    let buttons: BusinessStatus[] = [];
    if (processData && processData.steps) {
      for (const step of processData.steps) {
        if (step.statuses) {
          buttons = _.concat(
            buttons,
            step.statuses.filter((status) => status.view == 'VIEW_MORE_BOX'),
          );
        }
      }
      const closed = processData.closed?.filter((status) => status.view == 'VIEW_MORE_BOX');
      return _.concat(buttons, closed);
    } else {
      return buttons;
    }
  }, [processData]);

  const stagesBar = useMemo(() => {
    let stages: StageBar[] = [];
    if (processData && processData.stages) {
      for (const stage of processData.stages) {
        let steps: { step: string; status: string }[] = [];
        if (processData.steps) {
          for (const step of processData.steps) {
            if (step.stageId == stage.id) {
              steps.push({
                step: step.step.name,
                status: step.status.name,
              });
            }
          }
        }
        stages.push({
          id: stage.id,
          name: stage.name,
          steps: steps,
          property: stage.property,
        });
      }
    }
    return stages;
  }, [processData]);

  /*
  const SetDirection = useCallback((val: Direction) => {
    let direction = '';
    if (val == 'DIRECTION_FORWARD_OUTGOING_FORWARD') {
      direction = 'forward';
    } else if (val == 'DIRECTION_FORWARD_OUTGOING_BACKWARD') {
      direction = 'backward';
    } else if (val == 'DIRECTION_FORWARD_OUTGOING_JUMP') {
      direction = 'jump';
    } else if (val == 'DIRECTION_FORWARD_OUTGOING_MIDDLE') {
      direction = 'middle';
    } else if (val == 'DIRECTION_FORWARD_OUTGOING_PROCESS') {
      direction = 'process';
    } else if (val == 'DIRECTION_BACKWARD_OUTGOING_MIDDLE') {
      direction = 'l-middle';
    } else if (val == 'DIRECTION_BACKWARD_OUTGOING_FORWARD') {
      direction = 'l-forward';
    } else if (val == 'DIRECTION_BACKWARD_OUTGOING_BOTTOM') {
      direction = 'bottom backward';
    } else if (val == 'DIRECTION_FORWARD_OUTGOING_BOTTOM') {
      direction = 'bottom forward';
    } else if (val == 'DIRECTION_STAYING') {
      direction = 'staying';
    } else if (val == 'DIRECTION_NONE') {
      direction = 'none';
    }
    return direction;
  }, []);
  */

  useEffect(() => {
    if (processData && processData.steps?.[0].property == 'PROPERTY_NEW') {
      const updateData = {
        source: {
          id: docId,
          menu: menu,
        },
        processId: processId,
        prev: {
          step: processData.steps[0].step.id,
          status: processData.steps[0].status.id,
          sequence: '',
        },
        next: {
          step: '',
          status: 'property_new',
        },
      };
      mutationUpdate.mutate(updateData);
    }
  }, [processData]);

  const lastStageIndex = useMemo(() => {
    return stagesBar?.map((stage) => stage.steps && stage.steps.length > 0).lastIndexOf(true);
  }, [stagesBar]);

  return (
    <div className="w-100 bg-white pd-10 bd-b ht-140">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {processData && (
            <>
              <div className="pd-5 mg-b-10 d-flex justify-content-start">
                {singleButtons?.map((status) => {
                  // const direction = SetDirection(status.direction);
                  const currentStep = processData.steps.find((step) => step.step.id == status.step);
                  if (currentStep?.type == 'TYPE_CHECKLIST') {
                    let checkedId = '';
                    if (currentStep && currentStep.checklist) {
                      checkedId = currentStep.checklist;
                    }
                    const checklist: Checklist[] = JSON.parse(status.options ?? '');
                    const matchedIndex = checklist.findIndex((ck) => ck.id == checkedId);
                    const checklistCount = checklist.length;
                    return (
                      <React.Fragment key={status.id}>
                        <button
                          type="button"
                          className="btn btn-primary mg-l-10 rounded-5"
                          data-toggle="dropdown"
                        >
                          Checklist
                        </button>
                        <div className="dropdown-menu dropdown-menu-right pd-l-10">
                          {checklist.map((ck, index) => {
                            let checked = false;
                            if (index <= matchedIndex) {
                              checked = true;
                            }
                            let lastChecklist = false;
                            if (checklistCount == index + 1) {
                              lastChecklist = true;
                            }
                            return (
                              <Checkbox
                                key={ck.id}
                                label={ck.name}
                                checked={checked}
                                disabled={checked}
                                onChange={() => onChecklist(ck, lastChecklist, status)}
                              />
                            );
                          })}
                        </div>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      status.button && (
                        <button
                          key={status.id}
                          type="button"
                          className="btn btn-primary mg-l-10 rounded-5"
                          onClick={() => onChangeStatus(status)}
                        >
                          {status.button == '' || status.button == '-'
                            ? status.name
                            : status.button}
                        </button>
                      )
                    );
                  }
                })}

                {moreButtons && moreButtons.length > 0 && (
                  <div className="process-buttons mg-r-15">
                    <button type="button" className="btn btn-success mg-l-5" data-toggle="dropdown">
                      More
                      <span className="span-icon">
                        <ChevronDown />
                      </span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      {moreButtons.map((status) => {
                        // const direction = SetDirection(status.direction);
                        return (
                          status.button && (
                            <button
                              key={status.id}
                              type="button"
                              className="dropdown-item"
                              onClick={() => onChangeStatus(status)}
                            >
                              {status.button}
                            </button>
                          )
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <ol className="list-unstyled process-wrap text-nowrap">
                {stagesBar?.map((stage, index) => {
                  const isCurrent = index <= lastStageIndex;
                  return (
                    <li
                      key={stage.id}
                      className={classNames('process-item', {
                        current: isCurrent,
                      })}
                    >
                      <button type="button" className="btn process-item-btn">
                        {isCurrent ? <CheckCircle /> : <Circle />}
                      </button>
                      <div className="process-item-text mg-t-10">{stage.name}</div>
                      <div style={{ position: 'relative', left: '50%', top: '-40px' }}>
                        {stage.steps?.map((step, index) => {
                          return (
                            <div className="mg-t-5" key={index}>
                              <span className="tx-color-03 mg-r-5">{step.step}</span>
                              <span> &gt; {step.status}</span>
                            </div>
                          );
                        })}
                      </div>
                    </li>
                  );
                })}
              </ol>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default StatusBar;
