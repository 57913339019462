import PurchasedTable from '@settings/billing-license/components/purchased-table';
import React, { useMemo, useState } from 'react';

const BillAndPaymentItemPurchased: React.FC = () => {
  const [listType, setListType] = useState({
    isPeriod: true,
  });
  const purchasedItem: IPurchasedItem[] = [
    {
      name: 'CRM Add a user',
      purchasedDate: new Date(),
      billingDate: new Date('2020-05-04'),
      startDate: new Date('2020-05-04'),
      endDate: new Date('2021-05-04'),
      amount: 10,
    },
    {
      name: 'CRM 10 users',
      purchasedDate: new Date(),
      billingDate: new Date('2021-07-04'),
      startDate: new Date('2021-07-04'),
      endDate: new Date('2022-07-04'),
      amount: 10,
    },
  ];
  const columns = useMemo(
    () => [
      {
        Header: 'Item',
        accessor: (row: any) => {
          return <>{row.name}</>;
        },
      },
      {
        Header: 'Purchased on',
        accessor: (row: any) => {
          return <>{row.purchasedDate.toLocaleDateString()}</>;
        },
      },
      {
        Header: 'Billing Date',
        accessor: (row: any) => {
          return <>{row.billingDate.toLocaleDateString()}</>;
        },
      },
      {
        Header: 'Start on',
        accessor: (row: any) => {
          return <>{row.startDate.toLocaleDateString()}</>;
        },
      },
      {
        Header: 'End on',
        accessor: (row: any) => {
          return <>{row.endDate.toLocaleDateString()}</>;
        },
      },
      {
        Header: 'Amount',
        accessor: (row: any) => {
          return <>{row.amount}</>;
        },
      },
    ],
    [purchasedItem],
  );
  const onChange = (keyName: string, keyValue: boolean) => {
    if (keyName == 'isPeriod') {
      setListType({ ...listType, [keyName]: keyValue });
    }
  };
  return (
    <PurchasedTable
      columns={columns}
      purchasedItem={purchasedItem}
      listType={listType}
      onChange={(keyName: string, keyValue: boolean) => onChange(keyName, keyValue)}
    />
  );
};
export default BillAndPaymentItemPurchased;
