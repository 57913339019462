import { atom } from 'recoil';

export const layoutPreviewAtom = atom({
  key: 'LayoutPreviewAtom',
  default: {
    writeMode: 'preview',
    writeType: '',
    writeParam: {},
    isOpenWrite: false,
  },
});
