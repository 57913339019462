import { SpanLang } from '@base/components';
import { Priority, Switch } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_PRIORITY_SETTING } from '@settings/preferences/services/graphql';
import { usePrioritySetting } from '@settings/preferences/services/service';
import { IPriorityItem } from '@settings/preferences/types/interfaces';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

interface IPriorityProps {}
const PrioritySetting: React.FC<IPriorityProps> = (props: IPriorityProps) => {
  const [items, setItems] = useState<IPriorityItem[]>([]);
  const { data, isLoading } = usePrioritySetting();
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_PRIORITY_SETTING,
    'setting_updateMenuSetting',
  );
  const columns = useMemo(
    () => [
      {
        Header: ' ',
        accessor: (row: IPriorityItem, rowIndex: any) => {
          return (
            <>
              <Priority value={row.priority} />
            </>
          );
        },
        width: 'calc(100%-140px)',
      },
      {
        Header: 'Default',
        accessor: (row: IPriorityItem, rowIndex: any) => {
          return (
            <>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id={`pRadio${row.priority}`}
                  name={`pRadio${row.priority}`}
                  className="custom-control-input"
                  checked={row.isDefault}
                  onChange={() => {
                    onSetDefault(row);
                  }}
                />
                <label className="custom-control-label" htmlFor={`pRadio${row.priority}`}></label>
              </div>
            </>
          );
        },
        width: 70,
      },
    ],
    [items],
  );
  const onSave = (newItems: IPriorityItem[]) => {
    const nSetting = {
      menu: 'desk',
      key: 'priority',
      value: JSON.stringify(newItems),
    };
    mUpdate.mutate(
      { menuSetting: nSetting },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
        },
      },
    );
  };
  const onSetDefault = (row: IPriorityItem) => {
    const newItems = items.map((item: IPriorityItem) => {
      let isDefault = false;
      if (item.priority == row.priority) {
        isDefault = true;
      }
      return {
        ...item,
        isDefault: isDefault,
      };
    });
    setItems(newItems);
    // save data
    onSave(newItems);
  };
  const onActive = (row: IPriorityItem) => {
    const newItems = items.map((item: IPriorityItem) => {
      if (item.priority == row.priority) {
        return {
          ...item,
          active: !row.active,
        };
      }
      return item;
    });
    setItems(newItems);
    // save data
    onSave(newItems);
  };
  useEffect(() => {
    if (!isLoading && data?.value) {
      try {
        const slas: IPriorityItem[] = JSON.parse(data.value);
        setItems(slas);
      } catch (err: any) {}
    }
  }, [data]);

  return (
    <>
      <div className="card mg-b-15">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Priority</div>
        </div>
        <BasicTable
          columns={columns}
          data={items || []}
          className="settings-tb"
          wrapperClassName="remove-min-width"
          isLoading={isLoading}
        ></BasicTable>
      </div>
    </>
  );
};

export default PrioritySetting;
