import { useMemo } from 'react';
import usePost from '@base/hooks/usePost';
import { OptionValue } from '@base/types/interfaces/common';
import { GET_DEFINED_ITEMS } from '../services/custom';
import { DefinedItem, DefinedItems } from '@settings/process/types/process';
import { NodeType } from '../types/diagram';
import { stepDoCustom } from '../containers/view/diagram/step/StepWrite';

export type useDefinedItemProps = {
  stepType: NodeType;
  direction: string;
};

const useDefinedItem = ({
  stepType,
  direction,
}: useDefinedItemProps): [{ [index: string]: DefinedItem[] }, OptionValue[]] => {
  const { data: definedItems } = usePost<DefinedItems>(
    ['process_definedItems'],
    GET_DEFINED_ITEMS,
    {
      isTenant: false,
    },
    {
      cacheTime: 1000 * 5,
    },
  );

  const definedData = useMemo(() => {
    let items: { [index: string]: DefinedItem[] } = {};
    if (definedItems) {
      for (const item of definedItems.results) {
        if (!items[item.type]) {
          items[item.type] = [];
        }
        items[item.type].push(item);
      }
    }
    return items;
  }, [definedItems]);

  const definedOptions = useMemo<OptionValue[]>(() => {
    let options: OptionValue[] = [];
    if (definedData) {
      if (stepType == 'TYPE_ACTION' && definedData.TYPE_ACTION) {
        options.push(stepDoCustom);
        for (const action of definedData.TYPE_ACTION) {
          if (
            direction == 'DIRECTION_FORWARD_OUTGOING_BACKWARD' &&
            action.shape != 'SHAPE_BACKWARD'
          ) {
            continue;
          }
          if (
            direction == 'DIRECTION_FORWARD_OUTGOING_FORWARD' &&
            action.shape != 'SHAPE_FORWARD'
          ) {
            continue;
          }
          if (
            direction == 'DIRECTION_BACKWARD_OUTGOING_FORWARD' &&
            action.shape != 'SHAPE_BACKWARD'
          ) {
            continue;
          }
          options.push({
            keyName: action.id,
            languageKey: action.name,
          });
        }
      } else if (stepType == 'TYPE_CRITERIA' && definedData.TYPE_CRITERIA) {
        for (const criteria of definedData.TYPE_CRITERIA) {
          options.push({
            keyName: criteria.id,
            languageKey: criteria.name,
          });
        }
      }
    }
    return options;
  }, [stepType, definedData]);

  return [definedData, definedOptions];
};

export default useDefinedItem;
