import * as components from './components';

export default {
  desktop: {
    layout1: {
      list: components.ListDesktopLayout1,
      view: components.DesktopLayout1View,
      writeSetting: components.DesktopLayout1WriteSetting,
      writeAutomation: components.DesktopLayout1WriteAutomation,
    },
  },
  tablet: {
    layout1: {},
  },
  mobile: {
    layout1: {},
  },
};
