import * as keyNames from '@settings/online-digital-content/landing-page/config/key-names';
import * as components from '@settings/online-digital-content/landing-page/config/write-field/components';
import validators from '@base/utils/validation/field-validator';
import { LANDING_PAGE_TYPES, PUBLISH_SCHEDULE_OPTIONS } from '../constants';

export default {
  [keyNames.KEY_LANDINGPAGE_NAME]: {
    component: components.Input,
    componentProps: {
      menu: 'text',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_LANDINGPAGE_TYPE]: {
    component: components.LandingPageTypeSelect,
    componentProps: {
      options: LANDING_PAGE_TYPES,
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: LANDING_PAGE_TYPES[0],
    parseParam: (value: any) => {
      return value
        ? {
            value: value?.value,
            label: value?.label,
            languageKey: value?.languageKey,
          }
        : null;
    },
  },
  [keyNames.KEY_LANDINGPAGE_LANGUAGE]: {
    component: components.LanguageSelect,
    componentProps: {},
    showFullRow: true,
    defaultValue: 'en',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_LANDINGPAGE_PRODUCT]: {
    component: components.ProductAutoComplete,
    validate: {},
    defaultValue: null,
    showFullRow: true,
    parseParam: (value: any) => {
      return value?.length > 0
        ? value?.map((v: any) => {
            return {
              id: v?.id,
              name: v?.name,
            };
          })
        : [];
    },
  },
  [keyNames.KEY_LANDINGPAGE_DESCRIPTION]: {
    component: components.TextArea,
    validate: {},
    defaultValue: '',
    showFullRow: true,
    //parseValue: (valueApi: any) => valueApi || [],
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_LANDINGPAGE_APPROVAL]: {
    component: components.Switch,
    showFullRow: true,
    validate: {},
    defaultValue: true,
    parseParam: (value: boolean) => value,
  },
  [keyNames.KEY_LANDINGPAGE_ASSIGN_REP]: {
    component: components.UserAutoComplete,
    componentProps: {
      single: false,
      showAvatar: true,
    },
    showFullRow: true,
    validate: {},
    defaultValue: [],
    parseParam: (_ele: any) => {
      if (_ele.length == 0) {
        return _ele;
      }

      return _ele.map((v: any) => {
        const group = v.properties?.crmGroups?.length > 0 ? v.properties.crmGroups[0] : null;
        return {
          user: {
            id: v.id,
            name: v.name,
          },
          group: group
            ? {
                id: group.id,
                name: group.name,
              }
            : {},
        };
      });
    },
  },
  [keyNames.KEY_LANDINGPAGE_TEMPLATE]: {
    component: components.LandingPageTemplateSelect,
    componentProps: {},
    showFullRow: true,
    validate: {},
    defaultValue: null,
    parseParam: (value: any) => {
      if (value && value?.id) {
        return {
          id: value?.id,
          name: value?.name,
        };
      } else {
        return null;
      }
    },
  },
  [keyNames.KEY_LANDINGPAGE_TITLE]: {
    component: components.Input,
    componentProps: {
      type: 'text',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_LANDINGPAGE_AB_TEST]: {
    component: components.Switch,
    componentProps: {},
    validate: {},
    defaultValue: false,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_LANDINGPAGE_DESGIN]: {
    component: components.GrapesTS,
    componentProps: {
      height: 'calc(100vh - 70px)',
      storageId: 'landingpage-gts',
      templateType: 'full',
    },
    showFullRow: true,
    validate: {},
    defaultValue: null,
    parseParam: (value: any) => {
      return JSON.stringify(value);
    },
  },
  [keyNames.KEY_LANDINGPAGE_META_KEYWORD]: {
    component: components.Input,
    componentProps: {
      type: 'text',
    },
    validate: {},
    defaultValue: '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_LANDINGPAGE_META_DESC]: {
    component: components.TextArea,
    componentProps: {},
    showFullRow: true,
    validate: {},
    defaultValue: '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_LANDINGPAGE_PUBLISH]: {
    component: components.RadioGroup,
    componentProps: {
      isVertical: true,
      options: PUBLISH_SCHEDULE_OPTIONS,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    showFullRow: true,
    validate: {},
    defaultValue: PUBLISH_SCHEDULE_OPTIONS[0],
    parseParam: (value: any) => value?.value,
  },
  [keyNames.KEY_LANDINGPAGE_IMPLEMENTING_COST]: {
    component: components.Input,
    componentProps: {},
    showFullRow: true,
    validate: {},
    defaultValue: null,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_LANDINGPAGE_PUBLISH_DATE]: {
    component: components.DatePicker,
    defaultValue: new Date().toISOString(),
    parseValue: (valueApi: any) => valueApi || new Date().toISOString(),
    parseParam: (value: any) => {
      return new Date(value).toISOString();
    },
  },
};
