import { Input } from '@base/components/form';
import { useCallback, useState } from 'react';
import { Save, X } from 'react-feather';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { CREATE_MERGE_FIELD } from '@settings/general-setting/services/graphql/personalize';
import { toast } from 'react-toastify';

type writeMergeFieldProps = {
  // onAdd: Dispatch<SetStateAction<boolean>>;
  onAdd: (val: boolean) => void;
  refetch: any;
};

function WriteMergeField({ onAdd, refetch }: writeMergeFieldProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [placeHolder, setPlaceHolder] = useState('');
  const [replacement, setReplacement] = useState('');
  const onPlaceHolder = useCallback((newVal: string) => {
    setPlaceHolder(newVal);
  }, []);
  const onReplacement = useCallback((newVal: string) => {
    setReplacement(newVal);
  }, []);

  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_MERGE_FIELD,
    'setting_createMergeField',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any) => {
        setIsSaving(false);
        setTimeout(() => {
          refetch();
          onClose();
        }, 500);
        // console.log('success data', data);
        toast.success('Save mergefield successfully!');
      },
      onError: (error: any) => {
        setIsSaving(false);
        // console.log('failed', error);
        toast.success('Save mergefield failed');
      },
    },
  );

  const onSave = useCallback(() => {
    mutationAdd.mutate({
      mergeField: {
        fieldTag: placeHolder,
        replace: replacement,
      },
    });
  }, [placeHolder, replacement]);

  const onClose = useCallback(() => {
    onAdd(false);
  }, [onAdd]);

  return (
    <div className="row row-sm d-flex align-items-center">
      <div className="col-md-5">
        <label className="form-control-label">
          Placeholder:
          <span className="mg-l-5 tx-danger">*</span>
        </label>
        <Input value={placeHolder} onChange={(newValue: string) => onPlaceHolder(newValue)} />
      </div>
      <div className="col-md-5">
        <label className="form-control-label">
          Replacement:
          <span className="mg-l-5 tx-danger">*</span>
        </label>
        <Input value={replacement} onChange={(newValue: string) => onReplacement(newValue)} />
      </div>
      <div className="col-md-2 d-flex align-items-center justify-content-center">
        <button
          type="button"
          className="btn btn-xs btn-link tx-success btn-icon"
          onClick={onSave}
          disabled={isSaving}
        >
          {isSaving ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <Save />
          )}
        </button>
        <button type="button" className="btn btn-xs btn-link link-03 btn-icon" onClick={onClose}>
          <X />
        </button>
      </div>
    </div>
  );
}

export default WriteMergeField;
