import { ChangeEvent, useCallback } from 'react';
import { CheckBox, Lookup } from '@base/config/write-field/components';
import { DurationValue } from '@base/types/interfaces/common';
import Method from '@settings/process/components/method/MethodWrite';
import { Action } from '@settings/process/types/process';
import { useMenuTemplates } from '@base/services/setting-service';
import { ETemplateGroup, ETemplateGroupNum } from '@base/types/enums';
import _ from 'lodash';
import { useStepSettingAtomValue } from '@settings/process/recoil/process';
import log from '@base/utils/helpers/log';
import Duration from '@base/components/form/duration/edit';

interface ActionWriteProps {
  value: Action;
  onChange: (val: Action) => void;
}

function ActionWrite(props: ActionWriteProps) {
  const { value, onChange } = props;
  const stepSetting = useStepSettingAtomValue();

  const onTemplateChange = useCallback(
    (val: any) => {
      onChange && onChange({ ...value, template: { id: val.id, name: val.name } });
    },
    [value],
  );

  const onMethodChange = useCallback(
    (val: string) => {
      onChange && onChange({ ...value, method: val });
    },
    [value],
  );

  const onSendEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { checked },
      } = e;
      log(checked);
      onChange && onChange({ ...value, sendEmail: checked });
    },
    [value],
  );

  const onDurationChange = useCallback(
    (val: DurationValue) => {
      onChange && onChange({ ...value, duration: { time: val.duration, unit: val.durationUnit } });
    },
    [value],
  );

  return (
    <div className="form-row">
      {stepSetting.template && (
        <div className="form-group col-6">
          <label className="form-item-title">Template</label>
          <Lookup
            fetchList={useMenuTemplates}
            fieldValue={'id'}
            fieldLabel={'name'}
            extraParams={{ templateGroup: ETemplateGroupNum[ETemplateGroup.EMAIL] }}
            onChange={onTemplateChange}
            value={value.template}
            isMultiple={false}
            isSearch={false}
          />
        </div>
      )}
      {stepSetting.method && (
        <div className="form-group col-12">
          <Method value={value.method} onChange={onMethodChange} />
        </div>
      )}
      {stepSetting.email && (
        <div className="form-group col-6">
          <CheckBox
            label={'Send by email'}
            checked={value.sendEmail}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onSendEmailChange(e)}
          />
        </div>
      )}
      {stepSetting.due && (
        <div className="form-group col-6">
          <label className="form-item-title">Duration</label>
          <Duration
            value={{ duration: value.duration.time, durationUnit: value.duration.unit }}
            onChange={onDurationChange}
          />
        </div>
      )}
    </div>
  );
}

export default ActionWrite;
