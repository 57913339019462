import Icon from '@base/assets/icons/svg-icons';
import React, { lazy, useEffect, useRef, useState } from 'react';
import { Check, ChevronDown } from 'react-feather';
import { MENU_SETTING_LANDINGPAGE } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { useRecoilValue } from 'recoil';
import * as keyNames from '@settings/online-digital-content/landing-page/config/key-names';
import GrapesTSViewField from '@base/containers/view-field/grapes-ts';
import { default as viewConfig } from '@settings/online-digital-content/landing-page/config/view-field';
import {
  useDownloadObjectMutation,
  useStorageDownloadMutation,
} from '@base/hooks/useFileUploadMutation';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { base64URLDecode } from '@base/utils/helpers/base64';
import GrapesTS from '@base/components/grapes-ts';
import { Button } from '@base/components/form';
import Loading from '@base/components/loading';
import { toast } from 'react-toastify';

// const Notes = lazy(() => import('@base/containers/note'));

const ViewCenter: React.FC<any> = (props: any) => {
  const { id } = props;
  //state
  const [htmlJson, setHtmlJson] = useState<any>(null);

  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_SETTING_LANDINGPAGE));
  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
  const htmlData = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_LANDINGPAGE_DESGIN,
  );
  // const config = viewConfig[keyNames.KEY_LANDINGPAGE_DESGIN];

  // // console.log('html', htmlJson);

  //download mutation
  // const mS3Download: any = useDownloadObjectMutation<any>({
  //   onSuccess: (data: any, variables: any, context: any) => {
  //     const base64Data = data.substring(data.indexOf(',') + 1);
  //     const decodeData = base64URLDecode(base64Data);
  //     //// console.log('base64URLDecode', base64URLDecode(base64Data));
  //     setHtmlJson(decodeData);
  //   },
  //   onError: (error: any, variables: any, context: any) => {
  //     //// console.log('mutation error', error);
  //     //toast.error('There is an error in downloading image: ' + JSON.parse(error).message);
  //   },
  // });

  const mStorageDownload: any = useStorageDownloadMutation<BaseMutationResponse>({
    onSuccess: (data: any, variables: any, context: any) => {
      // // console.log('data', data);
      // const base64Data = data.substring(data.indexOf(',') + 1);
      // const decodeData = base64URLDecode(base64Data);
      //// console.log('base64URLDecode', base64URLDecode(base64Data));
      setHtmlJson(data);
    },
    onError: (error: any, variables: any, context: any) => {
      //// console.log('mutation error', error);
      toast.error('There is an error during uploading: ' + JSON.parse(error).message);
    },
  });

  useEffect(() => {
    if (htmlData?.data) {
      // const params = {
      //   url: htmlData.data, //"https://api.habin.io/v1/core/storage/object/r1kc5b5ix7bb9ku9gaioq69qda/123.json"
      // };
      //mS3Download.mutate(params);

      const params = {
        filename: htmlData.data,
        module: 'landingpage',
      };
      mStorageDownload.mutate(params);
    }
  }, [htmlData]);

  return (
    <div className="pd-20">
      {mStorageDownload.isLoading && <Loading />}
      {!mStorageDownload.isLoading && (
        <GrapesTS
          height={'calc(100vh - 235px)'}
          storageId={'landingpage-view-gts'}
          value={htmlJson ?? null}
          //onChange={onChange}
        />
      )}
      <div className="d-flex mg-t-10">
        <Button color="success" className="mg-l-auto">
          <Check className="mg-r-5" />
          Save
        </Button>
      </div>

      {/* <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Preview</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#LPPreview"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="LPPreview" className="card collapse show">
          <GrapesTSViewField
            keyName={keyNames.KEY_LANDINGPAGE_DESGIN}
            //value={JSON.parse(htmlData?.data ?? JSON.stringify({})) ?? null}
            value={JSON.parse(htmlJson ?? JSON.stringify({})) ?? null}
            userPermission={{ isEdit: true }}
            menuSourceId={id}
            menuSource={MENU_SETTING_LANDINGPAGE}
            viewConfig={viewConfig}
            config={config}
          />
        </div>
      </div> */}

      {/* <div className="view-content-section">
        <div className="view-content-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
            <span className="sr-only">드래그앤드롭으로 이동</span>
          </button>
          <span className="view-aside-section-header-title">Note</span>
          <button
            type="button"
            className="btn btn-link link-03 mg-l-auto"
            data-toggle="collapse"
            aria-label="collapse"
            data-target="#LPNote"
          >
            <ChevronDown />
            <span className="sr-only">내용 접기</span>
          </button>
        </div>
        <div id="LPNote" className="card collapse show">
          <React.Suspense fallback={<></>}>
            <Notes menuSource={MENU_SETTING_LANDINGPAGE} menuSourceId={id} />
          </React.Suspense>
        </div>
      </div> */}
    </div>
  );
};

export default ViewCenter;
