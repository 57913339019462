import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { useDiagramDetailAtomState } from '@settings/process/recoil/diagram';
import { DELETE_PROCESS } from '@settings/process/services/process';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ArrowLeft } from 'react-feather';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface HeaderViewProps {
  title: string | undefined;
}

function HeaderView({ title }: HeaderViewProps) {
  const [showDetail, setShowDetail] = useDiagramDetailAtomState();
  const { id: processId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_PROCESS,
    'process_deleteProcess',
    {
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Delete Process successfully!');
        setTimeout(() => {
          queryClient.invalidateQueries(['process_processes', 1]);
        }, 500);
        navigate('/settings/process/business');
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.success('Delete Process failed');
      },
    },
  );

  const deleteProcess = useCallback(() => {
    if (confirm('Do you want to delete this process?')) {
      mutationDelete.mutate({ ids: [processId] });
    }
  }, [processId]);

  return (
    <div className="process-header">
      <Link to={'/settings/process/business'} className="btn btn-link pd-l-0 pd-y-0 btn-back">
        <ArrowLeft />
      </Link>
      <h1 className="process-name pd-x-15 bd-l">{title}</h1>
      <button type="button" className="btn btn-warning mg-l-5" onClick={deleteProcess}>
        Delete
      </button>
      <nav className="nav nav-line mg-l-auto mg-r-30 mg-t-15 bd-0">
        <button
          className={`btn nav-link ${!showDetail ? 'active' : ''}`}
          onClick={() => setShowDetail(false)}
        >
          Simple view
        </button>
        <button
          className={`btn nav-link ${showDetail ? 'active' : ''}`}
          onClick={() => setShowDetail(true)}
        >
          Detail view
        </button>
      </nav>
    </div>
  );
}

export default HeaderView;
