import classnames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { Edge, Node } from '@settings/process/types/diagram';
import {
  useDiagramDetailAtomState,
  useShowStepActionAtomState,
} from '@settings/process/recoil/diagram';
import NodeEdit from './NodeEdit';
import classNames from 'classnames';
import React from 'react';
import EdgeAdd from '@settings/process/components/diagram/edge/EdgeAdd';
import { Target } from 'react-feather';

interface NodeCriteriaProps {
  processId: string;
  node: Node;
  stepHelper: any;
}

function NodeCriteria(props: NodeCriteriaProps) {
  const {
    node: {
      id: sourceId,
      data: { label },
      type: nodeType,
      position,
      className,
      property,
      shape,
      edges,
    },
    processId,
    stepHelper,
  } = props;

  const [showStepAction, setStepShowAction] = useShowStepActionAtomState();
  const [showDetail, setShowDetail] = useDiagramDetailAtomState();

  const onShowAction = useCallback(() => {
    setStepShowAction((old) => {
      return { id: sourceId, show: !old.show };
    });
  }, []);

  useEffect(() => {
    if (showStepAction.show) {
      setShowDetail(true);
    }
  }, [showStepAction]);

  const matchShow = useMemo(() => {
    if (showStepAction.id == sourceId && showStepAction.show) {
      return true;
    } else {
      return false;
    }
  }, [showStepAction, sourceId]);

  return (
    <>
      {(shape == 'SHAPE_FORWARD' || (shape == 'SHAPE_BACKWARD' && showDetail)) && (
        <div
          className={classnames('diagram-item-wrap', {
            active: matchShow,
          })}
          style={{ top: `${position.y}px`, left: `${position.x}px` }}
        >
          <div className={classNames('diagram-item diagram-criteria', className)}>
            <div className="criteria-shape"></div>
            <div className="diagram-item-name" onClick={onShowAction}>
              {label}
            </div>
            {matchShow && (
              <NodeEdit
                processId={processId}
                property={property}
                sourceId={sourceId}
                position={position}
                sourceType={nodeType}
                shape={shape}
              />
            )}
          </div>
          {edges?.map((edge: Edge) => {
            let styles: any = {};
            if (edge.sourceDirection == 'DIRECTION_FORWARD_OUTGOING_FORWARD') {
              styles = edge.height ? { height: `${edge.height}px` } : {};
              return (
                <div
                  className={classNames('', {
                    'diagram-direction-forward': edge.multiple == 'MULTIPLE_NONE',
                    'diagram-direction-forward with-split-choice':
                      edge.multipleOrder &&
                      edge.multipleOrder == 1 &&
                      edge.multiple == 'MULTIPLE_CHOICE',
                    'diagram-direction-split-choice':
                      edge.multipleOrder &&
                      edge.multipleOrder > 1 &&
                      edge.multiple == 'MULTIPLE_CHOICE',
                    'is-edit': matchShow,
                  })}
                  style={...styles}
                  key={edge.id}
                >
                  {!matchShow && edge.label && (
                    <div className="diagram-direction-text">{edge.label}</div>
                  )}

                  {matchShow && (
                    <>
                      {!edge.target ? (
                        edge.multipleOrder && edge.multipleOrder > 1 ? (
                          <EdgeAdd
                            style={{
                              left: '100%',
                              top: '100%',
                              marginLeft: '15px',
                              marginTop: '-10px',
                            }}
                            className={'btn-split'}
                            sourceId={sourceId}
                            sourceType={nodeType}
                            shape={shape}
                            edge={edge}
                            stepHelper={stepHelper}
                          />
                        ) : (
                          <EdgeAdd
                            style={{
                              left: '100%',
                              top: '100%',
                              marginTop: '-15px',
                              marginLeft: '15px',
                            }}
                            className={'btn-forward'}
                            sourceId={sourceId}
                            sourceType={nodeType}
                            shape={shape}
                            edge={edge}
                            stepHelper={stepHelper}
                          />
                        )
                      ) : (
                        <>
                          {(edge.multiple == 'MULTIPLE_JOIN' ||
                            edge.multiple == 'MULTIPLE_AND_JOIN') && (
                            <button type="button" className="btn btn-link btn-multiple">
                              <Target />
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              );
              // return (
              //   <div
              //     className={classNames('diagram-direction-forward', {
              //       'is-edit': matchShow,
              //     })}
              //     style={...styles}
              //     key={edge.id}
              //   >
              //     {!matchShow && edge.label && (
              //       <div className="diagram-direction-text">{edge.label}</div>
              //     )}{' '}
              //     {matchShow && !edge.target && (
              //       <EdgeAdd
              //         style={{
              //           left: '100%',
              //           top: '100%',
              //           marginTop: '-15px',
              //           marginLeft: '15px',
              //         }}
              //         className={'btn-forward'}
              //         sourceId={sourceId}
              //         sourceType={nodeType}
              //         shape={shape}
              //         edge={edge}
              //         stepHelper={stepHelper}
              //       />
              //     )}
              //   </div>
              // );
            } else if (edge.sourceDirection == 'DIRECTION_FORWARD_OUTGOING_BACKWARD') {
              return (
                <React.Fragment key={edge.id}>
                  {showDetail && (
                    <div
                      className={classNames(
                        'diagram-direction-vertical diagram-direction-backward diagram-direction-criteria',
                        {
                          'is-edit': matchShow,
                        },
                      )}
                    >
                      {!matchShow && edge.label && (
                        <div className="diagram-direction-text">{edge.label}</div>
                      )}
                      {matchShow && !edge.target && (
                        <EdgeAdd
                          style={{
                            left: '50%',
                            top: '100%',
                            marginTop: '16px',
                            marginLeft: '-13px',
                          }}
                          className={'btn-backward'}
                          sourceId={sourceId}
                          sourceType={nodeType}
                          shape={shape}
                          edge={edge}
                          stepHelper={stepHelper}
                        />
                      )}
                    </div>
                  )}
                </React.Fragment>
              );
            }
          })}
        </div>
      )}
    </>
  );
}

export default NodeCriteria;
