export const KEY_LANDINGPAGE_NAME = 'name';
export const KEY_LANDINGPAGE_TYPE = 'type';
export const KEY_LANDINGPAGE_LANGUAGE = 'language';
export const KEY_LANDINGPAGE_PRODUCT = 'products';
export const KEY_LANDINGPAGE_DESCRIPTION = 'description';
export const KEY_LANDINGPAGE_APPROVAL = 'approval';
export const KEY_LANDINGPAGE_ASSIGN_REP = 'assignTo';
export const KEY_LANDINGPAGE_TEMPLATE = 'template';
export const KEY_LANDINGPAGE_TITLE = 'title';
export const KEY_LANDINGPAGE_AB_TEST = 'abTest';
export const KEY_LANDINGPAGE_DESGIN = 'html';
export const KEY_LANDINGPAGE_META_KEYWORD = 'metaKeyword';
export const KEY_LANDINGPAGE_META_DESC = 'metaDescription';
export const KEY_LANDINGPAGE_PUBLISH = 'publish';
export const KEY_LANDINGPAGE_IMPLEMENTING_COST = 'implementingCost';
export const KEY_LANDINGPAGE_PREVIEW = 'preview';
export const KEY_LANDINGPAGE_CONTENT_TYPE = 'contentType';
export const KEY_LANDINGPAGE_VIEW = 'view';
export const KEY_LANDINGPAGE_CLICK = 'click';
export const KEY_LANDINGPAGE_CLICK_RATE = 'clickRate';
export const KEY_LANDINGPAGE_STAGE = 'stage';
export const KEY_LANDINGPAGE_PUBLISH_DATE = 'publishDate';

export const KEY_LANDINGPAGE_APPROVAL_BY = 'approvalBy';
export const KEY_LANDINGPAGE_CREATED_BY = 'createdBy';
export const KEY_LANDINGPAGE_CREATED_AT = 'createdAt';
export const KEY_LANDINGPAGE_UPDATED_BY = 'updatedBy';
export const KEY_LANDINGPAGE_UPDATED_AT = 'updatedAt';
