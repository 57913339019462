import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import '@settings/assignment-rule/assets/scss/style.scss';
import { DEFAULT_CATEGORY, MAIN_MENU, ListPage, ViewPage } from '../config/pages/main-page';
import { Helmet } from 'react-helmet-async';
import { ListType } from '@base/types/enums';

const getPreviousUrl = (params: any, pageData: any): string => {
  let prePath = '';
  // prePath empty in case params same with recoil data
  if (
    params.activeMenu == pageData.activeMenu &&
    params.activeId == pageData.activeId &&
    params.activeTab == pageData.activeTab
  ) {
    return prePath;
  }
  if (params.activeMenu == '' && pageData.activeMenu && pageData.activeMenu != '') {
    prePath = `/settings/assignment-rule/${pageData.activeMenu}`;
  }
  if (
    prePath != '' &&
    params.activeId == '' &&
    pageData.activeMenu &&
    pageData.activeMenu != '' &&
    pageData.activeId &&
    pageData.activeId != ''
  ) {
    prePath = `${prePath}/${pageData.activeId}`;
  }

  return prePath;
};

const MainPage: React.FC = () => {
  const params = useParams();
  // console.log('>>>>>>>>> params', params);
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MAIN_MENU));
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const navigate = useNavigate();
  const aParams = params['*'] ? params['*'].split('/') : [];
  const activeMenu = aParams[0] ? aParams[0] : DEFAULT_CATEGORY;
  const activeId = aParams[1] ? aParams[1] : '';
  const prePath = getPreviousUrl({ activeMenu, activeId }, pageData);
  // console.log('MainPage', pageData, activeMenu, activeId);
  // load menu language
  // useLanguageByMenu(['generalsetting']);
  const pageProps = {};
  // console.log('Sales Commission', pageData);
  useEffect(() => {
    let hasUpdate = false;
    if (activeMenu != '' && pageData.activeMenu != activeMenu) {
      hasUpdate = true;
    }
    if (pageData.activeId != activeId) {
      hasUpdate = true;
    }
    if (hasUpdate) {
      // set default filter
      let newData = {
        ...pageData,
        activeMenu: activeMenu,
        activeId: activeId,
      };
      setPageData(newData);
    }
    if (prePath != '') {
      // console.log('pageData prePath', prePath);
      navigate(prePath);
    }
  }, [prePath]);

  const { isSplitMode } = pageData;
  const setIsSplitMode = (isSplitMode: boolean) => {
    setPageData({
      ...pageData,
      listType: isSplitMode ? ListType.SPLIT : ListType.LIST,
      isSplitMode,
    });
  };
  const listProps = {
    isSplitMode,
    setIsSplitMode,
  };
  return (
    <>
      <Helmet>
        <title>Hanbiro CRM - Assignment Rule</title>
      </Helmet>
      <Routes>
        <Route path="list" element={<ListPage {...listProps} />} />
        <Route path=":id" element={<ViewPage />} />
        <Route path="/" element={<Navigate replace to="/settings/assignment-rule/list" />} />
      </Routes>
    </>
  );
};

export default MainPage;
