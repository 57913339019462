// ASSIGNMENT_RULE process
export const KEY_NAME_ASSIGNMENT_RULE_ID = 'id';
export const KEY_NAME_ASSIGNMENT_RULE_NAME = 'name';
export const KEY_NAME_ASSIGNMENT_RULE_DESC = 'description';
export const KEY_NAME_ASSIGNMENT_RULE_ACTIVE = 'active';
export const KEY_NAME_ASSIGNMENT_RULE_MODULE = 'module';
export const KEY_NAME_ASSIGNMENT_RULE_CRITERIA = 'criteria';
export const KEY_NAME_ASSIGNMENT_RULE_ASSIGNS_TO = 'assignsTo';
export const KEY_NAME_ASSIGNMENT_RULE_CREATED_AT = 'createdAt';
export const KEY_NAME_ASSIGNMENT_RULE_CREATED_BY = 'createdBy';
export const KEY_NAME_ASSIGNMENT_RULE_ENTRIES = 'rulesEntry';
export const KEY_NAME_ASSIGNMENT_RULE_ENTRY_ASSIGN_ID = 'assignRuleId';
export const KEY_NAME_ASSIGNMENT_RULE_ASSIGN_TO = 'assignTo';
export const KEY_NAME_ASSIGNMENT_RULE_ENTRY_ORDER = 'order';
