import usePost from '@base/hooks/usePost';
import usePosts from '@base/hooks/usePosts';
import { BaseResponse } from '@base/types/interfaces/response';
import { IAvailableCountry } from '@base/types/interfaces/setting';
import { ICurrency, IFormatSetting, ITimezoneSetting } from '../types/interfaces/format';
import { ISelection } from '../types/interfaces/selection';
import {
  GET_ALL_FORMAT_SETTING,
  GET_AVAILABLE_TIMEZONE,
  GET_LIST_COUNTRIES,
  GET_LIST_CURRENCIES,
  GET_MENU_SETTING,
  GET_MENU_SETTINGS,
  SETTING_SELECTION_FIELD_GET,
} from './graphql/format';

export const useFormatSettings = () => {
  let queryKey = ['setting_formatSettings'];

  const response = usePost<BaseResponse<IFormatSetting>>(queryKey, GET_ALL_FORMAT_SETTING, {});
  return response;
};

export const useTimezones = (keyword: string) => {
  let queryKey = ['setting_availableTimezones', keyword];

  const response = usePost<BaseResponse<ITimezoneSetting>>(queryKey, GET_AVAILABLE_TIMEZONE, {
    keyword,
  });
  return response;
};

export const useListCountries = (keyword: string) => {
  let queryKey = ['setting_availableCountries', keyword];

  const response = usePost<BaseResponse<IAvailableCountry>>(queryKey, GET_LIST_COUNTRIES, {
    keyword,
  });
  return response;
};
export const useListCurrencies = (keyword: string) => {
  let queryKey = ['setting_availableCurrencies', keyword];

  const response = usePost<BaseResponse<ICurrency>>(queryKey, GET_LIST_CURRENCIES, { keyword });
  return response;
};

export const useMenuSettings = (params: any) => {
  const usePostResult = usePosts<any[]>(
    ['setting_menusSettings', params.menus.join(','), params.keys.join(',')], //query keys
    GET_MENU_SETTINGS,
    params,
  );

  return usePostResult;
};

export const useMenuSetting = (params: any) => {
  const usePostResult = usePost<any>(
    ['setting_menuSetting', params.menu, params.key], //query keys
    GET_MENU_SETTING,
    params,
  );

  return usePostResult;
};

export const useSelectionFields = (params: any, options?: any) => {
  const usePostResult = usePosts<ISelection[]>(
    ['setting_selectionFields', params.filter.query], //query keys
    SETTING_SELECTION_FIELD_GET,
    params,
    options
  );

  return usePostResult;
};
