import BillAndPurchasedBillingPlan from '@settings/billing-license/containers/bill-and-payment/plan';
import BillAndPaymentInvoice from '@settings/billing-license/containers/bill-and-payment/invoice-issused';
import BillAndPaymentItemPurchased from '@settings/billing-license/containers/bill-and-payment/purchased';
import { useState } from 'react';

const BillAndPayment: React.FC = () => {
  const [submitValue, setSubmitValue] = useState({});
  const onChange = (keyName: string, keyValue: string) => {
    setSubmitValue({ ...submitValue, [keyName]: keyValue });
    // console.log(keyName, keyValue);
  };
  return (
    <div className="scroll-box pd-20">
      <div className="card-columns settings-columns">
        {/* Billing Plan */}
        <BillAndPurchasedBillingPlan />

        {/* Payment Schedule per a Item Purchased  */}
        <BillAndPaymentItemPurchased />

        {/* Invoice */}
        <BillAndPaymentInvoice />
      </div>
    </div>
  );
};
export default BillAndPayment;
