import { lazy } from 'react';

const Main = lazy(() => import('./main'));

export default [
  {
    path: '/settings/satisfaction-survey/*',
    component: Main,
  },
];
