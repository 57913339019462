import React, { useState, useEffect } from 'react';
import { Image, PlusCircle, Type, Video, Server } from 'react-feather';
import {
  Q_TITLE,
  Q_IMAGE,
  Q_VIDEO,
} from '@settings/online-digital-content/survey/config/constants';
import { useTranslation } from 'react-i18next';

//Survey title class
const SurveyToolbar: React.FC<any> = (props) => {
  const {
    focusS,
    focusQ,
    //scrollTop,
    onAddNewQuestion,
    onAddNewQuestionMedia,
    onAddNewSection,
  } = props;
  const { t } = useTranslation();

  const addNormalQEleId = 'q-addnormal-' + focusS + '-' + focusQ;
  const addTitleQEleId = 'q-addtitle-' + focusS + '-' + focusQ;
  const addImageQEleId = 'q-addimage-' + focusS + '-' + focusQ;
  const addVideoQEleId = 'q-addvideo-' + focusS + '-' + focusQ;
  const addSectionQEleId = 'q-addsection-' + focusS + '-' + focusQ;

  return (
    <div className="survey-write-content-toolbar-container" id="survey-toolbar">
      <div className="survey-write-content-toolbar">
        {/* first item */}
        <div
          className="survey-write-content-toolbar-item pd-t-8 han-tooltip han-tooltip--left"
          id={addNormalQEleId}
          data-han-tooltip={t('Add question')}
        >
          <a className="survey-write-content-toolbar-item-button" onClick={onAddNewQuestion}>
            <PlusCircle />
          </a>
        </div>
        {/* item 2 */}
        <div
          className="survey-write-content-toolbar-item pd-t-8 han-tooltip han-tooltip--left"
          id={addTitleQEleId}
          data-han-tooltip={t('Add title')}
        >
          <a
            className="survey-write-content-toolbar-item-button"
            onClick={() => onAddNewQuestionMedia(Q_TITLE)}
          >
            <Type />
          </a>
        </div>
        {/* item 3 */}
        {/* <div
          className="survey-write-content-toolbar-item pd-t-8 han-tooltip han-tooltip--left"
          id={addImageQEleId}
          data-han-tooltip={t('Add image')}
        >
          <a
            className="survey-write-content-toolbar-item-button"
            onClick={() => onAddNewQuestionMedia(Q_IMAGE)}
          >
            <Image />
          </a>
        </div> */}
        {/* item 4 */}
        <div
          className="survey-write-content-toolbar-item pd-t-8 han-tooltip han-tooltip--left"
          id={addVideoQEleId}
          data-han-tooltip={t('Add video')}
        >
          <a
            className="survey-write-content-toolbar-item-button"
            onClick={() => onAddNewQuestionMedia(Q_VIDEO)}
          >
            <Video />
          </a>
        </div>
        {/* item 5 */}
        <div
          className="survey-write-content-toolbar-item pd-t-8 pd-b-8 han-tooltip han-tooltip--left"
          id={addSectionQEleId}
          data-han-tooltip={t('Add section')}
        >
          <a className="survey-write-content-toolbar-item-button" onClick={onAddNewSection}>
            <Server />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SurveyToolbar;
