import React from 'react';
import CommonViewField from '@base/containers/view-field/common';
import View from './AutomationView';
import Write from './AutomationWrite';

function AutomationViewField(props: any) {
  return (
    <CommonViewField {...props} componentView={View} componentEdit={Write} value={props.value} />
  );
}

export default AutomationViewField;
