import React from 'react';
import { useRecoilValue } from 'recoil';
import { MENU_SETTING_CTA } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { ViewFields } from '@base/components/hook-form/view';
import Loading from '@base/components/loading';
import * as keyNames from '@settings/online-digital-content/cta/config/key-names';
import CTAPreview from '../cta-preview';

interface IDetailViewProps {
  id: string;
  category: string; //menu category
  //showTop?: boolean;
}

const DetailView: React.FC<IDetailViewProps> = (props) => {
  const { category, id } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_SETTING_CTA));

  // get fields by group
  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
  // const moreFields = viewData?.layout?.data?.[1]?.children || [];
  // // console.log('basicFields', basicFields);

  // hidden fields
  let ignoreFields: string[] = [
    keyNames.KEY_SETTING_CTA_CREATED_BY,
    keyNames.KEY_SETTING_CTA_CREATED_AT,
    keyNames.KEY_SETTING_CTA_UPDATED_BY,
    keyNames.KEY_SETTING_CTA_UPDATED_AT,
    keyNames.KEY_SETTING_CTA_IMAGE_URL,
    keyNames.KEY_SETTING_CTA_IMAGE_SIZE,
    keyNames.KEY_SETTING_CTA_IMAGE_ALT,
    keyNames.KEY_SETTING_CTA_PREVIEW,
    keyNames.KEY_SETTING_CTA_TEXT_VALUE,
    keyNames.KEY_SETTING_CTA_TEXT_BG_COLOR,
    keyNames.KEY_SETTING_CTA_TEXT_TEXT_COLOR,
    keyNames.KEY_SETTING_CTA_TEXT_FONT_SIZE,
    keyNames.KEY_SETTING_CTA_TEXT_FONT_WEIGHT,
    keyNames.KEY_SETTING_CTA_TEXT_ROUNDED,
    keyNames.KEY_SETTING_CTA_VALUE,
    keyNames.KEY_SETTING_CTA_TYPE,
    keyNames.KEY_SETTING_CTA_NAME,
    keyNames.KEY_SETTING_CTA_DESCRIPTION,
    keyNames.KEY_SETTING_CTA_LANGUAGE,
    keyNames.KEY_SETTING_CTA_PAGE_TITLE,
    keyNames.KEY_SETTING_CTA_CONTENT_TYPE,
    keyNames.KEY_SETTING_CTA_STAGE,
    keyNames.KEY_SETTING_CTA_RESOURCE,
  ];

  return (
    <div className="pd-t-15">
      <div className="scroll-box" style={{ height: 'calc(100vh - 160px)' }}>
        <div className="view-aside-section-body">
          <div className="form-row mg-x-0 mg-t-10">
            <React.Suspense fallback={<Loading />}>
              <ViewFields
                fields={basicFields}
                ignoreFields={ignoreFields}
                column={2}
                menu={viewData?.menu ?? ''}
                menuSource={viewData?.menuSource ?? ''}
                menuSourceId={viewData?.menuSourceId ?? ''}
              />
              {/* <CTAPreview /> */}
            </React.Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailView;
