import { Button, Switch } from '@base/components/form';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import BasicTable from '@base/components/table/basic';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { DELETE_DESK_CHANNEL, UPDATE_DESK_CHANNEL } from '@settings/preferences/services/graphql';
import { useChannels } from '@settings/preferences/services/service';
import { EChannelType, EDeskAssignType } from '@settings/preferences/types/enums';
import { IDeskChannel } from '@settings/preferences/types/interfaces';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { toast } from 'react-toastify';
import NewChannel from './new';
import { ATYPE_OPTIONS, CTYPE_OPTIONS } from '@settings/preferences/config/constant';

interface IDeskChannelsProps {}
const DeskChannels: React.FC<IDeskChannelsProps> = (props: IDeskChannelsProps) => {
  const [editItem, setEditItem] = useState<IDeskChannel | null>(null);
  const [items, setItems] = useState<IDeskChannel[]>([]);
  const { data, isLoading, isFetching, refetch } = useChannels('');
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_DESK_CHANNEL,
    'desk_updateChannel',
  );

  const mDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_DESK_CHANNEL,
    'desk_deleteChannel',
  );
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setEditItem(null);
    setIsOpen(false);
  };
  const onSetDefault = (id: string) => {
    const nItems = items.map((item) => {
      let isDefault = false;
      if (item.id === id) {
        isDefault = true;
      }
      return {
        ...item,
        isDefault: isDefault,
      };
    });
    mUpdate.mutate(
      {
        channel: {
          id,
          isDefault: true,
        },
      },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
        },
      },
    );
    setItems(nItems);
  };
  const onActive = (id: string) => {
    let active = true;
    const nItems = items.map((item) => {
      if (item.id === id) {
        active = !item.active;
        return {
          ...item,
          active: !item.active,
        };
      }
      return item;
    });
    mUpdate.mutate(
      {
        channel: {
          id,
          active: active,
        },
      },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
        },
      },
    );
    setItems(nItems);
  };
  const onSave = (channel: IDeskChannel, mode: string) => {
    let nItems = items;
    if (mode == 'add') {
      nItems = [channel, ...items];
    } else if (mode == 'update') {
      nItems = items.map((item) => {
        if (item.id == channel.id) {
          return channel;
        }
        return item;
      });
    }
    setItems(nItems);
    onClose();
  };
  const onDeleteChannel = (id: string) => {
    const nItems = items.filter((item) => {
      return item.id != id;
    });
    mDelete.mutate(
      { ids: [id] },
      {
        onSuccess: (res: any) => {
          toast.success('Data was removed!');
        },
      },
    );
    setItems(nItems);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Channel Name',
        accessor: (row: IDeskChannel, rowIndex: any) => {
          return <>{row.name}</>;
        },
      },
      {
        Header: 'Channel Type',
        accessor: (row: IDeskChannel, rowIndex: any) => {
          const found = CTYPE_OPTIONS.find((opt) => {
            return opt.value === row.type;
          });
          return <>{found ? found.label : row.type}</>;
        },
      },
      {
        Header: 'Assgined Rep',
        accessor: (row: IDeskChannel, rowIndex: any) => {
          let values: any[] = [];
          let showAvatar = false;
          if (row.assignType === EDeskAssignType.USER) {
            values = row.assignedUsers ?? [];
            showAvatar = true;
          } else if (row.assignType === EDeskAssignType.GROUP) {
            values = row.assignedGroups ?? [];
          }
          return (
            <>
              <ListTableCellDroplist values={values} showAvatar={showAvatar} />
            </>
          );
        },
      },
      {
        Header: 'Descriptions',
        accessor: (row: IDeskChannel, rowIndex: any) => {
          return <>{row.description}</>;
        },
      },

      {
        Header: 'Active',
        accessor: (row: IDeskChannel, rowIndex: any) => {
          return (
            <>
              <div className="d-flex align-items-center">
                <Switch
                  value={row.active}
                  onChange={() => {
                    onActive(row.id);
                  }}
                />
                <Button
                  color="link"
                  icon="Edit2"
                  className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                  data-han-tooltip="수정"
                  onClick={() => {
                    setEditItem(row);
                    setIsOpen(true);
                  }}
                />
                {row.type === EChannelType.LANDING_PAGE && (
                  <Button
                    color=""
                    icon="X"
                    iconClass="tx-danger"
                    className="btn-icon btn-delete-row han-tooltip--left pd-y-0"
                    data-han-tooltip="삭제"
                    onClick={() => {
                      onDeleteChannel(row.id);
                    }}
                  />
                )}
              </div>
            </>
          );
        },
      },
    ],
    [items],
  );
  useEffect(() => {
    if (!isFetching && data) {
      setItems(data.results ?? []);
    }
  }, [data]);
  return (
    <>
      <div className="pd-20 scroll-box setting-tab-body">
        <div className="mg-b-10 text-right">
          <Button
            color="primary"
            icon="Plus"
            name="Add Channel"
            onClick={() => {
              setIsOpen(true);
            }}
          />
          <button
            type="button"
            className="btn btn-icon btn-white mg-l-5"
            aria-label="reload"
            onClick={() => refetch()}
          >
            <RefreshCw />
            <span className="sr-only">새로고침</span>
          </button>
        </div>

        <BasicTable
          columns={columns}
          data={items || []}
          isLoading={isLoading}
          className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete"
          // wrapperClassName=""
        ></BasicTable>
      </div>
      {isOpen && (
        <>
          <NewChannel isOpen={isOpen} onClose={onClose} onSave={onSave} data={editItem} />
        </>
      )}
    </>
  );
};

export default DeskChannels;
