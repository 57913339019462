import React, { useState, useEffect, useCallback, lazy } from 'react';
import { toast } from 'react-toastify';
import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import Button from '@base/components/form/button';
import { ChevronUp } from 'react-feather';
import Field from '@base/components/hook-form/field';
import NoteAndError from '@base/components/hook-form/error-note';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';

import { ADD_MENU_TEMPLATE } from '@settings/template/services/graphql';
import { finalizeParams } from './payload';
import { createTemplateThumbnail } from '@settings/template/utils/helper';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import * as keyNames from '@settings/template/config/key-names';
import { Input, Select } from '@base/components/form';
import {
  TEMPLATE_TYPE_OPTIONS,
  TEMPLATE_MESSAGE_TYPE_OPTIONS,
} from '@settings/template/config/constants';
import LanguageSelect from '@base/containers/language-select';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import { UserAutoComplete } from '@base/containers';

interface WriteFormProps {
  fullScreen: boolean;
  menuApi: string;
  onClose: () => void;
  onReload?: () => void;
  //with write form
  defaultValues: any;
  fields: any[];
  tabs: any[];
  getParams: any;
  templateGroup: string;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<WriteFormProps> = (props: WriteFormProps) => {
  const {
    fullScreen,
    menuApi,
    onClose,
    onReload,
    //withWriteForm
    defaultValues,
    fields,
    tabs,
    getParams,
  } = props;
  //const { t } = useTranslation();

  //state
  //const [isSaving, setIsSaving] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
    mode: 'onChange',
  });
  useEffect(() => {
    if (isReset) {
      reset(); //back to default values
    }
  }, [isReset]);

  //field value watching
  //const account = watch(KEY_NAME_INVOICE_CUST_ID);
  // // console.log('write form formState', isValid, errors);

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    ADD_MENU_TEMPLATE,
    'setting_createMenuTemplate',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Create Template successfully!');
        // refecth data
        onReload && onReload();
        // reset form data
        reset();
        onClose && onClose();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Create Template failed: ' + JSON.parse(error).message);
        // refecth data
      },
    },
  );

  //add new customer
  const onAddNew = (params: any) => {
    mutationAdd.mutate({ menuTemplate: params });
  };

  //submit form
  const onSubmit = (formData: any) => {
    // create thumbnail
    // createTemplateThumbnail()
    const configParams = getParams(formData); //generate api params by fields
    const newParams: any = finalizeParams(configParams, props.templateGroup);
    onAddNew(newParams);
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    // console.log('error', errors, e);
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-15 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                //setIsReset(false);
                handleSubmit((data) => onSubmit(data), onError)().then(() => {
                  //// console.log('<<< completed submit >>>', mutationAdd.isSuccess);
                  if (mutationAdd.isSuccess) {
                    onClose();
                  }
                });
              }}
              disabled={mutationAdd.isLoading || !isValid}
              loading={mutationAdd.isLoading}
              name="Save"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  //setIsReset(true);
                  handleSubmit((data) => onSubmit(data), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                name="Save and Create New"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  //// console.log('form values', watch()); //get form values when inputing
  // // console.log('form values', getValues()); //get form values when inputing
  // // console.log('form errors', errors);
  // // console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  let formHeight = Object.keys(errors).length > 0 ? 'calc(100vh - 360px)' : 'calc(100vh - 320px)';
  if (fullScreen) {
    formHeight = Object.keys(errors).length > 0 ? 'calc(100vh - 210px)' : 'calc(100vh - 170px)';
  }
  const renderFields = () => {
    let group = props.templateGroup;
    switch (group) {
      case 'task':
        return renderFieldsTask();
      case 'call':
        return renderFieldsCall();
      case 'knowledgebase':
        return renderFieldsKB();
      default:
        return renderFieldsSMS();
    }
  };
  const renderFieldsKB = () => {
    return (
      <div className="form-row pd-x-20">
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Template Name<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_NAME}
            control={control}
            rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Subject<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_SUBJECT}
            control={control}
            rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Type<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_TYPE}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Select options={TEMPLATE_TYPE_OPTIONS} value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Language</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_LANGUAGE}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <LanguageSelect value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Product</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_PRODUCT}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <ProductAutoComplete value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Description</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_DESCRIPTION}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Owner</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_OWNER}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <UserAutoComplete value={value} onChange={onChange} />
            )}
          />
        </div>
      </div>
    );
  };
  const renderFieldsCall = () => {
    return (
      <div className="form-row pd-x-20">
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Template Name<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_NAME}
            control={control}
            rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Subject<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_SUBJECT}
            control={control}
            rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Description</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_DESCRIPTION}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Type<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_TYPE}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Select options={TEMPLATE_TYPE_OPTIONS} value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Language</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_LANGUAGE}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <LanguageSelect value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Product</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_PRODUCT}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <ProductAutoComplete value={value} onChange={onChange} />
            )}
          />
        </div>

        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Owner</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_OWNER}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <UserAutoComplete value={value} onChange={onChange} />
            )}
          />
        </div>
      </div>
    );
  };
  const renderFieldsTask = () => {
    return (
      <div className="form-row pd-x-20">
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Template Name<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_NAME}
            control={control}
            rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Subject<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_SUBJECT}
            control={control}
            rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Type<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_TYPE}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Select options={TEMPLATE_TYPE_OPTIONS} value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Language</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_LANGUAGE}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <LanguageSelect value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Product</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_PRODUCT}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <ProductAutoComplete value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Description</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_DESCRIPTION}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Owner</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_OWNER}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <UserAutoComplete value={value} onChange={onChange} />
            )}
          />
        </div>
      </div>
    );
  };
  const renderFieldsSMS = () => {
    return (
      <div className="form-row pd-x-20">
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Template Name<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_NAME}
            control={control}
            rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Subject<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_SUBJECT}
            control={control}
            rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Message Type<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_SMS_TYPE}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Select options={TEMPLATE_MESSAGE_TYPE_OPTIONS} value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">
            Template Type<span className="tx-danger">*</span>
          </label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_TYPE}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'BUTTON'}
            render={({ field: { onChange, value } }) => (
              <Select options={TEMPLATE_TYPE_OPTIONS} value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Language</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_LANGUAGE}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <LanguageSelect value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Product</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_PRODUCT}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <ProductAutoComplete value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Description</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_DESCRIPTION}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input type="text" value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className={classNames('form-group pd-0 col-12')}>
          <label className="form-item-title">Owner</label>
          <Controller
            name={keyNames.KEY_MENU_TEMPLATE_OWNER}
            control={control}
            //rules={{ required: true }}
            //defaultValue={'en'}
            render={({ field: { onChange, value } }) => (
              <UserAutoComplete value={value} onChange={onChange} />
            )}
          />
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  //// console.log('form api values', getParams(getValues())); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//
  return (
    <div className="pd-5">
      {/* <div className="bd-b pd-y-10">
        <Tabs
          className="bd-b-0 pd-15"
          tabs={tabs}
          tab={tab}
          onChange={(newTab) => newTab.value !== tab.value && setTab(newTab)}
        />
        <NoteAndError errors={errors} />
      </div> */}
      <div className="bd-b pd-b-10">
        <NoteAndError errors={errors} />
      </div>
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        <div
          className="pd-15 form-row"
          style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
        >
          {renderFields()}
        </div>
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(WriteForm);
