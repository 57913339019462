import { StatusWrite } from '@settings/process/components/diagram/status';
import {
  AutomationWrite,
  CriteriaWrite,
  WaitWrite,
  ActionWrite,
  SiteWrite,
  ChecklistWrite,
} from '@settings/process/config/write-field/components';
import { OptionValue } from '@base/types/interfaces/common';
import * as keyNames from '@settings/process/config/keyNames';
import { UserAutoComplete, SelectBox, Input, TextArea } from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';
import { Automation } from '@settings/process/types/process';
import { stepDoCustom } from '@settings/process/containers/view/diagram/step/StepWrite';

const StepWriteField = {
  [keyNames.KEY_NAME_STEP_NAME]: {
    component: Input,
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    languageKey: 'process_step_name',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_STEP_DESCRIPTION]: {
    component: TextArea,
    showFullRow: true,
    languageKey: 'process_step_description',
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_STEP_DO]: {
    component: SelectBox,
    showFullRow: false,
    languageKey: 'process_step_do',
    defaultValue: stepDoCustom,
    componentProps: {
      options: null,
      steptype: ['action', 'criteria'],
    },
    validate: {
      required: validators.required,
    },
    parseParam: (value: OptionValue) => value.keyName,
  },
  [keyNames.KEY_NAME_STEP_ACTION]: {
    component: ActionWrite,
    showFullRow: true,
    componentProps: {
      steptype: 'action',
    },
    defaultValue: {
      method: 'ACTION_METHOD_MANUAL',
      sendEmail: false,
      duration: {
        time: 86400,
        unit: 'UNIT_DAY',
      },
    },
    parseParam: (value: any) => {
      if (value.template) {
        return { ...value, template: value.template.id };
      } else {
        delete value.template;
        return value;
      }
    },
  },
  [keyNames.KEY_NAME_STEP_ASSIGN_USER]: {
    component: UserAutoComplete,
    componentProps: {
      single: false,
      showAvatar: true,
      placement: 'left',
      steptype: 'action',
    },
    showFullRow: true,
    defaultValue: [],
    languageKey: 'process_step_assign_user',
    parseParam: (value: any) => {
      if (value.length == 0) {
        return null;
      } else {
        return value.map((v: any) => {
          const group =
            v.properties?.crmGroups?.length > 0 ? v.properties.crmGroups[0] : { id: '', name: '' };
          return {
            user: {
              id: v.id,
              name: v.name,
            },
            group: {
              id: group?.id ?? '',
              name: group?.name ?? '',
            },
          };
        });
      }
    },
  },
  [keyNames.KEY_NAME_STEP_WAIT]: {
    component: WaitWrite,
    showFullRow: true,
    defaultValue: {
      type: 'WAIT_UNTIL_TRIGGER',
      datetime: null,
      schedule: {
        duration: {
          time: 86400,
          unit: 'UNIT_DAY',
        },
        when: 'WHEN_BEFORE',
        attr: '',
      },
      duration: {
        time: 86400,
        unit: 'UNIT_DAY',
      },
      trigger: '',
    },
    useTooltip: false,
    hideTitle: true,
    componentProps: {
      steptype: 'wait',
    },
    parseParam: (value: any) => {
      let wait: any = {
        type: value.type,
      };
      if (value.type == 'WAIT_UNTIL_DATE_TIME') {
        wait.datetime = value.datetime;
      } else if (value.type == 'WAIT_BY_DURATION') {
        wait.duration = {
          term: wait.duration.term,
          unit: wait.duration.unit,
        };
      } else if (value.type == 'WAIT_UNTIL_TRIGGER') {
        wait.trigger = value.trigger;
      } else if (value.type == 'WAIT_SCHEDULE_ATTRIBUTE') {
        wait.schedule = {
          duration: {
            term: wait.schedule.duration.term,
            unit: wait.schedule.duration.unit,
          },
          when: wait.schedule.when,
          attr: wait.schedule.attr,
        };
      } else {
        return null;
      }
      return wait;
    },
  },
  [keyNames.KEY_NAME_STEP_CRITERIA]: {
    component: CriteriaWrite,
    showFullRow: true,
    defaultValue: null,
    useTooltip: false,
    languageKey: 'process_step_criteria_rule',
    componentProps: {
      steptype: 'criteria',
      options: null,
    },
  },
  [keyNames.KEY_NAME_STEP_SITE]: {
    component: SiteWrite,
    showFullRow: true,
    defaultValue: null,
    useTooltip: false,
    hideTitle: true,
    componentProps: {
      steptype: 'site',
    },
  },
  [keyNames.KEY_NAME_STEP_CHECKLIST]: {
    component: ChecklistWrite,
    showFullRow: true,
    defaultValue: null,
    useTooltip: false,
    hideTitle: true,
    componentProps: {
      steptype: 'checklist',
    },
  },
  [keyNames.KEY_NAME_STEP_STATUSES]: {
    component: StatusWrite,
    showFullRow: true,
    defaultValue: null,
    useTooltip: false,
    hideTitle: true,
    componentProps: {
      steptype: ['action', 'simple', 'wait', 'site'],
    },
  },
  [keyNames.KEY_NAME_STEP_AUTOMATION]: {
    component: AutomationWrite,
    componentProps: {
      steptype: 'action',
    },
    hideTitle: true,
    defaultValue: {
      useSleeping: false,
      sleeping: {
        executes: [],
        duration: {
          time: 86400,
          unit: 'UNIT_DAY',
        },
      },
    },
    showFullRow: true,
    parseParam: (value: Automation) => {
      if (!value.useSleeping) return null;
      else {
        return {
          useSleeping: value.useSleeping,
          sleeping: {
            duration: value.sleeping.duration,
            executes: value.sleeping.executes.map((execute) => {
              return {
                useNotify: execute.useNotify,
                notifyId: execute.notify.id,
                statusId: execute.status.id,
                useChangeStep: execute.useChangeStep,
                changeStepId: execute.changeStep.id,
                useMywork: execute.useMywork,
              };
            }),
          },
        };
      }
    },
  },
};

export default StepWriteField;
