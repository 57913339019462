import { FormIcon, Tooltip } from '@base/components/form';
import { IconType } from '@base/types/types/app';
import { PREMISSION_CONFIG } from '@settings/page-layout/config/common';
import { EPermissionType } from '@settings/page-layout/types/enums';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import PlaceholderItem from '../placeholder/item';
import TextInputCustom from '../text-input-custom';
import { SpanLang } from '@base/components';
import ShowIn from '../show-in';
import ContexMenuItem from '../contex-menu-item';
import { isFieldEmptydOption } from '@settings/page-layout/utils/helpers';
interface IItemViewProps {
  permissionType: EPermissionType;
  options: any[];
  dataType: string;
  attributes: any[];
  label: any;
  setLabel: (params: any) => void;
  icon: string;
  iconType: IconType;
  isRequired: boolean;
  setIsRequired: (params: any) => void;
  locationsDisplay: any;
  setLocationsDisplay: (params: any) => void;
  onDelete: () => void;
  onEdit: () => void;
  isLoading: boolean;
  permission: any;
  onUnused: () => void;
}
const ItemView: React.FC<IItemViewProps> = (props: IItemViewProps) => {
  const {
    permissionType = EPermissionType.BASIC,
    options = [],
    dataType = '',
    attributes = [],
    label = '',
    setLabel = () => {},
    icon = '',
    iconType = 'icon',
    isRequired = false,
    setIsRequired = () => {},
    locationsDisplay = {
      showInList: false,
      showInView: false,
      showInWrite: false,
    },
    setLocationsDisplay = () => {},
    onDelete = () => {},
    onEdit = () => {},
    isLoading = false,
    permission = {},
    onUnused = () => {},
  } = props;
  const [labelInline, setLabelInline] = useState(label);

  useEffect(() => {
    setLabelInline(label);
  }, [label]);

  if (isLoading) {
    // console.log('itemLoading', isLoading);
    return <PlaceholderItem />;
  }

  return (
    <div
      className={classNames('page-layout-item-view bd', {
        ['page-layout-item-view-disabled']: !permission.canShowSettingButton,
      })}
      style={{
        borderLeft: `2px solid ${
          PREMISSION_CONFIG[permissionType as EPermissionType]?.color ?? '#596882'
        }`,
      }}
    >
      <div className={classNames('view-name')}>
        <div className="d-flex align-items-center pd-r-10">
          <div style={{ display: 'flex', flex: 1 }}>
            <div className={classNames('wd-20')}>
              <FormIcon icon={icon} iconType={iconType} />
            </div>
            <TextInputCustom
              label={label}
              isRequired={isRequired}
              setLabel={setLabel}
              isEdit={permission.canChangeFieldName}
              isBasicField={true}
            />

            {labelInline == '' && (
              <Tooltip overlay={'This value is require.'}>
                <FormIcon
                  icon="AlertCircle"
                  size={12}
                  color={'#dc3545'}
                  iconType="feather"
                  // style={{ marginLeft: 4 }}
                />
              </Tooltip>
            )}
          </div>
          {isFieldEmptydOption({
            dataType: dataType,
            options: options,
            attributes: attributes,
          }) && (
            <div className="is-required pd-l-10">
              <SpanLang className="tx-12" keyLang="crm_new_pagelayout_msg_enter_option" />
            </div>
          )}
        </div>
      </div>

      <ShowIn locationsDisplay={locationsDisplay} />
      <div>
        <ContexMenuItem
          permissionType={permissionType}
          locationsDisplay={locationsDisplay}
          setLocationsDisplay={setLocationsDisplay}
          isRequired={isRequired}
          setIsRequired={setIsRequired}
          onDelete={onDelete}
          onEdit={onEdit}
          canMarkRequired={permission.canMarkRequired}
          canShowInList={permission.canSetShowInList}
          canShowInView={permission.canSetShowInView}
          canShowInWrite={permission.canSetShowInWrite}
          canMoveUnused={permission.canMoveUnused}
          canEditProperty={permission.canEditProperty}
          canShowSettingButton={permission.canShowSettingButton}
          settingButtonTooltip={permission.settingButtonTooltip}
          canDelete={permission.canDelete}
          onUnused={onUnused}
        />
      </div>
    </div>
  );
};

export default ItemView;
