import React from 'react';
import { Button, FormIcon } from '@base/components/form';
import { ChevronDown } from 'react-feather';
import { ActionProperty } from '@settings/process/types/settings';

interface ToolbarProps {
  actions: ActionProperty[];
  onSelect: (action: ActionProperty) => void;
  color?: string | null;
}

function Toolbar(props: ToolbarProps) {
  const { actions, onSelect, color } = props;

  return (
    <>
      <Button
        className="dropdown-toggle-split pd-x-5"
        color={color ?? null}
        isSpanTitle={false}
        data-toggle="dropdown"
      >
        <ChevronDown />
      </Button>
      <div className="dropdown-menu dropdown-menu-right">
        {actions.map((action, i) => {
          return (
            <button key={i} className="dropdown-item" onClick={() => onSelect && onSelect(action)}>
              <FormIcon iconType={action?.iconType ?? 'feather'} icon={action?.icon} size={16} />
              <span className="mg-l-5">{action.label}</span>
            </button>
          );
        })}
      </div>
    </>
  );
}

Toolbar.defaultProps = {
  color: 'primary',
};

export default Toolbar;
