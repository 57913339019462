import Canvas from '@base/components/canvas';
import { DatePicker } from '@base/components/date-picker';
import { Input, RadioGroup, Select } from '@base/components/form';
import Portal from '@base/components/portal';
import { generateUUID } from '@base/utils/helpers';
import { BILLING_CYCLE_OPTION, RENEWAL_OPTION } from '@settings/billing-license/config/constant';
import { IBillingPlan } from '@settings/billing-license/types/interfaces/billing-plan';
import React, { useState } from 'react';

interface IWrite {
  isVisible: boolean;
  onClose: (params: any) => any;
  onSubmit: (params: any) => any;
}

const BillAndPaymentWrite: React.FC<IWrite> = (props: IWrite) => {
  const { isVisible, onClose, onSubmit } = props;
  const [submitData, setSubmitData] = useState<IBillingPlan>({
    id: generateUUID(),
    name: '',
    billingCycle: {
      value: '',
      label: '',
    },
    isRenewAuto: true,
    timesRenewal: 0,
    startDate: new Date(),
    endDate: new Date(new Date().setMonth(new Date().getMonth() + 6)),
  });
  return (
    <Portal>
      <Canvas
        isVisible={isVisible}
        onCloseSideBar={onClose}
        onClose={onClose}
        className={'page-sidebar-container-wrap'}
        customStyles={{ width: '420px' }}
      >
        <Canvas.Header title="New Billing Plan" />
        <Canvas.Body style={{ height: 'calc(100vh - 130px)', width: '420px', padding: '15px' }}>
          <label className="tx-color-03 mt-3">Plan Name</label>
          <Input
            value={submitData.name}
            onChange={(data: any) => setSubmitData({ ...submitData, name: data })}
          />
          <label className="tx-color-03 mt-3">Billing Cycle</label>
          <Select
            options={BILLING_CYCLE_OPTION}
            value={submitData.billingCycle}
            onChange={(data: any) => {
              setSubmitData({ ...submitData, billingCycle: data });
            }}
          />
          <ul className="list-group border-0 mt-3">
            <li className="d-flex align-items-center justify-content-between bg-white border-0">
              <RadioGroup
                // value={action=='view' ? selectedOpt :submitValue.selectedValue}
                value={submitData.isRenewAuto ? RENEWAL_OPTION[0] : RENEWAL_OPTION[1]}
                options={RENEWAL_OPTION}
                isVertical={true}
                onChange={() => {
                  setSubmitData({ ...submitData, isRenewAuto: !submitData.isRenewAuto });
                }}
              />
              <Input
                type="number"
                className="wd-100 mg-x-5"
                min="0"
                value={submitData.timesRenewal}
                onChange={(data: any) => setSubmitData({ ...submitData, timesRenewal: data })}
              />
              <span>of billing cycles.</span>
            </li>
          </ul>
          <label className="tx-color-03 mt-3">Start on</label>
          <DatePicker
            selected={submitData.startDate || null}
            onChange={(data: any) => setSubmitData({ ...submitData, startDate: data })}
          ></DatePicker>
          <label className="tx-color-03 mt-3">End on</label>
          {/* <div>{selectedDate.setMonth(selectedDate.getMonth()+6)}</div> */}
          <div>{submitData.endDate?.toLocaleDateString()}</div>
          <label className="tx-color-03">
            It will be changed into the date the first purchased.
          </label>
          <div></div>
          <label className="tx-color-03 mt-3">Invoice Date</label>
          <div>1/11/2023</div>
          <label className="tx-color-03 mt-3">Billing Date</label>
          <div>1/11/2023</div>
        </Canvas.Body>
        <Canvas.Footer
          onClose={onClose}
          onSave={() => {
            onSubmit(submitData);
          }}
          saveLabel="Add"
          saveColor="success"
        />
      </Canvas>
    </Portal>
  );
};
export default BillAndPaymentWrite;
