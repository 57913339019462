export const KEY_TICKET_FORM_NAME = 'name';
export const KEY_TICKET_FORM_LANGUAGE = 'language';
export const KEY_TICKET_FORM_PRODUCTS = 'products';
export const KEY_TICKET_FORM_DESCRIPTION = 'description';
export const KEY_TICKET_FORM_TEMPLATE = 'template';
export const KEY_TICKET_FORM_TITLE = 'title';
export const KEY_TICKET_FORM_HTML = 'html';
export const KEY_TICKET_FORM_SUBMISSION_DISPLAY = 'submissionDisplay';
export const KEY_TICKET_FORM_SUBMISSION_BEHAVIOR = 'submissionBehavior';
export const KEY_TICKET_FORM_BEHAVIOR_CHECK = 'behaviorCheck';

export const KEY_TICKET_FORM_VIEWS = 'views';
export const KEY_TICKET_FORM_SUBMISSION = 'submissions';
export const KEY_TICKET_FORM_SUBMISSION_RATE = 'submissionRate';

export const KEY_TICKET_FORM_STAGE = 'stage';
