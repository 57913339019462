import { gql } from 'graphql-request';

export const GET_AVAILABLE_TIMEZONE = gql`
  query q($keyword: String) {
    setting_availableTimezones(filter: { keyword: $keyword }) {
      results {
        nationEn
        tzone
        sdtime
        sdutc
      }
      paging {
        totalItems
        totalPage
        currentPage
      }
    }
  }
`;

export const UPDATE_FORMAT_SETTING = gql`
  mutation q($key: String, $value: String) {
    setting_updateFormatSetting(key: $key, value: $value) {
      id
    }
  }
`;
export const GET_USED_TIMEZONE = gql`
  query {
    setting_usedTimezones {
      results {
        tzone
        isDefault
        sdutc
        nationEn
      }
    }
  }
`;
export const GET_LIST_CURRENCIES = gql`
  query q($keyword: String) {
    setting_availableCurrencies(filter: { keyword: $keyword }) {
      results {
        code
        currencyName
        currencySymbol
      }
      paging {
        totalItems
        totalPage
        currentPage
      }
    }
  }
`;

export const GET_ALL_FORMAT_SETTING = gql`
  query {
    setting_formatSettings {
      results {
        id
        menu
        key
        value
      }
    }
  }
`;

export const GET_LIST_COUNTRIES = gql`
  query q($keyword: String) {
    setting_availableCountries(filter: { keyword: $keyword }) {
      results {
        isoCode2
        isoCode3
        country
      }
    }
  }
`;

export const GET_MENU_SETTINGS = gql`
  query q($menus: [String], $keys: [String]) {
    setting_menusSettings(menus: $menus, keys: $keys) {
      results {
        id
        value
        key
        menu
      }
    }
  }
`;

export const GET_MENU_SETTING = gql`
  query q($menu: String, $key: String) {
    setting_menuSetting(menu: $menu, key: $key) {
      id
      key
      menu
      value
    }
  }
`;

export const UPDATE_MENU_SETTING = gql`
  mutation q($menuSetting: menuSetting) {
    setting_updateMenuSetting(menuSetting: $menuSetting) {
      id
    }
  }
`;

export const CREATE_MENU_SETTING = gql`
  mutation ($menuSetting: menuSetting) {
    setting_createMenuSetting(menuSetting: $menuSetting) {
      id
    }
  }
`;

export const DELETE_MENU_SETTING = gql`
  mutation ($menu: String, $key: String) {
    setting_deleteMenuSetting(menu: $menu, key: $key) {
      id
    }
  }
`;

export const DELETE_MENU_SETTING_BY_ID = gql`
  mutation ($menu: String, $key: String, $ids: ids) {
    setting_deleteMenuSetting(menu: $menu, key: $key, ids: $ids) {
      id
    }
  }
`;

export const COMMON_BARCODE_GENERATE = gql`
  mutation q(
    $content: String
    $width: Integer
    $height: Integer
    $margin: Integer
    $format: String
    $lineColor: String
    $background: String
    $textAlign: String
    $textPosition: String
  ) {
    common_generateBarCode(
      content: $content
      width: $width
      height: $height
      margin: $margin
      format: $format
      lineColor: $lineColor
      background: $background
      textAlign: $textAlign
      textPosition: $textPosition
    ) {
      image
    }
  }
`;

export const SETTING_SELECTION_FIELD_GET = gql`
  query q($filter: FilterInput) {
    setting_selectionFields(filter: $filter) {
      results {
        id
        keyName
        languageKey
        keyGroup
        isDefault
        isBase
        languageData {
          en
          vi
          ko
          jp
          zh
          ido
        }
      }
    }
  }
`;

export const SETTING_SELECTION_FIELD_UPDATE = gql`
  mutation ($selection: SelectionInput) {
    setting_updateSelection(selection: $selection) {
      id
    }
  }
`;

export const SETTING_SELECTION_CREATE = gql`
  mutation q($selection: SelectionInput) {
    setting_createSelection(selection: $selection) {
      id
    }
  }
`;

export const SETTING_SELECTION_DELETE = gql`
  mutation q($id: String, $selectType: string) {
    setting_deleteSelection(id: $id, selectType: $selectType) {
      id
    }
  }
`;
