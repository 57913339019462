import { lazy } from 'react';

export const Input = lazy(() => import('@base/components/form/input'));
export const TextArea = lazy(() => import('@base/components/form/text-area'));
export const ProductAutoComplete = lazy(
  () => import('@product/product/containers/product-auto-complete'),
);
export const LanguageSelect = lazy(() => import('@base/containers/language-select'));
export const TemplateSelect = lazy(
  () => import('@settings/online-digital-content/ticket-form/containers/template-select'),
);
export const GrapesTS = lazy(() => import('@base/components/grapes-ts'));
export const SubmissionDisplay = lazy(() => import('../../components/submission-display'));
export const SubmissionBehavior = lazy(() => import('../../components/submission-behavior'));
