import BasicTable from '@base/components/table/basic';
import { IUsageHistory } from '@settings/billing-license/types/interfaces/usage-history';
import React from 'react';
interface IDataTable {
  date: Date;
  userName: string;
  subjectName: string;
  amount: number;
}
interface IHistoryUsageTable {
  columns: any[];
  title: string;
  data: IDataTable[];
}
const UsageTable: React.FC<IHistoryUsageTable> = (props: IHistoryUsageTable) => {
  const { title, columns, data } = props;
  return (
    <div className="card">
      <div className="card-header bg-light">
        <h2 className="card-title h6 mg-b-0">{title}</h2>
      </div>
      <div className="table-responsive">
        <BasicTable columns={columns} data={data}></BasicTable>
      </div>
    </div>
  );
};
export default UsageTable;
