// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".general-setting-container .input-time-picker-custom{flex:1;border:1px solid var(--input-border-color) !important;border-right:0px !important;border-top-right-radius:0px;border-bottom-right-radius:0px;height:40px !important;width:100%;position:relative;display:flex;align-items:center;background-color:var(--input-color) !important}.general-setting-container .input-time-picker-custom .form-control{border:none !important}.general-setting-container .input-time-picker-custom .input-group-prepend{margin-right:2px}.general-setting-container .input-time-picker-custom .input-group-prepend>.input-group-text{border:none !important}", "",{"version":3,"sources":["webpack://./src/settings/general-setting/assets/scss/style.scss"],"names":[],"mappings":"AAEE,qDACE,MAAA,CACA,qDAAA,CACA,2BAAA,CACA,2BAAA,CACA,8BAAA,CAEA,sBAAA,CACA,UAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,8CAAA,CACA,mEACE,sBAAA,CAGF,0EACE,gBAAA,CAEA,4FACE,sBAAA","sourcesContent":[".general-setting-container {\n  //    You scss here\n  .input-time-picker-custom {\n    flex: 1;\n    border: 1px solid var(--input-border-color) !important;\n    border-right: 0px !important;\n    border-top-right-radius: 0px;\n    border-bottom-right-radius: 0px;\n\n    height: 40px !important;\n    width: 100%;\n    position: relative;\n    display: flex;\n    align-items: center;\n    background-color: var(--input-color) !important;\n    .form-control {\n      border: none !important;\n    }\n\n    .input-group-prepend {\n      margin-right: 2px;\n\n      > .input-group-text {\n        border: none !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"general-setting-container": "general-setting-container",
	"input-time-picker-custom": "input-time-picker-custom",
	"form-control": "form-control",
	"input-group-prepend": "input-group-prepend",
	"input-group-text": "input-group-text"
};
export default ___CSS_LOADER_EXPORT___;
