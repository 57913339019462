import { atom } from 'recoil';

export const settingODCWriteOptionAtom = atom({
  key: 'SettingOnlineDigitalContentWriteOptionAtom',
  default: {
    writeType: '',
    writeMenu: 'list',
    isOpenWrite: false,
  },
});
