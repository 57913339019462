import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { ChevronsRight, ChevronsLeft } from 'react-feather';
//import { useTranslation } from "react-i18next";
import SurveyTitleView from '@settings/online-digital-content/survey/components/survey-title-view';
import QuestionView from '@settings/online-digital-content/survey/components/question-view';
import QuestionMediaView from '@settings/online-digital-content/survey/components/question-media-view';
import {
  Q_MULTI_CHOICES,
  Q_CHECKBOXES,
  Q_DROPDOWN,
  Q_SHORT_ANSWER,
  Q_PARAGRAPH,
  Q_FILE_UPLOAD,
  Q_DATE,
  Q_TIME,
  Q_MULTI_CHOICES_GRID,
  Q_TICK_BOX_GRID,
  Q_TITLE,
  Q_IMAGE,
  Q_VIDEO,
} from '@settings/online-digital-content/survey/config/constants';
import { NoData, SpanLang } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import usePublicMutationPost from '@base/hooks/usePublicMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { SURVEY_RESPONSE_CREATE } from '@settings/online-digital-content/survey/services/graphql';
import { Button } from '@base/components/form';
import { TRACKING_SURVEY_ANSWER_CREATE } from '@site-page/services/graphql';

const DEFAULT_BG_COLOR = '#e4f3e5';

interface SurveyViewProps {
  className?: string;
  id: string;
  name: string;
  previewData: any;
  isPublic?: boolean;
  token?: string;
}

const SurveyView = (props: SurveyViewProps) => {
  const {
    className = '',
    id = '', //survey id
    name = '', // survey name
    previewData = null,
    isPublic = false,
    token = '',
  } = props;
  //const { t } = useTranslation();
  //state
  const [surveyS, setSurveyS] = useState<any>([]);
  const [curSection, setCurSection] = useState<number>(0); //init current survey is 0
  const [surveyHeaderImg, setSurveyHeaderImg] = useState<any>(null); //image on survey header
  const [surveyBgColor, setSurveyBgColor] = useState<string>(DEFAULT_BG_COLOR); //light-gray
  const [requiredInValidKey, setRequiredInValidKey] = useState(-1); // default validate TRUE

  //private create response
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    SURVEY_RESPONSE_CREATE,
    'setting_createAnswer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Sent survey response successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Sent survey response failed: ' + JSON.parse(error).message);
      },
    },
  );

  //for client
  const mPublicAdd: any = usePublicMutationPost<BaseMutationResponse>(
    TRACKING_SURVEY_ANSWER_CREATE,
    'tracking_createAnswer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Sent survey response successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Sent survey response failed: ' + JSON.parse(error).message);
      },
    },
  );

  //track to get survey info
  useEffect(() => {
    if (previewData) {
      if (previewData?.sections) {
        setSurveyS(_.cloneDeep(previewData.sections));
      }
      if (previewData?.headerImg) {
        setSurveyHeaderImg(previewData.headerImg);
      }
      if (previewData?.bgColor) {
        setSurveyBgColor(previewData.bgColor);
      }
    }
  }, [previewData]);

  //submit success
  useEffect(() => {
    if (mutationAdd.isSuccess) {
      //reset survey
      setSurveyS(_.cloneDeep(previewData.sections)); //orginal data
    }
  }, [mutationAdd.isSuccess]);

  //submit success
  useEffect(() => {
    if (mPublicAdd.isSuccess) {
      //reset survey
      setSurveyS(_.cloneDeep(previewData.sections)); //orginal data
    }
  }, [mPublicAdd.isSuccess]);

  //validate required for question before go next or submit
  function validateRequired() {
    const curSurveyS = surveyS[curSection];
    for (let i = 0; i < curSurveyS.questions.length; i++) {
      //only validate required questions
      const item = curSurveyS.questions[i];
      if (item.required) {
        switch (item.type) {
          case Q_MULTI_CHOICES:
          case Q_CHECKBOXES:
          case Q_DROPDOWN:
            if (item.options.filter((ele: any) => ele.checked).length === 0) {
              setRequiredInValidKey(i);
              return false;
            }
            break;
          case Q_SHORT_ANSWER:
          case Q_PARAGRAPH:
          case Q_DATE:
          case Q_TIME:
          case Q_FILE_UPLOAD:
            if (!item.options[0].answer) {
              setRequiredInValidKey(i);
              return false;
            }
            break;
          case Q_MULTI_CHOICES_GRID:
          case Q_TICK_BOX_GRID:
            let hasGridAnswer: any = {};
            //have answers, but check enough or not
            if (item.options.answer) {
              Object.values(item.options.answer).map((ansCols: any, rIdx: number) => {
                Object.values(ansCols).map((value: any) => {
                  if (value) {
                    hasGridAnswer[rIdx] = true;
                  }
                });
              });
              //check if number of answer is not enough (must select answers for all rows)
              if (Object.keys(hasGridAnswer).length < item.options.rows.length) {
                setRequiredInValidKey(i);
                return false;
              }
            } else {
              //no any answers yet
              setRequiredInValidKey(i);
              return false;
            }

            break;
        }
      }
    }

    return true;
  }

  //go next section
  function onNext() {
    const isValid = validateRequired();
    if (isValid) {
      setCurSection((cur) => cur + 1);
    }
  }

  //go prev section
  function onPrevious() {
    setCurSection((cur) => cur - 1);
  }

  //submit response
  function onSubmit() {
    //validate required questions before submit
    const isValid = validateRequired();
    if (isValid) {
      if (surveyS && surveyS.length > 0) {
        const params = {
          survey: {
            id,
            name,
          },
          answers: JSON.stringify(surveyS),
        };
        if (isPublic) {
          mPublicAdd.mutate({ answer: params, token });
        } else {
          mutationAdd.mutate({ answer: params });
        }
      }
    }
  }

  //set option response
  function handleSetOptionsQ(keyQ: number, newOptions: any) {
    let newSurveyS = [...surveyS];
    //set new
    newSurveyS[curSection].questions[keyQ].options = newOptions;
    //reset required invalid key
    if (keyQ === requiredInValidKey) {
      setRequiredInValidKey(-1);
    }
    //set state
    setSurveyS(newSurveyS);
  }

  //render questions
  const SurveyQuestions = (props: any) => {
    const { surveyQ } = props;
    //// console.log('surveyQ', surveyQ);
    return (
      <div className="survey-write-tab-content">
        {/* header image */}
        {surveyHeaderImg && (
          <div className="survey-write-header-card">
            <img src={surveyHeaderImg} className="survey-write-header-image" />
          </div>
        )}
        {/* first section: title+desc */}
        <SurveyTitleView title={surveyQ.title} description={surveyQ.description} />
        {/* render questions */}
        {surveyQ.questions.map((item: any, index: number) => {
          //check answer yet
          let hasAnswer = false;
          if (
            item.type === Q_MULTI_CHOICES ||
            item.type === Q_CHECKBOXES ||
            item.type === Q_DROPDOWN
          ) {
            if (item.options.filter((ele: any) => ele.checked).length > 0) {
              hasAnswer = true;
            }
          }
          //for grid
          let hasGridAnswer: any = {};
          if (item.type === Q_MULTI_CHOICES_GRID || item.type === Q_TICK_BOX_GRID) {
            if (item.options.answer) {
              //// console.log('item.options.answer', Object.values(item.options.answer));
              Object.values(item.options.answer).map((ansCols: any, rIdx: number) => {
                Object.values(ansCols).map((value: any) => {
                  if (value) {
                    hasGridAnswer[rIdx] = true;
                  }
                });
              });
            }
          }
          return item.type === Q_TITLE || item.type === Q_IMAGE || item.type === Q_VIDEO ? (
            <QuestionMediaView
              key={index}
              keyQ={index}
              title={item.title}
              image={item.image}
              selectedQType={item.type}
              description={item.description}
              video={item.video}
            />
          ) : (
            <QuestionView
              key={index}
              keyQ={index}
              title={item.title}
              image={item.image}
              required={item.required}
              requiredInValid={requiredInValidKey === index} //valid for required is FALSE
              onSetRequiredInValidKey={setRequiredInValidKey}
              selectedQType={item.type}
              options={item.options}
              hasAnswer={hasAnswer}
              hasGridAnswer={hasGridAnswer}
              onSetOptionsQ={handleSetOptionsQ}
            />
          );
        })}
        {/* submit button */}
        <div className="pd-t-10">
          {curSection < surveyS.length - 1 && (
            <button type="button" className="btn btn-primary mg-r-10" onClick={onNext}>
              <SpanLang keyLang="Next" /> <ChevronsRight />
            </button>
          )}
          {curSection > 0 && (
            <button type="button" className="btn btn-primary mg-r-10" onClick={onPrevious}>
              <ChevronsLeft /> <SpanLang keyLang="Previous" />
            </button>
          )}
          {curSection === surveyS.length - 1 && id != '' && (
            <Button
              color="primary"
              onClick={onSubmit}
              loading={mutationAdd.isLoading || mPublicAdd.isLoading}
              disabled={mutationAdd.isLoading || mPublicAdd.isLoading}
            >
              <SpanLang keyLang="Submit" />
            </Button>
          )}
        </div>
      </div>
    );
  };

  //// console.log('surveyS', surveyS);
  //main render
  return (
    <div className={classNames('pos-relative z-index-10', className)}>
      <div className="pos-relative" style={{ backgroundColor: surveyBgColor }}>
        <div className="survey-write-content-container pd-t-10">
          {/* {isLoading && <Loading />} */}
          {surveyS.length > 0 && <SurveyQuestions surveyQ={surveyS[curSection]} />}
          {surveyS.length === 0 && <NoData />}
        </div>
      </div>
    </div>
  );
};

export default SurveyView;
