import { CriteriaOperator, StatusFormProperty } from '@settings/process/types/diagram';
import { StatusForm, StepType } from '@settings/process/types/process';

export const PROCESS_TYPE: { [index: string]: string } = {
  TYPE_BUSINESS: 'Business',
  TYPE_CAMPAIN: 'Campaign',
};

export const PROCESS_TYPE_OPTIONS = [
  {
    keyName: 'TYPE_BUSINESS',
    languageKey: PROCESS_TYPE['TYPE_BUSINESS'],
  },
  {
    keyName: 'TYPE_CAMPAIN',
    languageKey: PROCESS_TYPE['TYPE_CAMPAIN'],
  },
];

export const PROCESS_TRIGGER_OPTIONS = [
  {
    keyName: 'TRIGGER_RECORD_CREATED',
    languageKey: 'TRIGGER_RECORD_CREATED',
  },
  {
    keyName: 'TRIGGER_INVOKE_PROCESS',
    languageKey: 'TRIGGER_INVOKE_PROCESS',
  },
];

export const TRIGGER_TYPE: { [index: string]: string } = {
  TRIGGER_TYPE_NONE: 'None',
  TRIGGER_TYPE_RECORD_CREATED: 'Record Created',
  TRIGGER_TYPE_RECORD_UPDATED: 'Record Updated',
  TRIGGER_TYPE_RECORD_CREATED_UPDATED: 'Record Created or Updated',
  TRIGGER_TYPE_RECORD_DELETED: 'Record Deleted',
  TRIGGER_TYPE_FIELD_UPDATED: 'Field Updated',
  TRIGGER_TYPE_PROCESS_PROPERTY_UPDATED: 'Process Property Updated',
  TRIGGER_TYPE_SCHEDULED_DATE_TIME: 'Schedule Date time',
};

export const TRIGGER_TYPE_OPTIONS = [
  {
    keyName: 'TRIGGER_TYPE_NONE',
    languageKey: TRIGGER_TYPE['TRIGGER_TYPE_NONE'],
  },
  {
    keyName: 'TRIGGER_TYPE_RECORD_CREATED',
    languageKey: TRIGGER_TYPE['TRIGGER_TYPE_RECORD_CREATED'],
  },
  {
    keyName: 'TRIGGER_TYPE_RECORD_UPDATED',
    languageKey: TRIGGER_TYPE['TRIGGER_TYPE_RECORD_UPDATED'],
  },
  {
    keyName: 'TRIGGER_TYPE_RECORD_CREATED_UPDATED',
    languageKey: TRIGGER_TYPE['TRIGGER_TYPE_RECORD_CREATED_UPDATED'],
  },
  {
    keyName: 'TRIGGER_TYPE_RECORD_DELETED',
    languageKey: TRIGGER_TYPE['TRIGGER_TYPE_RECORD_DELETED'],
  },
  {
    keyName: 'TRIGGER_TYPE_FIELD_UPDATED',
    languageKey: TRIGGER_TYPE['TRIGGER_TYPE_FIELD_UPDATED'],
  },
  {
    keyName: 'TRIGGER_TYPE_PROCESS_PROPERTY_UPDATED',
    languageKey: TRIGGER_TYPE['TRIGGER_TYPE_PROCESS_PROPERTY_UPDATED'],
  },
  {
    keyName: 'TRIGGER_TYPE_SCHEDULED_DATE_TIME',
    languageKey: TRIGGER_TYPE['TRIGGER_TYPE_SCHEDULED_DATE_TIME'],
  },
];

export const PROCESS_STATUS_DIRECTIONS_VIEW: { [index: string]: string } = {
  DIRECTION_DISABLE: '-',
  DIRECTION_NONE: '',
  DIRECTION_FORWARD_INCOMING_MIDDLE: 'Incoming',
  DIRECTION_STAYING: 'Staying',
  DIRECTION_FORWARD_OUTGOING_FORWARD: 'Forward',
  DIRECTION_FORWARD_OUTGOING_BACKWARD: 'Backward',
  DIRECTION_FORWARD_OUTGOING_MIDDLE: 'Middle',
  DIRECTION_FORWARD_OUTGOING_JUMP: 'Jump',
  DIRECTION_FORWARD_OUTGOING_BOTTOM: 'forward Bottom', // for simple action
  DIRECTION_BACKWARD_OUTGOING_BOTTOM: 'Backward Bottom',
  DIRECTION_BACKWARD_OUTGOING_MIDDLE: 'Backward Middle',
  DIRECTION_BACKWARD_OUTGOING_FORWARD: 'Backward Forward',
  DIRECTION_FORWARD_OUTGOING_PROCESS: 'Other Process',
};

export const PROCESS_STATUS_DIRECTIONS = [
  { keyName: 'DIRECTION_NONE', languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_NONE'] },
  // { keyName: 'DIRECTION_FORWARD_INCOMING_MIDDLE', languageKey: 'Incoming' },
  {
    keyName: 'DIRECTION_STAYING',
    languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_STAYING'],
  },
  {
    keyName: 'DIRECTION_FORWARD_OUTGOING_FORWARD',
    languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_FORWARD_OUTGOING_FORWARD'],
  },
  {
    keyName: 'DIRECTION_FORWARD_OUTGOING_BACKWARD',
    languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_FORWARD_OUTGOING_BACKWARD'],
  },
  {
    keyName: 'DIRECTION_FORWARD_OUTGOING_MIDDLE',
    languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_FORWARD_OUTGOING_MIDDLE'],
  },
  {
    keyName: 'DIRECTION_FORWARD_OUTGOING_JUMP',
    languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_FORWARD_OUTGOING_JUMP'],
  },
  {
    keyName: 'DIRECTION_FORWARD_OUTGOING_BOTTOM',
    languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_FORWARD_OUTGOING_BOTTOM'],
  },
  {
    keyName: 'DIRECTION_BACKWARD_OUTGOING_BOTTOM',
    languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_BACKWARD_OUTGOING_BOTTOM'],
  },
  {
    keyName: 'DIRECTION_BACKWARD_OUTGOING_MIDDLE',
    languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_BACKWARD_OUTGOING_MIDDLE'],
  },
  {
    keyName: 'DIRECTION_BACKWARD_OUTGOING_FORWARD',
    languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_BACKWARD_OUTGOING_FORWARD'],
  },
  {
    keyName: 'DIRECTION_FORWARD_OUTGOING_PROCESS',
    languageKey: PROCESS_STATUS_DIRECTIONS_VIEW['DIRECTION_FORWARD_OUTGOING_PROCESS'],
  },
];

export const PROCESS_STATUS_DIRECTIONS_SORT: { [index: string]: number } = {
  DIRECTION_DISABLE: -1,
  DIRECTION_NONE: 7,
  DIRECTION_FORWARD_INCOMING_MIDDLE: 1,
  DIRECTION_BACKWARD_INCOMING_TOP: 2,
  DIRECTION_STAYING: 3,
  DIRECTION_FORWARD_OUTGOING_FORWARD: 4,
  DIRECTION_FORWARD_OUTGOING_BACKWARD: 5,
  DIRECTION_FORWARD_OUTGOING_MIDDLE: 6,
  DIRECTION_FORWARD_OUTGOING_PROCESS: 7,
  DIRECTION_FORWARD_OUTGOING_JUMP: 8,
  DIRECTION_FORWARD_OUTGOING_BOTTOM: 9,
  DIRECTION_BACKWARD_OUTGOING_BOTTOM: 10,
  DIRECTION_BACKWARD_OUTGOING_MIDDLE: 11,
  DIRECTION_BACKWARD_OUTGOING_FORWARD: 12,
  DIRECTION_BACKWARD_OUTGOING_PROCESS: 13,
};

export const PROCESS_STATUS_PROPERTIES_VIEW: { [index: string]: string } = {
  PROPERTY_DISABLE: '-',
  PROPERTY_NONE: 'None',
  PROPERTY_NEW: 'New', // fix
  PROPERTY_TODO: 'Todo', // fix
  PROPERTY_TODO_DOING: 'Todo Doing', // fix
  PROPERTY_TODO_CLOSE: 'Todo Close', // fix
  PROPERTY_COMPLETED: 'Completed',
  PROPERTY_CANCELED: 'Canceled',
  PROPERTY_LOST: 'Lost',
  PROPERTY_PAID_IN_FULL: 'Paid In Full',
  PROPERTY_PARTIALLY_PAID: 'Partially Paid',
  PROPERTY_REJECTED: 'Rejected',
  PROPERTY_REUSED: 'Reused',
  PROPERTY_APPROVED: 'Approved',
};

export const PROCESS_STATUS_PROPERTIES = [
  { keyName: 'PROPERTY_NONE', languageKey: PROCESS_STATUS_PROPERTIES_VIEW['PROPERTY_NONE'] },
  // { keyName: 'PROPERTY_TODO', languageKey: 'Todo' },
  // { keyName: 'PROPERTY_TODO_DOING', languageKey: 'Todo Doing' },
  {
    keyName: 'PROPERTY_TODO_CLOSE',
    languageKey: PROCESS_STATUS_PROPERTIES_VIEW['PROPERTY_TODO_CLOSE'],
  },
  {
    keyName: 'PROPERTY_COMPLETED',
    languageKey: PROCESS_STATUS_PROPERTIES_VIEW['PROPERTY_COMPLETED'],
  },
  {
    keyName: 'PROPERTY_CANCELED',
    languageKey: PROCESS_STATUS_PROPERTIES_VIEW['PROPERTY_CANCELED'],
  },
  { keyName: 'PROPERTY_LOST', languageKey: PROCESS_STATUS_PROPERTIES_VIEW['PROPERTY_LOST'] },
  {
    keyName: 'PROPERTY_PAID_IN_FULL',
    languageKey: PROCESS_STATUS_PROPERTIES_VIEW['PROPERTY_PAID_IN_FULL'],
  },
  {
    keyName: 'PROPERTY_PARTIALLY_PAID',
    languageKey: PROCESS_STATUS_PROPERTIES_VIEW['PROPERTY_PARTIALLY_PAID'],
  },
  {
    keyName: 'PROPERTY_REJECTED',
    languageKey: PROCESS_STATUS_PROPERTIES_VIEW['PROPERTY_REJECTED'],
  },
  { keyName: 'PROPERTY_REUSED', languageKey: PROCESS_STATUS_PROPERTIES_VIEW['PROPERTY_REUSED'] },
  {
    keyName: 'PROPERTY_APPROVED',
    languageKey: PROCESS_STATUS_PROPERTIES_VIEW['PROPERTY_APPROVED'],
  },
];

export const PROCESS_STATUS_EVENTS_VIEW: { [index: string]: string } = {
  EVENT_DISABLE: '-',
  EVENT_NONE: '',
  EVENT_CLICK: 'Click',
  EVENT_TRIGGER: 'Trigger',
  // EVENT_API: 'API',
  // EVENT_WEB_HOOK: 'Web Hook',
  // EVENT_CRITERIA_RESULT: 'Criteria Result',
  EVENT_DOWNLOAD: 'Download',
  EVENT_SUBMIT: 'Submit',
};

export const PROCESS_STATUS_EVENTS = [
  { keyName: 'EVENT_NONE', languageKey: PROCESS_STATUS_EVENTS_VIEW['EVENT_NONE'] },
  { keyName: 'EVENT_CLICK', languageKey: PROCESS_STATUS_EVENTS_VIEW['EVENT_CLICK'] },
  { keyName: 'EVENT_TRIGGER', languageKey: PROCESS_STATUS_EVENTS_VIEW['EVENT_TRIGGER'] },
  // { keyName: 'EVENT_API', languageKey: 'API' },
  // { keyName: 'EVENT_WEB_HOOK', languageKey: 'Web Hook' },
  // { keyName: 'EVENT_CRITERIA_RESULT', languageKey: 'Criteria Result' },
  { keyName: 'EVENT_DOWNLOAD', languageKey: PROCESS_STATUS_EVENTS_VIEW['EVENT_DOWNLOAD'] },
  { keyName: 'EVENT_SUBMIT', languageKey: PROCESS_STATUS_EVENTS_VIEW['EVENT_SUBMIT'] },
];

export const PROCESS_STATUS_VIEWS_VIEW: { [index: string]: string } = {
  VIEW_DISABLE: '-',
  VIEW_NONE: '',
  VIEW_SINGLE: 'Single',
  // VIEW_HIDE: 'Hide',
  VIEW_MORE_BOX: 'More Box',
  // VIEW_DIMNED: 'Dimned',
  // VIEW_SELECTED: 'Selected',
  // VIEW_CTA: 'CTA',
};

export const PROCESS_STATUS_VIEWS = [
  { keyName: 'VIEW_NONE', languageKey: PROCESS_STATUS_VIEWS_VIEW['VIEW_NONE'] },
  { keyName: 'VIEW_SINGLE', languageKey: PROCESS_STATUS_VIEWS_VIEW['VIEW_SINGLE'] },
  // { keyName: 'VIEW_HIDE', languageKey: 'Hide' },
  { keyName: 'VIEW_MORE_BOX', languageKey: PROCESS_STATUS_VIEWS_VIEW['VIEW_MORE_BOX'] },
  // { keyName: 'VIEW_DIMNED', languageKey: 'Dimned' },
  // { keyName: 'VIEW_SELECTED', languageKey: 'Selected' },
  // { keyName: 'VIEW_CTA', languageKey: 'CTA' },
];

export const STEP_TYPES: StepType[] = [
  {
    key: 'action',
    value: 'TYPE_ACTION',
    label: 'Action',
  },
  {
    key: 'wait',
    value: 'TYPE_WAIT',
    label: 'Wait',
  },
  {
    key: 'criteria',
    value: 'TYPE_CRITERIA',
    label: 'Rule Criteria',
  },
  {
    key: 'site',
    value: 'TYPE_SITE',
    label: 'Site',
  },
  {
    key: 'simple',
    value: 'TYPE_SIMPLE_ACTION',
    label: 'Simple Action',
  },
  {
    key: 'checklist',
    value: 'TYPE_CHECKLIST',
    label: 'Checklist',
  },
];

export const PROCESS_CLOSED_PROPERTY_OPTIONS = [
  {
    keyName: 'PROPERTY_COMPLETED',
    languageKey: 'PROPERTY_COMPLETED',
  },
  {
    keyName: 'PROPERTY_CANCELED',
    languageKey: 'PROPERTY_CANCELED',
  },
  {
    keyName: 'PROPERTY_LOST',
    languageKey: 'PROPERTY_LOST',
  },
  {
    keyName: 'PROPERTY_WON',
    languageKey: 'PROPERTY_WON',
  },
  {
    keyName: 'PROPERTY_REJECTED',
    languageKey: 'PROPERTY_REJECTED',
  },
];

export const PROCESS_CLOSED_VIEW_OPTIONS = [
  {
    keyName: 'VIEW_SINGLE',
    languageKey: 'VIEW_SINGLE',
  },
  {
    keyName: 'VIEW_MORE_BOX',
    languageKey: 'VIEW_MORE_BOX',
  },
];

export const AUTOMATION_TYPE: { [index: string]: string } = {
  RULE_TYPE_BOOLEAN: 'Boolean',
  RULE_TYPE_SIMPLE: 'Simple',
};

export const AUTOMATION_TYPE_OPTIONS = [
  {
    value: 'RULE_TYPE_BOOLEAN',
    label: AUTOMATION_TYPE['RULE_TYPE_BOOLEAN'],
  },
  {
    value: 'RULE_TYPE_SIMPLE',
    label: AUTOMATION_TYPE['RULE_TYPE_SIMPLE'],
  },
];

export const STATUS_BASIC_DATA = (props: StatusFormProperty): StatusForm => {
  const view = props.view ?? 'VIEW_NONE';
  const event = props.event ?? 'EVENT_NONE';
  const property = props.property ?? 'PROPERTY_NONE';
  const direction = props.direction ?? 'DIRECTION_NONE';
  const sequence = props.sequence ?? ['2'];
  const definedId = props.definedId ?? '';
  const newFlag = props.newFlag ?? false;
  const resetFlag = props.resetFlag ?? false;
  const ctaId = props.ctaId ?? '';

  return {
    id: props.id ?? '',
    button: props.button ?? '',
    name: props.name ?? '',
    view: { keyName: view, languageKey: PROCESS_STATUS_VIEWS_VIEW[view] },
    event: { keyName: event, languageKey: PROCESS_STATUS_EVENTS_VIEW[event] },
    property: {
      keyName: property,
      languageKey: PROCESS_STATUS_PROPERTIES_VIEW[property],
    },
    direction: {
      keyName: direction,
      languageKey: PROCESS_STATUS_DIRECTIONS_VIEW[direction],
    },
    nextStep: { keyName: '', languageKey: '' },
    sequence: sequence,
    new: newFlag,
    reset: resetFlag,
    definedId: definedId,
    order: PROCESS_STATUS_DIRECTIONS_SORT[direction],
    multiple: props.multiple ?? 'MULTIPLE_NONE',
    primary: false,
    ctaId: ctaId,
  };
};

export const CRITERIA_OPERATOR: { [index: string]: string } = {
  CRITERIA_OPERATOR_NONE: 'Operator',
  CRITERIA_OPERATOR_EQUAL: '=',
  CRITERIA_OPERATOR_NOT_EQUAL: '!=',
  CRITERIA_OPERATOR_LESS_THAN: '<',
  CRITERIA_OPERATOR_LESS_THAN_EQUAL: '<=',
  CRITERIA_OPERATOR_GREATER_THAN: '>',
  CRITERIA_OPERATOR_GREATER_THAN_EQUAL: '>=',
  CRITERIA_OPERATOR_IS_EMPTY: '""',
  CRITERIA_OPERATOR_IS_NOT_EMPTY: '!=""',
  CRITERIA_OPERATOR_CONTAIN: 'in',
  CRITERIA_OPERATOR_NOT_CONTAIN: '!in',
  CRITERIA_OPERATOR_ON: 'on',
  CRITERIA_OPERATOR_NOT_ON: '!on',
  CRITERIA_OPERATOR_AFTER: 'after',
  CRITERIA_OPERATOR_BEFORE: 'before',
  CRITERIA_OPERATOR_BETWEEN: 'between',
  CRITERIA_OPERATOR_THIS_ID: 'this id',
  CRITERIA_OPERATOR_RELATED_ID: 'releated id',
  CRITERIA_OPERATOR_PROPERTY: 'property',
};

export const CRITERIA_FIELD_TYPE: { [index: string]: string } = {
  FIELD_TYPE_NONE: 'Field Type',
  FIELD_TYPE_NUMBER: 'Number',
  FIELD_TYPE_TEXT: 'Text',
  FIELD_TYPE_DATE: 'Date',
  FIELD_TYPE_PROCESS: 'Process',
};

export const CRITERIA_OPERATOR_TYPE: {
  [index: string]: { keyName: CriteriaOperator; operator: string }[];
} = {
  FIELD_TYPE_NUMBER: [
    {
      keyName: 'CRITERIA_OPERATOR_EQUAL',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_EQUAL'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_NOT_EQUAL',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_NOT_EQUAL'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_LESS_THAN',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_LESS_THAN'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_LESS_THAN_EQUAL',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_LESS_THAN_EQUAL'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_GREATER_THAN',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_GREATER_THAN'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_GREATER_THAN_EQUAL',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_GREATER_THAN_EQUAL'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_IS_EMPTY',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_IS_EMPTY'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_IS_NOT_EMPTY',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_IS_NOT_EMPTY'],
    },
  ],
  FIELD_TYPE_TEXT: [
    {
      keyName: 'CRITERIA_OPERATOR_EQUAL',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_EQUAL'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_NOT_EQUAL',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_NOT_EQUAL'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_CONTAIN',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_CONTAIN'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_NOT_CONTAIN',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_NOT_CONTAIN'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_IS_EMPTY',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_IS_EMPTY'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_IS_NOT_EMPTY',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_IS_NOT_EMPTY'],
    },
  ],
  FIELD_TYPE_DATE: [
    {
      keyName: 'CRITERIA_OPERATOR_ON',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_ON'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_NOT_ON',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_NOT_ON'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_AFTER',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_AFTER'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_BEFORE',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_BEFORE'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_BETWEEN',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_BETWEEN'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_IS_EMPTY',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_IS_EMPTY'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_IS_NOT_EMPTY',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_IS_NOT_EMPTY'],
    },
  ],
  FIELD_TYPE_PROCESS: [
    {
      keyName: 'CRITERIA_OPERATOR_THIS_ID',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_THIS_ID'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_RELATED_ID',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_RELATED_ID'],
    },
    {
      keyName: 'CRITERIA_OPERATOR_PROPERTY',
      operator: CRITERIA_OPERATOR['CRITERIA_OPERATOR_PROPERTY'],
    },
  ],
};

export const INSTANT_ACTION_TYPE: { [index: string]: string } = {
  ACTION_TYPE_TASK: 'Assign a Task',
  ACTION_TYPE_EMAIL: 'Sending Email',
  ACTION_TYPE_FIELD_UPDATE: 'Field Update',
  ACTION_TYPE_OUTBOUND_MESSAGE: 'Outbound Message',
};

export const INSTANT_ACTION_TEMPLATE: { [index: string]: string } = {
  ACTION_TYPE_TASK: 'task',
  ACTION_TYPE_EMAIL: 'email',
};

export const INSTANT_ACTION_TYPE_OPTIONS = [
  {
    keyName: 'ACTION_TYPE_TASK',
    languageKey: INSTANT_ACTION_TYPE['ACTION_TYPE_TASK'],
  },
  {
    keyName: 'ACTION_TYPE_EMAIL',
    languageKey: INSTANT_ACTION_TYPE['ACTION_TYPE_EMAIL'],
  },
  {
    keyName: 'ACTION_TYPE_FIELD_UPDATE',
    languageKey: INSTANT_ACTION_TYPE['ACTION_TYPE_FIELD_UPDATE'],
  },
  {
    keyName: 'ACTION_TYPE_OUTBOUND_MESSAGE',
    languageKey: INSTANT_ACTION_TYPE['ACTION_TYPE_OUTBOUND_MESSAGE'],
  },
];
