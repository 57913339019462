import React, { useState } from 'react';
import TextEditable from '@base/components/form/text-editable';
import useMutationPost from '@base/hooks/useMutationPost';
import { UPDATE_MENU_TEMPLATE } from '@settings/template/services/graphql';
import { IMenuTemplate } from '@settings/template/types/interfaces';
import { toast } from 'react-toastify';
import { HoverEditContainer, ViewContainer } from '@base/containers/view-field/common/style';
import { Link } from 'react-router-dom';
import { Edit2 } from 'react-feather';
import classNames from 'classnames';
import ClickOutside from '@base/components/han-click-outside';

interface IListGridItemHeaderProps {
  title: string;
  titleLink: string;
  id: string;
  onAfterSaved: (nTitle: string) => void;
}
const ListGridItemHeader: React.FC<IListGridItemHeaderProps> = (
  props: IListGridItemHeaderProps,
) => {
  const { id, title, onAfterSaved, titleLink } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // update menuTemplate
  const mUpdateTemplate: any = useMutationPost<IMenuTemplate>(
    UPDATE_MENU_TEMPLATE,
    'setting_updateMenuTemplate',
  );
  const onSave = (nVal: string) => {
    if (nVal != '') {
      setIsSaving(true);
      mUpdateTemplate.mutate(
        { menuTemplate: { id: id, name: nVal } },
        {
          onSuccess: (res: any) => {
            setIsSaving(false);
            setIsEdit(false);
            toast.success('Template has updated!');
            onAfterSaved && onAfterSaved(nVal);
          },
          onError: (res: any) => {
            setIsSaving(false);
          },
        },
      );
    }
  };
  const onClose = () => {
    setIsEdit(false);
  };
  const handleOnEdit = () => {
    setIsEdit(true);
  };
  return (
    <>
      <div
        className="d-flex card-grid-header align-items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="grid-list-item-detail-header wd-100p">
          <Link to={titleLink} className="grid-list-item-title link-02">
            {title}
          </Link>
        </div>
        {/* <HoverEditContainer>
          {isEdit ? (
            <ClickOutside
              onClickOutsite={() => onClose()}
              onClickInsite={() => {
                // canEdit() && setIsEdit(true);
              }}
            >
              <TextEditable
                value={title}
                onClose={onClose}
                onSave={onSave}
                isSaving={isSaving}
                setIsSaving={setIsSaving}
              />
            </ClickOutside>
          ) : (
            <div className="grid-list-item-detail-header wd-100p">
              <ViewContainer className={classNames('wd-100p')} onClick={handleOnEdit}>
                <Link to={titleLink} className="grid-list-item-title link-02">
                  {title}
                </Link>
                <Edit2 className="mg-l-5 icon-edit" size={16} color="#8392a5" />
              </ViewContainer>
            </div>
          )}
        </HoverEditContainer> */}
      </div>
    </>
  );
};

export default ListGridItemHeader;
