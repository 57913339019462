import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { MENU_SETTING_ASSIGNMENT_RULE } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { ViewFields } from '@base/components/hook-form/view';
import { Button } from '@base/components/form';
import * as keyNames from '@settings/assignment-rule/config/key-names';

interface IViewLeftProps {
  id: string;
}

const IGNORE_KEYS: string[] = [
  keyNames.KEY_NAME_ASSIGNMENT_RULE_NAME,
  keyNames.KEY_NAME_ASSIGNMENT_RULE_ENTRIES,
];

const ViewLeft: React.FC<IViewLeftProps> = (props) => {
  const { id } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_SETTING_ASSIGNMENT_RULE));
  const [ignoreFields, setIgnoreFields] = useState<string[]>([]);

  useEffect(() => {
    setIgnoreFields(IGNORE_KEYS);
  }, [viewData]);

  // get fields by group
  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
  // // console.log('basicFields', basicFields);

  // render sections
  const SummarySection = useMemo(() => {
    return (
      <div className="view-aside-section">
        <div className="view-aside-section-header">
          <Button icon="move" iconType="custom" className="view-aside-section-move mg-r-5" />
          <span className="view-aside-section-header-title">Summary</span>
          <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#abmSummary"
          />
        </div>
        <div id="abmSummary" className="view-aside-section-body collapse fade show">
          <div className="form-row mg-x-0 mg-t-10">
            <ViewFields
              fields={basicFields}
              ignoreFields={ignoreFields}
              menu={viewData?.menu ?? ''}
              menuSource={viewData?.menuSource ?? ''}
              menuSourceId={viewData?.menuSourceId ?? ''}
            />
          </div>
        </div>
      </div>
    );
  }, [id, basicFields, ignoreFields, viewData]);

  return (
    <div className="scroll-box" style={{ height: 'calc(100vh - 100px)' }}>
      {SummarySection}
    </div>
  );
};

export default ViewLeft;
