import { ISideMenuItem } from '@base/types/interfaces/app';
import { lazy } from 'react';

const FormatSettingPage = lazy(() => import('@settings/general-setting/pages/format-setting'));
const SelectionFieldsPage = lazy(() => import('@settings/general-setting/pages/selection-fields'));
const ManageFieldsPage = lazy(
  () => import('@settings/general-setting/pages/selection-fields/manage-fields'),
);
const ManageGroupFieldsPage = lazy(
  () => import('@settings/general-setting/pages/selection-fields/manage-group-fields'),
);
const PersonalizePage = lazy(() => import('@settings/general-setting/pages/personalize'));

export const SUB_MENUS: ISideMenuItem[] = [
  {
    id: 'format-setting',
    default: true,
    keyName: 'format-setting',
    languageKey: 'Format Setting', //'crm_new_setting_format_setting',
    icon: {
      icon: 'Settings',
      iconType: 'feather',
    },
    layouts: [],
    component: FormatSettingPage,
  },
  {
    id: 'selection-fields',
    keyName: 'selection-fields',
    languageKey: 'crm_new_common_selection_fields',
    icon: {
      icon: 'CheckSquare',
      iconType: 'feather',
    },
    layouts: [
      {
        id: 'manage-fields',
        keyName: 'item',
        languageKey: 'crm_new_common_manage_fields',
        type: 'layout',
        component: ManageFieldsPage,
      },
      {
        id: 'manage-group-fields',
        keyName: 'group',
        languageKey: 'crm_new_common_manage_group_fields',
        type: 'layout',
        component: ManageGroupFieldsPage,
      },
    ],
    component: SelectionFieldsPage,
  },
  {
    id: 'personalize-setting',
    keyName: 'personalize',
    languageKey: 'Personalize', //'crm_new_setting_format_setting',
    icon: {
      icon: 'Tool',
      iconType: 'feather',
    },
    layouts: [],
    component: PersonalizePage,
  },
];
