import { lazy } from 'react';

export const LanguageSelectView = lazy(() => import('@base/containers/view-field/language-select'));
export const ImageSizeView = lazy(() => import('../../containers/view-field/image-size'));
export const LandingPageView = lazy(
  () =>
    import('@settings/online-digital-content/landing-page/containers/view-field/landing-page-view'),
);
export const LinkUrlView = lazy(() => import('../../containers/view-field/link-url'));
export const InputColorView = lazy(() => import('@base/containers/view-field/input-color'));
export const ResourceView = lazy(
  () => import('@settings/online-digital-content/cta/containers/view-field/resource-view'),
);
