import React, { useState } from 'react';

import BillingInfoContact from '@settings/billing-license/containers/billing-infomation/contact';
import BillingInfoAccount from '@settings/billing-license/containers/billing-infomation/account';
import BillingInfoPayment from '@settings/billing-license/containers/billing-infomation/payment';
import BillingInfoAddress from '@settings/billing-license/containers/billing-infomation/address';
import BillingInfoRegistration from '@settings/billing-license/containers/billing-infomation/registration';
import {
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_CONDITION,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_EVENT,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_LOGO,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_NAME,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_NUMBER,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_PEPRESENRATATIVE,
  KEY_NAME_BILLING_INFOMATION_REGISTRATION_SYNCHRO,
} from '@settings/billing-license/config/key-names';

const BillingInfomation: React.FC = () => {
  const [submitValue, setSubmitValue] = useState({
    account: {
      name: '',
      logo: '',
    },
    contact: [],

    registration: {
      [KEY_NAME_BILLING_INFOMATION_REGISTRATION_NUMBER]: '',
      [KEY_NAME_BILLING_INFOMATION_REGISTRATION_NAME]: '',
      [KEY_NAME_BILLING_INFOMATION_REGISTRATION_PEPRESENRATATIVE]: '',
      [KEY_NAME_BILLING_INFOMATION_REGISTRATION_SYNCHRO]: false,
      [KEY_NAME_BILLING_INFOMATION_REGISTRATION_CONDITION]: '',
      [KEY_NAME_BILLING_INFOMATION_REGISTRATION_EVENT]: '',
      [KEY_NAME_BILLING_INFOMATION_REGISTRATION_LOGO]: '',
    },
    paymentMethod: { value: 'receipt', label: '청구서와 가상계좌를 보내주면 결제합니다.' },
  });
  const onChange = (keyName: string, keyValue: any) => {
    setSubmitValue({ ...submitValue, [keyName]: keyValue });
  };
  // // console.log(submitValue);
  return (
    <div className="scroll-box pd-20">
      <div className="card-columns settings-columns">
        {/* Account Holder Name */}
        <BillingInfoAccount value={submitValue} onChange={onChange} />

        {/* Contact */}
        <BillingInfoContact value={submitValue} onChange={onChange} />

        {/* Address */}
        <BillingInfoAddress value={submitValue} onChange={onChange} />

        {/* 사업자 등록증 */}
        <BillingInfoRegistration value={submitValue} onChange={onChange} />

        {/* Payment Method */}
        <BillingInfoPayment value={submitValue} onChange={onChange} />
      </div>
    </div>
  );
};
export default BillingInfomation;
