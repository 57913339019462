import { lazy } from 'react';

export const MethodView = lazy(() => import('@settings/process/components/method/MethodViewField'));
export const WaitView = lazy(() => import('@settings/process/components/diagram/wait/WaitViewField'));
export const CriteriaView = lazy(() => import('@settings/process/components/diagram/criteria/CriteriaViewField'));
export const AutomationView = lazy(
  () => import('@settings/process/components/diagram/automation/AutomationViewField'),
);
export const ActionView = lazy(
  () => import('@settings/process/components/diagram/action/ActionViewField'),
);
export const SiteView = lazy(() => import('@settings/process/components/diagram/site/SiteView'));
export const ChecklistView = lazy(() => import('@settings/process/components/diagram/checklist/ChecklistViewField'));
