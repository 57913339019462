import { SpanLang } from '@base/components';
import Canvas from '@base/components/canvas';
import { Button, Input } from '@base/components/form';
import Portal from '@base/components/portal';
import UserAutoComplete from '@base/containers/user-auto-complete';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { generateUUID } from '@base/utils/helpers';
import {
  CREATE_ASSIGNMENT_GROUP,
  UPDATE_ASSIGNMENT_GROUP,
} from '@settings/preferences/services/graphql';
import { IAssignmentGroup, IAssignmentRep } from '@settings/preferences/types/interfaces';
import { index } from 'cheerio/lib/api/traversing';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

interface INewGroupProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (group: IAssignmentGroup, mode: string) => void;
  data: IAssignmentGroup | null;
}
const NewGroup: React.FC<INewGroupProps> = (props: INewGroupProps) => {
  const { isOpen, onClose, onSave, data } = props;
  const [name, setName] = useState(data ? data.name : '');
  const [isSaving, setIsSaving] = useState(false);
  const [reps, setReps] = useState<IAssignmentRep[]>([
    {
      id: generateUUID(),
      user: null,
      capacity: 0,
    },
  ]);
  // const [ticketCapacity, setTicketCapacity] = useState<number>(data ? data.ticketCapacity : 0);
  const [desc, setDesc] = useState(data ? data.description : '');
  const mAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_ASSIGNMENT_GROUP,
    'desk_createAssignmentGroup',
  );
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_ASSIGNMENT_GROUP,
    'desk_updateAssignmentGroup',
  );
  const handleSaveChange = () => {
    // console.log('handleSaveChange', name, data);
    if (name == '') {
      return;
    }
    let mode = 'add';
    let nReps = reps.filter((rep) => {
      return rep.user ? true : false;
    });
    nReps = nReps.map((rep) => {
      return {
        ...rep,
        user: {
          id: rep.user ? rep.user.id : '',
          name: rep.user ? rep.user.name : '',
        },
      };
    });
    let group: IAssignmentGroup = {
      id: '',
      name: '',
      reps: nReps,
      active: true,
      description: '',
    };
    setIsSaving(true);
    if (data?.id) {
      mode = 'update';
      let uGroup: IAssignmentGroup = {
        id: data.id,
        name,
        description: desc,
        active: data.active,
      };
      mUpdate.mutate(
        { group: uGroup },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
            setIsSaving(false);
            onSave && onSave(group, mode);
            onClose && onClose();
          },
          onError: () => {
            setIsSaving(false);
          },
        },
      );
      group = {
        ...data,
        name,
        description: desc,
        active: data.active,
      };
    } else {
      group = {
        id: generateUUID(),
        name,
        active: true,
        description: desc,
        reps: nReps,
      };
      mAdd.mutate(
        { group },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
            onSave && onSave(group, mode);
            onClose && onClose();
          },
          onError: () => {
            setIsSaving(false);
          },
        },
      );
    }
  };
  const getTotalCapacity = (): number => {
    let total = 0;
    reps.map((rep) => {
      total += rep.capacity * 1;
    });
    return total;
  };
  const onAddRep = () => {
    const nReps = [
      ...reps,
      {
        id: generateUUID(),
        user: null,
        capacity: 0,
      },
    ];
    setReps(nReps);
  };
  const onDeleteRep = (delIdx: number) => {
    const nReps = reps.filter((item, idx) => {
      return delIdx != idx;
    });
    setReps(nReps);
  };
  const onChangeRep = (rep: IAssignmentRep, uIdx: number) => {
    const nReps = reps.map((item, index) => {
      if (index === uIdx) {
        return rep;
      }
      return item;
    });
    setReps(nReps);
  };
  return (
    <>
      <Portal>
        <Canvas
          isVisible={isOpen}
          onCloseSideBar={onClose}
          onClose={onClose}
          className={'page-sidebar-container-wrap wd-800'}
          customStyles={{ zIndex: '1052' }}
          backdropStyles={{ zIndex: '1051' }}
        >
          <Canvas.Header
            title={
              data?.id ? <SpanLang keyLang="Edit Group" /> : <SpanLang keyLang="Create Group" />
            }
          />
          <Canvas.Body style={{ height: 'calc(100vh - 130px)' }}>
            <div className="form-group">
              <label className="form-item-title">
                Group Name<span className="tx-danger">*</span>
              </label>
              <Input
                type="text"
                value={name}
                onChange={(nVal: string) => {
                  setName(nVal);
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Description</label>
              <Input
                type="text"
                value={desc}
                onChange={(nVal: string) => {
                  setDesc(nVal);
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Users</label>
              <div className="table-responsive bd rounded-top">
                <table className="table table-bordered mg-b-0 bd-0 basic-table">
                  <thead>
                    <tr>
                      <th scope="col">User Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Capacity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reps.map((rep, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <UserAutoComplete
                              showAvatar
                              single
                              onChange={(nUser: any) => {
                                const nRep = {
                                  ...rep,
                                  user: {
                                    id: nUser.id,
                                    name: nUser.name,
                                    phone: nUser.phone ?? '0701111111',
                                    email: nUser.email ?? 'hanbirotest@vora.net',
                                  },
                                };
                                const found = reps.find((rep) => {
                                  return rep.user?.id === nUser.id;
                                });
                                if (found) {
                                  toast.error("User '" + nUser.name + "' has existed!");
                                  return;
                                }
                                onChangeRep(nRep, index);
                              }}
                            />
                          </td>
                          <td>{rep.user?.email}</td>
                          <td>{rep.user?.phone}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Input
                                type="number"
                                rightIcon="%"
                                className="wd-120"
                                value={rep.capacity}
                                onChange={(nVal: number) => {
                                  const nRep = {
                                    ...rep,
                                    capacity: nVal * 1,
                                  };
                                  onChangeRep(nRep, index);
                                }}
                              />
                              <Button
                                color=""
                                icon="X"
                                iconClass="tx-danger"
                                className="btn-icon btn-delete-row han-tooltip--left mg-l-auto pd-y-0"
                                data-han-tooltip="삭제"
                                onClick={() => {
                                  onDeleteRep(index);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center justify-content-between pd-10 bd bd-t-0 rounded-bottom">
                <span>Total {reps.length}</span>
                <span>{getTotalCapacity()}%</span>
              </div>
              <Button
                color="link"
                icon="Plus"
                name="Add line"
                onClick={() => {
                  onAddRep();
                }}
              />
            </div>
          </Canvas.Body>
          <Canvas.Footer
            onClose={onClose}
            onSave={handleSaveChange}
            saveColor="success"
            saveLoading={isSaving}
          />
        </Canvas>
      </Portal>
    </>
  );
};

export default NewGroup;
