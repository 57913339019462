import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Button } from '@base/components/form';
import { NoData } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { UPDATE_MENU_SETTING } from '@settings/general-setting/services/graphql/format';
import { useSetRecoilState } from 'recoil';
import { savingPreferencesAtom } from '@settings/preferences/recoil/atoms';

const ActivityEmailTerm: React.FC<any> = (props) => {
  const { emailData, domainData } = props;
  //state
  const setSavingOption = useSetRecoilState(savingPreferencesAtom);
  const [emailType, setEmailType] = useState<any>({
    email: true,
    domain: false,
  });
  const [emailItems, setEmailItems] = useState<any>([]); //{ value: 'email@vn.vn', description: 'spam' }
  const [debEmailItems, setDebEmailItems] = useState<any>([]);
  const setEmailItemsDebounced = useRef(
    _.debounce((newItems) => setDebEmailItems(newItems), 1000),
  ).current;
  const [domainItems, setDomainItems] = useState<any>([]); //{ value: 'vn.vn', description: 'spam' }
  const [debDomainItems, setDebDomainItems] = useState<any>([]);
  const setDomainItemsDebounced = useRef(
    _.debounce((newItems) => setDebDomainItems(newItems), 1000),
  ).current;
  const defaultValue: any = {
    value: '',
    description: ''
  }
  const initialEmailLoad = useRef<any>(false);
  const initialDomainLoad = useRef<any>(false);

  //mutation
  const mUpdate: any = useMutationPost(UPDATE_MENU_SETTING, 'setting_updateMenuSetting');

  //set saving option
  useEffect(() => {
    setSavingOption((curOption: any) => ({ ...curOption, isLoading: mUpdate.isLoading }));
  }, [mUpdate.isLoading]);

  //initial data
  useEffect(() => {
    if (emailData) {
      if (JSON.stringify(emailData) !== JSON.stringify(emailItems)) {
        setEmailItems(emailData);
      }
    } else {
      setEmailItems([]);
    }
  }, [emailData]);

  //initial data
  useEffect(() => {
    if (domainData) {
      if (JSON.stringify(domainData) !== JSON.stringify(domainItems)) {
        setDomainItems(domainData);
      }
    } else {
      setDomainItems([]);
    }
  }, [domainData]);

  //debounced saving for input text
  useEffect(() => {
    if (initialEmailLoad?.current) {
      handleSaveDebounce('emails', debEmailItems);
    } else {
      initialEmailLoad.current = true;
    }
    () => { initialEmailLoad.current = false };
  }, [debEmailItems]);

  useEffect(() => {
    if (initialDomainLoad?.current) {
      handleSaveDebounce('domains', debDomainItems);
    } else {
      initialDomainLoad.current = true;
    }
    () => { initialDomainLoad.current = false };
  }, [debDomainItems]);

  //save item
  const handleSave = (key: string, newItems: any) => {
    //const newItems = emailType.email ? [...emailItems] : [...domainItems];
    const params: any = {
      menu: 'activity',
      key: key, //emailType.email ? 'emails' : 'domains',
      value: JSON.stringify(newItems),
    };
    mUpdate.mutate({ menuSetting: params });

  }
  //debounce function
  const handleSaveDebounce = useCallback(_.debounce(handleSave, 500), [emailItems, domainItems]);

  //add new
  const handleAdd = () => {
    const newItems = emailType.email ? [...emailItems] : [...domainItems];
    newItems.push(defaultValue);
    if (emailType.email) {
      setEmailItems(newItems);
    } else {
      setDomainItems(newItems);
    }
    //save changes
    const key = emailType.email ? 'emails' : 'domains';
    handleSaveDebounce(key, newItems);
  }

  //remove item
  const handleEmailRemove = (index: number) => {
    const newItems = [...emailItems];
    newItems.splice(index, 1);
    setEmailItems(newItems);
    //save changes
    handleSaveDebounce('emails', newItems);
  }

  const handleDomainRemove = (index: number) => {
    const newItems = [...domainItems];
    newItems.splice(index, 1);
    setDomainItems(newItems);
    //save changes
    handleSaveDebounce('domains', newItems);
  }

  //value change
  const handleEmailValueChange = (index: number, keyName: string, keyValue: string) => {
    const newItems = [...emailItems];
    newItems[index][keyName] = keyValue;
    setEmailItems(newItems);
    setEmailItemsDebounced(newItems);
  }

  const handleDomainValueChange = (index: number, keyName: string, keyValue: string) => {
    const newItems = [...domainItems];
    newItems[index][keyName] = keyValue;
    setDomainItems(newItems);
    setDomainItemsDebounced(newItems);
  }

  //email addresses list
  const EmailAddresses = useMemo(() => {
    return (
      <>
        {emailItems.length === 0 && <NoData />}
        {emailItems.length > 0 &&
          <div className="table-responsive bd bd-t-0 rounded">
            <table className="table table-bordered mg-b-0 bd-0 hover-delete">
              <thead>
                <tr>
                  <th scope="col" className="text-nowrap">
                    Email Address
                  </th>
                  <th scope="col" className="text-nowrap">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {emailItems.map((_item: any, index: number) => (
                  <tr key={index}>
                    <td className="align-middle">
                      {/* {_item.id === selectedEmail?.id || _item.id === '' ? */}
                      <input
                        type={'text'}
                        className='form-control ht-38'
                        value={_item.value}
                        onChange={(e) => handleEmailValueChange(index, 'value', e.target.value)}
                      />
                      {/* : */}
                      {/* <span>{_item.value}</span> */}
                      {/* } */}
                    </td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center">
                        {/* {_item.id === selectedEmail?.id || _item.id === '' ? */}
                        <input
                          type={'text'}
                          className='form-control ht-38'
                          value={_item.description}
                          onChange={(e) => handleEmailValueChange(index, 'description', e.target.value)}
                        />
                        {/* : */}
                        {/* <span>{_item.description}</span> */}
                        {/* } */}
                        <div className='mg-l-auto wd-50'>
                          {/* {_item.id === selectedEmail?.id || _item.id === '' ?
                            <>
                              <Button
                                color=""
                                icon="Check"
                                iconClass="tx-primary"
                                className="btn-icon btn-delete-row han-tooltip--top"
                                data-han-tooltip="Save"
                                onClick={() => handleSave(index)}
                              />
                              {_item.id === selectedEmail?.id &&
                                <Button
                                  color=""
                                  icon="RefreshCw"
                                  iconClass="tx-secondary"
                                  className="btn-icon btn-delete-row han-tooltip--top"
                                  data-han-tooltip="Cancel"
                                  onClick={() => setSelectedEmail(null)}
                                />
                              }
                            </>
                            :
                            <Button
                              color=""
                              icon="Edit2"
                              iconClass="tx-secondary"
                              className="btn-icon btn-delete-row han-tooltip--top"
                              data-han-tooltip="Edit"
                              onClick={() => setSelectedEmail(_item)}
                            />
                          } */}
                          {/* {(selectedEmail === null || _item.id === '') && */}
                          <Button
                            color=""
                            icon="Trash"
                            iconClass="tx-danger"
                            className="btn-icon btn-delete-row han-tooltip--top"
                            data-han-tooltip="삭제"
                            onClick={() => handleEmailRemove(index)}
                          />
                          {/* } */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
      </>
    );
  }, [emailItems]);

  //domain addresses list
  const DomainAddresses = useMemo(() => {
    return (
      <>
        {domainItems.length === 0 && <NoData />}
        {domainItems.length > 0 &&
          <div className="table-responsive bd bd-t-0 rounded">
            <table className="table table-bordered mg-b-0 bd-0 hover-delete">
              <thead>
                <tr>
                  <th scope="col" className="text-nowrap">
                    Domain
                  </th>
                  <th scope="col" className="text-nowrap">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {domainItems.map((_item: any, index: number) => (
                  <tr key={index}>
                    <td className="align-middle">
                      {/* {_item.id === selectedDomain?.id || _item.id === '' ? */}
                      <input
                        type={'text'}
                        className='form-control ht-38'
                        value={_item.value}
                        onChange={(e) => handleDomainValueChange(index, 'value', e.target.value)}
                      />
                      {/* : */}
                      {/* <span>{_item.value}</span> */}
                      {/* } */}
                    </td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center">
                        {/* {_item.id === selectedDomain?.id || _item.id === '' ? */}
                        <input
                          type={'text'}
                          className='form-control ht-38'
                          value={_item.description}
                          onChange={(e) => handleDomainValueChange(index, 'description', e.target.value)}
                        />
                        {/* : */}
                        {/* <span>{_item.description}</span> */}
                        {/* } */}
                        <div className='mg-l-auto wd-100'>
                          {/* {_item.id === selectedDomain?.id || _item.id === '' ?
                            <>
                              <Button
                                color=""
                                icon="Check"
                                iconClass="tx-primary"
                                className="btn-icon btn-delete-row han-tooltip--top"
                                data-han-tooltip="Save"
                                onClick={() => handleSave(index)}
                              />
                              {_item.id === selectedDomain?.id &&
                                <Button
                                  color=""
                                  icon="RefreshCw"
                                  iconClass="tx-secondary"
                                  className="btn-icon btn-delete-row han-tooltip--top"
                                  data-han-tooltip="Cancel"
                                  onClick={() => setSelectedDomain(null)}
                                />
                              }
                            </>
                            :
                            <Button
                              color=""
                              icon="Edit2"
                              iconClass="tx-secondary"
                              className="btn-icon btn-delete-row han-tooltip--top"
                              data-han-tooltip="Edit"
                              onClick={() => setSelectedDomain(_item)}
                            />
                          } */}
                          {/* {(selectedDomain === null || _item.id === '') && */}
                          <Button
                            color=""
                            icon="Trash"
                            iconClass="tx-danger"
                            className="btn-icon btn-delete-row han-tooltip--top"
                            data-han-tooltip="삭제"
                            onClick={() => handleDomainRemove(index)}
                          />
                          {/* } */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
      </>
    );
  }, [domainItems]);

  //email terms
  const renderEmailTerm = () => {
    return (
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="d-flex flex-grow-1 card-header-title">
            <div className='d-flex w-40'>
              Email / Domain
            </div>
          </div>
        </div>
        <div className="card-body scroll-box" style={{ maxHeight: "calc(100vh - 220px)" }}>
          <p className="tx-info">Vora Desk App에서 Email 보낼때</p>
          <ul className="list-unstyled">
            <li className="mg-b-15">
              <span className="tx-semibold">-Business Process에서 자동 보낼때</span>
              <br />
              From : desk@*.vora.net
              <br />
              Reply-to : Rep의 Email
            </li>
            <li className="mg-b-15">
              <span className="tx-semibold">-직접 보낼때</span>
              <br />
              From : desk@*.vora.net
              <br />
              Reply-to : 본인의 Email이 기본
              <br />
              <span className='mg-l-65'>Rep의 Email</span>
            </li>
            <li className="mg-b-15">
              <span className="tx-semibold">
                -Vora Email App에서 보낼때/받을때
              </span>
              <br />
              자동 분류
            </li>
            <li className="mg-b-15">
              <span className="tx-semibold">
                -타 Email App에서 보낼때/받을때
              </span>
              <br />
              보내기: BCC-desk@*.vora.net 넣기
              <br />
              받기: Froward- 저장 후 desk@*.vora.net으로 보내기
            </li>
          </ul>
          <div className="form-group">
            <label className="form-item-title">
              Excluded Email
              <br />
              <span className="tx-orange">
                From/To 주소가 아래의 이메일 주소/도메인 정보와 일치하면 Activity-Email 이력으로
                저장 되지 않습니다.
              </span>
            </label>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Button
                  color=""
                  className={`nav-link rounded-0 ${emailType.email ? 'active tx-semibold' : ''
                    }`}
                  name="Email Address"
                  onClick={() => setEmailType({ email: true })}
                />
              </li>
              <li className="nav-item">
                <Button
                  color=""
                  className={`nav-link rounded-0 ${emailType.domain ? 'active tx-semibold' : ''
                    }`}
                  name="Domain"
                  onClick={() => setEmailType({ domain: true })}
                />
              </li>
            </ul>
            {emailType.email && EmailAddresses}
            {emailType.domain && DomainAddresses}
            <Button
              color="primary"
              icon="Plus"
              iconClass="mg-r-5"
              name="Add"
              className="mg-t-10"
              onClick={handleAdd}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='pos-relative pd-10'>
      {renderEmailTerm()}
    </div>
  );
};

export default ActivityEmailTerm;
