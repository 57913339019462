import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { ChevronsRight, ChevronsLeft } from 'react-feather';
//import { useTranslation } from "react-i18next";
import { NoData, SpanLang } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import usePublicMutationPost from '@base/hooks/usePublicMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { Button } from '@base/components/form';
import { TRACKING_SURVEY_ANSWER_CREATE } from '@site-page/services/graphql';
import SurveyTitleView from '@settings/online-digital-content/satisfaction-survey/components/survey-title-view';
import { SURVEY_RESPONSE_CREATE } from '@settings/online-digital-content/satisfaction-survey/services/graphql';
import Question from '@settings/online-digital-content/satisfaction-survey/components/question';

const DEFAULT_BG_COLOR = '#e4f3e5';

interface SurveyViewProps {
  className?: string;
  id: string;
  name: string;
  previewData: any;
  isPublic?: boolean;
  token?: string;
}

const SurveyView = (props: SurveyViewProps) => {
  const {
    className = '',
    id = '', //survey id
    name = '', // survey name
    previewData = null,
    isPublic = false,
    token = '',
  } = props;
  //const { t } = useTranslation();
  //state
  const [surveyS, setSurveyS] = useState<any>([]);
  const [curSection, setCurSection] = useState<number>(0); //init current survey is 0
  const [surveyHeaderImg, setSurveyHeaderImg] = useState<any>(null); //image on survey header
  const [surveyBgColor, setSurveyBgColor] = useState<string>(DEFAULT_BG_COLOR); //light-gray
  //const [requiredInValidKey, setRequiredInValidKey] = useState(-1); // default validate TRUE

  //private create response
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    SURVEY_RESPONSE_CREATE,
    'setting_createAnswer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Sent survey response successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Sent survey response failed: ' + JSON.parse(error).message);
      },
    },
  );

  //for client
  const mPublicAdd: any = usePublicMutationPost<BaseMutationResponse>(
    TRACKING_SURVEY_ANSWER_CREATE,
    'tracking_createAnswer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Sent survey response successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Sent survey response failed: ' + JSON.parse(error).message);
      },
    },
  );

  //track to get survey info
  useEffect(() => {
    if (previewData) {
      if (previewData?.sections) {
        setSurveyS(_.cloneDeep(previewData.sections));
      }
      if (previewData?.headerImg) {
        setSurveyHeaderImg(previewData.headerImg);
      }
      if (previewData?.bgColor) {
        setSurveyBgColor(previewData.bgColor);
      }
    }
  }, [previewData]);

  //submit success
  useEffect(() => {
    if (mutationAdd.isSuccess) {
      //reset survey
      setSurveyS(_.cloneDeep(previewData.sections)); //orginal data
    }
  }, [mutationAdd.isSuccess]);

  //submit success
  useEffect(() => {
    if (mPublicAdd.isSuccess) {
      //reset survey
      setSurveyS(_.cloneDeep(previewData.sections)); //orginal data
    }
  }, [mPublicAdd.isSuccess]);

  //validate required for question before go next or submit
  function validateRequired() {
    //const curSurveyS = surveyS[curSection];
    return true;
  }

  //go next section
  function onNext() {
    const isValid = validateRequired();
    if (isValid) {
      setCurSection((cur) => cur + 1);
    }
  }

  //go prev section
  function onPrevious() {
    setCurSection((cur) => cur - 1);
  }

  //submit response
  function onSubmit() {
    //validate required questions before submit
    const isValid = validateRequired();
    if (isValid) {
      if (surveyS && surveyS.length > 0) {
        const params = {
          survey: {
            id,
            name,
          },
          answers: JSON.stringify(surveyS),
        };
        if (isPublic) {
          mPublicAdd.mutate({ answer: params, token });
        } else {
          mutationAdd.mutate({ answer: params });
        }
      }
    }
  }

  //question content change
  const handleQuestionChange = (indexS: number, indexQ: number, newQuestion: any) => {
    const newSurveyS = [...surveyS];
    newSurveyS[indexS].questions[indexQ] = newQuestion;
    setSurveyS(newSurveyS);
  };

  //render questions
  const SurveyQuestions = (props: any) => {
    const { surveyQ } = props;
    //// console.log('surveyQ', surveyQ);
    return (
      <div className="survey-write-tab-content">
        {/* header image */}
        {surveyHeaderImg && (
          <div className="survey-write-header-card">
            <img src={surveyHeaderImg} className="survey-write-header-image" />
          </div>
        )}
        {/* first section: title+desc */}
        <SurveyTitleView title={surveyQ.title} description={surveyQ.description} />
        {/* render questions */}
        {surveyQ.questions.map((_question: any, index: number) => {
          return (
            <Fragment key={index}>
              <Question
                keyS={curSection}
                keyQ={index}
                value={_question}
                onChange={handleQuestionChange}
              />
            </Fragment>
          );
        })}
        {/* submit button */}
        <div className="pd-t-10">
          {curSection < surveyS.length - 1 && (
            <button type="button" className="btn btn-primary mg-r-10" onClick={onNext}>
              <SpanLang keyLang="Next" /> <ChevronsRight />
            </button>
          )}
          {curSection > 0 && (
            <button type="button" className="btn btn-primary mg-r-10" onClick={onPrevious}>
              <ChevronsLeft /> <SpanLang keyLang="Previous" />
            </button>
          )}
          {curSection === surveyS.length - 1 && id != '' && (
            <Button
              color="primary"
              onClick={onSubmit}
              loading={mutationAdd.isLoading || mPublicAdd.isLoading}
              disabled={mutationAdd.isLoading || mPublicAdd.isLoading}
            >
              <SpanLang keyLang="Submit" />
            </Button>
          )}
        </div>
      </div>
    );
  };

  //// console.log('surveyS', surveyS);
  //main render
  return (
    <div className={classNames('pos-relative z-index-10', className)}>
      <div className="pos-relative" style={{ backgroundColor: surveyBgColor }}>
        <div className="survey-write-content-container pd-t-10">
          {surveyS.length > 0 && <SurveyQuestions surveyQ={surveyS[curSection]} />}
          {surveyS.length === 0 && <NoData />}
        </div>
      </div>
    </div>
  );
};

export default SurveyView;
