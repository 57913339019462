import * as keyNames from '@settings/site/desk/config/key-names';
import * as commonComponents from '@base/config/view-field/components';
import * as components from './components';

// import {
//   TEMPLATE_TYPE_OPTIONS,
//   TEMPLATE_STAGE_OPTIONS,
//   TEMPLATE_MESSAGE_TYPE_OPTIONS,
// } from '../constants';
import * as commonConfig from '@base/config/view-field';
import { IFieldConfig } from '@base/types/interfaces/common';
import {
  parserIdNameToSelect,
  parserSelectToIdName,
  parserSelectToIdNameAndKey,
  parserSelectToIdNameObj,
} from '@base/utils/helpers/config-utils';
import { t } from 'i18next';

const configView: IFieldConfig = {
  ...commonConfig?.default,

  [keyNames.KEY_SITE_DESK_DESCRIPTION]: {
    // component: commonComponents.TextAreaView,
    schema: `description`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
  [keyNames.KEY_SITE_DESK_CODE]: {
    schema: `code`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
  [keyNames.KEY_SITE_DESK_CUSTOMER]: {
    //schema: `customer`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
  [keyNames.KEY_SITE_DESK_CONTACT]: {
    //schema: `code`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
  [keyNames.KEY_SITE_DESK_STATUS]: {
    component: commonComponents.DataSourceView,
    componentProps: {
      single: true,
      sourceKey: 'ticket_status',
      sourceType: 'field',
    },
    schema: `status {
      keyName
      languageKey
    }`,
    getValueView: (value: any) => {
      return t(value?.languageKey ?? '');
    },
    getValueEdit: (value: any) => {
      return { ...value, value: value?.keyName, label: t(value?.languageKey ?? '') };
    },
    getMutationValue: (value: any) => {
      //basic api params
      return value?.keyName || 'STATUS_NONE';
    },

    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
  [keyNames.KEY_SITE_DESK_CATEGORY]: {
    //schema: `code`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
  [keyNames.KEY_SITE_DESK_ASSIGNED_USER]: {
    //schema: `code`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
  [keyNames.KEY_SITE_DESK_FIRST_RESPOND]: {
    //schema: `code`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
  [keyNames.KEY_SITE_DESK_RESOLVED_ON]: {
    //schema: `code`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
  [keyNames.KEY_SITE_DESK_CLOSED_AT]: {
    //schema: `code`,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
};

export default configView;
