import { OptionValue } from '@base/types/interfaces/common';
import { LabelValue } from '@base/types/types/app';

export const initTypeOption: OptionValue = {
  keyName: '',
  languageKey: 'Select Type',
};

export const initContentOption: OptionValue = {
  keyName: '',
  languageKey: 'Select Content',
};

export const categoryOptions: LabelValue[] = [
  {
    label: 'Site',
    value: 'SITE_CATEGORY_SITE',
  },
  {
    label: 'Landing Page',
    value: 'SITE_CATEGORY_LANDING',
  },
];

export const samplePage = `
    Hello, $$Customer$$
    Thank you for using our service.
    plz click following button.

    <a title="Product" href="@@CTA.PAGE=xxxxxxxxxxx@@">Go to page</a>
`;
export { default as SiteView } from './SiteView';
export { default as SiteWrite } from './SiteWrite';
