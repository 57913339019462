import { atom } from 'recoil';

export const kbWriteOptionAtom = atom({
  key: 'KBWriteOptionAtom',
  default: {
    writeType: 'knowledgebase',
    writeMenu: 'list',
    isOpenWrite: false,
  },
});
