import React, { useState, useEffect, useCallback } from 'react';
import { Download, Edit, FolderPlus, Trash2, UserCheck, UserPlus } from 'react-feather';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';

import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { FilterInput } from '@base/types/interfaces/common';
import { DESC, ASC } from '@base/config/constant';
import SpanLang from '@base/components/form/span-lang';
import ListLoading, { GridNothing, TableNothing } from '@base/components/list/list-loading';
import {
  ListBody,
  ListContainer,
  ListGrid,
  ListPagination,
  ListQuickAction,
  ListToolbar,
} from '@base/components/list';
import {
  DEFAULT_CATEGORY,
  DEFAULT_GROUP,
  MAIN_MENU,
  WritePage,
} from '../../config/pages/main-page';
import { ListPageConfig } from '../../config/pages/list-page';
import { SearchFilter } from '@base/types/interfaces/app';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@settings/template/config/display-layout';
import { categoryOptions } from '@settings/template/config';
import { ListType } from '@base/types/enums';
import ListHeader, { ListHeaderFilters } from '@settings/template/components/list/list-header';
import ListGridItem from '@settings/template/components/list/list-grid/list-grid-item';
import { handleCheckbox } from '@base/utils/helpers/list-page-utils';
import { WRITE_OPTION_ATOM } from '@settings/template/config/pages/write-page';
import { IMenuTemplate } from '@settings/template/types/interfaces';
import useMutationPost from '@base/hooks/useMutationPost';
import {
  UPDATE_MENU_TEMPLATE,
  DELETE_MENU_TEMPLATE,
  DELETE_MENU_TEMPLATES,
} from '@settings/template/services/graphql';
import { toast } from 'react-toastify';
import ListReactTable from '@base/components/list/list-react-table';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';
import WriteFormPage from '../write-form';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { TEMPLATE_GROUP } from '@settings/template/config/constants';

interface ITemplateListPage {}

const TemplateListPage: React.FC<ITemplateListPage> = (props: ITemplateListPage) => {
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MAIN_MENU));
  const [writeOption, setWriteOption] = useRecoilState(WRITE_OPTION_ATOM);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [{ isDesktop, isMobile, isTablet, device, layout }] = useRecoilState(deviceAtom);
  const isSplitMode = false;
  const navigate = useNavigate();
  const params = useParams();
  const aParams = params['*'] ? params['*'].split('/') : [];
  const templateType = aParams[0] ? aParams[0] : DEFAULT_CATEGORY;
  const templateGroup = aParams[1] ? aParams[1] : DEFAULT_GROUP;
  const columnKeys: string[] = [];
  const columns = ListPageConfig.getColumns();
  const columnRenderRemap = ListPageConfig.getColumnRenderRemap({ templateType, templateGroup });
  const hideColumns = ListPageConfig.getHideColumns();
  // update menuTemplate
  const mUpdateTemplate: any = useMutationPost<IMenuTemplate>(
    UPDATE_MENU_TEMPLATE,
    'setting_updateMenuTemplate',
    {
      onSuccess: (res: any) => {
        // refetch();
        toast.success('Template has updated!');
      },
      onError: (res: any) => {},
    },
  );
  let { filter, listType } = pageData;
  const sort = filter?.sort;
  const setSort = (sort: any) => {
    let newData = {
      ...pageData,
      filter: {
        ...filter,
        sort: sort,
      },
    };
    setPageData(newData);
  };
  let baseFilters: any = {
    ...filter?.baseFilters,
    templateGroup,
  };
  let query = '';
  if (templateGroup != '') {
    let index = TEMPLATE_GROUP.findIndex((x) => x.key === templateGroup);
    if (index != -1) {
      query = 'group:' + index;
    }
  }
  let filtersQuery: FilterInput = {
    //filters: { ...baseFilters, ...filter?.searchFilters, ...filter?.headerFilters },
    query: query,
    sort: filter?.sort,
    paging: filter?.paging,
  };

  const { isLoading, data, isFetching, isPreviousData, refetch } =
    ListPageConfig.useFectchList(filtersQuery);
  const listData = data?.data ? data.data : [];
  const onCheckedRow = useCallback(
    (checkedValue: string | number) => {
      const checkedValueStr = checkedValue as string;
      let newSelectedIds = handleCheckbox(checkedValueStr, selectedIds, listData);
      setSelectedIds([...newSelectedIds]);
    },
    [selectedIds, setSelectedIds],
  );
  const onDelete = (id: string) => {};
  const onOpenEdit = (id: string) => {
    let editUrl = `/settings/template/${templateType}/${templateGroup}/${id}`;
    navigate(editUrl);
  };
  const onAfterSaved = (data: any) => {
    // refetch();ListQuickAction
  };
  const goView = useCallback((row: any) => {
    const url = `/settings/${MAIN_MENU}/${templateType}/${templateGroup}/${row.id}`;
    navigate(url);
  }, []);
  const listItemProps = {
    templateType,
    templateGroup,
    onDelete,
    onOpenEdit,
    onAfterSaved,
  };
  const listProps = {
    listData: listData,
    isFetching: false,
    fetching: <ListLoading />,
    onCheckedRow,
  };
  const listGridProps = {
    isSplitMode: false,
    selectedIds,
    device: device !== undefined ? device : 'desktop',
    nodata: <GridNothing />,
    columns,
    columnRenderRemap,
    hideColumns,
  };
  const listTableProps = {
    nodata: <TableNothing />,
    data: listData,
    columns: makeTableColumns(
      columns,
      columnRenderRemap,
      { templateType, templateGroup },
      ListPageConfig.getHideTableColumns(),
    ),
    initialState: {
      pageSize: data?.paging?.totalPage,
      pageIndex: data?.paging?.currentPage,
      selectedIds: selectedIds,
    },
    onCheckedRow: onCheckedRow,
    onRowClick: (row: any, e: any) => {
      e && e.preventEvent && e.preventDefault();
      goView(row);
    },
    onSortBy: (clName: string, isSortedDesc: boolean) => {
      setSort({ field: clName, orderBy: isSortedDesc ? DESC : ASC });
    },
  };
  const pagingProps = {
    totalPage: data?.paging?.totalPage || 1,
    totalItems: data?.paging?.totalItems || 0,
    currentPage: data?.paging?.currentPage || 1,
    itemPerPage: data?.paging?.itemPerPage || 10,
    nextPage: data?.paging?.nextPage || null,
    previousPage: data?.paging?.previousPage || null,
    isPreviousData,
    onChange: (page: number, size: number) => {
      filter = {
        ...filter,
        paging: {
          page,
          size,
        },
      };
      let newData = {
        ...pageData,
        filter: filter,
      };
      setPageData(newData);
    },
  };

  const sortColumns = [
    {
      label: 'Updated At',
      value: 'updatedAt',
    },
    {
      label: 'Created At',
      value: 'createdAt',
    },
  ];

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_MENU_TEMPLATES,
    'setting_deleteMenuTemplates',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Deleted Template successfully!');
        // refecth data
        setSelectedIds([]);
        //onReload && onReload();
        refetch && refetch();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Deleted Template failed: ' + JSON.parse(error).message);
      },
    },
  );
  const handleDelete = () => {
    //// console.log('>>>>>> selectedIds', selectedIds);
    //mutationDelete.mutate({ id: selectedIds[0] });
    mutationDelete.mutate({ ids: selectedIds });
  };
  const quickButtons = [
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: handleDelete,
    },
  ];

  // more menu
  const actionsProps = {};

  const handleSearchSubmit = (formData: any) => {
    // console.log('handleSearchSubmit', formData);
    let nFormData = {
      name: formData.keyword,
    };
    if (formData?.type === 'TYPE_NONE') {
      delete formData.type;
    }

    // setSearchParams(formData);
    let newFilter: SearchFilter = {
      ...pageData.filter,
      searchFilters: nFormData,
    };
    let newData = {
      ...pageData,
      filter: newFilter,
    };
    setPageData(newData);
  };
  const keyTitle = `crm_new_` + templateGroup;
  return (
    <>
      <ListContainer
        isLoading={isLoading || isFetching}
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={
            <ListToolbar
              hideHeaderTitle={true}
              listTypeProps={{
                listTypeStr: listType,
                onChangeListType: (listType: ListType) => {
                  let newData = {
                    ...pageData,
                    listType: listType,
                  };
                  setPageData(newData);
                },
                listTypes: [ListType.GRID, ListType.LIST],
              }}
              isSplitMode={isSplitMode}
              onRefresh={refetch}
              onAdd={(category: string | undefined) =>
                setWriteOption((filter) => ({
                  ...filter,
                  isOpenWrite: true,
                  writeType: templateType === undefined ? DEFAULT_CATEGORY : templateType,
                }))
              }
            />
          }
          header={
            <ListHeader
              listType={ListType.GRID}
              isSplitMode={isSplitMode}
              searchFields={ListPageConfig.searchFields.reduce((final: any[], field: any) => {
                if (columnKeys.indexOf(field.name) !== -1) {
                  final.push(field);
                }
                return final;
              }, [])}
              onSearchSubmit={(formData: any) => handleSearchSubmit(formData)}
              columns={columns}
              filterComponent={
                <ListHeaderFilters
                  category={templateType ?? ''}
                  isSplitMode={isSplitMode}
                  filterData={filter?.headerFilters}
                  onFilterDataChanged={(valueData: any) => {
                    let newFilter: SearchFilter = {
                      ...filter,
                      headerFilters: valueData,
                    };
                    let newData = {
                      ...pageData,
                      filter: newFilter,
                    };
                    setPageData(newData);
                  }}
                  sortData={sort}
                  onSortDataChanged={setSort}
                  sortColumns={sortColumns}
                />
              }
              onSelectedAll={() => onCheckedRow('all')}
              onDeselectedAll={() => onCheckedRow('notAll')}
              onRefresh={refetch}
            />
          }
          body={
            <ListBody>
              {listType == ListType.LIST && <ListReactTable {...listTableProps} />}
              {listType == ListType.GRID && (
                <ListGrid
                  {...listProps}
                  {...listGridProps}
                  listGridItemRender={(props: any) => (
                    <ListGridItem {...props} {...listItemProps} />
                  )}
                />
              )}

              <ListPagination isSplitMode={false} type={'full'} {...pagingProps} />
            </ListBody>
          }
        />

        {/* Tablet layout here */}
        {isTablet && null}

        {/* Mobile layout here */}
        {isMobile && null}
      </ListContainer>

      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons || []}
        resetSelected={() => setSelectedIds([])}
        visible={!!selectedIds.length}
      />

      {/* hook form - write new customer write */}
      {writeOption.isOpenWrite && ['email', 'sms'].includes(templateGroup) && (
        <WritePage
          title={<SpanLang keyLang={keyTitle} />}
          onClose={() => {
            setWriteOption((filter: any) => ({
              ...filter,
              isOpenWrite: false,
            }));
            refetch();
          }}
          templateType={templateType}
          templateGroup={templateGroup}
        />
      )}
      {writeOption.isOpenWrite && ['knowledgebase', 'task', 'call'].includes(templateGroup) && (
        <WriteFormPage
          title={<SpanLang keyLang={keyTitle} />}
          onClose={() => {
            setWriteOption((filter: any) => ({
              ...filter,
              isOpenWrite: false,
            }));
            refetch();
          }}
          templateType={templateType}
          templateGroup={templateGroup}
        />
      )}
    </>
  );
};

export default TemplateListPage;
