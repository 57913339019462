import BasicTable from '@base/components/table/basic';
interface IDataTransaction {
  date: Date;
  activity: string;
  item: string;
  note: string;
  amount: number;
  transactionId: string;
}
interface IHistoryTransactionTable {
  columns: any[];
  data: IDataTransaction[];
}
const TransactionTable: React.FC<IHistoryTransactionTable> = (props: IHistoryTransactionTable) => {
  const { columns, data } = props;
  return (
    <div className="card">
      <div className="card-header bg-light">
        <h2 className="card-title h6 mg-b-0">Transaction History</h2>
      </div>
      <div className="table-responsive">
        <BasicTable columns={columns} data={data}></BasicTable>
      </div>
    </div>
  );
};
export default TransactionTable;
