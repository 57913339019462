import React, { Component, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight, Printer, Trash } from 'react-feather';
//import ReactToPrint from 'react-to-print';
import { confirmAlert, SpanLang } from '@base/components';
import SurveyTitleView from '@settings/online-digital-content/survey/components/survey-title-view';
import SurveyResponse from './survey-response';
import Loading from '@base/components/loading';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { SURVEY_RESPONSE_DELETE } from '@settings/online-digital-content/survey/services/graphql';

const Individual: React.FC<any> = (props) => {
  const { responseS, onSetResponseS } = props;
  const { t } = useTranslation();

  //local state
  const [selectedS, setSelectedS] = useState<any>(null);
  const responseRef = useRef<any>(null);
  const [selectedIdx, setSelectedIdx] = useState<number>(1);

  //mutation delete
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    SURVEY_RESPONSE_DELETE,
    'setting_deleteAnswer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Create Ticket successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during processing: ' + JSON.parse(error).message);
      },
    },
  );

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationDelete.isSuccess) {
      //reset list
      const newResponseS = [...responseS];
      newResponseS.splice(selectedIdx - 1, 1);
      onSetResponseS(newResponseS);

      //reset selected id
      let newIdx = selectedIdx + 1;
      if (newIdx > newResponseS.length) {
        newIdx = newResponseS.length;
      }
      setSelectedIdx(newIdx);
      //set selected question
      setSelectedS(newResponseS[newIdx - 1]);
    }
  }, [mutationDelete.isSuccess]);

  //set init selected questions
  useEffect(() => {
    const initSelectedS = responseS.length > 0 ? responseS[0] : null;
    setSelectedS(initSelectedS);
  }, [responseS]);

  //change question index
  function handleSIndexChange(e: any) {
    let value = e.target.value;
    setSelectedIdx(value);

    //set selected question
    if (value > 0 && value <= responseS.length) {
      setSelectedS(responseS[value - 1]);
    }
  }

  //next question
  function handleNextIdx() {
    let newIdx = selectedIdx + 1;
    if (newIdx > responseS.length) {
      newIdx = responseS.length;
    }
    setSelectedIdx(newIdx);
    //set selected question
    setSelectedS(responseS[newIdx - 1]);
  }

  //previous question
  function handlePrevIdx() {
    let newIdx = selectedIdx - 1;
    if (newIdx < 1) {
      newIdx = 1;
    }
    setSelectedIdx(newIdx);
    //set selected question
    setSelectedS(responseS[newIdx - 1]);
  }

  //delete response
  function handleDeleteResponse() {
    mutationDelete.mutate({ ids: [selectedS.id] });
    // confirmAlert({
    //   title: t('crm_new_common_delete'),
    //   message: t('Are you sure you want to delete this response?'),
    //   buttons: [
    //     {
    //       label: 'No',
    //       className: 'btn-secondary',
    //     },
    //     {
    //       label: 'Yes',
    //       className: 'btn-primary',
    //       onClick: () => {
    //         mutationDelete.mutate({ ids: [selectedS.id] });
    //       },
    //     },
    //   ],
    // });
  }

  //component render
  return (
    <div className="pos-relative">
      {/* questions select */}
      {responseS.length > 0 && (
        <div
          className="card"
          style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0, borderTop: 0 }}
        >
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between">
              {/* response number select */}
              <div className="d-flex flex-row justify-content-between align-items-center wd-200">
                <div className="btn survey-home-btn-more-big" onClick={handlePrevIdx}>
                  <ChevronLeft />
                </div>
                <input
                  type="number"
                  min={1}
                  max={responseS.length}
                  className="wd-60 tx-center survey-write-input"
                  value={selectedIdx}
                  onChange={handleSIndexChange}
                />
                <span className="tx-16">
                  <SpanLang keyLang="of" />
                </span>
                <span className="tx-16">{responseS.length}</span>
                <div className="btn survey-home-btn-more-big" onClick={handleNextIdx}>
                  <ChevronRight />
                </div>
              </div>
              <div className="d-flex">
                {/* <ReactToPrint
                  trigger={() => <div className="btn survey-home-btn-more-big mg-r-15"><Printer /></div>}
                  content={() => responseRef.current}
                  onBeforeGetContent={() => setIsLoading(true)}
                  onBeforePrint={() => setIsLoading(false)}
                /> */}
                <div className="btn survey-home-btn-more-big" onClick={handleDeleteResponse}>
                  <Trash />
                </div>
              </div>
            </div>
          </div>

          {mutationDelete.isLoading && <Loading />}
        </div>
      )}

      {/* response component - print */}
      <div
        className="pos-relative mg-t-10 pd-b-10 scroll-box"
        ref={responseRef}
        style={{ height: 'calc(100vh - 370px)' }}
      >
        {selectedS &&
          selectedS.sections.map((section: any, sIdx: number) => (
            <div key={sIdx} className="pd-b-15">
              {/* survey title */}
              <SurveyTitleView
                className="mg-b-10"
                title={section.title}
                description={section.description}
              />
              {/* response survey content */}
              {section.questions.map((item: any, qIdx: number) => (
                <div key={qIdx} className="pd-b-10">
                  <SurveyResponse
                    keyQ={qIdx}
                    title={item.title}
                    image={item.image}
                    required={item.required}
                    selectedQType={item.type}
                    options={item.options}
                  />
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Individual;
