import React from 'react';
import TriggerView from '../trigger/TriggerView';
import { Wait } from '@settings/process/types/process';
import dayjs from 'dayjs';
import { parseDurationValueToString } from '@base/utils/helpers/date-utils';

interface WaitViewProps {
  value: Wait;
}

function WaitView({ value }: WaitViewProps) {
  return (
    <div className="form-row">
      <div className="form-group col-12">
        <label className="form-item-title">Wait Type</label>
        <div className="input-group wd-200">{value.type}</div>
      </div>
      {value.type == 'WAIT_UNTIL_TRIGGER' && <TriggerView value={value.trigger} />}
      {value.type == 'WAIT_UNTIL_DATE_TIME' && (
        <div className="form-group col-12">
          <label className="form-item-title">Date/Time</label>
          <div className="input-group wd-200">
            {value.datetime ? dayjs(value.datetime).format('yyyy/MM/dd HH:mm') : ''}
          </div>
        </div>
      )}
      {value.type == 'WAIT_BY_DURATION' && (
        <div className="form-group col-12">
          <label className="form-item-title">Duration</label>
          <div className="d-flex align-items-center">
            <span className="mg-r-10">Wait</span>
            {parseDurationValueToString({
              duration: value.duration?.time,
              durationUnit: value.duration?.unit,
            })}
          </div>
        </div>
      )}
      {value?.type == 'WAIT_SCHEDULE_ATTRIBUTE' && (
        <div className="form-group col-12">
          <label className="form-item-title">Schedule</label>
          <div className="d-flex flex-wrap align-items-center justify-content-start">
            <span className="mg-r-10">Wait until</span>
            {parseDurationValueToString({
              duration: value.schedule?.duration?.time,
              durationUnit: value.schedule?.duration?.unit,
            })}
            <span>{value.schedule?.when}</span>
            <span>{value.schedule?.attr}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default WaitView;
