import React from 'react';
import Icons from '@base/assets/icons/svg-icons';
import { useTranslation } from 'react-i18next';
import { Trash } from 'react-feather';
import { SpanLang } from '@base/components';

//render question types
const ImageView: React.FC<any> = (props) => {
  const {
    focusS,
    focusQ,
    keyS,
    keyQ,
    imageQ,
    onRemoveQImage
  } = props;
  const { t } = useTranslation();

  //render
  return (
    <div className="survey-write-question-card-image">
      <div className="survey-write-question-card-image-pos">
        {/* custom image menu */}
        {focusS === keyS && focusQ === (keyQ + 1) &&
          <div className="survey-write-question-card-image-menu">
            <div className="survey-home-grid-item-overflow">
              <a
                className="btn survey-home-btn-more cursor-default"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {Icons("more_vertical")}
              </a>
              <div className="dropdown-menu">
                <a className='dropdown-item cursor-default' onClick={onRemoveQImage}>
                  <Trash /> <SpanLang keyLang='Remove' />
                </a>
              </div>
            </div>
          </div>
        }
        <img src={URL.createObjectURL(imageQ.url)} className="survey-write-question-card-image-view" alt="Q_image" style={{ width: "680px" }} />
      </div>
    </div>
  )
}

export default ImageView;