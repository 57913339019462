import { Portal, SpanLang } from '@base/components';
import Canvas from '@base/components/canvas';
import { Button, Checkbox, Input, Select } from '@base/components/form';
import UserAutoComplete from '@base/containers/user-auto-complete';
import { generateUUID } from '@base/utils/helpers';
import { AR_ASSIGNTO_TYPE_OPTIONS } from '@settings/assignment-rule/config/constant';
import { EARAssignToMode, EARAssignToType } from '@settings/assignment-rule/types/enums';
import { IARAssignToItem } from '@settings/assignment-rule/types/interfaces';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IAssignToAddProps {
  isOpen: boolean;
  onClose: () => void;
  onAfterAdded: (nItems: IARAssignToItem[]) => void;
}
const AssignToAdd: React.FC<IAssignToAddProps> = (props: IAssignToAddProps) => {
  const { isOpen, onClose, onAfterAdded } = props;
  const [assignToType, setAssignToType] = useState(EARAssignToType.USERS);
  const [assignToMode, setAssignToMode] = useState(EARAssignToMode.OWNER);
  const [assignToTypeOpt, setAssignToTypeOpt] = useState(AR_ASSIGNTO_TYPE_OPTIONS[0]);
  const [seletedUsers, setSelectedUsers] = useState<any[]>([]);
  const [seletedGroups, setSeletedGroups] = useState<any[]>([]);
  const [baseOnWorkDay, setBaseOnWorkDay] = useState(false);
  const [capacity, setCapacity] = useState(0);
  const { t } = useTranslation();
  const handleSave = () => {
    let nItems: IARAssignToItem[] = [];
    if (assignToType === EARAssignToType.USERS) {
      seletedUsers.map((u) => {
        let nItem: IARAssignToItem = {
          id: generateUUID(),
          mode: assignToMode,
          type: assignToType,
          assignTo: {
            user: {
              id: u.id,
              name: u.name,
            },
            group: {
              id: generateUUID(),
              name: 'Group',
            },
          },
          baseOnWorkDay,
          capacity,
        };
        nItems.push(nItem);
      });
    } else {
      seletedGroups.map((g) => {
        let nItem: IARAssignToItem = {
          id: generateUUID(),
          mode: assignToMode,
          type: assignToType,
          assignTo: {
            user: {
              id: '',
              name: '',
            },
            group: {
              id: g.id,
              name: g.name,
            },
          },
          baseOnWorkDay,
          capacity,
        };
        nItems.push(nItem);
      });
    }

    // console.log('handleSave', nItems);
    onClose && onClose();
    onAfterAdded && onAfterAdded(nItems);
  };
  const handleChangeAssignType = (nVal: any) => {
    setAssignToType(nVal.keyName);
    setAssignToTypeOpt(nVal);
  };
  return (
    <>
      <Portal id={document.body}>
        <Canvas
          isVisible={isOpen}
          onCloseSideBar={() => onClose()}
          className={'page-sidebar-container-wrap wd-500'}
          customStyles={{ zIndex: 99999 }}
        >
          <Canvas.Header title={<SpanLang keyLang="Assign To" />} />
          <Canvas.Body style={{ height: 'calc(100vh - 130px)' }}>
            {/* <WriteCustomFieldForm fieldData={itemEdit} setFieldData={setItemEdit} /> */}
            <>
              <div className="form">
                <div className="d-flex flex-wrap align-items-center mg-b-10">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="assignTo1"
                      name="assignTo"
                      className="custom-control-input"
                      defaultChecked
                      onClick={() => {
                        setAssignToMode(EARAssignToMode.OWNER);
                        setAssignToType(EARAssignToType.USERS);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="assignTo1">
                      Assign to owner
                    </label>
                  </div>
                </div>
                {assignToMode === EARAssignToMode.OWNER && (
                  <div>
                    <UserAutoComplete
                      className="mg-l-20 mg-t-5"
                      value={seletedUsers}
                      onChange={(nVal: any) => setSelectedUsers(nVal)}
                    />
                  </div>
                )}

                {/* Assign to round-robin list */}
                <div className="custom-control custom-radio mg-b-10">
                  <input
                    type="radio"
                    id="assignTo2"
                    name="assignTo"
                    className="custom-control-input"
                    onClick={() => setAssignToMode(EARAssignToMode.ROUND_ROBIN)}
                  />
                  <label className="custom-control-label" htmlFor="assignTo2">
                    Assign to users (groups) with a round-robin pattern.
                  </label>
                </div>
                {assignToMode === EARAssignToMode.ROUND_ROBIN && (
                  <div className="mg-b-10 pd-l-25">
                    <div className="d-flex align-items-center mg-b-10">
                      Choose a round-robin list from
                      <Select
                        className="flex-shrink-0 mg-l-10"
                        options={AR_ASSIGNTO_TYPE_OPTIONS}
                        value={assignToTypeOpt}
                        onChange={handleChangeAssignType}
                        getOptionLabel={(c: any) => {
                          return t(c.languageKey);
                        }}
                        getOptionValue={(c: any) => {
                          return c.keyName;
                        }}
                        width={110}
                      />
                    </div>
                    {/* Users list */}
                    {assignToType === EARAssignToType.USERS && (
                      <UserAutoComplete
                        value={seletedUsers}
                        onChange={(nVal: any) => setSelectedUsers(nVal)}
                      />
                    )}
                    {/* Group list */}
                    {assignToType === EARAssignToType.GROUPS && (
                      <div className="input-group">
                        <Input placeholder="Type or click to select a group" />
                        <div className="input-group-append">
                          <Button color="" className="input-group-btn" icon="User" />
                        </div>
                      </div>
                    )}
                    <div className="mg-t-10">
                      <label className="tx-color-03">Check availability based on</label>
                      <Checkbox
                        label="Work Day"
                        checked={baseOnWorkDay}
                        onChange={() => setBaseOnWorkDay(!baseOnWorkDay)}
                      />
                    </div>
                  </div>
                )}

                {/* Load Balance by Capacity */}
                <div className="d-flex align-items-center mg-b-10">
                  <div className="custom-control custom-radio mg-r-10">
                    <input
                      type="radio"
                      id="assignTo3"
                      name="assignTo"
                      className="custom-control-input"
                      onClick={() => setAssignToMode(EARAssignToMode.BALANCE_CAPACITY)}
                    />
                    <label className="custom-control-label" htmlFor="assignTo3">
                      Load Balance by Capacity
                    </label>
                  </div>
                  {assignToMode === EARAssignToMode.BALANCE_CAPACITY && (
                    <Select
                      options={AR_ASSIGNTO_TYPE_OPTIONS}
                      value={assignToTypeOpt}
                      onChange={handleChangeAssignType}
                      getOptionLabel={(c: any) => {
                        return t(c.languageKey);
                      }}
                      getOptionValue={(c: any) => {
                        return c.keyName;
                      }}
                      width={110}
                    />
                  )}
                </div>
                {assignToMode === EARAssignToMode.BALANCE_CAPACITY && (
                  <div className="mg-l-25">
                    <ul className="list-group mg-b-10">
                      <li className="list-group-item d-flex align-items-center justify-content-between bg-white pd-10">
                        {assignToType === EARAssignToType.USERS ? 'Users' : 'Groups'}
                        <Input
                          className="wd-100"
                          placeholder="Capacity"
                          value={capacity}
                          onChange={(nVal: any) => setCapacity(nVal)}
                        />
                      </li>
                      <li className="list-group-item d-flex align-items-center justify-content-between bg-white pd-10">
                        {assignToType === EARAssignToType.USERS && (
                          <UserAutoComplete
                            className="wd-100p"
                            value={seletedUsers}
                            onChange={(nVal: any) => setSelectedUsers(nVal)}
                          />
                        )}
                        {assignToType === EARAssignToType.GROUPS && (
                          <div className="input-group">
                            <Input placeholder="Type or click to select a group" />
                            <div className="input-group-append">
                              <Button color="" className="input-group-btn" icon="Users" />
                            </div>
                          </div>
                        )}
                      </li>
                      <li className="list-group-item tx-right tx-semibold">Total Capacity : 100</li>
                    </ul>
                    <div>
                      <label className="tx-color-03">Check availability based on</label>
                      <Checkbox
                        label="Work Day"
                        checked={baseOnWorkDay}
                        onChange={() => setBaseOnWorkDay(!baseOnWorkDay)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          </Canvas.Body>
          <Canvas.Footer onClose={() => onClose()} onSave={() => handleSave()} saveLabel={'Add'} />
        </Canvas>
      </Portal>
    </>
  );
};

export default AssignToAdd;
