import Canvas from '@base/components/canvas';
import { FormIcon, SpanLang, Tooltip } from '@base/components/form';
import SelectHook from '@base/components/form/select-hook';
import LineLoader from '@base/components/placeholder/line-loader';
import {
  BASIC_DATA_TYPE_MULTI_CHOICES,
  BASIC_DATA_TYPE_SELECT_BOX,
  BASIC_DATA_TYPE_SINGLE_CHOICE,
  DATA_TYPE_ATTR_DUPLICATE,
  DATA_TYPE_ATTR_EMPLOYEE_SHOW,
  DATA_TYPE_ATTR_LAYOUT,
  DATA_TYPE_ATTR_LOOKUP_DATA_SOURCE,
  DATA_TYPE_ATTR_MAXLENGTH,
  DATA_TYPE_ATTR_MULTIPLE,
  DATA_TYPE_ATTR_NUMBER_DECIMAL,
  DATA_TYPE_ATTR_NUMBER_DIGITS,
  DATA_TYPE_ATTR_REQUIRED,
  DATA_TYPE_ATTR_TOOLTIP_MSG,
  DATA_TYPE_ATTR_TOOLTIP_SHOW,
  DATA_TYPE_ATTR_TOOLTIP_TYPE,
} from '@base/config/data-types';
import {
  CUSTOM_LAYOUT_TYPES,
  TOOLTIP_TYPE_ICON,
  TOOLTIP_TYPE_TEXT,
} from '@base/config/pagelayout-write';
import LangInput from '@base/containers/lang-input';
import item from '@base/containers/note/item';
import useMutationPost from '@base/hooks/useMutationPost';
import { UPDATE_SYSTEM_LANGUAGE } from '@base/services/graphql/sys_language';
import { getCurrentLang } from '@base/services/i18n';
import { ILanguageData } from '@base/types/interfaces/common';
import { generateUUID, nanoid } from '@base/utils/helpers';
import {
  UPDATE_PAGE_LAYOUT_FIELD,
  UPDATE_PAGE_LAYOUT_TPL_FIELD,
} from '@settings/page-layout/services/graphql/pagelayout';
import {
  IField,
  IPgField,
  ITemplateField,
} from '@settings/page-layout/types/interfaces/pagelayout';
import { initPgFieldUpdateData, initTplFieldUpdateData } from '@settings/page-layout/utils/helpers';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  AlignJustify,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  Info,
  Move,
  Plus,
  Trash,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const LIMIT_DEFAULT_VIEW_IN_LIST = 7;
let savedFieldData: IField = { id: '' };
interface IWriteCustomFieldFormProps {
  initFieldData: IField; // init Field Data
  lookupDataSources?: any;
  isOpen?: boolean;
  setIsOpen: (params: boolean) => void;
  onSavedFieldData: (params: IField) => void;
  initTotalDefaultViewInList?: number;
}
const WriteCustomFieldForm: React.FC<IWriteCustomFieldFormProps> = (
  props: IWriteCustomFieldFormProps,
) => {
  const {
    initFieldData,
    onSavedFieldData,
    //redux
    lookupDataSources,
    isOpen = false,
    setIsOpen,
    initTotalDefaultViewInList = 0,
  } = props;

  const [fieldData, setFieldData] = useState<IField>({ id: '' });

  const currentLang = getCurrentLang();
  //default data
  let keyName = nanoid(10);
  let languageKey = fieldData.languageKey + '_option_' + keyName;
  const defaultOption = {
    id: generateUUID(),
    keyName: keyName,
    language: [],
    dataType: 'option',
    languageKey: languageKey,
    default: true,
  };
  const defaultShow = {
    showInList: true,
    showInView: false,
    showInWrite: false,
    defaultViewInList: false,
    isRequired: false,
    employeeShow: false,
    allowDuplicate: false,
    tooltip: true,
    tooltipMsg: '',
    tooltipType: TOOLTIP_TYPE_ICON,
  };
  const { t } = useTranslation();
  //state

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [fieldName, setFieldName] = useState<any[]>([]); //[{id: "gb", value: "aa"}]
  const [charLength, setCharLength] = useState('');
  const [numberDigits, setNumberDigits] = useState('');
  const [numberDecimal, setNumberDecimal] = useState('');
  const [multiple, setMultiple] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState(CUSTOM_LAYOUT_TYPES[0]);
  const [options, setOptions] = useState<any>([]); //for checkbox, radio, select
  const [showCheck, setShowCheck] = useState(defaultShow);
  const [selectedDataSource, setSelectedDataSource] = useState<any>(null);
  //drag
  const [draggingRowId, setDraggingRowId] = useState('');
  //error
  const [nameError, setNameError] = useState(false);
  const [optionError, setOptionError] = useState(false);
  //event
  const [showTooltipPreview, setShowTooltipPreview] = useState(false);
  const mUpdateTplField: any = useMutationPost<IField>(
    UPDATE_PAGE_LAYOUT_TPL_FIELD,
    'setting_updateTemplateField',
  );
  const mUpdateField: any = useMutationPost<IField>(
    UPDATE_PAGE_LAYOUT_FIELD,
    'setting_updateField',
    {
      onError: (res: any) => {
        setIsSaving(false);
      },
    },
  );
  const mUpdateSysLanguage: any = useMutationPost<ILanguageData>(
    UPDATE_SYSTEM_LANGUAGE,
    'setting_updateSystemLanguage',
  );
  const onClose = () => {
    // reset data
    setFieldData({ id: '' });
    setIsOpen(false);
  };
  //init data by state

  useEffect(() => {
    // console.log('fieldData', fieldData);
    //set data from item field
    if (fieldData) {
      //field data type
      setSelectedType(fieldData.dataType ?? '');
      //set showCheck (L, V, W, ...)
      let newShowCheck = { ...showCheck };
      //must have
      newShowCheck.showInList = !!fieldData.showInList;
      newShowCheck.showInWrite = !!fieldData.showInWrite;
      newShowCheck.showInView = !!fieldData.showInView;
      newShowCheck.defaultViewInList = !!fieldData.defaultViewInList;
      //set name by lang - languages value
      let newLangName: any[] = [];
      if (fieldData.language) {
        Object.keys(fieldData.language).map((key) => {
          let objKey = key as keyof ILanguageData;
          let newValue = {
            id: key,
            value:
              fieldData.language && fieldData.language[objKey] ? fieldData.language[objKey] : '',
          };
          newLangName.push(newValue);
        });
      }

      setFieldName(newLangName);
      //atributes - optional
      fieldData?.attributes?.map((item: any) => {
        switch (item.keyName) {
          case DATA_TYPE_ATTR_MAXLENGTH:
            setCharLength(item.value);
            break;
          case DATA_TYPE_ATTR_MULTIPLE:
            setMultiple(item.value == '1' ? true : false);
            break;
          case DATA_TYPE_ATTR_NUMBER_DIGITS:
            setNumberDigits(item.value);
            break;
          case DATA_TYPE_ATTR_NUMBER_DECIMAL:
            setNumberDecimal(item.value);
            break;
          case DATA_TYPE_ATTR_REQUIRED:
            newShowCheck.isRequired = item.value == '1' ? true : false;
            break;
          case DATA_TYPE_ATTR_EMPLOYEE_SHOW:
            newShowCheck.employeeShow = item.value == '1' ? true : false;
            break;
          case DATA_TYPE_ATTR_DUPLICATE:
            newShowCheck.allowDuplicate = item.value == '1' ? true : false;
            break;
          case DATA_TYPE_ATTR_LAYOUT: {
            const initialLayout =
              CUSTOM_LAYOUT_TYPES[CUSTOM_LAYOUT_TYPES.findIndex((el) => el.value === item.value)];
            setSelectedLayout(initialLayout);
            break;
          }
          case DATA_TYPE_ATTR_TOOLTIP_SHOW:
            newShowCheck.tooltip = item.value == '1' ? true : false;
            break;
          case DATA_TYPE_ATTR_TOOLTIP_MSG:
            newShowCheck.tooltipMsg = item.value;
            break;
          case DATA_TYPE_ATTR_TOOLTIP_TYPE:
            newShowCheck.tooltipType = item.value;
            break;
          case DATA_TYPE_ATTR_LOOKUP_DATA_SOURCE: {
            const initialSource =
              lookupDataSources[lookupDataSources.findIndex((el: any) => el.key === item.value)];
            setSelectedDataSource(initialSource);
            break;
          }
        }
      });
      setShowCheck(newShowCheck);
      //options
      if (
        fieldData.dataType === BASIC_DATA_TYPE_MULTI_CHOICES ||
        fieldData.dataType === BASIC_DATA_TYPE_SINGLE_CHOICE ||
        fieldData.dataType === BASIC_DATA_TYPE_SELECT_BOX
      ) {
        if (fieldData?.options && fieldData?.options.length > 0) {
          //convert options
          const newOptions: any = [];
          fieldData?.options.map((opt: any) => {
            const newOpt = { ...opt };
            let newLangValue: any = [];
            if (opt.language) {
              Object.keys(opt.language).map((key) => {
                let newValue = {
                  id: key,
                  value: opt.language[key],
                };
                newLangValue.push(newValue);
              });
            }

            newOpt.language = newLangValue;
            //default
            opt?.attributes?.map((attr: any) => {
              if (attr.keyName === 'is_default') {
                newOpt.default = attr.value == '1' ? true : false;
              }
            });
            //push to array
            newOptions.push(newOpt);
          });
          // console.log('initOptions', newOptions);
          setOptions(newOptions);
        } else {
          setOptions([defaultOption]);
        }
      }
    }
  }, [fieldData]);
  useEffect(() => {
    if (initFieldData) {
      // reset savedFieldData
      savedFieldData = { id: '' };
      setFieldData(initFieldData);
    }
  }, [initFieldData]);
  //check show or not show attributes of field
  const isAttributeBelongTo = (attrKeyName: string) => {
    //For only on State
    return fieldData?.attributes &&
      fieldData.attributes.findIndex((el: any) => el.keyName === attrKeyName) > -1
      ? true
      : false;
  };

  //validate before Save
  const validProps = () => {
    //must have at least one name
    let errName = true;
    fieldName.map((el) => {
      if (el.value.length > 0) errName = false;
    });
    setNameError(errName);

    //all option name must be filled
    let errOption = false;
    if (
      selectedType === BASIC_DATA_TYPE_MULTI_CHOICES ||
      selectedType === BASIC_DATA_TYPE_SINGLE_CHOICE ||
      selectedType === BASIC_DATA_TYPE_SELECT_BOX
    ) {
      options.map((op: any) => {
        if (op.language.length === 0 || op.language[0]?.value.length === 0) errOption = true;
      });
      setOptionError(errOption);
    }

    //true if all errors are false
    return !errName && !errOption;
  };

  //handle update state DONE - - NOT USE
  const handleDone = () => {
    //set return data
    let newItem = { ...fieldData };
    newItem.defaultViewInList = showCheck.defaultViewInList;
    newItem.isRequired = showCheck.isRequired;
    newItem.showInList = showCheck.showInList;
    newItem.showInView = showCheck.showInView;
    newItem.showInWrite = showCheck.showInWrite;
    //language values
    let nLanguage: ILanguageData = newItem.language
      ? newItem.language
      : {
          en: '',
          vi: '',
          ko: '',
          jp: '',
          zh: '',
          ido: '',
        };
    fieldName.map((item) => {
      nLanguage[item.id as keyof ILanguageData] = item.value;
    });
    newItem.language = nLanguage;
    //attributes data
    newItem?.attributes?.map((item: any) => {
      switch (item.keyName) {
        case DATA_TYPE_ATTR_MAXLENGTH:
          item.value = charLength;
          break;
        case DATA_TYPE_ATTR_NUMBER_DIGITS:
          item.value = numberDigits;
          break;
        case DATA_TYPE_ATTR_NUMBER_DECIMAL:
          item.value = numberDecimal;
          break;
        case DATA_TYPE_ATTR_MULTIPLE:
          item.value = multiple ? '1' : '0';
          break;
        case DATA_TYPE_ATTR_REQUIRED:
          item.value = showCheck.isRequired ? '1' : '0';
          break;
        case DATA_TYPE_ATTR_EMPLOYEE_SHOW:
          item.value = showCheck.employeeShow ? '1' : '0';
          break;
        case DATA_TYPE_ATTR_DUPLICATE:
          item.value = showCheck.allowDuplicate ? '1' : '0';
          break;
        case DATA_TYPE_ATTR_LAYOUT:
          item.value = selectedLayout.value;
          break;
        case DATA_TYPE_ATTR_TOOLTIP_SHOW:
          item.value = showCheck.tooltip ? '1' : '0';
          break;
        case DATA_TYPE_ATTR_TOOLTIP_MSG:
          item.value = showCheck.tooltipMsg;
          break;
        case DATA_TYPE_ATTR_TOOLTIP_TYPE:
          item.value = showCheck.tooltipType;
          break;
        case DATA_TYPE_ATTR_LOOKUP_DATA_SOURCE:
          item.value = selectedDataSource.key;
          break;
      }
    });
    //options
    if (
      selectedType === BASIC_DATA_TYPE_MULTI_CHOICES ||
      selectedType === BASIC_DATA_TYPE_SINGLE_CHOICE ||
      selectedType === BASIC_DATA_TYPE_SELECT_BOX
    ) {
      //set new options
      let newOptions: any = [];
      options.map((item: any) => {
        if (item?.language?.length > 0) {
          let newItem: any = {
            id: item.id,
            languageKey: item.languageKey,
            keyName: item.keyName,
            dataType: 'option',
            language: {},
            attributes: [],
          };
          item.language.map((ele: any) => {
            newItem.language[ele.id] = ele.value;
          });
          let newAttr = {
            keyName: 'is_default',
            value: item.default ? '1' : '0',
            languageKey: '',
          };
          newItem.attributes.push(newAttr);
          newOptions.push(newItem);
        }
      });
      newItem.options = newOptions;
    }
    // console.log('fieldDataSave', newItem);
    savedFieldData = newItem;
    setIsSaving(true);
    // call update
    let pgField: IPgField = initPgFieldUpdateData(newItem);
    mUpdateField.mutate(
      {
        pgField: pgField,
      },
      {
        onSuccess: (res: any) => {
          // set load
          let tplField: ITemplateField = initTplFieldUpdateData(newItem);
          mUpdateTplField.mutate(
            {
              id: pgField.id,
              tplField: tplField,
            },
            {
              onSuccess: (res: any) => {
                // update language
                let newSysLang: ILanguageData = {
                  langKey: pgField.languageKey,
                  menu: 'pagelayout',
                  en: pgField.language ? pgField.language.en : '',
                  vi: pgField.language ? pgField.language.vi : '',
                  ko: pgField.language ? pgField.language.ko : '',
                  jp: pgField.language ? pgField.language.jp : '',
                  zh: pgField.language ? pgField.language.zh : '',
                  ido: pgField.language ? pgField.language.ido : '',
                };
                mUpdateSysLanguage.mutate({ systemLanguage: newSysLang });
                //update Option Language
                handleUpdateOptionsLangs(pgField.options);
                if (savedFieldData.id != '') {
                  onSavedFieldData(savedFieldData);
                }
                // savedFieldData = { id: '' };
                // set load
                toast.success('Field updated!');
                setIsSaving(false);
                // close write form
                onClose();
              },
            },
          );
        },
      },
    );
  };

  //handle set field options
  const setFieldOptions = (options: any) => {
    //set to edit field
    let newItem = { ...fieldData };
    //set new options
    let newOptions: any = [];
    options.map((_opt: any) => {
      //if(item?.language?.length > 0) {
      let newOpt = {
        ..._opt,
        language: {},
        attributes: [],
      };
      _opt?.language?.map((ele: any) => {
        newOpt.language[ele.id] = ele.value;
      });
      let newAttr = {
        keyName: 'is_default',
        value: _opt.default ? '1' : '0',
        languageKey: '',
      };
      newOpt.attributes.push(newAttr);
      newOptions.push(newOpt);
      //}
    });
    newItem.options = newOptions;
    //set state
    setFieldData(newItem);
  };

  //handle set field attributes data
  const setFieldAttributes = (attrData: any) => {
    //set to edit field
    let newItem = { ...fieldData };
    //L-W-V-D
    newItem.defaultViewInList = attrData?.defaultViewInList;
    newItem.showInList = attrData?.showInList;
    newItem.showInView = attrData?.showInView;
    newItem.showInWrite = attrData?.showInWrite;
    //attributes data
    newItem?.attributes?.map((item: any) => {
      switch (item.keyName) {
        case DATA_TYPE_ATTR_REQUIRED:
          item.value = attrData.required ? '1' : '0';
          newItem.isRequired = attrData.required;
          break;
        case DATA_TYPE_ATTR_EMPLOYEE_SHOW:
          item.value = attrData.employeeShow ? '1' : '0';
          break;
        case DATA_TYPE_ATTR_DUPLICATE:
          item.value = attrData.allowDuplicate ? '1' : '0';
          break;
        case DATA_TYPE_ATTR_TOOLTIP_SHOW:
          item.value = attrData.tooltip ? '1' : '0';
          break;
        case DATA_TYPE_ATTR_TOOLTIP_MSG:
          item.value = attrData.tooltipMsg;
          break;
        case DATA_TYPE_ATTR_TOOLTIP_TYPE:
          item.value = attrData.tooltipType;
          break;
      }
    });
    //set state
    setFieldData(newItem);
  };

  //set field main attributes
  const setFieldProperties = (propData: any, propKey: string) => {
    //set to edit field
    let newItem = { ...fieldData };
    //attributes data
    newItem?.attributes?.map((item: any) => {
      if (item.keyName == propKey) {
        switch (item.keyName) {
          case DATA_TYPE_ATTR_MAXLENGTH:
            item.value = propData; //charLength
            break;
          case DATA_TYPE_ATTR_NUMBER_DIGITS:
            item.value = propData; //numberDigits;
            break;
          case DATA_TYPE_ATTR_NUMBER_DECIMAL:
            item.value = propData; //numberDecimal;
            break;
          case DATA_TYPE_ATTR_MULTIPLE:
            item.value = propData ? '1' : '0'; //multiple
            break;
          case DATA_TYPE_ATTR_LAYOUT:
            item.value = propData.value; //selectedLayout
            break;
          case DATA_TYPE_ATTR_LOOKUP_DATA_SOURCE:
            item.value = propData.key; //selectedDataSource
            break;
        }
      }
    });
    //set state
    setFieldData(newItem);
  };

  //save item properties - NOT USE
  const handleSave = () => {
    //validate
    if (!validProps()) return;
    //Update state ONLY
    handleDone();
  };

  //handle field name change
  const handleFieldNameChange = (data: any) => {
    //set current state
    setFieldName(data);
    //set to edit field
    // let newItem = { ...fieldData };
    // //language values
    // let nLanguage: ILanguageData = newItem.language
    //   ? newItem.language
    //   : {
    //       en: '',
    //       vi: '',
    //       ko: '',
    //       jp: '',
    //       zh: '',
    //       ido: '',
    //     };
    // data.map((item: any) => {
    //   nLanguage[item.id as keyof ILanguageData] = item.value;
    // });
    // newItem.language = nLanguage;
    // setFieldData(newItem);
  };

  //add normal option
  const addOption = () => {
    let newOptions = [...options];
    let keyName = nanoid(10);
    let languageKey = fieldData.languageKey + '_option_' + keyName;
    const newOpt = {
      id: generateUUID(),
      keyName: keyName,
      language: [],
      languageKey: languageKey,
      dataType: 'option',
      default: false,
    };
    newOptions.push(newOpt);
    //set local state
    setOptions(newOptions);

    //set to edit field
    setFieldOptions(newOptions);
  };

  //remove an option
  const removeOption = (idx: any) => {
    let newOptions = [...options];
    newOptions.splice(idx, 1);
    //set local state
    setOptions(newOptions);

    //set to edit field
    setFieldOptions(newOptions);
  };

  //option value change - NOT USE
  const handleOptionValueChange = (data: any, idx: number) => {
    let newOptions = [...options];
    newOptions[idx].language = data;
    setOptions(newOptions);
  };
  const handleUpdateOptionsLangs = (options: any[] | undefined) => {
    if (options && options.length > 0) {
      options.map((opt: any) => {
        let newSysLang: ILanguageData = {
          langKey: opt.languageKey,
          menu: 'pagelayout',
          en: opt.language ? opt.language.en : '',
          vi: opt.language ? opt.language.en : '',
          ko: opt.language ? opt.language.en : '',
          jp: opt.language ? opt.language.en : '',
          zh: opt.language ? opt.language.en : '',
          ido: opt.language ? opt.language.en : '',
        };
        mUpdateSysLanguage.mutate({ systemLanguage: newSysLang });
      });
    }
  };
  //option text changes - add default to 'en'
  const handleOptionTextChange = (e: any, idx: number) => {
    //set local state
    let newOptions = [...options];
    newOptions[idx].language = [{ id: 'en', value: e.target.value }];
    setOptions(newOptions);

    //set to edit field
    setFieldOptions(newOptions);
  };

  //keyPress Enter to create new option
  const handleOptionKeyPress = (e: any, idx: number) => {
    if (e.key === 'Enter') {
      if (
        options[idx].language &&
        options[idx].language.length > 0 &&
        options[idx].language[0].value.length > 0
      ) {
        let newOptions = [...options];
        let keyName = nanoid(10);
        let languageKey = fieldData.languageKey + '_option_' + keyName;
        const newOpt = {
          id: generateUUID(),
          language: [],
          languageKey: languageKey,
          default: false,
          dataType: 'option',
          keyName: keyName,
        };
        newOptions.push(newOpt);
        setOptions(newOptions);

        //set to edit field
        setFieldOptions(newOptions);
      }
    }
  };

  //option default change
  const handleOptionDefaultChange = (e: any, idx: number) => {
    let newOptions = [...options];
    //reset to false
    if (selectedType === BASIC_DATA_TYPE_SINGLE_CHOICE) {
      newOptions.map((opt) => (opt.default = false));
    }
    //set current
    newOptions[idx].default = e.target.checked;
    setOptions(newOptions);

    //set to edit field
    setFieldOptions(newOptions);
  };

  //L, V, W show
  const handleShowCheck = (e: any, type: any) => {
    //set local state
    let newShowCheck: any = { ...showCheck };
    if (type === 'tooltipMsg') {
      newShowCheck[type] = e.target.value;
    } else if (type === 'tooltipType') {
      newShowCheck[type] = e; //value
    } else if (type === 'allowDuplicate') {
      newShowCheck[type] = !e.target.checked;
    } else {
      newShowCheck[type] = e.target.checked;
      //set show list/view to false if show write is false
      if (type === 'showInWrite' && e.target.checked === false) {
        newShowCheck.showInList = false;
        newShowCheck.defaultViewInList = false;
        newShowCheck.showInView = false;
      }
      if (type === 'showInList' && e.target.checked === false) {
        newShowCheck.defaultViewInList = false;
      }
      //reset tooltip value
      if (type === 'tooltip' && e.target.checked === false) {
        newShowCheck.tooltipMsg = '';
        newShowCheck.tooltipType = '';
      } else {
        newShowCheck.tooltipType = TOOLTIP_TYPE_ICON;
      }
      //reset default view in list if overpass MAX 7
      if (
        type === 'defaultViewInList' &&
        e.target.checked === true &&
        fieldData.defaultViewInList === false &&
        initTotalDefaultViewInList + 1 > LIMIT_DEFAULT_VIEW_IN_LIST
      ) {
        newShowCheck[type] = false;
        toast.warning(t('crm_new_pagelayout_it_was_more_than_limitation') as any);
      }
    }
    setShowCheck(newShowCheck);

    //set field state
    setFieldAttributes(newShowCheck);
  };

  //change lookup data source
  const handleChangeDataSource = (item: any) => {
    if (item) {
      setSelectedDataSource(item);
      //set field state
      setFieldProperties(item, DATA_TYPE_ATTR_LOOKUP_DATA_SOURCE);
    }
  };

  //change style when dragging
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    let itemStyle = {
      //background: isDragging && ("lightblue"),
      ...draggableStyle,
      left: 50,
    };
    if (draggableStyle.top) {
      itemStyle.top = draggableStyle.top - 50;
    }
    return itemStyle;
  };

  //drag start event
  const handleOnDragStart = (start: any) => {
    setDraggingRowId(start.draggableId);
  };

  //drag end event
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const newOptions = [...options];
    const [reorderedOption] = newOptions.splice(result.source.index, 1);
    newOptions.splice(result.destination.index, 0, reorderedOption);
    //set new order
    setOptions(newOptions);
    setDraggingRowId('');
  };

  //show icon
  const renderTypeIcon = () => {
    return (
      <>
        {selectedType === BASIC_DATA_TYPE_MULTI_CHOICES && (
          <CheckSquare className="wd-20 tx-gray-300 mg-r-5" />
        )}
        {selectedType === BASIC_DATA_TYPE_SINGLE_CHOICE && (
          <CheckCircle className="wd-20 tx-gray-300 mg-r-5" />
        )}
        {selectedType === BASIC_DATA_TYPE_SELECT_BOX && (
          <AlignJustify className="wd-20 tx-gray-300 mg-r-5" />
        )}
      </>
    );
  };

  //field type
  const renderFieldType = () => {
    //const idx = fieldTypes?.findIndex(el => el?.dataType === layoutField?.data_type);
    // let objKey = currentLang as keyof ILanguageData;
    let fType = fieldData.dataTypeLangKey ?? fieldData.dataType ?? ''; //fieldData?.language && fieldData.language[objKey] ? fieldData.language[objKey] : '';
    return (
      <div className="form-group">
        <label className="tx-color-03">Field Type</label>
        <div className="pd-l-10">
          {isLoading && <LineLoader />}
          {!isLoading && <span>{fType}</span>}
        </div>
      </div>
    );
  };

  //field name
  const renderFieldName = () => {
    return (
      <div className="form-group">
        <label className="tx-color-03" htmlFor="layoutFieldName">
          Field Name
          {/* <span className="tx-danger">*</span> */}
        </label>
        {/* lang */}
        {isLoading && <LineLoader />}
        {!isLoading && (
          <LangInput
            uid={'name'} //DOM unique key in one page
            disabled={!fieldData?.permission?.canChangeFieldName}
            value={fieldName} //ex: [{id, value}]
            onChange={handleFieldNameChange}
          />
        )}
        {nameError && <p className="tx-danger">Field name is required.</p>}
      </div>
    );
  };

  //field max length
  const renderFieldMaxLength = () => {
    return (
      <div className="form-group">
        <label className="tx-color-03">Number of characters allowed</label>
        {isLoading && <LineLoader />}
        {!isLoading && (
          <input
            type="number"
            className="form-control"
            value={charLength}
            onChange={(e) => {
              setCharLength(e.target.value);
              setFieldProperties(e.target.value, DATA_TYPE_ATTR_MAXLENGTH);
            }}
          />
        )}
      </div>
    );
  };

  //field number - number of digits
  const renderFieldNumberDigits = () => {
    return (
      <div className="form-group">
        <label className="tx-color-03">Number of digits allowed</label>
        {isLoading && <LineLoader />}
        {!isLoading && (
          <input
            type="number"
            name="xxxxxxx"
            className="form-control"
            value={numberDigits}
            onChange={(e) => {
              setNumberDigits(e.target.value);
              setFieldProperties(e.target.value, DATA_TYPE_ATTR_NUMBER_DIGITS);
            }}
          />
        )}
      </div>
    );
  };

  //field number - number of digits
  const renderFieldNumberDecimal = () => {
    return (
      <div className="form-group">
        <label className="tx-color-03">Number of decimal allowed</label>
        {isLoading && <LineLoader />}
        {!isLoading && (
          <input
            type="number"
            className="form-control"
            value={numberDecimal}
            onChange={(e) => {
              setNumberDecimal(e.target.value);
              setFieldProperties(e.target.value, DATA_TYPE_ATTR_NUMBER_DECIMAL);
            }}
          />
        )}
      </div>
    );
  };

  //field multiple option
  const renderFieldMultiple = () => {
    return (
      <div className="form-group">
        <div className="d-flex">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="layoutMultipleSwitch"
              checked={!!multiple}
              onChange={(e) => {
                setMultiple(e.target.checked);
                setFieldProperties(e.target.checked, DATA_TYPE_ATTR_MULTIPLE);
              }}
            />
            <label className="custom-control-label" htmlFor="layoutMultipleSwitch"></label>
          </div>
          <label className="tx-color-03">Multiple</label>
        </div>
      </div>
    );
  };

  //lookup data source
  const renderLookupDataSource = () => {
    return (
      <div className="form-group">
        <label className="tx-color-03">Lookup data source</label>
        {isLoading && <LineLoader />}
        {!isLoading && (
          <SelectHook
            isClearable
            outSide
            //defaultValue={selectedDataSource}
            value={selectedDataSource}
            options={lookupDataSources}
            onChange={handleChangeDataSource}
          />
        )}
      </div>
    );
  };

  //field show options
  const renderFieldShowIn = () => {
    return (
      <div className="form-group">
        <label className="d-block tx-color-03">Show in</label>
        <div className="pd-t-8">{isLoading && <LineLoader />}</div>
        {!isLoading && (
          <>
            <div className="custom-control custom-checkbox d-inline-block mg-r-10">
              <input
                type="checkbox"
                className="custom-control-input"
                id="layoutChkList"
                disabled={!fieldData?.permission?.canSetShowInList}
                checked={!!showCheck.showInList}
                onChange={(e) => handleShowCheck(e, 'showInList')}
              />
              <label className="custom-control-label" htmlFor="layoutChkList">
                List
              </label>
            </div>
            <div className="custom-control custom-checkbox d-inline-block mg-r-10">
              <input
                type="checkbox"
                className="custom-control-input"
                id="layoutChkView"
                disabled={!fieldData?.permission?.canSetShowInView}
                checked={!!showCheck.showInView}
                onChange={(e) => handleShowCheck(e, 'showInView')}
              />
              <label className="custom-control-label" htmlFor="layoutChkView">
                View
              </label>
            </div>
            <div className="custom-control custom-checkbox d-inline-block mg-r-10">
              <input
                type="checkbox"
                className="custom-control-input"
                id="layoutChkWrite"
                disabled={!fieldData?.permission?.canSetShowInWrite}
                checked={!!showCheck.showInWrite}
                onChange={(e) => handleShowCheck(e, 'showInWrite')}
              />
              <label className="custom-control-label" htmlFor="layoutChkWrite">
                Write
              </label>
            </div>
          </>
        )}
      </div>
    );
  };

  //field check options
  const renderFieldShowCheck = () => {
    return (
      <>
        {/* show when user click 'show in list' */}
        {showCheck.showInList && (
          <div className="custom-control custom-switch mg-b-10">
            <input
              type="checkbox"
              className="custom-control-input"
              id="layoutListDefaultCheck"
              checked={!!showCheck.defaultViewInList}
              onChange={(e) => handleShowCheck(e, 'defaultViewInList')}
            />
            <label className="custom-control-label" htmlFor="layoutListDefaultCheck">
              List Default View
            </label>
          </div>
        )}
        {/* required */}
        {isAttributeBelongTo(DATA_TYPE_ATTR_REQUIRED) && (
          <div className="custom-control custom-switch mg-b-10">
            <input
              type="checkbox"
              className="custom-control-input"
              id="layoutRequiredCheck"
              disabled={!fieldData?.permission?.canMarkRequired}
              checked={!!showCheck.isRequired}
              onChange={(e) => handleShowCheck(e, 'isRequired')}
            />
            <label className="custom-control-label" htmlFor="layoutRequiredCheck">
              Required
            </label>
          </div>
        )}
        {/* employeeShow */}
        {isAttributeBelongTo(DATA_TYPE_ATTR_EMPLOYEE_SHOW) && (
          <div className="custom-control custom-switch mg-b-10">
            <input
              type="checkbox"
              className="custom-control-input"
              id="layoutEmployeeShowCheck"
              checked={!!showCheck.employeeShow}
              onChange={(e) => handleShowCheck(e, 'employeeShow')}
            />
            <label className="custom-control-label" htmlFor="layoutEmployeeShowCheck">
              Show in Employee Page
            </label>
          </div>
        )}
        {/* duplicate */}
        {isAttributeBelongTo(DATA_TYPE_ATTR_DUPLICATE) && (
          <div className="custom-control custom-switch mg-b-10">
            <input
              type="checkbox"
              className="custom-control-input"
              id="layoutDupValueCheck"
              checked={!showCheck.allowDuplicate}
              onChange={(e) => handleShowCheck(e, 'allowDuplicate')}
            />
            <label className="custom-control-label" htmlFor="layoutDupValueCheck">
              Do not allow duplicate values
            </label>
          </div>
        )}
        {/* tooltip */}
        {isAttributeBelongTo(DATA_TYPE_ATTR_TOOLTIP_SHOW) && (
          <div className="custom-control custom-switch mg-b-10">
            <input
              type="checkbox"
              className="custom-control-input"
              id="layoutDupTooltipCheck"
              checked={!!showCheck.tooltip}
              onChange={(e) => handleShowCheck(e, 'tooltip')}
            />
            <label className="custom-control-label" htmlFor="layoutDupTooltipCheck">
              Show tooltip
            </label>
          </div>
        )}
      </>
    );
  };

  //field multiple option
  const renderFieldTooltip = () => {
    return (
      <>
        <div className="pd-t-8 mg-l-10">{showCheck.tooltip && isLoading && <LineLoader />}</div>
        {showCheck.tooltip && !isLoading && (
          <>
            {/* tooltip msg */}
            <div className="form-group mg-l-10">
              <label className="tx-color-03" htmlFor="tooltipMsg">
                Tooltip message
              </label>
              <input
                type="text"
                className="form-control"
                id="tooltipMsg"
                placeholder="Max of 32 characters"
                maxLength={32}
                value={showCheck.tooltipMsg}
                onChange={(e) => handleShowCheck(e, 'tooltipMsg')}
              />
            </div>
            {/* tooltip type */}
            <div className="form-group mg-l-10">
              <div className="d-flex flex-wrap">
                <label className="tx-color-03">Tooltip Type</label>
                <a
                  // href="javascript:void(0)"
                  className="mg-l-auto cursor-pointer"
                  onClick={() => setShowTooltipPreview((showTooltipPreview) => !showTooltipPreview)}
                >
                  {showTooltipPreview ? 'Hide Preview' : 'Show Preview'}
                </a>
              </div>
              <div className="custom-control custom-radio d-inline-block mg-r-10">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="tooltipTypeRadio"
                  id="tooltipIconTypeRadio"
                  checked={showCheck.tooltipType === TOOLTIP_TYPE_ICON}
                  onChange={(e) => handleShowCheck(TOOLTIP_TYPE_ICON, 'tooltipType')}
                />
                <label className="custom-control-label" htmlFor="tooltipIconTypeRadio">
                  Info Icon
                </label>
              </div>
              {selectedType !== BASIC_DATA_TYPE_SINGLE_CHOICE &&
                selectedType !== BASIC_DATA_TYPE_MULTI_CHOICES &&
                // selectedType !== DATA_TYPE_IP &&
                // selectedType !== DATA_TYPE_INDUSTRY &&
                // selectedType !== DATA_TYPE_PHONE &&
                // selectedType !== DATA_TYPE_SWITCH &&
                // selectedType !== DATA_TYPE_IMG_SINGLE &&
                // selectedType !== DATA_TYPE_IMG_MULTI &&
                selectedType !== BASIC_DATA_TYPE_SELECT_BOX && (
                  <div className="custom-control custom-radio d-inline-block">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="tooltipTypeRadio"
                      id="tooltipTextTypeRadio"
                      checked={showCheck.tooltipType === TOOLTIP_TYPE_TEXT}
                      onChange={(e) => handleShowCheck(TOOLTIP_TYPE_TEXT, 'tooltipType')}
                    />
                    <label className="custom-control-label" htmlFor="tooltipTextTypeRadio">
                      Static Text
                    </label>
                  </div>
                )}
            </div>
            {/* tooltip preview */}
            {showTooltipPreview && (
              <div className="form-group mg-l-10">
                <div className="d-flex align-items-center justify-content-center">
                  {showCheck.tooltipType === TOOLTIP_TYPE_ICON ? (
                    <>
                      <span className="tx-color-03 mg-r-5">Name</span>
                      <Tooltip
                        overlay={showCheck.tooltipMsg || 'Tooltip message'}
                        placement="top"
                        outSide
                      >
                        <Info className="wd-20 mg-r-5" style={{ fill: 'lightgrey' }} />
                      </Tooltip>
                      <input type="text" disabled className="form-control" />
                    </>
                  ) : (
                    <>
                      <span className="tx-color-03 mg-r-5">Name</span>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        placeholder="Tooltip message"
                        value={showCheck.tooltipMsg}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  //render option detail
  const renderOption = () => {
    return (
      <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
        <div className="table-responsive rounded bd">
          <Droppable droppableId={generateUUID()} type="options">
            {(provided, snapshot) => (
              <table
                className="table mg-0 bd-t-0 layout-option-list"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <thead>
                  <tr>
                    <th scope="col">Option Name</th>
                    <th scope="col" className="wd-70">
                      Default
                    </th>
                    {options.length > 1 && <th scope="col" className="wd-50"></th>}
                  </tr>
                </thead>

                <tbody>
                  <>
                    {options.map((opt: any, idx: number) => {
                      // console.log('Option', opt);
                      let optValue = '';
                      if (opt.language && opt.language.length > 0) {
                        optValue = opt.language[0].value;
                      }
                      //render
                      return (
                        <Draggable key={opt.id} draggableId={opt.id} index={idx}>
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                              className={classNames('', { 'bg-info': snapshot.isDragging })}
                            >
                              <td className="w-100">
                                <div className="d-flex align-items-center">
                                  <Move className="wd-15 mg-r-10 tx-gray-300" />
                                  {renderTypeIcon()}
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={'Option ' + (idx + 1)}
                                    autoFocus={idx === options.length - 1}
                                    value={optValue}
                                    onChange={(e) => handleOptionTextChange(e, idx)}
                                    onKeyPress={(e) => handleOptionKeyPress(e, idx)}
                                  />
                                </div>
                              </td>
                              <td className="align-middle text-center">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={'layoutChkDefault' + idx}
                                    checked={!!opt.default}
                                    onChange={(e) => handleOptionDefaultChange(e, idx)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={'layoutChkDefault' + idx}
                                  ></label>
                                </div>
                              </td>
                              {options.length > 1 && (
                                <td className="align-middle text-center">
                                  <button
                                    type="button"
                                    className="btn btn-link btn-icon link-03 mg-l-auto"
                                    onClick={() => removeOption(idx)}
                                  >
                                    <Trash />
                                  </button>
                                </td>
                              )}
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                    {draggingRowId.length === 0 && (
                      <tr>
                        <td colSpan={options.length > 1 ? 3 : 2} className="pd-5-f text-center">
                          <button type="button" className="btn btn-xs btn-link" onClick={addOption}>
                            <Plus /> Add Option {renderTypeIcon()}
                          </button>
                        </td>
                      </tr>
                    )}
                  </>
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    );
  };

  //render field options
  const renderFieldOption = () => {
    return (
      <>
        {/* options */}
        <div className="form-group">
          <label className="tx-color-03">Options</label>
          {/* option items */}
          {renderOption()}
          {optionError && <p className="tx-danger">All options&apos; name are required.</p>}
        </div>
        {/* layout */}
        <div className="form-group">
          <label className="tx-color-03">Layout</label>
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-block bd d-flex align-items-center"
              data-toggle="dropdown"
            >
              {selectedLayout ? <FormIcon icon={selectedLayout.icon} iconType="feather" /> : ''}
              <span className="text-truncate mg-l-5">
                {selectedLayout?.label ? selectedLayout?.label : 'Select...'}
              </span>
              <span className="d-inline-block mg-l-auto pd-l-10 bd-l">
                <ChevronDown className="tx-gray-400" />
              </span>
            </button>
            <div
              className="dropdown-menu wd-100p"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.176) 0px 6px 12px' }}
            >
              {CUSTOM_LAYOUT_TYPES.map((type, index) => (
                <a
                  key={index}
                  className="dropdown-item pd-x-10 cursor-pointer"
                  onClick={() => {
                    setSelectedLayout(type);
                    setFieldProperties(type, DATA_TYPE_ATTR_LAYOUT);
                  }}
                >
                  <FormIcon icon={type.icon} iconType="feather" /> {type.label}
                </a>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  //// console.log('options', options);

  //main render
  return (
    <Canvas
      isVisible={isOpen}
      onCloseSideBar={() => onClose()}
      className={'page-sidebar-container-wrap'}
      width="auto"
    >
      <Canvas.Header title={<SpanLang keyLang="crm_new_pagelayout_edit_property" />} />
      <Canvas.Body style={{ height: 'calc(100vh - 130px)' }}>
        {/* <WriteCustomFieldForm fieldData={itemEdit} setFieldData={setItemEdit} /> */}
        <>
          {/* field type name */}
          {renderFieldType()}
          {/* field name */}
          {renderFieldName()}
          {/* length */}
          {isAttributeBelongTo(DATA_TYPE_ATTR_MAXLENGTH) && renderFieldMaxLength()}
          {/* number digits */}
          {isAttributeBelongTo(DATA_TYPE_ATTR_NUMBER_DIGITS) && renderFieldNumberDigits()}
          {/* number decimal */}
          {isAttributeBelongTo(DATA_TYPE_ATTR_NUMBER_DECIMAL) && renderFieldNumberDecimal()}
          {/* Multiple */}
          {isAttributeBelongTo(DATA_TYPE_ATTR_MULTIPLE) && renderFieldMultiple()}
          {/* data source */}
          {isAttributeBelongTo(DATA_TYPE_ATTR_LOOKUP_DATA_SOURCE) && renderLookupDataSource()}
          {/* field options */}
          {(selectedType === BASIC_DATA_TYPE_MULTI_CHOICES ||
            selectedType === BASIC_DATA_TYPE_SINGLE_CHOICE ||
            selectedType === BASIC_DATA_TYPE_SELECT_BOX) &&
            renderFieldOption()}
          {/* Show in L, V, W */}
          {renderFieldShowIn()}
          {renderFieldShowCheck()}
          {isAttributeBelongTo(DATA_TYPE_ATTR_TOOLTIP_SHOW) && renderFieldTooltip()}
        </>
      </Canvas.Body>
      <Canvas.Footer onClose={() => onClose()} onSave={() => handleSave()} saveLoading={isSaving} />
    </Canvas>
  );
};

export default WriteCustomFieldForm;
