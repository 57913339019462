import React, { useState } from 'react';
import Icons from '@base/assets/icons/svg-icons';
import classNames from 'classnames';
import { X, AlertTriangle, AlertCircle } from 'react-feather';
import {
  Q_MULTI_CHOICES,
  Q_CHECKBOXES,
  Q_DROPDOWN,
  Q_SHORT_ANSWER,
  Q_PARAGRAPH,
  Q_FILE_UPLOAD,
  Q_DATE,
  Q_TIME,
  Q_MULTI_CHOICES_GRID,
  Q_TICK_BOX_GRID,
} from '@settings/online-digital-content/survey/config/constants';
import Loading from '@base/components/loading';
import { SpanLang } from '@base/components';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@base/components/date-picker';

//render question multiple choices
const QuestionView: React.FC<any> = (props) => {
  const {
    keyQ,
    selectedQType,
    title,
    image,
    required,
    requiredInValid,
    options,
    hasAnswer,
    hasGridAnswer,
    onSetRequiredInValidKey,
    onSetOptionsQ,
  } = props;
  const { t } = useTranslation();
  //local state
  const [titleQ, setTitleQ] = useState<string>(title);
  if (selectedQType === Q_DATE) {
    //init answer for date
    if (!options[0].answer) {
      options[0].answer = new Date().toISOString().slice(0, 10); //only ONE option
    }
  }
  const [optionsQ, setOptionsQ] = useState<any>(options);
  const [hasAnswerQ, setHasAnswer] = useState<boolean>(hasAnswer);
  const [hasGridAnswerQ, setHasGridAnswer] = useState<boolean>(hasGridAnswer);
  const initFiles: any = []; //for Q_FILE_UPLOAD
  const [files, setFiles] = useState<any>(initFiles);

  //track files upload - consider: it cause loop
  // useEffect(() => {
  //     if(selectedQType === Q_FILE_UPLOAD) {
  //         //set answer for file option
  //         let newOptions = [...optionsQ];
  //         //set new answer
  //         let newFiles = [];
  //         files.map(file => {
  //             newFiles.push({
  //                 name: file.name,
  //                 url: file.path,
  //                 //size: file.size
  //             });
  //         });
  //         newOptions[0].answer = newFiles;

  //         //set local state
  //         setOptionsQ(newOptions);
  //     }
  // }, [files]);

  //clear answer
  function clearAnswer() {
    //new one
    let newOptions = [...optionsQ];

    //reset answer
    newOptions.map((item) => {
      item.checked = false;
      if (item.isOther) {
        item.answer = '';
      }
    });

    //set local state
    setHasAnswer(false);
    setOptionsQ(newOptions);
  }

  //clear grid answer
  function clearGridAnswer() {
    //new one
    let newOptions = { ...optionsQ };

    //reset answer
    newOptions.answer = {};

    //set local state
    setHasGridAnswer(false);
    setOptionsQ(newOptions);

    //set for parent component
    onSetOptionsQ(keyQ, newOptions);
  }

  //change option radio checked
  function onOptionRadioChange(e: any, idx: number) {
    //new one
    let newOptions = [...optionsQ];

    //reset answer
    newOptions.map((item) => {
      item.checked = false;
      if (item.isOther) {
        item.answer = '';
      }
    });
    //set new answer
    newOptions[idx].checked = e.target.checked;

    //set local state
    setHasAnswer(true);
    setOptionsQ(newOptions);

    //reset required flag
    onSetRequiredInValidKey(-1);
  }

  //change option checkbox checked
  function onOptionCheckboxChange(e: any, idx: number) {
    //new one
    let newOptions = [...optionsQ];

    //set new answer
    newOptions[idx].checked = e.target.checked;

    //reset other answer if un-checked
    if (!newOptions[idx].checked && newOptions[idx].isOther) {
      newOptions[idx].answer = '';
    }

    //set local state
    setOptionsQ(newOptions);
    //check if all checkboxes are unchecked
    setHasAnswer(false); //reset
    newOptions.map((item) => {
      if (item.checked) {
        setHasAnswer(true);
        return;
      }
    });

    //reset required flag
    onSetRequiredInValidKey(-1);
  }

  //change option text
  function onOptionTextChange(e: any, idx: number) {
    //new one
    let newOptions = [...optionsQ];
    //text
    newOptions[idx].answer = e.target.value;
    //set local state
    setOptionsQ(newOptions);
  }

  //dropdown select
  function onOptionSelectChange(idx: number) {
    //new one
    let newOptions = [...optionsQ];

    //reset all selections
    newOptions.map((item) => (item.checked = false));
    //set new selected option
    newOptions[idx].checked = true;

    //set local state
    setOptionsQ(newOptions);
    setHasAnswer(true);

    //reset required flag
    onSetRequiredInValidKey(-1);
  }

  //get selected option - dropdown Q
  function renderSelectedOption() {
    const selections = optionsQ.filter((opt: any) => opt.checked);
    if (selections.length > 0) return selections[0].value;
    return <SpanLang keyLang="Choose" />;
  }

  //get selected option - dropdown Q
  function renderSelectedOptImage() {
    const selections = optionsQ.filter((opt: any, index: number) => opt.checked);
    if (selections.length > 0) {
      return (
        selections[0].image &&
        selections[0].image.url && (
          <div className="survey-write-question-card-image">
            <div className="survey-write-question-card-image-pos">
              <img
                src={selections[0].image.url}
                className="survey-write-question-card-image-view"
                alt="opt_image"
                style={{ width: '620px' }}
              />
            </div>
          </div>
        )
      );
    }

    return '';
  }

  //render title
  function renderTitle() {
    return (
      <div className="survey-write-question-card-title-wrapper">
        <div className="survey-write-question-card-title-row">
          <div className="survey-write-question-card-title-input">
            {/* title */}
            <div className="">
              <span className="survey-write-question-card-title-name">
                {titleQ ? titleQ : <SpanLang keyLang="Untitled Question" />}
                {required && <span className="tx-danger mg-l-2">*</span>}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //render question image
  function renderQImage() {
    return (
      image &&
      image.url && (
        <div className="survey-write-question-card-image">
          <div className="survey-write-question-card-image-pos">
            <img
              src={image.url}
              className="survey-write-question-card-image-view"
              alt="Q_image"
              style={{ width: '680px' }}
            />
          </div>
        </div>
      )
    );
  }

  //drop files for upload
  function onDrop(files: any) {
    setFiles(files);
  }

  //upload new file
  function handleFileUploadChange(e: any) {
    //upload file
    const files = e.target.files;
    if (files.length > 0) {
      //call upload
      let formData = new FormData();
      formData.append('file', files[0]);
      // myAxios.post(apis.uploadImage, formData).then((res) => {
      //     if (res.data.success) {
      //         //update image state
      //         const newFile = {};
      //         newFile.name = res.data.data.name;
      //         newFile.path = apis.getImage + '?path=' + res.data.data.path + res.data.data.name;
      //         //newFile.size = res.data.data.size;

      //         let newOptions = [...optionsQ];
      //         newOptions[0].answer = [newFile];
      //         //set state for survey
      //         onSetOptionsQ(keyQ, newOptions);

      //         // const newFiles=[];
      //         // newFiles.push(newFile);
      //         // setFiles(newFiles);
      //     }
      // })
      // .catch(function (error) {
      //     //// console.log(error);
      // });
    }
  }

  //remove upload file
  function handleRemoveOptionFile() {
    //setFiles([]);

    let newOptions = [...optionsQ];
    newOptions[0].answer = [];
    //set state for survey
    onSetOptionsQ(keyQ, newOptions);
  }

  //set date for Q_DATE
  function onOptionDateChange(date: any, idx: number) {
    //new one
    let newOptions = [...optionsQ];
    //text
    newOptions[idx].answer = new Date(date).toISOString().slice(0, 10);
    //set local state
    setOptionsQ(newOptions);

    //reset required flag
    onSetRequiredInValidKey(-1);
  }

  //change option time hour
  function onOptionTimeChange(e: any, idx: number, type: any) {
    //new one
    let newOptions = [...optionsQ];
    let newAnswer = newOptions[idx].answer ? newOptions[idx].answer.split(':') : ['', ''];
    if (type === 'H') {
      newAnswer[0] = e.target.value; //HH
    } else {
      //M
      newAnswer[1] = e.target.value; //MM
    }
    //text
    newOptions[idx].answer = newAnswer.join(':');
    //set local state
    setOptionsQ(newOptions);

    //reset required flag
    onSetRequiredInValidKey(-1);
  }

  //change option in multichoices grid
  function onOptionGridRadioChange(e: any, rIdx: number, cIdx: number) {
    //new one
    let newOptions = { ...optionsQ };

    //reset answer
    if (!newOptions.answer) {
      newOptions.answer = {}; //reset all
      newOptions.answer[rIdx] = {};
    } else {
      //reset if exists
      newOptions.answer[rIdx] = {};
    }

    //set new answer
    newOptions.answer[rIdx][cIdx] = e.target.checked;

    //set local state
    setHasAnswer(true);
    setOptionsQ(newOptions);

    //set to parent survey
    onSetOptionsQ(keyQ, newOptions);
  }

  //change option in multichoices grid
  function onOptionGridCheckboxChange(e: any, rIdx: number, cIdx: number) {
    //new one
    let newOptions = { ...optionsQ };

    //reset answer
    if (!newOptions.answer) {
      newOptions.answer = {};
      newOptions.answer[rIdx] = {};
    } else if (!newOptions.answer[rIdx]) {
      //reset if not exist
      newOptions.answer[rIdx] = {};
    }

    //set new answer
    newOptions.answer[rIdx][cIdx] = e.target.checked;

    //set local state
    setHasAnswer(true);
    setOptionsQ(newOptions);

    //set to parent survey
    onSetOptionsQ(keyQ, newOptions);
  }

  //render options
  function renderOption() {
    //render based on question type
    switch (selectedQType) {
      case Q_MULTI_CHOICES:
        return optionsQ.map((opt: any, idx: number) => {
          //dynamic option key
          const optionKey = 'option' + (keyQ + 1).toString() + (idx + 1).toString();
          return (
            <div key={idx} className="survey-write-question-option-container">
              <div className="survey-write-question-card-list-item">
                <div className="survey-write-question-card-list-item-inline w-100">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id={optionKey}
                      name={'q-radio' + (keyQ + 1)}
                      className="custom-control-input"
                      checked={opt?.checked || false}
                      onChange={(e) => onOptionRadioChange(e, idx)}
                    />
                    <label className="custom-control-label" htmlFor={optionKey}>
                      {opt?.value ? opt.value : t('Untitled Option') + ' ' + (idx + 1).toString()}
                    </label>
                  </div>
                  {opt.isOther && (
                    <div className="pd-l-10 w-100">
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control survey-write-input"
                        value={opt?.answer}
                        disabled={!opt?.checked}
                        onChange={(e) => onOptionTextChange(e, idx)}
                      />
                    </div>
                  )}
                </div>
              </div>
              {opt?.image?.url && (
                <div className="survey-write-question-card-image">
                  <div className="survey-write-question-card-image-pos">
                    <img
                      src={opt.image.url}
                      className="survey-write-question-card-image-view"
                      alt="opt_image"
                      style={{ width: '620px' }}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        });
      case Q_CHECKBOXES:
        return optionsQ.map((opt: any, idx: number) => {
          //dynamic option key
          const optionKey = 'option' + (keyQ + 1).toString() + (idx + 1).toString();
          return (
            <div key={idx} className="survey-write-question-option-container">
              <div className="survey-write-question-card-list-item">
                <div className="survey-write-question-card-list-item-inline w-100">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id={optionKey}
                      name={'q-checkbox' + (keyQ + 1)}
                      className="custom-control-input"
                      checked={opt?.checked || false}
                      onChange={(e) => onOptionCheckboxChange(e, idx)}
                    />
                    <label className="custom-control-label" htmlFor={optionKey}>
                      {opt?.value ? opt.value : t('Untitled Option') + ' ' + (idx + 1).toString()}
                    </label>
                  </div>
                  {opt?.isOther && (
                    <div className="pd-l-10 w-100">
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control survey-write-input"
                        value={opt?.answer || ''}
                        disabled={!opt?.checked}
                        onChange={(e) => onOptionTextChange(e, idx)}
                      />
                    </div>
                  )}
                </div>
              </div>
              {opt?.image?.url && (
                <div className="survey-write-question-card-image">
                  <div className="survey-write-question-card-image-pos">
                    <img
                      src={opt.image.url}
                      className="survey-write-question-card-image-view"
                      alt="opt_image"
                      style={{ width: '620px' }}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        });
      case Q_DROPDOWN:
        return (
          <div className="survey-write-question-option-container">
            <div className="survey-write-question-card-list-item">
              <div className="survey-write-question-card-list-item-inline w-100">
                <div className="dropdown">
                  <button
                    className="btn btn-white dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {renderSelectedOption()}
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {optionsQ.map((opt: any, idx: number) => (
                      <a
                        key={idx}
                        className="dropdown-item"
                        onClick={() => onOptionSelectChange(idx)}
                      >
                        {opt.value ? opt.value : t('Untitled Option') + ' ' + (idx + 1).toString()}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {renderSelectedOptImage()}
          </div>
        );
      case Q_SHORT_ANSWER:
        return optionsQ.map((opt: any, idx: number) => {
          return (
            <div key={idx} className="survey-write-question-card-list-item">
              <div className="survey-write-question-card-list-item-inline w-100">
                <div className="w-100">
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control survey-write-input"
                    placeholder={t('Your answer')}
                    value={opt.answer}
                    onChange={(e) => onOptionTextChange(e, idx)}
                    onBlur={(e) => onSetRequiredInValidKey(-1)}
                  />
                </div>
              </div>
            </div>
          );
        });
      case Q_PARAGRAPH:
        return optionsQ.map((opt: any, idx: number) => {
          return (
            <div key={idx} className="survey-write-question-card-list-item">
              <div className="survey-write-question-card-list-item-inline w-100">
                <div className="w-100">
                  <textarea
                    className="form-control survey-write-input mg-b-15 mg-r-50 w-100"
                    rows={2}
                    placeholder={t('Your answer')}
                    value={opt.answer}
                    onChange={(e) => onOptionTextChange(e, idx)}
                  />
                </div>
              </div>
            </div>
          );
        });
      case Q_FILE_UPLOAD:
        return (
          <div className="survey-write-question-card-list-item">
            <div className="survey-write-question-card-list-item-inline w-100">
              {
                optionsQ[0].answer && optionsQ[0].answer.length > 0 ? (
                  optionsQ[0].answer.map((file: any, idx: number) => {
                    return (
                      <div key={idx} className="d-flex flex-row align-items-center">
                        <span className="tx-14 mg-r-20">{file.name}</span>
                        <a className="survey-home-header-button" onClick={handleRemoveOptionFile}>
                          <X />
                        </a>
                      </div>
                    );
                  })
                ) : (
                  <div className="custom-file w-50">
                    <input
                      type="file"
                      className="custom-file-input"
                      //id={optionKey}
                      onChange={handleFileUploadChange}
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      <SpanLang keyLang="Choose file" />
                    </label>
                  </div>
                )
                /* <Upload files={files} onDrop={onDrop}/> */
              }
            </div>
          </div>
        );
      case Q_DATE:
        return optionsQ.map((opt: any, idx: number) => {
          //dynamic option key
          const optionKey = 'option' + (keyQ + 1).toString() + (idx + 1).toString();
          const selectedDate = opt.answer ? new Date(opt.answer) : new Date();
          return (
            <div key={idx} className="survey-write-question-card-list-item">
              <div className="survey-write-question-card-list-item-inline w-100">
                <div className="w-50">
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date: any) => {
                      onOptionDateChange(date, idx);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        });
      case Q_TIME:
        return optionsQ.map((opt: any, idx: number) => {
          //dynamic option key
          const optionKey = 'option' + (keyQ + 1).toString() + (idx + 1).toString();
          const selectedTime = opt.answer ? opt.answer.split(':') : ['', ''];
          return (
            <div key={idx} className="survey-write-question-card-list-item">
              <div className="survey-write-question-card-list-item-inline w-100">
                <span className="pd-r-10">
                  <SpanLang keyLang="Time" /> (HH:MM)
                </span>
                <div className="wd-80">
                  <input
                    type="number"
                    className="form-control survey-write-input tx-center"
                    min="0"
                    max="23"
                    value={selectedTime[0]}
                    onChange={(e: any) => onOptionTimeChange(e, idx, 'H')}
                    onInput={(e: any) => (e.target.value = e.target.value.slice(0, 2))}
                  />
                </div>
                :
                <div className="wd-80">
                  <input
                    type="number"
                    className="form-control survey-write-input tx-center"
                    min="0"
                    max="60"
                    value={selectedTime[1]}
                    onChange={(e: any) => onOptionTimeChange(e, idx, 'M')}
                    onInput={(e: any) => (e.target.value = e.target.value.slice(0, 2))}
                  />
                </div>
              </div>
            </div>
          );
        });
      case Q_MULTI_CHOICES_GRID:
        return (
          <div className="survey-write-question-card-list-container pd-t-10">
            <table className="table survey-write-question-grid-table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  {/* column headers */}
                  {optionsQ.cols.map((col: any, idx: number) => (
                    <th key={idx} scope="col">
                      {col.value ? col.value : t('Column') + ' ' + (idx + 1)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* rows content */}
                {optionsQ.rows.map((row: any, rIdx: number) => (
                  <tr key={rIdx}>
                    <td scope="row" className="tx-left">
                      {row.value ? row.value : t('Row') + ' ' + (rIdx + 1)}
                    </td>
                    {optionsQ.cols.map((col: any, cIdx: number) => {
                      //dynamic option key
                      const optName = 'q' + (keyQ + 1).toString() + (rIdx + 1).toString();
                      const optKey =
                        'option' +
                        (keyQ + 1).toString() +
                        (rIdx + 1).toString() +
                        (cIdx + 1).toString();
                      //answer checked, eg. [0][1] true
                      const optChecked =
                        optionsQ.answer && optionsQ.answer[rIdx]
                          ? optionsQ.answer[rIdx][cIdx]
                          : false;
                      //render option radio check
                      return (
                        <td key={cIdx} scope="row">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id={optKey}
                              name={optName}
                              className="custom-control-input"
                              checked={optChecked}
                              onChange={(e: any) => onOptionGridRadioChange(e, rIdx, cIdx)}
                            />
                            <label className="custom-control-label" htmlFor={optKey}></label>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      case Q_TICK_BOX_GRID:
        return (
          <div className="survey-write-question-card-list-container pd-t-10">
            <table className="table survey-write-question-grid-table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  {/* column headers */}
                  {optionsQ.cols.map((col: any, idx: number) => (
                    <th key={idx} scope="col">
                      {col.value ? col.value : t('Column') + ' ' + (idx + 1)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* rows content */}
                {optionsQ.rows.map((row: any, rIdx: number) => (
                  <tr key={rIdx}>
                    <td scope="row" className="tx-left">
                      {row.value ? row.value : t('Row') + ' ' + (rIdx + 1)}
                    </td>
                    {optionsQ.cols.map((col: any, cIdx: number) => {
                      //dynamic option key
                      //const optionName = 'q' + (keyQ + 1).toString() + (rIdx + 1).toString();
                      const optKey =
                        'option' +
                        (keyQ + 1).toString() +
                        (rIdx + 1).toString() +
                        (cIdx + 1).toString();
                      //answer checked, eg. [0][1] true
                      const optChecked =
                        optionsQ.answer && optionsQ.answer[rIdx]
                          ? optionsQ.answer[rIdx][cIdx]
                          : false;
                      //render option checkbox
                      return (
                        <td key={cIdx} scope="row">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id={optKey}
                              className="custom-control-input"
                              checked={optChecked}
                              onChange={(e) => onOptionGridCheckboxChange(e, rIdx, cIdx)}
                            />
                            <label className="custom-control-label" htmlFor={optKey}></label>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      default:
        return <div></div>;
    }
  }

  //render options group
  function renderOptionsGroup() {
    //other types
    const countGridAns = Object.keys(hasGridAnswerQ).length;
    return (
      <div className="survey-write-question-card-list pd-t-10">
        {/* options */}
        {renderOption()}

        {selectedQType === Q_MULTI_CHOICES_GRID && 1 === countGridAns && required && (
          <div className="d-flex flex-row pd-l-10 align-items-center">
            <AlertCircle className="tx-danger" />
            <span className="pd-l-10 tx-danger">This question requires one response per row</span>
          </div>
        )}

        {selectedQType === Q_TICK_BOX_GRID &&
          0 < countGridAns &&
          countGridAns < optionsQ.rows.length &&
          required && (
            <div className="d-flex flex-row pd-l-10 align-items-center">
              <AlertCircle className="tx-danger" />
              <span className="pd-l-10 tx-danger">
                This question requires at least one response per row
              </span>
            </div>
          )}

        {/* clear answer */}
        {(selectedQType === Q_MULTI_CHOICES ||
          selectedQType === Q_CHECKBOXES ||
          selectedQType === Q_DROPDOWN) &&
          hasAnswerQ && (
            <div className="survey-write-question-card-list-item-clear pd-t-10">
              <span className="survey-write-question-clear-button" onClick={clearAnswer}>
                <SpanLang keyLang="Clear selection" />
              </span>
            </div>
          )}

        {(selectedQType === Q_MULTI_CHOICES_GRID || selectedQType === Q_TICK_BOX_GRID) &&
          countGridAns > 0 && (
            <div className="survey-write-question-card-list-item-clear pd-t-10">
              <span className="survey-write-question-clear-button" onClick={clearGridAnswer}>
                <SpanLang keyLang="Clear selection" />
              </span>
            </div>
          )}
      </div>
    );
  }

  //// console.log('optionsQ', optionsQ);
  //component render
  return (
    <div className="survey-write-question-view-container">
      <div className="survey-write-question-card-root-container">
        <div className="survey-write-question-card-root">
          <div className={classNames('card', { 'bd-danger': required && requiredInValid })}>
            {/* <img src="..." class="card-img-top" alt="..."> */}

            {/* question options */}
            <div className="card-body">
              {/* title */}
              {renderTitle()}

              {renderQImage()}

              {/* question options group */}
              {renderOptionsGroup()}

              {required && requiredInValid && (
                <div className="pd-l-10">
                  <AlertTriangle className="tx-danger" />
                  <span className="tx-danger">
                    <SpanLang keyLang="Required" />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionView;
