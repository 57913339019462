import usePost from '@base/hooks/usePost';
import React, { useState } from 'react';
import { COMMON_QR_CODE_CREATE, SETTING_CTA_QR_CODE_GET } from '../../services/graphql';

const QrCodePreview: React.FC<any> = (props: any) => {
  const { id, linkUrl } = props;

  const { data, isLoading } = usePost<any>(
    ['common_generateQRCode', linkUrl, 'ctaQrCode'],
    COMMON_QR_CODE_CREATE,
    {
      content: linkUrl,
      width: 150,
      margin: 10,
      scale: 10,
      darkColor: '#00F',
      lightColor: '#0000',
    },
  );

  return <>{!isLoading && <img src={data?.image} style={{ width: 150, height: 150 }} />}</>;
};
export default QrCodePreview;
