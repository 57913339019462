import React, { useMemo, useState } from 'react';
import { ListContainer } from '@base/components/list';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@settings/assignment-rule/config/display-layout';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms';
import { FilterInput } from '@base/types/interfaces/common';
import { useRecoilState, useRecoilValue } from 'recoil';
import PageBody from './body';
import PageHeader from './header';
import PageToolbar from './toolbar';
import { ListPageConfig } from '@settings/assignment-rule/config/pages/list-page';
import { useTranslation } from 'react-i18next';
import {
  CREATE_MENU_TITLE,
  MAIN_MENU,
  MENU_TITLE,
} from '@settings/assignment-rule/config/pages/main-page';
import { ModalContainer } from '@base/containers/aside-modal';
import SalesCommissionWrite from '@settings/assignment-rule/containers/write';
import { IAssignMentRule } from '@settings/assignment-rule/types/interfaces';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_ASSIGNMENT_RULE } from '@settings/assignment-rule/services/graphql';
import { toast } from 'react-toastify';
import ARWrite from '@settings/assignment-rule/containers/write-new';
import ARView from '@settings/assignment-rule/containers/ar-view';

interface IListPageProps {
  isSplitMode: boolean;
  setIsSplitMode: (isSplitMode: boolean) => void;
}
const ListPage: React.FC<IListPageProps> = (props: IListPageProps) => {
  const { isSplitMode, setIsSplitMode } = props;
  const { isDesktop, device, layout } = useRecoilValue(deviceAtom);
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MAIN_MENU));
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState(false);
  const [modalTitle, setModalTitle] = useState(CREATE_MENU_TITLE);
  const [isOpen, setIsOpen] = useState(false);
  const [viewId, setViewId] = useState('');
  const [isView, setIsView] = useState(false);

  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_ASSIGNMENT_RULE,
    'setting_updateAssignmentRule',
    {
      onSuccess: (res: any) => {
        toast.success('Data was updated!');
        refetch();
        setIsSaving(false);
      },
      onError: (res: any) => {
        toast.error('Saving has failed!');
        setIsSaving(false);
      },
    },
  );
  // for page setting - list
  let { filter, listType } = pageData;
  let filtersQuery: FilterInput = {
    //filters: { ...filter?.baseFilters, ...filter?.searchFilters, ...filter?.headerFilters },
    sort: filter?.sort,
    paging: filter?.paging,
  };
  const { isLoading, data, isFetching, isPreviousData, refetch } =
    ListPageConfig.useFectchList(filtersQuery);

  const onAdd = () => {
    setIsOpen(true);
    setIsView(false);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  //format list data
  const scList = data?.data;
  // console.log(data?.data);
  /** ============================ RENDER ================================ */
  //toolbar
  const PageToolbarMemo = useMemo(() => {
    return (
      <PageToolbar
        refetch={refetch}
        onAdd={onAdd}
        category={'list'}
        isSplitMode={isSplitMode}
        setIsSplitMode={setIsSplitMode}
      />
    );
  }, [scList]);
  const PageHeaderMemo = useMemo(() => {
    return <PageHeader />;
  }, [scList]);
  const onActive = (nActive: boolean, row: IAssignMentRule) => {
    setIsSaving(true);
    let param: any = {
      id: row.id,
      active: nActive,
    };
    mUpdate.mutate({ ar: param });
  };
  const goView = (row: IAssignMentRule) => {
    // console.log('Go View', row.id);
    setViewId(row.id ?? '');
    setIsOpen(true);
    setIsView(true);
  };
  const onAfterDeleted = () => {
    setViewId('');
    setIsOpen(false);
    setIsView(false);
    refetch();
  };
  //body
  const PageBodyMemo = useMemo(() => {
    return (
      <PageBody
        fields={ListPageConfig.fields}
        itemsList={scList ?? []}
        paging={data?.paging}
        isPreviousData={isPreviousData}
        columnRenderRemap={ListPageConfig.getColumnRenderRemap({ onActive, goView })}
        hideColumns={ListPageConfig.hideColumns}
      />
    );
  }, [scList]);
  //main
  return (
    <>
      <ListContainer isLoading={isFetching || isSaving} isSplitMode={false} isDesktop={isDesktop}>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={PageToolbarMemo}
          body={PageBodyMemo}
          header={PageHeaderMemo}
        />
      </ListContainer>

      {/* hook form - write sales plan */}
      <ModalContainer
        isOpen={isOpen}
        onClose={onClose}
        isFooter={false}
        title={modalTitle}
        writeComponent={<ARWrite onClose={onClose} onAfterSaved={refetch} />}
        viewComponent={
          <ARView menuSource={MAIN_MENU} menuSourceId={viewId} onClose={onAfterDeleted} />
        }
        isView={isView}
      />
    </>
  );
};

export default ListPage;
