import _ from 'lodash';
import { StatusForm } from '@settings/process/types/process';
import CriteriaRuleView from './CriteriaRuleView';
import React from 'react';

interface CriteriaViewProps {
  status: StatusForm;
}

function CriteriaView(props: CriteriaViewProps) {
  const { status } = props;

  return (
    <div className="row">
      <div className="col-lg-3">
        <div className="diagram-item diagram-criteria with-boolean-direction">
          <div className="criteria-shape"></div>
          <div className="diagram-item-name">{status.button}</div>
          <div className="direction-true"></div>
          <div className="direction-false"></div>
        </div>
      </div>
      <div className="col-lg-9">
        <CriteriaRuleView
          criteriaId={status.id}
          criteriaName={status.button}
          criteriaOption={status.criteria!!}
        />
      </div>
    </div>
  );
}

export default React.memo(CriteriaView);
