import { Button, Checkbox, FormIcon, Input } from '@base/components/form';
import { ListPagination } from '@base/components/list';
import { TableNothing } from '@base/components/list/list-loading';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import Loading from '@base/components/loading';
import { FilterInput } from '@base/types/interfaces/common';
import { Paging } from '@base/types/interfaces/response';
import { KnowledgeBase } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import { IDeskTag } from '@desk/main/types/interfaces';
import { useKnowledgesByTag } from '@settings/preferences/services/service';
import { ColumnDef } from '@tanstack/react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trash2 } from 'react-feather';

interface IKnowledgeListProps {
  viewTag: IDeskTag | null;
  goList: () => void;
}
const KnowledgeList: React.FC<IKnowledgeListProps> = (props: IKnowledgeListProps) => {
  const { viewTag, goList } = props;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [listData, setListData] = useState<KnowledgeBase[]>([]);
  const [paging, setPaging] = useState<Paging | null>(null);
  const [keyword, setKeyword] = useState('');
  const getParam = () => {
    let filter: FilterInput | null = null;
    if (viewTag) {
      filter = {
        query: 'tags=' + viewTag?.id + ' subject:"' + keyword + '"',
        paging: {
          page: paging?.currentPage ?? 1,
          size: paging?.itemPerPage ?? 15,
        },
      };
    }
    return filter;
  };
  const { data, isFetching, refetch } = useKnowledgesByTag(getParam());
  const handleCheckItem = useCallback(
    (checkedValue: any[]) => {
      setSelectedIds(checkedValue);
    },
    [selectedIds],
  );
  const handleSearch = (nVal: string) => {
    setKeyword(nVal);
    refetch();
  };
  const handleDelete = (selectedIds: string[]) => {};
  const quickButtons = [
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: (selected: any) => {
        if (selectedIds.length > 0) {
          handleDelete(selectedIds);
        }
      },
    },
  ];
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      // {
      //   id: 'select',
      //   width: '45px',
      //   header: ({ table }) => (
      //     <Checkbox
      //       {...{
      //         checked: table.getIsAllRowsSelected(),
      //         indeterminate: table.getIsSomeRowsSelected(),
      //         onChange: table.getToggleAllRowsSelectedHandler(),
      //       }}
      //     />
      //   ),
      //   cell: ({ row }) => (
      //     <div className="pd-x-1">
      //       <Checkbox
      //         {...{
      //           checked: row.getIsSelected(),
      //           indeterminate: row.getIsSomeSelected(),
      //           onChange: row.getToggleSelectedHandler(),
      //         }}
      //       />
      //     </div>
      //   ),
      // },
      {
        id: 'category',
        header: ({ table }) => <span>Category/Folder</span>,
        cell: ({ row }: any) => {
          return (
            <>
              {row.original?.category?.name ?? ''}
              {row.original?.folder?.name ? '/' + row.original?.folder?.name : ''}
            </>
          );
        },
      },
      {
        id: 'subject',
        header: ({ table }) => <span>Subject</span>,
        cell: ({ row }: any) => {
          return <>{row.original?.subject}</>;
        },
      },
      {
        id: 'viewed',
        header: ({ table }) => <span>Viewed</span>,
        cell: ({ row }: any) => {
          return <>{row.original?.viewed}</>;
        },
      },
      {
        id: 'inserted',
        header: ({ table }) => <span>Inserted</span>,
        cell: ({ row }: any) => {
          return <>{row.original?.inserted}</>;
        },
      },
      {
        id: 'helped',
        header: ({ table }) => <span>Helpful</span>,
        cell: ({ row }: any) => {
          return <>{row.original?.helped}</>;
        },
      },
      {
        id: 'notHelped',
        header: ({ table }) => <span>Not Helpful</span>,
        cell: ({ row }: any) => {
          return <>{row.notHelped}</>;
        },
      },
    ],
    [listData, selectedIds],
  );
  const listTableProps = {
    nodata: <TableNothing />,
    data: listData,
    loading: isFetching,
    columns: columns, //tableHideColumns
    initialState: {
      pageSize: data?.paging?.itemPerPage || 15,
    },
    onRowSelect: handleCheckItem,
    // rowSelected: selectedIds,
    className: 'tag-refer-list',
  };
  //table paging
  const pagingProps = {
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: paging?.itemPerPage || 15,
    //isPreviousData,
    onChange: (page: number, size: number) => {
      const nPaging: Paging | null = paging
        ? {
            ...paging,
            currentPage: page,
            itemPerPage: size,
          }
        : null;
      setPaging(nPaging);
    },
  };
  useEffect(() => {
    if (data && !isFetching) {
      setListData(data.results ?? []);
      setPaging(data.paging);
    }
  }, [data]);

  return (
    <>
      {isFetching ? <Loading /> : ''}
      <div className="pd-20 scroll-box setting-tab-body">
        <div className="card list-wrap">
          <div className="card-header d-flex align-items-center pd-10">
            <Button
              color="link"
              icon="ArrowLeft"
              className="btn-icon mg-r-10"
              onClick={() => {
                goList();
              }}
            />
            {viewTag?.name ?? ''}
            <div className="input-group wd-300 mg-l-auto">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <FormIcon icon="Search" className="tx-gray-400" />
                </span>
              </div>
              <Input type="text" value={keyword} onChange={(nVal: string) => handleSearch(nVal)} />
            </div>
          </div>
          <ListReactTable8 {...listTableProps} />
          <ListPagination isSplitMode={false} type={'full'} {...pagingProps} />
          {/* {selectedIds?.length && (
            <ListQuickAction
              checked={selectedIds}
              buttons={quickButtons}
              resetSelected={() => setSelectedIds([])}
              visible={selectedIds.length > 0}
            />
          )} */}
        </div>
      </div>
    </>
  );
};

export default KnowledgeList;
