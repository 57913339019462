import Canvas from '@base/components/canvas';
import { DatePicker } from '@base/components/date-picker';
import { Input, RadioGroup, Select } from '@base/components/form';
import Portal from '@base/components/portal';
import { BILLING_CYCLE_OPTION, RENEWAL_OPTION } from '@settings/billing-license/config/constant';
import { IBillingPlan } from '@settings/billing-license/types/interfaces/billing-plan';
import React, { useEffect, useState } from 'react';

interface IView {
  isVisible: boolean;
  value: IBillingPlan;
  onChange: (keyName: string, keyValue: any) => any;
  onClose: (params: any) => any;
  onSubmit: (params: any) => any;
}

const BillAndPaymentView: React.FC<IView> = (props: IView) => {
  const { isVisible, value, onChange, onClose, onSubmit } = props;
  // const [selectedValueRenewal, setSelectedValueRenewal]=useState({value:'auto', label: 'Auto renews until canceled.'});
  const [data, setData] = useState<IBillingPlan>();
  useEffect(() => {
    setData(value);
  }, [value]);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  // // console.log(data);
  const handleChange = (keyName: string, keyValue: any) => {
    onChange && onChange(keyName, keyValue);
  };
  return (
    <Portal>
      <Canvas
        isVisible={isVisible}
        onCloseSideBar={onClose}
        onClose={onClose}
        className={'page-sidebar-container-wrap'}
        customStyles={{ width: '420px' }}
      >
        <Canvas.Header title="Billing Plan" />
        <Canvas.Body style={{ height: 'calc(100vh - 130px)', width: '420px' }}>
          <label className="tx-color-03 mt-3">Plan Name</label>
          <Input value={data?.name} onChange={(data: any) => handleChange('name', data)} />
          <label className="tx-color-03 mt-3">Billing Cycle</label>
          <Select
            options={BILLING_CYCLE_OPTION}
            value={data?.billingCycle}
            onChange={(data: any) => handleChange('billingCycle', data)}
          />
          <ul className="list-group border-0 mt-3">
            <li className="d-flex align-items-center justify-content-between bg-white border-0">
              <RadioGroup
                value={data?.isRenewAuto ? RENEWAL_OPTION[0] : RENEWAL_OPTION[1]}
                options={RENEWAL_OPTION}
                isVertical={true}
                onChange={() => handleChange('isRenewAuto', !data?.isRenewAuto)}
              />
              <Input
                type="number"
                className="wd-100 mg-x-5"
                min="0"
                value={data?.timesRenewal}
                onChange={(data: any) => handleChange('timesRenewal', data)}
              />
              <span>of billing cycles.</span>
            </li>
          </ul>
          <label className="tx-color-03 mt-3">Start on</label>
          <DatePicker
            selected={data?.startDate || new Date()}
            onChange={(data: any) => handleChange('startDate', data)}
          ></DatePicker>
          <label className="tx-color-03 mt-3">End on</label>
          <div>{data?.endDate?.toLocaleDateString() || new Date().toLocaleDateString()}</div>
          <label className="tx-color-03">
            It will be changed into the date the first purchased.
          </label>
          <div></div>
          <label className="tx-color-03 mt-3">Invoice Date</label>
          <div>1/11/2023</div>
          <label className="tx-color-03 mt-3">Billing Date</label>
          <div>1/11/2023</div>
        </Canvas.Body>
        <Canvas.Footer
          onClose={onClose}
          onSave={() => {
            onSubmit(value);
          }}
          saveColor={'success'}
        />
      </Canvas>
    </Portal>
  );
};
export default BillAndPaymentView;
