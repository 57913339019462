import { ISideMenuItem } from '@base/types/interfaces/app';
import { lazy } from 'react';
const BillingInfomation = lazy(() => import('@settings/billing-license/pages/billing-infomation'));
const BillingLicenses = lazy(() => import('@settings/billing-license/pages/license'));
const BillAndPayment = lazy(() => import('@settings/billing-license/pages/bill-and-payment'));
const History = lazy(() => import('@settings/billing-license/pages/history'));

export const SUB_MENUS: ISideMenuItem[] = [
  {
    id: 'billing-infomation',
    default: true,
    keyName: 'billing-infomation',
    languageKey: 'Billing Infomation',
    icon: {
      icon: 'Folder',
      iconType: 'feather',
    },
    layouts: [],
    component: BillingInfomation,
  },
  {
    id: 'license',
    default: true,
    keyName: 'license',
    languageKey: 'Licenses',
    icon: {
      icon: 'Folder',
      iconType: 'feather',
    },
    layouts: [],
    component: BillingLicenses,
  },
  {
    id: 'bill-payment',
    default: true,
    keyName: 'bill-payment',
    languageKey: 'Bill & Payment',
    icon: {
      icon: 'Folder',
      iconType: 'feather',
    },
    layouts: [],
    component: BillAndPayment,
  },
  {
    id: 'history',
    default: true,
    keyName: 'history',
    languageKey: 'History',
    icon: {
      icon: 'Folder',
      iconType: 'feather',
    },
    layouts: [],
    component: History,
  },
];
