import {
  LANDING_PAGE_PUBLISH_SCHEDULE_LATER,
  LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH,
} from './../../config/constants';
import * as keyNames from '@settings/online-digital-content/landing-page/config/key-names';

export const finalizeParams = (params: any, variableKey = 'landingPage') => {
  let newParams: any = {};

  const { ...remainParams } = params;

  newParams = {
    ...newParams,
    ...remainParams,
  };

  if (!newParams?.[keyNames.KEY_LANDINGPAGE_PUBLISH]) {
    newParams[keyNames.KEY_LANDINGPAGE_PUBLISH] = LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH;
  }

  if (newParams?.[keyNames.KEY_LANDINGPAGE_PUBLISH] != LANDING_PAGE_PUBLISH_SCHEDULE_LATER) {
    delete newParams?.[keyNames.KEY_LANDINGPAGE_PUBLISH_DATE];
  }

  return {
    [variableKey]: newParams,
  };
};
