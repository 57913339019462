import React from 'react';
import { Checkbox, Input } from '@base/components/form';

const SubmissionBehavior: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  const handleCreateTicketChange = (newValue: boolean) => {
    onChange && onChange({ ...value, createTicket: newValue });
  };

  const handleTicketNameChange = (newValue: string) => {
    onChange && onChange({ ...value, ticketName: newValue });
  };

  return (
    <>
      <Checkbox
        className="mg-b-10"
        label={'Create ticket'}
        checked={value?.createTicket === true}
        onChange={(e: any) => handleCreateTicketChange(e?.target?.checked)}
      />
      <Input type="text" value={value?.ticketName ?? ''} onChange={handleTicketNameChange} />
    </>
  );
};
export default SubmissionBehavior;
