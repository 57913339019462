import { SpanLang } from '@base/components';
import { getCurrentLang } from '@base/services/i18n';
import { ILanguageData } from '@base/types/interfaces/common';
import { IField } from '@settings/page-layout/types/interfaces/pagelayout';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ItemUnused from '../item-unused';
// eslint-disable-next-line react/display-name
const getRenderItem =
  // eslint-disable-next-line react/display-name
  (items: any, componentItem: any) => (provided: any, snapshot: any, rubric: any) => {
    const item = items[rubric.source.index];
    return (
      <>
        <li
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={provided.draggableProps.style}
          className={snapshot.isDragging ? 'dragging' : ''}
        >
          <ItemUnused
            isBasicField={item.isBasicField}
            isRequired={item.isRequired}
            locationsDisplay={{
              isShowInList: item.isShowInList,
              isShowInView: item.isShowInView,
              isShowInWrite: item.isShowInWrite,
            }}
            label={<SpanLang keyLang={item.languageKey} />}
            icon={item?.fieldIcon?.icon ?? ''}
            iconType={item?.fieldIcon?.iconType ?? ''}
            isDelete={item?.permission?.canDelete ?? false}
            onDelete={() => {}}
          />
        </li>
      </>
    );
  };

interface ICopyableUnusedProps {
  droppableId: string;
  items: IField[];
  className: string;
  componentItem?: any;
  onDeleteUnusedItem: (params: any) => void;
  isLoading: boolean;
}
const CopyableUnused: React.FC<ICopyableUnusedProps> = (props: ICopyableUnusedProps) => {
  const {
    items,
    componentItem = null,
    droppableId,
    className,
    onDeleteUnusedItem,
    isLoading = false,
  } = props;
  const currentLang = getCurrentLang();
  return (
    <Droppable
      renderClone={getRenderItem(props.items, componentItem)}
      droppableId={droppableId}
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <ul ref={provided.innerRef} className={className}>
          {items.map((item, index) => {
            const shouldRenderClone = item.id === snapshot.draggingFromThisWith;
            return (
              <React.Fragment key={item.id}>
                {shouldRenderClone ? (
                  <li className="react-beatiful-dnd-copy">
                    <ItemUnused
                      isBasicField={item.isBasicField}
                      isRequired={item.isRequired ? item.isRequired : false}
                      locationsDisplay={{
                        isShowInList: item.showInList,
                        isShowInView: item.showInView,
                        isShowInWrite: item.showInWrite,
                      }}
                      // label={<SpanLang keyLang={item.languageKey} />}
                      label={
                        item.language && item.language[currentLang as keyof ILanguageData]
                          ? item.language[currentLang as keyof ILanguageData]
                          : ''
                      }
                      icon={item?.icon?.icon ?? ''}
                      iconType={item?.icon?.iconType ?? 'icon'}
                      isDelete={item?.permission?.canDelete ?? false}
                      onDelete={() => {
                        onDeleteUnusedItem({
                          item,
                          index,
                        });
                      }}
                    />
                  </li>
                ) : (
                  <Draggable draggableId={item.id} index={index} isDragDisabled={isLoading}>
                    {(provided, snapshot) => (
                      <React.Fragment>
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={snapshot.isDragging ? 'dragging' : ''}
                        >
                          <ItemUnused
                            isBasicField={item.isBasicField}
                            // type={props.componentItem}
                            isRequired={item.isRequired ? item.isRequired : false}
                            locationsDisplay={{
                              isShowInList: item.showInList,
                              isShowInView: item.showInView,
                              isShowInWrite: item.showInWrite,
                            }}
                            // label={<SpanLang keyLang={item.languageKey} />}
                            label={
                              item.language && item.language[currentLang as keyof ILanguageData]
                                ? item.language[currentLang as keyof ILanguageData]
                                : ''
                            }
                            icon={item?.icon?.icon ?? ''}
                            iconType={item?.icon?.iconType ?? 'icon'}
                            isDelete={item?.permission?.canDelete ?? false}
                            onDelete={() =>
                              onDeleteUnusedItem({
                                item,
                                index,
                              })
                            }
                            isLoading={isLoading}
                          />
                        </li>
                      </React.Fragment>
                    )}
                  </Draggable>
                )}
              </React.Fragment>
            );
          })}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export default CopyableUnused;
