import React, { useEffect, useState, FC } from 'react';
import SelectDropdown from '@base/components/form/select-dropdown';
import ListHeaderSort from '@base/components/list/list-header/list-header-sort';
import { ListPageConfig } from '@settings/site/config/pages/list-page';
import dayjs from 'dayjs';
import SelectDropdownWithDates from '@base/components/form/select-dropdown-dates';
import SelectDropdownFilters from '@base/components/form/select-dropdown-filters';

interface IListHeaderFilters {
  category: string;
  filterData: { [key: string]: any };
  onFilterDataChanged: (value: any) => void;
  isSplitMode?: boolean;
  sortColumns: any[];
  sortData: any;
  onSortDataChanged: (sortData: any) => void;
}

const ListHeaderFilterNormalMode = ({
  filterData,
  onFilterDataChanged,
  groupByOptions,
  dateByOptions,
  filterByOptions,
}: any) => {
  return (
    <>
      {/*  Group By */}
      {groupByOptions?.length > 0 ? (
        <SelectDropdown
          options={groupByOptions}
          selected={filterData?.groupBy ?? ''}
          placeholder="Group By"
          onChange={(data: any) => onFilterDataChanged(data.value, 'group_by')}
        />
      ) : (
        ''
      )}
      {/*  Date By */}
      {dateByOptions?.length > 0 ? (
        <SelectDropdownWithDates
          placeholder="Select date"
          options={dateByOptions}
          onChange={(data: any) => {
            onFilterDataChanged(data, 'date');
          }}
        />
      ) : (
        ''
      )}
      {/*  Filter By */}
      {filterByOptions?.length > 0 ? (
        <SelectDropdownFilters
          placeholder="Filters"
          options={filterByOptions}
          onChange={(data: any) => onFilterDataChanged(data, 'filter')}
        />
      ) : (
        ''
      )}
    </>
  );
};

const ListHeaderFilters: FC<IListHeaderFilters> = (props: IListHeaderFilters) => {
  const {
    category,
    isSplitMode,
    sortColumns,
    sortData,
    onSortDataChanged,
    filterData,
    onFilterDataChanged,
  } = props;

  const [date, setDate] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [groupBy, setGroupBy] = useState<any>({});

  useEffect(() => {
    onFilterDataChanged({ ...date, ...filter, ...groupBy });
  }, [date, filter, groupBy]);

  const filterProps = {
    filterData,
    onFilterDataChanged: (data: any, type: string) => {
      if (type === 'date') {
        for (const k in data) {
          data[k][0] = dayjs(data[k][0]).toISOString();
          data[k][1] = dayjs(data[k][1]).toISOString();
        }
        setDate(data);
      } else if (type === 'filter') {
        setFilter(data);
      } else {
        setGroupBy(data ? { groupBy: data } : {});
      }
    },
    groupByOptions: ListPageConfig.groupByOptions,
    dateByOptions: ListPageConfig.dateByOptions,
    filterByOptions: ListPageConfig.filterByOptions,
  };

  const sortProps = { onSortDataChanged, sortData, sortColumns };

  return (
    <>
      <ListHeaderFilterNormalMode {...filterProps} />
      {sortColumns && !!sortColumns.length && <ListHeaderSort {...sortProps} />}
    </>
  );
};

export default ListHeaderFilters;
