import { SpanLang } from '@base/components';
import { Button, Input, Priority, Select } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import Duration from '@settings/preferences/components/duration';
import { OPERATIONAL_HOURS_OPTIONS } from '@settings/preferences/config/constant';
import { UPDATE_RESPOND_PRIORITY_SETTING } from '@settings/preferences/services/graphql';
import { useRespondByPrioritySetting } from '@settings/preferences/services/service';
import { EOperationalHours } from '@settings/preferences/types/enums';
import { IRespondPriorityItem } from '@settings/preferences/types/interfaces';
import { sr } from 'date-fns/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

interface IFirstRespondPriorityProps {}
const FirstRespondPriority: React.FC<IFirstRespondPriorityProps> = (
  props: IFirstRespondPriorityProps,
) => {
  const [items, setItems] = useState<IRespondPriorityItem[]>([]);
  const { data, isLoading } = useRespondByPrioritySetting();
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_RESPOND_PRIORITY_SETTING,
    'setting_updateMenuSetting',
  );
  const onSave = (newItems: IRespondPriorityItem[]) => {
    const nSetting = {
      menu: 'desk',
      key: 'respond_priority',
      value: JSON.stringify(newItems),
    };
    mUpdate.mutate(
      { menuSetting: nSetting },
      {
        onSuccess: (res: any) => {
          toast.success('Data was saved!');
        },
      },
    );
  };
  const onReset = (rIdx: number) => {
    const nItems = items.map((item, idx) => {
      if (rIdx == idx) {
        const nItem: IRespondPriorityItem = {
          priority: item.priority,
          standardSla: {
            duration: 0,
            durationUnit: '',
          },
          premiumSla: {
            duration: 0,
            durationUnit: '',
          },
        };
        // console.log('Reset', nItem);
        return nItem;
      }
      return item;
    });

    onSave(nItems);
    // console.log('nItems', nItems);
    setItems([...nItems]);
  };
  const onRowChange = (nRow: IRespondPriorityItem) => {
    const nItems = items.map((item) => {
      if (item.priority == nRow.priority) {
        return nRow;
      }
      return item;
    });
    setItems(nItems);
    onSave(nItems);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Priority',
        accessor: (row: IRespondPriorityItem, rowIndex: any) => {
          return (
            <>
              <Priority value={row.priority} />
            </>
          );
        },
      },
      {
        Header: 'Standard SLA',
        accessor: (row: IRespondPriorityItem, rowIndex: any) => {
          return (
            <>
              <Duration
                value={row.standardSla}
                onChange={(newVal) => {
                  const nRow: IRespondPriorityItem = {
                    ...row,
                    standardSla: newVal,
                  };
                  onRowChange(nRow);
                }}
              />
            </>
          );
        },
      },
      {
        Header: 'Premium SLA',
        accessor: (row: IRespondPriorityItem, rowIndex: any) => {
          return (
            <>
              <div className="d-flex">
                <Duration
                  value={row.premiumSla}
                  onChange={(newVal) => {
                    const nRow: IRespondPriorityItem = {
                      ...row,
                      premiumSla: newVal,
                    };
                    onRowChange(nRow);
                  }}
                />
                <Button
                  color=""
                  icon="X"
                  iconClass="tx-danger"
                  className="btn-icon btn-delete-row han-tooltip--left mg-l-auto"
                  data-han-tooltip="삭제"
                  onClick={() => {
                    onReset(rowIndex);
                  }}
                />
              </div>
            </>
          );
        },
      },
    ],
    [items],
  );
  useEffect(() => {
    // console.log('data', data);
    if (!isLoading && data?.value) {
      try {
        const slas: IRespondPriorityItem[] = JSON.parse(data.value);
        setItems(slas);
      } catch (err: any) {}
    }
  }, [data]);
  return (
    <>
      <div className="card mg-b-15">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">Time to 1st Respond by Priority</div>
        </div>
        <BasicTable
          columns={columns}
          data={items || []}
          className="table table-bordered mg-b-0 bd-0 settings-tb hover-delete"
          wrapperClassName=""
          isLoading={isLoading}
        ></BasicTable>
      </div>
    </>
  );
};

export default FirstRespondPriority;
