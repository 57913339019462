import React, { useState, useEffect, useCallback } from 'react';
import { Download, Edit, FolderPlus, Trash2, UserCheck, UserPlus } from 'react-feather';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';

import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { FilterInput } from '@base/types/interfaces/common';
import { DESC, ASC } from '@base/config/constant';
import SpanLang from '@base/components/form/span-lang';
import ListLoading, { GridNothing, TableNothing } from '@base/components/list/list-loading';
import {
  ListBody,
  ListContainer,
  ListGrid,
  ListPagination,
  ListQuickAction,
  ListToolbar,
} from '@base/components/list';
import { DEFAULT_CATEGORY, MAIN_MENU, WritePage } from '../../config/pages/main-page';
import { ListPageConfig } from '../../config/pages/list-page';
import { SearchFilter } from '@base/types/interfaces/app';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@settings/site/config/display-layout';
import { ListType } from '@base/types/enums';
import ListHeader, { ListHeaderFilters } from '@settings/site/components/list/list-header';
import ListGridItem from '@settings/site/components/list/list-grid/list-grid-item';
import { handleCheckbox } from '@base/utils/helpers/list-page-utils';
import { WRITE_OPTION_ATOM } from '@settings/site/config/pages/write-page';
import { PREVENT_DELETE_SITES, SITE_GROUP_OPTION_NUMBER } from '@settings/site/config/constants';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { DELETE_SITE_TEMPLATE } from '@settings/site/services/graphql';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';
import ListReactTable from '@base/components/list/list-react-table';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';

interface ITemplateListPage {
  isSplitMode: boolean;
  setIsSplitMode: (isSplitMode: boolean) => void;
  category: string;
}

const TemplateListPage: React.FC<ITemplateListPage> = (props: ITemplateListPage) => {
  const { isSplitMode, setIsSplitMode, category } = props;
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MAIN_MENU));
  const [writeOption, setWriteOption] = useRecoilState(WRITE_OPTION_ATOM);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [editId, setEditId] = useState('');
  const [{ isDesktop, isMobile, isTablet, device, layout }] = useRecoilState(deviceAtom);
  // const navigate = useNavigate();
  // const params = useParams();
  // const aParams = params['*'] ? params['*'].split('/') : [];
  // const category = aParams[0] ? aParams[0] : DEFAULT_CATEGORY;
  const { t } = useTranslation();
  // for list page
  // for pagelayout - list
  const sortColumns = [
    {
      label: 'Updated At',
      value: 'updatedAt',
    },
    {
      label: 'Created At',
      value: 'createdAt',
    },
  ];
  const columnKeys: string[] = [];
  const columns = ListPageConfig.getColumns();
  const columnRenderRemap = ListPageConfig.getColumnRenderRemap({ category });
  const hideColumns = ListPageConfig.getHideColumns();
  const writeCategory = `${MAIN_MENU}_${writeOption.writeType}`;
  const keyTitle = `crm_new_${writeOption.writeType}`;
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  let { filter, listType } = pageData;
  const sort = filter?.sort;
  const setSort = (sort: any) => {
    let newData = {
      ...pageData,
      filter: {
        ...filter,
        sort: sort,
      },
    };
    setPageData(newData);
  };
  let baseFilters: any = {
    ...filter?.baseFilters,
    siteGroup: SITE_GROUP_OPTION_NUMBER[category],
  };
  let filtersQuery: FilterInput = {
    //filters: { ...baseFilters, ...filter?.searchFilters, ...filter?.headerFilters },
    query: ParseFiltersToQuery(filter, sortColumns, [{ name: 'name' }]),
    sort: filter?.sort,
    paging: filter?.paging,
  };
  const { isLoading, data, isFetching, isPreviousData, refetch } =
    ListPageConfig.useFectchList(filtersQuery);
  const listData = data?.data ? data.data : [];
  const onCheckedRow = useCallback(
    (checkedValue: string | number) => {
      const checkedValueStr = checkedValue as string;
      let newSelectedIds = handleCheckbox(checkedValueStr, selectedIds, listData);
      setSelectedIds([...newSelectedIds]);
    },
    [selectedIds, setSelectedIds],
  );

  const onAfterSaved = (nTitle: string) => {
    refetch();
  };
  const mDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_SITE_TEMPLATE,
    'setting_deleteSiteTemplate',
    {
      onSuccess: (res: any) => {
        setIsSaving(false);
        toast.success('Data was deleted!');
        refetch();
      },
      onError: (res: any) => {
        setIsSaving(false);
        toast.error('Deleting has failed!');
      },
    },
  );
  const onDelete = (id: string) => {
    setIsSaving(true);
    mDelete.mutate({ id });
  };
  const onEdit = (id: string) => {
    // console.log('onEdit', id);
    setEditId(id);
    setWriteOption((filter) => ({
      ...filter,
      isOpenWrite: true,
      writeType: category === undefined ? DEFAULT_CATEGORY : category,
    }));
  };
  const listItemProps = {
    category,
    onDelete,
    onEdit,
    onAfterSaved,
  };
  const listProps = {
    listData: listData,
    isFetching: false,
    fetching: <ListLoading />,
    onCheckedRow,
  };
  const listGridProps = {
    isSplitMode: false,
    selectedIds,
    device: device !== undefined ? device : 'desktop',
    nodata: <GridNothing />,
    columns,
    columnRenderRemap,
    hideColumns,
  };
  const pagingProps = {
    totalPage: data?.paging?.totalPage || 1,
    totalItems: data?.paging?.totalItems || 0,
    currentPage: data?.paging?.currentPage || 1,
    itemPerPage: data?.paging?.itemPerPage || 10,
    nextPage: data?.paging?.nextPage || null,
    previousPage: data?.paging?.previousPage || null,
    isPreviousData,
    onChange: (page: number, size: number) => {
      filter = {
        ...filter,
        paging: {
          page,
          size,
        },
      };
      let newData = {
        ...pageData,
        filter: filter,
      };
      setPageData(newData);
    },
  };

  const quickButtons = [
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: (selected: any) => {},
    },
  ];
  const listTableProps = {
    nodata: <TableNothing />,
    data: listData,
    loading: isFetching,
    columns: makeTableColumns(
      ListPageConfig.getFields(),
      ListPageConfig.getColumnRenderRemap({ category }),
      { category },
      ['id', 'thumbnail', 'siteGroup'],
    ), //tableHideColumns
    initialState: {
      pageSize: data?.paging?.totalPage || 0,
      pageIndex: data?.paging?.currentPage || 1,
      selectedIds: selectedIds,
    },
    onCheckedRow: onCheckedRow,
    onSortBy: (clName: any, isSortedDesc: boolean) => {
      let orderBy = isSortedDesc ? DESC : ASC;
      setSort({ field: clName, orderBy: orderBy });
    },
  };

  // more menu
  const actionsProps = {};

  const handleSearchSubmit = (formData: any) => {
    // console.log('handleSearchSubmit', formData);
    let nFormData = {
      name: formData.keyword,
    };

    // setSearchParams(formData);
    let newFilter: SearchFilter = {
      ...pageData.filter,
      searchFilters: nFormData,
    };
    let newData = {
      ...pageData,
      filter: newFilter,
    };

    setPageData(newData);
  };
  const canAdd = PREVENT_DELETE_SITES.indexOf(category) != -1 ? false : true;
  return (
    <>
      <ListContainer
        isLoading={isLoading || isFetching || isSaving}
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={
            <ListToolbar
              hideHeaderTitle={true}
              listTypeProps={{
                listTypeStr: listType,
                onChangeListType: (listType: ListType) => {
                  let newData = {
                    ...pageData,
                    listType: listType,
                  };
                  setPageData(newData);
                },
                listTypes: [ListType.GRID, ListType.LIST, ListType.SPLIT],
              }}
              isSplitMode={isSplitMode}
              onRefresh={refetch}
              canAdd={canAdd}
              onAdd={(category: string | undefined) => {
                setEditId('');
                setWriteOption((filter) => ({
                  ...filter,
                  isOpenWrite: true,
                  writeType: category === undefined ? DEFAULT_CATEGORY : category,
                }));
              }}
            />
          }
          header={
            <ListHeader
              listType={listType}
              isSplitMode={isSplitMode}
              searchFields={ListPageConfig.searchFields.reduce((final: any[], field: any) => {
                if (columnKeys.indexOf(field.name) !== -1) {
                  final.push(field);
                }
                return final;
              }, [])}
              onSearchSubmit={(formData: any) => handleSearchSubmit(formData)}
              columns={columns}
              filterComponent={
                <ListHeaderFilters
                  category={category ?? ''}
                  isSplitMode={isSplitMode}
                  filterData={filter?.headerFilters}
                  onFilterDataChanged={(valueData: any) => {
                    let newFilter: SearchFilter = {
                      ...filter,
                      headerFilters: valueData,
                    };
                    let newData = {
                      ...pageData,
                      filter: newFilter,
                    };
                    setPageData(newData);
                  }}
                  sortData={sort}
                  onSortDataChanged={setSort}
                  sortColumns={sortColumns}
                />
              }
              onSelectedAll={() => onCheckedRow('all')}
              onDeselectedAll={() => onCheckedRow('notAll')}
              onRefresh={refetch}
            />
          }
          body={
            <ListBody styles={{ height: 'calc(100vh - 220px)' }}>
              {/* table list */}
              {listType == ListType.LIST && <ListReactTable {...listTableProps} />}
              {/* grid list */}
              {listType == ListType.GRID && (
                <ListGrid
                  {...listProps}
                  {...listGridProps}
                  listGridItemRender={(props: any) => (
                    <ListGridItem {...props} {...listItemProps} />
                  )}
                />
              )}

              <ListPagination isSplitMode={false} type={'full'} {...pagingProps} />
            </ListBody>
          }
        />

        {/* Tablet layout here */}
        {isTablet && null}

        {/* Mobile layout here */}
        {isMobile && null}
      </ListContainer>

      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons || []}
        resetSelected={() => setSelectedIds([])}
        visible={!!selectedIds.length}
      />

      {/* hook form - write new customer write */}
      {writeOption.isOpenWrite && (
        <WritePage
          title={<SpanLang keyLang={'Create New Desk Site'} />}
          size="xl"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() => {
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }));
            refetch();
          }}
          onReload={refetch}
          //menuApi={writeCategory}
          id={editId}
        />
      )}
    </>
  );
};

export default TemplateListPage;
