import * as keyNames from '@settings/process/config/keyNames';
import {
  TextView,
  TextAreaView,
  Radio,
  Selectbox,
  CheckboxSingle,
} from '@base/config/view-field/components';
import {
  PROCESS_CLOSED_PROPERTY_OPTIONS,
  PROCESS_CLOSED_VIEW_OPTIONS,
} from '@settings/process/config/constants';
import { OptionValue } from '@base/types/interfaces/common';

export const ClosedViewField = {
  [keyNames.KEY_NAME_CLOSED_NAME]: {
    languageKey: 'process_closed_name',
    showFullRow: true,
    component: TextView,
    getValueView: (value: string) => value,
    getValueEdit: (value: string) => value,
    getMutationValue: (value: string) => ({
      [keyNames.KEY_NAME_CLOSED_NAME]: value,
    }),
  },
  [keyNames.KEY_NAME_CLOSED_DESCRIPTION]: {
    languageKey: 'process_closed_description',
    showFullRow: true,
    component: TextAreaView,
    getValueView: (value: string) => value,
    getMutationValue: (value: string) => ({
      [keyNames.KEY_NAME_STAGE_DESCRIPTION]: value,
    }),
  },
  [keyNames.KEY_NAME_CLOSED_PROPERTY]: {
    component: Selectbox,
    showFullRow: true,
    getValueView: (value: string) =>
      PROCESS_CLOSED_PROPERTY_OPTIONS.find((opt) => opt.keyName == value),
    getValueEdit: (value: string) =>
      PROCESS_CLOSED_PROPERTY_OPTIONS.find((opt) => opt.keyName == value),
    getMutationValue: (value: OptionValue) => ({
      meta: { [keyNames.KEY_NAME_CLOSED_PROPERTY]: value.keyName },
    }),
    getChangedValue: (value: OptionValue) => value.keyName,
    checkMappingField: (data: any) => data.meta && data.meta.hasOwnProperty('property'),
    mappingData: (data: any) => data.meta?.property,
    languageKey: 'process_closed_property',
    componentProps: {
      options: PROCESS_CLOSED_PROPERTY_OPTIONS,
    },
  },
  [keyNames.KEY_NAME_CLOSED_VIEW]: {
    component: Radio,
    showFullRow: true,
    languageKey: 'process_closed_view',
    getValueView: (value: string) =>
      PROCESS_CLOSED_VIEW_OPTIONS.find((opt: OptionValue) => opt.keyName == value),
    getValueEdit: (value: string) =>
      PROCESS_CLOSED_VIEW_OPTIONS.find((opt: OptionValue) => opt.keyName == value),
    getMutationValue: (value: OptionValue) => ({
      meta: { [keyNames.KEY_NAME_CLOSED_VIEW]: value.keyName },
    }),
    getChangedValue: (value: OptionValue) => value.keyName,
    checkMappingField: (data: any) => data.meta && data.meta.hasOwnProperty('view'),
    mappingData: (data: any) => data.meta?.view,
    componentProps: {
      options: PROCESS_CLOSED_VIEW_OPTIONS,
      isHorizontal: true,
    },
  },
  [keyNames.KEY_NAME_CLOSED_JUMP]: {
    component: CheckboxSingle,
    showFullRow: true,
    componentProps: {
      label: 'Possible to jump from any step/status',
    },
    hideTitle: true,
    getMutationValue: (value: string) => ({
      meta: { [keyNames.KEY_NAME_CLOSED_JUMP]: value },
    }),
    checkMappingField: (data: any) => data.meta && data.meta.hasOwnProperty('jump'),
    mappingData: (data: any) => data.meta?.jump,
  },
};
