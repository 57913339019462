import * as keyNames from '@settings/online-digital-content/survey/config/key-names';
import * as commonComponents from '@base/config/view-field/components';
import * as components from './components';
import { IFieldConfig } from '@base/types/interfaces/common';
import * as commonConfig from '@base/config/view-field';

const viewConfig: IFieldConfig = {
  ...commonConfig?.default,
  [keyNames.KEY_SURVEY_NAME]: {
    component: commonComponents.TextView,
    schema: 'name',
    getRecoilStateValue: (value: any) => {
      return value || '';
    },
  },
  [keyNames.KEY_SURVEY_TITLE]: {
    component: commonComponents.TextView,
    schema: 'title',
  },
  [keyNames.KEY_SURVEY_DESCRIPTION]: {
    component: commonComponents.TextAreaView,
    schema: 'description',
  },
  [keyNames.KEY_SURVEY_TYPE]: {
    component: commonComponents.Selectbox,
    componentProps: {
      options: [],
    },
    schema: '',
  },
  [keyNames.KEY_SURVEY_LANGUAGE]: {
    component: components.LanguageSelectView,
    schema: 'language',
  },
  [keyNames.KEY_SURVEY_STAGE]: {
    component: commonComponents.Selectbox,
    schema: '',
  },
  [keyNames.KEY_SURVEY_TEMPLATE]: {
    component: null,
    schema: '',
  },
  [keyNames.KEY_SURVEY_CONTENT]: {
    component: null,
    schema: `
      isTemplate
      headerImage
      headerLineColor
      bgColor
      sections
    `,
  },
};
export default viewConfig;
