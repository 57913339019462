import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import { Node } from '@settings/process/types/diagram';
import NodeEdit from './NodeEdit';
import classNames from 'classnames';
import { useShowStepActionAtomState } from '@settings/process/recoil/diagram';

interface NodeStatusProps {
  processId: string;
  node: Node;
}

function NodeStatus(props: NodeStatusProps) {
  const {
    id: sourceId,
    data: { label },
    className,
    position,
    type: nodeType,
    shape,
  } = props.node;
  const [showStepAction, setStepShowAction] = useShowStepActionAtomState();

  const onShowAction = useCallback(() => {
    setStepShowAction((old) => {
      return { id: sourceId, show: !old.show };
    });
  }, []);

  const matchShow = useMemo(() => {
    if (showStepAction.id == sourceId && showStepAction.show) {
      return true;
    } else {
      return false;
    }
  }, [showStepAction, sourceId]);

  return (
    <div
      className={classnames('diagram-item-wrap', className, {
        active: matchShow,
      })}
      onClick={onShowAction}
    >
      <div className={classNames('diagram-item diagram-status', className)}>
        <div className="diagram-item-name">{label}</div>
        {matchShow && (
          <NodeEdit
            processId={props.processId}
            sourceId={sourceId}
            property={'PROPERTY_CLOSE'}
            position={position}
            sourceType={nodeType}
            shape={shape}
          />
        )}
      </div>
    </div>
  );
}

export default NodeStatus;
