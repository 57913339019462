import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { NoData, SpanLang } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { SETTING_SELECTION_FIELD_UPDATE, UPDATE_MENU_SETTING } from '@settings/general-setting/services/graphql/format';
import { useSetRecoilState } from 'recoil';
import { savingPreferencesAtom } from '@settings/preferences/recoil/atoms';
import { useTranslation } from 'react-i18next';
import { PRIORITIES_CLASS } from '@base/config/constant';

const ActivityPriority: React.FC<any> = (props) => {
  const { priorityData } = props;
  const { t } = useTranslation();
  //state
  const setSavingOption = useSetRecoilState(savingPreferencesAtom);
  const [priorityItems, setPriorityItems] = useState<any>([]);
  const [debPriorityItems, setDebPriorityItems] = useState<any>([]);
  const setPriorityItemsDebounced = useRef(
    _.debounce((newItems) => setDebPriorityItems(newItems), 1000),
  ).current;
  const defaultPriorityValue: any = { languageKey: '', priority: '', isDefault: false, active: false };
  const initialPriorityLoad = useRef<any>(false);

  //initial data
  useEffect(() => {
    if (priorityData) {
      if (JSON.stringify(priorityData) !== JSON.stringify(priorityItems)) {
        setPriorityItems(priorityData);
      }
    } else {
      setPriorityItems([]);
    }
  }, [priorityData]);

  //mutation
  const mUpdate: any = useMutationPost(SETTING_SELECTION_FIELD_UPDATE, 'setting_updateSelection');

  //set saving option
  useEffect(() => {
    setSavingOption((curOption: any) => ({ ...curOption, isLoading: mUpdate.isLoading }));
  }, [mUpdate.isLoading]);

  //debounced saving for input text
  useEffect(() => {
    if (initialPriorityLoad?.current) {
      handleSaveDebounce(debPriorityItems);
    } else {
      initialPriorityLoad.current = true;
    }
    () => {
      initialPriorityLoad.current = false;
    };
  }, [debPriorityItems]);

  //save item
  const handleSave = (newItems: any) => {
    const newDefaultItem = newItems.find((_ele: any) => _ele.isDefault === true);
    const params: any = {
      id: newDefaultItem.id,
      isDefault: true,
      keyGroup: newDefaultItem.keyGroup
    };
    mUpdate.mutate({ selection: params });
  };
  //debounce function
  const handleSaveDebounce = useCallback(_.debounce(handleSave, 500), [priorityItems]);

  //value change
  const handleValueChange = (index: number, keyName: string, keyValue: string) => {
    const newItems = [...priorityItems];
    newItems[index][keyName] = keyValue;
    if (keyName === 'isDefault') {
      //reset other to false
      newItems.map((_item: any, _index: number) => {
        if (index !== _index) {
          _item.isDefault = false;
        }
      });
    }
    setPriorityItems(newItems);
    setPriorityItemsDebounced(newItems); //for saving...
  };

  //phones list
  const PrioritiesRender = useMemo(() => {
    return (
      <>
        {priorityItems.length === 0 && <NoData />}
        {priorityItems.length > 0 && (
          <div className="table-responsive bd rounded">
            <table className="table table-bordered mg-b-0 bd-0 hover-delete">
              <thead>
                <tr>
                  <th scope="col" className="text-nowrap">
                    Priority
                  </th>
                  <th scope="col" className="text-nowrap">
                    Default
                  </th>
                </tr>
              </thead>
              <tbody>
                {priorityItems.map((_item: any, index: number) => (
                  <tr key={index}>
                    <td className="align-middle">
                      <span className={`badge badge-priority priority-${PRIORITIES_CLASS[_item.keyName]}`}>
                        <SpanLang keyLang={_item.languageKey} />
                      </span>
                    </td>
                    <td className="align-middle">
                      <div className="d-flex align-items-center">
                        <div className="custom-control custom-radio mg-l-15">
                          <input
                            type="radio"
                            id={'priorityRadio' + index}
                            name="activityPriorityRadio"
                            className="custom-control-input"
                            checked={_item.isDefault}
                            onChange={(e: any) =>
                              handleValueChange(index, 'isDefault', e.target.checked)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={'priorityRadio' + index}
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }, [priorityItems]);

  return (
    <div className="pos-relative pd-10">
      {PrioritiesRender}
    </div>
  );
};

export default ActivityPriority;
