// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navata-manage-group .btn-add svg{color:#007bff !important;fill:transparent !important;width:14px !important;height:14px !important}.navata-manage-group .btn-edit svg{color:#000 !important;fill:transparent !important;width:14px !important;height:14px !important}.navata-manage-group .btn-delete svg{color:#dc3545 !important;fill:transparent !important;width:14px !important;height:14px !important}", "",{"version":3,"sources":["webpack://./src/settings/general-setting/containers/tree-folder/group/styles.scss"],"names":[],"mappings":"AAEI,kCACE,wBAAA,CACA,2BAAA,CACA,qBAAA,CACA,sBAAA,CAIF,mCACE,qBAAA,CACA,2BAAA,CACA,qBAAA,CACA,sBAAA,CAIF,qCACE,wBAAA,CACA,2BAAA,CACA,qBAAA,CACA,sBAAA","sourcesContent":[".navata-manage-group {\n  .btn-add {\n    svg {\n      color: #007bff !important;\n      fill: transparent !important;\n      width: 14px !important;\n      height: 14px !important;\n    }\n  }\n  .btn-edit {\n    svg {\n      color: #000 !important;\n      fill: transparent !important;\n      width: 14px !important;\n      height: 14px !important;\n    }\n  }\n  .btn-delete {\n    svg {\n      color: #dc3545 !important;\n      fill: transparent !important;\n      width: 14px !important;\n      height: 14px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navata-manage-group": "navata-manage-group",
	"btn-add": "btn-add",
	"btn-edit": "btn-edit",
	"btn-delete": "btn-delete"
};
export default ___CSS_LOADER_EXPORT___;
