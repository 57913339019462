import { slideToMapByKey } from '@base/utils/helpers/array-utils';

export const parseFields = (layoutView: any, menuApi: string) => {
  let fields: any[] = [];
  if (layoutView?.data && layoutView?.data[0] && layoutView?.data[0]?.children) {
    if (menuApi == 'na') {
      fields = [...layoutView?.data[0]?.children, ...layoutView?.data[1]?.children];
    } else {
      fields = [...layoutView?.data[0]?.children];
    }
  }
  if (fields.length == 0) {
    return layoutView;
  }
  const mapFields = slideToMapByKey(fields, 'keyName');
  if (mapFields == false) {
    return layoutView;
  }

  if (menuApi == 'site') {
    fields = parseFieldsAR(mapFields, fields);
    let tmp = { ...layoutView };
    let tmpData = [...tmp.data];
    tmpData[0] = { ...tmpData[0], children: fields };
    tmp = { ...tmp, data: tmpData };
    return tmp;
  }
};

export const parseFieldsAR = (mapFields: any, fields: any) => {
  let newFields: any[] = [];

  let field = { ...mapFields['subject'] };

  newFields.push(field);

  field = { ...mapFields['code'] };
  field.languageKey = 'Ticket No';
  newFields.push(field);

  field = { ...mapFields['customer'] };
  //field.languageKey = 'Customer';
  newFields.push(field);

  field = { ...mapFields['contact'] };
  //field.languageKey = 'Contact';
  newFields.push(field);

  field = { ...mapFields['category'] };
  //field.languageKey = 'Category';
  newFields.push(field);

  field = { ...mapFields['assignedUser'] };
  //field.languageKey = 'Category';
  newFields.push(field);

  field = { ...mapFields['createdAt'] };
  //field.languageKey = 'CreatedAt';
  newFields.push(field);

  field = { ...mapFields['firstRespondDue'] };
  //field.languageKey = 'firstRespondDue';
  newFields.push(field);

  field = { ...mapFields['resolutionDue'] };
  //field.languageKey = 'Resolved on';
  newFields.push(field);

  field = { ...mapFields['closedAt'] };
  //field.languageKey = 'closedAt';
  newFields.push(field);

  return newFields;
};

export const parseFieldsList = (layoutList: any, menuApi: string) => {
  let fields: any[] = [];
  if (layoutList?.data && layoutList?.data[0]) {
    fields = [...layoutList?.data];
  }
  if (fields.length == 0) {
    return layoutList;
  }
  const mapFields = slideToMapByKey(fields, 'keyName');
  if (mapFields == false) {
    return layoutList;
  }

  if (menuApi == 'assignment-rule') {
    fields = parseFieldsAR(mapFields, fields);
    let tmp = { ...layoutList };

    tmp = { ...tmp, data: fields };
    return tmp;
  }
};
