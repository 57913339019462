import React, { useState, useEffect, useCallback, Fragment, useMemo } from 'react';
import _ from 'lodash';
//import d2i from "dom-to-image";
//import { dataURLtoFile, toDataURL } from '../../utils/ImageUtils';
import { storeSurveyToStorage } from '@settings/online-digital-content/survey/services/storage';
import { generateUUID } from '@base/utils/helpers';

import SurveyTitle from '../survey-title';
import SurveyToolbar from '../survey-toolbar';
import Question from '../question';

/**
 *
 * @param {*} props
 */
const QuestionWrite: React.FC<any> = (props) => {
  const {
    storageOn = false,
    questionRef,
    surveyId,
    surveyHeaderImg,
    surveyHeadLineColor,
    surveyBgColor,
    surveyImage, //TODO
    value, //surveySections
    onChange, //onSetSurveySections
  } = props;

  //state
  const [focusS, setFocusS] = useState<number>(0); //focus section
  const [focusQ, setFocusQ] = useState<number>(0); //focus question
  const [surveyS, setSurveyS] = useState<any>([]); //all sections
  const [focusEle, setFocusEle] = useState<any>(null);

  //set init data for survey
  useEffect(() => {
    //// console.log('init sections', value);
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(surveyS)) {
        setSurveyS(value);
      }
    } else {
      setSurveyS([]); //TODO: init default value
    }
  }, [value]);

  //handle mouseup to check element event click and focus
  useEffect(() => {
    document.addEventListener('mouseup', handleEleClick);
    return () => {
      document.removeEventListener('mouseup', handleEleClick);
    };
  }, []);

  //handle saving when survey content changes
  useEffect(() => {
    if (storageOn) {
      //auto save for text content
      const surveyData = {
        id: surveyId,
        headerImg: surveyHeaderImg || '',
        headerLineColor: surveyHeadLineColor,
        bgColor: surveyBgColor,
        //image: surveyImage,
        sections: surveyS,
      };
      onSaveSurveyDebounce(_.cloneDeep(surveyData));
    }
  }, [surveyS, surveyHeadLineColor]);

  //indexeddb store
  async function storeSurvey(data: any) {
    return await storeSurveyToStorage(data);
  }

  //debounce save survey
  const onSaveSurvey = (surveyData: any) => {
    // console.log('handle indexeddb saving..... ', surveyData);
    //save to indexeddb
    storeSurvey(surveyData);
  };
  //debounce function
  const onSaveSurveyDebounce = useCallback(_.debounce(onSaveSurvey, 1000), []);

  //add question when focusing on child (question) component
  function handleEleClick(e: any) {
    const el = e.path;
    //// console.log('el', el);

    //handle focusing
    let surveyEleId = el[0].id ? el[0].id : el[1].id;
    surveyEleId = surveyEleId ? surveyEleId : el[2].id;
    //surveyEleId = surveyEleId ? surveyEleId : el[3].id;
    //// console.log('surveyEleId', surveyEleId);

    //format: q-optinput-0-5-1 --> 0: section index, 5: question index, 1: option index
    if (surveyEleId) {
      const items = surveyEleId.split('-');
      const nameEle = items[0] + '-' + items[1];
      const elKeyS = parseInt(items[2]);
      const elKeyQ = parseInt(items[3]); //idx

      //for input element
      if (
        nameEle === 'survey-title' ||
        nameEle === 'survey-desc' ||
        //nameEle === 'q-optinput' ||
        nameEle === 'q-rowopt'
      ) {
        setFocusEle(surveyEleId); //focus on element (eg: input)
        setFocusS(elKeyS); //focus on section
        setFocusQ(elKeyQ); //focus on question
      }
    } else {
      //setFocusEle('');
    }
  }

  //change focus to section
  function handleFocusQChange(keyS: any, keyQ: any) {
    setFocusS(keyS);
    setFocusQ(keyQ);
  }

  //add new question to section
  function handleAddOptionRow(e: any) {
    e.preventDefault();
    if (focusS !== undefined) {
      const newSurveyS: any = [...surveyS];
      const newOptionRow = {
        id: generateUUID(),
        value: '',
        isOther: false
      };
      newSurveyS[focusS].questions[0].options.rows.push(newOptionRow);
      setSurveyS(newSurveyS);
      //callback
      onChange && onChange(newSurveyS);
    }
  }

  //survey value change: title, description
  const handleSurveyChange = (indexS: number, keyName: string, keyValue: any) => {
    const newSurveyS = [...surveyS];
    newSurveyS[indexS][keyName] = keyValue;
    setSurveyS(newSurveyS);
    //callback
    onChange && onChange(newSurveyS);
  };

  //question content change
  const handleQuestionChange = (indexS: number, indexQ: number, newQuestion: any) => {
    const newSurveyS = [...surveyS];
    newSurveyS[indexS].questions[indexQ] = newQuestion;
    setSurveyS(newSurveyS);
    //callback
    onChange && onChange(newSurveyS);
  };

  /**====================== RENDER ==================================*/
  //render questions
  const SurveyQuestions = (props: any) => {
    const { surveyQ, keyS, totalS } = props;
    //// console.log('surveyQ write', surveyQ);
    return (
      <div className="survey-write-tab-content">
        {/* first section: title+desc */}
        <SurveyTitle
          headLineColor={surveyHeadLineColor}
          keyS={keyS}
          totalS={totalS}
          focusQ={focusQ}
          focusS={focusS}
          focusEle={focusEle}
          onFocusQuestionChange={handleFocusQChange}
          surveyQ={surveyQ}
          onSurveyChange={(keyName: string, keyValue: any) =>
            handleSurveyChange(keyS, keyName, keyValue)
          }
        />
        {/* render questions */}
        {surveyQ.questions.map((_question: any, index: number) => (
          <Fragment key={index}>
            <Question
              keyS={keyS}
              keyQ={index}
              focusQ={focusQ}
              focusS={focusS}
              focusEle={focusEle}
              value={_question}
              onChange={handleQuestionChange}
              setFocusEle={setFocusEle}
              onFocusQuestionChange={handleFocusQChange}
            />
          </Fragment>
        ))}
      </div>
    );
  };

  //Survey sections
  const SurveySections = useMemo(() => {
    return (
      <>
        {surveyS.map((_section: any, idx: number) => (
          <div key={idx} className="pd-b-30" ref={idx === 0 ? questionRef : null}>
            <SurveyQuestions surveyQ={_section} keyS={idx} totalS={surveyS.length} />
          </div>
        ))}
      </>
    );
  }, [surveyS, focusS, focusQ, focusEle, surveyHeadLineColor]);

  //// console.log('state surveyS', surveyS);
  //main render
  return (
    <div>
      <SurveyToolbar
        focusS={focusS}
        focusQ={focusQ}
        onAddNewOptionRow={handleAddOptionRow}
      />

      {/* header image */}
      {surveyHeaderImg && (
        <div className="survey-write-header-card">
          <img src={surveyHeaderImg} className="survey-write-header-image" />
        </div>
      )}

      {/* sections, capture for section 1 */}
      {SurveySections}
    </div>
  );
};

export default QuestionWrite;
