import React, { useEffect, useState } from 'react';
import TextView from '@base/containers/view-field/text/view';
import { convertDateTimeServerToClient } from '@base/utils/helpers/general.utils';
import {
  LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH,
  PUBLISH_SCHEDULE_OPTIONS,
} from '@settings/online-digital-content/landing-page/config/constants';

const View: React.FC = (props: any) => {
  const { value } = props;
  const [valueString, setValueString] = useState('');

  useEffect(() => {
    let _string = PUBLISH_SCHEDULE_OPTIONS[0]?.label;
    if (value?.publish != LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH) {
      _string =
        'Publish ' +
          convertDateTimeServerToClient({
            date: value?.publishDate,
            isTime: true,
            humanize: true,
          }) ?? '';
    }
    setValueString(_string);
  }, [value]);

  return <TextView value={valueString} />;
};

export default View;
