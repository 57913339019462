import {
  KEY_NAME_SETTING_DESCRIPTION,
  KEY_NAME_SETTING_NAME,
} from '@settings/process/config/keyNames';
import { Input, TextArea } from '@base/config/write-field/components';
import validators from '@base/utils/validation/field-validator';

export const TriggerWriteField = {
  [KEY_NAME_SETTING_NAME]: {
    component: Input,
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    languageKey: 'process_trigger_name',
    parseParam: (value: string) => value,
  },
  // [KEY_NAME_SETTING_TRIGGER_TYPE]: {
  //   component: SelectBox,
  //   showFullRow: true,
  //   validate: {
  //     required: validators.required,
  //   },
  //   defaultValue: TRIGGER_TYPE_OPTIONS[0],
  //   languageKey: 'process_trigger_type',
  //   componentProps: {
  //     options: TRIGGER_TYPE_OPTIONS,
  //   },
  //   parseParam: (value: any) => value.keyName,
  // },
  [KEY_NAME_SETTING_DESCRIPTION]: {
    component: TextArea,
    showFullRow: true,
    defaultValue: '',
    languageKey: 'process_trigger_description',
    parseParam: (value: string) => value,
  },
};

export default TriggerWriteField;
