import React, { useEffect } from 'react';
import MergeField from '@settings/general-setting/containers/merge-field';

export type personalizeProps = {};

function PersonalizePage({}: personalizeProps) {
  return (
    <div className="ht-100p pd-15 overflow-auto general-setting-container">
      <div className="card-columns settings-columns">
        <MergeField />
      </div>
    </div>
  );
}

export default PersonalizePage;
