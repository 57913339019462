import * as keyNames from '@settings/assignment-rule/config/key-names';
import * as commonComponents from '@base/config/view-field/components';
import * as components from '@settings/assignment-rule/config/view-field/components';
import { AR_MODULE_OPTIONS } from '../constant';
import { EAssignmentRuleModule } from '@settings/assignment-rule/types/enums';
import { IFieldConfig } from '@base/types/interfaces/common';
import { parserIdNameToSelect, parserSelectToIdNameObj } from '@base/utils/helpers/config-utils';

const configView: IFieldConfig = {
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_NAME]: {
    languageKey: 'Name',
    showFullRow: true,
    component: commonComponents.TextView,
    getValueView: (value: string) => value,
    getValueEdit: (value: string) => value,
    getMutationValue: (value: string) => ({
      [keyNames.KEY_NAME_ASSIGNMENT_RULE_NAME]: value,
    }),
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_DESC]: {
    languageKey: 'Description',
    showFullRow: true,
    component: commonComponents.TextAreaView,
    componentProps: {},
    getValueView: (value: string) => value,
    getValueEdit: (value: string) => value,
    getMutationValue: (value: string) => ({
      [keyNames.KEY_NAME_ASSIGNMENT_RULE_DESC]: value,
    }),
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_MODULE]: {
    languageKey: 'Module',
    showFullRow: true,
    component: commonComponents.Selectbox,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
    componentProps: {
      fieldValue: 'keyName',
      fieldLabel: 'languageKey',
      options: AR_MODULE_OPTIONS,
    },
    getValueView: (value: any) => {
      var moduleSelect = '';
      AR_MODULE_OPTIONS.map((m) => {
        if (value[0] == m.keyName) {
          moduleSelect = m.languageKey;
        }
      });
      return moduleSelect;
    },
  },
  /*[keyNames.KEY_NAME_ASSIGNMENT_RULE_MODULE]: {
    languageKey: 'Module',
    showFullRow: true,
    component: commonComponents.Checkbox,
    componentProps: {
      fieldValue: 'keyName',
      fieldLabel: 'languageKey',
      options: AR_MODULE_OPTIONS,
    },
    getValueView: (value: any) => {
      return value.map((m: string) => {
        return m == EAssignmentRuleModule.CUSTOMER ? AR_MODULE_OPTIONS[0] : AR_MODULE_OPTIONS[1];
      });
    },
    getValueEdit: (value: any) => {
      return value.map((m: string) => {
        return m == EAssignmentRuleModule.CUSTOMER ? AR_MODULE_OPTIONS[0] : AR_MODULE_OPTIONS[1];
      });
    },
    getMutationValue: (value: any) => {
      // console.log('getMutationValue', value);
      let module =
        value.length > 0
          ? value.map((v: any) => {
              return v.keyName;
            })
          : [];
      return {
        module,
      };
    },
  },*/
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_CRITERIA]: {
    languageKey: 'Criteria',
    showFullRow: true,
    component: components.Criteria,
    schema: `criteria {
      key
      condition
    }`,
    getValueView: (value: any) => {
      // console.log('Criteria', value);
      return value ?? [];
    },
    getValueEdit: (value: any) => {
      return value ?? [];
    },
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_ASSIGNS_TO]: {
    languageKey: 'Assign To',
    showFullRow: true,
    component: components.AssignTo,
    componentProps: {},
    viewProps: {
      userPermission: {
        isEdit: false,
      },
    },
    getValueView: (value: any) => {
      return value;
    },
    getValueEdit: (value: any) => {
      return value;
    },
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_CREATED_BY]: {
    languageKey: 'Created By',
    schema: `createdBy{
        id
        name
        fullName
    }`,
    getValueView: (value: any) => {
      return value?.fullName;
    },
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_ACTIVE]: {
    component: components.Switch,
    schema: keyNames.KEY_NAME_ASSIGNMENT_RULE_ACTIVE,
  },
  [keyNames.KEY_NAME_ASSIGNMENT_RULE_ENTRIES]: {
    viewProps: {
      notShowTitle: true,
    },
    languageKey: 'Rule Entries',
    showFullRow: true,
    component: components.RuleEntry,
    schema: `
    rulesEntry {
      id
      order
      assignRuleId
       criteria {
      key
      condition
    }
    assignTo {
      mode
      type
      assignsTo {
        user {
          id
          name
        }
        group {
          id
          name
        }
      }
      capacity
      online
      baseOnWorkDay
    }
    }`,
    getValueView: (value: any) => {
      // console.log('Rule Entries', value);
      return value ?? [];
    },
  },
};

export default configView;
