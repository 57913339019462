import { lazy } from 'react';

export const Input = lazy(() => import('@base/components/form/input'));
export const TextArea = lazy(() => import('@base/components/form/text-area'));
export const RadioGroup = lazy(() => import('@base/components/form/radio-group'));
export const CodeGenerator = lazy(() => import('@base/containers/code-generator'));
export const StaticSelect = lazy(() => import('@base/components/form/static-select'));
export const UserAutoComplete = lazy(() => import('@base/containers/user-auto-complete'));
export const ProductAutoComplete = lazy(
  () => import('@product/product/containers/product-auto-complete'),
);
export const Switch = lazy(() => import('@base/components/form/switch'));
export const Select = lazy(() => import('@base/components/form/select'));
export const Address = lazy(() => import('@customer/customer/components/address'));
export const Phone = lazy(() => import('@customer/customer/components/phone'));

export const AssignRepCheck = lazy(() => import('@base/containers/assign-rep-check'));
export const LanguageSelect = lazy(() => import('@base/containers/language-select'));
export const LandingPageTemplateSelect = lazy(
  () =>
    import('@settings/online-digital-content/landing-page/containers/landing-page-template-select'),
);
export const TemplateDesign = lazy(
  () => import('@settings/online-digital-content/landing-page/components/template-design'),
);
export const GrapesTS = lazy(() => import('@base/components/grapes-ts'));
export const LandingPageTypeSelect = lazy(
  () => import('@settings/online-digital-content/landing-page/containers/landing-page-type-select'),
);
export const DatePicker = lazy(() => import('@base/components/form/date-selector'));
