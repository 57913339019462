import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Control, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import StatusList from './StatusList';
import { KEY_NAME_STEP_ACTION } from '@settings/process/config/keyNames';
import { StatusForm } from '@settings/process/types/process';
import { STATUS_BASIC_DATA } from '@settings/process/config/constants';
import SiteButtons from '@settings/process/components/diagram/site/SiteButtons';
import { statusWithFilter } from '@settings/process/recoil/status';
import stepTypeAtom from '@settings/process/recoil/step';
import { checkParallel } from '@settings/process/utils/helper';
import { useStepSettingAtomValue } from '@settings/process/recoil/process';
import ItemWrite from './StatusItemWrite';

export const sample = `
    Hello, $$Customer$$
    Thank you for using our service.
    plz click following button.

    <a name="Product" href="@@CTA.PAGE=122344444@@">Go to Product</a>
    <a name="Customer" href="@@CTA.PAGE=999999999@@">Go to Customer</a>
`;

export const initStatusForm: StatusForm[] = [
  STATUS_BASIC_DATA({
    id: uuidv4(),
    button: '-',
    name: '-',
    view: 'VIEW_DISABLE',
    event: 'EVENT_DISABLE',
    property: 'PROPERTY_TODO',
    direction: 'DIRECTION_FORWARD_INCOMING_MIDDLE',
    sequence: ['0'],
    definedId: uuidv4(),
  }),
  STATUS_BASIC_DATA({
    id: uuidv4(),
    button: '-',
    name: 'Doing',
    view: 'VIEW_DISABLE',
    event: 'EVENT_DISABLE',
    property: 'PROPERTY_TODO_DOING',
    direction: 'DIRECTION_STAYING',
    sequence: ['1'],
    definedId: uuidv4(),
  }),
  STATUS_BASIC_DATA({
    id: uuidv4(),
    button: 'Done',
    name: 'Done',
    view: 'VIEW_SINGLE',
    event: 'EVENT_CLICK',
    property: 'PROPERTY_TODO_CLOSE',
    direction: 'DIRECTION_FORWARD_OUTGOING_FORWARD',
    sequence: ['2'],
    definedId: uuidv4(),
  }),
];

interface StatusWriteProps {
  process: string;
  control: Control;
}

function StatusWrite(props: StatusWriteProps) {
  const { process: processId, control } = props;
  const stepType = useRecoilValue(stepTypeAtom);
  const stepSetting = useStepSettingAtomValue();
  const normalStatuses = useRecoilValue(statusWithFilter('normal'));
  const ctaStatuses = useRecoilValue(statusWithFilter('cta'));

  const selectedAction = useWatch({
    control,
    name: KEY_NAME_STEP_ACTION,
  });

  let normalParallelIndex = 0;
  let ctaParallelIndex = 0;

  return (
    <>
      <div className="form-group">
        <label className="form-item-title">Buttons/Status</label>
        <StatusList isCta={false} actionMethod={selectedAction?.method}>
          {normalStatuses?.map((status) => {
            if (checkParallel(status)) {
              normalParallelIndex++;
            }
            return (
              <ItemWrite
                key={status.id}
                processId={processId}
                status={status}
                parallelIndex={normalParallelIndex}
                isView={false}
                isCta={false}
                actionMethod={selectedAction?.method}
              />
            );
          })}
        </StatusList>
      </div>
      {(stepSetting.cta || stepType.value == 'TYPE_SITE') && (
        <>
          <div className="form-group">
            <label className="form-item-title">CTA</label>
            <StatusList isCta={true}>
              {ctaStatuses.map((status) => {
                if (checkParallel(status)) {
                  ctaParallelIndex++;
                }
                return (
                  <ItemWrite
                    key={status.id}
                    processId={processId}
                    status={status}
                    parallelIndex={ctaParallelIndex}
                    isView={false}
                    isCta={true}
                  />
                );
              })}
            </StatusList>
          </div>
          {stepType.value == 'TYPE_SITE' && <SiteButtons mode={'write'} />}
        </>
      )}
    </>
  );
}

export default StatusWrite;
