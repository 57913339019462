import { lazy } from 'react';

const TaskMain = lazy(() => import('./main'));

export default [
  {
    path: '/settings/task/*',
    component: TaskMain,
  },
];
