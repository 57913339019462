import {
  Disc,
  CheckCircle,
  Pocket,
  AlignLeft,
  Minus,
  Upload,
  List,
  Grid,
  Clock,
  Calendar,
} from 'react-feather';

//constant
export const Q_MULTI_CHOICES = 1;
export const Q_CHECKBOXES = 2;
export const Q_DROPDOWN = 3;
export const Q_SHORT_ANSWER = 4;
export const Q_PARAGRAPH = 5;
export const Q_FILE_UPLOAD = 6;
export const Q_DATE = 7;
export const Q_TIME = 8;
export const Q_MULTI_CHOICES_GRID = 9;
export const Q_TICK_BOX_GRID = 10;
export const Q_TITLE = 11;
export const Q_IMAGE = 12;
export const Q_VIDEO = 13;

export const QUESTION_TYPES = [
  {
    type: Q_MULTI_CHOICES,
    languageKey: 'Multiple choices',
    icon: Disc,
  },
  {
    type: Q_CHECKBOXES,
    languageKey: 'Checkboxes',
    icon: CheckCircle,
  },
  {
    type: Q_DROPDOWN,
    languageKey: 'Dropdown',
    icon: Pocket,
  },
  {
    type: Q_SHORT_ANSWER,
    languageKey: 'Short answer',
    icon: Minus,
  },
  {
    type: Q_PARAGRAPH,
    languageKey: 'Paragraph',
    icon: AlignLeft,
  },
  // {
  //   type: Q_FILE_UPLOAD,
  //   languageKey: 'File upload',
  //   icon: Upload,
  // },
  {
    type: Q_DATE,
    languageKey: 'Date',
    icon: Calendar,
  },
  {
    type: Q_TIME,
    languageKey: 'Time',
    icon: Clock,
  },
  {
    type: Q_MULTI_CHOICES_GRID,
    languageKey: 'Multi choices grid',
    icon: List,
  },
  {
    type: Q_TICK_BOX_GRID,
    languageKey: 'Tick box grid',
    icon: Grid,
  },
];
