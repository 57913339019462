import React, { useState } from 'react';
import { Select } from '@base/components/form';
import { useListCurrencies } from '@settings/general-setting/services/format-service';
import { useGetList } from '@base/services/list';
import { FilterInput, ISearchFields } from '@base/types/interfaces/common';
import { ParseSearchFieldsToQuery } from '@base/utils/helpers/advanced-search-query';

/**
 * @param {*} props
 * @returns
 */
interface ICurrencySelect {
  onChange: (params: any) => void;
}
const CurrencySelect: React.FC<ICurrencySelect> = (props: ICurrencySelect) => {
  const { onChange } = props;

  //state
  const [keyword, setKeyword] = useState('');
  //const { data, isLoading } = useListCurrencies(keyword);
  //B new searchFilter

  let searchFields: ISearchFields[] = [
    {
      key: 'code',
      type: 'string',
      label: 'Code',
      value: '', //AED
    },
    {
      key: 'currencyName',
      type: 'text',
      label: 'Currency Name',
      value: '',
    },
    {
      key: 'currencySymbol',
      type: 'string',
      label: 'Currency Symbol',
      value: '',
    },
  ];
  let schemas: string[] = ['code', 'currencyName', 'currencySymbol'];
  let listQuerySchema = schemas.join('\n');
  let filtersQuery: FilterInput = {
    query: ParseSearchFieldsToQuery(searchFields), //code=AED
  };
  const { isLoading, data, isFetching, isPreviousData, refetch } = useGetList<any[]>(
    'setting_availableCurrencies',
    listQuerySchema,
    filtersQuery,
  );
  //E new searchFilter

  // console.log('CurrencySelect', data);
  const placeholder = 'Select...';
  const onInputChange = (newValue: string, event: any) => {
    // console.log('onInputChange', newValue);
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setKeyword(newValue);
    }
  };
  //render
  return (
    <Select
      onChange={onChange}
      isSearchable={true}
      isLoading={isLoading}
      placeholder={<div className="react-select-placeholder">{placeholder}</div>}
      options={data?.data ?? []}
      //options={data?.data ?? []}
      getOptionLabel={(c: any) => {
        return c.currencyName;
      }}
      getOptionValue={(c: any) => {
        return c;
      }}
      inputValue={keyword}
      onInputChange={onInputChange}
    />
  );
};

export default CurrencySelect;
