import React from 'react';
import classnames from 'classnames';
import LinkUrl from '@settings/online-digital-content/cta/components/link-url';

const Edit: React.FC = (props: any) => {
  const { value, errors, componentProps, onChange } = props;

  return (
    <div>
      <div className={classnames({ 'is-required': errors.isRequired })}>
        <LinkUrl {...componentProps} value={value} onChange={onChange} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </div>
  );
};

export default Edit;
