import { FormIcon } from '@base/components/form';
import classNames from 'classnames';
import React, { useState } from 'react';

interface ICollapseProps {
  isShowInit: boolean;
  label: string | any;
  children: any;
  isBorderTop?: boolean;
  total: number;
  note?: string;
}
const Collapse: React.FC<ICollapseProps> = (props: ICollapseProps) => {
  const {
    isShowInit = true,
    label = '',
    children = null,
    isBorderTop = true,
    total = 0,
    note = '',
  } = props;
  const [isShow, setIsShow] = useState(isShowInit);
  return (
    <div>
      <div
        className={classNames('d-flex align-items-center bd-b', {
          'bd-t': isBorderTop,
        })}
      >
        <div
          className="ht-55 d-flex align-items-center pd-10 align-items-center"
          style={{ flex: 1 }}
        >
          {label}
          {total > 0 && <span className="badge badge-pill badge-primary mg-4">{total}</span>}
          {note && <div className="tx-12 tx-danger">{note}</div>}
        </div>
        <i className="pd-x-10" onClick={() => setIsShow(!isShow)}>
          <FormIcon icon={isShow ? 'ChevronDown' : 'ChevronRight'} iconType="feather" size={16} />
        </i>
      </div>
      <div>{isShow && children}</div>
    </div>
  );
};

export default Collapse;
