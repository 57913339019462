import { gql } from 'graphql-request';

export const GET_MERGE_FIELDS = gql`
  query {
    setting_mergeFields {
      results {
        id
        menuTag
        fieldTag
        fixed
        replace
      }
    }
  }
`;

export const CREATE_MERGE_FIELD = gql`
  mutation q($mergeField: MergeField!) {
    setting_createMergeField(mergeField: $mergeField) {
      id
    }
  }
`;

export const DELETE_MERGE_FIELD = gql`
  mutation q($id: String!) {
    setting_deleteMergeField(id: $id) {
      id
    }
  }
`;
