import React from 'react';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { useRecoilValue } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms';
import displayLayoutConfig from '@settings/template/config/display-layout';
import { WriteParseFields } from '@base/utils/helpers/no-layout-utils';

interface IWriteFormPageProps {
  onClose: () => void;
  templateType: string;
  templateGroup: string;
}
const WriteFormPage: React.FC<any> = (props: IWriteFormPageProps) => {
  const deviceState = useRecoilValue(deviceAtom);
  const defaultValue = { templateGroup: props.templateGroup };
  let writeConfigKey = `template_${props.templateGroup}`;
  const { fields, getParams, defaultValues } = WriteParseFields(writeConfigKey, defaultValue);
  // console.log('WriteFormPage', fields, getParams, defaultValues, props);
  return (
    <>
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={deviceState.device}
        layout={deviceState.layout}
        form={'write'}
        {...props}
        fields={fields}
        defaultValues={defaultValues}
        getParams={getParams}
      />
    </>
  );
};

export default WriteFormPage;
