import React, { useState } from 'react';
import { WriteParseFields } from '@base/utils/helpers/no-layout-utils';
import { useQueryClient } from '@tanstack/react-query'; //v4
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { MENU_SETTING_ASSIGNMENT_RULE_ENTRY } from '@base/config/menus';
import {
  CREATE_ASSIGNMENT_RULE_ENTRY,
  UPDATE_ASSIGNMENT_RULE_ENTRY,
} from '@settings/assignment-rule/services/graphql';
import { finalizeParams } from './payload';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { Button } from '@base/components/form';
import { ChevronUp } from 'react-feather';
import { Field } from '@base/components/hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';

interface IARWriteProps {
  onClose: () => void;
  assignRuleId: string;
  fullScreen: boolean;
  showCanvas: boolean;
  defaultValue?: any;
}

const AREntryWrite: React.FC<IARWriteProps> = (props: IARWriteProps) => {
  const { onClose, assignRuleId, fullScreen, showCanvas, defaultValue } = props;

  const queryClient = useQueryClient();

  const initValues = { assignRuleId: assignRuleId, assignTo: {}, order: 1 };
  const { fields, defaultValues, getParams } = WriteParseFields(
    MENU_SETTING_ASSIGNMENT_RULE_ENTRY,
    defaultValue
      ? {
          ...defaultValue,
          criteria: defaultValue?.criteria?.map((item: any) => ({
            ...item,
            condition: JSON.parse(item?.condition),
          })),
        }
      : initValues,
  );

  //state
  const [isSaving, setIsSaving] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_ASSIGNMENT_RULE_ENTRY,
    'setting_createAssignmentRuleEntry',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Create Assignment Rule successfully!');
        setIsSaving(false);

        setTimeout(() => {
          queryClient.invalidateQueries(['setting_assignmentRule']);
        }, 500);

        if (!isReset) {
          onClose && onClose();
        } else {
          reset();
        }
      },
      onError: (error: any) => {
        setIsSaving(false);
        // console.log('failed', error);
        toast.success('Create Assignment Rule failed');
      },
    },
  );

  // update mutation
  const mutationUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_ASSIGNMENT_RULE_ENTRY,
    'setting_updateAssignmentRuleEntry',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Update Assignment Rule successfully!');
        setIsSaving(false);

        setTimeout(() => {
          queryClient.invalidateQueries(['setting_assignmentRule']);
        }, 500);

        if (!isReset) {
          onClose && onClose();
        } else {
          reset();
        }
      },
      onError: (error: any) => {
        setIsSaving(false);
        // console.log('failed', error);
        toast.success('Update Assignment Rule failed');
      },
    },
  );

  const onSubmit = (formData: any, isReset: any) => {
    setIsReset(isReset);
    const newParams = getParams(formData);
    let finalParams = finalizeParams(newParams);

    if (defaultValue && defaultValue?.id != '') {
      finalParams.arEntry.id = defaultValue.id;
      mutationUpdate.mutate(finalParams);
    } else {
      mutationAdd.mutate(finalParams);
    }
    console.log('...onSubmit...', finalParams);
  };

  const onError = (errors: any, e: any) => {};

  const renderFields = () => {
    return (
      <div className={classNames('form-row')}>
        {fields.map((_item, _index) => {
          let newComponentProps = { ..._item?.componentProps };
          return (
            <Field
              key={_index}
              item={{
                ..._item,
                componentProps: newComponentProps,
                columns: _item.columns,
                hideTitle: _item.hideTitle, //hide title for a specific key name
              }}
              isHidden={newComponentProps?.isHidden ? true : false}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="d-flex pd-15 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              type="button"
              color="success"
              onClick={() => {
                handleSubmit((data) => onSubmit(data, false), onError)();
              }}
              disabled={isSaving || !isValid}
              loading={isSaving}
              children="Save"
            />
            {/* <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                type="button"
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  handleSubmit((data) => onSubmit(data, true), onError)();
                }}
                disabled={isSaving || !isValid}
                loading={isSaving}
                children="Save and Create New"
              />
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  let formHeight = 'calc(100vh - 280px)';
  if (fullScreen) {
    formHeight = 'calc(100vh - 200px)';
  } else if (showCanvas) {
    formHeight = 'calc(100vh - 135px)';
  }

  console.log('form values', watch()); //get form values when inputing
  return (
    <div className="pd-5">
      {/* <div className="bd-b pd-y-10">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>You can't except a required field</span>
          </span>
        </div>
      </div> */}
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        <div
          className="pos-relative pd-15 scroll-box"
          style={{
            height: formHeight,
            overflowY: 'auto',
            overflowX: 'hidden',
            maxWidth: props?.showCanvas ? 800 : 'auto',
          }}
        >
          {renderFields()}
        </div>
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(AREntryWrite);
