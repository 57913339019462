import React, { memo } from 'react';
import { SpanLang } from '@base/components';
import classNames from 'classnames';
import TextViewField from '@base/containers/view-field/text';
import View from '@base/containers/view-field/text/view';

interface Props {
  menu?: string;
  menuSource?: string;
  menuSourceId?: string;
  fields: any[];
  metadata?: any;
  ignoreFields?: string[];
  refetchFields?: string[];
  column?: number;
  onRefetch?: () => void;
}

const ViewFieldsPreview = (props: Props) => {
  const {
    menu,
    menuSource,
    menuSourceId,
    metadata,
    fields,
    ignoreFields,
    refetchFields = [],
    column = 1,
    onRefetch,
  } = props;
  return (
    <>
      {fields?.map((field: any, index: number) => {
        return (
          <div
            key={field.keyName + index.toString()}
            className={classNames('form-group col-6', {
              'col-6': column == 2,
              'col-12': column == 1,
            })}
          >            
              <label className="form-item-title">
                <SpanLang className="pd-l-0" keyLang={field?.languageKey} />
              </label>
            <View value={'sampe'} />
          </div>
        );
      })}
    </>
  );
};

export default ViewFieldsPreview;
