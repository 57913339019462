import Button from '@base/components/form/button';
import { Field } from '@base/components/hook-form';
import NoteAndError from '@base/components/hook-form/error-note';
import { MENU_PROCESS_AUTOMATION } from '@base/config/menus';
import withMiModal from '@base/hooks/hocs/withMiModal';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { WriteParseFields } from '@base/utils/helpers/no-layout-utils';
import { InstantValue } from '@settings/process/components/criteria-automation/CriteriaAutomation';
import { AUTOMATION_TYPE, INSTANT_ACTION_TYPE } from '@settings/process/config/constants';
import {
  KEY_NAME_AUTOMATION_CRITERIA,
  KEY_NAME_AUTOMATION_TRIGGER,
} from '@settings/process/config/keyNames';
import {
  KEY_GRAPHQL_PROCESS_CREATE_AUTOMATION_RULE,
  KEY_GRAPHQL_PROCESS_UPDATE_AUTOMATION_RULE,
} from '@settings/process/config/queryKeys';
import { useGetAutomationRule } from '@settings/process/hooks/useProcess';
import {
  CREATE_AUTOMATION_RULE,
  UPDATE_AUTOMATION_RULE,
} from '@settings/process/services/automation';
import _ from 'lodash';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { ChevronUp } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface AutomationWriteProps {
  fullScreen: boolean;
  onClose: () => void;
  onReload?: () => void;
  id?: string;
}

function AutomationWrite(props: AutomationWriteProps) {
  const { fullScreen, onClose, onReload, id } = props;
  const [isNew, setIsNew] = useState(false);

  let initValue: any = null;
  if (id) {
    const { data: ruleData } = useGetAutomationRule(id);
    console.log('rule Data', ruleData);
    initValue = useMemo(() => {
      if (ruleData) {
        let newInstants: { [index: string]: InstantValue[] } = {};
        ruleData.instants?.forEach((instant) => {
          if (!(instant.criteria in newInstants)) {
            newInstants[instant.criteria] = [];
          }
          let newInstant: InstantValue = {
            id: instant.id,
            name: instant.name,
            type: { keyName: instant.type, languageKey: INSTANT_ACTION_TYPE[instant.type] },
            template: instant.template && {
              keyName: instant.template.id,
              languageKey: instant.template.name,
            },
          };
          if (instant.type == 'ACTION_TYPE_TASK') {
            newInstant.targetUsers = instant.targets?.map((target) => ({
              value: target.id,
              label: target.name,
            }));
          } else if (instant.type == 'ACTION_TYPE_EMAIL') {
            newInstant.targetCustomers = instant.targets?.map((target) => ({
              value: target.id,
              label: target.name,
            }));
          } else if (instant.type == 'ACTION_TYPE_FIELD_UPDATE') {
            newInstant.field = {
              field: {
                keyName: instant.field?.name!!,
                languageKey: instant.field?.name!!,
                extra: instant.field?.type!!,
              },
              value: instant.field?.value!!,
            };
          } else if (instant.type == 'ACTION_TYPE_OUTBOUND_MESSAGE') {
            newInstant.message = instant.message;
          }
          newInstants[instant.criteria].push(newInstant);
        });

        return {
          name: ruleData.name,
          description: ruleData.description,
          type: { value: ruleData.type, label: AUTOMATION_TYPE[ruleData.type] },
          criteria: {
            criteria: ruleData.criteria && {
              keyName: ruleData.criteria.id,
              languageKey: ruleData.criteria.name,
            },
            instants: newInstants,
          },
          trigger: ruleData.trigger && {
            keyName: ruleData.trigger.id,
            languageKey: ruleData.trigger.name,
            extra: {
              module: ruleData.module,
            },
          },
        };
      } else {
        return {};
      }
    }, [ruleData]);
  }

  const { fields, getParams, defaultValues } = useMemo(
    () => WriteParseFields(MENU_PROCESS_AUTOMATION),
    [],
  );

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  useEffect(() => {
    if (initValue) {
      reset(initValue);
    }
  }, [initValue]);

  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_AUTOMATION_RULE,
    KEY_GRAPHQL_PROCESS_CREATE_AUTOMATION_RULE,
    {
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Create Automation Rule successfully!');
        if (onReload) {
          setTimeout(() => {
            onReload();
          }, 500);
        }
        reset();

        if (!isNew) {
          onClose && onClose();
        }
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.warn('Create Automation Rule failed');
      },
    },
  );

  const mutationEdit: any = useMutationPost<BaseMutationResponse>(
    UPDATE_AUTOMATION_RULE,
    KEY_GRAPHQL_PROCESS_UPDATE_AUTOMATION_RULE,
    {
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Update Automation Rule successfully!');
        if (onReload) {
          setTimeout(() => {
            onReload();
          }, 500);
        }
        reset();

        if (!isNew) {
          onClose && onClose();
        }
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.warn('Update Automation Rule failed');
      },
    },
  );

  const onSubmit = (formData: any) => {
    const newParams = getParams(formData);
    // console.log('newParams', newParams);

    const addData = {
      id: id ?? '',
      name: newParams.name,
      type: newParams.type,
      description: newParams.description,
      trigger: {
        id: newParams.trigger.id,
        name: newParams.trigger.name,
      },
      module: newParams.trigger.module,
      criteria: newParams.criteria.criteria,
      instants: newParams.criteria.instants,
    };
    console.log('addData', addData);
    if (id) {
      mutationEdit.mutate({ rule: addData });
    } else {
      mutationAdd.mutate({ rule: addData });
    }
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-15 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                handleSubmit((data) => onSubmit(data))();
              }}
              disabled={mutationAdd.isLoading || !isValid}
              loading={mutationAdd.isLoading}
              name="Save"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  setIsNew(true);
                  handleSubmit((data) => onSubmit(data))();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                name="Save and Create New"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //render
  const formHeight = fullScreen ? 'calc(100vh - 160px)' : 'calc(100vh - 300px)';

  const triggerName = watch(KEY_NAME_AUTOMATION_TRIGGER);
  // console.log(triggerName);

  return (
    <>
      <div className="bd-b pd-10">
        <NoteAndError errors={errors} />
      </div>
      <div
        className="pos-relative pd-t-10 scroll-box"
        style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        <div className="pd-15 form-row">
          {fields.map((_item) => {
            if (_item.keyName == KEY_NAME_AUTOMATION_CRITERIA) {
              if (_.isEmpty(triggerName?.keyName)) return;
              _item.componentProps = {
                ..._item.componentProps,
                triggerModule: triggerName?.extra?.module,
              };
            }
            return (
              <Suspense key={_item.keyName}>
                <Field
                  item={{
                    ..._item,
                    // componentProps: _item.componentProps,
                    columns: _item.columns,
                    // hideTitle: _item.hideTitle, //hide title for a specific key name
                  }}
                  control={control}
                  errors={errors}
                />
              </Suspense>
            );
          })}
        </div>
      </div>
      {renderFooter()}
    </>
  );
}

export default withMiModal(AutomationWrite);
