import React from 'react';
import { Button, Input, Select } from '@base/components/form';

const TextPreview: React.FC<any> = (props: any) => {
  const { txtValue, bgColor, textColor, bdRounded, fontSize, fontWeight } = props;

  return (
    <>
      <div className="tab-content bg-white bd bd-gray-200 bd-t-0 pd-20">
        Ticket No, Status, Customer, Category, Assigned RepTicket No, Status, Customer, Category,
        Assigned Rep
      </div>

      <div className="card mg-t-10">
        <div className="card-header d-flex align-items-center">
          <span className="avatar avatar-sm flex-shrink-0">
            <img
              src="https://n.hanbiro.com/ngw/org/user/photo/no/877/cn/1"
              className="rounded-circle"
            />
          </span>
          <div className="mg-l-10">
            MSR
            <div className="tx-color-03 tx-12">2022-08-10 16:30:05</div>
          </div>
          <Button
            color="primary"
            size="sm"
            outline
            name="Feedback"
            className="mg-l-auto rounded-30"
          />
        </div>
        <div className="card-body">Ticket No, Status, Customer, Category, Assigned Rep</div>
      </div>

      <div className="card mg-t-10">
        <div className="card-header d-flex align-items-center">
          <span className="avatar avatar-sm flex-shrink-0">
            <img
              src="http://themepixels.me/demo/dashforge1.1/assets/img/img1.png"
              className="rounded-circle"
            />
          </span>
          <div className="mg-l-10">
            Feedback from Contact 1<div className="tx-color-03 tx-12">2022-08-10 16:30:05</div>
          </div>
        </div>
        <div className="card-body">Ticket No, Status, Customer, Category, Assigned Rep</div>
      </div>
    </>
  );
};

export default TextPreview;
