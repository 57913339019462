import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import Button from '@base/components/form/button';
import { ChevronUp } from 'react-feather';
import Field from '@base/components/hook-form/field';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import Loading from '@base/components/loading';
import * as keyNames from '@settings/online-digital-content/cta/config/key-names';
import { SETTING_CTA_CREATE } from '@settings/online-digital-content/cta/services/graphql';
import CTAPreview from '@settings/online-digital-content/cta/components/cta-preview';
import { finalizeParams } from './payload';
import { TEMPLATE_STAGE_OPTIONS } from '../../config/constants';
import { Input, Select } from '@base/components/form';
import { isString } from 'lodash';
import { useUploadMutation } from '@base/hooks/useFileUploadMutation';
import LandingPageAutoComplete from '@settings/online-digital-content/landing-page/containers/landing-page-auto-complete';

require('@base/assets/img/cta-sample-1.png');

const IMAGE_PREVIEW_KEYS = [
  keyNames.KEY_SETTING_CTA_IMAGE_URL,
  keyNames.KEY_SETTING_CTA_IMAGE_SIZE,
  keyNames.KEY_SETTING_CTA_IMAGE_ALT,
  keyNames.KEY_SETTING_CTA_PREVIEW,
];

const TEXT_PREVIEW_KEYS = [
  keyNames.KEY_SETTING_CTA_TEXT_VALUE,
  keyNames.KEY_SETTING_CTA_TEXT_BG_COLOR,
  keyNames.KEY_SETTING_CTA_TEXT_TEXT_COLOR,
  keyNames.KEY_SETTING_CTA_TEXT_FONT_SIZE,
  keyNames.KEY_SETTING_CTA_TEXT_FONT_WEIGHT,
  keyNames.KEY_SETTING_CTA_TEXT_ROUNDED,
];

interface WriteFormProps {
  fullScreen: boolean;
  menuApi: string;
  onClose: () => void;
  onReload?: () => void;
  //with write form
  defaultValues: any;
  fields: any[];
  tabs: any[];
  getParams: any;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<WriteFormProps> = (props: WriteFormProps) => {
  const {
    fullScreen,
    menuApi,
    onClose,
    onReload,
    //withWriteForm
    defaultValues,
    fields,
    tabs,
    getParams,
  } = props;
  //// console.log('config defaultValues', defaultValues);

  // state
  const [isReset, setIsReset] = useState(false);
  const [formParams, setFormParams] = useState<any>({});

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  // create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    SETTING_CTA_CREATE,
    'setting_createCta',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        // // console.log('after save', context);
        toast.success('Created Setting CTA successfully!');
        // refecth data
        // onReload && onReload();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        // // console.log('mutation error', error);
        toast.error('Created Setting CTA failed: ' + JSON.parse(error).message);
      },
    },
  );

  // check success
  useEffect(() => {
    if (mutationAdd.isSuccess) {
      if (isReset) {
        reset();
      } else {
        onClose();
      }
      // refecth data
      onReload && onReload();
    }
  }, [mutationAdd.isSuccess]);

  // watching
  const ctaType = watch(keyNames.KEY_SETTING_CTA_TYPE);
  const linkType = watch(keyNames.KEY_SETTING_CTA_LINK_TYPE);
  const contentType = watch(keyNames.KEY_SETTING_CTA_CONTENT_TYPE);
  const imageData = watch(keyNames.KEY_SETTING_CTA_IMAGE_URL);
  const imageSize = watch(keyNames.KEY_SETTING_CTA_IMAGE_SIZE);
  const altText = watch(keyNames.KEY_SETTING_CTA_IMAGE_ALT);

  const btnValue = watch(keyNames.KEY_SETTING_CTA_TEXT_VALUE);
  const bgColor = watch(keyNames.KEY_SETTING_CTA_TEXT_BG_COLOR);
  const textColor = watch(keyNames.KEY_SETTING_CTA_TEXT_TEXT_COLOR);
  const fontSize = watch(keyNames.KEY_SETTING_CTA_TEXT_FONT_SIZE);
  const fontWeight = watch(keyNames.KEY_SETTING_CTA_TEXT_FONT_WEIGHT);
  const bdRounded = watch(keyNames.KEY_SETTING_CTA_TEXT_ROUNDED);

  // upload
  const mUpload: any = useUploadMutation<BaseMutationResponse>(
    {
      onSuccess: (data: any, variables: any, context: any) => {
        // toast.success('Uploaded successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // // console.log('mutation error', error);
        toast.error('Uploaded failed: ' + JSON.parse(error).message);
      },
    },
    // (pEvent: ProgressEvent, partsNumber: number, partIndex: number, uploadId?: string) =>
    //   uploadProgressHandler(pEvent, partsNumber, partIndex, uploadId),
  );

  function loadXHR(url: any) {
    return new Promise(function (resolve, reject) {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.onerror = function () {
          reject('Network error.');
        };
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject('Loading error:' + xhr.statusText);
          }
        };
        xhr.send();
      } catch (err: any) {
        reject(err.message);
      }
    });
  }

  // submit form
  const onSubmit = (formData: any) => {
    let configParams = getParams(formData); //generate api params by fields

    configParams.resource = formData?.resource
      ? { id: formData?.resource?.id, name: formData?.resource?.name }
      : null;

    let defaultParams = finalizeParams(configParams);

    setFormParams(defaultParams);

    mutationAdd.mutate(defaultParams);
  };

  // upload success
  useEffect(() => {
    // // console.log('<<< completed useEffect >>>', mUpload);
    if (mUpload.isSuccess) {
      formParams.cta.imgUrl = mUpload?.data?.url ?? '';
      mutationAdd.mutate(formParams);
    }
  }, [mUpload.isSuccess]);

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    //// console.log('error', errors, e);
    toast.error('Created Marketing CTA failed: ' + JSON.stringify(errors));
  };

  /** ============================== EVENT ==================================== */

  //======================== RENDER FORM ========================//
  const renderImagePreview = () => {
    const ImageField = fields.find(
      (_ele: any) => _ele.keyName === keyNames.KEY_SETTING_CTA_IMAGE_URL,
    );
    const ImageSizeField = fields.find(
      (_ele: any) => _ele.keyName === keyNames.KEY_SETTING_CTA_IMAGE_SIZE,
    );
    const ImageAltField = fields.find(
      (_ele: any) => _ele.keyName === keyNames.KEY_SETTING_CTA_IMAGE_ALT,
    );
    return (
      <div key={keyNames.KEY_SETTING_CTA_IMAGE_URL} className="col-12 row row-xs">
        <div className="col-lg-6">
          <Field className="pd-0" item={ImageField} control={control} errors={errors} />
          <Field className="pd-0" item={ImageSizeField} control={control} errors={errors} />
          <Field className="pd-0" item={ImageAltField} control={control} errors={errors} />
        </div>
        <div className="col-lg-6">
          <CTAPreview image={imageData} imageSize={imageSize} altText={altText} />
        </div>
      </div>
    );
  };

  const renderTextPreview = () => {
    return (
      <div key={keyNames.KEY_SETTING_CTA_IMAGE_URL} className="col-12 row row-xs">
        <div className="col-lg-6">
          <div className="form-group pd-0 col-12">
            <label className="form-item-title">Value</label>
            <Controller
              name={keyNames.KEY_SETTING_CTA_TEXT_VALUE}
              control={control}
              rules={{ required: true }}
              defaultValue={'BUTTON'}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input type={'text'} value={value} onChange={onChange} />
              )}
            />
          </div>
          <div className="form-group pd-0 col-12">
            <label className="form-item-title d-block">Background Color</label>
            <Controller
              name={keyNames.KEY_SETTING_CTA_TEXT_BG_COLOR}
              control={control}
              rules={{ required: true }}
              defaultValue={'#0000FF'}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <input type="color" defaultValue={value} onChange={onChange} />
              )}
            />
          </div>
          <div className="form-group pd-0 col-12">
            <label className="form-item-title d-block">Text Color</label>
            <Controller
              name={keyNames.KEY_SETTING_CTA_TEXT_TEXT_COLOR}
              control={control}
              rules={{ required: true }}
              defaultValue={'#ffffff'}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <input type="color" defaultValue={value} onChange={onChange} />
              )}
            />
          </div>
          <div className="form-group pd-0 col-12">
            <label className="form-item-title">Font Size</label>
            <Controller
              name={keyNames.KEY_SETTING_CTA_TEXT_FONT_SIZE}
              control={control}
              rules={{ required: true }}
              defaultValue={11}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <div className="input-group wd-200">
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={value}
                    onChange={(e: any) => {
                      onChange(parseInt(e?.target?.value));
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text lh-1">px</span>
                  </div>
                </div>
              )}
            />
          </div>

          <div className="form-group pd-0 col-12">
            <label className="form-item-title">Rounded</label>
            <Controller
              name={keyNames.KEY_SETTING_CTA_TEXT_ROUNDED}
              control={control}
              rules={{ required: true }}
              defaultValue={10}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <input
                  type="range"
                  className="form-control-range wd-80p"
                  defaultValue={value}
                  max={50}
                  onChange={(e: any) => onChange(parseInt(e?.target?.value))}
                />
              )}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group d-flex flex-column ht-100p">
            <label className="form-item-title">Preview</label>
            <div className="d-flex align-items-center flex-grow-1 pd-10 bg-light rounded overflow-auto">
              <div className="wd-100p tx-center">
                <button
                  type={'button'}
                  className="btn"
                  style={{
                    backgroundColor: bgColor,
                    color: textColor,
                    borderRadius: `${bdRounded}px`,
                  }}
                >
                  <span style={{ color: textColor, fontSize: fontSize, fontWeight: fontWeight }}>
                    {btnValue ?? ''}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLandingPageSelect = () => {
    return (
      <div key={keyNames.KEY_SETTING_CTA_RESOURCE} className="form-group col-12">
        <label className="form-item-title d-flex">
          Resource<span className="tx-danger mg-l-5">*</span>
        </label>
        <Controller
          name={keyNames.KEY_SETTING_CTA_RESOURCE}
          control={control}
          rules={{ required: true }}
          defaultValue={null}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <LandingPageAutoComplete single={true} value={value} onChange={onChange} />
          )}
        />
      </div>
    );
  };

  //form view
  const renderForm = () => {
    return (
      <div className="form-row pd-15">
        {fields.map((_item: any, index: number) => {
          let newComponentProps = { ..._item?.componentProps };
          // custom field's props
          let newItem = {
            ..._item,
            componentProps: newComponentProps,
          };
          if (
            !IMAGE_PREVIEW_KEYS.includes(_item.keyName) &&
            !TEXT_PREVIEW_KEYS.includes(_item?.keyName)
          ) {
            return <Field key={index} item={newItem} control={control} errors={errors} />;
          }
        })}
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-15 bd-t">
        <div className="d-flex mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                // setIsReset(false);
                handleSubmit((data) => onSubmit({ ...data, stage: 'STAGE_INACTIVE' }), onError)();
              }}
              disabled={mutationAdd.isLoading || !isValid}
              loading={mutationAdd.isLoading || mUpload.isLoading}
              name="Finish (Stage:Disable)"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  // setIsReset(true);
                  handleSubmit((data) => onSubmit({ ...data, stage: 'STAGE_ACTIVE' }), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading || mUpload.isLoading}
                name="Finish (Stage:Enable)"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // // console.log('form values', watch()); //get form values when inputing
  // // console.log('form api values', getParams(getValues())); //get form values when inputing
  // // console.log('form errors', errors);
  // // console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ====================//

  //render
  let formHeight = 'calc(100vh - 280px)';
  if (fullScreen) {
    formHeight = 'calc(100vh - 165px)';
  }

  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-10">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>You cannot except a required field</span>
          </span>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        <div className="scroll-box" style={{ height: formHeight, overflowX: 'hidden' }}>
          <React.Suspense fallback={<Loading />}>{renderForm()}</React.Suspense>
        </div>
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(withWriteForm(WriteForm));
