import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Icons from '@base/assets/icons/svg-icons';
import { Trash } from 'react-feather';
import { useTranslation } from "react-i18next";
import { SpanLang } from '@base/components';

//Survey title class
const SurveyTitle: React.FC<any> = (props) => {
  const {
    headLineColor,
    keyS,
    totalS,
    focusQ,
    focusS,
    focusEle,
    onFocusQuestionChange,
    surveyQ,
    onSurveyChange
  } = props;
  const { t } = useTranslation();
  //state
  const descRef = useRef<any>(null);
  const [title, setTitle] = useState<string>(surveyQ.title || '');
  const [description, setDescription] = useState<string>(surveyQ.description || '');

  //textarea: set pointer end
  useEffect(() => {
    if (descRef && descRef.current) {
      descRef.current.selectionStart = descRef.current.value.length;
      descRef.current.selectionEnd = descRef.current.value.length;
    }
  }, []);

  //placeholder
  const sectionTitle = t('Section') + ' ' + (keyS + 1) + ' ' + t('of') + ' ' + totalS;
  const titlePlaceholder = keyS === 0 ? t('Survey title') : t('Section title');
  const descPlaceholder = keyS === 0 ? t('Survey description') : t('Section description');
  const surveyTitleEleId = 'survey-title-' + keyS + '-0';
  const surveyDescEleId = 'survey-desc-' + keyS + '-0';
  //render
  return (
    <div>
      {/* section header */}
      {(totalS > 1) &&
        <div className="survey-write-section-header">
          <div className="survey-write-section-header-container">
            <div className="survey-write-section-header-text-container" style={{ backgroundColor: headLineColor }}>
              <div className="survey-write-section-header-text">{sectionTitle}</div>
            </div>
          </div>
        </div>
      }

      <div className="card" onClick={() => { onFocusQuestionChange(keyS, 0) }}>

        {/* pink line */}
        <div className={classNames("survey-write-tab-content-solid-bg",
          { "survey-write-tab-content-theme-section": totalS > 1 },
          { "survey-write-tab-content-theme-stripe": totalS === 1 })} style={{ backgroundColor: headLineColor }}></div>

        {/* active vertical line */}
        {(focusS === keyS && focusQ === 0) && <div className="survey-write-tab-content-cursor-root"></div>}

        {/* title+desc input */}
        <div className="card-body">
          {/* title */}
          <div className="d-flex flex-row align-items-center">
            <div className="survey-write-tab-content-title-row mg-r-10">
              <input
                autoComplete='off'
                id={surveyTitleEleId}
                type="text"
                className="form-control survey-write-input"
                placeholder={titlePlaceholder}
                value={title} //surveyQ?.title
                onChange={(e: any) => {
                  setTitle(e.target.value);
                  //setTitleDebounced(e.target.value);
                  onSurveyChange('title', e.target.value);
                }}
                autoFocus={surveyTitleEleId === focusEle}
              />
            </div>
          </div>
          {/* description */}
          <div className="survey-write-tab-content-desc-row pd-t-10">
            <textarea
              id={surveyDescEleId}
              ref={descRef}
              rows={2}
              className="form-control survey-write-input"
              placeholder={descPlaceholder}
              value={description}
              onChange={(e: any) => {
                setDescription(e.target.value);
                //setDescriptionDebounced(e.target.value);
                onSurveyChange('description', e.target.value)
              }}
              autoFocus={surveyDescEleId === focusEle}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyTitle;