import { Button, CheckboxGroup } from '@base/components/form';
import { LIST_OPTIONS_RECHARGE } from '@settings/billing-license/config/constant';
import React, { useState } from 'react';
import { Database } from 'react-feather';
import LicenseStoragePurchased from '../purchased-views/storage-purchased';

export default function LicenseStorageAndRecharge() {
  const [isOpen, setIsOpen] = useState(false);

  const [defaultValue, setDefaultValue] = useState([LIST_OPTIONS_RECHARGE[0]]);

  return (
    <>
      <div className="row row-sm mg-y-10">
        <div className="col-xl-4">
          {/* Online Storage Used */}
          <div className="card">
            <div className="card-header bg-light">
              <h2 className="card-title h6 mg-b-0">Online Storage Used</h2>
            </div>
            <div className="card-body">
              <div className="media mg-y-9">
                <Database className="wd-35 ht-35 stroke-wd-1" />
                <div className="media-body mg-l-10">
                  <div className="mg-b-4">5GB used of 11GB(/a Domain)</div>
                  <div className="progress ht-5 op-7">
                    <div
                      className="progress-bar bg-orange wd-45p"
                      role="progressbar"
                      aria-valuenow={45}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <Button
                color="primary"
                outline={false}
                onClick={() => {
                  // console.log('asdasd');
                  setIsOpen(true);
                }}
              >
                Purchase Online Storage
              </Button>
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          {/* Current Recharge Status */}
          <div className="card">
            <div className="card-header bg-light">
              <h2 className="card-title h6 mg-b-0">Current Recharge Status</h2>
            </div>
            <div className="card-body">
              <label className="d-block tx-color-03">Allow to use Services</label>
              <CheckboxGroup
                isVertical={false}
                options={LIST_OPTIONS_RECHARGE}
                value={defaultValue}
                className={'mg-r-10'}
                onChange={(data: any) => {
                  setDefaultValue(data);
                }}
              />
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between">
              <div>
                <label className="mg-r-10 tx-color-03">The Balance Left</label>
                <strong className="tx-16 tx-danger">￦0</strong>
              </div>
              <div>
                <label className="mg-r-10 tx-color-03">Recharging</label>
                <Button color="white"> View History </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LicenseStoragePurchased
        isVisible={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onSubmit={() => {
          // console.log('submit');
        }}
      />
    </>
  );
}
