import React, { useEffect, useMemo, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, pageDataByMenuAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { useGetView } from '@base/services/view';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import Title from '@base/containers/view-field/title';
import { SpanLang } from '@base/components/form';
import { MENU_SETTING_CTA } from '@base/config/menus';
import Loading from '@base/components/loading';

import displayLayoutConfig from '@settings/online-digital-content/cta/config/display-layout';
import ViewLeft from '@settings/online-digital-content/cta/layouts/desktop/layout1/view/view-left';
import { default as viewConfig } from '@settings/online-digital-content/cta/config/view-field';
import { ctaWriteOptionAtom } from '@settings/online-digital-content/cta/recoil/atom/write-atom'; //for write
import WritePage from '../write';
import { KEY_SETTING_CTA_NAME } from '@settings/online-digital-content/cta/config/key-names';
import { getTabs } from './tabs';
import { SETTING_ONLINE_DIGITAL_MENUS } from '@base/config/route-menus';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { SETTING_CTA_DELETE } from '../../services/graphql';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query'; //v4
import NotFound from '@base/components/errors/notFound';

interface IViewProps {
  isSplitMode?: boolean;
}

/**
 * Pages: detect layout, device, init props
 */
const ViewPage: React.FC<IViewProps> = (props: IViewProps) => {
  const { isSplitMode } = props;

  // recoil
  const listFilter = useRecoilValue(pageDataByMenuAtom(MENU_SETTING_CTA));
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_SETTING_CTA));

  // params + state
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { activeId, activeTab } = listFilter;
  let id = activeId != params.id ? params.id ?? '' : activeId ?? '';
  let currentTab = params['*'] && activeTab != params['*'] ? params['*'] : activeTab;
  //for write form
  const [writeOption, setWriteOption] = useRecoilState(ctaWriteOptionAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page

  /** Hook + Get data */
  const { data: layoutView, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    MENU_SETTING_CTA,
    'view',
  );

  // // console.log('layoutView', layoutView);
  const viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  const {
    isLoading,
    data: ctaData,
    refetch,
  } = useGetView<any>({ menu: 'setting_cta', schemas: viewSchema, params: { id } });

  // get detail data
  useEffect(() => {
    //if (!isLoading && !isLayoutLoading && campaignData) {
    const layoutData = mergeLayoutData(layoutView, ctaData, viewConfig);
    let newLayoutData: any = { ...layoutData };
    newLayoutData.menuSourceId = id;
    newLayoutData.menuSource = MENU_SETTING_CTA;
    setViewData(newLayoutData);
    //}
  }, [ctaData, isLoading, isLayoutLoading]);

  // delete mutation
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    SETTING_CTA_DELETE,
    'setting_deleteCta',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Deleted CTA successfully!');
        setTimeout(() => {
          queryClient.invalidateQueries(['setting_ctas']);
        }, 500);
        return navigate(`/settings/online-digital-content/cta`);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        toast.error('Deleted CTA failed: ' + JSON.parse(error).message);
      },
    },
  );

  // render
  const renderView = () => {
    const keyTitle = `crm_new_${writeOption.writeType}`;
    //props config
    let pageProps: IDestopLayout1ViewProps = {
      headerProps: {
        menu: 'cta', //key
        menus: SETTING_ONLINE_DIGITAL_MENUS,
        isDeleting: false,
        onDelete: () => mutationDelete.mutate({ ids: [id] }),
        onNew: (category: string | undefined) => {
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: 'cta',
            writeMenu: 'view',
          }));
        },
        splitViewMode: isSplitMode,
        componentLeft: (
          <>
            <Title
              keyName={KEY_SETTING_CTA_NAME}
              value={ctaData?.[KEY_SETTING_CTA_NAME]}
              userPermission={{ isEdit: true }}
              onSave={(params: any) => {}}
              menuSourceId={id}
              menuSource={MENU_SETTING_CTA}
              viewConfig={viewConfig}
            />
          </>
        ),
      },
      centerProps: {
        showTop: false, //send to all tab's component
        menuSource: MENU_SETTING_CTA, //send to all tab's component
        menuSourceId: id, //send to all tab's component
        tabs: getTabs(),
        currentTab: currentTab,
      },
      componentTop: null,
      componentLeft: <ViewLeft id={id} />,
      leftProps: {},
      componentRight: null,
      rightProps: {},
    };

    return (
      // <React.Suspense fallback={<></>}>
      <>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="view"
          {...pageProps}
        />
        {/* hook form - write new product write */}
        {writeOption.isOpenWrite &&
          writeOption.writeType === 'cta' &&
          writeOption.writeMenu === 'view' && (
            <WritePage
              title={<SpanLang keyLang={`Create CTA`} />}
              size="lg"
              fullScreen={fullScreen}
              setFullScreen={setFullScreen}
              isOpen={writeOption.isOpenWrite}
              onClose={() =>
                setWriteOption((filter) => ({
                  ...filter,
                  isOpenWrite: false,
                }))
              }
              onReload={refetch}
              menuApi={MENU_SETTING_CTA}
            />
          )}
      </>
      // </React.Suspense>
    );
  };

  // render
  return <>{isLoading || isLayoutLoading ? <Loading /> : ctaData ? renderView() : <NotFound />}</>;
};

export default ViewPage;
