import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { Globe, Download } from 'react-feather';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Checkbox } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import { CtaProperty } from '@settings/process/types/diagram';
import { useSiteCtaAtomState, useStepSiteAtomValue } from '@settings/process/recoil/process';
import { STATUS_BASIC_DATA } from '@settings/process/config/constants';
import { getCtaFromHTML } from '@settings/process/utils/site';
import { samplePage } from '.';
import statusAtom, { selectedStatusAtom } from '@settings/process/recoil/status';

interface SiteButtonsProps {
  mode: 'view' | 'write';
}

function SiteButtons({ mode }: SiteButtonsProps) {
  const setSelectedStatus = useSetRecoilState(selectedStatusAtom);
  const [statusesValue, setStatusesValue] = useRecoilState(statusAtom);
  const stepSite = useStepSiteAtomValue();
  // console.log('stepSite', stepSite);
  const [siteCta, setSiteCta] = useSiteCtaAtomState();

  const handleAdd = useCallback((btn: CtaProperty) => {
    // If there is anything, remove it. otherwise add it.
    setStatusesValue((old) => {
      const targetIndex = old.findIndex((status) => status.ctaId === btn.id);
      if (targetIndex != -1) {
        return [...old.slice(0, targetIndex), ...old.slice(targetIndex + 1)];
      } else {
        let event = 'EVENT_NONE';
        let direction = 'DIRECTION_NONE';
        let definedId = uuidv4();
        if (btn.type == 'submit') {
          event = 'EVENT_SUBMIT';
          direction = 'DIRECTION_STAYING';
        } else if (btn.type == 'download') {
          event = 'EVENT_DOWNLOAD';
          direction = 'DIRECTION_STAYING';
        } else {
          event = 'EVENT_CLICK';
          direction = 'DIRECTION_FORWARD_OUTGOING_FORWARD';
        }
        return [
          ...old,
          {
            ...STATUS_BASIC_DATA({
              id: btn.id,
              button: btn.button,
              name: btn.title,
              newFlag: true,
              resetFlag: true,
              event: event,
              direction: direction,
              definedId: definedId,
              sequence: ['1'],
              ctaId: btn.id,
              pageName: btn.title,
            }),
          },
        ];
      }
    });
    setSelectedStatus(btn.id);
  }, []);

  const openPage = useCallback(
    (id: string) => {
      setSiteCta((old) => {
        const targetIndex = old.findIndex((cta) => cta.id === id);
        if (targetIndex != -1) {
          const targetValue = {
            ...old[targetIndex],
            page: getCtaFromHTML(samplePage), // it has to change with page api later.
          };

          return [...old.slice(0, targetIndex), targetValue, ...old.slice(targetIndex + 1)];
        }
        return old;
      });
    },
    [siteCta],
  );

  const renderButtons = useMemo(
    () => (buttons: CtaProperty[]) => {
      return (
        <>
          {buttons.length > 0 && (
            <ul className="site-links-list btns-list mg-l-15">
              {buttons.map((cta, index) => {
                const found = statusesValue.find(
                  (status) => status.ctaId && status.ctaId === cta.id,
                );
                // console.log('cta button', cta);
                const checked = found ? true : false;
                return (
                  <li key={index}>
                    <div className="d-flex align-items-center">
                      {cta.type == 'click' && (
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="d-flex align-items-center">
                            <div className="mg-r-10">{cta.button}</div>
                            {(mode == 'write' || (mode == 'view' && found?.new)) && (
                              <Checkbox onChange={() => handleAdd(cta)} checked={checked} />
                            )}
                            <div className="site-direction"></div>
                            <div
                              className="card bd-primary pointer"
                              onClick={() => openPage(cta.id)}
                            >
                              <div className="card-header bg-primary tx-white">
                                <Globe />
                                {cta.title}
                              </div>
                            </div>
                          </div>
                          {cta.page && cta.page.length > 0 && renderButtons(cta.page)}
                        </div>
                      )}
                      {cta.type == 'submit' && (
                        <>
                          <div className="card bd-purple mg-r-10">
                            <div className="card-header bg-purple tx-white">
                              {Icon('form')}
                              {cta.title}
                            </div>
                            <div className="card-body">{cta.button}</div>
                          </div>
                          {(mode == 'write' || (mode == 'view' && found?.new)) && (
                            <Checkbox onChange={() => handleAdd(cta)} checked={checked} />
                          )}
                        </>
                      )}
                      {cta.type == 'download' && (
                        <>
                          <div className="d-inline-block pd-x-20 pd-y-10 rounded-30 bg-orange tx-white mg-r-10">
                            <Download size={16} className="mg-r-5" />
                            Download
                          </div>
                          {(mode == 'write' || (mode == 'view' && found?.new)) && (
                            <Checkbox onChange={() => handleAdd(cta)} checked={checked} />
                          )}
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      );
    },
    [siteCta, statusesValue],
  );

  return (
    <div className="form-group col-12">
      <h1 className="modal-title">{stepSite.template.languageKey}</h1>
      {renderButtons(siteCta)}
    </div>
  );
}

export default SiteButtons;
