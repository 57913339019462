import {
  KEY_NAME_SETTING_NAME,
  KEY_NAME_SETTING_DESCRIPTION,
} from '@settings/process/config/keyNames';
import TextView from '@base/containers/view-field/text/view';

export const ActionViewField = {
  [KEY_NAME_SETTING_NAME]: {
    component: TextView,
    showFullRow: false,
    defaultValue: '',
    languageKey: 'process_action_name',
  },
  [KEY_NAME_SETTING_DESCRIPTION]: {
    component: TextView,
    showFullRow: true,
    defaultValue: '',
    languageKey: 'process_action_description',
  },
};

export default ActionViewField;
