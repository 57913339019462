import React, { useCallback, useMemo } from 'react';
import { OptionValue } from '@base/types/interfaces/common';
import { SelectBox } from '@base/config/write-field/components';
import useTrigger from '@settings/process/hooks/useTrigger';
import { TRIGGER_TYPE } from '@settings/process/config/constants';

interface TriggerWriteProps {
  value: string;
  onChange: (val: string) => void;
}

function TriggerWrite(props: TriggerWriteProps) {
  const { value, onChange } = props;
  const [defined, options] = useTrigger(value, 'wait');
  const onChangeTrigger = useCallback((val: OptionValue) => {
    onChange && onChange(val.keyName);
  }, []);

  const triggerValue = useMemo(() => {
    return options.find((opt) => opt.keyName == value)!!;
  }, [value, options]);

  return (
    <>
      <div className="form-group col-12">
        <label className="form-item-title">Trigger Name</label>
        <SelectBox
          options={options}
          value={triggerValue}
          onChange={(newVal) => onChangeTrigger(newVal)}
        />
      </div>
      {defined?.trigger && (
        <>
          <div className="form-group col-12">
            <label className="form-item-title">Trigger Type</label>
            <div>{TRIGGER_TYPE[defined.trigger]}</div>
          </div>
          {(defined.trigger == 'TRIGGER_TYPE_RECORD_CREATED' ||
            defined.trigger == 'TRIGGER_TYPE_RECORD_UPDATED' ||
            defined.trigger == 'TRIGGER_TYPE_RECORD_CREATED_UPDATED' ||
            defined.trigger == 'TRIGGER_TYPE_RECORD_DELETED') && (
            <div className="form-group col-12">
              <label className="form-item-title">Record</label>
              <div className="pd-10 bd rounded">
                <div className="row">
                  <div className="col-md-12 mg-b-10">
                    <label>Module</label>
                    <div>{defined.module}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {defined?.trigger === 'TRIGGER_TYPE_FIELD_UPDATED' && (
        <div className="form-group col-12">
          <label className="form-item-title">Field</label>
          <div className="pd-10 bd rounded">
            <div className="row">
              <div className="col-md-6 mg-b-10">
                <label>Module</label>
                <div>{defined.module}</div>
              </div>
              <div className="col-md-6 mg-b-10 mg-md-b-0">
                <label>Field</label>
                <div>{defined.field}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {defined?.trigger === 'TRIGGER_TYPE_PROCESS_PROPERTY_UPDATED' && (
        <>
          <div className="form-group col-12">
            <label className="form-item-title">Process</label>
            <div className="pd-10 bd rounded">
              <div className="row">
                <div className="col-md-6 mg-b-10">
                  <label>Type</label>
                  <div>{defined.ptype}</div>
                </div>
                <div className="col-md-6 mg-b-10 mg-md-b-0">
                  <label>Module</label>
                  <div>{defined.module}</div>
                </div>
                <div className="col-md-4 mg-b-10 mg-md-b-0">
                  <label>Process Name</label>
                  <div>{defined.process.name}</div>
                </div>
                <div className="col-md-4 mg-b-10 mg-md-b-0">
                  <label>Step Name</label>
                  <div>{defined.step.name}</div>
                </div>
                <div className="col-md-4 mg-b-10 mg-md-b-0">
                  <label>Step Property</label>
                  <div>{defined.property}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TriggerWrite;
