import { Button, Input } from '@base/components/form';
import { X } from 'react-feather';
import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import statusAtom from '@settings/process/recoil/status';

interface ChecklistWriteProps {}

function ChecklistWrite(props: ChecklistWriteProps) {
  const [statusesValue, setStatusesValue] = useRecoilState(statusAtom);
  const [name, setName] = useState('');
  const targetIndex = useMemo(
    () => statusesValue.findIndex((status) => status.property.keyName == 'PROPERTY_TODO_CLOSE'),
    [],
  );
  let checklist = _.cloneDeep(statusesValue[targetIndex]?.checklist!!);

  const onChangeName = useCallback((value: string) => {
    setName(value);
  }, []);

  const handleAdd = useCallback(() => {
    setStatusesValue((old) => {
      checklist.push({ id: _.now().toString(), name: name });
      const targetValue = { ...old[targetIndex], ...{ checklist: checklist } };
      return [...old.slice(0, targetIndex), targetValue, ...old.slice(targetIndex + 1)];
    });
    setName('');
  }, [checklist, targetIndex]);

  const hanldeDelete = useCallback(
    (id: string) => {
      setStatusesValue((old) => {
        const newChecklist = checklist.filter((ck) => ck.id != id);
        const targetValue = { ...old[targetIndex], ...{ checklist: newChecklist } };
        return [...old.slice(0, targetIndex), targetValue, ...old.slice(targetIndex + 1)];
      });
    },
    [checklist, targetIndex],
  );

  return (
    <div className="form-row">
      <div className="form-group col-12">
        <label className="form-item-title">Checklist</label>
        <ul className="list-group">
          {checklist?.map((ck) => {
            return (
              <li key={ck.id} className="list-group-item bg-transparent d-flex align-items-center">
                {ck.name}
                <button
                  type="button"
                  className="btn mg-l-auto pd-0"
                  onClick={() => hanldeDelete(ck.id)}
                >
                  <X className="tx-danger" />
                  <span className="sr-only">삭제</span>
                </button>
              </li>
            );
          })}
          <li className="list-group-item bg-transparent d-flex align-items-center">
            <div className="d-flex align-items-center wd-100p">
              <Input type="text" value={name} onChange={onChangeName} />
              <Button
                color="primary"
                icon="Plus"
                name="Add"
                className="flex-shrink-0 mg-l-10"
                onClick={handleAdd}
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ChecklistWrite;
