import React from 'react';
//import ReactPlayer from 'react-player';

import {
  Q_IMAGE,
  Q_TITLE,
  Q_VIDEO,
} from '@settings/online-digital-content/survey/config/constants';

//render question multiple choices
const QuestionMediaView: React.FC<any> = (props) => {
  const { keyQ, selectedQType, title, description, image, video } = props;

  //local state

  //component render
  return (
    <div className="survey-write-question-view-container">
      <div className="survey-write-question-card-root-container">
        <div className="survey-write-question-card-root">
          <div className="card">
            {/* <img src="..." class="card-img-top" alt="..."> */}

            {/* question options */}
            <div className="card-body">
              <div className="survey-write-question-card-title-wrapper">
                <div className="survey-write-question-card-title-row">
                  <div className="survey-write-question-card-title-input">
                    {/* title */}
                    <div className="">
                      <span className="survey-write-question-card-title-name">
                        {title ? title : 'Untitled Question'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {selectedQType === Q_TITLE && (
                <div className="pd-t-10">
                  <span>{description}</span>
                </div>
              )}

              {selectedQType === Q_IMAGE && image && image.url && (
                <div className="survey-write-question-card-image">
                  <div className="survey-write-question-card-image-pos">
                    <img
                      src={image.url}
                      className="survey-write-question-card-image-view"
                      alt="Q_image"
                      style={{ width: '680px' }}
                    />
                  </div>
                </div>
              )}

              {selectedQType === Q_VIDEO && (
                <div className="survey-write-question-card-video pd-t-10">
                  <span className="">{video}</span>
                  {/* <ReactPlayer url={video} controls={true} /> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionMediaView;
