import { Button } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import { moneyFormat } from '@base/utils/helpers';
import { useListInvoiceIssued, useListOrder } from '@settings/billing-license/services';
import React, { useEffect, useMemo, useState } from 'react';

const BillAndPaymentInvoice: React.FC = () => {
  const { data, isLoading, refetch } = useListOrder('');
  const {
    data: dataIssued,
    isLoading: isLoadingIssued,
    refetch: refetchIssued,
  } = useListInvoiceIssued('');
  const [dataTableDetail, setDataTableDetail] = useState<any[]>([]);
  const [dataTableIssued, setDataTableIssued] = useState<any[]>([]);

  useEffect(() => {
    if (data && data?.data) {
      setDataTableDetail(data?.data);
    }
  }, [data]);

  useEffect(() => {
    if (dataIssued && dataIssued?.data) {
      setDataTableIssued(dataIssued?.data);
    }
  }, [dataIssued]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.name}</>;
        },
      },
      {
        Header: 'Qty',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.quantity}</>;
        },
      },
      {
        Header: 'Unit Price',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.unitPrice == 'Free' ? 'Free' : '$' + moneyFormat(row.unitPrice)}</>;
        },
      },
      {
        Header: 'Unit',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.unit}</>;
        },
      },
      {
        Header: 'Price',
        accessor: (row: any, rowIndex: any) => {
          return (
            <>
              {row.unitPrice == 'Free' ? 'Free' : '$' + moneyFormat(row.quantity * row.unitPrice)}
            </>
          );
        },
      },
    ],
    [dataTableDetail],
  );

  const columnsInvoiceIssued = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.date.toLocaleDateString()}</>;
        },
      },
      {
        Header: 'Invoice Name',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.name}</>;
        },
      },
      {
        Header: 'Total Amount',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.amount}</>;
        },
      },
      {
        Header: 'Payment',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.payment}</>;
        },
      },
    ],
    [dataTableIssued],
  );

  return (
    <div className="card">
      <div className="card-header bg-light">
        <h2 className="card-title h6 mg-b-0">Invoices</h2>
      </div>
      <div className="card-body">
        <p>
          Your Next Payment of <strong className="tx-16">$5,000</strong> is due on{' '}
          <span className="tx-primary">Feb 26, 2020</span>.
        </p>
        <div>
          Current Balance <strong className="tx-16 tx-danger">$5,000</strong>
          <Button color="white" className="btn-sm mg-l-10">
            View
          </Button>
        </div>
        <hr />
        <label className="tx-color-03">Order Detail</label>
        <div className="table-responsive rounded bd">
          <BasicTable
            columns={columns}
            data={dataTableDetail}
            className="table table-bordered mg-b-0 bd-0 settings-tb"
          />
        </div>
        <div className="d-flex justify-content-end mg-t-10">
          <ul className="list-unstyled lh-10 wd-lg-40p mg-0 pd-x-20 pd-y-10">
            <li className="d-flex justify-content-between">
              <span>Sub-Total</span>
              <span></span>
            </li>
            <li className="d-flex justify-content-between">
              <span>Balance</span>
              <span>-5,000</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>Tax</span>
              <span></span>
            </li>
            <li className="d-flex justify-content-between mg-t-10 pd-t-10 bd-t tx-semibold">
              <span>Total</span>
              <span></span>
            </li>
          </ul>
        </div>
        <div className="mg-t-10 text-right">
          <Button color="primary" outline={true} className="mg-r-5">
            Request Virtual Billing Account
          </Button>
          <Button color="primary" className="">
            Make Payment
          </Button>
        </div>
        <hr />
        <label className="tx-color-03">Invoice Issued</label>
        <div className="table-responsive rounded bd">
          <BasicTable
            columns={columnsInvoiceIssued}
            data={dataTableIssued}
            className="table table-bordered mg-b-0 bd-0 settings-tb"
          />
        </div>
      </div>
      <div className="card-footer tx-16 font-weight-bold">Total Revenue :</div>
    </div>
  );
};
export default BillAndPaymentInvoice;
