import { SpanLang } from '@base/components';
import Canvas from '@base/components/canvas';
import { Input } from '@base/components/form';
import Portal from '@base/components/portal';
import { IdName } from '@base/types/interfaces/common';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';
import { ISLA } from '@settings/preferences/types/interfaces';
import React, { useEffect, useState } from 'react';
import { Search, X } from 'react-feather';
interface IViewCustomerProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (params: any) => void;
  data: ISLA | null;
}
const ViewCustomer: React.FC<IViewCustomerProps> = (props: IViewCustomerProps) => {
  const { isOpen, onClose, onSave, data } = props;
  const [keyword, setKeyword] = useState('');
  const [customers, setCustomers] = useState<IdName[]>([]);
  const [searchedCustomers, setSearchedCustomers] = useState<IdName[]>([]);
  const handleSaveChange = () => {
    const nData = {
      ...data,
      customers: customers,
    };
    onSave && onSave(nData);
  };
  const onAddCustomers = (addCustomer: any) => {
    let nCustomers = [...customers];
    const found = customers.find((item) => {
      return addCustomer.id === item.id;
    });
    if (!found) {
      nCustomers.push({
        id: addCustomer.id,
        name: addCustomer.name,
      });
    }
    setCustomers(nCustomers);
  };
  const onRemoveCustomer = (deIdx: number) => {
    const nCustomers = customers.filter((item, idx) => {
      return deIdx != idx;
    });
    setCustomers(nCustomers);
  };
  const onSearch = (input: string) => {
    if (input != '') {
      const reg = new RegExp(input, 'ig');
      const nCustomers = customers.filter((cus) => {
        return reg.test(cus.name);
      });
      setSearchedCustomers(nCustomers);
    } else {
      const nCustomers = customers;
      setSearchedCustomers(nCustomers);
    }
  };
  useEffect(() => {
    if (data) {
      setCustomers(data.customers);
    }
  }, [data]);
  return (
    <>
      <Portal>
        <Canvas
          isVisible={isOpen}
          onCloseSideBar={onClose}
          onClose={onClose}
          className={'page-sidebar-container-wrap wd-500'}
          customStyles={{ zIndex: '1052' }}
          backdropStyles={{ zIndex: '1051' }}
        >
          <Canvas.Header title={<SpanLang keyLang="Customers" />} />
          <Canvas.Body style={{ height: 'calc(100vh - 130px)' }}>
            <ul className="list-group">
              <li className="list-group-item pd-0">
                <div className="input-group">
                  <Input
                    type="text"
                    className="bd-0"
                    value={keyword}
                    onChange={(nKey: string) => {
                      onSearch(nKey);
                      setKeyword(nKey);
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text bd-0">
                      <Search />
                    </span>
                  </div>
                </div>
              </li>
              {keyword == '' &&
                customers &&
                customers.map((item, idx) => {
                  return (
                    <li
                      className="list-group-item d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bg-white"
                      key={idx}
                    >
                      <div className="media align-items-center">
                        <span className="avatar avatar-sm">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;amp;usqp=CAU&amp;size=l"
                            alt="Adventure Works"
                            className="rounded-circle"
                          />
                        </span>
                        <div className="media-body mg-l-10">{item.name}</div>
                      </div>
                      <button
                        type="button"
                        className="btn mg-l-auto pd-0"
                        data-han-tooltip="Delete"
                        onClick={() => {
                          onRemoveCustomer(idx);
                        }}
                      >
                        <X className="tx-danger" />
                      </button>
                    </li>
                  );
                })}
              {keyword != '' &&
                searchedCustomers &&
                searchedCustomers.map((item, idx) => {
                  return (
                    <li
                      className="list-group-item d-flex flex-wrap align-items-center pd-x-15 pd-y-5 bg-white"
                      key={idx}
                    >
                      <div className="media align-items-center">
                        <span className="avatar avatar-sm">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6f-iwbt_f4VBnQODrf6zKd4j7ou6U5ZwQeg&amp;amp;usqp=CAU&amp;size=l"
                            alt="Adventure Works"
                            className="rounded-circle"
                          />
                        </span>
                        <div className="media-body mg-l-10">{item.name}</div>
                      </div>
                      <button
                        type="button"
                        className="btn mg-l-auto pd-0"
                        data-han-tooltip="Delete"
                        onClick={() => {
                          onRemoveCustomer(idx);
                        }}
                      >
                        <X className="tx-danger" />
                      </button>
                    </li>
                  );
                })}
            </ul>
            <CustomerAutoComplete
              single
              showAvatar
              className="wd-100p mg-t-5"
              value={[]}
              onChange={(newValues: any) => onAddCustomers(newValues)}
            />
          </Canvas.Body>
          <Canvas.Footer onClose={onClose} onSave={handleSaveChange} saveColor="success" />
        </Canvas>
      </Portal>
    </>
  );
};

export default ViewCustomer;
