import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_CATEGORY } from '@settings/site/config/pages/main-page';
import { Button } from '@base/components/form';
import { Check } from 'react-feather';
import { SITE_BUILDER_VENDOR_URL } from '@base/config/site-builder';
import { getBuilderUrl, getVendorBuilderUrl } from '@settings/site/utils/helper';
import Loading from '@base/components/loading';
import { useSite } from '@settings/site/services/service';
import { PREVENT_DELETE_SITES, SITE_GROUP_OPTION } from '@settings/site/config/constants';
import Title from '@settings/site/containers/title';
import useMutationPost from '@base/hooks/useMutationPost';
import { UPDATE_DEFAULT_SITE_TEMPLATE } from '@settings/site/services/graphql';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import WriteForm from '@settings/site/containers/write-form';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@settings/site/config/display-layout';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms/app';
import { SITE_MENUS } from '@base/config/route-menus';
import { MENU_SETTING_SITE } from '@base/config/menus';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { parseFields } from '../utils';
import { siteTplDataState } from '@settings/site/recoil/atom/view-atom';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { default as viewConfig } from '@settings/site/config/view-field';

interface IViewPageProps {
  isSplitMode?: boolean;
  category: string;
}
const ViewPage: React.FC<IViewPageProps> = (props: IViewPageProps) => {
  const { isSplitMode, category } = props;
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom); //app data
  const navigate = useNavigate();
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [name, setName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const builderUrl = getBuilderUrl();
  const iframeRef = useRef<any>(null);
  const params = useParams();
  const aParams = params['*'] ? params['*'].split('/') : [];
  const siteGroup = aParams[0] ? aParams[0] : DEFAULT_CATEGORY;
  let siteId = params['id'] ? params['id'] : '';
  siteId = aParams[1] ? aParams[1] : siteId;
  const isEdit = aParams[2] ? aParams[2] == 'edit' : false;
  const [isOpenWrite, setIsOpenWrite] = useState(isEdit);
  let { data, isLoading: isLoadingData, refetch } = useSite(siteId, SITE_GROUP_OPTION[siteGroup]);
  const [isLoading, setIsLoading] = useState(isLoadingData);
  const categoryWithPrefix = 'desk_ticket';
  let { data: layoutView, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    categoryWithPrefix,
    'view',
  );
  layoutView = parseFields(layoutView, 'site');
  //data = useRecoilValue(siteTplDataState);
  console.log('>>>>>>>>>>>>> data', data, isLoading);

  const viewUrl = getVendorBuilderUrl() + '?id=' + siteId;
  const [url, setUrl] = useState(viewUrl);
  const goList = () => {
    let listUrl = `/settings/site/${siteGroup}`;
    navigate(listUrl);
  };
  const mUpdateDefaultTemplate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_DEFAULT_SITE_TEMPLATE,
    'setting_updateDefaultSiteTemplate',
  );

  const onSetDefault = () => {
    setIsSaving(true);
    const nIsDefault = !isDefault;
    mUpdateDefaultTemplate.mutate(
      { id: siteId, isDefault: nIsDefault },
      {
        onSuccess: (res: any) => {
          setIsSaving(false);
          setIsDefault(nIsDefault);
          toast.success('Site Template has updated!');
        },
        onError: (res: any) => {
          setIsSaving(false);
        },
      },
    );
  };
  const onEdit = () => {
    setIsOpenWrite(true);
  };
  useEffect(() => {
    /*if (data) {
      setName(data.name ?? '');
      setIsDefault(data.isDefault ?? false);
    }*/
    /*const layoutData = mergeLayoutData(layoutView, data, viewConfig);
    let newLayoutData: any = { ...layoutData };
    newLayoutData.menuSourceId = siteId;
    newLayoutData.menuSource = menuSource;
    setViewData(newLayoutData);*/
  }, [data]);
  const onLoaded = (ev: any) => {
    setIsLoadingIframe(false);
  };
  const onClose = () => {
    // console.log('onClose');
    setIsOpenWrite(false);
    setIsLoadingIframe(true);
    refetch();
  };
  const canSetDefault = PREVENT_DELETE_SITES.indexOf(siteGroup) != -1 ? false : true;
  const viewProps = {
    isLoading: isLoading,
    headerProps: {
      menu: category,
      menus: SITE_MENUS, //menu list
      isDeleting: false,
      splitViewMode: isSplitMode,
      onPrint: () => {},
      onDelete: () => {},
      onNew: (category: string | undefined) => {},
      moreActions: [],
      onStageChange: () => null,
      componentLeft: (
        <>
          <Title
            title={name}
            isDefault={isDefault}
            id={siteId}
            canEdit={true}
            onAfterSaved={setName}
          />
        </>
      ),
    },

    centerProps: {
      showTop: true,
      menuSource: MENU_SETTING_SITE,
      menuSourceId: siteId,
      tabs: [],
      currentTab: null,
    },
  };
  return (
    <>
      {/* <div className="template-header">
        <a className="btn btn-link pd-l-0 pd-y-0 btn-back" onClick={goList}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </a>

        <Title
          title={name}
          isDefault={isDefault}
          id={siteId}
          canEdit={true}
          onAfterSaved={setName}
        />

        <div className="mg-l-auto pd-15">
          {canSetDefault ? (
            isDefault ? (
              <Button color="danger" name="Remove Default" onClick={onSetDefault} icon="Minus" />
            ) : (
              <Button
                color="primary"
                name="Set Default"
                onClick={onSetDefault}
                icon="CheckCircle"
              />
            )
          ) : (
            ''
          )}
          <Button color="warning" name="Edit" className="mg-l-5" onClick={onEdit} icon="Edit2" />
        </div>
      </div> */}
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={device}
        layout={layout}
        form="view"
        {...viewProps}
      />
      {/* {isLoadingIframe || isLoading || isSaving ? <Loading /> : ''} */}
      {/* {!isOpenWrite && (
        <iframe
          src={url}
          frameBorder={0}
          style={{ width: '100%', height: '100%' }}
          ref={iframeRef}
          onLoad={onLoaded}
        />
      )} */}
      {isOpenWrite && (
        <WriteForm onClose={onClose} url={`${builderUrl}?category=${siteGroup}&id=${siteId}`} />
      )}
    </>
  );
};

export default ViewPage;
