import { atom } from 'recoil';

export interface DeskTemplate {
  id: string;
  name: string;
  code: string;
  customer: string;
  contact: string;
  description?: string;
  status?: KeyAndLang;
  category: string;
  assignedUser: string;
  createdOn: string;
  firstRespondDue: string;
  resolvedOn: string;
  closedAt: string;
}
export interface KeyAndLang {
  keyName: string;
  languageKey: string;
}

const deskTplData: DeskTemplate = {
  id: '0000001',
  name: 'Desk Template 01',
  code: '00000001',
  customer: 'Customer 1',
  contact: 'Contact 1',
  description: 'description 1',
  status: {
    keyName: 'STATUS_NEW',
    languageKey: 'New',
  },
  category: 'Product/Error',
  assignedUser: 'MSR',
  createdOn: '2022-08-08 15:36:56',
  firstRespondDue: '2022-08-08 15:36:56',
  resolvedOn: '2022-08-08 15:36:56',
  closedAt: '2022-08-08 15:36:56',
};

export const deskTplDataState = atom({
  key: 'deskDataDetail',
  default: deskTplData,
});
