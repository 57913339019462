export function mergeSection(sections: any) {
  let newSection;
  if (sections.length > 0) {
    let childrenSection1 = sections[0]?.children;
    let childrenSection2 = sections[1]?.children;
    newSection = { ...sections[0], children: [...childrenSection1, ...childrenSection2] };
  }

  // let newSection  = {
  //   ...sections[0],
  //   chilren: [...sections[0]?.children,...sections[1]?.chilren]
  // }
  return [newSection];
}
export function filterSelection(sections: any, mode: any) {
  if (mode == 'write') {
    return sections?.map((section: any) => {
      return {
        ...section,
        children: section?.children.filter((item: any) => {
          return item.showInWrite == true;
        }),
      };
    });
  }

  if (mode == 'list') {
    return sections?.map((section: any) => {
      return {
        ...section,
        children: section?.children.filter((item: any) => {
          return item.showInList == true;
        }),
      };
    });
  }
  if (mode == 'view') {
    return sections?.map((section: any) => {
      return {
        ...section,
        children: section?.children.filter((item: any) => {
          return item.showInView == true;
        }),
      };
    });
  }
  return '';
}
