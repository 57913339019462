import {
  CustomerAutoComplete,
  Input,
  SelectBox,
  TextArea,
  UserAutoComplete,
} from '@base/config/write-field/components';
import { OptionValue } from '@base/types/interfaces/common';
import validators from '@base/utils/validation/field-validator';
import { FieldProperty } from '@settings/process/components/field-value/FieldValue';
import { INSTANT_ACTION_TYPE_OPTIONS } from '@settings/process/config/constants';
import {
  KEY_NAME_AUTOMATION_INSTANT_FIELD,
  KEY_NAME_AUTOMATION_INSTANT_MESSAGE,
  KEY_NAME_AUTOMATION_INSTANT_NAME,
  KEY_NAME_AUTOMATION_INSTANT_TARGET_CUSTOMER,
  KEY_NAME_AUTOMATION_INSTANT_TARGET_USER,
  KEY_NAME_AUTOMATION_INSTANT_TEMPLATE,
  KEY_NAME_AUTOMATION_INSTANT_TYPE,
} from '@settings/process/config/keyNames';
import { FieldValue } from '@settings/process/config/write-field/components';

export const InstantWriteField = {
  [KEY_NAME_AUTOMATION_INSTANT_NAME]: {
    component: Input,
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    languageKey: 'business_instant_name',
    parseParam: (value: string) => value,
  },
  [KEY_NAME_AUTOMATION_INSTANT_TYPE]: {
    component: SelectBox,
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    languageKey: 'business_instant_type',
    componentProps: {
      options: INSTANT_ACTION_TYPE_OPTIONS,
    },
    parseParam: (value: OptionValue) => value,
  },
  [KEY_NAME_AUTOMATION_INSTANT_TARGET_USER]: {
    component: UserAutoComplete,
    componentProps: {
      single: false,
      showAvatar: false,
      placement: 'left',
    },
    showFullRow: true,
    defaultValue: [],
    languageKey: 'business_instant_assign_user',
    parseParam: (v: any) => {
      return v
        ? v.map((val: any) => {
            return {
              id: val.id,
              name: val.name,
            };
          })
        : null;
    },
  },
  [KEY_NAME_AUTOMATION_INSTANT_TARGET_CUSTOMER]: {
    component: CustomerAutoComplete,
    componentProps: {
      single: false,
      showAvatar: false,
      showEmail: false,
      placement: 'left',
    },
    showFullRow: true,
    defaultValue: [],
    languageKey: 'business_instant_customer',
    parseParam: (v: any) => {
      return v
        ? v.map((val: any) => {
            return {
              id: val.id,
              name: val.name,
            };
          })
        : null;
    },
  },
  [KEY_NAME_AUTOMATION_INSTANT_TEMPLATE]: {
    component: SelectBox,
    showFullRow: true,
    componentProps: {
      options: [],
    },
    defaultValue: null,
    languageKey: 'business_instant_template',
    parseParam: (value: OptionValue) =>
      value
        ? {
            id: value.keyName,
            name: value.languageKey,
          }
        : null,
  },
  [KEY_NAME_AUTOMATION_INSTANT_FIELD]: {
    component: FieldValue,
    showFullRow: true,
    defaultValue: null,
    componentProps: {
      module: '',
    },
    languageKey: 'business_instant_field',
    parseParam: (value: FieldProperty) => value,
  },
  [KEY_NAME_AUTOMATION_INSTANT_MESSAGE]: {
    component: TextArea,
    showFullRow: true,
    defaultValue: '',
    languageKey: 'business_instant_outbound_message',
    parseParam: (value: string) => value,
  },
};

export default InstantWriteField;
