import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { SpanLang } from '@base/components';
import { CONFIG } from '@settings/page-layout/config/common';
import CollapseSection from '../collapse-section';
import Section from '../Section';

interface IContainerProps {
  itemDragging: any;
  items: any[] | undefined;
  disabled: boolean;
  onDeleteSection: (params: any) => void;
  onDeleteItemSection: (params: any) => void;
  onEditItemSection: (params: any) => void;
  onUnusedItemSection: (params: any) => void;
  onUpdateItemSection: (params: any) => void;
  isLoading?: boolean;
}
const Container: React.FC<IContainerProps> = (props: IContainerProps) => {
  const {
    itemDragging,
    items,
    disabled,
    onDeleteSection,
    onDeleteItemSection,
    onEditItemSection,
    onUnusedItemSection,
    onUpdateItemSection,
    isLoading = false,
  } = props;
  return (
    <Droppable
      key={CONFIG.container}
      droppableId={CONFIG.container}
      isDropDisabled={
        itemDragging?.source?.droppableId != CONFIG.sections &&
        itemDragging?.source?.droppableId != CONFIG.container
      }
    >
      {(provided, snapshot) => (
        <ul ref={provided.innerRef} className="shopping-bag container-scroll">
          {items && items.length == 0 ? (
            <div className="decription-drag-drop tx-gray-500">
              <SpanLang keyLang="crm_new_pagelayout_msg_drag_drop_section" />
            </div>
          ) : (
            items &&
            items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={true}>
                {(provided, snapshot) => (
                  <div
                    className="pd-b-10"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    <div data-label={item.label} className="section-view d-flex card">
                      <CollapseSection
                        label={<SpanLang keyLang={item.languageKey} />}
                        keyName={item.keyName}
                      >
                        <Section
                          disabled={disabled}
                          data={{
                            section: item,
                          }}
                          // label={item.label}
                          items={item.children}
                          id={item.id}
                          itemDragging={props.itemDragging}
                          onDeleteSection={() =>
                            onDeleteSection({
                              item: item,
                              index: index,
                            })
                          }
                          onDeleteItemSection={onDeleteItemSection}
                          onUpdateItemSection={onUpdateItemSection}
                          onEditItemSection={onEditItemSection}
                          onUnusedItemSection={onUnusedItemSection}
                          isLoading={item.isLoading}
                        />
                      </CollapseSection>
                    </div>
                  </div>
                )}
              </Draggable>
            ))
          )}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export default Container;
