import { IARAssignToItem } from '@settings/assignment-rule/types/interfaces';
import { EARAssignToMode, EARAssignToType } from '@settings/assignment-rule/types/enums';

export const ruleEntriesData: IARAssignToItem[] = [
  {
    id: '22a61004-0f03-4cbb-b087-0d76f896e073',
    assignTo: {
      user: {
        id: '1',
        name: 'meo',
      },
      group: {
        id: '2',
        name: 'meo',
      },
    },
    mode: EARAssignToMode.OWNER,
    type: EARAssignToType.USERS,
    baseOnWorkDay: true,
    capacity: 30,
  },
  {
    id: '22a61004-0f03-4cbb-b087-0d76f896e0732',
    assignTo: {
      user: {
        id: '1',
        name: 'meo',
      },
      group: {
        id: '2',
        name: 'meo',
      },
    },
    mode: EARAssignToMode.OWNER,
    type: EARAssignToType.USERS,
    baseOnWorkDay: true,
    capacity: 50,
  },
];
