import { MENU_SETTING_TEMPLATE } from '@base/config/menus';
import { lazy } from 'react';

/** MAIN PAGE CONFIG */
export const MAIN_MENU = MENU_SETTING_TEMPLATE;
export const LIST_ROUTE = ':category/:group';
export const VIEW_ROUTE = ':category/:group/:id/*';
export const WRITE_ROUTE = ':category/:group/write';
//export const DEFAULT_ROUTE = '/settings/template/menus/knowledgebase';
export const DEFAULT_ROUTE = '/settings/template/menus/email';
export const DEFAULT_CATEGORY = 'menus';
//export const DEFAULT_GROUP = 'knowledgebase';
export const DEFAULT_GROUP = 'email';
export const PRIMARY_KEY = 'id';
export const NAME_KEY = 'name';

export const ListPage = lazy(() => import('@settings/template/pages/list'));
export const ViewPage = lazy(() => import('@settings/template/pages/view-new'));
export const WritePage = lazy(() => import('@settings/template/pages/write-new'));
