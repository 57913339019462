import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Plus } from 'react-feather';
import classNames from 'classnames';
import _, { template } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { SelectBox } from '@base/config/write-field/components';
import { OptionValue } from '@base/types/interfaces/common';
import { useGetDefinedItems } from '@settings/process/hooks/useProcess';
import { GET_DEFINED_ITEMS } from '@settings/process/services/custom';
import { BusinessStatus, StatusForm } from '@settings/process/types/process';
import { getStepStatuses } from '@settings/process/utils/helper';
import { Button } from '@base/components/form';
import { WriteParseFields } from '@base/utils/helpers/no-layout-utils';
import { MENU_PROCESS_AUTOMATION_INSTANT } from '@base/config/menus';
import NoteAndError from '@base/components/hook-form/error-note';
import { Field } from '@base/components/hook-form';
import {
  KEY_NAME_AUTOMATION_INSTANT_FIELD,
  KEY_NAME_AUTOMATION_INSTANT_MESSAGE,
  KEY_NAME_AUTOMATION_INSTANT_TARGET_CUSTOMER,
  KEY_NAME_AUTOMATION_INSTANT_TARGET_USER,
  KEY_NAME_AUTOMATION_INSTANT_TEMPLATE,
  KEY_NAME_AUTOMATION_INSTANT_TYPE,
} from '@settings/process/config/keyNames';
import { LabelValue } from '@base/types/types/app';
import { useMenuTemplates } from '@base/services/setting-service';
import { INSTANT_ACTION_TEMPLATE } from '@settings/process/config/constants';
import { FieldProperty } from '@settings/process/components/field-value/FieldValue';
import { removeItemAtIndex, replaceItemAtIndex } from '@base/utils/helpers/array-utils';
import React from 'react';
import CriteriaView from './CriteriaView';

export interface InstantValue {
  id?: string;
  name: string;
  type: OptionValue;
  template?: OptionValue;
  targetUsers?: LabelValue[];
  targetCustomers?: LabelValue[];
  field?: FieldProperty;
  message?: string;
}

export interface CriteriaValue {
  criteria: OptionValue;
  instants: {
    [index: string]: InstantValue[];
  };
}

interface openInstantState {
  id: string;
  mode: 'w' | 'v';
  open: boolean;
  aid?: string;
}

interface CriteriaAutomationProps {
  triggerModule: string;
  value: CriteriaValue;
  onChange: (option: CriteriaValue) => void;
}

function CriteriaAutomation(props: CriteriaAutomationProps) {
  const { value: criteriaValue, triggerModule, onChange } = props;
  console.log('criteira Value', criteriaValue);
  // const [statusesValue, setStatusValue] = useState<StatusForm[]>([]);
  const [openInstant, setOpenInstant] = useState<openInstantState>({
    id: '',
    mode: 'w',
    open: false,
  });
  // const [currentInstant, setCurrentInstant] = useState<InstantValue | null>(null);

  const { data: definedTriggers } = useGetDefinedItems(
    GET_DEFINED_ITEMS,
    'TYPE_CRITERIA',
    'criteria',
    10,
  );

  const { fields, getParams, defaultValues } = useMemo(
    () => WriteParseFields(MENU_PROCESS_AUTOMATION_INSTANT),
    [],
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    watch,
  } = useForm<InstantValue>({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  // useEffect(() => {
  //   if (currentInstant) {
  //     reset(currentInstant);
  //   }
  // }, [currentInstant]);

  const selectedInstantType = watch(KEY_NAME_AUTOMATION_INSTANT_TYPE);
  const { data: templateData } = useMenuTemplates({
    templateGroup: INSTANT_ACTION_TEMPLATE[selectedInstantType.keyName],
    options: {
      enabled:
        selectedInstantType.keyName == 'ACTION_TYPE_TASK' ||
        selectedInstantType.keyName == 'ACTION_TYPE_EMAIL',
    },
  });
  console.log('templatedata', templateData);

  const templateOptions = useMemo(() => {
    console.log(templateData);
    let templates: OptionValue[] = [];
    if (templateData && templateData.results) {
      console.log('template data', templateData);
      templates = templateData.results.map((v) => {
        return {
          keyName: v.id,
          languageKey: v.name!!,
        };
      });
    }
    return templates;
  }, [templateData]);

  const criteriaOptions = useMemo(() => {
    let options: OptionValue[] = [];
    if (definedTriggers && definedTriggers.results) {
      options = definedTriggers.results.map((v) => {
        return {
          keyName: v.id,
          languageKey: v.name,
          extra: v.statuses,
        };
      });
    }
    return options;
  }, [definedTriggers]);

  const onChangeCriteria = useCallback(
    (option: OptionValue) => {
      onChange && onChange({ ...criteriaValue, instants: {}, criteria: option });
      onInstantClose();
    },
    [criteriaValue],
  );

  const statusesValue = useMemo(() => {
    if (criteriaOptions) {
      const find = criteriaOptions.find(
        (criteria) => criteria.keyName == criteriaValue?.criteria?.keyName,
      );
      if (find) {
        return getStepStatuses('TYPE_CRITERIA', find.extra as BusinessStatus[]);
      }
    }
    return [];
  }, [criteriaOptions, criteriaValue?.criteria?.keyName]);

  const onSubmit = (formData: any) => {
    let newParams = getParams(formData);
    let newInstants = _.cloneDeep(criteriaValue.instants);
    if (!(openInstant.id in newInstants)) {
      newInstants[openInstant.id] = [];
    }

    // edit
    if (openInstant.mode == 'v') {
      const oldInstant = newInstants[openInstant.id];
      const targetIndex = oldInstant.findIndex((action) => action.id === openInstant.id);
      const targetValue = { ...oldInstant[targetIndex], ...newParams };
      newInstants[openInstant.id] = replaceItemAtIndex(oldInstant, targetIndex, targetValue);
    } else {
      newInstants[openInstant.id].push({ ...newParams, id: uuidv4() });
    }

    console.log('newInstants', newInstants);
    onChange && onChange({ ...criteriaValue, instants: newInstants });
    onInstantClose();
  };

  const onInstantClose = useCallback(() => {
    reset(defaultValues);
    setOpenInstant({ id: '', mode: 'w', open: false });
  }, []);

  const onInstantOpen = useCallback((id: string) => {
    reset(defaultValues);
    setOpenInstant({ id: id, mode: 'w', open: true });
  }, []);

  const onInstantEditOpen = useCallback((id: string, action: InstantValue) => {
    reset(action);
    setOpenInstant({ id: id, aid: action.id, mode: 'v', open: true });
  }, []);

  const onDeleteInstant = useCallback(() => {
    if (openInstant.mode == 'v') {
      const newInstants = _.cloneDeep(criteriaValue.instants);
      const oldInstant = newInstants[openInstant.id];
      const targetIndex = oldInstant.findIndex((action) => action.id === openInstant.aid);
      newInstants[openInstant.id] = removeItemAtIndex(oldInstant, targetIndex);
      onChange && onChange({ ...criteriaValue, instants: newInstants });
      onInstantClose();
    }
  }, [openInstant, criteriaValue?.instants]);

  const statusCount = statusesValue.length;

  return (
    <>
      <div className="form-row pd-l-15 pd-r-15">
        <div className="form-group col-6">
          <label className="form-item-title">Criteria Name</label>
          <SelectBox
            options={criteriaOptions}
            value={criteriaValue.criteria}
            onChange={(newVal) => onChangeCriteria(newVal)}
            errors={{}}
          />
        </div>
        {/* <div className="form-group col-6">
          <label className="form-item-title">Criteria Type</label>
          <div className="wd-250"></div>
        </div> */}
      </div>

      {statusCount > 0 && (
        <>
          <div className="form-group col-12">
            <div className="form-group-title">View</div>
            <div className="pd-15 bg-light rounded">
              {statusesValue.map((status, index) => {
                if (statusCount == index + 1) return;
                return (
                  <React.Fragment key={status.id}>
                    <CriteriaView status={status} />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className="form-group col-12">
            <div className="form-group-title">Build</div>
            <div className="form-row mg-b-30">
              <div
                className={classNames('form-group', {
                  'col-7': openInstant,
                  'col-12': !openInstant,
                })}
              >
                {statusesValue.map((status, index) => {
                  if (statusCount == index + 1) return;
                  const instants = criteriaValue.instants[status.id];
                  return (
                    <div
                      key={status.id}
                      className="diagram-item diagram-criteria with-boolean-direction"
                    >
                      <div className="criteria-shape"></div>
                      <div className="diagram-item-name">{status.button}</div>
                      <div className="direction-true">
                        <div className="immediate-action shadow-sm">
                          <div className="action-header">Instant Actions</div>
                          {instants?.map((action) => {
                            return (
                              <Button
                                key={action.id}
                                color=""
                                block
                                icon="Edit2"
                                iconClass="mg-r-10 tx-primary"
                                name={action.name}
                                onClick={() => onInstantEditOpen(status.id, action)}
                              />
                            );
                          })}
                          <Button
                            color=""
                            block
                            icon="Plus"
                            iconClass="mg-r-10 tx-primary"
                            name="Add Action"
                            onClick={() => onInstantOpen(status.id)}
                          />
                        </div>
                      </div>
                      <div className="direction-false"></div>
                    </div>
                  );
                })}
              </div>
              {openInstant.open && (
                <div className="form-group col-5">
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <h6 className="mg-b-0">Add Instant Action</h6>
                    </div>
                    <div className="card-body pd-0">
                      <div className="pd-5">
                        <NoteAndError errors={errors} />
                      </div>
                      <div className="pd-15 form-row">
                        {fields.map((_item) => {
                          if (selectedInstantType.keyName == 'ACTION_TYPE_TASK') {
                            if (
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TARGET_CUSTOMER ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_FIELD ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_MESSAGE
                            ) {
                              return;
                            }
                          } else if (selectedInstantType.keyName == 'ACTION_TYPE_EMAIL') {
                            if (
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TARGET_USER ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_FIELD ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_MESSAGE
                            ) {
                              return;
                            }
                          } else if (selectedInstantType.keyName == 'ACTION_TYPE_FIELD_UPDATE') {
                            if (
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TARGET_USER ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TARGET_CUSTOMER ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_MESSAGE ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TEMPLATE
                            ) {
                              return;
                            }
                          } else if (
                            selectedInstantType.keyName == 'ACTION_TYPE_OUTBOUND_MESSAGE'
                          ) {
                            if (
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TARGET_USER ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TARGET_CUSTOMER ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_FIELD ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TEMPLATE
                            ) {
                              return;
                            }
                          } else {
                            if (
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TARGET_USER ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TARGET_CUSTOMER ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_FIELD ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_MESSAGE ||
                              _item.keyName == KEY_NAME_AUTOMATION_INSTANT_TEMPLATE
                            ) {
                              return;
                            }
                          }

                          if (_item.keyName == KEY_NAME_AUTOMATION_INSTANT_TEMPLATE) {
                            _item.componentProps = {
                              ..._item.componentProps,
                              options: templateOptions,
                            };
                          }

                          if (_item.keyName == KEY_NAME_AUTOMATION_INSTANT_FIELD) {
                            _item.componentProps = {
                              ..._item.componentProps,
                              module: triggerModule,
                            };
                          }

                          return (
                            <Suspense key={_item.keyName}>
                              <Field
                                item={{
                                  ..._item,
                                  componentProps: _item.componentProps,
                                  columns: _item.columns,
                                  hideTitle: _item.hideTitle, //hide title for a specific key name
                                }}
                                control={control}
                                errors={errors}
                              />
                            </Suspense>
                          );
                        })}
                      </div>
                      <div className="card-footer">
                        <div className="d-flex justify-content-between">
                          <button
                            type="button"
                            className="btn btn-light btn-xs mg-r-5"
                            onClick={() => onInstantClose()}
                          >
                            Close
                          </button>
                          <div className="d-flex justify-content-end">
                            {openInstant?.mode == 'v' && (
                              <button
                                type="button"
                                className="btn btn-danger btn-xs mg-r-5"
                                onClick={() => onDeleteInstant()}
                              >
                                Delete
                              </button>
                            )}
                            <button
                              type="button"
                              className="btn btn-primary btn-xs"
                              onClick={() => handleSubmit((data) => onSubmit(data))()}
                              disabled={!isValid}
                            >
                              {openInstant ? (
                                'Save'
                              ) : (
                                <>
                                  <Plus /> Add
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CriteriaAutomation;
