import React, { useState, useEffect, forwardRef, useCallback, useMemo, lazy } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import SpanLang from '@base/components/form/span-lang';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deviceAtom, languagesAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { FilterInput } from '@base/types/interfaces/common';
import { DESC, ASC } from '@base/config/constant';
import ListLoading, { GridNothing, TableNothing } from '@base/components/list/list-loading';
import {
  ListBody,
  ListContainer,
  ListGrid,
  ListPagination,
  ListToolbar,
  ListHeader,
  ListHeaderFilters,
  ListQuickAction,
  ListSearch,
  ListTableColumnSetting,
} from '@base/components/list';
import ListGridItem from '@base/components/list/list-grid/item';
import { Trash2 } from 'react-feather';
import { convertDateTimeServerToClient } from '@base/utils/helpers/general.utils';
import ListReactTable from '@base/components/list/list-react-table';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';
import { buildListSchema } from '@base/utils/helpers/schema';
import { MENU_SETTING_CTA, MENU_TEMPLATE_KNOWLEDGEBASE } from '@base/config/menus';
import { SearchFilter } from '@base/types/interfaces/app';
import { ListType } from '@base/types/enums';
import { useGetList } from '@base/services/list';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { categoryOptions, dateByOptions, filterByOptions, searchFields } from '../../config/list';
import { groupByOptions } from '@settings/template/config/list';
import { default as configFields } from '../../config/view-field';
import displayLayoutConfig from '../../config/display-layout';
//import * as keyNames from '../../config/key-names';
import * as keyNames from '../../../config/key-names';

//for write
//import WritePage from '../../../pages/write-form';
import { WritePage } from '../../../config/pages/main-page';
//end for write

import { listDataByMenuAtom } from '@base/recoil/atoms';
import ListToolbarContainer from '@base/containers/list/toolbar';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';

import { DELETE_MENU_TEMPLATES } from '@settings/template/services/graphql';
import { Avatar, confirmAlert } from '@base/components';
import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';
import { kbWriteOptionAtom } from '../../recoil/atom/write-atom';
//import { TEMPLATE_STAGE_OPTIONS } from '../../config/constants';
import {
  TEMPLATE_GROUP,
  TEMPLATE_TYPE_OPTIONS,
  TEMPLATE_STAGE_OPTIONS,
} from '@settings/template/config/constants';
//import { useMenuTemplates } from '../../../services/service';
import { parseFieldsList } from '../main/utils';
import { parseStringQuery } from '@settings/template/utils/helper';

interface IListPage {
  isSplitMode: boolean;
  setIsSplitMode: (isSplitMode: boolean) => void;
}

const ListPage: React.FC<IListPage> = (props: IListPage) => {
  const { t } = useTranslation();
  const { isSplitMode, setIsSplitMode } = props;

  const queryClient = useQueryClient();

  /** Define */
  const menu = MENU_TEMPLATE_KNOWLEDGEBASE;
  const category = 'knowledgebase';

  //recoil
  const { isDesktop, device, layout } = useRecoilValue(deviceAtom);
  const [writeOption, setWriteOption] = useRecoilState(kbWriteOptionAtom); //for write page
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [listData, setListData] = useRecoilState(listDataByMenuAtom(MENU_TEMPLATE_KNOWLEDGEBASE)); //current list
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_TEMPLATE_KNOWLEDGEBASE));
  const avaiLanguages: any = useRecoilValue(languagesAtom);

  // react-hook
  const params = useParams();
  const navigate = useNavigate();

  // state
  const [showColumns, setShowColumns] = useState<any[]>([]);
  const [hideColumns, setHideColumns] = useState<string[]>([]);
  const [sortColumns, setSortColumns] = useState<any[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [quickLoading, setQuickLoading] = useState<boolean>(false);
  const [showUpdateGroup, setShowUpdateGroup] = useState<boolean>(false);
  const [showChangeAssign, setShowChangeAssign] = useState<string>('');

  const goToView = useCallback((row: any) => {
    const url = `/${menu}/${category}/${row.id}`;
    navigate(url);
  }, []);

  // Sort
  let { filter, listType, settingColumns } = pageData;
  const sort = filter?.sort;
  const setSort = (sort: any) => {
    let newFilter = {
      ...pageData,
      filter: {
        ...pageData.filter,
        sort: sort,
      },
    };
    setPageData(newFilter);
  };

  // get pagelayout
  const layoutMenu = MENU_TEMPLATE_KNOWLEDGEBASE;
  let { data: listLayoutData } = usePageLayoutByMenu(MENU_SETTING_CTA, 'list');
  listLayoutData = parseFieldsList(listLayoutData, writeOption.writeType);
  // console.log('>>>>> writeOption', writeOption);
  // console.log('>>>>> params', params);
  // console.log('>>>>> listLayoutData', listLayoutData);

  let fields: any[] = [];
  if (listLayoutData && listLayoutData?.data) {
    fields = listLayoutData?.data;
  }
  // console.log('>>>>> fields', fields);

  const canSorts: string[] = ['state', 'language', 'title', 'type', 'name', 'createdAt'];
  const getSortColumns = () => {
    return [
      /*{
        value: 'createdAt',
        label: 'Created At',
      },
      {
        value: 'updatedAt',
        label: 'Updated At',
      },*/
    ];
  };

  useEffect(() => {
    if (fields?.length > 0 && settingColumns.length == 0) {
      const newColumns: any[] = [];
      const newFilter: any = { ...pageData };
      listLayoutData?.data?.map((_ele: any) => {
        newColumns.push({
          ..._ele,
          isViewing: _ele.defaultViewInList,
          disableSortBy: canSorts.indexOf(_ele.keyName) === -1 ? true : false,
        });
      });
      newFilter.settingColumns = {
        ...settingColumns,
        [layoutMenu]: newColumns,
      };
      setPageData(newFilter);
    }
  }, [fields]);
  // console.log('settingColumns >>>>', settingColumns);
  // columns will show in list
  useEffect(() => {
    if (settingColumns && settingColumns[layoutMenu]) {
      let newShowColumns: any[] = [];
      newShowColumns = settingColumns[layoutMenu].filter((_ele: any) => _ele.isViewing);
      setShowColumns(newShowColumns);
    }
  }, [settingColumns]);

  // build list query schema
  const listQuerySchema = buildListSchema({ fields, configFields });

  // list filters
  // console.log('>>>>>>>> searchFields', searchFields);
  // console.log('>>>>> filter?.headerFilters', filter?.headerFilters);

  let queries = ['group=1'];
  let parseQuery = ParseFiltersToQuery(filter, dateByOptions, searchFields);
  if (parseQuery) {
    queries.push(parseQuery);
  }

  let filtersQuery: FilterInput = {
    keyword: filter?.keyword ?? '',
    sort: filter?.sort,
    paging: filter?.paging,
    //query: ParseFiltersToQuery(filter, dateByOptions, searchFields),
    query: parseStringQuery(queries.join(' '), '22779486-f43a-4530-b77f-31a932dd0a23'),
  };

  // get list data
  const { isLoading, data, isFetching, isPreviousData, refetch } = useGetList<any[]>(
    'setting_menuTemplates',
    listQuerySchema,
    filtersQuery,
  );

  useEffect(() => {
    setListData(data?.data ?? []);
  }, [data?.data]);

  const columnRenderRemap = {
    [keyNames.KEY_MENU_TEMPLATE_NAME](col: string, data: any) {
      let name = data[col] ? data[col] : '';
      let sourceId = data.id ? data.id : '';
      return <Link to={`/settings/${category}/${sourceId}`}>{name}</Link>;
      // return <Link to={`/settings/online-digital-content/cta/${sourceId}`}>{name}</Link>; // view detail on tabs
    },
    [keyNames.KEY_MENU_TEMPLATE_TYPE](col: string, data: any) {
      return TEMPLATE_TYPE_OPTIONS?.find((v: any) => v.value === data[col])?.label ?? '(none)';
    },
    [keyNames.KEY_MENU_TEMPLATE_LANGUAGE](col: string, data: any) {
      return avaiLanguages?.find((v: any) => v.code == data?.[col])?.title ?? data?.[col] ?? 'none';
    },

    [keyNames.KEY_MENU_TEMPLATE_DESCRIPTION](col: string, data: any) {
      return data[col] || '(none)';
    },
    [keyNames.KEY_MENU_TEMPLATE_STAGE](col: string, data: any) {
      return TEMPLATE_STAGE_OPTIONS?.find((v: any) => v.value === data[col])?.label ?? 'none';
    },
  };

  const columnsOnSplitMode = {
    // [keyNames.KEY_PRODUCT_TYPE]: {
    //   // title: '',
    // },
    // [keyNames.KEY_PRODUCT_ACTIVE]: {
    //   title: 'Active',
    // },
  };

  const onCheckedRow = useCallback(
    (checkedValue: string | number) => {
      const checkedValueStr = checkedValue as string;
      let newSelectedIds = selectedIds;
      switch (checkedValueStr) {
        case 'all':
          newSelectedIds = [];
          listData?.map((prod: any) => {
            newSelectedIds.push(prod?.id);
          });
          break;
        case 'notAll':
          newSelectedIds = [];
          break;
        default:
          if (selectedIds.indexOf(checkedValueStr) !== -1) {
            newSelectedIds.splice(selectedIds.indexOf(checkedValueStr), 1);
          } else {
            newSelectedIds = [...selectedIds, checkedValueStr];
          }
          break;
      }
      setSelectedIds(cloneDeep(newSelectedIds));
    },
    [selectedIds, setSelectedIds],
  );

  const listProps = {
    listData: listData ?? [],
    isFetching: false,
    fetching: <ListLoading />,
    onCheckedRow: onCheckedRow,
  };

  const listGridProps = {
    menuSource: MENU_TEMPLATE_KNOWLEDGEBASE,
    titleKey: 'name',
    titleLink: '/settings/template/' + category,
    isSplitMode,
    selectedIds,
    device: device !== undefined ? device : 'desktop',
    nodata: <GridNothing />,
    columns: showColumns,
    columnRenderRemap: columnRenderRemap,
    hideColumns: hideColumns,
    columnsOnSplitMode: columnsOnSplitMode,
  };

  const listTableProps = {
    nodata: <TableNothing />,
    data: listData ?? [],
    columns: makeTableColumns(showColumns, columnRenderRemap, { category }, hideColumns),
    initialState: {
      pageSize: data?.paging?.totalPage,
      pageIndex: data?.paging?.currentPage,
      selectedIds: selectedIds,
      sort: sort,
    },
    onCheckedRow: onCheckedRow,
    onRowClick: (row: any, e: any) => {
      e && e.preventEvent && e.preventDefault();
      goToView(row);
    },
    onSortBy: (clName: any, isSortedDesc: boolean) => {
      let orderBy = isSortedDesc ? DESC : ASC;
      setSort({ field: clName, orderBy: orderBy });
    },
  };

  const pagingProps = {
    totalPage: data?.paging?.totalPage || 1,
    totalItems: data?.paging?.totalItems || 0,
    currentPage: data?.paging?.currentPage || 1,
    itemPerPage: data?.paging?.itemPerPage || 10,
    nextPage: data?.paging?.nextPage || null,
    previousPage: data?.paging?.previousPage || null,
    isPreviousData,
    onChange: (page: number, size: number) => {
      filter = {
        ...filter,
        paging: {
          page,
          size,
        },
      };
      let newFilter = {
        ...pageData,
        filter: filter,
      };
      setPageData(newFilter);
    },
  };

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_MENU_TEMPLATES,
    'setting_deleteMenuTemplates',
    {
      onMutate: () => {
        setQuickLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Delete Template successfully!');
        setQuickLoading(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['setting_deleteMenuTemplates']);
        }, 500);
        onCheckedRow('notAll');
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Delete Template failed: ' + JSON.parse(error).message);
        setQuickLoading(false);
      },
    },
  );

  const quickButtons = [
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: (selected: any) => {
        mutationDelete.mutate({ ids: selectedIds });
      },
    },
  ];

  // more menu
  const actionsProps = {
    actions: {
      import: {
        label: 'Import',
        icon: 'UploadCloud',
      },
      export: {
        label: 'Export',
        icon: 'Download',
      },
    },
    onSelect: (key: string) => {},
  };

  const handleSearchSubmit = (formData: any) => {
    if (formData?.type === 'TYPE_NONE') {
      delete formData.type;
    }
    let newFilter: SearchFilter = {
      ...pageData.filter,
    };

    if (formData?.keyword != '') {
      newFilter = {
        ...newFilter,
        keyword: formData?.keyword,
      };
      delete formData.keyword;
    }
    newFilter = {
      ...newFilter,
      searchFilters: formData,
    };
    let newData: any = {
      ...pageData,
      filter: newFilter,
    };
    setPageData(newData);
  };

  const onChangeColumnSetting = (newColumns: any) => {
    setPageData({
      ...pageData,
      settingColumns: {
        ...settingColumns,
        [layoutMenu]: newColumns,
      },
    });
  };
  //const keyTitle = `crm_new_knowledgebase`;
  const keyTitle = `Create Knowledbase Template`;
  return (
    <>
      <ListContainer
        isLoading={isLoading || isFetching}
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={
            <ListToolbarContainer
              listTypeProps={{
                listTypeStr: listType,
                onChangeListType: (listType: ListType) => {
                  let newData = {
                    ...pageData,
                    listType: listType,
                  };
                  setPageData(newData);
                },
                listTypes: [ListType.GRID, ListType.LIST, ListType.SPLIT],
              }}
              // actionsProps={actionsProps}
              isSplitMode={isSplitMode}
              setIsSplitMode={setIsSplitMode}
              onRefresh={refetch}
              onAdd={(category: string | undefined) =>
                setWriteOption((filter: any) => ({
                  ...filter,
                  isOpenWrite: true,
                  writeType: category === undefined ? MENU_TEMPLATE_KNOWLEDGEBASE : category,
                }))
              }
              categoryOptions={categoryOptions}
              categorySelected={category}
              // onCategoryChange={(newCategory: string) => {
              //   return navigate(`/${menu}/${newCategory}`);
              // }}
              menu={MENU_TEMPLATE_KNOWLEDGEBASE}
            />
          }
          header={
            <ListHeader
              isSimple={isSplitMode}
              searchComponent={
                <ListSearch
                  isSimple={isSplitMode}
                  placeholder={'Enter name to search'}
                  // searchFields={searchFields.reduce((final: any[], field: any) => {
                  //   if (showColumns?.findIndex((v: any) => v.name == field.name) !== -1) {
                  //     final.push(field);
                  //   }
                  //   return final;
                  // }, [])}
                  searchValue={filter?.keyword}
                  onSubmit={handleSearchSubmit}
                />
              }
              filterComponent={
                <ListHeaderFilters
                  category={category ?? ''}
                  isSplitMode={isSplitMode}
                  groupByOptions={groupByOptions}
                  filterByOptions={filterByOptions}
                  dateByOptions={dateByOptions}
                  filterData={filter?.headerFilters}
                  onFilterDataChanged={(valueData: any) => {
                    let newFilter: SearchFilter = {
                      ...filter,
                      headerFilters: valueData,
                    };
                    let newData = {
                      ...pageData,
                      filter: newFilter,
                    };
                    // console.log('...onFilterDataChanged...', valueData);
                    setPageData(newData);
                  }}
                  sortData={sort}
                  onSortDataChanged={(s: any) => {
                    // console.log('...onSortDataChanged...', s);
                    setSort(s);
                  }}
                  sortColumns={getSortColumns()}
                />
              }
              /*columnSettingComponent={
                !isSplitMode &&
                listType == ListType.LIST && (
                  <ListTableColumnSetting
                    columns={
                      settingColumns && settingColumns[layoutMenu] ? settingColumns[layoutMenu] : []
                    }
                    hideColumns={hideColumns}
                    columLimit={10}
                    onChange={onChangeColumnSetting}
                  />
                )
              }*/
              onSelectedAll={() => onCheckedRow('all')}
              onDeselectedAll={() => onCheckedRow('notAll')}
              onRefresh={refetch}
              actionsProps={actionsProps}
            />
          }
          body={
            <ListBody>
              {isSplitMode ? (
                <ListGrid
                  {...listProps}
                  {...listGridProps}
                  listGridItemRender={(props: any) => <ListGridItem {...props} />}
                />
              ) : (
                <>
                  {/* table list */}
                  {/* {listType.list && <ListTable {...listProps} {...listTableProps} />} */}
                  {listType == ListType.LIST && <ListReactTable {...listTableProps} />}
                  {/* grid list */}
                  {listType == ListType.GRID && (
                    <ListGrid
                      {...listProps}
                      {...listGridProps}
                      listGridItemRender={(props: any) => <ListGridItem {...props} />}
                    />
                  )}
                </>
              )}
              <ListPagination isSplitMode={isSplitMode} type={'full'} {...pagingProps} />
            </ListBody>
          }
        />

        {/* Tablet layout here */}
        {/* {isTablet && null} */}

        {/* Mobile layout here */}
        {/* {isMobile && null} */}
      </ListContainer>

      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons || []}
        resetSelected={() => onCheckedRow('notAll')}
        visible={!!selectedIds.length}
      />

      {/* hook form - write new product write */}
      {writeOption.isOpenWrite && writeOption.writeType === MENU_TEMPLATE_KNOWLEDGEBASE && (
        <WritePage
          title={<SpanLang keyLang={keyTitle} />}
          size="xl"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          onReload={refetch}
          templateGroup={'knowledgebase'}
          menuApi={MENU_TEMPLATE_KNOWLEDGEBASE}
        />
      )}
    </>
  );
};

export default ListPage;
