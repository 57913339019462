import { useDownloadObjectMutation } from '@base/hooks/useFileUploadMutation';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const ImagePreview: React.FC<any> = (props: any) => {
  const { className, imgSrc, imgAlt, imgSize } = props;

  // state
  const [imageSrc, setImageSrc] = useState<string>('');
  const [imageData, setImageData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    if (imgSrc != imageSrc) {
      setImageSrc(imgSrc);
    }
  }, [imgSrc]);

  // download mutation
  const mDownload: any = useDownloadObjectMutation<any>({
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data: any, variables: any, context: any) => {
      // toast.success('Uploaded successfully!');
      // // console.log('onSuccess data >>>', data);
      setImageData(data);
    },
    onError: (error: any, variables: any, context: any) => {
      // // console.log('mutation error', error);
      toast.error('There is an error in downloading image: ' + JSON.parse(error).message);
    },
  });

  useEffect(() => {
    if (imageSrc != '' && !isLoading) {
      const params = {
        id: '',
        url: imageSrc,
      };
      mDownload.mutate(params);
    }
  }, [imageSrc, isLoading]);

  return (
    <div className={classNames('')}>
      {imageData && (
        <img
          src={imageData}
          alt={imgAlt ?? ''}
          // style={{ width: imgSize?.width, height: imgSize?.height }}
          // className="img-fit-contain ht-150 wd-150"
          className={classNames('img-fit-contain', className)}
          // onLoad={() => {
          //   setImgLoading(true);
          // }}
        ></img>
      )}
    </div>
  );
};

export default ImagePreview;
