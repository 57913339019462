import { ESiteGroup } from '../types/enums';
export const SITE_GROUP_NUMBER: { [index: string]: number } = {
  SITE_GROUP_DESK: 5,
};
export const SITE_GROUP_KEY: { [index: string]: string } = {
  SITE_GROUP_DESK: 'desk',
};
export const SITE_GROUP_OPTION: { [index: string]: ESiteGroup } = {
  desk: ESiteGroup.DESK,
};
export const SITE_GROUP_OPTION_NUMBER: { [index: string]: number } = {
  desk: SITE_GROUP_NUMBER[ESiteGroup.DESK],
};

export const PREVENT_DELETE_SITES = ['desk1']; //['desk'];

export const SITE_TYPE_OPTIONS = [
  {
    label: 'Desk Site',
    value: 'desk',
  },
];
