import { Button, Input } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { generateUUID } from '@base/utils/helpers';
import {
  DELETE_TICKET_CLASSIFICATION_SETTING,
  UPDATE_TICKET_CLASSIFICATION_SETTING,
  CREATE_TICKET_CLASSIFICATION_SETTING,
} from '@settings/preferences/services/graphql';
import { useTicketClassificationsSetting } from '@settings/preferences/services/service';
import { ITicketClassification } from '@settings/preferences/types/interfaces';
import { t } from 'i18next';
import React, { useMemo, useState, useEffect } from 'react';
import { Plus } from 'react-feather';
import { toast } from 'react-toastify';
import classNames from 'classnames';

interface ITicketClassificationProps {}
const TicketClassification: React.FC<ITicketClassificationProps> = (
  props: ITicketClassificationProps,
) => {
  const [items, setItems] = useState<ITicketClassification[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading } = useTicketClassificationsSetting();
  const mUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_TICKET_CLASSIFICATION_SETTING,
    'desk_updateTicketClassification',
  );
  const mAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_TICKET_CLASSIFICATION_SETTING,
    'desk_createTicketClassification',
  );
  const mDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_TICKET_CLASSIFICATION_SETTING,
    'desk_updateTicketClassification',
  );
  const onChangeValues = (nVal: string, rIdx: number) => {
    const aVals = nVal.split(',');
    const editItem = items[rIdx];
    let nItem = {
      ...editItem,
      values: aVals,
    };
    if (editItem.id && editItem.id != '') {
      // update
      mUpdate.mutate(
        { classification: nItem },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
          },
        },
      );
    } else if (editItem.id == '' && editItem.name != '') {
      nItem.id = generateUUID();

      mAdd.mutate(
        { classification: nItem },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
          },
        },
      );
    }
    updateItemsLC(nItem, rIdx);
  };
  const updateItemsLC = (nItem: ITicketClassification, rIdx: number) => {
    const nItems = items.map((item, idx) => {
      if (idx == rIdx) {
        return nItem;
      }
      return item;
    });
    setItems(nItems);
  };
  const onChangeName = (nName: string, rIdx: number) => {
    const editItem = items[rIdx];
    // console.log('onChangeName', nName);
    if (editItem.id && editItem.id != '') {
      // do update
      const nItem = {
        ...editItem,
        name: nName,
      };
      mUpdate.mutate(
        { classification: nItem },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
          },
        },
      );
      updateItemsLC(nItem, rIdx);
    } else {
      // do create
      const nItem = {
        ...editItem,
        name: nName,
        id: generateUUID(),
      };
      mAdd.mutate(
        { classification: nItem },
        {
          onSuccess: (res: any) => {
            toast.success('Data was saved!');
          },
        },
      );
      updateItemsLC(nItem, rIdx);
    }
  };
  const onDelete = (id: string) => {
    mDelete.mutate(
      { ids: [id] },
      {
        onSuccess: (res: any) => {
          toast.success('Data was removed!');
        },
      },
    );
    const nItems = items.filter((item) => {
      return item.id != id;
    });
    setItems(nItems);
  };
  const onAddRow = () => {
    const nItem: ITicketClassification = {
      id: '',
      name: '',
      values: [],
    };
    const nItems = [nItem, ...items];
    setItems(nItems);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Classification',
        accessor: (row: ITicketClassification, rowIndex: any) => {
          const canEdit = rowIndex < items.length - 2;
          return (
            <>
              {!canEdit && <>{row.name}</>}
              {canEdit && (
                <Input
                  type="text"
                  placeholder="Enter Name"
                  value={row.name}
                  onBlur={(nVal: string) => {
                    if (nVal != '') {
                      onChangeName(nVal, rowIndex);
                    }
                  }}
                  // onEnter={(nVal: string) => {
                  //   // console.log('onEnter');
                  //   onChangeName(nVal, rowIndex);
                  // }}
                />
              )}
            </>
          );
        },
      },
      {
        Header: 'Values',
        accessor: (row: ITicketClassification, rowIndex: any) => {
          const values = row.values ? row.values.join(',') : '';
          const canEdit = rowIndex < items.length - 2;
          return (
            <>
              <div className="d-flex align-items">
                <Input
                  type="text"
                  placeholder="Seperate with , or click enter"
                  value={values}
                  // onBlur={(nVal: string) => {
                  //   onChangeValues(nVal, rowIndex);
                  // }}
                  onEnter={(nVal: string) => {
                    // console.log('onEnter', nVal);
                    if (nVal != "'") {
                      onChangeValues(nVal, rowIndex);
                    }
                  }}
                />

                <Button
                  color=""
                  icon="X"
                  iconClass="tx-danger"
                  className={classNames('btn-icon btn-delete-row han-tooltip--left', {
                    // 'ng-hide': !canEdit,
                  })}
                  data-han-tooltip="삭제"
                  onClick={() => {
                    if (canEdit) {
                      onDelete(row.id);
                    } else {
                      onChangeValues('', rowIndex);
                    }
                  }}
                />
              </div>
            </>
          );
        },
      },
    ],
    [items],
  );
  useEffect(() => {
    if (!isLoading && data?.results) {
      setItems(data.results);
    }
  }, [data]);
  return (
    <>
      <div className="card">
        <div className="card-header h6 bg-light">
          <div className="card-header-title">
            Ticket Classification
            <br />
            <small className="mg-t-5 tx-color-03 tx-normal">
              These Attribute-Values are used for Assignment Rule and Ticket creation
            </small>
          </div>
        </div>
        <BasicTable
          columns={columns}
          data={items || []}
          className="settings-tb hover-delete"
          wrapperClassName="remove-min-width"
          isLoading={isLoading}
        ></BasicTable>
        <div className="card-footer">
          <button
            type="button"
            className="btn btn-sm btn-primary flex-shrink-0"
            onClick={() => {
              onAddRow();
            }}
          >
            <Plus className="mg-r-5" />
            Add
          </button>
        </div>
      </div>
    </>
  );
};

export default TicketClassification;
