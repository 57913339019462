import React, { lazy, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { MENU_SETTING_PREFERENCES } from '@base/config/menus';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayout from '@settings/preferences/config/display-layout';
import { SUB_MENUS } from '@settings/preferences/config/sub-menus';
import '@settings/preferences/assets/scss/style.scss';
import { savingPreferencesAtom } from '@settings/preferences/recoil/atoms';

const getPreviousUrl = (params: any, pageData: any): string => {
  let prePath = '';
  // prePath empty in case params same with recoil data
  if (
    params.activeMenu == pageData.activeMenu &&
    params.activeId == pageData.activeId &&
    params.activeTab == pageData.activeTab
  ) {
    return prePath;
  }
  if (params.activeMenu == '' && pageData.activeMenu && pageData.activeMenu != '') {
    prePath = `/settings/preferences/${pageData.activeMenu}`;
  }

  return prePath;
};

const MainPage: React.FC = () => {
  const params = useParams();
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_SETTING_PREFERENCES));
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const savingOption = useRecoilValue(savingPreferencesAtom);
  const navigate = useNavigate();
  const aParams = params['*'] ? params['*'].split('/') : [];
  const activeMenu = aParams[0] ? aParams[0] : 'desk';
  const prePath = getPreviousUrl({ activeMenu }, pageData);
  const pageProps = {
    children: <div>child component</div>,
    menus: SUB_MENUS,
    activeMenu: 'settings/preferences',
    pageTitle: 'Preferences',
    loading: false,
    isFetching: savingOption.isLoading,
    match: {
      params: {
        menu: activeMenu,
      },
    },
  };

  useEffect(() => {
    let hasUpdate = false;
    if (activeMenu != '' && pageData.activeMenu != activeMenu) {
      hasUpdate = true;
    }
    if (hasUpdate) {
      // set default filter
      let newData = {
        ...pageData,
        activeMenu: activeMenu,
      };
      setPageData(newData);
    }
    if (prePath != '') {
      navigate(prePath);
    }
  }, [prePath]);

  //// console.log('pagePros', pageProps);
  return (
    <>
      <BaseLayoutDisplay
        displayConfig={displayLayout}
        device={device}
        layout={layout}
        form="setting"
        {...pageProps}
      />
    </>
  );
};

export default MainPage;
