import ClickOutside from '@base/components/han-click-outside';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

interface ITextInputCustomProps {
  label: any;
  isRequired: boolean;
  setLabel: (params: any) => void;
  isEdit: boolean;
  isBasicField: boolean;
}
const TextInputCustom: React.FC<ITextInputCustomProps> = (props: ITextInputCustomProps) => {
  const {
    label,
    isRequired = false,
    setLabel = () => {},
    isEdit = false,
    isBasicField = false,
  } = props;
  const [labelInline, setLabelInline] = useState(label);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    setLabelInline(label);
  }, [label]);

  useEffect(() => {
    if (isActive != null && isActive == false && labelInline != label) {
      setLabel(labelInline);
    }
  }, [isActive]);

  return (
    <ClickOutside
      onClickOutsite={() => {
        setIsActive(false);
      }}
      onClickInsite={() => setIsActive(true)}
    >
      {(isActive || labelInline == '') && isEdit ? (
        <input
          // ref={ref}
          autoFocus={true}
          className={classNames({
            error: labelInline == '',
            ['txt-custom-field']: !isBasicField,
          })}
          value={labelInline}
          onChange={(e) => setLabelInline(e.target.value)}
          placeholder="No name"
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              // console.log(e);
              setLabelInline(e.target.value);
              setIsActive(false);
            }
          }}
          // onBlur={onBlur}
        />
      ) : (
        <div
          className={classNames('pd-l-3', {
            'is-edit': isEdit,
            ['txt-custom-field']: !isBasicField,
          })}
        >
          {labelInline}
          <span className="is-required">{isRequired && '*'}</span>
        </div>
      )}
    </ClickOutside>
  );
};

export default TextInputCustom;
