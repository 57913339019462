import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { MENU_SETTING_LANDINGPAGE } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { ViewFields } from '@base/components/hook-form/view';
import { Button, Select } from '@base/components/form';
import * as keyNames from '@settings/online-digital-content/landing-page/config/key-names';
import Icon from '@base/assets/icons/svg-icons';
import {
  LANDING_PAGE_PUBLISH_SCHEDULE_LATER,
  LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH,
} from '@settings/online-digital-content/landing-page/config/constants';

interface IViewLeftProps {
  id: string;
}

const ViewLeft: React.FC<IViewLeftProps> = (props) => {
  const { id } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_SETTING_LANDINGPAGE));

  // hidden fields
  const ignoreFields: string[] = [];
  const statusKeyNames: any[] = [
    keyNames.KEY_LANDINGPAGE_STAGE,
    keyNames.KEY_LANDINGPAGE_PUBLISH_DATE,
  ];
  const summaryKeyNames: any[] = [
    keyNames.KEY_LANDINGPAGE_TYPE,
    keyNames.KEY_LANDINGPAGE_LANGUAGE,
    keyNames.KEY_LANDINGPAGE_PRODUCT,
    keyNames.KEY_LANDINGPAGE_AB_TEST,
    keyNames.KEY_LANDINGPAGE_DESCRIPTION,
    keyNames.KEY_LANDINGPAGE_APPROVAL_BY,
    keyNames.KEY_LANDINGPAGE_STAGE,
    keyNames.KEY_LANDINGPAGE_CREATED_BY,
    keyNames.KEY_LANDINGPAGE_CREATED_AT,
    keyNames.KEY_LANDINGPAGE_UPDATED_AT,
  ];
  const optionKeyNames: any[] = [
    keyNames.KEY_LANDINGPAGE_META_KEYWORD,
    keyNames.KEY_LANDINGPAGE_META_DESC,
    keyNames.KEY_LANDINGPAGE_IMPLEMENTING_COST,
    keyNames.KEY_LANDINGPAGE_PUBLISH,
    // keyNames.KEY_LANDINGPAGE_PUBLISH_DATE,
  ];

  // get fields by group
  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];

  const publishData = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_LANDINGPAGE_PUBLISH,
  );

  //get summary fields
  const statusFields: any[] = [];
  const summaryFields: any[] = [];
  const optionFields: any[] = [];
  basicFields.map((_field: any) => {
    if (statusKeyNames.includes(_field.keyName)) {
      statusFields.push(_field);
    }
    if (summaryKeyNames.includes(_field.keyName)) {
      summaryFields.push(_field);
    }
    if (optionKeyNames.includes(_field.keyName)) {
      if (_field.keyName === keyNames.KEY_LANDINGPAGE_PUBLISH_DATE) {
        if (publishData?.data != LANDING_PAGE_PUBLISH_SCHEDULE_UNPUBLISH) {
          optionFields.push(_field);
        }
      } else {
        optionFields.push(_field);
      }
    }
  });

  //render sections
  const SummarySection = useMemo(() => {
    return (
      <>
        <div className="view-aside-section">
          <div className="view-aside-section-header">
            <button type="button" className="view-aside-section-move mg-r-5">
              {Icon('move')}
            </button>
            <span className="view-aside-section-header-title">Summary</span>
            <Button
              color="link link-03"
              className="mg-l-auto pd-0"
              icon="ChevronDown"
              data-toggle="collapse"
              data-target="#abmSummary"
            />
          </div>
          <div id="abmSummary" className="view-aside-section-body collapse fade show">
            <div className="form-row mg-x-0 mg-t-10">
              {/* <ViewFields
              fields={statusFields}
              ignoreFields={ignoreFields}
              menu={viewData?.menu ?? ''}
              menuSource={viewData?.menuSource ?? ''}
              menuSourceId={viewData?.menuSourceId ?? ''}
            /> */}
              <ViewFields
                fields={summaryFields}
                ignoreFields={ignoreFields}
                menu={viewData?.menu ?? ''}
                menuSource={viewData?.menuSource ?? ''}
                menuSourceId={viewData?.menuSourceId ?? ''}
              />
            </div>
          </div>
        </div>
        <div className="view-aside-section">
          <div className="view-aside-section-header">
            <button type="button" className="view-aside-section-move mg-r-5">
              {Icon('move')}
            </button>
            <span className="view-aside-section-header-title">Options</span>
            <Button
              color="link link-03"
              className="mg-l-auto pd-0"
              icon="ChevronDown"
              data-toggle="collapse"
              data-target="#abmOptions"
            />
          </div>
          <div id="abmOptions" className="view-aside-section-body collapse fade show">
            <div className="form-row mg-x-0 mg-t-10">
              <ViewFields
                fields={optionFields}
                ignoreFields={ignoreFields}
                menu={viewData?.menu ?? ''}
                menuSource={viewData?.menuSource ?? ''}
                menuSourceId={viewData?.menuSourceId ?? ''}
              />
            </div>
          </div>
        </div>
      </>
    );
  }, [id, basicFields]);

  return (
    <div className="scroll-box" style={{ height: 'calc(100vh - 110px)' }}>
      {SummarySection}
    </div>
  );
};

export default ViewLeft;
