import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { useSetRecoilState } from 'recoil';

import { OptionValue } from '@base/types/interfaces/common';
import { SUB_MENUS } from '@settings/site/config/sub-menus';
import { SelectBox } from '@base/config/write-field/components';
import { useSite, useSites } from '@settings/site/services/service';
import { SITE_GROUP_OPTION } from '@settings/site/config/constants';
import { useSiteCtaAtomState, useStepSiteAtomState } from '@settings/process/recoil/process';
import { STATUS_BASIC_DATA } from '@settings/process/config/constants';
import { getCtaFromHTML } from '@settings/process/utils/site';
import { RadioGroup } from '@base/components/form';
import { LabelValue } from '@base/types/types/app';
import { StepSiteForm } from '@settings/process/types/process';
import { categoryOptions, initContentOption, initTypeOption, samplePage } from '.';
import statusAtom from '@settings/process/recoil/status';

interface SiteWriteProps {}

function SiteWrite(props: SiteWriteProps) {
  const [stepSite, setStepSite] = useStepSiteAtomState();
  const setStatusesValue = useSetRecoilState(statusAtom);
  const [, setSiteCta] = useSiteCtaAtomState();

  const typeOptions: OptionValue[] = useMemo(() => {
    return [
      initTypeOption,
      ...SUB_MENUS.map((menu) => {
        return {
          keyName: menu.keyName,
          languageKey: menu.languageKey,
        };
      }),
    ];
  }, []);

  const [contentOptions, setContentOptions] = useState<OptionValue[]>([]);

  const handleTypeChange = useCallback((newOption: OptionValue) => {
    setStepSite((cur: StepSiteForm) => {
      return { ...cur, type: newOption, template: initContentOption };
    });
    setContentOptions([initContentOption]);
  }, []);

  const handleContentChange = useCallback((newOption: OptionValue) => {
    setStepSite((cur) => {
      return { ...cur, template: newOption };
    });
    // reset status
    setStatusesValue([
      STATUS_BASIC_DATA({
        id: uuidv4(),
        button: '-',
        name: 'Visited',
        event: 'EVENT_DISABLE',
        direction: 'DIRECTION_FORWARD_INCOMING_MIDDLE',
        definedId: uuidv4(),
        property: 'PROPERTY_TODO',
        sequence: ['0'],
      }),
    ]);
  }, []);

  const handleCategoryChange = useCallback((newOption: LabelValue) => {
    setStepSite((cur) => {
      return { ...cur, category: newOption };
    });
  }, []);

  // get the content date from site content api for selected site type.
  const { data: siteContents } = useSites(
    {
      paging: {
        page: 1,
        size: 1000,
      },
      sort: {
        field: 'createdAt',
        orderBy: 2,
      },
    },
    stepSite.type.keyName == '' ? false : true,
  );

  const { data: siteContent } = useSite(
    stepSite.template.keyName,
    SITE_GROUP_OPTION[stepSite.type.keyName],
  );

  useEffect(() => {
    if (siteContents) {
      let contents: OptionValue[] = [initContentOption];
      for (const content of siteContents.data) {
        contents.push({
          keyName: content.id,
          languageKey: content.name!!,
        });
      }
      setContentOptions(contents);
    }
  }, [siteContents]);

  useEffect(() => {
    if (siteContent && siteContent.properties) {
      const body = JSON.parse(siteContent.properties);
      if (body.Html) {
        // setSiteCta(getCtaFromHTML(body.Html));
        setSiteCta(getCtaFromHTML(samplePage));
        setStepSite((cur: StepSiteForm) => {
          return { ...cur, html: body.Html };
        });
      }
    }
  }, [siteContent]);

  return (
    <div className="form-row">
      <div className="form-group col-6">
        <label className="form-item-title">LandingPage/Site</label>
        <RadioGroup
          isVertical={false}
          options={categoryOptions}
          value={stepSite.category}
          onChange={handleCategoryChange}
        />
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Site Type</label>
        <SelectBox options={typeOptions} value={stepSite.type} onChange={handleTypeChange} />
      </div>
      <div className="form-group col-6">
        <label className="form-item-title">Site Content</label>
        <SelectBox
          options={contentOptions}
          value={stepSite.template}
          onChange={handleContentChange}
        />
      </div>
    </div>
  );
}

export default SiteWrite;
