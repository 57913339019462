import { Button } from '@base/components/form';
import { useListBillingPlan } from '@settings/billing-license/services';
import { IBillingPlan } from '@settings/billing-license/types/interfaces/billing-plan';
import React, { useEffect, useMemo, useState } from 'react';
import BillAndPaymentView from '../plan-view';
import BillAndPaymentWrite from '../plan-write';
import PlanTable from '@settings/billing-license/components/plan-table';
const BillAndPaymentBillingPlan: React.FC = () => {
  const [dataTable, setDataTable] = useState<IBillingPlan[]>([]);
  const { data, isLoading, refetch } = useListBillingPlan('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<any>();
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row: any, rowIndex: any) => {
          return (
            <Button
              outline={false}
              color="white"
              className="border-0"
              onClick={() => {
                setSelectedLicense(row);
                setIsOpenView(true);
              }}
            >
              {row.name}
            </Button>
          );
        },
      },
      {
        Header: 'Billing cycle',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.billingCycle.label}</>;
        },
      },
      {
        Header: 'Start on',
        accessor: (row: any, rowIndex: any) => {
          return <>{row.startDate.toLocaleDateString()}</>;
        },
      },
    ],
    [dataTable],
  );
  useEffect(() => {
    if (data && data?.data) {
      setDataTable(data?.data);
    }
  }, [data]);
  const onChange = (keyName: string, keyValue: any) => {
    // console.log(keyName, keyValue);
    setSelectedLicense({ ...selectedLicense, [keyName]: keyValue });
  };
  return (
    <>
      <PlanTable columns={columns} data={dataTable} setOpenTrue={() => setIsOpen(true)} />
      <BillAndPaymentWrite
        isVisible={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onSubmit={(data: any) => {
          setDataTable([...dataTable, data]);
          setIsOpen(false);
        }}
      />
      <BillAndPaymentView
        isVisible={isOpenView}
        onClose={() => {
          setIsOpenView(false);
        }}
        value={selectedLicense}
        onChange={(keyName: string, keyValue: any) => onChange(keyName, keyValue)}
        onSubmit={(data: any) => {
          // console.log(data);
          const newVal = dataTable.map((_item: any) => {
            if (_item?.id == data.id) return data;
            else return _item;
          });
          setIsOpenView(false);
          setDataTable(newVal);
        }}
      />
    </>
  );
};
export default BillAndPaymentBillingPlan;
