import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import displayLayoutConfig from '@settings/assignment-rule/config/display-layout';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { useParams, useNavigate } from 'react-router-dom';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { SETTING_ASSIGNMENT_RULE } from '@base/config/route-menus';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { DELETE_ASSIGNMENT_RULE } from '@settings/assignment-rule/services/graphql'; //for write
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query'; //v4
import { arWriteOptionAtom } from '@settings/assignment-rule/recoil/atom/write-atom'; //for write

import { emailListFilterState } from '@settings/template/email/recoil/atom/list-atom';
import Title from '@base/containers/view-field/title';
import { KEY_MENU_TEMPLATE_NAME } from '@settings/template/config/key-names';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { MENU_SETTING_CTA, MENU_SETTING_ASSIGNMENT_RULE } from '@base/config/menus';
import { parseFields } from '../main/utils';
import { default as viewConfig } from '@settings/assignment-rule/config/view-field';
import { useGetView } from '@base/services/view';
import ViewCenter from '@settings/assignment-rule/layouts/desktop/layout1/view/view-center';
import ViewLeft from '@settings/assignment-rule/layouts/desktop/layout1/view/view-left';
import Loading from '@base/components/loading';
import NotFound from '@base/components/errors/notFound';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';

const ViewPage = () => {
  // console.log('>>>>>>>>> view page');
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const listFilter = useRecoilValue(emailListFilterState);
  const [viewDataRecoil, setViewData] = useRecoilState(
    viewDataByMenuAtom(MENU_SETTING_ASSIGNMENT_RULE),
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams();
  const { activeId, activeTab } = listFilter;
  let id = activeId != params.id ? params.id ?? '' : activeId ?? '';
  const isSplitMode = false;

  //for write form
  const [writeOption, setWriteOption] = useRecoilState(arWriteOptionAtom);

  // delete mutation
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_ASSIGNMENT_RULE,
    'setting_deleteAssignmentRule',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Deleted Assignment Rule successfully!');
        setTimeout(() => {
          queryClient.invalidateQueries(['setting_menuTemplates']);
        }, 500);
        return navigate(`/settings/assignment-rule/list`);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        toast.error('Deleted Assignment Rule failed: ' + JSON.parse(error).message);
      },
    },
  );

  /** Hook + Get data */
  let { data: layoutView, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    MENU_SETTING_CTA,
    'view',
  );
  layoutView = parseFields(layoutView, 'rule');
  // // console.log('layoutView', layoutView);
  const viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  const {
    isLoading,
    data: viewData,
    refetch,
  } = useGetView<any>({ menu: 'setting_assignmentRule', schemas: viewSchema, params: { id } });
  useEffect(() => {
    //if (!isLoading && !isLayoutLoading && campaignData) {
    const layoutData = mergeLayoutData(layoutView, viewData, viewConfig);
    let newLayoutData: any = { ...layoutData };
    newLayoutData.menuSourceId = id;
    newLayoutData.menuSource = MENU_SETTING_ASSIGNMENT_RULE;
    setViewData(newLayoutData);
    //}
  }, [viewData, isLoading, isLayoutLoading]);
  // render
  const renderView = () => {
    const pageProps: IDestopLayout1ViewProps = {
      headerProps: {
        menu: 'assignment-rule', //key
        menus: SETTING_ASSIGNMENT_RULE,
        isDeleting: false,
        onDelete: () => mutationDelete.mutate({ ids: [id] }),
        onNew: (category: string | undefined) => {
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: 'assignment-rule',
            writeMenu: 'view',
          }));
        },
        splitViewMode: isSplitMode,
        componentLeft: (
          <>
            <Title
              keyName={KEY_MENU_TEMPLATE_NAME}
              value={viewData?.[KEY_MENU_TEMPLATE_NAME]}
              userPermission={{ isEdit: true }}
              onSave={(params: any) => {}}
              menuSourceId={id}
              menuSource={MENU_SETTING_ASSIGNMENT_RULE}
              viewConfig={viewConfig}
            />
          </>
        ),
      },
      /*centerProps: {
        showTop: false, //send to all tab's component
        menuSource: MENU_SETTING_ASSIGNMENT_RULE, //send to all tab's component
        menuSourceId: id, //send to all tab's component
        tabs: getTabs(),
        currentTab: currentTab,
      },*/
      componentTop: null,
      componentLeft: <ViewLeft id={id} />,
      leftProps: {},
      componentCenter: <ViewCenter id={id} refetch={refetch} />,
      componentRight: null,
      rightProps: {},
    };

    return (
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={device}
        layout={layout}
        form="view"
        {...pageProps}
      />
    );
  };
  // render
  return <>{isLoading || isLayoutLoading ? <Loading /> : viewData ? renderView() : <NotFound />}</>;
};

export default ViewPage;
