// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navata-help-type-field{display:inline !important}.navata-help-type-field .cursor-pointer{display:inline !important}.navata-help-type-field .dot-type{width:14px;height:14px;border-radius:50%;border:solid 1px #fff;margin-right:5px}", "",{"version":3,"sources":["webpack://./src/settings/page-layout/containers/help-type-field/styles.scss"],"names":[],"mappings":"AAAA,wBACE,yBAAA,CACA,wCACE,yBAAA,CAEF,kCACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,qBAAA,CACA,gBAAA","sourcesContent":[".navata-help-type-field {\n  display: inline !important;\n  .cursor-pointer {\n    display: inline !important;\n  }\n  .dot-type {\n    width: 14px;\n    height: 14px;\n    border-radius: 50%;\n    border: solid 1px white;\n    margin-right: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navata-help-type-field": "navata-help-type-field",
	"cursor-pointer": "cursor-pointer",
	"dot-type": "dot-type"
};
export default ___CSS_LOADER_EXPORT___;
