import Icon from '@base/assets/icons/svg-icons';
import React, { lazy } from 'react';
import { ChevronDown } from 'react-feather';
import { MENU_SETTING_ASSIGNMENT_RULE, MENU_TEMPLATE_EMAIL } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { useRecoilValue } from 'recoil';
//import * as keyNames from '@settings/template/config/key-names';
import * as keyNames from '@settings/assignment-rule/config/key-names';
import GrapesTSViewField from '@base/containers/view-field/grapes-ts';
//import { default as viewConfig } from '@settings/online-digital-content/landing-page/config/view-field';
import { default as viewConfig } from '@settings/template/config/view-field/email/index';
import { ViewFields } from '@base/components/hook-form/view';
import { slideToMapByKey, filtersArray } from '@base/utils/helpers/array-utils';

const Notes = lazy(() => import('@base/containers/note'));

const ViewCenter: React.FC<any> = (props: any) => {
  // console.log('ViewCenter >>>> ');
  const { id } = props;
  const column = 1;
  const ignoreFields: [] = [];
  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_SETTING_ASSIGNMENT_RULE));
  const moreFields = viewData?.layout?.data?.[0]?.children;

  let detailFields = filtersArray(moreFields, [keyNames.KEY_NAME_ASSIGNMENT_RULE_ENTRIES]);

  return (
    <div className="pd-15 scroll-box" style={{ height: 'calc(100vh - 100px)' }}>
      <ViewFields
        fields={detailFields}
        column={column}
        ignoreFields={ignoreFields}
        menu={viewData?.menu ?? ''}
        menuSource={viewData?.menuSource ?? ''}
        menuSourceId={viewData?.menuSourceId ?? ''}
        onRefetch={props?.refetch}
      />
    </div>
  );
};

export default ViewCenter;
