import { SpanLang } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { UPDATE_SYSTEM_LANGUAGE } from '@base/services/graphql/sys_language';
import { getCurrentLang } from '@base/services/i18n';
import { ILanguageData } from '@base/types/interfaces/common';
import { CONFIG } from '@settings/page-layout/config/common';
import {
  UPDATE_PAGE_LAYOUT_FIELD,
  UPDATE_PAGE_LAYOUT_TPL_FIELD,
} from '@settings/page-layout/services/graphql/pagelayout';
import { EPermissionType } from '@settings/page-layout/types/enums';
import { IField } from '@settings/page-layout/types/interfaces/pagelayout';
import { setValueFromAttributes } from '@settings/page-layout/utils/helpers';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import ItemView from '../item-view';

interface ISectionProps {
  data: any;
  onUpdateItemSection: (params: any) => void;
  id: string;
  itemDragging: any;
  disabled: boolean;
  items: IField[];
  onEditItemSection: (params: any) => void;
  onUnusedItemSection: (params: any) => void;
  onDeleteItemSection: (params: any) => void;
  onDeleteSection: () => void;
  isLoading: boolean;
}
const Section: React.FC<ISectionProps> = (props: ISectionProps) => {
  const {
    data,
    onUpdateItemSection,
    id,
    items,
    itemDragging,
    disabled,
    onEditItemSection,
    onUnusedItemSection,
    onDeleteItemSection,
  } = props;
  const currentLang = getCurrentLang();
  const mUpdateField: any = useMutationPost<IField>(
    UPDATE_PAGE_LAYOUT_FIELD,
    'setting_updateField',
  );
  const mUpdateTplField: any = useMutationPost<IField>(
    UPDATE_PAGE_LAYOUT_TPL_FIELD,
    'setting_updateTemplateField',
  );
  const mUpdateSysLanguage: any = useMutationPost<ILanguageData>(
    UPDATE_SYSTEM_LANGUAGE,
    'setting_updateSystemLanguage',
  );

  const onHandleRequire = ({ item, checked, index }: any) => {
    const attributesNew = setValueFromAttributes({
      attributes: item.attributes,
      keyMapping: {
        required: checked ? '1' : '0',
      },
    });
    // console.log('attributesNew', attributesNew);
    mUpdateField.mutate(
      {
        pgField: {
          id: item.id,
          attributes: attributesNew,
        },
      },
      {
        onSuccess: (res: any) => {
          onUpdateItemSection({
            item: {
              ...item,
              isRequired: checked,
              attributes: attributesNew,
            },
            index: index,
            section: props.data.section,
          });
          toast.success('Data was updated!');
        },
      },
    );
  };
  const onHandleShowIn = ({ locationsDisplay, item, index }: any) => {
    // console.log('Item ===>>', item, props);
    mUpdateTplField.mutate(
      {
        id: item.id,
        tplField: {
          id: item.templateFieldId,
          showInList: locationsDisplay.showInList,
          showInView: locationsDisplay.showInView,
          showInWrite: locationsDisplay.showInWrite,
        },
      },
      {
        onSuccess: (res: any) => {
          onUpdateItemSection({
            item: {
              ...item,
              ...locationsDisplay,
            },
            index: index,
            section: props.data.section,
          });
          toast.success('Data was updated!');
        },
      },
    );
  };
  const onHandleUpdateLabel = ({ item, label, index }: any) => {
    let pgField = {
      id: item.id,
      languageKey: item.languageKey,
      language: {
        ...item.language,
        [currentLang]: label,
      },
    };
    mUpdateField.mutate(
      {
        pgField: pgField,
      },
      {
        onSuccess: (res: any) => {
          let newSysLang: ILanguageData = {
            langKey: pgField.languageKey,
            menu: 'pagelayout',
            en: pgField.language ? pgField.language.en : '',
            vi: pgField.language ? pgField.language.vi : '',
            ko: pgField.language ? pgField.language.ko : '',
            jp: pgField.language ? pgField.language.jp : '',
            zh: pgField.language ? pgField.language.zh : '',
            ido: pgField.language ? pgField.language.ido : '',
          };
          mUpdateSysLanguage.mutate({ systemLanguage: newSysLang });
          onUpdateItemSection({
            item: {
              ...item,
              languag: {
                ...item.language,
                [currentLang]: label,
              },
            },
            index: index,
            section: props.data.section,
          });
          toast.success('Data was updated!');
        },
      },
    );
  };
  return (
    <>
      <Droppable
        droppableId={props.id}
        isDropDisabled={
          itemDragging?.source?.droppableId == CONFIG.sections ||
          itemDragging?.source?.droppableId == CONFIG.container ||
          disabled
        }
      >
        {(provided, snapshot) => (
          <ul
            ref={provided.innerRef}
            className="shopping-bag"
            style={{
              padding: 5,
              margin: 4,
              borderRadius: 4,
              backgroundColor: snapshot.isDraggingOver
                ? 'var(--background-hover-color)'
                : 'var(--background-container)',
            }}
          >
            {items && items.length == 0 ? (
              snapshot.isDraggingOver ? null : (
                <div className="decription-drag-drop tx-gray-500">
                  <SpanLang keyLang="crm_new_pagelayout_msg_drag_drop_section" />
                </div>
              )
            ) : (
              items.map((item: IField, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={!item?.permission?.canMovePosition}
                >
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={provided.draggableProps.style}
                    >
                      <ItemView
                        key={item.id}
                        permissionType={
                          item.permissionType ? item.permissionType : EPermissionType.CUSTOM
                        }
                        attributes={item?.attributes ?? []}
                        options={item?.options ?? []}
                        isLoading={item.isLoading ?? false}
                        locationsDisplay={{
                          showInList: item.showInList,
                          showInView: item.showInView,
                          showInWrite: item.showInWrite,
                          // isEdit: item.showInEmployeePage,
                          // isEdit: true,
                        }}
                        icon={item?.icon?.icon ?? ''}
                        iconType={item?.icon?.iconType ?? 'icon'}
                        permission={item.permission}
                        setLocationsDisplay={(locationsDisplay: any) => {
                          onHandleShowIn({ locationsDisplay, item, index });
                        }}
                        dataType={item.dataType ? item.dataType : 'text'}
                        // label={<SpanLang keyLang={item.languageKey} />}
                        label={
                          item.language && item.language[currentLang as keyof ILanguageData]
                            ? item.language[currentLang as keyof ILanguageData]
                            : ''
                        }
                        // type={item.type}
                        setLabel={(label: any) => onHandleUpdateLabel({ item, label, index })}
                        isRequired={item.isRequired ? item.isRequired : false}
                        // element={item.element}
                        setIsRequired={(checked: any) => {
                          onHandleRequire({
                            item: item,
                            checked: checked,
                            index: index,
                          });
                        }}
                        onUnused={() =>
                          onUnusedItemSection({
                            item: item,
                            index: index,
                            section: props.data.section,
                          })
                        }
                        // onSetting={() => alert('It will display setting modal.')}
                        onEdit={() =>
                          onEditItemSection({
                            item: item,
                            index: index,
                            section: props.data.section,
                          })
                        }
                        onDelete={() =>
                          onDeleteItemSection({
                            item: item,
                            index: index,
                            section: props.data.section,
                          })
                        }
                      />
                    </li>
                  )}
                </Draggable>
              ))
            )}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </>
  );
};

export default Section;
