import React from 'react';
import Criteria from '../../criteria';

interface ICriteriaEditProps {
  value: any;
  onChange: (params: any) => {};
}
const CriteriaEdit: React.FC<ICriteriaEditProps> = (props: ICriteriaEditProps) => {
  const { value, onChange } = props;
  return (
    <>
      <Criteria value={value} onChange={onChange} />
    </>
  );
};

export default CriteriaEdit;
