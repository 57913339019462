import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icons from '@base/assets/icons/svg-icons';
import { ChevronLeft, ChevronRight } from 'react-feather';
import {
  Q_MULTI_CHOICES,
  Q_CHECKBOXES,
  Q_DROPDOWN,
  Q_SHORT_ANSWER,
  Q_PARAGRAPH,
  Q_FILE_UPLOAD,
  Q_DATE,
  Q_TIME,
  Q_MULTI_CHOICES_GRID,
  Q_TICK_BOX_GRID,
} from '@settings/online-digital-content/survey/config/constants';
import QuestionStaticView from '@settings/online-digital-content/survey/components/question-static-view';
import OptionResponse from './option-response';
import { SpanLang } from '@base/components';
import classNames from 'classnames';

/**
 * Response by Question
 * @param {*} props
 */
const Question: React.FC<any> = (props) => {
  const { responseS, listQ } = props;
  const { t } = useTranslation();
  //local state
  const [selectedQ, setSelectedQ] = useState<any>(null);
  const [selectedIdx, setSelectedIdx] = useState<number>(1);
  const [answerOptions, setAnswerOptions] = useState<any>([]);

  //set init selected questions
  useEffect(() => {
    const initSelectedQ = listQ.length > 0 ? listQ[0] : null;
    setSelectedQ(initSelectedQ);
  }, [listQ]);

  //track survey question change
  useEffect(() => {
    //// console.log('responseS', responseS); //? sometimes it is empty []
    if (selectedQ) {
      //get response of selected questions
      //survey id, question id = selectedIdx
      let newAnsOptions: any = [
        // {
        //     option: [], //leave it blank, default
        //     count: 0
        // },
        // {
        //     option: [{}], //having answer
        //     count: 0
        // },
      ];
      const typeQ = selectedQ.type;
      //get responses of selected question survey
      //const surveyResponses = responseS.filter((resp: any) => resp.surveyId == selectedQ.surveyId);

      //each response
      responseS.map((surResp: any) => {
        //selectedQ.id = index of question in survey template
        let respQ: any = null;
        let curQuestion: any = null;
        switch (typeQ) {
          case Q_MULTI_CHOICES:
          case Q_DROPDOWN:
            //get questions by section
            respQ = surResp.sections[selectedQ.sectionId]; //get section of survey
            curQuestion = respQ?.questions?.find((_ele: any) => _ele.title == selectedQ.title);
            //count answer
            let hasAnswer = false;
            //respQ &&
            //respQ.questions.length > selectedQ.id &&
            //respQ.questions[selectedQ.id].type === typeQ &&
            //respQ.questions[selectedQ.id].options.map((opt: any, idx: number) => {
            curQuestion &&
              curQuestion?.options?.map((opt: any, idx: number) => {
                if (opt.checked) {
                  hasAnswer = true;
                  //check exist
                  let isExist = false;

                  let vOption: string;
                  if (opt.isOther) {
                    vOption = opt.answer;
                  } else {
                    vOption = opt.value;
                  }

                  newAnsOptions.map((ansOpt: any) => {
                    if (ansOpt.options.length > 0 && ansOpt.options[0].value === vOption) {
                      //question content, one by one
                      ansOpt.count += 1;
                      isExist = true;
                    }
                  });
                  if (!isExist) {
                    newAnsOptions.push({
                      options: [{ ...opt, value: vOption }], // one by one
                      count: 1,
                    });
                  }
                }
              });
            //blank answer
            if (!hasAnswer) {
              //blank answer
              let isExist = false;
              newAnsOptions.map((ansOpt: any) => {
                if (ansOpt.options && ansOpt.options.length === 0) {
                  //check option no answer
                  ansOpt.count += 1;
                  isExist = true;
                }
              });
              if (!isExist) {
                newAnsOptions.push({
                  options: [], // blank answer
                  count: 1,
                });
              }
            }
            break;
          case Q_CHECKBOXES:
            //get questions by section
            respQ = surResp.sections[selectedQ.sectionId]; //get section of survey
            curQuestion = respQ?.questions?.find((_ele: any) => _ele.title == selectedQ.title);

            //get checked checkbox
            const checkedOpts =
              //respQ &&
              //respQ.questions.length > selectedQ.id &&
              //respQ.questions[selectedQ.id].type === typeQ
              //? respQ.questions[selectedQ.id].options.filter((opt: any) => opt.checked)
              curQuestion ? curQuestion?.options?.filter((opt: any) => opt.checked) : [];
            if (checkedOpts.length > 0) {
              //check this exist in answerOptions
              let isExist = false;
              for (let i = 0; i < newAnsOptions.length; i++) {
                let ansOpt = newAnsOptions[i];
                //ansOpt.options is an array of optiona combination
                let isQual = true;
                if (ansOpt.options.length == checkedOpts.length) {
                  for (let j = 0; j < checkedOpts.length; j++) {
                    let vOption: string = ansOpt.options[j].isOther
                      ? ansOpt.options[j].answer
                      : ansOpt.options[j].value;
                    let cOption: string = checkedOpts[j].isOther
                      ? checkedOpts[j].answer
                      : checkedOpts[j].value;

                    checkedOpts[j].value = cOption;
                    ansOpt.options[j].value = vOption;

                    // if (ansOpt.options[j].value !== checkedOpts[j].value) {
                    if (vOption !== cOption) {
                      isQual = false;
                      break;
                    }
                  }
                } else {
                  isQual = false;
                }
                //if equal, increase count
                if (isQual) {
                  ansOpt.count += 1;
                  isExist = true;
                  break;
                }
              }
              //not having option combination, add new
              if (!isExist) {
                newAnsOptions.push({
                  options: checkedOpts,
                  count: 1,
                });
              }
            } else {
              //blank answer
              let isExist = false;
              newAnsOptions.map((ansOpt: any) => {
                if (ansOpt.options && ansOpt.options.length === 0) {
                  //check option no answer
                  ansOpt.count += 1;
                  isExist = true;
                }
              });
              if (!isExist) {
                newAnsOptions.push({
                  options: [], // blank answer
                  count: 1,
                });
              }
            }
            break;
          case Q_SHORT_ANSWER:
          case Q_PARAGRAPH:
          case Q_DATE:
          case Q_TIME:
            //get questions by section
            respQ = surResp.sections[selectedQ.sectionId]; //get section of survey
            curQuestion = respQ?.questions?.find((_ele: any) => _ele.title == selectedQ.title);

            if (
              //respQ &&
              //respQ.questions.length > selectedQ.id &&
              //respQ.questions[selectedQ.id].type === typeQ
              curQuestion
            ) {
              //const ansQ = respQ.questions[selectedQ.id]; //this question contain answer
              const ansQ = curQuestion;
              if (ansQ.options[0].answer) {
                //this question only has ONE option
                //check exist
                let isExist = false;
                newAnsOptions.map((ansOpt: any) => {
                  if (ansOpt.options.length > 0) {
                    //check option having answer
                    //check exist in answer list
                    if (ansOpt.options[0].answer === ansQ.options[0].answer) {
                      //question content, one by one
                      ansOpt.count += 1;
                      isExist = true;
                    }
                  }
                });
                if (!isExist) {
                  newAnsOptions.push({
                    options: [ansQ.options[0]], // one by one
                    count: 1,
                  });
                }
              } else {
                //blank answer
                let isExist = false;
                newAnsOptions.map((ansOpt: any) => {
                  if (ansOpt.options && ansOpt.options.length === 0) {
                    //check option no answer
                    ansOpt.count += 1;
                    isExist = true;
                  }
                });
                if (!isExist) {
                  newAnsOptions.push({
                    options: [], // blank answer
                    count: 1,
                  });
                }
              }
            }
            break;
          case Q_FILE_UPLOAD:
            //get questions by section
            respQ = surResp.sections[selectedQ.sectionId]; //get section of survey
            curQuestion = respQ?.questions?.find((_ele: any) => _ele.title == selectedQ.title);

            if (
              //respQ &&
              //respQ.questions.length > selectedQ.id &&
              //respQ.questions[selectedQ.id].type === typeQ
              curQuestion
            ) {
              //const ansQ = respQ.questions[selectedQ.id]; //this question contain answer
              const ansQ = curQuestion;
              if (ansQ.options[0].answer) {
                //this question only has ONE option
                newAnsOptions.push({
                  options: [ansQ.options[0]], // one by one
                  count: 1,
                });
              } else {
                //blank answer
                let isExist = false;
                newAnsOptions.map((ansOpt: any) => {
                  if (ansOpt.options && ansOpt.options.length === 0) {
                    //check option no answer
                    ansOpt.count += 1;
                    isExist = true;
                  }
                });
                if (!isExist) {
                  newAnsOptions.push({
                    options: [], // blank answer
                    count: 1,
                  });
                }
              }
            }
            break;
          case Q_MULTI_CHOICES_GRID:
          case Q_TICK_BOX_GRID:
            //get questions by section
            respQ = surResp.sections[selectedQ.sectionId]; //get section of survey
            curQuestion = respQ?.questions?.find((_ele: any) => _ele.title == selectedQ.title);

            if (
              //respQ &&
              //respQ.questions.length > selectedQ.id &&
              //respQ.questions[selectedQ.id].type === typeQ &&
              //respQ.questions[selectedQ.id].options.answer
              curQuestion &&
              curQuestion.options.answer
            ) {
              //answer not empty
              //const ansQOption = respQ.questions[selectedQ.id].options;
              const ansQOption = curQuestion.options;
              //check exist
              let isExist = false;
              newAnsOptions.map((ansOpt: any) => {
                if (ansOpt.options && ansOpt.options.length > 0) {
                  if (
                    JSON.stringify(ansOpt.options[0].answer) === JSON.stringify(ansQOption.answer)
                  ) {
                    //question content, one by one
                    ansOpt.count += 1;
                    isExist = true;
                  }
                }
              });
              if (!isExist) {
                newAnsOptions.push({
                  options: [ansQOption], // one by one
                  count: 1,
                });
              }
            } else {
              //blank answer
              let isExist = false;
              newAnsOptions.map((ansOpt: any) => {
                if (ansOpt.options && ansOpt.options.length === 0) {
                  //check option no answer
                  ansOpt.count += 1;
                  isExist = true;
                }
              });
              if (!isExist) {
                newAnsOptions.push({
                  options: [], // blank answer
                  count: 1,
                });
              }
            }
            break;
          default:
            break;
        }
      });

      // // console.log('newAnsOptions', newAnsOptions);
      //set state
      setAnswerOptions(newAnsOptions);
    }
  }, [selectedQ]);

  //select other question
  function handleQuestionChange(idx: number) {
    setAnswerOptions([]); //set empty
    setSelectedIdx(idx + 1);
    setSelectedQ(listQ[idx]);
  }

  //change question index
  function handleQIndexChange(e: any) {
    setAnswerOptions([]); //set empty
    let value = e.target.value;
    setSelectedIdx(value);

    //set selected question
    if (value > 0 && value <= listQ.length) {
      setSelectedQ(listQ[value - 1]);
    }
  }

  //next question
  function handleNextIdx() {
    let newIdx = selectedIdx + 1;
    //just set empty when < max
    if (newIdx < listQ.length) {
      setAnswerOptions([]); //set empty
    }
    if (newIdx > listQ.length) {
      newIdx = listQ.length;
    }
    setSelectedIdx(newIdx);
    //set selected question
    setSelectedQ(listQ[newIdx - 1]);
  }

  //previous question
  function handlePrevIdx() {
    let newIdx = selectedIdx - 1;
    //just set empty when > 1
    if (newIdx > 1) {
      setAnswerOptions([]); //set empty
    }
    if (newIdx < 1) {
      newIdx = 1;
    }
    setSelectedIdx(newIdx);
    //set selected question
    setSelectedQ(listQ[newIdx - 1]);
  }

  //// console.log('selectedQ', selectedQ);
  // // console.log('answerOptions', answerOptions);
  //component render
  return (
    <div className="pos-relative">
      {/* questions select */}
      <div
        className="card"
        style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0, borderTop: 0 }}
      >
        <div className="card-body">
          <div className="d-flex flex-row justify-content-start">
            {/* questions selection */}
            <div
              className="d-flex justify-content-center align-items-center mg-r-20"
              style={{ minWidth: '150px' }}
            >
              <a
                className="d-flex align-items-center justify-content-center btn btn-white ht-40 w-100"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>{selectedQ && selectedQ.title}</span>
                {Icons('down')}
              </a>
              <ul className="dropdown-menu">
                {listQ.map((question: any, idx: number) => (
                  <li
                    key={idx}
                    className={classNames('dropdown-item', {
                      active: selectedQ?.title == question?.title,
                    })}
                    onClick={() => handleQuestionChange(idx)}
                  >
                    {question.title ? question.title : t('Untitled question') + ' ' + idx}
                  </li>
                ))}
              </ul>
            </div>
            {/* question number select */}
            <div className="d-flex flex-row justify-content-between align-items-center wd-200">
              <div className="btn survey-home-btn-more-big" onClick={handlePrevIdx}>
                <ChevronLeft />
              </div>
              <input
                type="number"
                min={1}
                max={listQ.length}
                className="wd-60 tx-center survey-write-input"
                value={selectedIdx}
                onChange={handleQIndexChange}
              />
              <span className="tx-16">
                <SpanLang keyLang="of" />
              </span>
              <span className="tx-16">{listQ.length}</span>
              <div className="btn survey-home-btn-more-big" onClick={handleNextIdx}>
                <ChevronRight />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="pos-relative mg-t-10 pd-b-10 scroll-box"
        style={{ height: 'calc(100vh - 390px)' }}
      >
        {/* question content view */}
        {selectedQ && (
          <QuestionStaticView
            keyQ={selectedIdx}
            selectedQType={selectedQ.type}
            title={selectedQ.title}
            required={selectedQ.required}
            image={selectedQ.image}
            options={selectedQ.options}
          />
        )}

        {/* question options */}
        {answerOptions.map(
          (item: any, idx: number) =>
            item.count > 0 && (
              <OptionResponse
                key={idx}
                type={selectedQ.type}
                count={item.count}
                options={item.options}
              />
            ),
        )}
      </div>
    </div>
  );
};

export default Question;
