import React, { useState, useEffect, Fragment, useRef } from 'react';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import Button from '@base/components/form/button';
import { ArrowLeft, ArrowRight, ChevronUp } from 'react-feather';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import Loading from '@base/components/loading';
import * as keyNames from '@settings/site/config/key-names';
import { Input, Select } from '@base/components/form';
import { SITE_TYPE_OPTIONS } from '@settings/site/config/constants';
import LanguageSelect from '@base/containers/language-select';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import classNames from 'classnames';
import SiteTemplateSelect from '@settings/template/containers/site-template-select';
import { captureTheImage } from '@settings/template/utils/helper';
import {
  ADD_SITE_TEMPLATE,
  GET_SITE_DETAIL,
  UPDATE_SITE_TEMPLATE,
} from '@settings/site/services/graphql';
import usePost from '@base/hooks/usePost';
const GrapesTS = React.lazy(() => import('@base/components/grapes-ts'));
//import { finalizeParams } from './payload';

const TABS = [
  {
    value: 1,
    label: 'Properties',
    keys: [
      keyNames.KEY_SITE_NAME,
      keyNames.KEY_SITE_TYPE,
      keyNames.KEY_SITE_LANGUAGE,
      keyNames.KEY_SITE_PRODUCT,
      keyNames.KEY_SITE_DESCRIPTION,
    ],
  },
  {
    value: 2,
    label: 'Select a Template',
    keys: [keyNames.KEY_SITE_TEMPLATE],
  },
  {
    value: 3,
    label: 'Design',
    keys: [keyNames.KEY_SITE_SUBJECT, keyNames.KEY_SITE_DESGIN],
  },
];

interface WriteFormProps {
  id?: string;
  fullScreen: boolean;
  onClose: () => void;
  onReload?: () => void;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<WriteFormProps> = (props: WriteFormProps) => {
  const {
    id = '', //for edit
    fullScreen,
    onClose,
    onReload,
  } = props;
  //// console.log('config defaultValues', defaultValues);

  //state
  const [formStep, setFormStep] = useState(TABS[0].value);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    //getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      [keyNames.KEY_SITE_NAME]: '',
      [keyNames.KEY_SITE_TYPE]: SITE_TYPE_OPTIONS[0],
      [keyNames.KEY_SITE_LANGUAGE]: 'en',
      [keyNames.KEY_SITE_PRODUCT]: [],
      [keyNames.KEY_SITE_DESCRIPTION]: '',
      [keyNames.KEY_SITE_TEMPLATE]: null,
      [keyNames.KEY_SITE_SUBJECT]: '',
      [keyNames.KEY_SITE_DESGIN]: '',
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //get site for edit
  const { data: postData, isLoading: isPostLoading } = usePost<any>(
    ['setting_siteTemplate', id],
    GET_SITE_DETAIL,
    { id },
    { enabled: id?.length > 0 },
  );

  //init data
  useEffect(() => {
    if (id) {
      //// console.log('postData', postData);
      if (!isPostLoading && postData) {
        if (postData[keyNames.KEY_SITE_NAME]) {
          setValue(keyNames.KEY_SITE_NAME, postData[keyNames.KEY_SITE_NAME], {
            shouldValidate: true,
          });
        }
        if (postData[keyNames.KEY_SITE_LANGUAGE]) {
          setValue(keyNames.KEY_SITE_LANGUAGE, postData[keyNames.KEY_SITE_LANGUAGE]);
        }
        if (postData[keyNames.KEY_SITE_PRODUCT]) {
          setValue(keyNames.KEY_SITE_PRODUCT, postData[keyNames.KEY_SITE_PRODUCT]);
        }
        if (postData[keyNames.KEY_SITE_DESCRIPTION]) {
          setValue(keyNames.KEY_SITE_DESCRIPTION, postData[keyNames.KEY_SITE_DESCRIPTION]);
        }
        if (postData[keyNames.KEY_SITE_SUBJECT]) {
          setValue(keyNames.KEY_SITE_SUBJECT, postData[keyNames.KEY_SITE_SUBJECT]);
        }
        if (postData.properties) {
          try {
            setValue(keyNames.KEY_SITE_DESGIN, JSON.parse(postData.properties));
          } catch (e) {
            toast.error('Site data parse error.');
          }
        }
      }
    } else {
      reset();
    }
  }, [id, postData]);

  //create
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    ADD_SITE_TEMPLATE,
    'setting_createSiteTemplate',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Created Site successfully!');
        // refecth data
        //onReload && onReload();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Created Site failed: ' + JSON.parse(error).message);
      },
    },
  );

  //update
  const mutationUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_SITE_TEMPLATE,
    'setting_updateSiteTemplate',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Updated Site successfully!');
        // refecth data
        //onReload && onReload();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Updated Site failed: ' + JSON.parse(error).message);
      },
    },
  );

  //watch
  const templateValue = watch(keyNames.KEY_SITE_TEMPLATE);

  useEffect(() => {
    if (templateValue) {
      setValue(keyNames.KEY_SITE_DESGIN, templateValue);
    }
  }, [templateValue]);

  //create success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationAdd.isSuccess) {
      //refecth data
      onReload && onReload();
      onClose();
    }
  }, [mutationAdd.isSuccess]);

  //update success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationUpdate);
    if (mutationUpdate.isSuccess) {
      //refecth data
      onReload && onReload();
      onClose();
    }
  }, [mutationUpdate.isSuccess]);

  //get thumbnail base64
  async function getThumbnail(body: any) {
    const thumbnailBase64 = await captureTheImage(body, 'desk_thumbnail');
    return thumbnailBase64;
  }

  //submit form
  const onSubmit = async (formData: any) => {
    //// console.log('formData', formData);
    let designThumbnailBase64: any = '';
    if (formData[keyNames.KEY_SITE_DESGIN].body) {
      const designBody = formData[keyNames.KEY_SITE_DESGIN].body;
      designThumbnailBase64 = await getThumbnail(designBody);
    }
    //// console.log('designThumbnailBase64', designThumbnailBase64);
    // //TEST preview
    // const imgPreview: any = document.getElementById('img-preview');
    // if (imgPreview) {
    //   imgPreview.src = designThumbnailBase64;
    // }

    //build add params and save
    const params: any = {
      name: formData[keyNames.KEY_SITE_NAME],
      description: formData[keyNames.KEY_SITE_DESCRIPTION],
      siteGroup: 'SITE_GROUP_DESK',
      properties: JSON.stringify({
        html: formData[keyNames.KEY_SITE_DESGIN].html,
        css: formData[keyNames.KEY_SITE_DESGIN].css,
      }),
    };
    if (designThumbnailBase64) {
      params.thumbnail = designThumbnailBase64;
    }
    if (id.length > 0) {
      params.id = id;
      mutationUpdate.mutate({ siteTemplate: params });
    } else {
      mutationAdd.mutate({ siteTemplate: params });
    }
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    //// console.log('error', errors, e);
    toast.error('Created Site Template failed: ' + JSON.stringify(errors));
  };

  /** ============================== EVENT ==================================== */
  //go to next step - set data
  const goNextStep = () => {
    setFormStep((cur) => cur + 1);
    if (formStep + 1 === 2) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null, { shouldValidate: true });
    }
    if (formStep + 1 === 3) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null, { shouldValidate: true });
    }
  };

  //go previous step - reset data
  const goPreviousStep = () => {
    setFormStep((cur) => cur - 1);
    if (formStep - 1 === 2) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null);
    }
    if (formStep - 1 === 1) {
      //setValue(keyNames.KEY_NAME_MARKETING_LOYALTY_EARN, null);
    }
  };

  //======================== RENDER FORM ========================//
  //step tabs
  const renderFormSteps = () => {
    return (
      <div className="w-100 pd-b-10">
        <ul className="steps steps-justified steps-tab w-100">
          {TABS.map((_item: any, index: number) => (
            <li
              key={index}
              className={classnames('step-item', {
                complete: _item.value < formStep,
                active: _item.value == formStep,
                disabed: _item.value > formStep,
              })}
            >
              <button
                type="button"
                className="btn step-link rounded-0"
                //onClick={(e) => setFormStep(_item.value)}
              >
                <span className="step-number">{_item.value}</span>
                <span className="step-title">{_item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  //form view
  const renderForm = () => {
    return (
      <div className="form-row pd-x-20">
        {formStep >= 1 && (
          <>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">
                Name<span className="tx-danger">*</span>
              </label>
              <Controller
                name={keyNames.KEY_SITE_NAME}
                control={control}
                rules={{ required: true }}
                //defaultValue={'BUTTON'}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">
                Type<span className="tx-danger">*</span>
              </label>
              <Controller
                name={keyNames.KEY_SITE_TYPE}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'BUTTON'}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isDisabled
                    options={SITE_TYPE_OPTIONS}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Language</label>
              <Controller
                name={keyNames.KEY_SITE_LANGUAGE}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <LanguageSelect value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Product</label>
              <Controller
                name={keyNames.KEY_SITE_PRODUCT}
                control={control}
                //rules={{ required: true }}
                //defaultValue={'en'}
                render={({ field: { onChange, value } }) => (
                  <ProductAutoComplete value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 1 })}>
              <label className="form-item-title">Description</label>
              <Controller
                name={keyNames.KEY_SITE_DESCRIPTION}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
          </>
        )}
        {formStep >= 2 && (
          <>
            <div className={classNames('form-group pd-0 col-12', { 'd-none': formStep !== 2 })}>
              <Controller
                name={keyNames.KEY_SITE_TEMPLATE}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SiteTemplateSelect value={value} onChange={onChange} />
                )}
              />
            </div>
          </>
        )}
        {formStep === 3 && (
          <>
            <div className={classNames('form-group pd-0 col-12')}>
              <label className="form-item-title">Subject</label>
              <Controller
                name={keyNames.KEY_SITE_SUBJECT}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input type="text" value={value} onChange={onChange} />
                )}
              />
            </div>
            <div className={classNames('form-group pd-0 col-12')} style={{ minHeight: '300px' }}>
              <Controller
                name={keyNames.KEY_SITE_DESGIN}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <React.Suspense fallback={<Loading />}>
                    <GrapesTS
                      //isFullScreen={fullScreen}
                      height={'calc(100vh - 70px)'}
                      storageId={'site-gts'}
                      templateType="full"
                      value={value}
                      onChange={onChange}
                    />
                  </React.Suspense>
                )}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="flex-grow-1">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
        </div>
        <div className="mg-l-auto">
          {formStep > 1 && (
            <button type="button" className="btn btn-light mg-r-5" onClick={goPreviousStep}>
              <ArrowLeft />
              Previous
            </button>
          )}
          {formStep < 3 && (
            <button
              disabled={!isValid}
              type="button"
              className="btn btn-primary"
              onClick={goNextStep}
            >
              Next
              <ArrowRight />
            </button>
          )}
          {formStep === 3 && (
            <Button
              color="success"
              onClick={() => {
                handleSubmit((data) => onSubmit(data), onError)();
              }}
              disabled={mutationAdd.isLoading || mutationUpdate.isLoading || !isValid}
              loading={mutationAdd.isLoading | mutationUpdate.isLoading}
              children="Save and Finish"
            />
          )}
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  //// console.log('form api values', getParams(getValues())); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  let formHeight = 'calc(100vh - 280px)';
  if (fullScreen) {
    formHeight = 'calc(100vh - 205px)';
  }

  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-5">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>You cannot except a required field</span>
          </span>
        </div>
      </div>
      {renderFormSteps()}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        <div
          className="scroll-box"
          style={
            fullScreen
              ? { height: formHeight, overflowX: 'hidden' }
              : { maxHeight: formHeight, overflowX: 'hidden' }
          }
        >
          {renderForm()}
        </div>
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(WriteForm);
