import { Button } from '@base/components/form';
import BasicTable from '@base/components/table/basic';
import { IBillingPlan } from '@settings/billing-license/types/interfaces/billing-plan';
import React from 'react';
import { Plus } from 'react-feather';
interface IPlanTable {
  columns: any;
  data: IBillingPlan[];
  setOpenTrue: () => any;
}
const PlanTable: React.FC<IPlanTable> = (props: IPlanTable) => {
  const { columns, data, setOpenTrue } = props;
  return (
    <div className="card">
      <div className="card-header bg-light">
        <h2 className="card-title h6 mg-b-0">Billing Plan</h2>
        <Button
          type="button"
          outline={false}
          color="primary"
          className="btn btn-sm btn-primary mg-l-auto"
          onClick={() => setOpenTrue()}
        >
          <Plus />
          Add new plan
        </Button>
      </div>
      {/* <div className="table-responsive"> */}
      <BasicTable
        columns={columns}
        data={data || []}
        className="table mg-b-0 bd-0 settings-tb"
      ></BasicTable>
      {/* </div> */}
    </div>
  );
};
export default PlanTable;
