import Icon from '@base/assets/icons/svg-icons';
import { SpanLang } from '@base/components';
import Button from '@base/components/form/button';
import { Field } from '@base/components/hook-form';
import NoteAndError from '@base/components/hook-form/error-note';
import { MENU_PROCESS_STEP } from '@base/config/menus';
import useMutationPost from '@base/hooks/useMutationPost';
import { useModalSizeAtomState } from '@base/recoil/atoms';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { ObjectSignature } from '@base/types/types/app';
import { WriteParseFields } from '@base/utils/helpers/no-layout-utils';
import { initStatusForm, sample } from '@settings/process/components/diagram/status/StatusWrite';
import { STATUS_BASIC_DATA, STEP_TYPES } from '@settings/process/config/constants';
import {
  KEY_NAME_STEP_ACTION,
  KEY_NAME_STEP_AUTOMATION,
  KEY_NAME_STEP_DO,
  KEY_NAME_STEP_NAME,
  KEY_NAME_STEP_STATUSES,
} from '@settings/process/config/keyNames';
import useDefinedItem from '@settings/process/hooks/useDefinedItem';
import { useNodeEdgesAtomValue, useStageAtomValue } from '@settings/process/recoil/diagram';
import {
  useStepDoAtomState,
  useStepSettingAtomReset,
  useStepSettingAtomState,
  useStepSiteAtomValue,
} from '@settings/process/recoil/process';
import statusAtom from '@settings/process/recoil/status';
import stepTypeAtom, { stepAddOpenAtom } from '@settings/process/recoil/step';
import { CREATE_PROCESS_STEP } from '@settings/process/services/process';
import { StatusForm } from '@settings/process/types/process';
import { checkSequence, getAxisStep, getStepStatuses } from '@settings/process/utils/helper';
import { getCtaFromHTML } from '@settings/process/utils/site';
import { SITE_GROUP_OPTION } from '@settings/site/config/constants';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

interface StepWriteProps {
  processId: string;
  onClose?: () => void;
}

export const stepDoCustom = { keyName: '', languageKey: 'Manual' };

function StepWrite({ processId, onClose }: StepWriteProps) {
  // console.log('step write======');
  const [step, setStep] = useState({
    choose: true,
    write: false,
  });

  const [stepType, setStepType] = useRecoilState(stepTypeAtom);
  const [stepSetting, setStepSetting] = useStepSettingAtomState();
  const stepSettingReset = useStepSettingAtomReset();
  const stages = useStageAtomValue();
  const sourceStep = useRecoilValue(stepAddOpenAtom);
  const [statusesValue, setStatusesValue] = useRecoilState(statusAtom);
  const nodeEdgesValue = useNodeEdgesAtomValue();
  const [stepDo, setStepDo] = useStepDoAtomState();
  const setModalSize = useModalSizeAtomState();

  const stepSite = useStepSiteAtomValue(); // for site
  const { fields, getParams, defaultValues } = useMemo(
    () => WriteParseFields(MENU_PROCESS_STEP),
    [],
  );

  const [definedData, definedOptions] = useDefinedItem({
    stepType: stepType.value,
    direction: sourceStep.direction,
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    watch,
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  const selectedDo = watch(KEY_NAME_STEP_DO);
  const selectedAction = watch(KEY_NAME_STEP_ACTION);
  useEffect(() => {
    setStepDo(selectedDo?.keyName);
  }, [selectedDo]);

  useEffect(() => {
    if (stepDo) {
      if (definedData) {
        const selectedDefine = definedData[stepType.value]?.find((define) => define.id == stepDo);
        if (selectedDefine && selectedDefine.setting) {
          setStepSetting(selectedDefine.setting);
        }
      }
    } else {
      stepSettingReset();
    }
  }, [definedData, stepDo, stepType.value]);

  const queryClient = useQueryClient();
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    CREATE_PROCESS_STEP,
    'process_createStep',
    {
      onSuccess: (data: any) => {
        // console.log('success data', data);
        toast.success('Create Step successfully!');

        setTimeout(() => {
          queryClient.invalidateQueries(['process_diagram', processId]);
          queryClient.invalidateQueries(['process_nextSteps', processId]);
        }, 500);
        reset();
        setStatusesValue([]);
        setStep({ choose: true, write: false });
        onClose && onClose();
      },
      onError: (error: any) => {
        // console.log('failed', error);
        toast.warn('Create Process failed');
      },
    },
  );

  const onSubmit = (formData: any) => {
    // console.log('formData', formData);

    if (stepType.value != 'TYPE_WAIT') {
      delete formData.wait;
    }
    if (stepType.value != 'TYPE_ACTION') {
      delete formData.action;
    }
    if (stepType.value != 'TYPE_SITE') {
      delete formData.site;
    }
    if (!stepSetting.auto) {
      delete formData.automation;
    }

    delete formData.criteria;
    delete formData.checklist;
    delete formData.statuses;

    let newParams = getParams(formData);
    let checkTodoClose = false;
    let allSequence: string[] = [];
    let splitCount = 0;
    let addY = 0;
    const statuses = statusesValue.map((status) => {
      if (stepType.value == 'TYPE_ACTION') {
        if (status.property.keyName == 'PROPERTY_TODO_CLOSE') {
          checkTodoClose = true;
        }
        allSequence = _.concat(allSequence, status.sequence);
      }
      if (status.multiple == 'MULTIPLE_CHOICE' || status.multiple == 'MULTIPLE_PARALLEL') {
        if (splitCount > 0) {
          addY += 250;
        }
        splitCount += 1;
      }

      let options = '';
      if (stepType.value == 'TYPE_CHECKLIST') {
        options = JSON.stringify(status.checklist);
      } else if (stepType.value == 'TYPE_CRITERIA') {
        options = JSON.stringify(status.criteria);
      }

      return {
        id: status.id,
        definedId: status.definedId,
        button: status.button,
        name: status.name,
        direction: status.direction.keyName,
        property: status.property.keyName,
        view: status.view.keyName,
        event: status.event.keyName,
        sequence: status.sequence,
        nextStep:
          status.nextStep.keyName != ''
            ? {
                id: status.nextStep.keyName,
                name: status.nextStep.languageKey,
              }
            : null,
        multiple: status.multiple,
        primary: status.primary,
        options: options,
        ctaId: status.ctaId ?? '',
        flag: status.flag ?? 'FLAG_NONE',
      } as ObjectSignature;
    });

    if (stepType.value == 'TYPE_ACTION') {
      if (!checkSequence(_.sortBy(_.uniq(allSequence)))) {
        alert('Sequence Number has to be order');
        return;
      }

      if (!checkTodoClose) {
        alert('You have to add one todo close at least in property');
        return;
      }
    }

    const axisStep = getAxisStep(
      nodeEdgesValue.others,
      {
        id: sourceStep.sourceId,
        type: sourceStep.sourceType,
        direction: sourceStep.direction,
        multiple: sourceStep.multiple ?? 'MULTIPLE_NONE',
        primary: sourceStep.primary ?? false,
        position: sourceStep.position,
        shape: sourceStep.shape,
      },
      stepType.value,
    );

    let addData = {
      id: processId,
      step: {
        ...newParams,
        meta: {
          axis: axisStep,
        },
        shape: sourceStep.shape,
        type: stepType.value,
        property: 'PROPERTY_LINK',
        statuses: statuses,
      },
      link: {
        step: sourceStep.sourceId,
        status: sourceStep.directionId,
        direction: sourceStep.direction,
        multiple: sourceStep.multiple ?? 'MULTIPLE_NONE',
        // updateY: saveStep.meta.axis.y,
        updateY: stepType.value == 'TYPE_CRITERIA' && addY > 0 ? axisStep.y + 21 : axisStep.y,
        addY: addY,
      },
    } as ObjectSignature;

    const checkStage = stages.find((stage) => stage.axisX > axisStep.x);
    if (checkStage) {
      addData.step.stage = {
        id: checkStage.id,
        name: checkStage.name,
      };
    }

    if (stepType.value == 'TYPE_SITE') {
      addData.step.site = {
        category: stepSite.category.value,
        type: SITE_GROUP_OPTION[stepSite.type.keyName],
        template: stepSite.template.keyName,
        // html: stepSite.html
      };
    }

    if (stepType.value == 'TYPE_ACTION') {
      addData.step.setting = stepSetting;
    }

    // console.log('step addData', addData);
    mutationAdd.mutate(addData);
  };

  useEffect(() => {
    if (
      stepType.value == 'TYPE_ACTION' ||
      stepType.value == 'TYPE_SIMPLE_ACTION' ||
      stepType.value == 'TYPE_SITE'
    ) {
      setModalSize('wd-1100');
    } else if (stepType.value == 'TYPE_WAIT') {
      setModalSize('wd-800');
    } else if (stepType.value == 'TYPE_CRITERIA') {
      setModalSize('wd-800');
    } else {
      setModalSize('wd-600');
    }
  }, [stepType.value]);

  useEffect(() => {
    if (
      sourceStep.sourceType == 'TYPE_SIMPLE_ACTION' &&
      (sourceStep.direction == 'DIRECTION_FORWARD_OUTGOING_BACKWARD' ||
        sourceStep.direction == 'DIRECTION_FORWARD_OUTGOING_BOTTOM')
    ) {
      setStep({
        choose: false,
        write: true,
      });
      setStepType(STEP_TYPES[4]);
    }
  }, [sourceStep]);

  useEffect(() => {
    let statusForms: StatusForm[] = [];
    if (stepType.value == 'TYPE_ACTION') {
      if (stepDo == '') {
        statusForms = initStatusForm.slice(0, 2);
      } else {
        if (definedData && definedData.TYPE_ACTION) {
          const find = definedData.TYPE_ACTION.find((el) => el.id == stepDo);
          statusForms = getStepStatuses(stepType.value, find?.statuses);
        }
      }
    } else if (stepType.value == 'TYPE_SIMPLE_ACTION') {
      statusForms = initStatusForm;
    } else if (stepType.value == 'TYPE_CHECKLIST') {
      statusForms = [...initStatusForm.slice(0, 2), { ...initStatusForm[2], checklist: [] }];
    } else if (stepType.value == 'TYPE_WAIT') {
      statusForms = initStatusForm;
    } else if (stepType.value == 'TYPE_SITE') {
    } else if (stepType.value == 'TYPE_CRITERIA') {
      if (definedData && definedData.TYPE_CRITERIA) {
        const find = definedData.TYPE_CRITERIA.find((el) => el.id == stepDo);
        statusForms = getStepStatuses(stepType.value, find?.statuses);
      }
    }
    // console.log('statusForm', statusForms);
    setStatusesValue(statusForms);
  }, [stepDo, stepType, definedData]);

  useEffect(() => {
    // get template
    if (stepSetting.cta && selectedAction?.template) {
      const ctaList = getCtaFromHTML(sample);
      const statusForms: StatusForm[] = ctaList.map((cta) => {
        return STATUS_BASIC_DATA({
          id: _.now().toString(),
          ctaId: cta.id,
          button: cta.button,
          name: cta.title,
          event: 'EVENT_CLICK',
          direction: 'DIRECTION_FORWARD_OUTGOING_FORWARD',
          sequence: ['0'],
          definedId: _.now().toString(),
          multiple: 'MULTIPLE_ANY',
        });
      });
      setStatusesValue((old) => {
        return [...old, ...statusForms];
      });
    }
  }, [stepSetting.cta, selectedAction?.template]);

  //render footer
  const renderFooter = () => {
    return (
      <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <div className="d-flex pd-15 bd-t justify-content-between">
          <button type="button" className="btn btn-light mg-r-5" onClick={onClose}>
            Close
          </button>
          <div className="btn-group dropup">
            {step.choose && (
              <button
                type="button"
                className="btn btn-primary mg-l-auto"
                onClick={() => setStep({ write: true, choose: false })}
              >
                Next
                <ArrowRight className="mg-l-5" />
              </button>
            )}
            {step.write && (
              <>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => setStep({ choose: true, write: false })}
                >
                  <ArrowLeft className="mg-r-5" />
                  Previous
                </button>
                <Button
                  color="success"
                  onClick={() => handleSubmit((data) => onSubmit(data))()}
                  type="button"
                  disabled={mutationAdd.isLoading || !isValid}
                  loading={mutationAdd.isLoading}
                  name="Save"
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const formHeight = step.write ? 'calc(100vh - 215px)' : 'calc(100vh - 175px)';

  return (
    <div className="d-flex flex-column pd-0-f" style={{ margin: '-10px' }}>
      <ul role="tablist" className="steps steps-justified steps-tab bd-b">
        <li
          role="tab"
          className={classNames('step-item first', {
            active: step.choose,
            complete: step.write,
          })}
        >
          <div className="step-link">
            {step.choose && <span className="current-info sr-only">현재 단계</span>}
            <span className="step-number">1</span>
            <span className="step-title">Step Type</span>
          </div>
        </li>
        <li
          role="tab"
          className={classNames('step-item diabled', {
            active: step.write,
          })}
        >
          <div className="step-link">
            {step.write && <span className="current-info sr-only">현재 단계</span>}
            <span className="step-number">2</span>
            <span className="step-title">Settings</span>
          </div>
        </li>
      </ul>
      {step.write && (
        <div className="bd-b pd-10">
          <NoteAndError errors={errors} />
        </div>
      )}
      <div
        className="pos-relative scroll-box"
        style={{ height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
      >
        {step.choose && (
          <div className="list-group step-list-group">
            {STEP_TYPES.map((item) => (
              <button
                key={item.value}
                className={classNames(`list-group-item list-group-item-action step-${item.key}`, {
                  active: item.key == stepType.key,
                })}
                onClick={() => {
                  setStepType(item);
                }}
              >
                <span className="step-type-icon">{Icon(`diagram_${item.key}`)}</span>
                {item.label}
              </button>
            ))}
          </div>
        )}

        {step.write && (
          <form className="form">
            <div className="form-row pd-15">
              <div className="form-group col-12">
                <label className="form-item-title d-flex">
                  <SpanLang keyLang={'Step Type'} />
                </label>
                <div className="pd-l-8">{stepType.label}</div>
              </div>
              {fields.map((_item) => {
                let componentProps: any = _item?.componentProps ?? {};
                if (componentProps?.steptype) {
                  if (_.isArray(componentProps.steptype)) {
                    if (!_.includes(componentProps.steptype, stepType.key)) {
                      return;
                    }
                  } else {
                    if (stepType.key !== componentProps.steptype) {
                      return;
                    }
                  }
                }

                if (_item.keyName == KEY_NAME_STEP_DO) {
                  componentProps = { ...componentProps, options: definedOptions };
                }

                if (_item.keyName == KEY_NAME_STEP_NAME) {
                  if (
                    stepType.value == 'TYPE_ACTION' ||
                    stepType.value == 'TYPE_SITE' ||
                    stepType.value == 'TYPE_CRITERIA'
                  ) {
                    _item.showFullRow = false;
                    _item.columns = 2;
                  } else {
                    _item.showFullRow = true;
                    _item.columns = 1;
                  }
                }

                if (_item.keyName == KEY_NAME_STEP_AUTOMATION && !stepSetting.auto) {
                  return;
                }

                componentProps.process = processId;
                if (_item.keyName == KEY_NAME_STEP_STATUSES) {
                  componentProps.defined = definedData;
                }

                return (
                  <Suspense key={_item.keyName}>
                    <Field
                      item={{
                        ..._item,
                        componentProps: componentProps,
                      }}
                      control={control}
                      errors={errors}
                    />
                  </Suspense>
                );
              })}
            </div>
          </form>
        )}
      </div>
      {renderFooter()}
    </div>
  );
}

export default StepWrite;
