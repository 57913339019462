import usePost from '@base/hooks/usePost';
import { FilterInput, IdName } from '@base/types/interfaces/common';
import { BaseResponse, ResponsePaging } from '@base/types/interfaces/response';
import { IMenuSetting } from '@base/types/interfaces/setting';
import { KnowledgeBase } from '@desk/knowledge-base/types/interfaces/knowledge-base';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
import { stringify } from 'query-string';
import { keyStringify } from '@base/utils/helpers';
import {
  IAssignmentGroup,
  IAssignmentRep,
  IAssignmentUser,
  IDeskChannel,
  IDeskTag,
  ITicketCategory,
  ITicketClassification,
} from '../types/interfaces';
import {
  GET_ALL_KNOWLEDGES_BY_TAG,
  GET_ALL_TICKETS_BY_TAG,
  GET_ALL_TICKET_FORM,
  GET_AUTO_CLOSE_TICKET_SETTING,
  GET_DESK_ASSIGNMENT_GROUPS,
  GET_DESK_ASSIGNMENT_REPS,
  GET_DESK_ASSIGNMENT_USERS,
  GET_DESK_CHANNELS,
  GET_DESK_HOURS_SETTING,
  GET_DESK_TAGS,
  GET_PRIORITY_SETTING,
  GET_PUBLIC_TICKET_CLASSIFICATION_SETTING,
  GET_RESOLVE_SLA_SETTING,
  GET_RESPOND_PRIORITY_SETTING,
  GET_SLA_SETTING,
  GET_SURVEY_TEMPLATE_SETTING,
  GET_TICKET_CATEGORIES,
  GET_TICKET_CLASSIFICATION_SETTING,
} from './graphql';
import usePublicPost from '@base/hooks/usePublicPost';

export const useSLASetting = () => {
  let queryKey = ['setting_menuSetting', 'desk', 'sla'];
  const response = usePost<IMenuSetting>(queryKey, GET_SLA_SETTING, {});
  return response;
};
export const usePrioritySetting = () => {
  let queryKey = ['setting_menuSetting', 'desk', 'priority'];
  const response = usePost<IMenuSetting>(queryKey, GET_PRIORITY_SETTING, {});
  return response;
};
export const useRespondByPrioritySetting = () => {
  let queryKey = ['setting_menuSetting', 'desk', 'respond_priority'];
  const response = usePost<IMenuSetting>(queryKey, GET_RESPOND_PRIORITY_SETTING, {});
  return response;
};
export const useResolveSLASetting = () => {
  let queryKey = ['setting_menuSetting', 'desk', 'resolve_sla'];
  const response = usePost<IMenuSetting>(queryKey, GET_RESOLVE_SLA_SETTING, {});
  return response;
};
export const useAutoCloseTicketSetting = () => {
  let queryKey = ['setting_menuSetting', 'desk', 'auto_close_ticket'];
  const response = usePost<IMenuSetting>(queryKey, GET_AUTO_CLOSE_TICKET_SETTING, {});
  return response;
};
export const useSurveyTemplateSetting = () => {
  let queryKey = ['setting_menuSetting', 'desk', 'survey_template'];
  const response = usePost<IMenuSetting>(queryKey, GET_SURVEY_TEMPLATE_SETTING, {});
  return response;
};

export const useTicketClassificationsSetting = (isPublic?: boolean, token?: string) => {
  //let queryKey = ['desk_ticketClassifications'];
  let response = null;
  if (isPublic) {
    response = usePublicPost<BaseResponse<ITicketClassification>>(
      ['tracking_ticketClassifications'],
      GET_PUBLIC_TICKET_CLASSIFICATION_SETTING,
      { token },
    );
  } else {
    response = usePost<BaseResponse<ITicketClassification>>(
      ['desk_ticketClassifications'],
      GET_TICKET_CLASSIFICATION_SETTING,
      {},
    );
  }

  return response;
};

export const useDeskHoursSetting = () => {
  let queryKey = ['setting_menuSetting', 'desk', 'desk_hours'];
  const response = usePost<IMenuSetting>(queryKey, GET_DESK_HOURS_SETTING, {});
  return response;
};

export const useTicketCategories = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['desk_ticketCategories', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<ITicketCategory>>(queryKey, GET_TICKET_CATEGORIES, params, {
    // initialData: settingTicketCategories(), // init fake data
  });
  return response;
};

export const useTags = (keyword: string) => {
  let filter: FilterInput = {
    keyword: keyword,
  };
  let queryKey = ['desk_tags', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<IDeskTag>>(queryKey, GET_DESK_TAGS, params, {
    // initialData: settingTicketCategories(), // init fake data
  });
  return response;
};

export const useChannels = (keyword: string) => {
  let filter: FilterInput = {
    query: 'name:' + keyword,
  };
  let queryKey = ['desk_channels', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<IDeskChannel>>(queryKey, GET_DESK_CHANNELS, params, {
    // initialData: settingTicketCategories(), // init fake data
  });
  return response;
};
export const useTicketForms = (keyword: string) => {
  let filter: FilterInput = {
    query: 'name:' + keyword,
  };
  let queryKey = ['setting_ticketForms', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<IdName>>(queryKey, GET_ALL_TICKET_FORM, params, {
    // initialData: settingTicketCategories(), // init fake data
  });
  return response;
};
export const useAssignmentGroups = (keyword: string) => {
  let filter: FilterInput = {
    query: 'name:' + keyword,
  };
  let queryKey = ['desk_assignmentGroups', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<IAssignmentGroup>>(
    queryKey,
    GET_DESK_ASSIGNMENT_GROUPS,
    params,
    {
      // initialData: settingTicketCategories(), // init fake data
    },
  );
  return response;
};
export const useAssignmentReps = (groupId: string) => {
  let queryKey = ['desk_assignmentReps', groupId];
  let params = {
    id: groupId,
  };
  const response = usePost<BaseResponse<IAssignmentRep>>(
    queryKey,
    GET_DESK_ASSIGNMENT_REPS,
    params,
    {
      // initialData: settingTicketCategories(), // init fake data
      enabled: groupId != '',
    },
  );
  return response;
};

export const useAssignmentUsers = (keyword: string) => {
  let filter: FilterInput = {
    query: 'name:' + keyword,
  };
  let queryKey = ['desk_assignmentUsers', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<IAssignmentUser>>(
    queryKey,
    GET_DESK_ASSIGNMENT_USERS,
    params,
    {
      // initialData: settingTicketCategories(), // init fake data
    },
  );
  return response;
};

export const useAvailableAssignmentUsers = (keyword: string) => {
  let filter: FilterInput = {
    query: ' active=true',
  };
  let queryKey = ['desk_assignmentUsers', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<IAssignmentUser>>(
    queryKey,
    GET_DESK_ASSIGNMENT_USERS,
    params,
    {
      // initialData: settingTicketCategories(), // init fake data
    },
  );
  return response;
};

export const useAvailableAssignmentGroups = (keyword: string) => {
  let filter: FilterInput = {
    query: 'name:' + keyword + ' active=true',
  };
  let queryKey = ['desk_assignmentGroups', keyword];
  let params = {
    filter,
  };
  const response = usePost<BaseResponse<IAssignmentGroup>>(
    queryKey,
    GET_DESK_ASSIGNMENT_GROUPS,
    params,
    {
      // initialData: settingTicketCategories(), // init fake data
    },
  );
  return response;
};

export const useTicketsByTag = (filter: FilterInput | null) => {
  let queryKey = ['desk_tickets', keyStringify(filter ?? {}, '')];
  let params = {
    filter,
  };
  const response = usePost<ResponsePaging<ITicket>>(queryKey, GET_ALL_TICKETS_BY_TAG, params, {
    // initialData: settingTicketCategories(), // init fake data
    enabled: filter != null,
  });
  return response;
};

export const useKnowledgesByTag = (filter: FilterInput | null) => {
  let queryKey = ['desk_knowledgebases', keyStringify(filter ?? {}, '')];
  let params = {
    filter,
  };
  const response = usePost<ResponsePaging<KnowledgeBase>>(
    queryKey,
    GET_ALL_KNOWLEDGES_BY_TAG,
    params,
    {
      // initialData: settingTicketCategories(), // init fake data
      enabled: filter != null,
    },
  );
  return response;
};
